import React, { useState, useEffect, useContext } from 'react';
import { Scoped } from 'kremling';
import { getCustomerRefGroups, createCustomerRefGroups, editCustomerRefGroup, editCustomer } from 'shared/common.api';
import { PageHeader } from 'components/page-header/page-header';
import styles from './customer-groups.styles.scss';
import { Pagination } from 'components/pagination/pagination.component';
import { Loader } from 'components/loader/loader.component';
import { userState } from 'shared/user-state';
import { SortHeader } from 'components/table/sort-header.component';
import { Button } from 'components/button/button.component';
import utils from 'shared/utils';
import { Dropdown } from 'components/dropdown/dropdown.component';
import { CreateModal } from './create-modal.component';
import { EditModal } from './edit-modal.component';
import { CustomerDrawerContext } from '../../context/customer-drawer-context';
export function CustomerGroups(props) {
  const [groups, setGroups] = useState([]);
  const [count, setCount] = useState();
  const [search, setSearch] = useState('');
  const [ordering, setOrdering] = useState();
  const [paginationParams, setPaginationParams] = useState({
    limit: 20,
    offset: 0
  });
  const [loading, setLoading] = useState(true);

  // Create Group
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [createErrors, setCreateErrors] = useState({});
  const [createData, setCreateData] = useState({});

  // Edit Group
  const [showEditModal, setShowEditModal] = useState(false);
  const [editErrors, setEditErrors] = useState({});
  const [editData, setEditData] = useState({});

  // Customer Drawer
  const {
    openCustomerDrawer
  } = useContext(CustomerDrawerContext);
  useEffect(() => {
    setLoading(true);
    getCustomerRefGroups({
      ...paginationParams,
      search,
      ordering,
      company: userState.getAsCompanyId()
    }).then(({
      data
    }) => {
      if (data) {
        setGroups(data.results);
        setCount(data.count);
        setLoading(false);
      }
    });
  }, [search, ordering, paginationParams]);
  const createGroup = () => {
    setCreateErrors({});
    return createCustomerRefGroups({
      ...createData,
      company: userState.getAsCompanyId()
    }).then(({
      data
    }) => {
      setEditData(data);
      setEditErrors({});
      setShowEditModal(true);
      setPaginationParams({
        ...paginationParams
      });
    }).catch(e => {
      setCreateErrors(e.response.data);
      throw e;
    });
  };
  const editGroup = () => {
    setEditErrors({});
    return editCustomerRefGroup(editData.id, {
      name: editData.name
    }).then(() => editCustomer(editData.customer.id, editData.customer)).then(() => {
      setEditData({});
      setEditErrors({});
      setPaginationParams({
        ...paginationParams
      });
    }).catch(e => {
      setEditErrors(e.response.data);
      throw e;
    });
  };
  return <Scoped css={styles}>
      <div className="wrapper">
        {loading && <Loader overlay background="rgba(255,255,255,.5)" />}
        <PageHeader name={`Customer Group Management ${count ? `(${utils.commaize(count)})` : ''}`} onSearch={setSearch} actions={userState.hasPermission('customer.add_customerrefgroup') && <Button actionType="primary" icon="fa-regular-plus" onClick={() => setShowCreateModal(true)} />} />
        <div className="wrapper-scroll p-md">
          <div className="table-container">
            <table className="table-list">
              <thead>
                <tr>
                  <SortHeader name="entity_group" ordering={ordering} update={setOrdering}>
                    Group ID
                  </SortHeader>
                  <SortHeader name="name" ordering={ordering} update={setOrdering}>
                    Group Name
                  </SortHeader>
                  <SortHeader name="customer__first_name" ordering={ordering} update={setOrdering}>
                    First Name
                  </SortHeader>
                  <SortHeader name="customer__last_name" ordering={ordering} update={setOrdering}>
                    Last Name
                  </SortHeader>
                  <SortHeader name="customer__email" ordering={ordering} update={setOrdering}>
                    Email
                  </SortHeader>
                  <SortHeader name="customer__phone" ordering={ordering} update={setOrdering}>
                    Phone
                  </SortHeader>
                  <th style={{
                  width: 40
                }} />
                </tr>
              </thead>
              <tbody>
                {groups.map(group => <tr key={group.id} onClick={() => {
                openCustomerDrawer(group.customer.id);
              }}>
                    <td>{group.entity_group}</td>
                    <td>{group.name}</td>
                    <td>{group.customer.first_name}</td>
                    <td>{group.customer.last_name}</td>
                    <td>{group.customer.email}</td>
                    <td>{utils.formatPhoneNumber(group.customer.phone)}</td>
                    <td>
                      <Dropdown horizontal="west" trigger={() => <Button actionType="flat" icon="fa-regular-ellipsis-h" />} content={() => <ul className="select-list">
                            {userState.hasPermission('customer.view_customer') && <li onClick={() => {
                      setCustomer(group.customer);
                      setCustomerDrawerOpen(true);
                    }}>
                                <a>View Customer</a>
                              </li>}
                            {userState.hasPermission('customer.change_customerrefgroup') && <li onClick={() => {
                      setEditData(group);
                      setShowEditModal(true);
                      setEditErrors({});
                    }}>
                                <a>Edit</a>
                              </li>}
                            {userState.hasPermission('customer.delete_customerrefgroup') && <>
                                <li className="divider" />
                                <li onClick={() => this.isSegmentSelected(segment)}>
                                  <a>Delete</a>
                                </li>
                              </>}
                          </ul>} />
                    </td>
                  </tr>)}
              </tbody>
            </table>
          </div>
          <div className="pt-md pb-md">
            <Pagination limit={paginationParams.limit} data={{
            count
          }} onChange={setPaginationParams} />
          </div>
        </div>
      </div>

      <CreateModal showCreateModal={showCreateModal} setShowCreateModal={setShowCreateModal} createGroup={createGroup} createErrors={createErrors} setCreateData={setCreateData} createData={createData} />

      <EditModal showEditModal={showEditModal} setShowEditModal={setShowEditModal} editGroup={editGroup} editErrors={editErrors} setEditData={setEditData} editData={editData} />
    </Scoped>;
}