import React, { Component } from 'react';
import { func, object, shape, string, number } from 'prop-types';
import { getCallToolsToken, getCallToolsLists } from '../../../integrations/integrations.resource.js';
import { userState } from 'shared/user-state';
import { Dropdown } from 'components/dropdown/dropdown.component';
import { Button } from 'components/button/button.component';
import { Icon } from 'components/icon/icon.component';
import { StaticTypeAhead } from '../../../../components/static-type-ahead/static-type-ahead.componet';
import { set } from 'lodash';
export class ActionSendToCallTools extends Component {
  static propTypes = {
    update: func,
    node: object,
    data: shape({
      list_id: number,
      list_name: string,
      token_id: string
    })
  };
  state = {
    listList: []
  };
  componentDidMount() {
    let listList = [];
    getCallToolsToken({
      company: userState.getAsCompanyId()
    }).then(({
      results
    }) => results.reduce((chain, token) => chain.then(() => getCallToolsLists(token.id)).then(lists => {
      lists = lists.map(list => ({
        list_id: list.id,
        list_name: list.name,
        token_id: token.id
      }));
      listList = listList.concat(lists);
    }), Promise.resolve())).then(() => {
      this.setState({
        listList
      });
    });
  }
  toggleList = list => {
    this.props.update(list);
  };
  render() {
    const {
      listList
    } = this.state;
    const selectedList = listList.find(list => list.list_id === this.props.data.list_id);
    return <div>
        <div className="form-group">
          <label>Phone List</label>
          <StaticTypeAhead items={listList} placeholder={selectedList ? selectedList.list_name : 'Choose Phone List'} displayProperty="list_name" keyProperty="list_id" value={selectedList ? selectedList.list_id : null} onChange={list => {
          this.toggleList(list);
        }} />
        </div>
      </div>;
  }
}