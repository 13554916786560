import React, { useState, useRef, useEffect } from 'react';
import { userState } from 'shared/user-state';
import { getCompanyFields } from 'shared/common.api';
import { Emoji } from 'components/emoji/emoji.component';

/**
 * TODO There are some hard coded node specific things in here. We should probably fix that.
 * TODO A lot of this is shared with the SMSMessage component
 */

export const ReviewMessage = props => {
  const {
    param
  } = props;
  const message = props.data.message || '';
  const [fields, setFields] = useState([]);
  const [messageHeight, setMessageHeight] = useState(50);
  useEffect(() => {
    getCompanyFields(userState.state.asCompany.id).then(({
      data: fields
    }) => {
      setFields(fields);
    });
  }, []);
  const onMessageChange = e => {
    setMessageHeight(e.target.scrollHeight);
    props.update({
      message: e.target.value
    });
  };
  const insertEmoji = e => {
    props.update({
      message: props.data.message ? props.data.message + e : e
    });
  };
  const insertData = e => {
    const {
      message
    } = props.data;
    props.update({
      message: `${message || ''}{{{${e.target.value}}}}`
    });
  };
  const getId = (groupId, fieldId) => {
    return `${groupId}.${fieldId.replace(/__/g, '.')}`;
  };
  return <>
      <div className="form-group">
        <div className="d-flex justify-content-between align-items-center">
          <label className="my-0">{param.label}</label>
          <Emoji openDirection="right" onEmojiClick={e => insertEmoji(e)} />
        </div>
        <div className="form-control" style={{
        height: 'auto',
        padding: '0px'
      }}>
          <textarea className="form-control form-message" style={{
          border: 'none',
          boxShadow: 'none',
          overflowY: 'scroll',
          minHeight: '24px',
          maxHeight: '80px',
          height: `${messageHeight}px`,
          resize: 'none',
          ':focus': {
            outline: 'none'
          }
        }} onChange={onMessageChange} value={message || ''} />
        </div>
      </div>
      <div className="d-flex mb-3 justify-content-between">
        <select className="form-control" style={{
        height: '2rem',
        width: '136px'
      }} onChange={insertData} value={''}>
          <option>Insert Tag</option>
          {fields.map(field => <optgroup key={field.id} label={field.name}>
              {field.fields.map(subfield => <option key={subfield.id} value={getId(field.id, subfield.id)}>
                  {subfield.name}
                </option>)}
            </optgroup>)}
        </select>
      </div>
    </>;
};