import React, { Component } from 'react';
import { func, bool, array, number } from 'prop-types';
import { Scoped, k, a } from 'kremling';
import { Loader } from 'components/loader/loader.component';
export class FormWizardChooser extends Component {
  static propTypes = {
    searchingForForms: bool,
    formHtmlIndex: number,
    forms: array,
    updateState: func
  };
  state = {
    previewForm: false
  };
  updateFormIndex = formHtmlIndex => {
    this.props.updateState(({
      forms
    }) => ({
      formHtmlIndex,
      formFields: forms[formHtmlIndex].fields.map(field => ({
        ...field,
        field: null
      }))
    }));
  };
  render() {
    const {
      searchingForForms,
      formHtmlIndex,
      forms
    } = this.props;
    const {
      previewForm
    } = this.state;
    return <Scoped css={css}>
        <div>
          {searchingForForms && <div className="searching">
              Searching for Forms
              <Loader />
            </div>}

          {!searchingForForms && forms.length > 0 && <div>
                <h3>{forms.length} Form{forms.length > 1 ? 's' : ''} found</h3>
                <div className="item-form-group">
                  {forms.map((form, i) => <div className={a('item-form').m('item-form--active', formHtmlIndex === i)} key={i} onClick={() => this.updateFormIndex(i)}>
                      <div className="item-form__box">
                        <div className="pt-lg">
                          <div style={{
                    display: 'flex'
                  }}>
                            <div className="mini-input" style={{
                      flexGrow: 2
                    }} />
                            <div className="mini-input" style={{
                      flexGrow: 1
                    }} />
                          </div>
                          <div style={{
                    display: 'flex'
                  }}>
                            <div className="mini-input" style={{
                      flexGrow: 1
                    }} />
                          </div>
                          <div style={{
                    display: 'flex'
                  }}>
                            <div className="mini-input" style={{
                      flexGrow: 1
                    }} />
                            <div className="mini-input" style={{
                      flexGrow: 2
                    }} />
                          </div>
                          <div style={{
                    display: 'flex',
                    justifyContent: 'flex-end'
                  }}>
                            <div className="mini-input" style={{
                      width: '2rem'
                    }} />
                          </div>
                          <div className="item-form__fields">
                            {form.fields.length} fields
                          </div>
                        </div>
                      </div>
                      <div className="item-form__name">
                        Form {i + 1}
                      </div>
                    </div>)}
                </div>
                {forms && !!forms.length && <>
                    <div className="preview-form">
                      <label>
                        <input type="checkbox" checked={previewForm} onChange={() => this.setState({
                  previewForm: !previewForm
                })} /> Preview Form
                      </label>
                    </div>
                    {previewForm && <div className="fake-form">
                        {forms[formHtmlIndex].fields.map((field, i) => <div className="form-group" key={i}>
                            <label>{field.label || `field[${i}]`}</label>
                            <input type="text" className="form-control" readOnly />
                          </div>)}
                      </div>}
                  </>}
              </div>}

          {!searchingForForms && forms.length === 0 && <div className="no-forms-found">
              No Forms found
            </div>}
        </div>
      </Scoped>;
  }
}
const css = {
  styles: `[kremling="i39"] body,body[kremling="i39"] {
  --color-primary: #487aae;
  --color-highlight: #e7efff;
  --color-accent: #354052;
  --color-success: #73b56e;
  --color-warning: #f4b707;
  --color-danger: #df5651;
  --color-grey-10: #f9f9f9;
  --color-grey-25: #f3f3f3;
  --color-grey-50: #e9e9e9;
  --color-grey-75: #e3e3e3;
  --color-grey-100: #d3d3d3;
  --color-grey-200: #c3c3c3;
  --color-grey-300: #b3b3b3;
  --color-grey-400: #a3a3a3;
  --color-grey-500: #808080;
  --color-grey-600: #707070;
  --color-grey-700: #606060;
  --color-grey-800: #505050;
  --color-grey-900: #404040;
  --base-font-family: Roboto, Helvetica, Arial, sans-serif;
  --base-font-size: 1.4rem;
  --base-font-color: #404040;
  --base-font-weight: 400;
  --base-line-height: 1.4;
  --box-shadow-1: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.12);
  --box-shadow-2: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-3: 0 10px 20px rgba(0, 0, 0, 0.17), 0 6px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-4: 0 14px 28px rgba(0, 0, 0, 0.13), 0 10px 10px rgba(0, 0, 0, 0.1);
  --box-shadow-5: 0 19px 38px rgba(0, 0, 0, 0.18), 0 15px 12px rgba(0, 0, 0, 0.1);
  --base-border-radius: 0.4rem;
}

[kremling="i39"] .searching,[kremling="i39"].searching {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 30rem;
  font-weight: 700;
  font-size: 1.6rem;
}

[kremling="i39"] .item-form-group,[kremling="i39"].item-form-group {
  padding: 1.6rem;
  border-bottom: solid 0.1rem var(--color-grey-100);
}

[kremling="i39"] .item-form,[kremling="i39"].item-form {
  user-select: none;
  margin: 0 1.6rem 1.6rem 0;
  width: 10rem;
  display: inline-block;
  cursor: pointer;
}

[kremling="i39"] .item-form__box,[kremling="i39"].item-form__box {
  width: 10rem;
  height: 8rem;
  border-radius: var(--base-border-radius);
  border: solid 0.3rem var(--color-grey-100);
  background-color: var(--color-grey-25);
  padding: 0.8rem;
  position: relative;
}

[kremling="i39"] .mini-input,[kremling="i39"].mini-input {
  height: 0.4rem;
  background-color: var(--color-grey-100);
  margin: 0 0.2rem 0.4rem 0.2rem;
}

[kremling="i39"] .item-form__fields,[kremling="i39"].item-form__fields {
  position: absolute;
  top: 0.6rem;
  left: 0.8rem;
  color: var(--color-grey-500);
  font-size: 1.2rem;
}

[kremling="i39"] .item-form__name,[kremling="i39"].item-form__name {
  padding: 0.4rem 0;
  text-align: center;
  font-weight: 700;
}

[kremling="i39"] .item-form.item-form--active .item-form__box,[kremling="i39"].item-form.item-form--active .item-form__box {
  border-color: var(--color-primary);
  background-color: var(--color-highlight);
}

[kremling="i39"] .item-form.item-form--active .mini-input,[kremling="i39"].item-form.item-form--active .mini-input {
  background-color: var(--color-primary);
}

[kremling="i39"] .item-form.item-form--active .item-form__fields,[kremling="i39"].item-form.item-form--active .item-form__fields {
  color: var(--color-primary);
}

[kremling="i39"] .item-form.item-form--active .item-form__name,[kremling="i39"].item-form.item-form--active .item-form__name {
  color: var(--color-primary);
}

[kremling="i39"] .preview-form,[kremling="i39"].preview-form {
  position: relative;
  z-index: 1;
  margin-top: -1rem;
}

[kremling="i39"] .preview-form>label,[kremling="i39"].preview-form>label {
  padding: 0 1.6rem 0 0;
  background-color: #fff;
  display: inline-block;
}

[kremling="i39"] .fake-form,[kremling="i39"].fake-form {
  background-color: var(--color-grey-25);
  border-radius: var(--base-border-radius);
  border: solid 0.1rem var(--color-grey-100);
  padding: 1.6rem;
  transform: scale(0.9);
  pointer-events: none;
  margin: -2rem;
}

[kremling="i39"] .no-forms-found,[kremling="i39"].no-forms-found {
  font-size: 1.6rem;
  font-weight: 700;
  margin: 10rem auto;
  text-align: center;
}`,
  id: 'i39',
  namespace: 'kremling'
};