import React, { Component } from 'react';
import { Scoped, k } from 'kremling';
import { Redirect } from 'react-router-dom';
import { Modal } from '../../components/modal/modal.component';
import { toasterService } from '../../components/toaster/toaster-service';
import utils from '../../shared/utils';
import cinchLogo from '../../assets/img/cinch-logo.svg';
import { Button } from '../../components/button/button.component';
import { getMe, signIn } from '../../shared/auth.api';
import { userState } from '../../shared/user-state';
import { api } from '../../shared/api';
import signInImg from '../../assets/img/sign-in-pattern.jpg';
const LOCAL = 'localhost:8080';
const DEV = 'marketing-dev.cinch.io';
export class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      rememberMe: false,
      modalIsOpen: false,
      forgotEmail: '',
      newPassword: '',
      shopify: false,
      modalResetIsOpen: this.hasParamToken(),
      formForgetPwdErrors: {
        email: {},
        password: {}
      },
      formErrors: {
        email: {},
        password: {}
      },
      formResetPwdErrors: {
        password: {}
      },
      redirect: null
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitForgot = this.handleSubmitForgot.bind(this);
    this.handleSubmitReset = this.handleSubmitReset.bind(this);
    this.handleUserInput = utils.handleUserInput.bind(this);
    this.handleUserInputCheckbox = utils.handleUserInputCheckbox.bind(this);
  }
  componentDidMount() {
    setTimeout(() => {
      const params = new URLSearchParams(this.props.location.search);
      if (params.has('timeout')) {
        toasterService.warning('Session timed out - please sign in.');
      }
      if (params.has('redirect')) {
        this.setState({
          redirect: params.get('redirect'),
          shopify: params.get('redirect').indexOf('shopify-authorize') > -1
        });
      }
    }, 0);
  }
  setLogo = () => ({
    __html: cinchLogo
  });
  openModal() {
    this.setState({
      modalIsOpen: true
    });
  }
  closeModal() {
    this.setState({
      modalIsOpen: false
    });
  }
  render() {
    const {
      formErrors,
      formResetPwdErrors,
      shopify
    } = this.state;
    if (userState.state.validToken) return <Redirect to={userState.state.redirect} />;

    /*
    // IS THIS BEING USED STILL?
    if (redirect.isRedirect) {
      return <Redirect to={redirect.path} />;
    }
    */

    return <Scoped css={css}>
        <div className="wrapper-contain">
          <div className="sign-in__wrapper" style={{
          backgroundImage: `url(${signInImg})`
        }}>
            <div className="sign-in">
              <form onSubmit={this.handleSubmit}>
                <div className="text-center">
                  <div className="mb-5">
                    <div className="logo" dangerouslySetInnerHTML={this.setLogo()} />
                  </div>
                </div>
                {shopify && <div className="mb-5">
                    Thank you for installing our Shopify app.
                    <br />
                    Don't have a Cinch account?
                    <br />
                    Contact sales <a href="mailto: sales@cinch.io">sales@cinch.io</a>
                  </div>}
                <div className="form-group">
                  {formErrors.email === true ? <div>
                      <p className="invalid-input">Invalid email</p>
                    </div> : null}
                  <label>Email Address</label>
                  <input id="iSignInEmail" className="form-control" name="email" onChange={event => this.handleUserInput(event, this)} type="email" value={this.state.email} email="true" autoFocus />
                </div>
                <div className="form-group">
                  {formErrors.password === true ? <div>
                      <p className="invalid-input">Password required</p>
                    </div> : null}
                  <label>Password</label>
                  <input id="iSignInPassword" className="form-control" name="password" onChange={event => this.handleUserInput(event, this)} value={this.state.password} type="password" />
                </div>
                <div className="form-group">
                  <div className="checkbox-label">
                    <label className="form-check-label">
                      <input
                    //id="iSignInRememberMe"
                    className="form-check-input" name="rememberMe" type="checkbox" value={this.state.rememberMe} htmlFor="rememberMe" onChange={event => this.handleUserInputCheckbox(event, this)} id="rememberMe" />
                      <span className="m-2">Remember me</span>
                    </label>
                  </div>
                </div>
                <div className="mt-5">
                  <Button id="bSignInSignIn" type="submit" actionType="primary" block large>
                    Sign In
                  </Button>
                </div>
                <div className="mt-2">
                  <a onClick={this.openModal} tooltip="Forgot Password" placement="bottom">
                    {' '}
                    Forgot Password?
                  </a>
                </div>
              </form>
            </div>
          </div>

          <Modal open={this.state.modalIsOpen} onClose={this.closeModal} allowBackdropClick title="Reset your password">
            <div className="modal__body">
              <p>Enter the email address associated with your account.</p>
              <div className="form-group">
                <input className="form-control" name="forgotEmail" placeholder="Email Address" onChange={event => this.handleUserInput(event, this)} type="email" value={this.state.forgotEmail} autoFocus />
              </div>
            </div>
            <div className="modal__actions">
              <Button actionType="flat" onClick={this.closeModal}>
                Cancel
              </Button>
              <Button actionType="primary" onClick={() => this.handleSubmitForgot()}>
                Submit
              </Button>
            </div>
          </Modal>

          <Modal open={this.state.modalResetIsOpen} onClose={() => this.setState({
          modalResetIsOpen: false
        })} allowBackdropClick title="Reset password">
            <div className="modal__body">
              <p>Enter your new password.</p>
              <div className="form-group">
                <input className="form-control" placeholder="New Password" name="newPassword" onChange={event => this.handleUserInput(event, this)} type="password" value={this.state.newPassword} autoFocus />
                {formResetPwdErrors.password === true ? <p style={{
                color: 'red'
              }}>Password required</p> : null}
              </div>
            </div>
            <div className="modal__actions">
              <Button actionType="flat" onClick={() => this.setState({
              modalResetIsOpen: false
            })}>
                Cancel
              </Button>
              <Button actionType="primary" onClick={() => this.handleSubmitReset()}>
                Submit
              </Button>
            </div>
          </Modal>
        </div>
      </Scoped>;
  }
  hasParamToken() {
    return !!this.props.match.params && !!this.props.match.params.token;
  }
  handleSubmit(event) {
    event.preventDefault();
    if (this.validInputs()) {
      signIn({
        email: this.state.email,
        password: this.state.password,
        remember_me: this.state.rememberMe
      }).then(({
        data
      }) => {
        localStorage.setItem('showBetaBanner', true);
        if (data.beta_opt_in) {
          //they are opted into the beta, forward them to new site
          if (window.location.host === LOCAL) {
            //do nothing
          } else {
            window.location.href = `https://beta.${window.location.host}`;
          }
        }
        userState.setToken(data.token, data.expires_when);
        userState.set({
          validToken: true
        });
        if (data.requires_mfa) {
          this.props.history.push('/mfa');
        } else {
          this.props.history.push(this.state.redirect || '/companies');
        }
      }, ({
        response
      }) => {
        if (response?.data?.[0]) {
          toasterService.error(response?.data?.[0]);
        } else if (response?.data?.non_field_errors?.[0]) {
          toasterService.error(response?.data?.non_field_errors?.[0]);
        } else {
          toasterService.error('Username or password is incorrect');
        }
      });
    }
  }
  handleSubmitForgot() {
    event.preventDefault();
    if (this.validInputsForgot()) {
      api.post('/users/forgot-password', {
        email: this.state.forgotEmail
      }).then(response => response.data).then(data => {
        if (data.non_field_errors) {
          toasterService.warning(data.non_field_errors[0]);
        } else if (data.message) {
          this.setState({
            modalIsOpen: false
          });
          toasterService.success(data.message);
        }
      }).catch(error => {
        toasterService.error('Oops, something went wrong!');
      });
    }
  }
  handleSubmitReset() {
    event.preventDefault();
    if (this.validInputsReset()) {
      api.post('/users/reset-password', {
        password: this.state.newPassword,
        token: this.props.match.params.token
      }).then(response => response.data).then(data => {
        this.setState({
          modalResetIsOpen: false
        });
        toasterService.success(data.message);
      }).catch(error => {
        //Toaste showing error messsage
        if (error.response && error.response.data) {
          if (error.response.data.password) {
            //Show toast for error
            toasterService.error(error.response.data.password[0]);
          } else if (error.response.data.non_field_errors) {
            toasterService.error(error.response.data.non_field_errors[0]);
          }
        } else {
          toasterService.error('Oops, something went wrong!');
        }
      });
    }
  }
  validInputsForgot() {
    const {
      forgotEmail
    } = this.state;
    if (!forgotEmail || !forgotEmail.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      this.setState({
        formForgetPwdErrors: {
          email: true
        }
      });
      return false;
    }
    this.setState({
      formForgetPwdErrors: {
        email: false
      }
    });
    return true;
  }
  validInputsReset() {
    const {
      newPassword
    } = this.state;
    if (!newPassword) {
      this.setState({
        formResetPwdErrors: {
          password: true
        }
      });
      return false;
    }
    this.setState({
      formResetPwdErrors: {
        password: false
      }
    });
    return true;
  }
  validInputs() {
    const {
      email,
      password
    } = this.state;
    if (!email || !email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      this.setState({
        formErrors: {
          email: true
        }
      });
      return false;
    } else if (!password) {
      this.setState({
        formErrors: {
          password: true
        }
      });
      return false;
    }
    this.setState({
      formErrors: {
        email: false,
        password: false
      }
    });
    return true;
  }
}
const css = {
  styles: `[kremling="i2"] body,body[kremling="i2"] {
  --color-primary: #487aae;
  --color-highlight: #e7efff;
  --color-accent: #354052;
  --color-success: #73b56e;
  --color-warning: #f4b707;
  --color-danger: #df5651;
  --color-grey-10: #f9f9f9;
  --color-grey-25: #f3f3f3;
  --color-grey-50: #e9e9e9;
  --color-grey-75: #e3e3e3;
  --color-grey-100: #d3d3d3;
  --color-grey-200: #c3c3c3;
  --color-grey-300: #b3b3b3;
  --color-grey-400: #a3a3a3;
  --color-grey-500: #808080;
  --color-grey-600: #707070;
  --color-grey-700: #606060;
  --color-grey-800: #505050;
  --color-grey-900: #404040;
  --base-font-family: Roboto, Helvetica, Arial, sans-serif;
  --base-font-size: 1.4rem;
  --base-font-color: #404040;
  --base-font-weight: 400;
  --base-line-height: 1.4;
  --box-shadow-1: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.12);
  --box-shadow-2: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-3: 0 10px 20px rgba(0, 0, 0, 0.17), 0 6px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-4: 0 14px 28px rgba(0, 0, 0, 0.13), 0 10px 10px rgba(0, 0, 0, 0.1);
  --box-shadow-5: 0 19px 38px rgba(0, 0, 0, 0.18), 0 15px 12px rgba(0, 0, 0, 0.1);
  --base-border-radius: 0.4rem;
}

[kremling="i2"] .sign-in__wrapper,[kremling="i2"].sign-in__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-position: center;
}

[kremling="i2"] .sign-in,[kremling="i2"].sign-in {
  background-color: #fff;
  padding: 5rem 2rem;
  border-radius: 2rem;
  box-shadow: var(--box-shadow-5);
  flex-grow: 1;
}
@media (min-width: 768px) {
  [kremling="i2"] .sign-in,[kremling="i2"].sign-in {
    border-radius: 1rem;
    padding: 5rem 3rem;
    width: 36rem;
    flex-grow: 0;
  }
}

[kremling="i2"] .logo,[kremling="i2"].logo {
  fill: var(--color-primary);
  width: 100px;
  margin: 0 auto;
}

[kremling="i2"] .request,[kremling="i2"].request {
  color: #12aae1;
  font-size: 16px;
  line-height: 20px;
  text-decoration: underline;
}

[kremling="i2"] .bg-wrapper,[kremling="i2"].bg-wrapper {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

[kremling="i2"] .bg-wrapper video,[kremling="i2"].bg-wrapper video {
  min-width: 100vw;
  min-height: 100vh;
}

[kremling="i2"] .remember-me,[kremling="i2"].remember-me {
  color: #8f8f8f;
  font-size: 16px;
  line-height: 20px;
}

[kremling="i2"] .best,[kremling="i2"].best {
  color: #646464;
  font-size: 16px;
  line-height: 20px;
}

[kremling="i2"] .btn-sm,[kremling="i2"].btn-sm {
  font-weight: normal;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

[kremling="i2"] .forgot-btn,[kremling="i2"].forgot-btn {
  position: absolute;
  right: 6px;
  top: 37px;
  color: #a5a5a5;
  font-size: 12px;
  text-transform: inherit;
  border-radius: 2px;
  background-color: #e3e3e3;
}

[kremling="i2"] .filter,[kremling="i2"].filter {
  z-index: 100;
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  width: 100vw;
  height: 100vh;
}

[kremling="i2"] .checkbox-box,[kremling="i2"].checkbox-box {
  float: left;
  width: 30px;
}

[kremling="i2"] .checkbox-label,[kremling="i2"].checkbox-label {
  float: left;
  margin-bottom: 13px;
  margin-top: -5px;
}

[kremling="i2"] .width-240,[kremling="i2"].width-240 {
  width: 240px;
}

[kremling="i2"] .invalid-input,[kremling="i2"].invalid-input {
  margin-bottom: 0px;
  color: red;
}`,
  id: 'i2',
  namespace: 'kremling'
};