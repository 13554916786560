import React, { useContext } from 'react';
import { PageHeaderMui } from 'components';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { getReviews, sendReviewReply, getReviewsLocations } from '../../shared/common.api';
import moment from 'moment';
import EnhancedTable from '../../components/mui/enhanced-table/enhanced-table.component';
import Rating from '@mui/material/Rating';
import GoogleIcon from '@mui/icons-material/Google';
import { Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import CssBaseline from '@mui/material/CssBaseline';
import ReplyDialog from './reply-dialog.component';
import { userState } from 'shared/user-state';
import { SingleSelectFilter } from 'components/mui/filter/single-select.component';
import { MultiSelectFilter } from 'components/mui/filter/multi-select.component';
import { DateRangeFilter } from 'components/mui/filter/date-range.component';
import { LocationTooltip } from '../../components/mui/tooltip/location-tooltip.component';
import { CustomWidthTooltip } from '../../components/mui/tooltip/custom-width-tooltip.component';
import { CustomerDrawerContext } from '../../context/customer-drawer-context';
import StarOutlinedIcon from '@mui/icons-material/StarOutlined';
import { Icon } from '../../components/icon/icon.component';
const renderReplied = (review, hover) => {
  if (!review.reply_when && review.id == hover) {
    return <div style={{
      width: '40px',
      display: 'flex'
    }}>
        <div style={{
        color: '#53A6D6'
      }}>
          <Icon name="custom-reply" size={20} />
        </div>
        <Typography sx={{
        pl: 1,
        margin: 'auto'
      }} color={'#53A6D6'}>
          Reply
        </Typography>
      </div>;
  }
  if (!!review.reply_when && review.id == hover) {
    return <div style={{
      width: '40px',
      display: 'flex'
    }}>
        <EditOutlinedIcon sx={{
        fontSize: '24px',
        color: '#53A6D6'
      }} />
        <Typography sx={{
        pl: 1,
        margin: 'auto'
      }} color={'#53A6D6'}>
          Edit Reply
        </Typography>
      </div>;
  }
  if (!review.reply_when) {
    return <CancelOutlinedIcon sx={{
      fontSize: '24px',
      color: '#FCCD6F'
    }} />;
  }
  return <CheckCircleOutlineIcon sx={{
    fontSize: '24px',
    color: '#66CC99'
  }} />;
};
export const Reviews = () => {
  const [search, setSearch] = React.useState('');
  const [replyDialog, setReplyDialog] = React.useState(null);
  const [locations, setLocations] = React.useState([]);
  const [dataUpdate, setDataUpdate] = React.useState(0);
  const {
    openCustomerDrawer
  } = useContext(CustomerDrawerContext);
  const [filters, setFilters] = React.useState({
    date_after: moment().subtract(30, 'days').startOf('day').toISOString()
  });
  React.useEffect(() => {
    getReviewsLocations({
      company: userState.getAsCompanyId()
    }).then(({
      data
    }) => {
      if (data) {
        setLocations(data);
      }
    });
  }, []);
  const searchSegment = search => {
    setSearch(search);
  };
  const convertNormalizedScore = normalizedScore => {
    if (normalizedScore == 0) return 1;
    if (normalizedScore > 0 && normalizedScore <= 0.25) return 2;
    if (normalizedScore > 0.25 && normalizedScore <= 0.5) return 3;
    if (normalizedScore > 0.5 && normalizedScore <= 0.75) return 4;
    if (normalizedScore > 0.75) return 5;
  };
  const filterOptions = [{
    component: SingleSelectFilter,
    label: 'Replied',
    key: 'has_reply',
    options: [{
      label: 'Show all',
      value: 'all',
      isDefault: true
    }, {
      label: 'Yes',
      value: 'true'
    }, {
      label: 'No',
      value: 'false'
    }]
  }, {
    component: MultiSelectFilter,
    label: 'Score',
    key: 'score',
    options: [{
      label: <Rating name="read-only" value={5} readOnly />,
      chipLabel: <Rating sx={{
        textAlign: 'center',
        margin: 'auto',
        top: '2px',
        color: '#53A6D6'
      }} name="read-only" value={5} readOnly emptyIcon={<StarOutlinedIcon sx={{
        color: 'rgba(83, 166, 214, 0.3)'
      }} />} />,
      value: '1'
    }, {
      label: <Rating name="read-only" value={4} readOnly />,
      chipLabel: <Rating sx={{
        textAlign: 'center',
        margin: 'auto',
        top: '2px',
        color: '#53A6D6'
      }} name="read-only" value={4} readOnly emptyIcon={<StarOutlinedIcon sx={{
        color: 'rgba(83, 166, 214, 0.3)'
      }} />} />,
      value: '0.75'
    }, {
      label: <Rating name="read-only" value={3} readOnly />,
      chipLabel: <Rating sx={{
        textAlign: 'center',
        margin: 'auto',
        top: '2px',
        color: '#53A6D6'
      }} name="read-only" value={3} readOnly emptyIcon={<StarOutlinedIcon sx={{
        color: 'rgba(83, 166, 214, 0.3)'
      }} />} />,
      value: '0.5'
    }, {
      label: <Rating name="read-only" value={2} readOnly />,
      chipLabel: <Rating sx={{
        textAlign: 'center',
        margin: 'auto',
        top: '2px',
        color: '#53A6D6'
      }} name="read-only" value={2} readOnly emptyIcon={<StarOutlinedIcon sx={{
        color: 'rgba(83, 166, 214, 0.3)'
      }} />} />,
      value: '0.25'
    }, {
      label: <Rating name="read-only" value={1} readOnly />,
      chipLabel: <Rating sx={{
        textAlign: 'center',
        margin: 'auto',
        top: '2px',
        color: '#53A6D6'
      }} name="read-only" value={1} readOnly emptyIcon={<StarOutlinedIcon sx={{
        color: 'rgba(83, 166, 214, 0.3)'
      }} />} />,
      value: '0'
    }]
  }, {
    component: DateRangeFilter,
    label: 'Replied Date',
    key: 'replied'
  }, {
    component: MultiSelectFilter,
    label: 'Location',
    key: 'location',
    options: locations.map(location => ({
      label: location.name,
      value: location.id
    }))
  }];
  const headCells = [{
    key: 'customer',
    align: 'left',
    disablePadding: true,
    label: 'Customer',
    component: 'th',
    scope: 'row',
    padding: 'none',
    lableId: `enhanced-table-checkbox-0`,
    displayComponent: ({
      data
    }) => data.customer_first_name || data.customer_last_name ? `${data.customer_first_name || ''} ${data.customer_last_name || ''}` : data.customer_phone,
    props: {}
  }, {
    key: 'score',
    align: 'left',
    disablePadding: true,
    label: 'Score',
    displayComponent: ({
      data
    }) => <Stack direction="row" justifyContent="flex-start" alignItems="center">
          <GoogleIcon sx={{
        fontSize: 16
      }} />
          <Rating name="read-only" value={convertNormalizedScore(data.normalized_score)} readOnly />
        </Stack>
  }, {
    key: 'review_when',
    align: 'left',
    disablePadding: true,
    label: 'Date',
    displayComponent: ({
      data
    }) => <>{moment(data.review_when).format('MMM D, YYYY')}</>
  }, {
    key: 'location_format',
    align: 'left',
    disablePadding: true,
    label: 'Location',
    displayComponent: ({
      data
    }) => <LocationTooltip location={data.location} />
  }, {
    key: 'comment',
    align: 'left',
    disablePadding: true,
    label: 'Review Comment',
    type: 'string',
    displayComponent: ({
      data
    }) => <CustomWidthTooltip tip={data.comment} />
  }, {
    key: 'reply_when',
    align: 'left',
    disablePadding: true,
    label: 'Replied',
    displayComponent: ({
      data,
      index,
      onHover
    }) => <>{renderReplied(data, onHover.row)}</>
  }];
  function onDataChange(newData) {
    setReplyDialog(newData);
  }
  function onSubmitData(body) {
    sendReviewReply(replyDialog.id, body).then(({
      result
    }) => {
      setDataUpdate(dataUpdate + 1);
    });
  }
  function onCustomerDrawer(customer) {
    setReplyDialog(null);
    if (userState.hasPermission('customer.view_customer')) openCustomerDrawer(customer.id);
  }
  return <>
      <CssBaseline />
      <div className="wrapper" style={{
      backgroundColor: '#DAEFFF'
    }}>
        <PageHeaderMui type="Reviews" icon={<Icon name="custom-reviews" size={24} />} searchVal={search} onSearch={searchSegment} />
        <div className="mui-wrapper">
          <EnhancedTable headCells={headCells} search={search} api={getReviews} enableAutoRefresh defaultSort={'-review_when'} locations={locations} onParentChange={dataUpdate} handleRowClick={row => {
          setReplyDialog(row);
        }} filterOptions={filterOptions} filters={filters} setFilters={setFilters} />
        </div>
      </div>

      {replyDialog && <ReplyDialog data={replyDialog} onDataChange={onDataChange} onSubmit={onSubmitData} onCustomerDrawer={onCustomerDrawer} />}
    </>;
};