import React, { Component } from 'react';
import { func, object, shape, string, bool } from 'prop-types';
import { a, m } from 'kremling';
import { get } from 'lodash';
import { Button } from 'components/button/button.component';
import { Dropdown } from 'components/dropdown/dropdown.component';
import { Icon } from 'components/icon/icon.component';
import { userState } from 'shared/user-state';
import { getForms } from 'shared/forms.api';
import { DynamicTypeAhead } from '../../../../components/dynamic-type-ahead/dynamic-type-ahead.component';
export class TriggerWebForm extends Component {
  static propTypes = {
    update: func,
    node: object,
    data: shape({
      name: string,
      use_form: bool,
      formId: string,
      uuid: string
    })
  };
  state = {
    forms: []
  };
  componentDidMount() {
    getForms({
      company: userState.getAsCompanyId(),
      limit: 1000
    }).then(({
      data
    }) => {
      this.setState({
        forms: data.results
      });
    });
  }
  onUseFormChange = use_form => {
    this.props.update({
      use_form
    });
  };
  onFormIdChange = formId => {
    this.props.update({
      formId
    });
  };
  render() {
    const {
      uuid,
      formId,
      use_form
    } = this.props.data;
    const {
      forms
    } = this.state;
    const selectedForm = forms.find(form => form.id === formId);
    return <div className="pt-md">
        <div className="toggler toggler--block mb-md">
          <Button className={m('active', use_form)} onClick={() => this.onUseFormChange(true)}>
            Choose Form
          </Button>
          <Button className={m('active', !use_form)} onClick={() => this.onUseFormChange(false)}>
            Custom
          </Button>
        </div>

        {!use_form && <>
            <div className="form-group">
              <label>Instructions</label>
              <p>
                Set up a form to <code>POST</code> to the following url:
              </p>
              <a href={`${API_URL}/form/${uuid}`} target="_blank">
                {API_URL}/form/{uuid}
              </a>
            </div>
            <div className="form-group">
              <label>Allowed Body Properties</label>
              <ul>
                <li><code>first_name</code></li>
                <li><code>last_name</code></li>
                <li><code>email</code> (required)</li>
                <li><code>address1</code></li>
                <li><code>address2</code></li>
                <li><code>city</code></li>
                <li><code>state</code></li>
                <li><code>zip_code</code></li>
                <li><code>country</code></li>
                <li><code>entity_data</code></li>
              </ul>
            </div>
          </>}
        {use_form && <div>
            <div className="mb-md">
            <DynamicTypeAhead getItems={getForms} getItemsFilters={{
            company: userState.getAsCompanyId()
          }} placeholder={selectedForm ? `${selectedForm.name}` : "Select a form"} displayProperty="name" keyProperty="id" value={selectedForm ? selectedForm.id : null} onChange={form => {
            this.onFormIdChange(form ? form.id : null);
          }} />  
            </div>
          </div>}
      </div>;
  }
}