import React, { useState, useEffect } from 'react';
import { cloneDeep } from 'lodash';
import { Scoped } from 'kremling';
import moment from 'moment';
import { Icon } from 'components/icon/icon.component';
import { Button } from 'components/button/button.component';
import { userState } from 'shared/user-state';
import { Loader } from 'components/loader/loader.component';
import { PageHeader } from 'components/page-header/page-header';
import { Save } from 'components/save/save.component';
import { getCompanyFields, getAllCompanyFields, patchCompany, refreshAllCompanyFields, checkCompanyFields } from 'shared/common.api';
import { toasterService } from 'components/toaster/toaster-service';
import { getCompany } from '../../shared/common.api';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { RenameModelModal } from './rename-model-modal.component';
import { CopyFieldsModal } from './copy-fields-modal.component';
import { AddModelModal } from './add-model-modal.component';
import { AddMissingFieldModal } from './add-missing-field-modal.component';
import { EditFieldModal } from './edit-field-modal.component';
import { isFixed, sortFields } from './utils';
import styles from './data-fields.styles.scss';
export const DataFields = () => {
  const [company, setCompany] = useState({});
  const [fields, setFields] = useState([]);
  const [allFields, setAllFields] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(0);
  const [originalRefreshTime, setOriginalRefreshTime] = useState();
  const [isRefreshing, setIsRefreshing] = useState(false);

  // RenameModelModal
  const [modelToRename, setModelToRename] = useState();

  // CopyFieldsModal
  const [showCopyFieldsModal, setShowCopyFieldsModal] = useState(false);

  // AddModelModal
  const [showAddModelModal, setShowAddModelModal] = useState(false);
  const [missingModels, setMissingModels] = useState([]);

  // AddMissingFieldModal
  const [modelToAddFields, setModelToAddFields] = useState();
  const [modelFieldsMissing, setModelFieldsMissing] = useState([]);

  // EditFieldModal
  const [fieldToEdit, setFieldToEdit] = useState();
  const [fieldToEditModelID, setFieldToEditModelID] = useState();
  useEffect(() => {
    /**
     * Load company fields on mount
     */
    const companyId = userState.getAsCompanyId();
    Promise.all([getCompany(companyId), getCompanyFields(companyId), getAllCompanyFields(companyId)]).then(([{
      data: company
    }, {
      data: fields
    }, {
      data: allFields
    }]) => {
      setIsLoading(false);
      setCompany(company);
      setFields(fields);
      setAllFields(allFields);
    });
  }, []);
  useEffect(() => {
    /**
     * Figure out which models are missing when the fields change
     */

    const ids = fields.map(f => f.id);
    const missingModels = allFields.filter(f => ids.indexOf(f.id) === -1);
    setMissingModels(missingModels);
  }, [fields, allFields, setMissingModels]);
  useEffect(() => {
    /**
     * Figure out which fields are missing after we select a model
     */

    if (!modelToAddFields) {
      return;
    }
    const allFieldsModel = allFields.find(f => f.id === modelToAddFields.id);
    const ids = modelToAddFields.fields.map(f => f.id);
    const missingFields = allFieldsModel.fields.filter(f => ids.indexOf(f.id) === -1);
    setModelFieldsMissing(missingFields);
  }, [modelToAddFields, allFields, setModelFieldsMissing]);
  useEffect(() => {
    if (isRefreshing) {
      /**
       * Check the company fields to see if we're done updating
       */
      const checkRefresh = setInterval(() => {
        getCompany(company.id).then(({
          data: company
        }) => {
          if (originalRefreshTime === company.all_fields_cached_when) {
            // Company field time has not changed so return
            return;
          }

          // Company fields changed so update them now!
          getAllCompanyFields(company.id).then(({
            data: allFields
          }) => {
            setIsRefreshing(false);
            setOriginalRefreshTime(null);
            setCompany(company);
            setAllFields(allFields);
            toasterService.success('Finished refreshing all company fields');
          });
        });
      }, 5000);
      return () => clearInterval(checkRefresh);
    }
  }, [isRefreshing, originalRefreshTime]);
  const saveFields = newFields => {
    /**
     * Wrapper for saving fields to API with a count for how many saves are still happening.
     * TODO convert this to debouncing.
     */
    // sort fields
    newFields = sortFields(newFields);
    setFields(newFields);
    setIsSaving(isSaving => isSaving + 1);
    return patchCompany(company.id, {
      fields: newFields
    }).then(() => setIsSaving(isSaving => isSaving - 1));
  };
  const refreshFields = () => {
    /**
     * Trigger a refresh of the company fields
     */
    refreshAllCompanyFields(company.id).then(() => {
      toasterService.success('Attempting to refresh all company fields. This may take a few minutes');
      setIsRefreshing(true);
      setOriginalRefreshTime(company.all_fields_cached_when);
    }).catch(() => toasterService.error('There was an error while attempting to refresh all company fields'));
  };
  const cloneFields = companyId => {
    /**
     * Callback when the user confirms they want to clone fields from a company
     */
    return getCompanyFields(companyId).then(({
      data: newFields
    }) => saveFields(newFields)).then(() => setShowCopyFieldsModal(false));
  };
  const addModel = modelID => {
    /**
     * Add selected model to data fields
     */
    const newFields = cloneDeep(fields);
    const model = allFields.find(m => m.id === modelID);
    newFields.push(model);
    return saveFields(newFields);
  };
  const addField = (modelID, field) => {
    /**
     * Add field to model
     */
    const newFields = cloneDeep(fields);
    const model = newFields.find(m => m.id === modelID);
    if (!model.fields.find(f => f.id === field.id)) {
      model.fields.push(field);
    }
    const newMissingFields = cloneDeep(modelFieldsMissing).filter(f => f.id !== field.id);
    setModelFieldsMissing(newMissingFields);
    return saveFields(newFields);
  };
  const renameModal = newName => {
    /**
     * Callback from when modal rename is done
     */
    const newFields = cloneDeep(fields);
    const model = newFields.find(m => m.id === modelToRename.id);
    model.name = newName;
    setModelToRename(undefined);
    return saveFields(newFields);
  };
  const onDragEnd = (event, modelID) => {
    /**
     * When a user releases from dragging and dropping reorder the model
     */
    if (!event.destination) {
      // Item dropped outside of the model area
      return;
    }
    const newFields = cloneDeep(fields);
    const model = newFields.find(m => m.id === modelID);
    const filteredFields = model.fields.filter(f => !isFixed(modelID, f.id));
    const from = model.fields.findIndex(f => f.id === filteredFields[event.source.index].id);
    const to = model.fields.findIndex(f => f.id === filteredFields[event.destination.index].id);
    model.fields.splice(to, 0, model.fields.splice(from, 1)[0]);
    return saveFields(newFields);
  };
  const deleteModel = modelID => {
    /**
     * Check if this model is being used on the company. If it's not then remove it from the company
     */
    checkCompanyFields(company.id, {
      model: modelID
    }).then(({
      data
    }) => {
      if (data.journeys.length) {
        return toasterService.error(`Cannot remove model. It's currently being used in the journeys: ${data.journeys.join(', ')}`);
      }
      if (data.segments.length) {
        return toasterService.error(`Cannot remove model. It's currently being used in the segments: ${data.segments.join(', ')}`);
      }
      const newFields = cloneDeep(fields);
      const index = newFields.findIndex(model => model.id == modelID);
      newFields.splice(index, 1);
      saveFields(newFields);
    });
  };
  const removeModelField = (modelID, fieldID) => {
    /**
     * After checking if a field is being used anywhere, remove it from the model
     */

    checkCompanyFields(company.id, {
      model: modelID,
      field: fieldID
    }).then(({
      data
    }) => {
      if (data.journeys.length) {
        return toasterService.error(`Cannot remove field. It's currently being used in the journeys: ${data.journeys.join(', ')}`);
      }
      if (data.segments.length) {
        return toasterService.error(`Cannot remove field. It's currently being used in the segments: ${data.segments.join(', ')}`);
      }
      const newFields = cloneDeep(fields);
      const model = newFields.find(m => m.id === modelID);
      model.fields = model.fields.filter(f => f.id !== fieldID);
      return saveFields(newFields);
    });
  };
  const updateField = (modelID, field) => {
    /**
     * Update field on a given model
     */

    const newFields = cloneDeep(fields);
    const model = newFields.find(m => m.id === modelID);
    const index = model.fields.findIndex(f => f.id === field.id);
    model.fields[index] = field;
    return saveFields(newFields);
  };
  return <Scoped css={styles}>
      <div className="wrapper">
        {isLoading ? <>
            <PageHeader name="Company Data Fields" />
            <Loader overlay />
          </> : <>
            <PageHeader name={`${company.name} Fields`} actions={userState.hasPermission('organization.change_company') && <>
                    <Save saving={isSaving > 0} />
                    <div>
                      Last Refreshed:{' '}
                      {company.all_fields_cached_when ? moment(company.all_fields_cached_when).format('MMM D, YYYY HH:mm') : 'Never'}
                    </div>
                    <Button disabled={isRefreshing} actionType="primary" icon="fa-regular-sync-alt" onClick={refreshFields} />
                    {userState.state.user.companies.length > 1 && <Button actionType="primary" icon="fa-regular-clone" onClick={() => setShowCopyFieldsModal(true)} />}
                    <Button disabled={missingModels.length === 0} actionType="primary" icon="fa-regular-plus" onClick={() => setShowAddModelModal(true)} />
                  </>} />
            <div className="wrapper-scroll p-md">
              {fields.length && fields.map((model, index) => <div className="integration mb-5" key={model.id}>
                    <div className="integration__header">
                      <div>
                        {model.name} ({model.id})
                      </div>
                      {userState.hasPermission('organization.change_company') && <div>
                          <Button actionType="flat" icon="fa-regular-plus" onClick={() => setModelToAddFields(model)} />
                          <Button actionType="flat" icon="fa-regular-edit" onClick={() => setModelToRename(model)} />
                          <Button actionType="flat" icon="fa-regular-trash" onClick={() => deleteModel(model.id)} />
                        </div>}
                    </div>
                    {model.fields.filter(field => isFixed(model.id, field.id)).map(field => <div className="integration-item" key={field.id}>
                          <div className="integration-item__inner">
                            <div className="integration-title">
                              <strong className="pr-1">{field.name}</strong>(
                              {field.id}) &mdash; {field.type || 'text'}
                            </div>
                            <div className="integration-actions">
                              {userState.hasPermission('organization.change_company') && <>
                                  <Button actionType="flat" icon="fa-regular-edit" onClick={() => {
                      setFieldToEdit(field);
                      setFieldToEditModelID(model.id);
                    }} />
                                  <Button actionType="flat" icon="fa-regular-trash" onClick={() => removeModelField(model.id, field.id)} />
                                </>}
                            </div>
                          </div>
                        </div>)}
                    <DragDropContext onDragEnd={event => onDragEnd(event, model.id)}>
                      <Droppable droppableId="droppable">
                        {provided => <div className="integration__body" {...provided.droppableProps} ref={provided.innerRef}>
                            {model.fields.filter(field => !isFixed(model.id, field.id)).map((field, index) => <Draggable key={field.id} draggableId={field.id} index={index} isDragDisabled={!userState.hasPermission('organization.change_company') || isFixed(model.id, field.id)}>
                                  {provided => <div className="integration-item" key={field.id} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                      <div className="integration-item__inner">
                                        <div className="integration-title">
                                          <strong className="pr-1">
                                            {field.name}
                                          </strong>
                                          ({field.id}) &mdash;{' '}
                                          {field.type || 'text'}
                                        </div>
                                        <div className="integration-actions">
                                          {userState.hasPermission('organization.change_company') && <>
                                              <Button actionType="flat" icon="fa-regular-edit" onClick={() => {
                              setFieldToEdit(field);
                              setFieldToEditModelID(model.id);
                            }} />
                                              <Button actionType="flat" icon="fa-regular-trash" onClick={() => removeModelField(model.id, field.id)} />
                                              {!isFixed(model.id, field.id) && <span style={{
                              marginLeft: '8px'
                            }}>
                                                  <Icon name="fa-solid-bars" fill="#808080" size={14} />
                                                </span>}
                                            </>}
                                        </div>
                                      </div>
                                    </div>}
                                </Draggable>)}
                            {provided.placeholder}
                          </div>}
                      </Droppable>
                    </DragDropContext>
                  </div>)}
            </div>

            <RenameModelModal open={!!modelToRename} modelToRename={modelToRename} onClose={() => setModelToRename(null)} onSubmit={renameModal} />

            <AddModelModal open={!!showAddModelModal} missingModels={missingModels} onClose={() => setShowAddModelModal(false)} addModel={addModel} />

            <CopyFieldsModal open={!!showCopyFieldsModal} onClose={() => setShowCopyFieldsModal(false)} onSubmit={cloneFields} />

            <AddMissingFieldModal open={!!modelToAddFields} model={modelToAddFields} addField={addField} missingFields={modelFieldsMissing} onClose={() => setModelToAddFields(false)} />

            <EditFieldModal open={!!fieldToEdit} field={fieldToEdit} modelID={fieldToEditModelID} onClose={() => {
          setFieldToEdit(null);
          setFieldToEditModelID(null);
        }} onSubmit={updateField} />
          </>}
      </div>
    </Scoped>;
};