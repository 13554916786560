import SimpleState from '@geoctrl/simple-state';
import { cloneDeep } from 'lodash';
import { toasterService } from 'components/toaster/toaster-service';
import { Node } from './node/node';
import { Text } from './text/text';
import { NodeLink } from './node/node-link';
import { allNodes } from './node/node-list';
import { nodesCanConnect, linkExists, buildBackdrop, MODES, emptyState } from './node/node-utils';
import { createNode, removeNode, createLink, removeLink, patchRevision } from '../../shared/common.api';
class JourneyState extends SimpleState {
  constructor(defaultState) {
    super(defaultState);
  }
  populate = (nodes = []) => {
    // build nodes
    nodes.forEach(node => {
      this.addNode(node.name, {
        x: node.x,
        y: node.y
      }, node.id, node.parameters, (node.from_links || []).length > 0);
    });
    // then build links
    nodes.forEach(node => {
      (node.from_links || []).forEach(link => {
        const initNode = this.state.nodes.find(n => n.id === node.id);
        const fromNode = this.state.nodes.find(node => node.id === link.from_node);
        const toNode = this.state.nodes.find(node => node.id === link.to_node);
        this.addLink(fromNode, toNode, link.id, link.on_event, initNode.onEventList, initNode.getEvent, initNode.getEventList);
      });
    });
  };
  populateTexts = (texts = []) => {
    texts.forEach(text => {
      this.addText(text.text, {
        x: text.x,
        y: text.y
      });
    });
  };
  setAnalytics = nodes => {
    this.state.nodes.forEach(node => {
      const data = nodes.find(({
        id
      }) => id === node.id);
      if (data) {
        node.updateStats(data.enter, data.leave);
      } else {
        node.updateStats(0, 0);
      }
    });
  };
  destroy = () => {
    this.state.links.forEach(link => link.willDestroy());
    this.state.texts.forEach(text => text.willDestroy());
    this.state.nodes.forEach(node => node.willDestroy());
    this.set(emptyState);
  };
  reset = () => {
    this.state.nodes.forEach(node => node.willDestroy());
    this.state.links.forEach(link => link.willDestroy());
    this.state.texts.forEach(text => text.willDestroy());
    const {
      revisionId,
      linksContainer,
      nodesContainer,
      textsContainer,
      stage
    } = this.state;
    this.set({
      ...emptyState,
      revisionId,
      linksContainer,
      nodesContainer,
      textsContainer,
      stage
    });
  };

  /**
   * Nodes
   * ---------
   * **/

  addNode = (subType, position, id, parameters, hasLinks) => {
    const item = cloneDeep(allNodes.find(node => node.subType === subType));
    item.defaultParameters = cloneDeep(item.parameters);
    item.parameters = parameters || (item.initParams ? item.initParams() : item.parameters);
    const node = new Node(item, position, hasLinks);
    this.state.nodesContainer.addChild(node.container);
    this.set(({
      nodes
    }) => ({
      nodes: [...nodes, node]
    }));
    if (id) {
      node.id = id;
    } else {
      createNode(node.type, node.subType, node.container.x, node.container.y, this.state.revisionId, item.parameters).then(({
        data
      }) => {
        node.id = data.id;
      });
    }
  };
  removeNode = node => {
    const nodeIndex = this.state.nodes.findIndex(n => n === node);
    this.state.nodesContainer.removeChild(node.container);
    node.willDestroy();
    const nodes = this.state.nodes;
    this.set({
      nodes: [...nodes.slice(0, nodeIndex), ...nodes.slice(nodeIndex + 1)]
    });
    Promise.all(this.removeLinkByNode(node)).then(() => removeNode(node.id));
  };
  sortNodes = node => {
    this.state.nodesContainer.children.sort((a, b) => {
      if (a === node.container) return 1;
      if (b === node.container) return -1;
      return 0;
    });
  };
  invalidNodes = () => {
    return this.state.nodes.filter(node => !node.isValidated());
  };
  onParamEdit = () => {
    this.set({
      onParamEdit: this.state.onParamEdit + 1
    });
  };

  /**
   * Text
   * ---------
   */

  addText = (text, position, autoEditParam = false) => {
    const textObj = new Text(text, position);
    this.state.textsContainer.addChild(textObj.container);
    this.set(({
      texts
    }) => ({
      texts: [...texts, textObj]
    }));
    if (autoEditParam) {
      this.editTextParam(textObj);
    }
  };
  removeText = textToDelete => {
    this.state.textsContainer.removeChild(textToDelete.container);
    textToDelete.willDestroy();
    this.set(({
      texts
    }) => ({
      texts: texts.filter(text => text !== textToDelete)
    }));
    this.saveText();
  };
  saveText = () => {
    const texts = this.state.texts.map(text => ({
      text: text.parameters.text,
      x: text.container.x,
      y: text.container.y
    }));
    patchRevision(this.state.revisionId, {
      meta: {
        texts
      }
    });
  };

  /**
   * Links
   * ---------
   * **/

  addLink = (from = this.state.nodeFrom, to = this.state.nodeTo, id, onEvent, onEventList, getEvent, getEventList) => {
    if (from && to && from !== to && !linkExists(from, to, this.state.links) && nodesCanConnect(from, to)) {
      const link = new NodeLink();
      link.connect(from, to, onEvent);
      this.state.linksContainer.addChild(link.container);
      this.set(({
        links
      }) => ({
        links: [...links, link]
      }));
      if (onEvent && getEvent) {
        getEvent(onEvent).then(event => {
          link.eventText.text = event.toUpperCase();
          link.updateNodes();
        });
      } else if (onEvent && onEventList) {
        const event = onEventList.find(item => item.id === onEvent);
        if (event) {
          link.eventText.text = event.name.toUpperCase();
          link.updateNodes();
        }
      } else if (onEvent) {
        link.eventText.text = onEvent.toUpperCase();
        link.updateNodes();
      }
      if (id) {
        link.id = id;
      } else {
        createLink(from.id, to.id, onEvent).then(({
          data
        }) => {
          link.id = data.id;
          if (!onEvent && (from.onEventList || from.getEventList)) {
            this.endPointLink();
            this.editParam(from, link);
          }
        });
      }
    }
  };
  updateNodeLinks = node => {
    this.state.links.forEach(link => {
      if (link.nodeFrom === node || link.nodeTo === node) {
        link.updateNodes();
      }
    });
  };
  removeLinkByNode = node => {
    const linksToRemove = this.state.links.filter(link => link.nodeFrom === node || link.nodeTo === node);
    this.set(({
      links
    }) => ({
      links: links.filter(link => link.nodeFrom !== node && link.nodeTo !== node)
    }));
    return linksToRemove.map(link => {
      this.state.linksContainer.removeChild(link.container);
      link.willDestroy();
      return removeLink(link.id);
    });
  };
  removeLink = linkToDelete => {
    this.state.linksContainer.removeChild(linkToDelete.container);
    this.set(({
      links
    }) => ({
      links: links.filter(link => link !== linkToDelete)
    }));
    removeLink(linkToDelete.id);
  };
  checkDuplicateLinks = node => {
    const linksEventsFromNode = this.state.links.filter(link => link.nodeFrom.id == node?.id);
    var valueArr = linksEventsFromNode.map(function (item) {
      return item.onEvent;
    });
    var isDuplicate = valueArr.some(function (item, idx) {
      return valueArr.indexOf(item) != idx;
    });
    if (isDuplicate) this.set({
      duplicateNodeWarningModal: true
    });
  };

  /**
   * Point Links
   * ---------
   * **/

  startPointLink = (nodeFrom, x, y) => {
    this.set({
      mode: MODES.POINT_LINK
    });
    this.set({
      nodeFrom
    });
    this.pointLink = new NodeLink(nodeFrom.onEventList);
    this.pointLink.customPath(x, y, x, y);
    this.state.linksContainer.addChild(this.pointLink.container);
  };
  updatePointLink = data => {
    const {
      x,
      y
    } = data.getLocalPosition(this.state.linksContainer);
    this.pointLink.updatePointLink(x, y);
  };
  endPointLink = () => {
    if (this.state.mode !== MODES.POINT_LINK) return;
    this.set({
      mode: MODES.CANVAS
    });
    this.state.linksContainer.removeChild(this.pointLink.container);
  };
  pointLinkMouseOver = nodeTo => {
    this.set({
      nodeTo
    });
  };
  pointLinkMouseOut = () => {
    this.set({
      nodeTo: null
    });
  };

  /**
   * Mode
   * ---------
   */

  editParam = (node, link) => {
    if (node && !node.id) {
      toasterService.warning("Node isn't ready yet. Try again in a moment.");
      return;
    }
    this.lastMode = this.state.mode;
    this.set({
      mode: MODES.EDIT_PARAM,
      nodeEdit: node,
      linkEdit: link
    });
    this.backdrop = buildBackdrop(this.state.canvasSize.width * 5, this.state.canvasSize.height * 5);
    this.state.nodesContainer.addChild(this.backdrop);
    this.sortNodes(node);
  };
  editTextParam = text => {
    this.lastMode = this.state.mode;
    this.set({
      mode: MODES.EDIT_PARAM,
      nodeEdit: text,
      linkEdit: null
    });
    this.backdrop = buildBackdrop(this.state.canvasSize.width * 5, this.state.canvasSize.height * 5);
    this.state.textsContainer.addChild(this.backdrop);
  };
  closeParam = () => {
    this.state.nodesContainer.removeChild(this.backdrop);
    this.backdrop.destroy();
    this.onParamEdit();
    this.set({
      mode: this.lastMode,
      nodeEdit: null,
      editEvent: false
    });
  };
  openCustomersList = (node, event) => {
    if (node && !node.id) {
      toasterService.warning("Node isn't ready yet. Try again in a moment.");
      return;
    }
    this.set({
      customersListNodeId: node.id
    });
    this.backdrop = buildBackdrop(this.state.canvasSize.width * 5, this.state.canvasSize.height * 5);
    this.state.nodesContainer.addChild(this.backdrop);
    this.sortNodes(node);
  };
  closeCustomersList = () => {
    this.state.nodesContainer.removeChild(this.backdrop);
    this.backdrop.destroy();
    this.onParamEdit();
    this.set({
      customersListNodeId: null
    });
  };
}
const journeyState = new JourneyState(emptyState);
export { journeyState };