import React from 'react';
import moment from 'moment-timezone';
import { Scoped } from 'kremling';
import styles from './index.styles.scss';
export function TimelineComponent(props) {
  // These props are a lazy way to pass data around. We need to optimize this later.
  const {
    customerTimeline,
    goToSchedule,
    goToTransaction
  } = props;
  const clickRow = ref => {
    if (ref.type === 'Schedule') {
      goToSchedule(ref.id);
    }
    if (ref.type === 'Transaction') {
      goToTransaction(ref.id);
    }
  };
  return <Scoped css={styles}>
      <div className="drawer__body" style={{
      height: 'calc(100vh - 9rem)',
      overflowY: 'auto'
    }}>
        <table className="table-list mb-5">
          <thead>
            <tr>
              <th style={{
              width: '170px'
            }}>Date</th>
              <th style={{
              width: '100px'
            }}>Type</th>
              <th style={{
              width: '90px'
            }}>&nbsp;</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {customerTimeline.map(ref => <tr key={ref.id} onClick={() => clickRow(ref)}>
                <td>{moment.utc(ref.created_when).local().format('MMM D, YYYY h:mm A')}</td>
                <td>{ref.type}</td>
                <td>{ref.detail1}</td>
                <td>{ref.detail2}</td>
              </tr>)}
            {!customerTimeline.length && <tr>
                <td colSpan="4"><i>Customer has no timeline</i></td>
              </tr>}
          </tbody>
        </table>
      </div>
    </Scoped>;
}