import React, { Component } from 'react';
import { func, object, shape, string } from 'prop-types';
import { userState } from '../../../../shared/user-state';
import { getCompanyFields, getEmailTemplates } from 'shared/common.api';
import { DynamicTypeAhead } from 'components';
export class ActionNotifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: []
    };
  }
  static propTypes = {
    update: func,
    node: object,
    data: shape({
      method: string,
      message: string,
      from: string,
      to: string,
      message_type: string,
      email_template: string
    })
  };
  componentDidMount() {
    getCompanyFields(userState.state.asCompany.id).then(({
      data
    }) => this.setState({
      fields: data
    }));
  }
  onMessageChange = e => {
    this.props.update({
      message: e.target.value
    });
  };
  onMethodChange = e => {
    this.props.update({
      method: e.target.value
    });
  };
  onFromChange = e => {
    this.props.update({
      from: e.target.value
    });
  };
  onToChange = e => {
    this.props.update({
      to: e.target.value
    });
  };
  onMessageTypeChange = e => {
    this.props.update({
      message_type: e.target.value
    });
  };
  insertData = e => {
    const {
      message
    } = this.props.data;
    this.props.update({
      message: `${message}{{${e.target.value}}}`
    });
  };
  getId = (groupId, fieldId) => {
    return `${groupId}.${fieldId.replace(/__/g, '.')}`;
  };
  render() {
    const {
      message,
      method,
      from,
      to,
      message_type = 'custom',
      email_template
    } = this.props.data;
    const {
      fields
    } = this.state;
    return <div>
        <div className="form-group">
          <label>Send By</label>
          <select className="form-control" onChange={this.onMethodChange} value={method || ''}>
            <option value="sms">SMS</option>
            <option value="email">Email</option>
          </select>
        </div>
        {method === 'email' && message_type === 'custom' && <div className="form-group">
            <label>Send From</label>
            <p>Enter a single email address here.</p>
            <input type="text" className="form-control" onChange={this.onFromChange} value={from || ''} />
          </div>}
        <div className="form-group">
          <label>Send To</label>
          <p>Enter as many as you want separated by a semi-colon.</p>
          <input type="text" className="form-control" onChange={this.onToChange} value={to || ''} />
        </div>

        {method === 'email' && <div className="form-group">
            <label>Message Type</label>
            <select className="form-control" value={message_type} onChange={this.onMessageTypeChange}>
              <option value="custom">Custom Message</option>
              <option value="template">Template</option>
            </select>
          </div>}

        {method === 'email' && message_type === 'template' && <div className="form-group">
            <label>Template</label>
            <DynamicTypeAhead getItems={getEmailTemplates} getItemsFilters={{
          company: userState.getAsCompanyId(),
          archived: false,
          published: true
        }} placeholder="Choose Published Email Template" displayProperty="name" keyProperty="id" value={email_template} onChange={val => this.props.update({
          email_template: val ? val.id : null
        })} />
          </div>}

        {(method !== 'email' || message_type !== 'template') && <div className="form-group">
            <div className="d-flex">
              <div className="flex-grow">
                <label>Message</label>
              </div>
              <div>
                <select className="form-control" style={{
              height: '2rem'
            }} onChange={this.insertData} value={''}>
                  <option>Insert Dynamic Data</option>
                  {fields.map(field => <optgroup key={field.id} label={field.name}>
                      {field.fields.map(subfield => <option key={subfield.id} value={this.getId(field.id, subfield.id)}>
                          {subfield.name}
                        </option>)}
                    </optgroup>)}
                </select>
              </div>
            </div>
            <textarea cols="3" className="form-control" style={{
          height: '100%'
        }} onChange={this.onMessageChange} value={message || ''}></textarea>
          </div>}
      </div>;
  }
}