import { Container, Graphics, Sprite, TextStyle, Text } from 'pixi.js';
const radius = 10;
const size = 20;
export class NodeNumber {
  constructor(text, color) {
    this.color = color;
    this.container = new Container();
    this.build(text);
  }
  update = text => {
    this.container.removeChild(this.text);
    this.container.removeChild(this.shape);
    this.build(text);
  };
  build = text => {
    this.text = this.buildText(text);
    this.text.y = 3;
    this.text.x = text.length > 1 ? 5 : 7;
    this.shape = this.buildSquare(text.length > 1 ? this.text.width + 10 : size);
    this.container.addChild(this.shape);
    this.container.addChild(this.text);
  };
  buildSquare = width => {
    const shape = new Graphics();
    shape.beginFill(`0x${this.color}`);
    shape.moveTo(0, radius);
    shape.lineTo(0, size - radius);
    shape.arcTo(0, size, radius, size, radius);
    shape.lineTo(size - radius, size);
    shape.arcTo(width, size, width, size - radius, radius);
    shape.lineTo(width, radius);
    shape.arcTo(width, 0, size - radius, 0, radius);
    shape.lineTo(radius, 0);
    shape.arcTo(0, 0, 0, radius, radius);
    shape.endFill();
    return shape;
  };
  buildText = number => {
    const style = new TextStyle({
      wordWrap: true,
      align: 'center',
      fontSize: 12,
      fill: '#ffffff'
    });
    return new Text(number, style);
  };
}