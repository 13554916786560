import React, { Component } from 'react';
import { func, object, shape, string, number } from 'prop-types';
import { getDripDropCampaigns, createDripDropCampaign } from '../../../integrations/integrations.resource';
import { userState } from 'shared/user-state';
import { DynamicTypeAhead } from 'components/dynamic-type-ahead/dynamic-type-ahead.component';
import { Button } from 'components/button/button.component';
import { Loader } from 'components/loader/loader.component';
import { toasterService } from 'components/toaster/toaster-service';
import { getLocations } from 'shared/common.api';
const Styles = {
  textHelper: {
    textTransform: 'none',
    padding: '8px',
    fontWeight: '400'
  },
  createButton: {
    display: 'block',
    float: 'right',
    paddingTop: '2px'
  }
};
export class ActionDripDrop extends Component {
  static propTypes = {
    update: func,
    node: object,
    data: shape({
      campaign_id: string,
      campaign_name: string
    })
  };
  createNewSegment() {
    const {
      newCampaignName
    } = this.state;
    this.setState({
      loadingCreateCampaign: true
    });
    createDripDropCampaign({
      company: userState.getAsCompanyId(),
      name: newCampaignName
    }).then(({
      data
    }) => {
      this.props.update({
        campaign_id: data.id,
        campaign_name: data.name
      });
      this.setState({
        newCampaignName: '',
        isCreateNewCampaign: false,
        loadingCreateCampaign: false
      });
    }).catch(() => {
      toasterService.error('Unable to create campaign.');
      this.setState({
        loadingCreateCampaign: false
      });
    });
  }
  state = {
    isCreateNewCampaign: false,
    newCampaignName: '',
    loadingCreateCampaign: false
  };
  render() {
    const {
      campaign_id
    } = this.props.data;
    const {
      isCreateNewCampaign,
      newCampaignName,
      loadingCreateCampaign
    } = this.state;
    return <div>
        <div className="form-group">
          {!isCreateNewCampaign && <>
              <label>Campaign</label>
              <DynamicTypeAhead getItems={getDripDropCampaigns} getItemsFilters={{
            company: userState.getAsCompanyId()
          }} placeholder="Choose DripDrop Campaign" displayProperty="name" keyProperty="id" value={campaign_id} onChange={campaign => this.props.update({
            campaign_id: campaign ? campaign.id : null,
            campaign_name: campaign ? campaign.name : null
          })} />
              <label style={Styles.textHelper}>
                Or Create a{' '}
                <a onClick={() => this.setState({
              isCreateNewCampaign: true
            })}>
                  New Campaign
                </a>
              </label>
            </>}

          {isCreateNewCampaign && <>
              <label>New DripDrop Campaign</label>
              <input type="text" className="form-control mb-2" name="name" value={newCampaignName || ''} placeholder="Campaign Name" onChange={e => this.setState({
            newCampaignName: e.target.value
          })} />
              <div style={Styles.createButton}>
                <Button actionType="primary" className="mt-2" onClick={() => this.createNewSegment()} disabled={loadingCreateCampaign || newCampaignName === ''}>
                  {loadingCreateCampaign ? <Loader style={{
                float: 'right'
              }} size="sm" /> : 'Create New Campaign'}
                </Button>
              </div>
              <div style={{
            clear: 'both'
          }} />
            </>}
        </div>
      </div>;
  }
}
// TODO ALLOW CREATION OF NEW CAMPAIGN