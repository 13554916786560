import React, { createRef, useEffect, useState, useCallback, useRef } from 'react';
import { Scoped, a, m } from 'kremling';
import { isEqual } from 'lodash';
import styles from './dynamic-type-ahead.styles.scss';
import { Button } from '../button/button.component';
import { Icon } from '../icon/icon.component';
import Tooltip from 'rc-tooltip';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Divider from '@mui/material/Divider';
import { withStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';
export const DynamicTypeAheadMui = props => {
  const contentEl = createRef();
  const isScrollLoading = useRef(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenAbove, setIsOpenAbove] = useState(false);
  const [search, setSearch] = useState('');
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [items, setItems] = useState([]);
  const [limit, setLimit] = useState(999);
  const [offset, setOffset] = useState(0);
  const [nextPage, setNextPage] = useState(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const [options, setOptions] = useState([]);
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  useEffect(() => {
    const defaultFilters = props.getItemsFilters || {};
    props.getItems({
      ...defaultFilters,
      search,
      limit,
      offset
    }).then(({
      data
    }) => {
      setOptions(data.results);
    });
  }, [search]);
  return <div>
        <Autocomplete freeSolo value={value} onChange={(event, newValue) => {
      setValue(newValue);
      props.onChange(newValue);
    }} inputValue={inputValue} onInputChange={(event, newInputValue) => {
      setSearch(newInputValue);
      setInputValue(newInputValue);
    }} options={options} getOptionLabel={option => option[props.displayProperty]} sx={{
      width: 300
    }} renderInput={params => <TextField {...params} label="Select Template" />} renderOption={(propsOpt, option) => {
      return <li {...propsOpt} key={option.id}>
                {option[props.displayProperty]}
              </li>;
    }} />
    </div>;
};