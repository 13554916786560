import React, { Component } from 'react';
import { func, array } from 'prop-types';
import { Scoped, k } from 'kremling';
import { get } from 'lodash';
import { TypeAhead } from 'components/type-ahead/type-ahead.component';
import { api } from 'shared/api';
import { userState } from 'shared/user-state';
import { Loader } from 'components/loader/loader.component';
const convertName = label => {
  return label.replace(/[\W_]+/g, "_").toLowerCase();
};
export class FormMapping extends Component {
  static propTypes = {
    fields: array,
    onChange: func
  };
  state = {
    customerFields: [],
    loadingFields: true
  };
  componentDidMount() {
    // TODO use the src\shared\common.api.js version
    api.get(`/companies/${userState.getAsCompanyId()}/fields`).then(({
      data
    }) => {
      const customerModel = data.find(model => model.id === 'customer');
      this.setState({
        customerFields: get(customerModel, 'fields') || [],
        loadingFields: false
      });
    }).catch(err => {
      this.setState({
        customerFields: [],
        loadingFields: false
      });
    });
  }
  onFieldUpdate = newField => {
    const {
      fields
    } = this.props;
    this.props.onChange(fields.map(f => {
      if (f.name === newField.name) {
        f = newField;
      }
      return f;
    }));
  };
  render() {
    const {
      fields
    } = this.props;
    const {
      customerFields,
      loadingFields
    } = this.state;
    return <Scoped css={css}>
        {loadingFields && <div className="form-fields-loading">
            <Loader overlay />
          </div>}
        {!loadingFields && <div className="form-map-table-container">
            <table className="form-map-table">
              <thead>
              <tr>
                <th>Form Field</th>
                <th>Cinch Field</th>
              </tr>
              </thead>
              <tbody>
              {fields && fields.filter(f => f.name && f.label).map((field, i) => <tr key={i}>
                  <td>{field.label}</td>
                  <td>
                    <TypeAhead items={[...customerFields, {
                  id: `entity_data__${convertName(field.label)}`,
                  name: `Entity Data > ${field.label}`,
                  type: 'text'
                }]} displayProperty="name" keyProperty="id" value={get(customerFields.find(f => f.id === field.field), 'name') || ''} onChange={newField => this.onFieldUpdate({
                  ...field,
                  field: get(newField, 'id', null)
                })} />
                  </td>
                </tr>)}
              </tbody>
            </table>
          </div>}
      </Scoped>;
  }
}
const css = {
  styles: `[kremling="i27"] body,body[kremling="i27"] {
  --color-primary: #487aae;
  --color-highlight: #e7efff;
  --color-accent: #354052;
  --color-success: #73b56e;
  --color-warning: #f4b707;
  --color-danger: #df5651;
  --color-grey-10: #f9f9f9;
  --color-grey-25: #f3f3f3;
  --color-grey-50: #e9e9e9;
  --color-grey-75: #e3e3e3;
  --color-grey-100: #d3d3d3;
  --color-grey-200: #c3c3c3;
  --color-grey-300: #b3b3b3;
  --color-grey-400: #a3a3a3;
  --color-grey-500: #808080;
  --color-grey-600: #707070;
  --color-grey-700: #606060;
  --color-grey-800: #505050;
  --color-grey-900: #404040;
  --base-font-family: Roboto, Helvetica, Arial, sans-serif;
  --base-font-size: 1.4rem;
  --base-font-color: #404040;
  --base-font-weight: 400;
  --base-line-height: 1.4;
  --box-shadow-1: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.12);
  --box-shadow-2: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-3: 0 10px 20px rgba(0, 0, 0, 0.17), 0 6px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-4: 0 14px 28px rgba(0, 0, 0, 0.13), 0 10px 10px rgba(0, 0, 0, 0.1);
  --box-shadow-5: 0 19px 38px rgba(0, 0, 0, 0.18), 0 15px 12px rgba(0, 0, 0, 0.1);
  --base-border-radius: 0.4rem;
}

[kremling="i27"] .form-fields-loading,[kremling="i27"].form-fields-loading {
  height: 30rem;
  position: relative;
}

[kremling="i27"] .form-map-table-container,[kremling="i27"].form-map-table-container {
  background-color: var(--color-grey-25);
  border-radius: var(--base-border-radius);
  border: solid 0.1rem var(--color-grey-100);
}

[kremling="i27"] .form-map-table,[kremling="i27"].form-map-table {
  width: 100%;
  border-collapse: collapse;
}

[kremling="i27"] .form-map-table tr,[kremling="i27"].form-map-table tr {
  border-bottom: solid 0.1rem var(--color-grey-100);
}

[kremling="i27"] .form-map-table tbody>tr:last-child,[kremling="i27"].form-map-table tbody>tr:last-child {
  border: none;
}

[kremling="i27"] .form-map-table tr>th,[kremling="i27"] .form-map-table tr>td,[kremling="i27"].form-map-table tr>th,[kremling="i27"].form-map-table tr>td {
  border-right: solid 0.1rem var(--color-grey-100);
  padding: 1rem;
}

[kremling="i27"] .form-map-table tr>th:last-child,[kremling="i27"] .form-map-table tr>td:last-child,[kremling="i27"].form-map-table tr>th:last-child,[kremling="i27"].form-map-table tr>td:last-child {
  border-right: none;
}`,
  id: 'i27',
  namespace: 'kremling'
};