import React, { Component } from 'react';
import { func, object, shape, string } from 'prop-types';
import { userState } from 'shared/user-state';
import { getPhoneNumbers } from 'shared/common.api';
import { StaticTypeAhead } from '../../../../components/static-type-ahead/static-type-ahead.componet';
import utils from '../../../../shared/utils';
export class TriggerSMSOptin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      segmentList: [],
      numbers: []
    };
  }
  static propTypes = {
    update: func,
    node: object,
    data: shape({
      sentTo: string,
      sentToLabel: string
    })
  };
  componentDidMount() {
    getPhoneNumbers({
      company: userState.state.asCompany.id,
      limit: 1000
    }).then(numbers => {
      const numbersWithFormat = numbers.data.results.map(number => {
        let numberFormat = {
          ...number
        };
        numberFormat.phone = utils.formatPhoneNumber(numberFormat.phone);
        return numberFormat;
      });
      this.setState({
        numbers: [{
          id: '',
          phone: 'All'
        }].concat(numbersWithFormat)
      });
    });
  }
  sentToChange = (sentTo, sentToLabel) => {
    this.props.update({
      sentTo,
      sentToLabel
    });
  };
  render() {
    const {
      sentTo
    } = this.props.data;
    const {
      numbers
    } = this.state;
    const selectedSentTo = numbers.find(n => n.id === sentTo);
    return <div>
        <div className="form-group">
          <label>Sent To</label>
          <StaticTypeAhead items={numbers} placeholder={selectedSentTo ? selectedSentTo.phone : 'All'} displayProperty="phone" keyProperty="id" value={selectedSentTo ? selectedSentTo.id : ''} onChange={sentTo => sentTo && this.sentToChange(sentTo.id, sentTo.phone)} />
        </div>
      </div>;
  }
}