import React, { Component } from 'react';
import { object } from 'prop-types';
import { Scoped } from 'kremling';
import { PageHeader } from 'components/page-header/page-header';
import { Button } from 'components/button/button.component';
import styles from './segments.styles.scss';
import { userState } from 'shared/user-state';
import { newSegment, getSegmentCustomers, getSegment, editSegment, copySegment, testSegment, segmentConvertToQuery, getJourneys } from '../../shared/common.api';
import { Rules } from 'components/rules/rules.component';
import { Loader } from 'components/loader/loader.component';
import { ModalDialog } from 'components/modal/modal-dialog.component';
import numeral from 'numeral';
import { Icon } from 'components/icon/icon.component';
import Tooltip from 'rc-tooltip';
import { Tags as TagsComponent } from 'components/tags/tags.component';
import { CustomerDrawerContext } from 'context/customer-drawer-context';
const EDIT = 'EDIT';
const CREATE = 'CREATE';
const COPY = 'COPY';
const ASSET_MAX = 10;
const segmentTypeTooltip = <>
    <p>
      Static Segment: Using this option, you will generate a list of customers that currently meet the criteria you set. This list will not
      change unless you add or remove a customer from it (either using a journey or manually in the customer drawer).
    </p>
    <p>
      Dynamic Segment: Using this option, you set the criteria and then each time you either open or use (in a journey or broadcast) this
      segment, it will return all customers who meet the criteria at that time.
    </p>
  </>;
export class EditSegment extends Component {
  static propTypes = {
    location: object
  };
  state = {
    view: window.location.pathname.includes('edit') ? EDIT : window.location.pathname.includes('copy') ? COPY : CREATE,
    segment: {
      company: userState.state.asCompany.id
    },
    segmentError: null,
    pageLoad: false,
    builder: true,
    switchModal: false,
    isSuperUser: userState.state.user.is_superuser,
    testCount: null,
    testLoading: false,
    hasMore: false,
    sampleCustomers: [],
    showSample: false,
    loadingCreateSegment: false,
    tagValue: '',
    journeysTriggered: [],
    journeyTriggersExpanded: false,
    journeysEdited: [],
    journeyEditedExpanded: false,
    showAskMore: false
  };
  static contextType = CustomerDrawerContext;
  componentDidMount() {
    const {
      view
    } = this.state;
    const id = this.props.match.params.id;
    if (view === EDIT) {
      this.setState({
        pageLoad: true
      });
      Promise.all([getSegment(id), getJourneys({
        company: userState.getAsCompanyId(),
        node_segment_triggers: id,
        archived: false
      }), getJourneys({
        company: userState.getAsCompanyId(),
        node_segment_edits: id,
        archived: false
      })]).then(([segmentResult, journeysTriggered, journeysEdited]) => {
        this.setState({
          builder: !!segmentResult.data.segment_builder || !segmentResult.data.segment_query,
          pageLoad: false,
          segment: segmentResult.data,
          journeysEdited: journeysEdited.data.results,
          journeysTriggered: journeysTriggered.data.results
        });
      });
    } else if (view === COPY) {
      getSegmentCustomers(this.props.match.params.id).then(({
        data
      }) => {
        if (data) this.setState({
          customers: data
        });
      });
      getSegment(this.props.match.params.id).then(({
        data
      }) => {
        if (data) this.setState({
          builder: true,
          segment: data,
          company: data.company
        });
      });
    }
    this.setState({
      company: userState.state.asCompany.id
    });
  }
  updateQuery = segment_builder => {
    this.setState(prevState => ({
      segment: {
        ...prevState.segment,
        segment_builder
      }
    }));
  };
  updateSegment = (key, val) => {
    this.setState(prevState => ({
      segment: {
        ...prevState.segment,
        [key]: val
      }
    }));
  };
  switchToAdvancedMode = () => {
    const {
      segment
    } = this.state;
    let promise = Promise.resolve();
    if (segment.segment_builder) {
      promise = promise.then(() => {
        return segmentConvertToQuery({
          segment_builder: segment.segment_builder,
          company: segment.company,
          included: segment.included
        }).then(({
          data
        }) => data.query);
      });
    }
    promise.then(query => {
      this.setState(prevState => ({
        segment: {
          ...prevState.segment,
          segment_builder: null,
          segment_query: segment.segment_query || query
        },
        builder: false
      }));
    });
  };
  fullCount = () => {
    this.setState({
      testLoading: true,
      testCount: null,
      testError: null
    });
    testSegment({
      ...this.state.segment,
      full: true
    }).then(({
      data
    }) => {
      this.setState({
        testLoading: false,
        testCount: `${numeral(data.count).format('0,0')} records found`,
        hasMore: false
      });
    });
  };
  handleTest = () => {
    this.setState({
      testLoading: true,
      testCount: null,
      testError: null
    });
    testSegment(this.state.segment).then(({
      data
    }) => {
      const {
        segment
      } = this.state;
      if (segment.included) {
        let customerCount = data.customer_count;
        if (data.customer_count > 1000) {
          customerCount = 1000;
        }
        this.setState({
          testLoading: false,
          sampleCustomers: data.customer_sample,
          testCount: `${numeral(customerCount).format('0,0')}${data.included_count > 1000 ? '+' : ''} customers, ${data.included_count > 1000 ? '1,000+' : numeral(data.included_count).format('0,0')} ${segment.included}s found`,
          hasMore: data.included_count > 1000
        });
      } else {
        this.setState({
          testLoading: false,
          sampleCustomers: data.customer_sample,
          testCount: `${data.customer_count > 1000 ? '1,000+' : numeral(data.customer_count).format('0,0')} customers found`,
          hasMore: data.customer_count > 1000
        });
      }
    }).catch(e => {
      let testError = 'There was an error in the query.  Please double check your query.';
      if (e.response && Array.isArray(e.response.data) && e.response.data.length) {
        testError = e.response.data[0];
      }
      this.setState({
        testLoading: false,
        testCount: null,
        testError,
        hasMore: false,
        sampleCustomers: []
      });
    });
  };
  addField = () => {
    const segment = JSON.parse(JSON.stringify(this.state.segment));
    if (!segment.fields) {
      segment.fields = [];
    }
    segment.fields.push({
      variable: '',
      label: '',
      type: 'text',
      options: [],
      description: ''
    });
    this.setState({
      segment
    });
  };
  updateField = (key, val, index) => {
    const segment = JSON.parse(JSON.stringify(this.state.segment));
    if (key === 'variable' && segment.values && segment.values[segment.fields[index].variable]) {
      // Change variable key name to match
      segment.values[val] = segment.values[segment.fields[index].variable];
      delete segment.values[segment.fields[index].variable];
    }
    segment.fields[index][key] = val;
    if (key === 'type' && val === 'picklist') {
      segment.fields[index].options = [''];
    } else if (key === 'type' && val !== 'picklist') {
      segment.fields[index].options = [];
    }
    this.setState({
      segment
    });
  };
  updateValue = (key, val) => {
    const segment = JSON.parse(JSON.stringify(this.state.segment));
    if (!segment.values) {
      segment.values = {};
    }
    segment.values[key] = val;
    this.setState({
      segment
    });
  };
  addPicklistOption = index => {
    const segment = JSON.parse(JSON.stringify(this.state.segment));
    segment.fields[index].options.push('');
    this.setState({
      segment
    });
  };
  updateOption = (fieldIndex, optionIndex, val) => {
    const segment = JSON.parse(JSON.stringify(this.state.segment));
    segment.fields[fieldIndex].options[optionIndex] = val;
    this.setState({
      segment
    });
  };
  removeField = index => {
    const segment = JSON.parse(JSON.stringify(this.state.segment));
    if (segment.values && segment.values[segment.fields[index].variable]) {
      // Remove old value
      delete segment.values[segment.fields[index].variable];
    }
    segment.fields.splice(index, 1);
    this.setState({
      segment
    });
  };
  deleteTag = i => {
    const {
      segment
    } = this.state;
    let deleteTags = segment.tags;
    deleteTags.splice(i, 1);
    let newTags = segment.tags.filter(t => !t.includes(segment.tags[i]));
    this.setState({
      segment: {
        ...segment,
        tags: newTags
      }
    });
  };
  addNewTag = newTag => {
    newTag = newTag.toUpperCase();
    const {
      segment
    } = this.state;
    this.setState({
      tagValue: ''
    });
    if (segment.tags) {
      if (!segment.tags.includes(newTag)) this.setState({
        segment: {
          ...segment,
          tags: [...segment.tags, newTag]
        }
      });
    } else {
      this.setState({
        segment: {
          ...segment,
          tags: [newTag]
        }
      });
    }
  };
  viewCustomer = id => {
    this.setState({
      showSample: false
    });
    this.context.openCustomerDrawer(id);
  };
  render() {
    const {
      segmentError,
      view,
      segment,
      pageLoad,
      builder,
      switchModal,
      isSuperUser,
      testCount,
      testLoading,
      testError,
      hasMore,
      loadingCreateSegment,
      tagValue,
      journeysTriggered,
      journeysEdited,
      journeyTriggersExpanded,
      journeyEditedExpanded,
      showSample,
      showAskMore,
      sampleCustomers
    } = this.state;
    const {} = this.state;
    let pageName;
    if (view === EDIT) {
      pageName = 'Edit Segment';
    } else if (view === COPY) {
      pageName = 'Copy Segment';
    } else {
      pageName = 'New Segment';
    }
    if (pageLoad) return <Loader overlay />;
    return <Scoped css={styles}>
        <div className="wrapper">
          <PageHeader name={pageName} actions={isSuperUser && builder && <Button onClick={() => this.setState({
          switchModal: true
        })}>Advanced Query Editor</Button>} />
          <div className="segments-details">
            <div className="form-group">
              <label>
                Name <span className="form-error">*</span>
              </label>
              <input onChange={e => this.updateSegment('name', e.target.value)} className="form-control" style={{
              width: '35%'
            }} type="text" name="name" value={segment.name || ''} />
            </div>
            <div className="form-group">
              <label>
                SEGMENT TYPE <span className="form-error">*</span>
              </label>
              <Tooltip overlay={segmentTypeTooltip}>
                <span>
                  <Icon className="ml-2 mb-2" name="fa-solid-question-circle" size={10} />
                </span>
              </Tooltip>
              <div className="d-flex">
                <div className="checkbox-label " style={{
                width: '20%'
              }}>
                  <label className="form-check-label">
                    <input className="form-check-input" type="checkbox" checked={segment.time_dynamic_segment === false} htmlFor="staticSegment" onChange={e => this.updateSegment('time_dynamic_segment', false)} />
                    <span style={{
                    textTransform: 'none'
                  }}>Static</span>
                  </label>
                </div>
                <div className="checkbox-label " style={{
                width: '20%'
              }}>
                  <label className="form-check-label">
                    <input className="form-check-input" type="checkbox" checked={segment.time_dynamic_segment === true} htmlFor="dynamicSegment" onChange={e => this.updateSegment('time_dynamic_segment', true)} />
                    <span style={{
                    textTransform: 'none'
                  }}>Dynamic</span>
                  </label>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label>TAGS</label>
              <div>
                <div className="form-group mb-sm">
                  {segment.tags && <TagsComponent tags={segment.tags} styleType="custom" onDelete={i => this.deleteTag(i)} onClick={null} />}

                  <input placeholder="+ New Tag" className={`form-control input-new-tag ${segment.tags && 'ml-2'}`} type="text" value={tagValue} name="tagValue" maxLength="20" onChange={e => this.setState({
                  tagValue: e.target.value
                })} />
                  <Button disabled={tagValue === ''} className="button-new-tag" actionType="primary" style={{}} onClick={() => this.addNewTag(tagValue)}>
                    Add
                  </Button>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label>Description</label>
              <textarea onChange={e => this.updateSegment('description', e.target.value)} className="form-control" name="description" style={{
              minHeight: '5.4rem'
            }} value={segment.description || ''} />
            </div>
            {builder ? <div className="form-group">
                <label>Segment Builder</label>
                <Rules query={segment.segment_builder || {
              and: []
            }} onChange={this.updateQuery} />
              </div> : <>
                {isSuperUser && <>
                    <div className="form-group">
                      <label>Segment Query</label>
                      <textarea onChange={e => this.updateSegment('segment_query', e.target.value)} className="form-control" name="segmentQuery" style={{
                  height: '10%',
                  minHeight: '20rem'
                }} value={segment.segment_query || ''} />
                    </div>
                    <div className="form-group">
                      <label>
                        Fields
                        <Button actionType="primary" className="ml-3" onClick={this.addField}>
                          Add Field
                        </Button>
                      </label>
                      {segment.fields && segment.fields.length > 0 && <table className="w-100">
                          <thead>
                            <tr>
                              <th style={{
                        width: '15%'
                      }}>SQL Variable</th>
                              <th style={{
                        width: '15%'
                      }}>Label</th>
                              <th style={{
                        width: '30%'
                      }} colSpan={2}>
                                Field
                              </th>
                              <th style={{
                        width: '40%'
                      }}>Description</th>
                              <th style={{
                        width: '40px'
                      }}>&nbsp;</th>
                            </tr>
                          </thead>
                          <tbody>
                            {segment.fields.map((field, index) => <tr key={`field${index}`}>
                                <td className="pr-2">
                                  <input className="form-control" value={field.variable} onChange={e => this.updateField('variable', e.target.value, index)} />
                                </td>
                                <td className="pr-2">
                                  <input className="form-control" value={field.label} onChange={e => this.updateField('label', e.target.value, index)} />
                                </td>
                                <td colSpan={field.type === 'picklist' ? 1 : 2} style={{
                        width: field.type === 'picklist' ? '10%' : '30%'
                      }} className="pr-2">
                                  <select className="form-control" value={field.type} onChange={e => this.updateField('type', e.target.value, index)}>
                                    <option value="text">Text</option>
                                    <option value="numeric">Numeric</option>
                                    <option value="picklist">Picklist</option>
                                  </select>
                                </td>
                                {field.type === 'picklist' && <td className="pr-2" style={{
                        width: '20%'
                      }}>
                                    {field.options.map((option, optionIndex) => <input key={`field${index}option${optionIndex}`} className="form-control mb-2" value={option} onChange={e => this.updateOption(index, optionIndex, e.target.value)} />)}

                                    <Button actionType="primary" onClick={() => this.addPicklistOption(index)}>
                                      Add Option
                                    </Button>
                                  </td>}

                                <td className="pr-2">
                                  <textarea className="form-control" value={field.description} onChange={e => this.updateField('description', e.target.value, index)} />
                                </td>

                                <td>
                                  <Button icon="fa-regular-times" small actionType="flat" onClick={() => this.removeField(index)} />
                                </td>
                              </tr>)}
                          </tbody>
                        </table>}
                    </div>
                  </>}
                {segment.fields && segment.fields.length > 0 && <div className="form-group">
                    <label>Values</label>
                    <table className="w-100">
                      <tbody>
                        {segment.fields.map((field, index) => <tr key={`value${index}`}>
                            <td className="pr-5" style={{
                      width: '10%'
                    }}>
                              <strong>{field.label}</strong>
                            </td>
                            <td className="pr-5" style={{
                      width: '20%'
                    }}>
                              {field.type === 'picklist' && <select className="form-control" value={segment.values && segment.values[field.variable] || ''} onChange={e => this.updateValue(field.variable, e.target.value)}>
                                  {field.options.indexOf('') === -1 && <option></option>}

                                  {field.options.map((option, index) => <option key={`value${index}option${index}`}>{option}</option>)}
                                </select>}
                              {field.type === 'numeric' && <input type="number" className="form-control" value={segment.values && segment.values[field.variable] || ''} onChange={e => this.updateValue(field.variable, e.target.value)} />}
                              {field.type === 'text' && <input className="form-control" value={segment.values && segment.values[field.variable] || ''} onChange={e => this.updateValue(field.variable, e.target.value)} />}
                            </td>
                            <td style={{
                      width: '70%'
                    }}>{field.description}</td>
                          </tr>)}
                      </tbody>
                    </table>
                  </div>}
              </>}
            <div className="form-group">
              <label>Included</label>
              <select onChange={e => {
              if (e.target.value) {
                this.updateSegment('included', e.target.value);
                this.updateSegment('distinct_on', null);
              } else {
                this.updateSegment('included', null);
              }
            }} className="form-control" name="included" style={{
              width: '25%'
            }} value={segment.included || ''}>
                <option value="">Nothing Included</option>
                <option value="possession">Possession</option>
                <option value="schedule">Schedule</option>
                <option value="transaction">Transaction</option>
                <option value="cart">Cart</option>
              </select>
            </div>
            <div className="form-group">
              <label>Distinct Values</label>
              <div className="d-flex">
                <div className="checkbox-label " style={{
                width: '20%'
              }}>
                  <label className="form-check-label">
                    <input className="form-check-input" type="checkbox" checked={segment.distinct_on === 'email'} htmlFor="distinctEmail" onChange={e => this.updateSegment('distinct_on', segment.distinct_on === 'email' ? null : 'email')} disabled={segment.included !== null && segment.included !== undefined} />
                    <span style={{
                    textTransform: 'none'
                  }}>Distinct Emails</span>
                  </label>
                </div>
                <div className="checkbox-label " style={{
                width: '20%'
              }}>
                  <label className="form-check-label">
                    <input className="form-check-input" type="checkbox" checked={segment.distinct_on === 'phone'} htmlFor="distinctPhone" onChange={e => this.updateSegment('distinct_on', segment.distinct_on === 'phone' ? null : 'phone')} disabled={segment.included !== null && segment.included !== undefined} />
                    <span style={{
                    textTransform: 'none'
                  }}>Distinct Phone Numbers</span>
                  </label>
                </div>
              </div>
              {segmentError ? <div className="form-error">{segmentError}</div> : null}
            </div>
            <div className="row form-group">
              <div className="col-6">
                <label>Triggered by Journey</label>
                <div>
                  {!journeysTriggered.length && <div className="mb-3">
                      <em>Not triggered by any journeys</em>
                    </div>}
                  {journeysTriggered.filter((r, i) => i < ASSET_MAX || journeyTriggersExpanded).map(journey => <Button actionType="primary" key={journey.id} className="mr-3 mb-3" onClick={() => this.props.history.push(`/customer-journeys/details/${journey.id}`)}>
                        {journey.name}
                      </Button>)}
                  {journeysTriggered.length > ASSET_MAX && <Button actionType="flat" onClick={() => this.setState({
                  journeyTriggersExpanded: !journeyTriggersExpanded
                })}>
                      Show {journeyTriggersExpanded ? 'Less' : 'More'}
                    </Button>}
                </div>
              </div>
              {!segment.time_dynamic_segment && <div className="col-6">
                  <label>Edited by Journey</label>
                  <div>
                    {!journeysEdited.length && <div className="mb-3">
                        <em>Not edited by any journeys</em>
                      </div>}
                    {journeysEdited.filter((r, i) => i < ASSET_MAX || journeyEditedExpanded).map(journey => <Button actionType="primary" key={journey.id} className="mr-3 mb-3" onClick={() => this.props.history.push(`/customer-journeys/details/${journey.id}`)}>
                          {journey.name}
                        </Button>)}
                    {journeysEdited.length > ASSET_MAX && <Button actionType="flat" onClick={() => this.setState({
                  journeyEditedExpanded: !journeyEditedExpanded
                })}>
                        Show {journeyEditedExpanded ? 'Less' : 'More'}
                      </Button>}
                  </div>
                </div>}
            </div>
            <div className="form-group d-flex ">
              <div className="d-flex justify-content-flex-end align-items-center text-right" style={{
              width: '100%'
            }}>
                {testCount && <>
                    <span>{testCount}</span>
                    <Button className="mr-1 ml-3" onClick={() => this.setState({
                  showSample: true
                })} disabled={testLoading}>
                      View Sample
                    </Button>
                    {hasMore && <Button className="mr-1 ml-1" onClick={() => this.setState({
                  showAskMore: true
                })} disabled={testLoading}>
                        Get Total {testLoading && <Loader size="sm" className="ml-2" />}
                      </Button>}
                  </>}

                {testError && <span>{testError}</span>}

                <Button className="mr-3 ml-1" onClick={this.handleTest} disabled={testLoading}>
                  Test {testLoading && <Loader size="sm" className="ml-2" />}
                </Button>

                <Button actionType="primary" disabled={!segment.name || segment.time_dynamic_segment === undefined || loadingCreateSegment} onClick={this.handleSubmit}>
                  {view === EDIT ? 'Save' : loadingCreateSegment ? <Loader size="sm" /> : 'Create'}
                </Button>
              </div>
            </div>
          </div>
          <ModalDialog title="Use Advanced Query Editor" open={switchModal} submitText="Use Advanced Query Editor" allowBackdropClick onClose={() => this.setState({
          switchModal: false
        })} onSubmit={this.switchToAdvancedMode}>
            <p>Are you sure you want to switch to advanced mode?</p>
            <p>This action cannot be undone - all "segment builder" logic will be lost.</p>
          </ModalDialog>
          <ModalDialog title="Calculate Total" open={showAskMore} submitText="Yes" allowBackdropClick onClose={() => this.setState({
          showAskMore: false
        })} onSubmit={this.fullCount}>
            <p>Calculating the total segment size may take several minutes. Are you sure you would like ot proceed?.</p>
          </ModalDialog>
          <ModalDialog size="xl" title="Sample Customers" open={showSample} allowBackdropClick onClose={() => this.setState({
          showSample: false
        })} cancelText="Done">
            <table className="table-list mb-5">
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Phone</th>
                  <th>Email</th>
                </tr>
              </thead>
              <tbody>
                {sampleCustomers?.map((ref, index) => {
                return <tr key={index} onClick={() => this.viewCustomer(ref.id)}>
                      <td>{ref.first_name}</td>
                      <td>{ref.last_name}</td>
                      <td>{ref.phone}</td>
                      <td>{ref.email}</td>
                    </tr>;
              })}
              </tbody>
            </table>
          </ModalDialog>
        </div>
      </Scoped>;
  }
  handleSubmit = () => {
    const {
      segment,
      company,
      view
    } = this.state;
    this.setState({
      loadingCreateSegment: true
    });
    if (view === EDIT) {
      editSegment(segment.id, segment).then(({
        data
      }) => {
        if (data) this.props.history.push('/segments');
      }).catch(error => {
        this.setState({
          segmentError: error.response.data.segment_query[0]
        });
      });
    } else if (view === COPY) {
      copySegment(segment).then(({
        data
      }) => {
        if (data) this.props.history.push('/segments');
      }).catch(error => {
        this.setState({
          segmentError: error.response.data.segment_query[0]
        });
      });
    } else if (view === CREATE) {
      newSegment({
        ...segment,
        company
      }).then(({
        data
      }) => {
        if (data) this.props.history.push('/segments');
      }).catch(error => {
        if (error.response.data.segment_query) {
          this.setState({
            segmentError: error.response.data.segment_query[0]
          });
        } else if (error.response.data.time_dynamic_segment) this.setState({
          segmentError: error.response.data.time_dynamic_segment[0]
        });
        this.setState({
          loadingCreateSegment: false
        });
      });
    }
  };
}