import React, { useEffect, useState } from 'react';
import { getter } from './utils';
import { Scoped } from 'kremling';
import styles from './index.styles.scss';
import { Button } from '../../button/button.component';
import moment from 'moment-timezone';
const fieldsToExclude = ['location', 'appointment_naive', 'status', 'type', 'description'];
export function SchedulesComponent({
  selectedSchedule,
  ...props
}) {
  const {
    schedules,
    availableModelsFields,
    availableModels
  } = props;
  const [i, setScheduleIndex] = useState(0);
  const [showMoreFields, setShowMoreFields] = useState(false);
  useEffect(() => {
    let toSelect = 0;
    if (selectedSchedule) {
      toSelect = schedules.findIndex(s => s.id === selectedSchedule);
      if (toSelect === -1) {
        toSelect = 0;
      }
    }
    setScheduleIndex(toSelect);
  }, []);
  const handleScheduleIndex = i => {
    setScheduleIndex(i);
  };
  const scheduleFields = availableModelsFields.find(model => model.id === 'schedule').fields.filter(f => !fieldsToExclude.includes(f.id));
  return <Scoped css={styles}>
      <div className="drawer__body" style={{
      height: 'calc(100vh - 9rem)',
      overflowY: 'auto'
    }}>
        <div className="d-flex justify-content-between">
          <Button icon="fa-solid-caret-left" disabled={schedules.length === 0 || i === schedules.length - 1} onClick={() => handleScheduleIndex(i + 1)} />
          {schedules && schedules[i] && <span style={{
          marginTop: '8px'
        }}>
              {schedules[i].location_name && schedules[i].location_name}{' '}
              {moment.utc(schedules[i].appointment_naive).format('MMM D, YYYY h:mm A')}
            </span>}
          <Button icon="fa-solid-caret-right" disabled={i === 0} onClick={() => handleScheduleIndex(i - 1)} />
        </div>
        {(!schedules || !schedules[i]) && <div className="my-5">
            <em>Customer has no schedules</em>
          </div>}
        {schedules && schedules[i] && <>
            <table className="data-table mt-5">
              <tbody>
                {/* Location */}
                <tr>
                  {schedules[i].location_name && <th>Location</th>}
                  {schedules[i].appointment_naive && <th>Appointment (Local Time)</th>}
                </tr>
                <tr>
                  {schedules[i].location_name && <td>{schedules[i].location_name}</td>}
                  {schedules[i].appointment_naive && <td>
                      {moment.utc(schedules[i].appointment_naive).format('MMM D, YYYY h:mm A')}
                    </td>}
                </tr>
                {/* STATUS - TYPE*/}
                <tr>
                  {schedules[i].status && <th>status</th>}
                  {schedules[i].type && <th>type</th>}
                </tr>
                <tr>
                  {schedules[i].status && <td>{schedules[i].status}</td>}

                  {schedules[i].type && <>
                      <td>{schedules[i].type}</td>
                    </>}
                </tr>
                {/* DESCRIPTION  */}
                {schedules[i].description && <>
                    <tr>
                      <th>description</th>
                    </tr>
                    <tr>
                      <td>{schedules[i].description}</td>
                    </tr>
                  </>}
              </tbody>
            </table>
            <div className="div-table">
              {showMoreFields && availableModels.indexOf('schedule') > -1 && scheduleFields.map((field, index) => {
            const fieldValue = schedules[i][field.id];
            return fieldValue !== undefined && fieldValue !== null && fieldValue !== '' ? <div key={index} className={'mt-2 column'}>
                      <h4>{field.name}</h4>
                      <p>
                        <i>
                          {field.type === 'datetime' ? moment(fieldValue).local().format('MMM D, YYYY h:mm A') : field.type === 'date' ? moment(fieldValue).format('MMM D, YYYY') : fieldValue}
                        </i>
                      </p>
                    </div> : field.id.substring(0, 13) === 'entity_data__' ? <div key={index} className={'mt-2 column'}>
                      <h4>{field.name}</h4>
                      <p>
                        {getter(schedules[i], field.id.split('__')) || <i>No Data Available</i>}
                      </p>
                    </div> : <></>;
          })}
            </div>
            {scheduleFields.length !== 0 && <div className="buttonCenter mt-5">
                <Button tag="a" onClick={() => setShowMoreFields(!showMoreFields)}>
                  {showMoreFields ? 'Show Less' : 'Show More'}
                </Button>
              </div>}
          </>}
      </div>
    </Scoped>;
}