import React, { Component } from 'react';
import { userState } from '../../shared/user-state';
import { Button } from '../../components/button/button.component';
import { getCourses, addCourse, deleteCourse } from './integrations.resource';
import { toasterService } from '../../components/toaster/toaster-service';
import { ModalDialog } from '../../components/modal/modal-dialog.component';
export class IntegrationForeUP extends Component {
  static propTypes = {};
  state = {
    courses: [],
    deleteForeUPCourse: {},
    isCourseModalOpen: false,
    courseRef: null
  };
  componentDidMount() {
    this.getForeUPData().finally(() => this.props.onLoad());
  }
  getForeUPData = () => {
    return getCourses({
      company: userState.getAsCompanyId()
    }).then(({
      results
    }) => {
      this.setState({
        courses: results
      });
    });
  };
  addForeUPCourse = courseRef => {
    return addCourse(courseRef, userState.getAsCompanyId()).then(() => toasterService.success('Successfully added course.  Data import will begin shortly and can take several minutes.')).then(() => this.getForeUPData()).catch(err => {
      if (err.response.data.course_ref) {
        toasterService.error(err.response.data.course_ref[0]);
      } else if (err.response.data.company) {
        toasterService.error(err.response.data.company[0]);
      } else {
        toasterService.error('Unknown error trying add course. Please try again.');
      }
    });
  };
  removeForeUPCourse = courseId => {
    return deleteCourse(courseId).then(() => toasterService.success('Successfully deleted course')).then(() => this.getForeUPData()).catch(() => toasterService.error('Unknown error trying delete course. Please try again.'));
  };
  render() {
    const {
      courses,
      isCourseModalOpen,
      courseRef,
      deleteForeUPCourse
    } = this.state;
    return <div className="integration mb-5">
        <div className="integration__header">
          <div>ForeUP</div>
          {userState.hasPermission('foreup.add_course') && <Button className="btn" actionType="primary" tag="a" onClick={() => this.setState({
          isCourseModalOpen: true
        })}>
              Add Course
            </Button>}
        </div>
        <div className="integration__body">
          {courses.map(course => <div className="integration-item" key={course.course_ref}>
              <div className="integration-item__inner">
                <div className="integration-title">
                  <strong>{course.location_name}</strong>
                </div>
                <div className="integration-actions">
                  {userState.hasPermission('foreup.delete_course') && <Button actionType="flat" icon="fa-regular-trash" onClick={e => {
                e.stopPropagation();
                this.setState({
                  deleteForeUPCourse: course
                });
              }} />}
                </div>
              </div>
            </div>)}
        </div>

        <ModalDialog open={!!deleteForeUPCourse.id} title="Remove course" submitText="Remove" onSubmit={() => this.removeForeUPCourse(deleteForeUPCourse.id)} onClose={() => this.setState({
        deleteForeUPCourse: {}
      })} allowBackdropClick>
          <p>
            <strong>{deleteForeUPCourse.location_name}</strong>
          </p>
          <p>Are you sure you want to remove this course?</p>
        </ModalDialog>

        <ModalDialog open={isCourseModalOpen} title="Add Course" onSubmit={() => this.addForeUPCourse(courseRef)} onClose={() => this.setState({
        isCourseModalOpen: false
      })} allowBackdropClick>
          <p>
            Please enter the ForeUP course ID number to link to your account.
          </p>
          <p>
            <input required className="form-control" name="course_ref" onChange={e => this.setState({
            courseRef: e.target.value
          })} />
          </p>
        </ModalDialog>
      </div>;
  }
}