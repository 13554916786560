import React, { useState, useEffect, useCallback, useContext } from 'react';
import { getPhoneRegistration } from 'shared/common.api';
import { WorkingAsContext } from 'context/working-as-context';
import { CustomerProfileModal } from './customer-profile-modal.component';
import { BuyPhoneNumberModal } from './buy-phone-number-modal.component';
export const PhoneNumberModal = props => {
  const {
    asCompany
  } = useContext(WorkingAsContext);
  const [profile, setProfile] = useState();
  const [showBuy, setShowBuy] = useState();
  useEffect(() => {
    if (asCompany) {
      // Check if we have a messaging service or registration
      getPhoneRegistration(asCompany.id).then(({
        data: profile
      }) => {
        setShowBuy(profile.has_messaging_service && (!profile.brand_status || profile.campaign_status === 'VERIFIED'));
        setProfile(profile);
      }).catch(() => {});
    }
  }, [asCompany]);
  if (!profile) {
    // We're not loaded yet so skip for now.
    return null;
  }
  if (showBuy) {
    // We have a messaging service and no brand information or campaign is approved so let's let them buy a phone number.
    return <BuyPhoneNumberModal {...props} profile={profile} setShowBuy={setShowBuy} />;
  }
  return <CustomerProfileModal {...props} profile={profile} setShowBuy={setShowBuy} />;
};