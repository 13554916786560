import React, { useState, useRef, useEffect } from 'react';
import { Scoped } from 'kremling';
import classnames from 'classnames';
import Papa from 'papaparse';
import { get } from 'lodash';
import { getCompanyFields, getCSVImportSources, importCSV, getSegments } from 'shared/common.api';
import styles from './upload.styles.scss';
import { userState } from 'shared/user-state';
import constants from 'shared/constants';
import { Dashboard } from '@uppy/react';
import Uppy from '@uppy/core';
import AwsS3Multipart from '@uppy/aws-s3-multipart';
import { createMultipartUpload, listParts, prepareUploadPart, completeMultipartUpload, abortMultipartUpload } from 'components/file-viewer/provider';
import { ModalDialog, Loader, Button, PageHeader, DynamicTypeAhead, Modal, toasterService } from 'components';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
export function UploadImport(props) {
  let fields, numRecords;
  const customerFields = useRef([]);
  const [uploadedFile, setUploadedFile] = useState();
  const [confirmUpload, setConfirmUpload] = useState(false);
  const [importSources, setImportSources] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [uppy, setUppy] = useState();
  const [agree1, setAgree1] = useState(false);
  const [agree2, setAgree2] = useState(false);
  const [uniqueIdModal, setUniqueIdModal] = useState(false);
  useEffect(() => {
    getCompanyFields(userState.getAsCompanyId()).then(({
      data
    }) => {
      const customerModel = data.find(model => model.id === 'customer');
      const allCustomerFields = get(customerModel, 'fields') || [];
      const filteredCustomerFields = allCustomerFields.filter(f => {
        return !constants.READ_ONLY_CUSTOMER_FIELDS.some(cf => cf === f.id.substr(0, cf.length));
      });
      customerFields.current = filteredCustomerFields;
    });
    getCSVImportSources({
      company: userState.getAsCompanyId()
    }).then(({
      data
    }) => {
      setImportSources(data.map(r => r.source));
    });
    const uppy = new Uppy({
      autoProceed: true,
      restrictions: {
        maxNumberOfFiles: 1,
        minNumberOfFiles: 1,
        maxTotalFileSize: 25000000,
        // Max file size 25mb
        allowedFileTypes: ['.csv']
      },
      disableThumbnailGenerator: true,
      showRemoveButtonAfterComplete: false,
      fileManagerSelectionType: 'files',
      allowMultipleUploads: false
    }).use(AwsS3Multipart, {
      companionUrl: API_URL + `/company-files/${userState.getAsCompanyId()}/upload`,
      parentFolder: 'csv/',
      createMultipartUpload,
      listParts,
      prepareUploadPart,
      completeMultipartUpload,
      abortMultipartUpload
    }).on('file-added', file => {
      handleFile(file.data);
    }).on('upload-success', file => {
      const maps = {};
      const tempMap = {};
      customerFields.current.forEach(field => {
        tempMap[field.id.toUpperCase()] = field.id;
        tempMap[field.name.toUpperCase()] = field.id;
      });
      fields.forEach(field => {
        if (tempMap[field.toUpperCase()]) {
          maps[field] = tempMap[field.toUpperCase()];
        }
      });
      setUploadedFile({
        company: userState.getAsCompanyId(),
        filename: file.name,
        total_records: numRecords,
        source: '',
        other: '',
        fields,
        unique_id: '',
        maps,
        new_fields: {},
        segment_option: 'new',
        new_segment: file.name,
        segment_id: ''
      });
    });
    setUppy(uppy);
  }, []);
  const handleFile = file => {
    const fr = new FileReader();
    fr.onload = function () {
      const data = Papa.parse(fr.result);
      const rows = data.data.filter(r => r.join('').trim() !== '');
      fields = rows.shift();
      numRecords = Math.max(0, rows.length);
    };
    try {
      fr.readAsText(file);
    } catch (e) {
      // For some unknown reason this throws an error but it still works *facepalm*
    }
  };
  const upload = () => {
    if (uploadedFile.source === 'other' && uploadedFile.other === '') {
      toasterService.error('Source set as "other" but not specified.');
      return;
    }
    if (uploadedFile.segment_option === 'new' && uploadedFile.new_segment === '') {
      toasterService.error('New segment must be given a name.');
      return;
    }
    if (uploadedFile.segment_option === 'select' && uploadedFile.new_segment === 'segment_id') {
      toasterService.error('A segment must be selected.');
      return;
    }

    // TODO make sure at least one field is mapped.
    setConfirmUpload(true);
  };
  const actuallyUpload = () => {
    //setIsUploading(true);

    importCSV(uploadedFile).then(() => {
      props.history.push({
        pathname: '/imports'
      });
    }).catch(() => {
      toasterService.error('Unknown error occured uploading the CSV. Please try again.');
    }).then(() => {
      setIsUploading(false);
    });
  };
  const mapField = (field, val) => {
    const maps = {
      ...uploadedFile.maps,
      [field]: val
    };
    Object.keys(maps).forEach(key => {
      if (val && val !== 'NEW' && val !== '' && key !== field && maps[key] === val) {
        maps[key] = '';
      }
    });
    setUploadedFile({
      ...uploadedFile,
      maps
    });
  };
  return <Scoped css={styles}>
      <div className="wrapper">
        <PageHeader name="Upload Customer Import" />

        {!uploadedFile && uppy && <div style={{
        margin: '50px auto'
      }}>
            <Dashboard uppy={uppy} />
          </div>}

        {uploadedFile && <div className="p-5" style={{
        width: '100%',
        maxWidth: '800px',
        margin: '0 auto'
      }}>
            <div className="row">
              <div className="col-6 form-group">
                <label>Filename</label>
                <input value={uploadedFile.filename} className="form-control" disabled />
              </div>
              <div className="col-6 form-group">
                <label>Number of Rows</label>
                <input value={uploadedFile.total_records} className="form-control" disabled />
              </div>
            </div>
            <div className="row">
              <div className="col-6 form-group">
                <label>Source</label>
                <select value={uploadedFile.source} className="form-control" onChange={e => setUploadedFile({
              ...uploadedFile,
              source: e.target.value
            })}>
                  <option value="">None</option>
                  {importSources.map(s => <option key={s} value={s}>
                      {s}
                    </option>)}
                  <option value="other">Other (Specify)</option>
                </select>
              </div>
              <div className="col-6 form-group">
                {uploadedFile.source === 'other' && <>
                    <label>Other</label>
                    <input value={uploadedFile.other} className="form-control" onChange={e => setUploadedFile({
                ...uploadedFile,
                other: e.target.value
              })} />
                  </>}
              </div>
            </div>
            <div className="row">
              <div className="col-6 form-group">
                <label>Add Customers to Segment</label>
                <select value={uploadedFile.segment_option} className="form-control" onChange={e => setUploadedFile({
              ...uploadedFile,
              segment_option: e.target.value
            })}>
                  <option value="">
                    Don't Add Customers to Static Segment
                  </option>
                  <option value="select">Select Segment</option>
                  <option value="new">New Static Segment</option>
                </select>
              </div>
              <div className="col-6 form-group">
                {uploadedFile.segment_option === 'new' && <>
                    <label>New Static Segment</label>
                    <input value={uploadedFile.new_segment} className="form-control" onChange={e => setUploadedFile({
                ...uploadedFile,
                new_segment: e.target.value
              })} />
                  </>}
                {uploadedFile.segment_option === 'select' && <>
                    <label>Select Static Segment</label>
                    <div className="form-group">
                      <DynamicTypeAhead getItems={getSegments} getItemsFilters={{
                  time_dynamic_segment: false,
                  company: userState.getAsCompanyId()
                }} placeholder="Choose Customer Segment" displayProperty="name" keyProperty="id" value={uploadedFile.segment_id} onChange={val => setUploadedFile({
                  ...uploadedFile,
                  segment_id: val.id
                })} />
                    </div>
                  </>}
              </div>
            </div>
            <hr />

            <table className="w-100 mt-5">
              <thead>
                <tr className="mb-2">
                  <th style={{
                width: '43%'
              }}>CSV Field</th>
                  <th style={{
                width: '43%'
              }}>Customer Field</th>
                  <th className="text-center" style={{
                width: '14%'
              }}>
                    Unique ID
                    <Button icon="fa-solid-question-circle" actionType="flat" style={{}} onClick={() => setUniqueIdModal(true)}></Button>
                  </th>
                </tr>
              </thead>
              <tbody>
                {uploadedFile.fields.map(f => <tr key={f}>
                    <td>
                      <input value={f} className="form-control" disabled={true} />
                    </td>
                    <td>
                      {uploadedFile.maps[f] !== 'NEW' ? <select className={classnames({
                  'form-control': true,
                  faded: !uploadedFile.maps[f] || uploadedFile.maps[f] === ''
                })} value={uploadedFile.maps[f] || ''} onChange={e => mapField(f, e.target.value)}>
                          <option value="">Select Target Field</option>
                          {customerFields.current && customerFields.current.map(f => <option key={f.id} value={f.id}>
                                {f.name}
                              </option>)}
                          <option value="NEW">New Entity Data Field</option>
                        </select> : <div className="d-flex align-items-center">
                          <input className="form-control" placeholder="New Entity Data Field" value={uploadedFile.new_fields[f] || ''} onChange={e => setUploadedFile({
                    ...uploadedFile,
                    new_fields: {
                      ...uploadedFile.new_fields,
                      [f]: e.target.value
                    }
                  })} />
                          <Button className="custom-field-cancel" icon="fa-regular-times" actionType="flat" small onClick={() => setUploadedFile({
                    ...uploadedFile,
                    maps: {
                      ...uploadedFile.maps,
                      [f]: ''
                    }
                  })} />
                        </div>}
                    </td>
                    <td className="text-center">
                      <input type="checkbox" checked={uploadedFile.unique_id === f} onChange={e => setUploadedFile({
                  ...uploadedFile,
                  unique_id: e.target.checked ? f : ''
                })} />
                    </td>
                  </tr>)}
              </tbody>
            </table>

            <Button className="mt-5 mr-5" onClick={() => props.history.push({
          pathname: '/imports'
        })} actionType="grey" disabled={isUploading}>
              Cancel
            </Button>

            <Button className="mt-5" onClick={upload} actionType="primary" disabled={isUploading}>
              {isUploading ? <Loader size="sm" /> : 'Upload'}
            </Button>
          </div>}
      </div>

      <ModalDialog open={confirmUpload} onClose={() => setConfirmUpload(false)} allowBackdropClick onSubmit={actuallyUpload} title="Confirm Upload" submitText="Upload" submitDisabled={!agree1 || !agree2}>
        <p>
          Please confirm you want to load these{' '}
          {uploadedFile && uploadedFile.total_records} records into{' '}
          {userState.state.asCompany.name}
        </p>

        <p>
          <input type="checkbox" checked={agree1} onChange={() => setAgree1(a => !a)} />{' '}
          I understand that importing new records may impact my monthly pricing
          for Cinch. (Please see your contract or contact{' '}
          <a href="mailto:support@cinch.io">support@cinch.io</a> for more
          information)
        </p>
        <p>
          <input type="checkbox" checked={agree2} onChange={() => setAgree2(a => !a)} />{' '}
          I certify that have permission to contact or market to these customer
          records
        </p>
      </ModalDialog>
      <Modal open={!!uniqueIdModal} onClose={() => setUniqueIdModal(true)} allowBackdropClick title="Unique ID Information">
        <div className="modal__body">
          <p>
            Check this box if your data set contains a unique identifier
            (meaning that this identifier or value should only exists once in
            the data). When you check this box next to the appropriate unique
            ID, Cinch will check if value already exists in your database, and
            if it does, it will update the existing record instead of creating a
            new record.
          </p>
          <p>
            For example, if you selected “Email” as your unique ID, Cinch will
            check if the email addresses in the CSV you’re loading have been
            loaded before, and if they have, it will just update the existing
            records with a matching email address”
          </p>
        </div>
        <div className="modal__actions">
          <Button actionType="primary" onClick={() => setUniqueIdModal(false)}>
            Okay
          </Button>
        </div>
      </Modal>
    </Scoped>;
}