import React, { useState, useEffect } from 'react';
import { userState } from 'shared/user-state';
import { DynamicTypeAhead as DTA } from 'components/dynamic-type-ahead/dynamic-type-ahead.component';
export const DynamicMultiTypeAhead = props => {
  const {
    param,
    data
  } = props;
  const [displayVal, setDisplayVal] = useState(<em>Loading...</em>);
  useEffect(() => {
    if (!data[param.key] || !data[param.key].length) {
      setDisplayVal(<em>No item selected.</em>);
    } else {
      param.getItems({
        [(param.keyProperty || 'id') + '_in']: data[param.key].join(','),
        limit: data[param.key].length
      }).then(({
        data
      }) => {
        if (data.results.length > 0) {
          setDisplayVal(data.results.map(r => r[param.displayProperty || 'name']).join(', '));
        } else {
          setDisplayVal(<em>Unable to find items.</em>);
        }
      }).catch(() => {
        setDisplayVal(<em>Error retrieving items.</em>);
      });
    }
  }, []);
  return <div>{displayVal}</div>;
};