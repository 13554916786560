import React, { useEffect, useState, createContext } from 'react';
import ChangedWorkingAsModal from './changed-working-as.component';
import { userState, hasPermission } from 'shared/user-state';
import { getCompanies, getCompany, getUserCompanies } from 'shared/common.api';
import { getInstalls } from '../pages/integrations/integrations.resource';
import NeedsPhoneModal from './needs-phone.component';
import NeedsPasswordResetModal from './needs-password-reset.component';
export const WorkingAsContext = createContext();
export const WorkingAsContextProvider = ({
  children
}) => {
  // Global state items
  const [asCompany, setAsCompany] = useState({});
  const [installedIntegrations, setInstalledIntegrations] = useState([]);

  // Changing working as items
  const [newCompany, setNewCompany] = useState();
  const [oldCompany, setOldCompany] = useState();
  const [checkPromise, setCheckPromise] = useState();
  useEffect(() => {
    // We can replace all of the user state asCompany stuff with this context provider
    // but that will be a large and potentially hazardous change, so for now we are
    // going to sync the two states so a component can use either.
    const userObserver = userState.subscribe(() => {
      setAsCompany(userState.state.asCompany);
    }, 'asCompany');
    return () => userObserver.unsubscribe();
  }, []);
  useEffect(() => {
    if (asCompany?.id) {
      getInstalls({
        company: asCompany.id,
        limit: 100
      }).then(({
        results: installedIntegrations
      }) => {
        setInstalledIntegrations(installedIntegrations);
      });
      getUserCompanies(userState.state.user.id, {
        company: userState.state.asCompany.id
      }).then(({
        data
      }) => {
        window.pendo.initialize({
          visitor: {
            id: userState.state.user.id,
            // Required if user is logged in, default creates anonymous ID
            email: userState.state.user.email,
            // Recommended if using Pendo Feedback, or NPS Email
            full_name: userState.state.user.first_name + ' ' + userState.state.user.last_name,
            // Recommended if using Pendo Feedback
            company: userState.state.asCompany.id,
            companyName: userState.state.asCompany.name,
            permissions: userState.state.user.permissions,
            role: userState.state.user.is_superuser ? 'superuser' : data.results[0].role_name
            // You can add any additional visitor level key-values here,
            // as long as it's not one of the above reserved names.
          },
          account: {
            id: userState.state.asCompany.id,
            // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
            name: userState.state.asCompany.name
          }
        });
      });
    } else {
      setInstalledIntegrations([]);
    }
  }, [asCompany?.id]);
  const hasIntegration = integration => {
    return installedIntegrations.some(i => i.integration === integration);
  };
  const setUser = u => {
    userState.set({
      user: u,
      validToken: true
    });
  };
  const checkCompany = companyId => {
    return new Promise((resolve, reject) => {
      if (userState.state.asCompany.id != companyId) {
        if (hasPermission(userState.state.user, companyId, 'organization.view_company')) {
          getCompany(companyId).then(({
            data: comp
          }) => {
            if (comp) {
              setOldCompany(userState.state.asCompany);
              setNewCompany(comp);
              setCheckPromise([resolve, reject]);
            } else {
              reject('Unable to find company');
            }
          });
        } else {
          reject('User does not have permission for this company');
        }
      } else {
        resolve();
      }
    });
  };
  const [openPhoneModal, setOpenPhoneModal] = useState(false);
  const [changedPhone, setChangedPhone] = useState(null);
  const triggerNeedsPhoneModal = value => {
    setOpenPhoneModal(value);
  };
  return <WorkingAsContext.Provider value={{
    asCompany,
    checkCompany,
    hasIntegration,
    triggerNeedsPhoneModal,
    changedPhone
  }}>
      {children}
      <ChangedWorkingAsModal newCompany={newCompany} oldCompany={oldCompany} onAccept={comp => {
      const [resolve, _] = checkPromise;
      userState.setAsCompany(comp);
      resolve();
    }} onBack={() => {
      const [_, reject] = checkPromise;
      reject('User chose not to switch companies');
      window.history.go(-1);
    }} onDone={() => {
      setNewCompany(null);
      setOldCompany(null);
    }} />
      <NeedsPhoneModal open={!!userState?.state?.user?.requires_phone || openPhoneModal} me={userState.state.user} setUser={setUser} logOut={() => userState.clear()} optionalOptIn={openPhoneModal} onClose={e => {
      setChangedPhone(e);
    }} />
      <NeedsPasswordResetModal open={!!userState?.state?.user?.requires_password_reset} me={userState.state.user} setUser={setUser} logOut={() => userState.clear()} />
    </WorkingAsContext.Provider>;
};