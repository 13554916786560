import React, { Component } from 'react';
import { func, object, shape, string } from 'prop-types';
import { RRule } from 'rrule';
import { Scoped, k } from 'kremling';
import { DateTime } from 'luxon';
import moment from 'moment-timezone';
import { Dropdown } from 'components/dropdown/dropdown.component';
import { Button } from 'components/button/button.component';
import { Icon } from 'components/icon/icon.component';
import { Calendar } from 'components/calendar/calendar.component';
import { Daily } from './daily.component';
import { Weekly } from './weekly.component';
import { Monthly } from './monthly.component';
import { Quarterly } from './quarterly.component';
import { Annually } from './annually.component';
import { patternOptions, defaultOptions, dailyOptions, weeklyOptions, monthlyOptions, quarterlyOptions, annuallyOptions, timeSlots, normalizeRRules, getTime } from './trigger-recurring.utils';
import { TypeAhead } from 'components/type-ahead/type-ahead.component';
const local = DateTime.local();
export class ActionContestRecurring extends Component {
  static propTypes = {
    update: func,
    node: object,
    data: shape({
      pattern: string,
      rrule: string,
      timezone: string
    })
  };
  state = {
    rrule: null,
    timezone: local.zoneName
  };
  componentDidMount() {
    if (!this.props.data.rrule) {
      this.props.update({
        rrule: new RRule({
          ...dailyOptions.everyDay,
          ...defaultOptions
        }).toString()
      });
    }
    if (!this.props.data.timezone) {
      this.props.update({
        timezone: this.state.timezone
      });
    }
  }
  static getDerivedStateFromProps(props) {
    const rrule = props.data.rrule ? RRule.fromString(normalizeRRules(props.data.rrule)) : new RRule({
      ...dailyOptions.everyDay,
      ...defaultOptions
    });
    return {
      rrule
    };
  }
  setPattern = option => {
    let rruleOptions, patternOption;
    if (option.pattern === 'daily') {
      rruleOptions = dailyOptions.everyDay;
      patternOption = 'everyDay';
    }
    if (option.pattern === 'weekly') {
      rruleOptions = weeklyOptions.everyWeek;
      patternOption = 'everyWeek';
    }
    if (option.pattern === 'monthly') {
      rruleOptions = monthlyOptions.everyMonth;
      patternOption = 'everyMonth';
    }
    if (option.pattern === 'quarterly') {
      rruleOptions = quarterlyOptions.onDay;
      patternOption = 'onDay';
    }
    if (option.pattern === 'annually') {
      rruleOptions = annuallyOptions.everyYear;
      patternOption = 'everyYear';
    }
    this.props.update({
      pattern: option.pattern,
      patternOption,
      rrule: new RRule({
        ...this.state.rrule.options,
        ...rruleOptions
      }).toString()
    });
  };
  update = options => {
    this.props.update({
      ...options,
      rrule: normalizeRRules(new RRule({
        ...this.state.rrule.options,
        ...(options.rrule || {})
      }).toString())
    });
  };
  updateStartDate = date => {
    this.update({
      rrule: {
        dtstart: DateTime.fromJSDate(date).toUTC().toJSDate()
      }
    });
  };
  updateEndDate = date => {
    this.update({
      rrule: {
        until: DateTime.fromJSDate(date).toUTC().toJSDate()
      }
    });
  };
  updateTime = (hour, minute) => {
    this.update({
      rrule: {
        byhour: hour,
        byminute: minute
      }
    });
  };
  toggleUntil = () => {
    let until;
    if (!this.state.rrule.options.until) {
      until = DateTime.fromJSDate(this.state.rrule.options.dtstart);
      until = until.plus({
        day: 1
      });
    }
    this.update({
      rrule: {
        until: until && until.toJSDate()
      }
    });
  };
  toggleCount = () => {
    let count;
    if (!this.state.rrule.options.count) {
      count = '10';
    }
    this.update({
      rrule: {
        count
      }
    });
  };
  getFrequency = () => {
    return patternOptions.find(option => {
      return option.pattern === this.props.data.pattern;
    });
  };
  selectTimezone = timezone => {
    this.props.update({
      timezone
    });
  };
  render() {
    const {
      data
    } = this.props;
    const {
      rrule,
      timezone
    } = this.state;
    const dtstart = DateTime.fromJSDate(rrule.options.dtstart);
    const until = rrule.options.until && DateTime.fromJSDate(rrule.options.until);
    return <Scoped css={css}>
        <div>
          <div className="form-group">
            <label>Number of Winners</label>

            <div className="row">
              <div className="col-4 form-group">
                <label>1st Place</label><br />
                <input className="form-control" type="number" min="1" onChange={e => this.props.update({
                winners_1: e.target.value
              })} value={data.winners_1 || 1} />
              </div>
              <div className="col-4 form-group">
                <label>2nd Place</label><br />
                <input className="form-control" type="number" min="0" onChange={e => this.props.update({
                winners_2: e.target.value
              })} value={data.winners_2 || 0} />
              </div>
              <div className="col-4 form-group">
                <label>3rd Place</label><br />
                <input className="form-control" type="number" min="0" onChange={e => this.props.update({
                winners_3: e.target.value
              })} value={data.winners_3 || 0} />
              </div>
            </div>

            <label>Allow single customer to win multiple prizes each round?</label>
            <input type="checkbox" checked={data.allow_multiple} onChange={() => this.props.update({
            allow_multiple: !data.allow_multiple
          })} />
          </div>

          <div className="recurring-divider" />

          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <label>Start Date</label>
                <Dropdown size="md" allowContentClicks trigger={() => <Button block dropdown>
                      {dtstart.toFormat('LL/dd/yy')}
                    </Button>} content={({
                close
              }) => <div className="p-sm">
                      <Calendar onChange={date => {
                  this.updateStartDate(date);
                  close();
                }} value={dtstart.toJSDate()} />
                    </div>} />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label>
                  <input type="checkbox" checked={!!until} onChange={this.toggleUntil} />
                  End Date
                </label>
                {!!until ? <Dropdown size="md" allowContentClicks trigger={() => <Button block dropdown>
                        {until.toFormat('LL/dd/yy')}
                      </Button>} content={({
                close
              }) => <div className="p-sm">
                        <Calendar onChange={date => {
                  this.updateEndDate(date);
                  close();
                }} minDate={dtstart.toJSDate()} value={until.toJSDate()} />
                      </div>} /> : <Button disabled block dropdown />}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <label>Start Time</label>
                <Dropdown size="block" contentHeight={280} trigger={() => <Button block dropdown>
                      {getTime(rrule.options.byhour[0], rrule.options.byminute[0])}
                    </Button>} content={() => <ul className="select-list">
                      {timeSlots.map(slot => <li key={slot.time}>
                          <a onClick={() => this.updateTime(slot.hour, slot.minute)}>
                            {slot.time}
                          </a>
                        </li>)}
                    </ul>} />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label>
                  <input type="checkbox" checked={!!rrule.options.count} onChange={this.toggleCount} />
                  # Occurrences
                </label>
                <input type="number" className="form-control" disabled={!rrule.options.count} value={rrule.options.count || ''} onChange={e => this.update({
                rrule: {
                  count: parseInt(e.target.value) < 1 ? 1 : e.target.value
                }
              })} />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <label>Timezone</label>
                <TypeAhead items={moment.tz.names()} onChange={this.selectTimezone} value={timezone} />
              </div>
            </div>
          </div>

          <div className="recurring-divider" />

          <div className="form-group">
            <label>Recurrence</label>
            <Dropdown size="block" trigger={() => <Button dropdown block>
                  {this.getFrequency().name}
                </Button>} content={() => <ul className="select-list">
                  {patternOptions.map(option => <li key={option.name}>
                      <a onClick={() => this.setPattern(option)}>
                        <div className="select-list__content">
                          {option.name}
                        </div>
                        {this.getFrequency().name === option.name && <Icon className="select-list__selected" size={14} name="fa-regular-check" />}
                      </a>
                    </li>)}
                </ul>} />
          </div>

          {this.getFrequency().pattern === 'daily' && <Daily update={this.update} data={data} rrule={rrule} />}

          {this.getFrequency().pattern === 'weekly' && <Weekly update={this.update} data={data} rrule={rrule} />}

          {this.getFrequency().pattern === 'monthly' && <Monthly update={this.update} rrule={rrule} data={data} />}

          {this.getFrequency().pattern === 'quarterly' && <Quarterly update={this.update} rrule={rrule} data={data} />}

          {this.getFrequency().pattern === 'annually' && <Annually update={this.update} rrule={rrule} data={data} />}
        </div>
      </Scoped>;
  }
}
const css = {
  styles: `[kremling="i34"] body,body[kremling="i34"] {
  --color-primary: #487aae;
  --color-highlight: #e7efff;
  --color-accent: #354052;
  --color-success: #73b56e;
  --color-warning: #f4b707;
  --color-danger: #df5651;
  --color-grey-10: #f9f9f9;
  --color-grey-25: #f3f3f3;
  --color-grey-50: #e9e9e9;
  --color-grey-75: #e3e3e3;
  --color-grey-100: #d3d3d3;
  --color-grey-200: #c3c3c3;
  --color-grey-300: #b3b3b3;
  --color-grey-400: #a3a3a3;
  --color-grey-500: #808080;
  --color-grey-600: #707070;
  --color-grey-700: #606060;
  --color-grey-800: #505050;
  --color-grey-900: #404040;
  --base-font-family: Roboto, Helvetica, Arial, sans-serif;
  --base-font-size: 1.4rem;
  --base-font-color: #404040;
  --base-font-weight: 400;
  --base-line-height: 1.4;
  --box-shadow-1: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.12);
  --box-shadow-2: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-3: 0 10px 20px rgba(0, 0, 0, 0.17), 0 6px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-4: 0 14px 28px rgba(0, 0, 0, 0.13), 0 10px 10px rgba(0, 0, 0, 0.1);
  --box-shadow-5: 0 19px 38px rgba(0, 0, 0, 0.18), 0 15px 12px rgba(0, 0, 0, 0.1);
  --base-border-radius: 0.4rem;
}

[kremling="i34"] .recurring-divider,[kremling="i34"].recurring-divider {
  padding-top: 0.4rem;
  margin-bottom: 1.6rem;
  border-bottom: solid 1px #d3d3d3;
  margin-left: -1.6rem;
  margin-right: -1.6rem;
}

[kremling="i34"] .recurring-inline-with-text,[kremling="i34"].recurring-inline-with-text {
  margin-left: 0.8rem;
  margin-right: 0.8rem;
  width: 6.2rem;
}

[kremling="i34"] .recurring-inline-with-selection,[kremling="i34"].recurring-inline-with-selection {
  margin-right: 0.8rem;
  display: inline-block;
  width: auto;
  margin-bottom: 0.8rem;
}

[kremling="i34"] .recurring-inline-days input,[kremling="i34"].recurring-inline-days input {
  margin-right: 0;
}

[kremling="i34"] .recurring-inline-days label,[kremling="i34"].recurring-inline-days label {
  width: 6rem;
}

[kremling="i34"] .recurring-option,[kremling="i34"].recurring-option {
  border-radius: 0.4rem;
  border: solid 1px #d3d3d3;
  padding: 0.8rem 0.8rem 0.8rem 1.2rem;
  margin-bottom: 0.8rem;
  user-select: none;
  text-transform: uppercase;
  color: #808080;
  font-size: 12px;
  font-weight: 500;
  display: block;
}
[kremling="i34"] .recurring-option input,[kremling="i34"] .recurring-option select,[kremling="i34"].recurring-option input,[kremling="i34"].recurring-option select {
  font-weight: 500;
  color: #808080;
  font-size: 1.2rem;
  text-transform: uppercase;
}
[kremling="i34"] .recurring-option input[type=radio],[kremling="i34"].recurring-option input[type=radio] {
  margin-right: 1.2rem;
}
[kremling="i34"] .recurring-option.recurring-option--active,[kremling="i34"].recurring-option.recurring-option--active {
  border-color: #487aae;
}`,
  id: 'i34',
  namespace: 'kremling'
};