import * as React from 'react';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import CssBaseline from '@mui/material/CssBaseline';
import Alert from '@mui/material/Alert';
import { postForm } from 'shared/forms.api';
import { Loader } from '../../../components/loader/loader.component';
import { userState } from 'shared/user-state';
import Tooltip from '@mui/material/Tooltip';
import { getCompanies } from '../../../shared/common.api';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { getFacebookTokens, getFacebookAccounts } from '../../../pages/integrations/integrations.resource';
import { fetchFacebookForms, getForms } from '../../../shared/forms.api.js';

//warning this will return true on strings, do with this what you will
function isIterable(obj) {
  if (obj == null) {
    return false;
  }
  return typeof obj[Symbol.iterator] === 'function';
}
export default function FacebookLeads(props) {
  const [textFieldValue, setTextFieldValue] = React.useState(props.data?.name || '');
  const [isLoading, setIsLoading] = React.useState(true);
  const [newFormData, setNewFormData] = React.useState(props.data || null);
  const [tokens, setTokens] = React.useState([]);
  const [companies, setCompanies] = React.useState([]);
  const [forms, setForms] = React.useState(null);
  const [isMainData, setIsMainData] = React.useState(true);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [field, setField] = React.useState(null);
  React.useEffect(() => {
    if (isMainData) {
      getFacebookData();
    } else if (!isMainData) {
      Promise.all(tokens.map(token => {
        return fetchFacebookForms("5864a50e-2db6-4285-83fa-1bd6fdee4592");
      })).then(result => {
        let formattedArray = [];
        formattedArray = result.flat(1);
        let uniqueArray = [];
        uniqueArray = formattedArray.filter((value, index, self) => index === self.findIndex(t => t.place === value.place && t.name === value.name));
        formattedArray = uniqueArray.filter(element => {
          return element !== undefined;
        });
        getForms({
          entity_source: 'facebook_leads',
          limit: 1000
        }).then(result => {
          let cleanResults = formattedArray.map((data, idx) => {
            result.data.results.every((_data, idx) => {
              if (_data.entity_ref === data.id) {
                data.pre_ex_cmpny = _data.company;
                data.is_pre_ex_cmpny = true;
                return false;
              } else {
                data.is_pre_ex_cmpny = false;
                data.pre_ex_cmpny = '';
                return true;
              }
            });
            return data;
          });
          cleanResults = cleanResults.filter(element => {
            return element !== undefined;
          });
          setForms(cleanResults);
          setIsLoading(false);
        });
      });
    }
  }, [isMainData]);
  const handleListItemClick = index => {
    setSelectedIndex(index);
    setOpen(true);
  };
  const handleSetValue = data => {
    let field = data.questions.map((data, idx) => {
      let _data = {};
      _data.field = null;
      _data.label = data.label;
      _data.name = data.key;
      return _data;
    });
    setField(field);
    setSelectedItem(data);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const listOfThings = forms => {
    let accountFoundNoFormFound = false;
    let permissionsError = false;
    forms.forEach(data => {
      if (data.error_location === 'form mapping') {
        //when we get to UX find a way to tell the user that there were accounts with no forms found, and if this is unexpected to check
        // Permissions, refresh our page, make sure X user truly has that form attached to them
        accountFoundNoFormFound = true;
      } else if (data.error_location) {
        //This basically only happens when there were permissions issues, tell the user to refresh the permissions on their facebook accounts
        permissionsError = true;
      }
    });

    //clean the forms from all the errors
    forms = forms.filter(element => {
      return element.hasOwnProperty('id');
    });
    const list = forms.map((data, idx) => {
      let warningText = null;
      if (data.is_pre_ex_cmpny) {
        warningText = "You must first delete the form present in company: ";
        let company_name = null;
        userState.state.user.companies.forEach((_data, idx) => {
          if (_data.id === data.pre_ex_cmpny) {
            company_name = _data.name;
          }
        });
        //in case you don't have permission, default to id
        if (company_name === null) {
          company_name = data.pre_ex_cmpny;
        }
        warningText += company_name;
      }
      return <Tooltip title={warningText == null ? "" : warningText}>
            <ListItem key={data.id} component="div" disablePadding disabled={data.is_pre_ex_cmpny} onClick={() => handleSetValue(data)}>
                  <ListItemButton selected={selectedIndex === 0} disabled={data.is_pre_ex_cmpny} onClick={() => handleListItemClick(0)}>
                    <ListItemText primary={`${data.name}`} />
                  </ListItemButton>
                </ListItem>
            </Tooltip>;
    });
    // I want it in return ( copy-and-paste-of-the-failure-value ). I don't think you can string back to back conditional together, but I'm not sure why or what the work around is
    //		const failure =
    //        				< Stack sx={{ width: '100%' }} spacing={2} >
    //                    {permissionsError && <Alert severity="error">Permissions Issue. Update Facebook tokens for all accounts with forms</Alert>}
    //                    {accountFoundNoFormFound && <Alert severity="warning">Accounts found, but no forms found. Please check that the correct accounts are being used</Alert>}
    //                    {!permissionsError && !accountFoundNoFormFound <Alert severity="warning">Please first link a facebook account in the integrations tab</Alert>}
    //                    </Stack>

    if (forms.length === 0) {
      return <Stack sx={{
        width: '100%'
      }} spacing={2}>
            	<Alert severity="error">
            	{permissionsError ? <> Permissions Issue. Update Facebook tokens for all accounts with forms. </> : <> Please first link a facebook account in the integrations tab. </>} </Alert>
            	{accountFoundNoFormFound && <Alert severity="warning">This can be ignored. Accounts found with no owned forms. If this is not expected, please check that the correct accounts are being used.</Alert>}
            	</Stack>;
      //          return failure
    } else {
      return list;
    }
  };
  const handleSend = () => {
    postForm({
      company: userState.getAsCompanyId(),
      name: selectedItem.name,
      entity_source: 'facebook_leads',
      entity_ref: selectedItem.id,
      url: null,
      redirect: null,
      fields: field,
      entity_data: {
        "tokens": tokens.map((element, idx) => {
          return element.id;
        })
      },
      html_index: null,
      html_tag: null
    }).then(({
      data
    }) => {
      setSelectedItem(null);
      setField(null);
      setOpen(false);
    });
  };
  const getFacebookData = () => {
    let tokens = [];
    return getCompanies({
      limit: 9999
    }).then(({
      data
    }) => {
      setCompanies(data.results);
      return getFacebookTokens({
        ordering: 'name',
        user: userState.state.user.id
      });
    }).then(({
      results
    }) => {
      tokens = results;
      return getFacebookAccounts({
        ordering: 'name',
        token__user: userState.state.user.id,
        limit: 1000
      });
    }).then(({
      results
    }) => {
      tokens = tokens.map(token => {
        token.accounts = results.filter(account => account.token === token.id);
        return token;
      });
      setTokens(tokens);
      setIsMainData(false);
    });
  };
  return <>
        <CssBaseline />
        <Dialog maxWidth='md' PaperProps={{
      style: {
        borderRadius: '28px',
        minWidth: '350px'
      }
    }} open={!!props.open} onClose={(e, reason) => {
      if (reason === 'backdropClick' && !!newFormData) {
        return;
      }
      props.onClose();
    }}>
            <DialogTitle sx={{
        m: 0,
        p: 2,
        fontSize: 'medium'
      }}>
            <IconButton aria-label="close" onClick={e => {
          props.onClose();
        }} sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme => theme.palette.grey[500]
        }}>
                <CloseIcon />
                </IconButton>
            <Stack alignItems="center" component="span">
            <Typography variant="h4" component="span"> Add Facebook Leads </Typography>
            </Stack>
            </DialogTitle>
            <DialogContent dividers>
                <DialogContentText component="span">
                    {isLoading && <Loader overlay background="rgba(255,255,255,.5)" />}
                </DialogContentText>
                {!isLoading && !!forms && <Box sx={{
          width: '100%',
          height: 400,
          maxWidth: 360,
          bgcolor: 'background.paper'
        }}>

                                 <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                                         <DialogTitle id="alert-dialog-title">
                                           {"Create A Form?"}
                                         </DialogTitle>
                                         <DialogContent>
                                           <DialogContentText id="alert-dialog-description">
                                             I want to create this form?
                                           </DialogContentText>
                                         </DialogContent>
                                         <DialogActions>
                                           <Button onClick={handleClose}>Disagree</Button>
                                           <Button onClick={handleSend} autoFocus>
                                             Agree
                                           </Button>
                                         </DialogActions>
                                       </Dialog>

                                     {listOfThings(forms)}
                                 </Box>}

            </DialogContent>
            <DialogActions sx={{
        py: 1
      }}>
                {!newFormData && <Button disabled={isLoading} onClick={() => {
          props.onClose();
        }} variant="text" sx={{
          borderRadius: '20px',
          color: 'rgba(29, 37, 45, 0.7)',
          width: '75px',
          textTransform: 'none'
        }}>
                Done
                </Button>}
                {newFormData && <Button disabled={isLoading || !textFieldValue} onClick={() => {
          props.handleSubmit();
        }} variant="text" sx={{
          mr: 1,
          borderRadius: '20px',
          width: '75px',
          color: '#53A6D6',
          textTransform: 'none'
        }}>
                Close
                </Button>}
            </DialogActions>
        </Dialog>
    </>;
}