import React from 'react';
import { Scoped } from 'kremling';
import styles from './media.styles.scss';
import { Icon } from '../icon/icon.component';
import { userState } from 'shared/user-state';
function authentication_url(url, token) {
  return API_URL + "/communication/sms/twilio-download?url=" + encodeURIComponent(url) + "&_token=" + encodeURIComponent(token);
}
export function Media(props) {
  const {
    token
  } = userState.getToken();
  let content;
  if (['image/bmp', 'image/gif', 'image/jpeg', 'image/png'].includes(props.media.content_type)) {
    content = <img src={authentication_url(props.media.url, token)} style={{
      width: "100%"
    }} />;
  } else {
    content = <div>
      <Icon size={30} name="fa-regular-file" />
    </div>;
  }
  return <Scoped css={styles}>
      <a href={authentication_url(props.media.url, token)} target="_blank">
        {content}
      </a>
    </Scoped>;
}