import React, { useEffect, useState } from 'react';
import { AsyncModalDialog } from 'components/modal/async-modal-dialog.component';
import classnames from 'classnames';
import { getCompanyFields } from 'shared/common.api';
import { userState } from 'shared/user-state';
const customerFields = [[{
  size: 6,
  name: 'First Name',
  key: 'first_name'
}, {
  size: 6,
  name: 'Last Name',
  key: 'last_name'
}], [{
  size: 6,
  name: 'Email',
  key: 'email'
}, {
  size: 6,
  name: 'Phone',
  key: 'phone'
}], [{
  size: 6,
  name: 'Address',
  key: 'address1'
}, {
  size: 6,
  name: 'Address Line 2',
  key: 'address2'
}], [{
  size: 6,
  name: 'City',
  key: 'city'
}, {
  size: 2,
  name: 'ST',
  key: 'state'
}, {
  size: 4,
  name: 'Zipcode',
  key: 'zip_code'
}], [{
  size: 6,
  name: 'Country',
  key: 'country'
}, {
  size: 3,
  name: 'Lat',
  key: 'lat'
}, {
  size: 3,
  name: 'Lon',
  key: 'lon'
}], [{
  size: 4,
  name: 'Organization',
  key: 'organization'
}, {
  size: 4,
  name: 'Job Title',
  key: 'job_title'
}, {
  size: 4,
  name: 'Customer Type',
  key: 'customer_type'
}], [{
  size: 4,
  name: 'Aniversary',
  key: 'anniversary'
}, {
  size: 4,
  name: 'Birthday',
  key: 'birthday'
}, {
  size: 4,
  name: 'Gender',
  key: 'gender'
}]];
export const EditModal = props => {
  const [entityFields, setEntityFields] = useState([]);
  const editEntityData = (id, val) => {
    const {
      editData,
      setEditData
    } = props;
    const newEditData = JSON.parse(JSON.stringify(editData));
    if (!newEditData.customer.entity_data) {
      newEditData.customer.entity_data = {};
    }
    newEditData.customer.entity_data[id] = val;
    setEditData(newEditData);
  };
  const getEntityData = id => {
    const {
      editData
    } = props;
    if (!editData.customer.entity_data) {
      return '';
    }
    return editData.customer.entity_data[id] || '';
  };
  useEffect(() => {
    getCompanyFields(userState.getAsCompanyId()).then(({
      data
    }) => {
      const customerModel = data.find(i => i.id === 'customer');
      if (!customerModel) return;
      const allEntityFields = customerModel.fields.filter(i => i.type !== 'list' && i.id.substring(0, 13) === 'entity_data__' && (i.id.match(/__/g) || []).length === 1);
      const rowifiedEntityFields = [];
      for (let i = 0; i < allEntityFields.length; i++) {
        if (i % 2 === 0) {
          rowifiedEntityFields.push([]);
        }
        rowifiedEntityFields[Math.floor(i / 2)].push(allEntityFields[i]);
      }
      setEntityFields(rowifiedEntityFields);
    });
  }, []);
  const {
    showEditModal,
    setShowEditModal,
    editGroup,
    editErrors,
    setEditData,
    editData
  } = props;
  return <AsyncModalDialog open={!!showEditModal} title="Edit Customer Group" submitText="Save" onSubmit={editGroup} onClose={() => setShowEditModal(false)} allowBackdropClick size="xl">
      {editData.id && <>
          {editErrors.non_field_errors && editErrors.non_field_errors.map((e, i) => <div className="invalid-feedback text-danger mb-3" key={i}>
                {e}
              </div>)}

          <div className="row">
            <div className="col-6">
              <div className={classnames('form-group', {
            'is-invalid': editErrors.name
          })}>
                <label>Group Name</label>
                <input className="form-control" name="edit-group-name" onChange={e => setEditData({
              ...editData,
              name: e.target.value
            })} value={editData.name || ''} />
                {editErrors.name && editErrors.name.map((e, i) => <div className="invalid-feedback text-danger" key={i}>
                      {e}
                    </div>)}
              </div>
            </div>
          </div>
          <p>
            <strong>Customer</strong>
          </p>

          {customerFields.map((row, i) => <div className="row" key={`row-${i}`}>
              {row.map((field, j) => <div className={`col-${field.size}`} key={`row-${i}-field-${j}`}>
                  <div className={classnames('form-group', {
            'is-invalid': editErrors[field.key]
          })}>
                    <label>{field.name}</label>
                    <input className="form-control" name={`edit-customer-${field.key}`} onChange={e => setEditData({
              ...editData,
              customer: {
                ...editData.customer,
                [field.key]: e.target.value
              }
            })} value={editData.customer[field.key] || ''} />
                    {editErrors[field.key] && editErrors[field.key].map((e, k) => <div className="invalid-feedback text-danger" key={`row-${i}-field-${j}-error-${k}`}>
                          {e}
                        </div>)}
                  </div>
                </div>)}
            </div>)}

          {entityFields.length > 0 && <>
              <p>
                <strong>Entity Data</strong>
              </p>

              {entityFields.map((row, i) => <div className="row" key={`entity-row-${i}`}>
                  {row.map((field, j) => <div className={`col-6`} key={`entity-row-${i}-field-${j}`}>
                      <div className={classnames('form-group', {
              'is-invalid': editErrors[field.id]
            })}>
                        <label>{field.name}</label>
                        <input className="form-control" name={`edit-customer-${field.id}`} onChange={e => editEntityData(field.id, e.target.value)} value={getEntityData(field.id)} />
                        {editErrors[field.id] && editErrors[field.id].map((e, k) => <div className="invalid-feedback text-danger" key={`entity-row-${i}-field-${j}-error-${k}`}>
                              {e}
                            </div>)}
                      </div>
                    </div>)}
                </div>)}
            </>}
        </>}
    </AsyncModalDialog>;
};