import React, { Component } from 'react';
import { userState } from '../../shared/user-state';
import { Button } from '../../components/button/button.component';
import { addAdwordsAccount, createAdwordsAudience, deleteAdwordsAccount, getAdwordsAccounts, getAdwordsAudiencesNoUnwrap, syncAdwordsAudiences } from './integrations.resource';
import { toasterService } from '../../components/toaster/toaster-service';
import { ModalDialog } from '../../components/modal/modal-dialog.component';
import { ModalCompanySelect } from '../../components/modal/modal-company-select.component';
import { Pagination } from '../../components/pagination/pagination.component';
import { Loader } from '../../components/loader/loader.component';
export class IntegrationAdwords extends Component {
  static propTypes = {};
  state = {
    accounts: [],
    deleteAccount: {},
    installId: false,
    enterCustomerIDModal: false,
    clientCustomerID: '',
    createAudienceAccountID: null,
    audienceName: '',
    audienceDescription: '',
    showAudiences: false,
    accountIdSelected: null,
    audiences: [],
    isLoadingAudiences: null,
    paginationParams: {
      offset: 0,
      limit: 20
    }
  };
  componentDidMount() {
    this.adwordsRedirect().then(hasRedirect => hasRedirect && this.props.history.push('/integrations')).then(() => this.getAdwordsData()).finally(() => this.props.onLoad());
  }
  adwordsRedirect = () => {
    return new Promise(resolve => {
      const {
        match,
        location
      } = this.props;
      if (match.params.redirect === 'adwords-authorize') {
        const params = new URLSearchParams(location.search);
        if (params.get('error')) {
          toasterService.error('Unable to link Google Adwords account. Please try again. If the problem persists contact support.');
          resolve(false);
        } else {
          const installId = params.get('install_id');
          this.addAccount(installId).then(() => resolve(false));
        }
      } else {
        resolve(false);
      }
    });
  };
  getAdwordsData = () => {
    return getAdwordsAccounts({
      company: userState.getAsCompanyId()
    }).then(({
      results
    }) => this.setState({
      accounts: results
    }));
  };
  getAdwordsAudiencesData = audience_id => {
    const {
      paginationParams
    } = this.state;
    this.setState({
      isLoadingAudiences: true
    });
    getAdwordsAudiencesNoUnwrap({
      account__company: userState.getAsCompanyId(),
      account: audience_id,
      ...paginationParams
    }).then(({
      data
    }) => {
      this.setState({
        audiences: data.results,
        paginationData: data,
        isLoadingAudiences: false
      });
    });
  };
  addAccount = installId => {
    if (userState.hasMultiAccess()) {
      this.setState({
        installId
      });
      return Promise.resolve(false);
    } else {
      return this.finallyAddAccount(installId, userState.getAsCompanyId());
    }
  };
  finallyAddAccount = (installId, companyId) => {
    return addAdwordsAccount(installId, companyId).then(() => toasterService.success('Successfully added account. Audiences will start syncing momentarily')).then(() => this.getAdwordsData()).catch(err => {
      const key = Object.keys(err.response.data)[0];
      toasterService.error(err.response.data[key][0]);
    });
  };
  removeAccount = accountId => {
    return deleteAdwordsAccount(accountId).then(() => toasterService.success('Successfully deleted account')).then(() => this.getAdwordsData()).catch(() => toasterService.error('Unknown error trying delete account. Please try again.'));
  };
  syncAudiences = accountId => {
    return syncAdwordsAudiences(accountId).then(() => toasterService.success('Queued audiences for syncing.')).catch(() => toasterService.error('Unknown error trying sync audiences. Please try again.'));
  };
  createAudience = () => {
    const {
      createAudienceAccountID,
      audienceName,
      audienceDescription
    } = this.state;
    createAdwordsAudience(createAudienceAccountID, {
      name: audienceName,
      description: audienceDescription
    }).then(() => {
      this.getAdwordsAudiencesData(this.state.accountIdSelected);
      toasterService.success('Audience created successfully');
    }).catch(() => toasterService.error('Unknown error trying create audience. Please try again.'));
  };
  requestAccount = clientCustomerID => {
    window.open(`${API_URL}/adwords/account/request?client_customer_id=${clientCustomerID}`);
  };
  pageOnChange = params => {
    this.setState({
      paginationParams: params
    }, () => {
      this.getAdwordsAudiencesData(this.state.accountIdSelected);
    });
  };
  render() {
    const {
      accounts,
      deleteAccount,
      installId,
      enterCustomerIDModal,
      clientCustomerID,
      createAudienceAccountID,
      audienceName,
      showAudiences,
      audiences,
      paginationData,
      isLoadingAudiences
    } = this.state;
    return <div className="integration mb-5">
          <div className="integration__header">
            <div>Google Adwords</div>
            {userState.hasPermission('adwords.add_account') && <Button className="btn" actionType="primary" onClick={() => this.setState({
          enterCustomerIDModal: true
        })}>
                  Add Account
                </Button>}
          </div>
          <div className="integration__body">
            {accounts.map(account => <div className="integration-item" key={account.id}>
                  <div className="integration-item__inner">
                    <div className="integration-title">
                      {account.email} ({account.client_customer_id})
                    </div>

                    <div className="integration-actions">
                      {userState.hasPermission('adwords.change_audience') && <Button onClick={e => {
                e.stopPropagation();
                this.syncAudiences(account.id);
              }} actionType="flat" icon="fa-regular-sync-alt" />}
                      {userState.hasPermission('adwords.delete_account') && <Button actionType="flat" icon="fa-regular-trash" onClick={e => {
                e.stopPropagation();
                this.setState({
                  deleteAccount: account
                });
              }} />}
                    </div>
                  </div>
                  <div className="integration-item">
                    <div className="integration-item__inner ">
                      <div className="integration-title ml-2">
                        <b>
                          Audiences
                        </b>
                      </div>
                      <div className="integration-actions">
                        <Button onClick={e => {
                  e.stopPropagation();
                  this.setState({
                    showAudiences: !showAudiences,
                    accountIdSelected: account.id
                  });
                  this.getAdwordsAudiencesData(account.id);
                }} actionType="flat" icon={!showAudiences ? "fa-regular-chevron-down" : "fa-regular-chevron-up"} />
                        {userState.hasPermission('adwords.add_audience') && <Button onClick={e => {
                  e.stopPropagation();
                  this.setState({
                    createAudienceAccountID: account.id
                  });
                }} actionType="flat" icon="fa-regular-plus" />}
                      </div>
                    </div>
                  </div>
                  {showAudiences && this.state.accountIdSelected === account.id && <div className="integration__body">
                    {isLoadingAudiences ? <div className="integration-item">
                      <div className="integration-item__inner">
                        <div className="integration-item__inner">
                          <span>                            <Loader size="sm" />
                  </span>
                        </div>
                      </div>
                    </div> : audiences.length ? audiences.map(audience => <div className="integration-item" key={audience.id}>
                          <div className="integration-item__inner">
                              <span style={{
                  wordBreak: "break-all"
                }} className="ml-5">
                                {audience.name}
                              </span>
                          </div>
                        </div>) : <div className="integration-item">
                      <div className="integration-item__inner">
                        <span>This account does not have any audiences</span>
                      </div>
                    </div>}
                    {!isLoadingAudiences && audiences.length !== 0 && <Pagination data={paginationData} onChange={this.pageOnChange} />}
                  </div>}
                </div>)}
          </div>


          <ModalCompanySelect open={!!installId} onClose={() => this.setState({
        installId: false
      })} onSubmit={companyId => this.finallyAddAccount(installId, companyId)} onCancel={() => toasterService.error('You must select a company to link your account')}>
            <p>Select which company to link this Google Adwords account.</p>
          </ModalCompanySelect>

          <ModalDialog open={enterCustomerIDModal} title="Enter Client Customer ID" submitText="Authorize" onSubmit={() => this.requestAccount(clientCustomerID)} onClose={() => this.setState({
        enterCustomerIDModal: false
      })} submitDisabled={!clientCustomerID.match(/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/g)} allowBackdropClick>
            <p>
              Please enter the client customer ID you want to link to Cinch. The
              ID looks like 123-123-1234 and can be found on the accounts page of
              your Adwords account.
            </p>

            <p>
              <label>Client Customer ID</label>
              <input required className="form-control" name="client_customer_id" onChange={e => this.setState({
            clientCustomerID: e.target.value
          })} />
            </p>
          </ModalDialog>

          <ModalDialog open={!!createAudienceAccountID} title="Create Adwords Audience" submitText="Create" onSubmit={() => {
        this.setState({
          isLoadingAudiences: true
        });
        this.createAudience();
      }} onClose={() => this.setState({
        createAudienceAccountID: null
      })} submitDisabled={audienceName === ''} allowBackdropClick>
            <p>
              This will create an Adwords user list you can use in your journeys.
            </p>

            <p>
              <label>Name</label>
              <input required className="form-control" name="audience_name" onChange={e => this.setState({
            audienceName: e.target.value
          })} />
            </p>

            <p>
              <label>Description</label>
              <textarea style={{
            height: '60px'
          }} className="form-control" name="audience_description" onChange={e => this.setState({
            audienceDescription: e.target.value
          })} />
            </p>
          </ModalDialog>

          <ModalDialog open={!!deleteAccount.id} title="Remove Account" submitText="Remove" onSubmit={() => this.removeAccount(deleteAccount.id)} onClose={() => this.setState({
        deleteAccount: {}
      })} allowBackdropClick>
            <p>
              <strong>{deleteAccount.email}</strong>
            </p>
            <p>Are you sure you want to remove this account?</p>
          </ModalDialog>
        </div>;
  }
}