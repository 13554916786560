import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { Scoped } from 'kremling';
import styles from './import.styles.scss';
import { PageHeader } from 'components/page-header/page-header';
import { Button } from 'components/button/button.component';
import { Loader } from 'components/loader/loader.component';
import { userState } from 'shared/user-state';
import { SortHeader } from 'components/table/sort-header.component';
import { Pagination } from 'components/pagination/pagination.component';
import { getImports, deleteImport as apiDeleteImport } from 'shared/common.api';
import { toasterService } from 'components/toaster/toaster-service';
import { AsyncModalDialog } from 'components/modal/async-modal-dialog.component';
import { Modal } from '../../components/modal/modal.component';
export function Imports(props) {
  const [imports, setImports] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [ordering, setOrdering] = useState('-created_when');
  const [paginationParams, setPaginationParams] = useState({
    offset: 0,
    limit: 20
  });
  const [count, setCount] = useState();
  const [deleteImport, setDeleteImport] = useState();
  const [importIssuesModal, setImportIssuesModal] = useState();
  const [importIssues, setImportIssues] = useState([]);
  const loadImports = () => {
    setIsLoading(true);
    getImports({
      ...paginationParams,
      search,
      company: userState.getAsCompanyId(),
      ordering
    }).then(({
      data
    }) => {
      if (data) {
        setImports(data.results);
        setCount(data.count);
        setIsLoading(false);
      }
    });
  };
  useEffect(() => {
    loadImports();
  }, [search, ordering, paginationParams]);
  useEffect(() => {
    if (imports.find(imp => imp.status == 'pending')) {
      const interval = setInterval(() => loadImports(), 5000);
      return () => clearInterval(interval);
    }
  }, [imports]);
  const confirmDeleteImport = () => {
    setDeleteImport(null);
    return apiDeleteImport(deleteImport.id).then(() => {
      toasterService.success(`Import successfully deleted.  Customers will be deleted over the next few minutes.`);
      loadImports();
    }).catch(() => {
      toasterService.error(`An unknown error occurred while attempting to delete the import. Please try again`);
    });
  };
  const sendToSegment = imp => {
    if (imp.status == 'success') {
      props.history.push(`/segments/${imp.options.new_segment_id}`);
    }
    if (imp.status == 'error') {
      setImportIssuesModal(true);
      setImportIssues(imp.options.import_issues);
    }
  };
  return <Scoped css={styles}>
      <div className="wrapper">
        {isLoading && <Loader overlay />}

        <PageHeader name="Customer Import" onSearch={setSearch} actions={<Button onClick={() => props.history.push('/imports/upload')}>
              Upload
            </Button>} />

        <div className="wrapper-scroll p-md">
          <div className="table-container">
            <table className="table-list">
              <thead>
                <tr>
                  <SortHeader name="filename" ordering={ordering} update={setOrdering}>
                    File Name
                  </SortHeader>
                  <SortHeader name="source" ordering={ordering} update={setOrdering}>
                    Source
                  </SortHeader>
                  <SortHeader name="total_records" ordering={ordering} update={setOrdering}>
                    Records
                  </SortHeader>
                  <SortHeader name="status" ordering={ordering} update={setOrdering}>
                    Status
                  </SortHeader>
                  <SortHeader name="created_when" ordering={ordering} update={setOrdering}>
                    Date Loaded
                  </SortHeader>
                  <th>Loaded By</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>

              <tbody>
                {imports.length && !isLoading && imports.map(imp => <tr key={imp.id}>
                      <td onClick={() => sendToSegment(imp)}>{imp.filename}</td>
                      <td onClick={() => sendToSegment(imp)}>{imp.source}</td>
                      <td onClick={() => sendToSegment(imp)}>{imp.total_records}</td>
                      <td onClick={() => sendToSegment(imp)} style={{
                  textTransform: 'capitalize'
                }}>
                        {imp.status == 'pending' && <div style={{
                    float: 'left'
                  }}> <Loader size="sm" /> </div> || imp.status}
                      </td>
                      <td onClick={() => sendToSegment(imp)}>
                        {moment(imp.created_when).local().format('MMM D, YYYY h:mm A')}
                      </td>
                      <td>
                        {imp.created_by_first_name} {imp.created_by_last_name}
                      </td>
                      <td>
                        {imp.status !== 'pending' && <Button icon="fa-regular-trash" actionType="flat" onClick={e => setDeleteImport(imp)} />}
                      </td>
                    </tr>) || <tr>
                    {!isLoading && <td colSpan={6}>
                        <strong>No Results</strong>
                      </td>}
                  </tr>}
              </tbody>
            </table>
          </div>
          <div className="pt-md pb-md">
            <Pagination data={{
            count
          }} onChange={setPaginationParams} />
          </div>
        </div>
      </div>

      <AsyncModalDialog open={!!deleteImport} title="Delete Import" submitText="Confirm" onSubmit={() => confirmDeleteImport()} onClose={() => setDeleteImport(null)} actionType="danger" allowBackdropClick>
        <p>
          Are you sure you want to delete this import and all the customers
          imported from it? This cannot be undone.
        </p>
      </AsyncModalDialog>
      <Modal title="Import Issues" onClose={() => setImportIssuesModal(false)} open={!!importIssuesModal} allowBackdropClick size="lg" type="scroll">
        <div className="modal__body">
          <table className="table-list">
            <thead>
              <tr>
                <th> CSV Line</th>
                <th> Level</th>
                <th> Issue</th>
              </tr>
            </thead>
            <tbody>
              {importIssues?.length && importIssues.map((imp, index) => <tr key={index}>
                    <td>{imp.line || 0} </td>
                    <td style={{
                textTransform: 'capitalize'
              }}>{imp.level}</td>
                    <td> {imp.issue} </td>
                  </tr>) || <div>
                  <tr>
                    <td>Unknown Error</td>
                  </tr>
                  <tr>
                    <td>Contact Cinch Support for help</td>
                  </tr>
                </div>}
            </tbody>
          </table>
        </div>
        <div className="modal__actions">
          <Button actionType="primary" onClick={() => setImportIssuesModal(false)}>
            Okay
          </Button>
        </div>
      </Modal>
    </Scoped>;
}