import numeral from 'numeral';
import { Container, Sprite, TextStyle, Text, Graphics } from 'pixi.js';
import { buildSquare, buildBorder, MODES, PROCESS } from './node-utils';
import { NodeDelete } from './node-delete';
import { NodeDuplicate } from './node-duplicate';
import { NodeValid } from './node-valid';
import { NodeNumber } from './node-number';
import { NodeNumberDual } from './node-number-dual';
import { NodeConnect } from './node-connect';
import { journeyState } from '../journey-state';
import { updateNode, updateLink } from 'shared/common.api';
let sx, sy;
export class Node {
  constructor(node, initialPosition = {
    x: 0,
    y: 0
  }, hasLinks = false) {
    this.id = null;
    this.type = node.type;
    this.subType = node.subType;
    this.name = node.name;
    this.img = node.img;
    this.component = node.component;
    this.componentParams = node.componentParams;
    this.color = node.color;
    this.colorShade = node.colorShade;
    this.defaultParameters = node.defaultParameters;
    this.modalWidth = node.modalWidth;
    this.parameters = node.parameters;
    this.connectFrom = node.connectFrom;
    this.connectTo = node.connectTo;
    this.links = [];
    this.mode = journeyState.state.mode;
    this.onEventList = node.onEventList;
    this.getEvent = node.getEvent;
    this.getEventList = node.getEventList;
    this.noLink = node.noLink;
    this._isValidated = node.validate;
    this.buildLabel = node.buildLabel;
    this.initialPosition = initialPosition;
    this.hasLinks = hasLinks;
    this.history = journeyState.state.history;
    this.listen();
    this.build();
    this.onClick();
  }
  listen = () => {
    this.modeObserver = journeyState.subscribe(({
      mode
    }) => {
      this.mode = mode;
      this.onMouseOut();
    }, 'mode');
  };
  build = () => {
    this.buildContainer();
    this.buildShape();
    this.buildDelete();
    this.buildDuplicate();
    this.buildValid();
    if (journeyState.state.process === PROCESS.PUBLISHED) {
      this.buildNodeNumber();
    }
    if (!this.noLink) {
      this.buildConnect();
    }
    this.buildNodeContent();
  };
  buildContainer = () => {
    this.container = new Container();
    this.container.movement = null;
    this.container.x = this.initialPosition.x;
    this.container.y = this.initialPosition.y;
    this.container.interactive = true;
    this.container.on('mouseover', this.onMouseOver).on('mouseout', this.onMouseOut).on('pointerdown', this.onDragStart).on('pointerup', this.onDragEnd).on('pointerupoutside', this.onDragEnd).on('pointermove', this.onDragMove);
  };
  buildShape = () => {
    this.shape = buildSquare(`0x${this.color}`, 124);
    this.container.addChild(this.shape);
    this.shapeBorder = buildBorder(124);
    this.shapeBorder.alpha = 0;
    this.shape.addChild(this.shapeBorder);
  };
  buildDelete = () => {
    this.nodeDelete = new NodeDelete(this.onDelete);
    this.nodeDelete.container.x = -6;
    this.nodeDelete.container.y = -6;
    this.container.addChild(this.nodeDelete.container);
  };
  buildDuplicate = () => {
    this.nodeDuplicate = new NodeDuplicate(this.onDuplicate);
    this.nodeDuplicate.container.x = -6;
    this.nodeDuplicate.container.y = 100;
    this.container.addChild(this.nodeDuplicate.container);
  };
  buildValid = () => {
    this.nodeValid = new NodeValid();
    this.nodeValid.container.x = 104;
    this.nodeValid.container.y = -6;
    this.container.addChild(this.nodeValid.container);
    this.nodeValid.container.alpha = this.isValidated() ? 0 : 1;
  };
  buildNodeNumber = () => {
    this.numberStats = new NodeNumberDual('', '', this.colorShade, this.hasLinks);
    this.numberStats.container.position = {
      x: -5,
      y: -5
    };
    this.numberPercentage = new NodeNumber('', this.colorShade);
    this.numberPercentage.container.position = {
      x: 124 - this.numberPercentage.container.width + 5,
      y: -5
    };
    this.numberStats.container.interactive = true;
    this.numberStats.container.mouseover = () => {
      if (this.stats) {
        this.numberStats.update(numeral(this.stats[0]).format('0,0'), numeral(this.stats[1]).format('0,0'));
      }
    };
    this.numberStats.container.mouseout = () => {
      if (this.stats) {
        this.numberStats.update(numeral(this.stats[0]).format('0a'), numeral(this.stats[1]).format('0a'));
      }
    };
    this.numberStats.container.on('pointerup', this.onClick);
    this.container.addChild(this.numberPercentage.container);
    this.container.addChild(this.numberStats.container);
  };
  onClick = event => {
    if (event && event.data.button == 2) {
      journeyState.openCustomersList(this, event);
    }
  };
  updateStats = (countIn, countOut) => {
    this.stats = [countIn, countOut];
    this.numberStats.update(numeral(countIn).format('0a'), numeral(countOut).format('0a'));
    this.numberPercentage.update(`${countIn === 0 && countOut === 0 ? 0 : Math.round(countOut * 100 / countIn)}%`);
    this.numberPercentage.container.position = {
      x: 124 - this.numberPercentage.container.width + 5,
      y: -5
    };
    if (!this.hasLinks) {
      this.container.removeChild(this.numberPercentage.container);
    }
  };
  buildConnect = () => {
    this.nodeConnect = new NodeConnect(this);
    this.nodeConnect.container.x = 106;
    this.nodeConnect.container.y = 43;
    this.container.addChild(this.nodeConnect.container);
  };
  trimLabelText = (labelText, ellipse) => {
    if (labelText) {
      this.label.text = labelText + (ellipse ? '...' : '');
      if (this.label.height > 28) {
        const splitText = labelText.split('');
        return this.trimLabelText(splitText.splice(0, splitText.length - 1).join(''), true);
      }
    } else {
      this.label.text = labelText;
    }
  };
  buildNodeContent = () => {
    this.icon = new Sprite.from(this.img);
    this.icon.alpha = 0.7;
    this.icon.scale.x = 0.5;
    this.icon.scale.y = 0.5;
    const style = {
      wordWrap: true,
      wordWrapWidth: 114,
      align: 'center',
      fontSize: 12,
      fontWeight: 400,
      fill: '#ffffff'
    };
    this.text = new Text(this.name || this.parameters.name || 'Unknown Node', new TextStyle({
      ...style,
      fontWeight: 700,
      fontSize: 13
    }));
    (this.buildLabel ? this.buildLabel(this.parameters) : Promise.resolve('')).then(labelText => {
      this.label = new Text('', new TextStyle(style));
      this.trimLabelText(labelText);
      this.label.alpha = 0.8;
      const padding = 6;
      const iconHeight = 32;
      const width = 124;
      const offset = !labelText ? 18 : this.label.height > 14 ? 4 : 8;
      this.icon.x = Math.round(width / 2 - iconHeight / 2);
      this.icon.y = Math.round(width / 2 - iconHeight - this.text.height / 2 - padding + offset);
      this.text.x = Math.round(width / 2 - this.text.width / 2);
      this.text.y = Math.round(width / 2 - this.text.height / 2 + offset);
      this.label.x = Math.round(width / 2 - this.label.width / 2);
      this.label.y = Math.round(width / 2 + this.text.height / 2 + padding + offset);
      this.shape.addChild(this.icon);
      this.shape.addChild(this.text);
      this.shape.addChild(this.label);
    });
  };
  updateLabel = () => {
    const width = 124;
    if (this.buildLabel) {
      this.buildLabel(this.parameters).then(labelText => {
        this.trimLabelText(labelText);
        const padding = 6;
        const iconHeight = 32;
        const offset = !labelText ? 18 : this.label.height > 14 ? 4 : 8;
        this.icon.y = Math.round(width / 2 - iconHeight - this.text.height / 2 - padding + offset);
        this.text.y = Math.round(width / 2 - this.text.height / 2 + offset);
        this.label.x = Math.round(width / 2 - this.label.width / 2);
        this.label.y = Math.round(width / 2 + this.text.height / 2 + padding + offset);
      });
    } else {
      this.text.x = Math.round(width / 2 - this.text.width / 2);
      this.text.y = Math.round(width / 2 - this.text.height / 2 + 18);
    }
  };
  willDestroy = () => {
    this.modeObserver.unsubscribe();
    if (!this.noLink) this.nodeConnect.willDestroy();
    this.container.destroy();
  };
  isValidated = () => {
    try {
      return this._isValidated ? this._isValidated(this.parameters) : true;
    } catch {
      return false;
    }
  };
  onDelete = event => {
    if (!this.container.hasDragged && this.mode === MODES.CANVAS) {
      event.stopped = true;
      journeyState.removeNode(this);
    }
  };
  onDuplicate = event => {
    if (!this.container.hasDragged && this.mode === MODES.CANVAS) {
      event.stopped = true;
      const position = {
        x: this.initialPosition.x + 150,
        y: this.initialPosition.y
      };
      journeyState.addNode(this.subType, position, null, this.parameters);
      this.container.dragging = false;
    }
  };
  updateParameters = parameters => {
    this.parameters = parameters;
    if (this.parameters.name) {
      this.text.text = this.parameters.name;
    }
    this.updateLabel();
    this.nodeValid.container.alpha = this.isValidated() ? 0 : 1;
    updateNode(this.id, {
      parameters
    });
  };
  updateOnEvent = (linkId, onEvent) => {
    this.onEvent = onEvent.id;
    updateLink(linkId, {
      on_event: this.onEvent
    });
  };
  onMouseOver = () => {
    if (!this.id) {
      return;
    }
    if (this.mode === MODES.CANVAS) {
      this.nodeDelete.container.alpha = 1;
      this.nodeDuplicate.container.alpha = 1;
      if (!this.noLink) this.nodeConnect.container.alpha = 1;
    }
    if (this.mode === MODES.POINT_LINK && journeyState.state.nodeFrom !== this) {
      this.shapeBorder.alpha = 1;
      journeyState.pointLinkMouseOver(this);
    }
  };
  onMouseOut = () => {
    this.nodeDelete.container.alpha = 0;
    this.nodeDuplicate.container.alpha = 0;
    this.shapeBorder.alpha = 0;
    if (this.mode === MODES.POINT_LINK) {
      journeyState.pointLinkMouseOut();
    } else {
      if (!this.noLink) this.nodeConnect.container.alpha = 0;
    }
  };
  onDragStart = event => {
    if (this.mode === MODES.CANVAS) {
      this.container.dragging = true;
      this.container.movement = 0;
      this.data = event.data;
      sx = this.data.getLocalPosition(this.container).x * this.container.scale.x;
      sy = this.data.getLocalPosition(this.container).y * this.container.scale.y;
      journeyState.sortNodes(this);
    }
  };
  onDragEnd = event => {
    if (this.container.dragging) {
      this.container.dragging = false;
      this.container.hasDragged = false;
      this.data = null;
      if (journeyState.state.snapToGrid) {
        const containerInX = Math.round(this.container.x / 25) * 25;
        const containerInY = Math.round(this.container.y / 25) * 25;
        this.container.x = containerInX;
        this.container.y = containerInY;
      }
      if (this.initialPosition.x !== this.container.x || this.initialPosition.y !== this.container.y) {
        this.initialPosition = {
          x: this.container.x,
          y: this.container.y
        };
        updateNode(this.id, this.initialPosition);
      }
    }
    if (this.container.movement === 0 && this.mode === MODES.CANVAS && (this.component || this.componentParams) && event.type === 'pointerup') {
      journeyState.editParam(this);
    }
    if (this.container.movement === null && this.mode === MODES.PAN && (this.component || this.componentParams) && event.type === 'pointerup' && event.data.button != '2') {
      journeyState.editParam(this);
    }
    if (this.container.movement === null && !this.container.dragging && journeyState.state.nodeFrom !== this) {
      journeyState.addLink();
    }
    this.container.movement = null;
  };
  onDragMove = event => {
    // Chris -- I added a check to make sure there is movement in at least one direction before we mark the "hasDragged".
    // Otherwise I was having problems where I couldn't delete a node. Instead it it was opening the paramters.
    // It might be a Windows thing.
    if (this.container.dragging && (event.data.originalEvent.movementX !== 0 || event.data.originalEvent.movementY !== 0)) {
      this.container.hasDragged = true;
      const newPosition = this.data.getLocalPosition(this.container.parent);
      const x = newPosition.x - sx;
      const y = newPosition.y - sy;
      this.container.movement = (Math.abs(this.container.x - x) + Math.abs(this.container.y - y)) * 100;
      this.container.x = x <= 0 ? 0 : Math.round(x);
      this.container.y = y <= 0 ? 0 : Math.round(y);
      journeyState.updateNodeLinks(this);
    }
  };
}