import React, { Component } from 'react';
import { Scoped, k } from 'kremling';
import moment from 'moment-timezone';
import { userState } from 'shared/user-state';
import { PageHeader, Button, Dropdown, Status, Pagination, Loader } from 'components';
import { getBroadcasts, deleteBroadcast } from '../../../shared/common.api';
import { SortHeader } from '../../../components/table/sort-header.component';
import { BroadcastEmailModal } from './broadcast-email-modal.component';
export class BroadcastEmailList extends Component {
  state = {
    broadcasts: [],
    isLoading: true,
    error: null,
    search: '',
    paginationParams: {},
    loadingCreateBroadcast: false,
    ordering: '-broadcast_when',
    editBroadcast: null
  };
  componentDidMount() {
    this.loadBroadcasts();
  }
  loadBroadcasts = (editBroad = null) => {
    const {
      search,
      ordering
    } = this.state;
    this.setState({
      isLoading: true
    });
    getBroadcasts({
      ...this.state.paginationParams,
      company: userState.getAsCompanyId(),
      search: search,
      ordering
    }).then(({
      data
    }) => {
      if (data) {
        this.setState({
          broadcasts: data.results,
          isLoading: false,
          paginationData: data
        }, () => {
          if (editBroad) {
            this.setState({
              editBroadcast: this.state.broadcasts.find(b => b.id === editBroad.id)
            });
          }
        });
      }
    });
  };
  pageOnChange = params => {
    this.setState({
      paginationParams: params
    }, () => {
      this.loadBroadcasts();
    });
  };
  deleteBroadcast = broadcast => {
    deleteBroadcast(broadcast.id).then(() => {
      this.loadBroadcasts();
    });
  };
  searchBroadcasts = search => {
    this.setState({
      search
    }, () => {
      this.loadBroadcasts();
    });
  };
  updateOrdering = ordering => {
    this.setState({
      ordering
    }, () => this.loadBroadcasts());
  };
  openCreate = () => {
    this.setState({
      editBroadcast: {
        segment: null,
        segment_name: null,
        email_template: null,
        email_template_name: null,
        broadcast_when: null,
        schedule_timezone: moment.tz.guess(),
        google_campaign: '',
        distinct_emails: true,
        drip: false,
        drip_hours: null,
        send_window: false,
        window_start: "09:00:00",
        window_end: "17:00:00",
        window_days: [1, 2, 3, 4, 5],
        window_timezone: moment.tz.guess(),
        drip_timezone: moment.tz.guess()
      }
    });
  };
  render() {
    const {
      broadcasts,
      error,
      isLoading,
      paginationData,
      ordering,
      editBroadcast
    } = this.state;
    return <Scoped css={css}>
        <div className="wrapper">
          <PageHeader name="Email Broadcasts" onSearch={this.searchBroadcasts} actions={userState.hasPermission('email.add_broadcast') && <Button actionType="primary" icon="fa-regular-plus" onClick={this.openCreate} />} />
          <div className="wrapper-scroll broadcast-list">
            {isLoading && <Loader overlay />}
            {error && <p>{error.message}</p>}

            <table className="table-list">
              {!isLoading && !!broadcasts.length && <thead>
                  <tr>
                    <SortHeader name="email_template__name" ordering={ordering} update={ordering => this.updateOrdering(ordering)}>
                      Template
                    </SortHeader>
                    <SortHeader name="segment__name" ordering={ordering} update={ordering => this.updateOrdering(ordering)}>
                      Segment
                    </SortHeader>
                    <th>Status</th>
                    <SortHeader name="broadcast_when" ordering={ordering} update={ordering => this.updateOrdering(ordering)}>
                      Date
                    </SortHeader>
                    <SortHeader name="estimated_sends" ordering={ordering} update={ordering => this.updateOrdering(ordering)}>
                      Est. Sends
                    </SortHeader>
                    <SortHeader name="sends" ordering={ordering} update={ordering => this.updateOrdering(ordering)}>
                      Sends
                    </SortHeader>
                    <SortHeader name="delivers" ordering={ordering} update={ordering => this.updateOrdering(ordering)}>
                      Delivers
                    </SortHeader>
                    <SortHeader name="bounces" ordering={ordering} update={ordering => this.updateOrdering(ordering)}>
                      Bounces
                    </SortHeader>
                    <SortHeader name="opens" ordering={ordering} update={ordering => this.updateOrdering(ordering)}>
                      Opens
                    </SortHeader>
                    <SortHeader name="clicks" ordering={ordering} update={ordering => this.updateOrdering(ordering)}>
                      Clicks
                    </SortHeader>
                    <SortHeader name="unsubscribes" ordering={ordering} update={ordering => this.updateOrdering(ordering)}>
                      Unsubscribes
                    </SortHeader>
                    <th style={{
                  width: 40
                }} />
                  </tr>
                </thead>}
              <tbody>
                {broadcasts.length && !isLoading && broadcasts.map(broadcast => <tr key={broadcast.id} onClick={() => {
                if (!broadcast.broadcast_when) {
                  this.loadBroadcasts(broadcast);
                } else {
                  this.setState({
                    editBroadcast: broadcast
                  });
                }
              }}>
                      <td>{broadcast.email_template_name}</td>
                      <td>{broadcast.segment_name}</td>
                      <td>
                        {broadcast.broadcast_when && <Status status="published">Sent</Status>}
                        {!broadcast.broadcast_when && <Status status="draft">Scheduled</Status>}
                      </td>
                      <td>
                        {broadcast.broadcast_when && moment(broadcast.broadcast_when).format('MMM D, YYYY h:mm A')}
                        {!broadcast.broadcast_when && moment(broadcast.schedule_when).format('MMM D, YYYY h:mm A')}
                      </td>
                      <td>
                        {broadcast.estimated_sends === null ? '--' : broadcast.estimated_sends.toLocaleString('en-US')}
                      </td>
                      <td>{broadcast.sends.toLocaleString('en-US')}</td>
                      <td>{broadcast.delivers.toLocaleString('en-US')}</td>
                      <td>{broadcast.bounces.toLocaleString('en-US')}</td>
                      <td>{broadcast.opens.toLocaleString('en-US')}</td>
                      <td>{broadcast.clicks.toLocaleString('en-US')}</td>
                      <td>{broadcast.unsubscribes.toLocaleString('en-US')}</td>
                      <td>
                        {userState.hasPermission('email.change_broadcast', 'email.delete_broadcast') && <Dropdown horizontal="west" trigger={() => <Button actionType="flat" icon="fa-regular-ellipsis-h" />} content={({
                    close
                  }) => <ul className="select-list">
                                {!broadcast.broadcast_when && userState.hasPermission('email.change_broadcast') && <li>
                                      <a onClick={() => {
                        this.setState({
                          editBroadcast: broadcast
                        });
                        close();
                      }}>
                                        Edit Broadcast
                                      </a>
                                    </li>}
                                {userState.hasPermission('email.delete_broadcast') && <li>
                                    <a onClick={() => {
                        this.deleteBroadcast(broadcast);
                        close();
                      }}>
                                      Delete Broadcast
                                    </a>
                                  </li>}
                              </ul>} />}
                      </td>
                    </tr>) || <tr>
                    {!isLoading && <td colSpan={10}>
                        <strong>No Results</strong>
                      </td>}
                  </tr>}
              </tbody>
            </table>
            <Pagination data={paginationData} onChange={this.pageOnChange} />
          </div>

          {editBroadcast && <BroadcastEmailModal broadcast={editBroadcast} disabled={!!editBroadcast.broadcast_when} onSave={() => {
          this.loadBroadcasts();
          this.setState({
            editBroadcast: null
          });
        }} onClose={() => this.setState({
          editBroadcast: null
        })} onDelete={() => {
          this.loadBroadcasts();
          this.setState({
            editBroadcast: null
          });
        }} />}
        </div>
      </Scoped>;
  }
}
const css = {
  styles: `[kremling="i21"] body,body[kremling="i21"] {
  --color-primary: #487aae;
  --color-highlight: #e7efff;
  --color-accent: #354052;
  --color-success: #73b56e;
  --color-warning: #f4b707;
  --color-danger: #df5651;
  --color-grey-10: #f9f9f9;
  --color-grey-25: #f3f3f3;
  --color-grey-50: #e9e9e9;
  --color-grey-75: #e3e3e3;
  --color-grey-100: #d3d3d3;
  --color-grey-200: #c3c3c3;
  --color-grey-300: #b3b3b3;
  --color-grey-400: #a3a3a3;
  --color-grey-500: #808080;
  --color-grey-600: #707070;
  --color-grey-700: #606060;
  --color-grey-800: #505050;
  --color-grey-900: #404040;
  --base-font-family: Roboto, Helvetica, Arial, sans-serif;
  --base-font-size: 1.4rem;
  --base-font-color: #404040;
  --base-font-weight: 400;
  --base-line-height: 1.4;
  --box-shadow-1: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.12);
  --box-shadow-2: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-3: 0 10px 20px rgba(0, 0, 0, 0.17), 0 6px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-4: 0 14px 28px rgba(0, 0, 0, 0.13), 0 10px 10px rgba(0, 0, 0, 0.1);
  --box-shadow-5: 0 19px 38px rgba(0, 0, 0, 0.18), 0 15px 12px rgba(0, 0, 0, 0.1);
  --base-border-radius: 0.4rem;
}

[kremling="i21"] .broadcast-list,[kremling="i21"].broadcast-list {
  padding: 2rem;
}

[kremling="i21"] .broadcast-list table button,[kremling="i21"].broadcast-list table button {
  opacity: 0;
}
[kremling="i21"] .broadcast-list table tr:hover button,[kremling="i21"].broadcast-list table tr:hover button {
  opacity: 1;
}

[kremling="i21"] .broadcast-list__address,[kremling="i21"].broadcast-list__address {
  font-size: 12px;
}`,
  id: 'i21',
  namespace: 'kremling'
};