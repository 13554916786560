import { Container, Graphics, Sprite, TextStyle, Text } from 'pixi.js';
import { DropShadowFilter } from '@pixi/filter-drop-shadow';
import { flattenDeep } from 'lodash';

/**
 * Build Square
 * @param color
 * @param size
 * @return {Container}
 */
export function buildSquare(color, size) {
  const radius = 6;
  const shapeContainer = new Container();
  const shape = new Graphics();
  shape.beginFill(color);
  shape.moveTo(0, radius);
  shape.lineTo(0, size - radius);
  shape.arcTo(0, size, radius, size, radius);
  shape.lineTo(size - radius, size);
  shape.arcTo(size, size, size, size - radius, radius);
  shape.lineTo(size, radius);
  shape.arcTo(size, 0, size - radius, 0, radius);
  shape.lineTo(radius, 0);
  shape.arcTo(0, 0, 0, radius, radius);
  shape.endFill();
  const dropshadow = new DropShadowFilter({
    distance: 0,
    rotation: 0,
    alpha: 0.3,
    pixelSize: 0.5,
    blur: 5
  });
  shape.filters = [dropshadow];
  shapeContainer.addChild(shape);
  return shapeContainer;
}

/**
 * Build Border
 * A rounded rect with a stroke and without a fill
 * @param size {number}
 * @return {Graphics}
 */
export function buildBorder(size) {
  const radius = 6;
  const shape = new Graphics();
  shape.lineStyle(3, 0x000000, 0.15, 1);
  shape.moveTo(0, radius);
  shape.lineTo(0, size - radius);
  shape.arcTo(0, size, radius, size, radius);
  shape.lineTo(size - radius, size);
  shape.arcTo(size, size, size, size - radius, radius);
  shape.lineTo(size, radius);
  shape.arcTo(size, 0, size - radius, 0, radius);
  shape.lineTo(radius, 0);
  shape.arcTo(0, 0, 0, radius, radius);
  shape.endFill();
  return shape;
}

/**
 * Build Circle
 * Not currently using - but saving
 * @param color
 * @param size
 * @return {Graphics}
 */
export function buildCircle(color, size) {
  const radius = size / 2;
  const shape = new Graphics();
  shape.beginFill(color);
  shape.drawCircle(radius, radius, radius);
  shape.endFill();
  return shape;
}

/**
 * Build Octogon
 * Not currently using - but saving
 * @param color
 * @return {Graphics}
 */
export function buildOctogon(color) {
  const side = 58;
  const ratio = 1.41421356237;
  const point = side / ratio;
  const shape = new Graphics();
  shape.beginFill(color);
  shape.drawPolygon([0, point, point, 0, point + side, 0, point + side + point, point, point + side + point, point + side, point + side, point + side + point, point, point + side + point, 0, point + side]);
  shape.endFill();
  return shape;
}
export function buildBackdrop(width, height) {
  const backdrop = new Graphics();
  backdrop.interactive = true;
  backdrop.beginFill(0x000000);
  backdrop.alpha = 0.5;
  backdrop.drawRect(0, 0, width, height);
  backdrop.endFill();
  return backdrop;
}

/**
 * Nodes Can Connect
 * Each node comes with connecting rules - make sure nodes are compatible
 * @param nodeFrom {Node}
 * @param nodeTo {Node}
 * @return {boolean}
 */
export function nodesCanConnect(nodeFrom, nodeTo) {
  return nodeTo.connectFrom.includes(nodeFrom.type) && nodeFrom.connectTo.includes(nodeTo.type);
}

/**
 * Link Exists
 * don't allow duplicate or reverse duplicate links
 * @param nodeFrom {Node}
 * @param nodeTo {Node}
 * @param links {array}
 * @return {boolean}
 */
export function linkExists(nodeFrom, nodeTo, links) {
  return links.some(link => {
    return link.nodeFrom === nodeFrom && link.nodeTo === nodeTo || link.nodeFrom === nodeTo && link.nodeTo === nodeFrom;
  });
}
export const MODES = {
  POINT_LINK: 'POINT_LINK',
  EDIT_PARAM: 'EDIT_PARAM',
  CANVAS: 'CANVAS',
  TEXT: 'TEXT',
  PAN: 'PAN'
};
export const PROCESS = {
  DRAFT: 'DRAFT',
  PUBLISHED: 'PUBLISHED'
};
export const emptyState = {
  canvasSize: {
    width: 3840,
    height: 2160
  },
  revisionId: null,
  links: [],
  nodes: [],
  texts: [],
  mode: MODES.CANVAS,
  nodeEdit: null,
  linkEdit: false,
  nodeTo: null,
  nodeFrom: null,
  linksContainer: null,
  nodesContainer: null,
  stage: null,
  process: null,
  onParamEdit: 0
};
export function findRules(query) {
  let rules = [];
  const group = query.and || query.or;
  if (group) {
    rules = flattenDeep(group.map(findRules));
  } else {
    rules = query;
  }
  return rules;
}
export function filterNode(node, matchSubType) {
  return IS_PROD ? !node.subType.match(matchSubType) : true;
}
export const validateRules = obj => {
  if ('and' in obj) {
    return obj.and.length > 0 && !obj.and.some(obj => !validateRules(obj));
  } else if ('or' in obj) {
    return obj.or.length > 0 && !obj.or.some(obj => !validateRules(obj));
  } else if (!obj.field || !obj.model || !obj.operator) {
    return false;
  } else if (obj.operator.substring(0, 3) === 'is_') {
    return true;
  } else if (!obj.query_type) {
    return false;
  } else if (obj.query_type === 'value' && !obj.value) {
    return false;
  } else if (obj.query_type === 'related_field' && (!obj.related_model || !obj.related_field)) {
    return false;
  } else if (obj.query_type === 'relative_time' && (!obj.value || !obj.time_unit || !obj.time_type)) {
    return false;
  }
  return true;
};