import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { chunk } from 'lodash';
import { ModalDialog } from '../../components/modal/modal-dialog.component';
import { DynamicTypeAhead } from '../../components/dynamic-type-ahead/dynamic-type-ahead.component';
import { getMarkets, getPhoneNumbers } from '../../shared/common.api';
import { userState } from '../../shared/user-state';
const excludeFields = ['created_when', 'deleted_when', 'updated_when', 'id', 'entity_ref', 'is_active', 'lat', 'lon', 'account_id', 'company', 'entity_source', 'needs_geocode'];
export const EditModalLocation = props => {
  const {
    showEditModal,
    setShowEditModal,
    editLocation,
    editErrors,
    editData,
    allFields,
    setEditData
  } = props;
  const [markets, setMarkets] = useState([]);
  const [marketId, setMarketId] = useState(null);
  useEffect(() => {
    if (editData.id) {
      getMarkets({
        company: userState.getAsCompanyId(),
        limit: 200
      }).then(({
        data
      }) => {
        setMarkets(data.results);
        setMarketId(editData.market);
      });
    }
  }, [showEditModal]);
  const selectedMarket = markets && markets.find(market => market.id === marketId);
  return <ModalDialog open={!!showEditModal} title="Edit Location" submitText="Save" onSubmit={editLocation} onClose={() => setShowEditModal(false)} allowBackdropClick size="xl">
      {editData.id && <>
          {editErrors.non_field_errors && editErrors.non_field_errors.map((e, i) => <div className="invalid-feedback text-danger mb-3" key={i}>
                {e}
              </div>)}
          {allFields && chunk(allFields.filter(f => !excludeFields.includes(f.id)), 3).map((row, i) => {
        return <div className="row" key={`row-${i}`}>
                  {row.map((field, j) => {
            return <div className={`col-${field.size}`} key={`row-${i}-field-${j}`}>
                        <div className={classnames('form-group', {
                'is-invalid': editErrors[field.id]
              })}>
                          <label>{field.name}</label>
                          {field.id === 'market' && <DynamicTypeAhead getItems={getMarkets} getItemsFilters={{
                  company: userState.getAsCompanyId()
                }} placeholder={selectedMarket ? `${selectedMarket.name}` : 'Select a Market'} displayProperty="name" keyProperty="id" value={selectedMarket ? selectedMarket.id : null} onChange={market => {
                  setMarketId(market ? market.id : null);
                  setEditData({
                    ...editData,
                    [field.id]: market ? market.id : null
                  });
                }} />}
                          {field.id === 'sms_phone_number' && <DynamicTypeAhead getItems={getPhoneNumbers} getItemsFilters={{
                  company: userState.getAsCompanyId()
                }} placeholder="Select a Phone Number" displayProperty="name" keyProperty="id" value={editData[field.id] || null} onChange={phone => {
                  setEditData({
                    ...editData,
                    [field.id]: phone ? phone.id : null
                  });
                }} />}

                          {field.id !== 'sms_phone_number' && field.id !== 'market' && <input className="form-control" name={`edit-location-${field.id}`} onChange={e => setEditData({
                  ...editData,
                  [field.id]: e.target.value
                })} value={editData[field.id] || ''} />}
                        </div>
                      </div>;
          })}
                </div>;
      })}
        </>}
    </ModalDialog>;
};