import React, { Component } from 'react';
import { userState } from '../../shared/user-state';
import { Button } from '../../components/button/button.component';
import { Status } from '../../components/status/status.component';
import { addCallToolsToken, getCallToolsToken, deleteCallToolsToken, addCallToolsDisposition, editCallToolsDisposition, deleteCallToolsDisposition } from './integrations.resource';
import { toasterService } from '../../components/toaster/toaster-service';
import { ModalDialog } from '../../components/modal/modal-dialog.component';
export class IntegrationCallTools extends Component {
  static propTypes = {};
  state = {
    tokens: [],
    deleteToken: {},
    isAddModalOpen: false,
    token: null,
    isDispositionModalOpen: false,
    dispositionTokenID: null,
    isEditDispositionModalOpen: false,
    disposition: null,
    dispositionID: null,
    isDeleteDispositionModalOpen: false
  };
  componentDidMount() {
    this.getCallToolsData().finally(() => this.props.onLoad());
  }
  getCallToolsData = () => {
    return getCallToolsToken({
      company: userState.getAsCompanyId()
    }).then(({
      results
    }) => this.setState({
      tokens: results
    }));
  };
  addToken = tokenId => {
    return addCallToolsToken(tokenId, userState.getAsCompanyId()).then(() => toasterService.success('Successfully added token')).then(() => this.getCallToolsData()).catch(() => toasterService.error('Unknown error trying add token. Please try again.'));
  };
  removeToken = tokenId => {
    return deleteCallToolsToken(tokenId).then(() => toasterService.success('Successfully deleted token')).then(() => this.getCallToolsData()).catch(() => toasterService.error('Unknown error trying delete token. Please try again.'));
  };
  addDisposition = (disposition, tokenId) => {
    return addCallToolsDisposition(disposition, tokenId).then(() => toasterService.success('Successfully added disposition')).then(() => this.getCallToolsData()).then(() => this.setState({
      isDispositionModalOpen: true,
      isEditDispositionModalOpen: false,
      dispositionID: null,
      disposition: null
    })).catch(() => toasterService.error('Unknown error trying add disposition. Please try again.'));
  };
  editDisposition = (dispositionID, disposition) => {
    return editCallToolsDisposition(dispositionID, disposition).then(() => toasterService.success('Successfully edited disposition')).then(() => this.getCallToolsData()).then(() => this.setState({
      isDispositionModalOpen: true,
      isEditDispositionModalOpen: false,
      dispositionID: null,
      disposition: null
    })).catch(() => toasterService.error('Unknown error trying edit disposition. Please try again.'));
  };
  deleteDisposition = dispositionID => {
    return deleteCallToolsDisposition(dispositionID).then(() => toasterService.success('Successfully deleted disposition')).then(() => this.getCallToolsData()).then(() => this.setState({
      isDispositionModalOpen: true,
      isDeleteDispositionModalOpen: false,
      dispositionID: null,
      disposition: null
    })).catch(() => toasterService.error('Unknown error trying delete disposition. Please try again.'));
  };
  render() {
    const {
      tokens,
      dispositionTokenID,
      deleteToken,
      isAddModalOpen,
      isDispositionModalOpen,
      token,
      isEditDispositionModalOpen,
      disposition,
      dispositionID,
      isDeleteDispositionModalOpen
    } = this.state;
    return <div className="integration mb-5">
        <div className="integration__header">
          <div>CallTools</div>
          {userState.hasPermission('integration.add_credential') && <Button className="btn" actionType="primary" tag="a" onClick={() => this.setState({
          isAddModalOpen: true
        })}>
              Add Token
            </Button>}
        </div>
        <div className="integration__body">
          {tokens.map(token => <div className="integration-item" key={token.id}>
              <div className="integration-item__inner">
                <div className="integration-title">
                  <strong>{token.token}</strong>
                  <Status className="ml-3">
                    {token.dispositions.length}{' '}
                    {token.dispositions.length === 0 || token.dispositions.length > 1 ? 'Dispositions' : 'Disposition'}
                  </Status>
                </div>
                <div className="integration-actions">
                  {userState.hasPermission('calltools.change_disposition') && <Button actionType="flat" icon="fa-regular-edit" onClick={() => this.setState({
                isDispositionModalOpen: true,
                dispositionTokenID: token.id
              })} />}
                  {userState.hasPermission('integration.delete_credential') && <Button actionType="flat" icon="fa-regular-trash" onClick={e => {
                e.stopPropagation();
                this.setState({
                  deleteToken: token
                });
              }} />}
                </div>
              </div>
            </div>)}
        </div>

        <ModalDialog open={isAddModalOpen} title="Add API Token" onSubmit={() => this.addToken(token)} onClose={() => this.setState({
        isAddModalOpen: false
      })} allowBackdropClick>
          <p>
            Please enter the API Token for your CallTools account. It can be
            found on this page:{' '}
            <a target="_blank" href="https://app.calltools.com/api/documentation/">
              https://app.calltools.com/api/documentation/
            </a>
          </p>
          <p>
            <label>API Token</label>
            <input required className="form-control" name="token" onChange={e => this.setState({
            token: e.target.value
          })} />
          </p>
        </ModalDialog>

        <ModalDialog open={isDispositionModalOpen} title="Edit Dispositions" onClose={() => this.setState({
        isDispositionModalOpen: false
      })} cancelText="Done" allowBackdropClick>
          {dispositionTokenID && !tokens.find(token => token.id === dispositionTokenID).dispositions.length && <p>
                <i>No dispositions specified.</i>
              </p>}

          {dispositionTokenID && tokens.find(token => token.id === dispositionTokenID).dispositions.map(disposition => <div className="d-flex" key={disposition.id}>
                  <div className="flex-grow">{disposition.name}</div>
                  <div>
                    <Button actionType="flat" icon="fa-regular-edit" onClick={() => this.setState({
              isDispositionModalOpen: false,
              isEditDispositionModalOpen: true,
              disposition: disposition.name,
              dispositionID: disposition.id
            })} />
                    <Button actionType="flat" icon="fa-regular-trash" onClick={() => this.setState({
              isDispositionModalOpen: false,
              isDeleteDispositionModalOpen: true,
              disposition: disposition.name,
              dispositionID: disposition.id
            })} />
                  </div>
                </div>)}

          <Button actionType="primary" onClick={() => this.setState({
          isDispositionModalOpen: false,
          isEditDispositionModalOpen: true,
          disposition: null,
          dispositionID: null
        })}>
            Add Disposition
          </Button>
        </ModalDialog>

        <ModalDialog open={!!deleteToken.id} title="Remove Token" submitText="Remove" onSubmit={() => this.removeToken(deleteToken.id)} onClose={() => this.setState({
        deleteToken: {}
      })} allowBackdropClick>
          <p>
            <strong>{deleteToken.token}</strong>
          </p>
          <p>Are you sure you want to delete this token?</p>
        </ModalDialog>

        <ModalDialog open={isEditDispositionModalOpen} title={(!!dispositionID ? 'Edit' : 'Add') + ' Disposition'} onSubmit={() => !!dispositionID ? this.editDisposition(dispositionID, disposition) : this.addDisposition(disposition, dispositionTokenID)} onClose={() => this.setState({
        isEditDispositionModalOpen: false,
        isDispositionModalOpen: true,
        disposition: null,
        dispositionID: null
      })} allowBackdropClick>
          <p>
            <label>Disposition Name</label>
            <input required className="form-control" name="disposition" value={disposition || ''} onChange={e => this.setState({
            disposition: e.target.value
          })} />
          </p>
        </ModalDialog>

        <ModalDialog open={!!isDeleteDispositionModalOpen} title="Remove Disposition" submitText="Remove" onSubmit={() => this.deleteDisposition(dispositionID)} onClose={() => this.setState({
        dispositionID: null,
        disposition: null,
        isDeleteDispositionModalOpen: false,
        isDispositionModalOpen: true
      })} allowBackdropClick>
          <p>
            <strong>{disposition}</strong>
          </p>
          <p>Are you sure you want to delete this disposition?</p>
        </ModalDialog>
      </div>;
  }
}