import { Container, Graphics, Sprite } from 'pixi.js';
import trashImg from '../assets/trash.png';
export class NodeDelete {
  constructor(onDelete) {
    this.container = this.build();
    this.container.interactive = true;
    this.container.on('mouseover', this._onMouseOver).on('mouseout', this._onMouseOut).on('click', event => {
      onDelete(event);
    });
    this._hookId = 0;
    this._hooks = [];
  }
  build = () => {
    const container = new Container();
    const size = 26;
    const radius = size / 2;
    const innerRed = new Graphics();
    innerRed.lineStyle(2, 0xffffff);
    innerRed.beginFill(0xdf5651);
    innerRed.drawCircle(radius, radius, radius);
    innerRed.endFill();
    this.icon = new Sprite.from(trashImg);
    this.icon.scale.x = 0.37;
    this.icon.scale.y = 0.37;
    this.icon.x = 5.8;
    this.icon.y = 5.7;
    this.icon.alpha = 0.7;
    container.addChild(innerRed);
    container.addChild(this.icon);
    container.alpha = 0;
    return container;
  };
  addHook = (name, callback) => {
    this._hooks = [...this._hooks, {
      id: this._hookId,
      name,
      callback
    }];
    const removeHook = () => this.removeHook(this._hookId);
    this._hookId += 1;
    return removeHook;
  };
  removeHook = id => {
    this._hooks = this._hooks.filter(hook => hook.id !== id);
  };
  callHook = (name, event) => {
    this._hooks.forEach(hook => {
      if (hook.name === name) hook.callback(event);
    });
  };
  _onMouseOver = e => {
    this.icon.alpha = 1;
    this.callHook('mouseover', e);
  };
  _onMouseOut = e => {
    this.icon.alpha = 0.7;
    this.callHook('mouseout', e);
  };
}