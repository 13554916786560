import React, { useState, useEffect } from 'react';
import { Scoped } from 'kremling';
import moment from 'moment';
import 'react-toastify/dist/ReactToastify.min.css';
import { PageHeader } from '../../components/page-header/page-header';
import styles from './users.styles.scss';
import { Button } from '../../components/button/button.component';
import { Dropdown } from '../../components/dropdown/dropdown.component';
import { userState } from '../../shared/user-state';
import { Loader } from '../../components/loader/loader.component';
import { Pagination } from '../../components/pagination/pagination.component';
import { getUsers, getRoles } from '../../shared/common.api';
import { deleteUserCompany, inviteUser as inviteUserAPI, updateUserCompany } from '../../shared/common.api';
import { SortHeader } from '../../components/table/sort-header.component';
import { ModalDialog } from '../../components/modal/modal-dialog.component';
import { Modal } from '../../components/modal/modal.component';
import { toasterService } from '../../components/toaster/toaster-service';
const UsersList = props => {
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [ordering, setOrdering] = useState();
  const [paginationParams, setPaginationParams] = useState({
    offset: 0,
    limit: 20
  });
  const [count, setCount] = useState();
  const [inviteUser, setInviteUser] = useState(false);
  const [inviteUserEmail, setInviteUserEmail] = useState('');
  const [inviteUserRole, setInviteUserRole] = useState('');
  const [inviteUserFirstName, setInviteUserFirstName] = useState('');
  const [inviteUserLastName, setInviteUserLastName] = useState('');
  const [removeUser, setRemoveUser] = useState();
  const [changeRole, setChangeRole] = useState();
  const [changeRoleRole, setChangeRoleRole] = useState('');
  useEffect(() => {
    if (userState.hasPermission('authentication.view_role')) {
      getRoles({
        offset: 0,
        limit: 1000,
        company: userState.getAsCompanyId(),
        ordering: 'name'
      }).then(({
        data
      }) => {
        if (data) {
          setRoles(data.results);
        }
      });
    }
  }, []);
  useEffect(() => {
    setIsLoading(true);
    getUsers({
      ...paginationParams,
      search,
      ordering,
      company: userState.getAsCompanyId()
    }).then(({
      data
    }) => {
      if (data) {
        setUsers(data.results);
        setCount(data.count);
        setIsLoading(false);
      }
    });
  }, [search, ordering, paginationParams]);
  const actuallyRemoveUser = () => {
    if (userState.state.user.id === removeUser.id) {
      toasterService.error('You cannot remove yourself');
    } else {
      deleteUserCompany(removeUser.user_to_company).then(() => {
        setPaginationParams({
          ...paginationParams,
          offset: 0
        });
        toasterService.success('Successfully removed user');
      }).catch(() => {
        toasterService.error('An unknown error occured. Please try again.');
      });
    }
  };
  const actuallyInviteUser = () => {
    inviteUserAPI({
      first_name: inviteUserFirstName,
      last_name: inviteUserLastName,
      company: userState.getAsCompanyId(),
      email: inviteUserEmail,
      role: inviteUserRole
    }).then(() => {
      setInviteUser(false);
      setInviteUserEmail('');
      setInviteUserRole('');
      setInviteUserFirstName('');
      setInviteUserLastName('');
      setPaginationParams({
        ...paginationParams,
        offset: 0
      });
      toasterService.success('User invitation sent.');
    }).catch(err => {
      if (err?.response?.data?.non_field_errors) {
        toasterService.error(err?.response?.data?.non_field_errors[0]);
      } else {
        toasterService.error('An unknown error occured. Please try again.');
      }
    });
  };
  const actuallyChangeRole = () => {
    updateUserCompany(changeRole.user_to_company, {
      role: changeRoleRole
    }).then(() => {
      setChangeRole(false);
      setChangeRoleRole('');
      setPaginationParams({
        ...paginationParams,
        offset: 0
      });
      toasterService.success('User role updated');
    }).catch(() => {
      toasterService.error('An unknown error occured. Please try again.');
    });
  };
  return <Scoped css={styles}>
      <div className="wrapper">
        <PageHeader name="Users" onSearch={setSearch} actions={userState.hasPermission('authentication.add_usertocompany') && <Button actionType="primary" icon="fa-regular-plus" onClick={() => {
        setInviteUser(true);
      }} />} />
        <div className="wrapper-scroll user-list">
          {isLoading && <Loader overlay />}

          <table className="table-list">
            {!isLoading && !!users.length && <thead>
                <tr>
                  <SortHeader name="first_name" ordering={ordering} update={setOrdering}>
                    First Name
                  </SortHeader>
                  <SortHeader name="last_name" ordering={ordering} update={setOrdering}>
                    Last Name
                  </SortHeader>
                  <SortHeader name="email" ordering={ordering} update={setOrdering}>
                    User Name
                  </SortHeader>
                  <SortHeader name="created_when" ordering={ordering} update={setOrdering}>
                    Created
                  </SortHeader>
                  <SortHeader name="last_login" ordering={ordering} update={setOrdering}>
                    Last Login
                  </SortHeader>
                  <th>Role</th>
                  <th style={{
                width: 50
              }} />
                </tr>
              </thead>}
            <tbody>
              {users.length && !isLoading && users.map(user => <tr key={user.id} onClick={() => props.history.push(`/users/${user.id}`)}>
                    <td>{user.first_name}</td>
                    <td>{user.last_name}</td>
                    <td>{user.email}</td>
                    <td> {moment(user.created_when).format('MMM D, YYYY')}</td>
                    <td>
                      {' '}
                      {user.last_login === null ? 'N/A' : moment(user.last_login).format('MMM D, YYYY')}
                    </td>
                    <td>
                      {user.is_superuser === true ? 'Superuser' : user.role}
                    </td>
                    <td>
                      {userState.hasPermission('authentication.change_usertocompany', 'authentication.delete_usertocompany') && <Dropdown horizontal="west" trigger={() => <Button actionType="flat" icon="fa-regular-ellipsis-h" />} content={() => <ul className="select-list">
                              {userState.hasPermission('authentication.change_usertocompany') && <li onClick={() => {
                    setChangeRole(user);
                    const r = roles.find(role => role.display_name == user.role);
                    if (r) setChangeRoleRole(r.id);
                  }}>
                                  <a>Change Role</a>
                                </li>}
                              {userState.hasPermission('authentication.delete_usertocompany') && <li onClick={() => setRemoveUser(user)}>
                                  <a>Remove User</a>
                                </li>}
                            </ul>} />}
                    </td>
                  </tr>) || <tr>
                  {!isLoading && <td colSpan={6}>
                      <strong>No Results</strong>
                    </td>}
                </tr>}
            </tbody>
          </table>
          <Pagination data={{
          count
        }} onChange={setPaginationParams} />
        </div>
      </div>

      <ModalDialog open={!!removeUser} onClose={() => setRemoveUser(undefined)} allowBackdropClick title="Remove User" onSubmit={actuallyRemoveUser} submitText="Remove User" actionType="danger">
        Are you sure you want to remove this user from this company?
      </ModalDialog>

      <Modal open={!!inviteUser} onClose={() => setInviteUser(false)} allowBackdropClick title="Invite User">
        <div className="modal__body">
          <p>Enter the information for the user you want to invite:</p>
          <div className="form-group">
            <label>First Name</label>
            <input className="form-control" name="inviteUserFirstName" onChange={e => setInviteUserFirstName(e.target.value)} type="text" value={inviteUserFirstName} />
          </div>
          <div className="form-group">
            <label>Last Name</label>
            <input className="form-control" name="inviteUserLastName" onChange={e => setInviteUserLastName(e.target.value)} type="text" value={inviteUserLastName} />
          </div>
          <div className="form-group">
            <label>Email Address</label>
            <input className="form-control" name="inviteUserEmail" onChange={e => setInviteUserEmail(e.target.value)} type="email" value={inviteUserEmail} />
          </div>
          <div className="form-group">
            <label>Role</label>
            <select className="form-control" name="inviteUserRole" onChange={e => setInviteUserRole(e.target.value)} value={inviteUserRole}>
              <option value=""></option>
              {roles.map(role => <option key={role.id} value={role.id}>
                  {role.display_name}
                </option>)}
            </select>
          </div>
        </div>
        <div className="modal__actions">
          <Button actionType="flat" onClick={() => setInviteUser(false)}>
            Cancel
          </Button>
          <Button actionType="primary" disabled={inviteUserRole === '' || inviteUserEmail === '' || inviteUserFirstName === '' || inviteUserLastName === ''} onClick={actuallyInviteUser}>
            Invite
          </Button>
        </div>
      </Modal>

      <Modal open={!!changeRole} onClose={() => setChangeRole(false)} allowBackdropClick title="Change Role">
        <div className="modal__body">
          <p>To what role do you want to change this user:</p>
          <div className="form-group">
            <label>Role</label>
            <select className="form-control" name="changeRoleRole" onChange={e => setChangeRoleRole(e.target.value)} value={changeRoleRole}>
              <option value=""></option>
              {roles.map(role => <option key={role.id} value={role.id}>
                  {role.display_name}
                </option>)}
            </select>
          </div>
        </div>
        <div className="modal__actions">
          <Button actionType="flat" onClick={() => setChangeRole(false)}>
            Cancel
          </Button>
          <Button actionType="primary" disabled={changeRoleRole === ''} onClick={actuallyChangeRole}>
            Update
          </Button>
        </div>
      </Modal>
    </Scoped>;
};
export default UsersList;