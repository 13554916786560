import React, { useState, useEffect, useContext } from 'react';
import { PageHeader, Loader, Button, Icon, Save, toasterService, Status } from 'components';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { v4 as uuid } from 'uuid';
import { createSurvey, createSurveyRevision, updateSurveyQuestion, createSurveyQuestion, getSurvey, getSurveyQuestions, deleteSurveyQuestion } from 'shared/common.api';
import { userState } from '../../shared/user-state';
import { updateSurvey, updateSurveyRevision } from '../../shared/common.api';
import { Scoped } from 'kremling';
import styles from './surveys-edit.styles.scss';
import moment from 'moment-timezone';
import { DateTime } from 'luxon';
import { WorkingAsContext } from '../../context/working-as-context';
export const SurveysEdit = props => {
  const [survey, setSurvey] = useState({
    name: 'Untitled'
  });
  const [revision, setRevision] = useState({});
  const [saving, setSaving] = useState(false);
  const [revisions, setRevisions] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [canEdit, setCanEdit] = useState(false);
  const {
    checkCompany
  } = useContext(WorkingAsContext);

  /**
   * On revision select, load that revision
   */
  useEffect(() => {
    if (revision && revision.id) {
      setCanEdit(!revision.published_when && userState.hasPermission('surveys.change_question'));
      getSurveyQuestions({
        revision: revision.id,
        limit: 1000
      }).then(({
        data
      }) => {
        setQuestions(data.results);
      });
    }
  }, [revision]);

  /**
   * Load page based on url parameters.  Either "create" or survey ID
   */
  useEffect(() => {
    if (props.match.params.id === 'create') {
      // Create empty survey
      createSurvey({
        company: userState.getAsCompanyId(),
        title: 'Untitled'
      }).then(({
        data: survey
      }) => {
        setSurvey(survey);
        return createSurveyRevision({
          survey: survey.id
        });
      }).then(({
        data: revision
      }) => {
        setRevision(revision);
        setRevisions([revision]);

        // Update URL with the generated survey's ID
        const newurl = window.location.protocol + '//' + window.location.host + '/surveys/' + revision.survey;
        window.history.pushState({
          path: newurl
        }, '', newurl);
        setLoading(false);
      });
    } else if (props.match.params.id) {
      // Load survey
      // check for different company link
      getSurvey(props.match.params.id).then(({
        data: survey
      }) => {
        checkCompany(survey.company).then(() => {
          setSurvey(survey);
          setRevision(survey.revisions.length > 1 ? survey.revisions[1] : survey.revisions[0]);
          setRevisions(survey.revisions);
          setLoading(false);
        }).catch(() => {
          // User chose not to switch companies.
        });
      });
    }
  }, [props.match.params.id]);

  /**
   * Update survey title from page header quick edit.
   *
   * @param {string} title
   * @returns {Promise} Promise for API call to update survey
   */
  const updateTitle = title => {
    setSurvey({
      ...survey,
      title
    });
    return updateSurvey(survey.id, {
      title
    });
  };

  /**
   * Reorder questions based on React drag-and-drop event
   *
   * @param {*} event React drag-and-drop drag event
   * @returns null
   */
  const onDragEnd = event => {
    if (!event.destination || event.source.index === event.destination.index) {
      // We didn't drop anywhere new so don't update
      return;
    }

    // Reorder array and update order values.
    const newQuestions = JSON.parse(JSON.stringify(questions));
    const element = newQuestions[event.source.index];
    newQuestions.splice(event.source.index, 1);
    newQuestions.splice(event.destination.index, 0, element);
    newQuestions.forEach((q, index) => q.order = index);
    setQuestions(newQuestions);
  };

  /**
   * Helper function to update an individual item on a question.
   *
   * Creates a clone of the questions array, updates the individual value
   * specified and then updates the questions state.
   *
   * @param {int} index
   * @param {string} key
   * @param {string} value
   */
  const updateQuestion = (index, key, value) => {
    const updatedQuestions = JSON.parse(JSON.stringify(questions));
    updatedQuestions[index][key] = value;
    setQuestions(updatedQuestions);
  };

  /**
   * Helper function to display the revision name which is either Draft
   * or Revision # Published: <published_when>
   *
   * @param {string} revision
   * @param {int} num
   * @returns {string}
   */
  const getRevisionName = (revision, num) => {
    if (revision.published_when) {
      return `Revision #${num} Published: ${moment(revision.published_when).format('MMM D, YYYY')}`;
    }
    return 'Draft';
  };

  /**
   * Publish current revision.
   *
   * First, save all of the questions in case there are any updates.
   * Second, save the current timestamp as the revisions published when which
   * effectively publishes the revision
   * Third, create a new draft revision with the current questions.
   *
   * @returns {null}
   */
  const publishRevision = () => {
    if (!questions.length) {
      toasterService.error('Survey must have at least one question');
      return;
    }
    setSaving(true);
    let updatedRevision;
    performSaveRevision() // Update questions
    .then(() => updateSurveyRevision(revision.id, {
      // publish revision
      published_when: DateTime.local().toUTC().toISO()
    })).then(({
      data: revision
    }) => {
      setRevision(revision);
      updatedRevision = revision;
      return createSurveyRevision({
        survey: revision.survey
      });
    }).then(({
      data: newRevision
    }) => {
      setRevisions([newRevision, updatedRevision, ...revisions.slice(1)]);
      return Promise.all(questions.map(question => createSurveyQuestion({
        ...question,
        revision: newRevision.id
      })));
    }).catch(e => {
      toasterService.error('Unable to publish revision. All fields are required.');
    }).then(() => {
      setSaving(false);
    });
  };

  /**
   * Update or create each question for the revision
   *
   * @returns {Promise}
   */
  const performSaveRevision = () => {
    const updatedQuestions = JSON.parse(JSON.stringify(questions));
    return Promise.all(updatedQuestions.map(question => {
      if (question.new) {
        return createSurveyQuestion({
          q_type: question.q_type,
          q_text: question.q_text,
          order: question.order,
          revision: revision.id
        }).then(({
          data
        }) => {
          question.id = data.id;
          question.new = false;
        });
      } else {
        return updateSurveyQuestion(question.id, {
          q_type: question.q_type,
          q_text: question.q_text,
          order: question.order,
          revision: revision.id
        });
      }
    })).then(() => setQuestions(updatedQuestions));
  };

  /**
   * Save all of the questions when pushing the Save button in the header
   */
  const saveRevision = () => {
    setSaving(true);
    performSaveRevision().catch(() => {
      toasterService.error('Unable to save revision. All fields are required.');
    }).then(() => {
      setSaving(false);
    });
  };

  /**
   * Soft delete a survey question and update the state.
   *
   * @param {*} question
   */
  const deleteQuestion = question => {
    setSaving(true);
    let promise;
    if (question.new) {
      promise = Promise.resolve();
    } else {
      promise = deleteSurveyQuestion(question.id);
    }
    promise.then(() => {
      setQuestions(questions.filter(q => q.id !== question.id));
      setSaving(false);
    });
  };
  return <Scoped css={styles}>
      <div className="wrapper">
        <PageHeader name={survey.title} updateName={userState.hasPermission('surveys.change_survey') ? title => updateTitle(title) : null} actions={<>
              {canEdit ? <>
                  {saving && <Save saving={saving} />}
                  <Button onClick={() => saveRevision()}>Save Changes</Button>
                  <Button onClick={() => publishRevision()} actionType="primary">
                    Publish Revision
                  </Button>
                </> : <Status status={revision.published_when ? 'published' : 'draft'}></Status>}

              <select className="form-control inline" value={revision.id} onChange={e => setRevision(revisions.find(r => r.id === e.target.value))}>
                {revisions.map((revision, index) => <option key={revision.id} value={revision.id}>
                    {getRevisionName(revision, revisions.length - index)}
                  </option>)}
              </select>
            </>} />
        <div className="wrapper-scroll">
          {loading && <Loader overlay />}

          <div className="survey-edit">
            <DragDropContext onDragEnd={event => onDragEnd(event)}>
              <Droppable droppableId="droppable">
                {provided => <div className="question-wrapper" {...provided.droppableProps} ref={provided.innerRef}>
                    {questions.map((question, index) => <Draggable key={question.id} draggableId={question.id} index={index} isDragDisabled={!canEdit}>
                        {provided => <div className="question-item" key={question.id} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                            <div className="question-item__inner">
                              {canEdit && <div className="quetion-item__icon">
                                  <Icon name="fa-solid-bars" fill="#808080" size={14} />
                                </div>}
                              <div className="quetion-item__inputs">
                                <div className="form-group">
                                  <label>Question</label>
                                  <input className="form-control" value={question.q_text} disabled={!canEdit} onChange={e => updateQuestion(index, 'q_text', e.target.value)} />
                                </div>
                                <div className="form-group">
                                  <label>Question Type</label>
                                  <select className="form-control" value={question.q_type} disabled={!canEdit} onChange={e => updateQuestion(index, 'q_type', e.target.value)}>
                                    <option value="excellent_to_poor">
                                      Excellent to Poor
                                    </option>
                                    <option value="highly_to_unlikely">
                                      Highly to Unlikely
                                    </option>
                                    <option value="yes_no">Yes/No</option>
                                    <option value="comment">Comment</option>
                                    <option value="one_to_five">
                                      One to Five
                                    </option>
                                    <option value="one_to_ten">
                                      One to Ten
                                    </option>
                                  </select>
                                </div>
                              </div>
                              {canEdit && <div className="quetion-item__icon">
                                  <Button icon="fa-regular-trash" actionType="flat" onClick={() => deleteQuestion(question)} />
                                </div>}
                            </div>
                          </div>}
                      </Draggable>)}{' '}
                    {provided.placeholder}
                  </div>}
              </Droppable>
            </DragDropContext>

            {canEdit && <Button actionType="primary" onClick={() => {
            setQuestions(questions => [...questions, {
              id: uuid(),
              new: true,
              order: questions.length,
              q_type: 'excellent_to_poor',
              q_text: 'How was...'
            }]);
          }}>
                Add Question
              </Button>}
          </div>
        </div>
      </div>
    </Scoped>;
};