import React, { Component } from 'react';
import { a, Scoped, k } from 'kremling';
import moment from 'moment-timezone';
import { PageHeader } from '../../components/page-header/page-header';
import { getMail, getPrintRevision } from '../../shared/common.api';
import { Loader } from '../../components/loader/loader.component';
import { userState } from '../../shared/user-state';
import { Status } from '../../components/status/status.component';
import { WorkingAsContext } from '../../context/working-as-context';
export class TemplateMailPublished extends Component {
  static contextType = WorkingAsContext;
  state = {
    template: {},
    loadTemplate: true,
    proofs: [],
    selectedProof: 0,
    selectedRevisionID: ''
  };
  componentDidMount() {
    let {
      id,
      revisionID
    } = this.props.match.params;
    this.loadData(id, revisionID);
  }
  componentWillReceiveProps(nextProps) {
    let {
      id,
      revisionID
    } = nextProps.match.params;
    this.loadData(id, revisionID);
  }
  loadData(id, revisionID) {
    getMail(id).then(({
      data
    }) => {
      // check for different company link
      this.context.checkCompany(data.company).then(() => {
        let mail = data;
        if (!mail.revisions.find(r => !r.published_when)) {
          mail.revisions.unshift({});
        }
        if (!revisionID) {
          let revision = data.revisions.find(r => !!r.published_when);
          revisionID = revision.id;
        }
        getPrintRevision(revisionID).then(({
          data
        }) => {
          this.setState({
            template: mail,
            loadTemplate: false,
            selectedRevisionID: revisionID,
            proofs: [`${CC_API}/${userState.getAsCompanyId()}/${data.canvas_id}/0_0%5b968x968%5d.png`, `${CC_API}/${userState.getAsCompanyId()}/${data.canvas_id}/1_0%5b968x968%5d.png`]
          });
        });
      });
    }).catch(error => {
      console.error(error);
      this.setState({
        error,
        isLoading: false
      });
    });
  }
  loadRevision(revisionID) {
    const {
      template
    } = this.state;
    if (revisionID !== '') {
      this.props.history.push(`/templates/print/${template.id}/${revisionID}`);
    } else {
      this.props.history.push(`/templates/edit/print/${template.id}`);
    }
  }
  render() {
    const {
      loadTemplate,
      template,
      selectedProof,
      proofs,
      selectedRevisionID
    } = this.state;
    return <Scoped css={css}>
        <div className="wrapper">
          <PageHeader name={template.name} actions={<>
                <div>
                  <select className="form-control" value={selectedRevisionID} onChange={e => this.loadRevision(e.target.value)}>
                    {!!template && !!template.revisions && template.revisions.map(revision => <option key={revision.id} value={revision.published_when ? revision.id : ''}>
                          {!revision.published_when ? 'Draft' : `Published ${moment(revision.published_when).format('MMM D, YYYY h:mm A')}`}
                        </option>)}
                  </select>
                </div>
                <Status status="published" />
              </>} />
          <div className="wrapper__inner">
            {loadTemplate && <Loader overlay />}
            <div className="preview-proof">
              {proofs.map((proof, index) => <div className={a('preview-proof__item').m('active', selectedProof === index)} onClick={() => this.setState({
              selectedProof: index
            })} key={index.toString()}>
                  <div className="preview-proof__item-inner">
                    <img src={proof} />
                  </div>
                  {index === 0 ? 'Front' : 'Back'}
                </div>)}
            </div>
            {!!proofs.length && <div className="preview-current">
                <img src={proofs[selectedProof]} />
              </div>}
          </div>
        </div>
      </Scoped>;
  }
}
const css = {
  styles: `[kremling="i16"] body,body[kremling="i16"] {
  --color-primary: #487aae;
  --color-highlight: #e7efff;
  --color-accent: #354052;
  --color-success: #73b56e;
  --color-warning: #f4b707;
  --color-danger: #df5651;
  --color-grey-10: #f9f9f9;
  --color-grey-25: #f3f3f3;
  --color-grey-50: #e9e9e9;
  --color-grey-75: #e3e3e3;
  --color-grey-100: #d3d3d3;
  --color-grey-200: #c3c3c3;
  --color-grey-300: #b3b3b3;
  --color-grey-400: #a3a3a3;
  --color-grey-500: #808080;
  --color-grey-600: #707070;
  --color-grey-700: #606060;
  --color-grey-800: #505050;
  --color-grey-900: #404040;
  --base-font-family: Roboto, Helvetica, Arial, sans-serif;
  --base-font-size: 1.4rem;
  --base-font-color: #404040;
  --base-font-weight: 400;
  --base-line-height: 1.4;
  --box-shadow-1: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.12);
  --box-shadow-2: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-3: 0 10px 20px rgba(0, 0, 0, 0.17), 0 6px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-4: 0 14px 28px rgba(0, 0, 0, 0.13), 0 10px 10px rgba(0, 0, 0, 0.1);
  --box-shadow-5: 0 19px 38px rgba(0, 0, 0, 0.18), 0 15px 12px rgba(0, 0, 0, 0.1);
  --base-border-radius: 0.4rem;
}

[kremling="i16"] .wrapper__inner,[kremling="i16"].wrapper__inner {
  padding: 1.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

[kremling="i16"] .preview-proof,[kremling="i16"].preview-proof {
  display: flex;
  margin-bottom: 2rem;
  width: 100%;
  max-width: 96.7rem;
  flex-shrink: 0;
}

[kremling="i16"] .preview-proof__item,[kremling="i16"].preview-proof__item {
  text-align: center;
  padding: 0.6rem;
  border-radius: 0.4rem;
  cursor: pointer;
  position: relative;
}

[kremling="i16"] .preview-proof__item.active,[kremling="i16"].preview-proof__item.active {
  background-color: var(--primary-background-color);
}

[kremling="i16"] .preview-proof__item-inner,[kremling="i16"].preview-proof__item-inner {
  width: 8rem;
  position: relative;
  border: solid 0.1rem #d3d3d3;
  padding: 0.3rem;
  border-radius: 0.3rem;
  background-color: #fff;
  margin-bottom: 0.4rem;
}

[kremling="i16"] .preview-proof__item img,[kremling="i16"].preview-proof__item img {
  width: 100%;
}

[kremling="i16"] .preview-current,[kremling="i16"].preview-current {
  max-width: 96.7rem;
  max-height: 96.7rem;
  background-color: #fff;
  margin-bottom: 3.2rem;
}

[kremling="i16"] .preview-current img,[kremling="i16"].preview-current img {
  width: 100%;
}

[kremling="i16"] .preview-title,[kremling="i16"].preview-title {
  text-align: center;
  margin-bottom: 1rem;
}

[kremling="i16"] .preview-proof__check,[kremling="i16"].preview-proof__check {
  height: 2rem;
  width: 2rem;
  position: absolute;
  right: 0.3rem;
  top: 0.3rem;
  border-radius: 2rem;
  background-color: #73b56e;
}

[kremling="i16"] .preview-proof__check svg,[kremling="i16"].preview-proof__check svg {
  fill: #fff;
  height: 1.4rem;
  width: 1.4rem;
}`,
  id: 'i16',
  namespace: 'kremling'
};