import React, { Component } from 'react';
import { Scoped, m } from 'kremling';
import { Redirect } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';
import { getJourneys, getTemplates, getTemplatesTags, deleteEmail, patchEmail, deleteMail, postPrints, postEmail, patchPrints, duplicateMail, duplicateEmail, getEmailTemplateTemplates, getPrintTemplateTemplates, createPrintFromTemplate, createEmailFromTemplate, getBroadcasts, getSmsBroadcasts, createNewFolder, getCompanyFolders, updateFolder, deleteFolder, postSms, duplicateSms, patchSms, deleteSms, createSmsFromTemplate } from '../../shared/common.api';
import { Badge } from '../../components/badge/badge.component';
import { Loader } from '../../components/loader/loader.component';
import { Button } from '../../components/button/button.component';
import { PageHeader } from '../../components/page-header/page-header';
import { Dropdown } from '../../components/dropdown/dropdown.component';
import { Pagination } from '../../components/pagination/pagination.component';
import { Modal } from '../../components/modal/modal.component';
import { userState } from '../../shared/user-state';
import styles from './template-list.styles.scss';
import { Status } from '../../components/status/status.component';
import { SortHeader } from '../../components/table/sort-header.component';
import { DynamicTypeAhead } from 'components/dynamic-type-ahead/dynamic-type-ahead.component';
import { TagModal } from '../../components/tags/tag-modal.component';
import { Tags } from '../../components/tags/tags.component';
import utils from '../../shared/utils';
import { DateTime } from 'luxon';
import { Icon } from '../../components/icon/icon.component';
import Tooltip from 'rc-tooltip';
import NewTemplateDialog from './new-template-dialog.component';
import CssBaseline from '@mui/material/CssBaseline';
import { snackbarService } from 'components/mui/snackbar/snackbar-service';
const templateSizes = {
  '11x6': {
    width: 11,
    length: 6
  },
  '6x4': {
    width: 6,
    length: 4
  }
};
const renderIcon = item => {
  if (item == 'folder') return <Icon name="fa-regular-folder" className="mr-3" />;
  return <Icon name="fa-solid-mail-bulk" className="mr-3" />;
};
const paramsFilters = ['filters', 'search', 'ordering', 'archiveMode'];
export class TemplateList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      currentTemplateId: null,
      selectedTemplate: null,
      publishedJourneys: [],
      error: null,
      templates: [],
      redirect: {
        active: false,
        to: {
          pathname: '',
          state: {}
        }
      },
      is_loading: false,
      paginationParams: {},
      modalType: '',
      openDeleteTemplate: false,
      openCanvasLayoutState: false,
      openEmailMailSelectionState: false,
      /*MailOptions*/
      redirectToEditMail: false,
      redirectToCopyMail: false,
      filters: null,
      // Tony is here:
      isTemplateModalOpen: false,
      templateLoading: false,
      templateType: 'mail',
      templateSize: '11x6',
      emailTemplateId: '',
      printTemplateId: '',
      ordering: '-updated_when',
      archiveMode: false,
      archiveModalId: null,
      templateInBroadcast: [],
      templateTags: [],
      filterDrawerOpen: false,
      folderData: [],
      dragItem: null,
      newFolderCreate: null,
      newFolderName: "",
      currentFolderView: [{
        name: 'Home',
        id: null
      }],
      renameFolderModal: null,
      renameFolderName: '',
      deleteFolderModal: null,
      addressBarWidth: 500,
      showAddressBar: true,
      smsTemplateId: ''
    };
    this.updateHistory = utils.updateHistory.bind(this);
    this.loadStateFromParams = utils.loadStateFromParams.bind(this);
  }
  postPrints = () => {
    this.setState({
      templateLoading: true
    });
    if (this.state.printTemplateId !== '') {
      createPrintFromTemplate(this.state.printTemplateId, userState.getAsCompanyId()).then(({
        data
      }) => {
        this.setState({
          redirect: {
            active: true,
            to: {
              pathname: `/templates/edit/print/${data.id}`
            }
          }
        });
      });
    } else {
      postPrints({
        name: 'Untitled',
        company: userState.getAsCompanyId(),
        substrate_width_inches: templateSizes[this.state.templateSize].width,
        substrate_length_inches: templateSizes[this.state.templateSize].length
      }).then(({
        data
      }) => {
        this.setState({
          redirect: {
            active: true,
            to: {
              pathname: `templates/edit/print/${data.id}`
            }
          }
        });
      });
    }
  };
  postEmail = () => {
    this.setState({
      templateLoading: true
    });
    if (this.state.emailTemplateId !== '') {
      createEmailFromTemplate(this.state.emailTemplateId, userState.getAsCompanyId()).then(({
        data
      }) => {
        this.setState({
          redirect: {
            active: true,
            to: {
              pathname: `/templates/edit/email/${data.id}`
            }
          }
        });
      });
    } else {
      postEmail({
        name: 'Untitled',
        company: userState.getAsCompanyId()
      }).then(({
        data
      }) => {
        this.setState({
          redirect: {
            active: true,
            to: {
              pathname: `/templates/edit/email/${data.id}`,
              isFromCreate: true
            }
          }
        });
      });
    }
  };
  createTemplateOpen = () => {
    this.setState({
      isTemplateModalOpen: true,
      templateLoading: false,
      templateType: 'email',
      templateSize: '11x6'
    });
  };
  createTemplateCancel = () => {
    this.setState({
      isTemplateModalOpen: false
    });
  };
  updateTemplateSize = templateSize => {
    this.setState({
      templateSize
    });
  };
  updateTemplateType = templateType => {
    this.setState({
      templateType
    });
  };

  /**
   * FETCH FUNCTIONS FOR LISTING TEMPLATES
   */

  getTemplatesTags = () => {
    getTemplatesTags(userState.getAsCompanyId()).then(({
      data
    }) => {
      const templatesTags = data.results.map((tag, index) => {
        return {
          tag: tag,
          id: index
        };
      });
      this.setState({
        templatesTags
      });
    });
  };
  checkForCallbacks = () => {
    if (!!this.props.history.location.state) {
      if (this.props.history.location.state.type == 'snackbar') {
        snackbarService.popup({
          message: this.props.history.location.state.message
        });
      }
    }
  };
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions();
    this.loadStateFromParams(this.state, paramsFilters, this.showTemplates);
    this.getTemplatesTags();
    this.checkForCallbacks();
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }
  showTemplates = () => {
    const {
      search,
      ordering,
      filters,
      archiveMode
    } = this.state;
    const parent = this.state.currentFolderView.at(-1)?.id ? this.state.currentFolderView.at(-1)?.id : 'home';
    this.setState({
      isLoading: true
    });
    const promises = [getCompanyFolders({
      model: 'template',
      company: userState.getAsCompanyId(),
      parent: parent
    }), getTemplates({
      ...this.state.paginationParams,
      ...(filters || {}),
      search: search,
      archived: archiveMode,
      company: userState.getAsCompanyId(),
      ordering,
      folders: search !== '' ? null : parent
    })];
    Promise.all(promises).then(([folderData, data]) => {
      this.setState({
        folderData: folderData.data.results,
        templates: data.data.results,
        paginationData: data.data,
        isLoading: false
      });
    }).catch(error => this.setState({
      error,
      isLoading: false
    }));
  };
  searchTemplates = search => {
    this.setState({
      search
    }, () => {
      this.updateHistory(this.state, paramsFilters);
      this.showTemplates();
    });
  };
  onFilter = filters => {
    this.setState({
      filters
    }, () => {
      this.updateHistory(this.state, paramsFilters);
      this.showTemplates();
    });
  };
  pageOnChange = params => {
    this.setState({
      paginationParams: params
    }, () => {
      this.updateHistory(this.state, paramsFilters);
      this.showTemplates();
    });
  };
  openDeleteEmailTemplateModal = e => {
    this.setState({
      openDeleteTemplate: true
    });
  };
  closeDeleteEmailTemplateModal = e => {
    this.setState({
      currentTemplateId: ''
    });
    this.setState({
      openDeleteTemplate: false
    });
  };
  /*****
   * Mail functions to open modals
   * ****/
  openDeleteMailTemplateModal = e => {
    this.setState({
      openDeleteTemplate: true
    });
  };
  closeDeleteEmailTemplateModal = e => {
    this.setState({
      currentTemplateId: ''
    });
    this.setState({
      openDeleteTemplate: false
    });
  };
  handleEmailTemplateToDelete = (e, id) => {
    this.loadPublishedJourneys(id).then(() => {
      this.openDeleteEmailTemplateModal();
      this.setState({
        currentTemplateId: id,
        modalType: 'email'
      });
    });
  };
  deleteEmailTemplate = () => {
    deleteEmail(this.state.currentTemplateId).then(() => {
      this.showTemplates();
      this.closeDeleteEmailTemplateModal();
    }).catch(error => {
      toast.error('Oops, something went wrong!');
    });
  };
  loadEmailBroadcasts = id => {
    return getBroadcasts({
      company: userState.getAsCompanyId(),
      email_template: id,
      broadcast_when: false
    }).then(data => {
      this.setState({
        templateInBroadcast: data.data.results
      });
    });
  };
  loadPublishedJourneys = id => {
    return getJourneys({
      company: userState.getAsCompanyId(),
      node_template: id,
      archived: false
    }).then(companies => {
      this.setState({
        publishedJourneys: companies.data.results
      });
    });
  };

  /***Delete **/
  handleMailTemplateToDelete = (e, id) => {
    this.loadPublishedJourneys(id).then(() => {
      this.openDeleteMailTemplateModal();
      this.setState({
        currentTemplateId: id,
        modalType: 'mail'
      });
    });
  };
  deleteMailTemplate = () => {
    deleteMail(this.state.currentTemplateId).then(() => {
      this.closeDeleteEmailTemplateModal();
      toast.success('Mail Template deleted successfully!', {
        autoClose: 2000,
        onClose: this.toastClosed
      });
    }).catch(error => {
      console.error(error.message);
      toast.error('Oops, something went wrong!');
    });
  };
  handleSmsTemplateToDelete = (e, id) => {
    const promises = [getSmsBroadcasts({
      company: userState.getAsCompanyId(),
      sms_template: id,
      broadcast_when: false
    }), getJourneys({
      company: userState.getAsCompanyId(),
      node_template: id,
      archived: false
    })];
    Promise.all(promises).then(([data, companies]) => {
      this.openDeleteEmailTemplateModal();
      this.setState({
        templateInBroadcast: data.data.results,
        publishedJourneys: companies.data.results,
        currentTemplateId: id,
        modalType: 'sms'
      });
    });
  };
  deleteSmsTemplate = () => {
    deleteSms(this.state.currentTemplateId).then(() => {
      this.showTemplates();
      this.closeDeleteEmailTemplateModal();
    }).catch(error => {
      toast.error('Oops, something went wrong!');
    });
  };

  /**Delete END */

  toastClosed = () => {
    window.location.reload();
  };
  getLinkToTemplate = template => {
    const id = template.id;
    if (template.type === 'mail') {
      return template.published ? `/templates/print/${id}` : `/templates/edit/print/${id}`;
    } else if (template.type === 'email') {
      return `/templates/edit/email/${id}`;
    } else if (template.type === 'sms') {
      return `/templates/edit/sms/${id}`;
    }
  };

  /**
   * ARCHIVE TEMPLATE
   */

  closeArchiveModal = () => {
    this.setState({
      archiveModalId: null
    });
  };
  openArchiveModal = template => {
    this.setState({
      templateInBroadcast: [],
      publishedJourneys: []
    });
    this.loadPublishedJourneys(template.id).then(() => {
      if (template.type === 'mail') {
        this.setState({
          archiveModalId: template.id,
          currentTemplate: template
        });
      } else if (template.type === 'email') {
        this.loadEmailBroadcasts(template.id).then(() => this.setState({
          archiveModalId: template.id,
          currentTemplate: template
        }));
      } else if (template.type === 'sms') {
        //TODO: [CIN-1527] Confirm these endpoints actually work as expected once sms templates are implemented across site
        const promises = [getSmsBroadcasts({
          company: userState.getAsCompanyId(),
          sms_template: template.id,
          broadcast_when: false
        }), getJourneys({
          company: userState.getAsCompanyId(),
          node_template: template.id,
          archived: false
        })];
        Promise.all(promises).then(([data, companies]) => {
          this.setState({
            templateInBroadcast: data.data.results,
            publishedJourneys: companies.data.results,
            archiveModalId: template.id,
            currentTemplate: template
          });
        });
      }
    });
  };
  archiveTemplate = () => {
    const {
      currentTemplate
    } = this.state;
    if (currentTemplate.type === 'email') {
      patchEmail(currentTemplate.id, {
        archived_when: DateTime.local()
      }).then(() => {
        this.showTemplates();
        this.closeArchiveModal();
      });
    } else if (currentTemplate.type === 'mail') {
      patchPrints(currentTemplate.id, {
        archived_when: DateTime.local()
      }).then(() => {
        this.showTemplates();
        this.closeArchiveModal();
      });
    } else if (currentTemplate.type === 'sms') {
      patchSms(currentTemplate.id, {
        archived_when: DateTime.local()
      }).then(() => {
        this.showTemplates();
        this.closeArchiveModal();
      });
    }
  };
  restoreTemplate = template => {
    if (template.type === 'email') {
      patchEmail(template.id, {
        archived_when: null
      }).then(() => this.showTemplates());
    } else if (template.type === 'mail') {
      patchPrints(template.id, {
        archived_when: null
      }).then(() => this.showTemplates());
    } else if (template.type === 'sms') {
      patchSms(template.id, {
        archived_when: null
      }).then(() => this.showTemplates());
    }
  };
  duplicateTemplate = template => {
    if (template.type === 'mail') {
      duplicateMail(template.id).then(({
        data
      }) => this.props.history.push(`/templates/edit/print/${data.id}`));
    } else if (template.type === 'email') {
      duplicateEmail(template.id).then(({
        data
      }) => this.props.history.push(`/templates/edit/email/${data.id}`));
    } else {
      duplicateSms(template.id).then(({
        data
      }) => this.props.history.push(`/templates/edit/sms/${data.id}`));
    }
    ;
  };
  updateOrdering = ordering => {
    this.setState({
      ordering
    }, () => {
      this.updateHistory(this.state, paramsFilters);
      this.showTemplates();
    });
  };
  handleArchiveMode = () => {
    const {
      archiveMode
    } = this.state;
    this.setState({
      archiveMode: !archiveMode,
      search: '',
      paginationParams: {}
    }, () => {
      this.updateHistory(this.state, paramsFilters);
      this.showTemplates();
    });
  };
  addTagFromTemplateteRow = (e, tag) => {
    e.stopPropagation();
    this.setState({
      filterDrawerOpen: true
    });
    let filters = this.state.filters ? this.state.filters : {};
    if (filters && filters.tags && filters.tags.length !== 0) {
      if (!filters.tags.includes(tag)) {
        filters.tags.push(tag);
      }
    } else {
      filters.tags = [];
      filters.tags.push(tag);
    }
    this.onFilter(filters);
  };

  /**
      * FOLDERS
      */
  makeNewFolder = newFolderName => {
    this.setState({
      isLoading: true
    });
    const parent = this.state.currentFolderView.at(-1)?.id;
    createNewFolder({
      name: newFolderName,
      model: 'template',
      parent: parent,
      company: userState.getAsCompanyId()
    }).then(() => {
      getCompanyFolders({
        model: 'template',
        company: userState.getAsCompanyId(),
        parent: this.state.currentFolderView.at(-1)?.id ? this.state.currentFolderView.at(-1)?.id : 'home'
      }).then(results => {
        this.setState({
          folderData: results.data.results,
          isLoading: false
        });
      });
    });
  };
  renameFolder = () => {
    this.setState({
      isLoading: true
    });
    updateFolder(this.state.renameFolderModal, {
      name: this.state.renameFolderName
    }).then(() => {
      this.showTemplates();
    });
  };
  deleteFolders = () => {
    this.setState({
      isLoading: true
    });
    const id = this.state.deleteFolderModal;
    this.setState({
      deleteFolderModal: null
    });
    deleteFolder(id).then(() => {
      this.showTemplates();
    });
  };
  handleEnterDirectory = folder => {
    this.setState({
      isLoading: true,
      currentFolderView: [...this.state.currentFolderView, folder]
    }, () => {
      this.showTemplates();
    });
  };
  handleEnterTreeDirectory = (f, index) => {
    if (f.id == this.state.currentFolderView.at(-1)?.id) {
      return;
    }
    this.setState({
      isLoading: true
    });
    const popCount = -(this.state.currentFolderView.length - index - 1);
    this.setState({
      currentFolderView: this.state.currentFolderView.slice(0, popCount)
    }, () => {
      this.showTemplates();
    });
  };
  handleDragStart = template => {
    this.setState({
      dragItem: template
    });
  };
  handleDragEnter = e => {
    e.target.parentNode.style.backgroundColor = "grey";
  };
  handleDragLeave = e => {
    e.target.parentNode.style.backgroundColor = "white";
  };
  handleDrop = (e, folder) => {
    if (this.state.dragItem.type === 'email') {
      patchEmail(this.state.dragItem.id, {
        folder: folder.id
      }).then(() => {
        this.showTemplates();
      });
    } else if (this.state.dragItem.type === 'mail') {
      patchPrints(this.state.dragItem.id, {
        folder: folder.id
      }).then(() => {
        this.showTemplates();
      });
    } else if (this.state.dragItem.type === 'sms') {
      patchSms(this.state.dragItem.id, {
        folder: folder.id
      }).then(() => {
        this.showTemplates();
      });
    }
    e.target.parentNode.style.backgroundColor = "white";
  };
  moveUpDirectory = () => {
    this.setState({
      currentFolderView: this.state.currentFolderView.slice(0, -1)
    }, () => {
      this.showTemplates();
    });
  };
  moveUpToRoot = template => {
    this.setState({
      isLoading: true
    });
    if (template.type === 'email') {
      patchEmail(template.id, {
        folder: null
      }).then(() => {
        this.showTemplates();
      });
    } else if (template.type === 'mail') {
      patchPrints(template.id, {
        folder: null
      }).then(() => {
        this.showTemplates();
      });
    } else if (template.type === 'sms') {
      patchSms(template.id, {
        folder: null
      }).then(() => {
        this.showTemplates();
      });
    }
  };
  updateDimensions = () => {
    this.setState({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight
    }, () => {
      if (this.state.windowWidth > 1250) {
        this.setState({
          addressBarWidth: 500
        });
        this.setState({
          showAddressBar: true
        });
      } else if (this.state.windowWidth < 1250 && this.state.windowWidth > 860) {
        this.setState({
          addressBarWidth: this.state.windowWidth - 750
        });
        this.setState({
          showAddressBar: true
        });
      } else {
        this.setState({
          showAddressBar: false
        });
      }
    });
  };
  postSms = () => {
    this.setState({
      templateLoading: true
    });
    if (this.state.smsTemplateId !== '') {
      createSmsFromTemplate(this.state.smsTemplateId, userState.getAsCompanyId()).then(({
        data
      }) => {
        this.setState({
          redirect: {
            active: true,
            to: {
              pathname: `/templates/edit/sms/${data.id}`
            }
          }
        });
      });
    } else {
      postSms({
        name: 'Untitled',
        company: userState.getAsCompanyId()
      }).then(({
        data
      }) => {
        this.setState({
          redirect: {
            active: true,
            to: {
              pathname: `/templates/edit/sms/${data.id}`,
              isFromCreate: true
            }
          }
        });
      });
    }
  };
  handleNewFormDialogContinue = template => {
    this.setState({
      isTemplateModalOpen: false
    });
    if (template.type == 'email') {
      this.setState({
        emailTemplateId: template.id
      }, () => {
        this.postEmail();
      });
    }
    if (template.type == 'sms') {
      this.setState({
        smsTemplateId: template.id
      }, () => {
        this.postSms();
      });
    }
    if (template.type == 'mail') {
      this.updateTemplateSize(template.mailTemplateSize);
      this.setState({
        printTemplateId: template.id
      }, () => {
        this.postPrints();
      });
    }
  };
  render() {
    const {
      templates,
      redirect,
      isLoading,
      error,
      paginationData,
      isTemplateModalOpen,
      templateLoading,
      templateType,
      templateSize,
      currentTemplateId,
      publishedJourneys,
      ordering,
      emailTemplateId,
      printTemplateId,
      emailTemplates,
      printTemplates,
      selectedTemplate,
      templatesTags,
      search,
      filters,
      archiveMode,
      templateInBroadcast,
      filterDrawerOpen,
      currentFolderView,
      folderData,
      addressBarWidth,
      showAddressBar
    } = this.state;
    if (redirect.active) {
      return <Redirect to={redirect.to} />;
    }
    const currentTemplate = templates.find(template => template.id === currentTemplateId) || {};
    return <Scoped css={styles}>
        <div className="wrapper">
          <PageHeader name={archiveMode ? 'Templates - Archive' : 'Templates'} searchVal={search} onSearch={this.searchTemplates} filterDrawerOpen={filterDrawerOpen} hasFolders={true} preactions={<>
                <Button onClick={() => this.handleArchiveMode()} actionType={archiveMode ? 'primary' : 'grey'}>
                  {archiveMode ? 'Exit Archive' : 'View Archive'}
                </Button>
              </>} actions={<>
                {userState.hasPermission('template.add_template') && <Button actionType="primary" icon="fa-regular-plus" onClick={this.createTemplateOpen} />}
              </>} leftactions={<>
                {showAddressBar && <div className='form-control' style={{
            width: addressBarWidth + 'px',
            overflow: 'hidden'
          }}>
                    <table>
                      <tbody style={{
                float: 'right',
                marginTop: '3px',
                cursor: 'pointer',
                whiteSpace: 'nowrap'
              }}>
                        <tr>
                          {currentFolderView.map((f, index) => <td key={f.id} onClick={() => {
                    this.handleEnterTreeDirectory(f, index);
                  }}>
                            {f.name == 'Home' ? <Icon name="fa-regular-home-alt" className="mr-3"></Icon> : <>{f.name}</>}
                                <Icon name="fa-regular-angle-right"></Icon>
                            </td>)}
                        </tr>
                      </tbody>
                    </table>
                  </div>}
                {this.state.currentFolderView.length > 1 && <Tooltip placement="bottom" align={{
            points: ['tl', 'tr'],
            offset: [0, 35],
            targetOffset: ['100%', '0%'],
            overflow: {
              adjustX: true,
              adjustY: true
            }
          }} overlay="Move up a directory">
                  <span>
                    <Button icon="fa-regular-arrow-up" actionType="grey" onClick={() => {
                this.moveUpDirectory();
              }} /></span>
                  </Tooltip>}
                {userState.hasPermission('template.add_template') && <Tooltip placement="bottom" align={{
            points: ['tl', 'tr'],
            offset: [0, 35],
            targetOffset: ['100%', '0%'],
            overflow: {
              adjustX: true,
              adjustY: true
            }
          }} overlay="Create new folder">
                  <Button icon="fa-regular-folder-plus" actionType="grey" onClick={() => {
              this.setState({
                newFolderCreate: true
              });
            }} />
                  </Tooltip>}
              </>} tags={templatesTags} onFilter={filters => this.onFilter(filters)} filterVals={filters} filters={[{
          key: 'name',
          label: 'Template Name',
          type: 'text'
        }, {
          key: 'tags',
          label: 'Tags',
          type: 'typeahead',
          options: templatesTags
        }, {
          key: 'status',
          label: 'Status',
          type: 'options',
          options: ['draft', 'published']
        }, {
          key: 'created',
          label: 'Created',
          type: 'range'
        }, {
          key: 'type',
          label: 'Type',
          type: 'options',
          options: ['mail', 'email', 'sms']
        }, {
          key: 'created_by',
          label: 'Created By',
          type: 'text'
        }]} />

          <ToastContainer />

          <div className="wrapper-scroll template-list">
            {isLoading && <Loader overlay />}
            {error && <p>{error.message}</p>}

            <table className="table-list">
              {!!templates.length && <thead>
                  <tr>
                    <th style={{
                  width: 50
                }} />
                    <SortHeader name="name" ordering={ordering} update={ordering => this.updateOrdering(ordering)}>
                      Template Name
                    </SortHeader>
                    <SortHeader name="tags" ordering={ordering} update={ordering => this.updateOrdering(ordering)}>
                      Tags
                    </SortHeader>
                    <SortHeader name="published" ordering={ordering} update={ordering => this.updateOrdering(ordering)}>
                      Status
                    </SortHeader>
                    <SortHeader name="created_when" ordering={ordering} update={ordering => this.updateOrdering(ordering)}>
                      Created
                    </SortHeader>
                    <SortHeader name="type" ordering={ordering} update={ordering => this.updateOrdering(ordering)}>
                      Type
                    </SortHeader>
                    <SortHeader name="created_by" ordering={ordering} update={ordering => this.updateOrdering(ordering)}>
                      Created By
                    </SortHeader>
                    <th style={{
                  width: 40
                }} />
                  </tr>
                </thead>}
              <tbody>
              {search === '' && folderData.map(folder => <tr key={folder.id} onClick={() => {
                this.handleEnterDirectory(folder);
              }} onDragEnter={e => this.handleDragEnter(e)} onDragLeave={e => this.handleDragLeave(e)} onDrop={e => this.handleDrop(e, folder)} onDragOver={e => e.preventDefault()}>
                    {!!templates.length && <td>
                        {renderIcon('folder')}
                      </td> || <td style={{
                  paddingLeft: '15px'
                }}>
                        {renderIcon('folder')}
                        {folder.name}
                      </td>}
                    {!!templates.length && <td>
                        {folder.name}
                        </td> || <td>
                      </td>}
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        {userState.hasPermission('template.add_template', 'template.delete_template', 'template.view_template', 'template.change_template') && <Dropdown horizontal="west" trigger={() => <Button actionType="flat" icon="fa-regular-ellipsis-h" />} content={prop => <ul className="select-list">
                                {userState.hasPermission('template.change_template') && <>
                                    <li>
                                      <a onClick={() => {
                          this.setState({
                            renameFolderName: folder.name,
                            renameFolderModal: folder.id
                          });
                          prop.close();
                        }}>
                                        Rename
                                      </a>
                                    </li>
                                  </>}
                                {userState.hasPermission('template.delete_template') && <li>
                                    <a onClick={() => {
                        this.setState({
                          deleteFolderModal: folder.id
                        });
                        prop.close();
                      }}>
                                      Delete
                                    </a>
                                  </li>}
                              </ul>} />}
                      </td>
                    </tr>)}
                {!!templates.length && !isLoading && templates.map((template, index) => <tr key={template.id + index} draggable onDragStart={e => this.handleDragStart(template)} onDragOver={e => e.preventDefault()} onClick={() => {
                if (userState.hasPermission('template.change_template')) {
                  this.props.history.push(this.getLinkToTemplate(template));
                }
              }}>
                      <td>
                        <Badge name={template.name.split('')[0]} />
                      </td>
                      <td>
                        {search !== '' && template.path}
                        {template.name}
                      </td>
                      <td>
                        <Tags tags={template.tags} onDelete={null} onClick={(e, tag) => {
                    this.addTagFromTemplateteRow(e, tag);
                  }} />
                      </td>
                      <td>
                        <div>
                          {template.archived_when ? <Status status={'archived'} /> : template.published ? <Status status="published" /> : <Status type="success">Draft</Status>}
                        </div>
                      </td>
                      <td className="no-wrap">
                        <div>
                          {moment(template.created_when).format('MMM D, YYYY')}
                        </div>
                      </td>
                      <td>
                        <div className="template-list__email-type">
                          {template.type}
                        </div>
                      </td>
                      <td className="no-wrap">
                        <div>{template.created_by}</div>
                      </td>
                      <td>
                        <Dropdown horizontal="west" trigger={() => <Button actionType="flat" icon="fa-regular-ellipsis-h" />} content={() => <ul className="select-list">
                              {userState.hasPermission('template.add_template') && <li>
                                  <a onClick={() => this.duplicateTemplate(template)}>
                                    Duplicate
                                  </a>
                                </li>}
                              {!archiveMode ? <li>
                                  <a onClick={() => this.openArchiveModal(template)}>
                                    Archive
                                  </a>
                                </li> : <li>
                                  <a onClick={() => this.restoreTemplate(template)}>
                                    Restore
                                  </a>
                                </li>}
                              {userState.hasPermission('template.change_template') && <li>
                                  <a onClick={() => this.setState({
                        selectedTemplate: template
                      })}>
                                    Edit Tags
                                  </a>
                                </li>}
                              {this.state.currentFolderView.length > 1 && <li>
                                <a onClick={() => {
                        this.moveUpToRoot(template);
                      }}>
                                  Move to Root Folder
                                </a>
                              </li>}
                              {userState.hasPermission('template.delete_template') && <li onClick={e => {
                      this[template.type === 'mail' ? 'handleMailTemplateToDelete' : template.type === 'email' ? 'handleEmailTemplateToDelete' : 'handleSmsTemplateToDelete'](event, template.id);
                    }}>
                                  <a>Delete</a>
                                </li>}
                            </ul>} />
                      </td>
                    </tr>) || <tr>
                    <td colSpan={8}>
                      <strong>No Results</strong>
                    </td>
                  </tr>}
              </tbody>
            </table>

            <Pagination data={paginationData} onChange={this.pageOnChange} />

            {isTemplateModalOpen && <>
                <CssBaseline />
                <NewTemplateDialog open={isTemplateModalOpen} onClose={this.createTemplateCancel} onContinue={this.handleNewFormDialogContinue} />
              </>}

          <CssBaseline />

            <Modal open={this.state.openDeleteTemplate} title={this.state.modalType === 'email' ? 'Delete Email template' : this.state.modalType === 'mail' ? 'Delete Mail template' : 'Delete Sms template'} onClose={this.closeDeleteEmailTemplateModal} allowBackdropClick>
              <div className="modal__body">
                <p>
                  Are you sure you want to delete{' '}
                  <strong>{currentTemplate.name}</strong>? This cannot be
                  undone.
                </p>
                {!!publishedJourneys.length && <p>
                    This template is part of{' '}
                    <strong>{publishedJourneys[0].name}</strong> and cannot be
                    deleted at this time.
                  </p>}
              </div>
              <div className="modal__actions">
                <Button actionType="flat" onClick={this.closeDeleteEmailTemplateModal}>
                  Cancel
                </Button>
                <Button actionType="primary" disabled={!!publishedJourneys.length} onClick={this.state.modalType === 'email' ? this.deleteEmailTemplate : this.state.modalType === 'mail' ? this.deleteMailTemplate : this.deleteSmsTemplate}>
                  Delete
                </Button>
              </div>
            </Modal>
            <Modal title="Copy Template"></Modal>
            <Modal title={!!publishedJourneys.length || !!templateInBroadcast.length ? 'Cannot Archive Template' : 'Archive Template'} onClose={this.closeArchiveModal} open={!!this.state.archiveModalId} allowBackdropClick>
              <div className="modal__body">
                {!!publishedJourneys.length || !!templateInBroadcast.length ? <>
                    <p>
                      The template <strong>{currentTemplate.name} </strong>{' '}
                      cannot be archived because it is used in the following
                      places.
                    </p>
                    <h4>Journeys</h4>
                    <p>
                      {!!publishedJourneys.length ? publishedJourneys.map((j, index) => {
                    return <p key={index}>{j.name}</p>;
                  }) : <p>None</p>}
                    </p>
                    {'  '}
                    <h4>Broadcasts</h4>
                    {!!templateInBroadcast.length ? templateInBroadcast.map((s, index) => {
                  return <p key={index}>{s.email_template_name}</p>;
                }) : <p>None</p>}
                  </> : <p>
                    Are you sure you would like to archive this template? It
                    will no longer be available to send in journeys or
                    broadcasts
                  </p>}
              </div>
              <div className="modal__actions">
                {!!publishedJourneys.length || !!templateInBroadcast.length ? <Button actionType="primary" onClick={this.closeArchiveModal}>
                    Okay
                  </Button> : <>
                    <Button actionType="flat" onClick={this.closeArchiveModal}>
                      Cancel
                    </Button>
                    <Button actionType="primary" onClick={() => this.archiveTemplate()}>
                      Confirm
                    </Button>
                  </>}
              </div>
            </Modal>
            <Modal title="Rename Folder" onClose={() => this.setState({
            renameFolderModal: null
          })} open={!!this.state.renameFolderModal} allowBackdropClick>
            <div className="modal__body">
              <p>
              <input type="text" className="form-control" value={this.state.renameFolderName} onChange={e => {
                  this.setState({
                    renameFolderName: e.target.value
                  });
                }} />
              </p>
            </div>
            <div className="modal__actions">
              <Button actionType="flat" onClick={() => this.setState({
                renameFolderModal: null
              })}>
                Cancel
              </Button>
              <Button actionType="primary" onClick={() => {
                this.renameFolder();
                this.setState({
                  renameFolderModal: null
                });
              }}>
                Rename Folder
              </Button>
            </div>
          </Modal>
          <Modal title="Delete Folder" onClose={() => this.setState({
            deleteFolderModal: null
          })} open={!!this.state.deleteFolderModal} allowBackdropClick>
            <div className="modal__body">
              <p>
               Are you sure you want to delete this folder?
               </p>
               <p>
               If this folder has any templates in it, they will not be deleted, but will be moved back to the home folder.
              </p>
            </div>
            <div className="modal__actions">
              <Button actionType="flat" onClick={() => this.setState({
                deleteFolderModal: null
              })}>
                Cancel
              </Button>
              <Button actionType="danger" onClick={() => this.deleteFolders()}>
                Delete
              </Button>
            </div>
          </Modal>
          <Modal title="Create New Folder" onClose={() => this.setState({
            newFolderCreate: null
          })} open={!!this.state.newFolderCreate} allowBackdropClick size='sm'>
            <div className="modal__body">
               <input type="text" className="form-control" value={this.state.newFolderName} placeholder="Folder Name" onChange={e => {
                this.setState({
                  newFolderName: e.target.value
                });
              }} onKeyPress={e => {
                if (e.key === 'Enter') {
                  this.makeNewFolder(this.state.newFolderName);
                  this.setState({
                    newFolderCreate: false
                  });
                }
              }} autoFocus />
            </div>
            <div className="modal__actions">
              <Button actionType="flat" onClick={() => this.setState({
                newFolderCreate: null
              })}>
                Cancel
              </Button>
              <Button actionType="primary" onClick={e => {
                this.makeNewFolder(this.state.newFolderName);
                this.setState({
                  newFolderCreate: false
                });
              }}>
                Create
              </Button>
            </div>
          </Modal>
            <TagModal onClose={() => this.setState({
            selectedTemplate: null
          })} onSuccess={() => {
            this.showTemplates();
            this.getTemplatesTags();
          }} objectTarget={selectedTemplate} getViewTags={getTemplatesTags} patchView={selectedTemplate && selectedTemplate.type === 'email' ? patchEmail : selectedTemplate && selectedTemplate.type === 'mail' ? patchPrints : selectedTemplate && selectedTemplate.type === 'sms' ? patchSms : null} viewName={'Templates'}></TagModal>
          </div>
        </div>
      </Scoped>;
  }
}