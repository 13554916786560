import React, { Component } from 'react';
import { Scoped, a } from 'kremling';
import { NavLink, Link } from 'react-router-dom';
import styles from './sidebar.styles.scss';
import cinchLogo from '../../assets/img/cinch-logo.svg';
import cinchLogoSmall from '../../assets/img/cinch-logo-small.svg';
import { Icon } from '../icon/icon.component';
import { Button } from '../button/button.component';
import { Badge } from '../badge/badge.component';
import { userState } from '../../shared/user-state';
import { journeyState } from '../../pages/customer-journeys/journey-state';
import { MODES } from '../../pages/customer-journeys/node/node-utils';
import { getInstalls } from '../../pages/integrations/integrations.resource.js';
const localKey = 'cinch.sidebar.open';
export class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      asCompany: {},
      mode: '',
      open: false,
      showCustomerGroups: false,
      showCoupons: false,
      showSurveys: false,
      showReviews: false
    };
  }
  componentDidMount() {
    this.userObserver = userState.subscribe(this.updateFromState, 'user', 'asCompany', 'refresh');
    this.updateFromState();
    this.modeObserver = journeyState.subscribe(({
      mode
    }) => {
      this.setState({
        mode
      });
    }, 'mode');
    this.setState({
      mode: journeyState.state.mode
    });
  }
  componentWillUnmount() {
    this.userObserver.unsubscribe();
    this.modeObserver.unsubscribe();
  }
  toggleSidebar = () => {
    this.setState({
      open: !this.state.open
    });
  };
  updateFromState = () => {
    this.setState({
      user: userState.state.user,
      asCompany: userState.state.asCompany
    });
    if (userState.state.asCompany && this.state.asCompany.id !== userState.state.asCompany.id) {
      // If the company ID exists and has changed then we need to check stuff....
      getInstalls({
        company: userState.state.asCompany.id,
        limit: 100
      }).then(({
        results: installs
      }) => {
        const showCustomerGroups = installs.some(i => i.integration === 'customergroups');
        const showCoupons = installs.some(i => i.integration === 'loyalty' || i.integration === 'isi');
        const showSurveys = installs.some(i => i.integration === 'surveys');
        const showReviews = installs.some(i => i.integration === 'reviews');
        this.setState({
          showCustomerGroups,
          showCoupons,
          showSurveys,
          showReviews
        });
      });
    }
  };
  render() {
    const {
      history
    } = this.props;
    const {
      user,
      asCompany,
      open,
      showCustomerGroups,
      showCoupons,
      showSurveys,
      showReviews
    } = this.state;
    return <Scoped css={styles}>
        <Button actionType="flat" className="shower" icon="fa-solid-bars" onClick={() => this.toggleSidebar()} />
        <div className={a('sidebar').m('sidebar--closed', !open)}>
          {!!user && <>
              <div className="header">
                <Button actionType="flat-light" className="hider" icon="fa-regular-times" onClick={() => this.toggleSidebar()} />

                <div className="logo-parent">
                  <div className="logo" dangerouslySetInnerHTML={{
                __html: cinchLogo
              }} />
                  <div className="logo-small" dangerouslySetInnerHTML={{
                __html: cinchLogoSmall
              }} />
                </div>
              </div>
              <div className="menu">
                <div className="user">
                  <Badge name={`${user.first_name} ${user.last_name}`} />
                  <span className="badge-name"> {`${user.first_name} ${user.last_name}`}</span>
                </div>
                <div className="block" style={{
              paddingTop: 0
            }}>
                  {(user.is_superuser || user.companies && user.companies.length > 1) && <Link to={`/companies/${asCompany.id}`} style={{
                display: 'block'
              }}>
                      Working as <br />
                      <span data-cy="working-as" style={{
                  color: '#fff',
                  textTransform: 'none'
                }}>
                        {asCompany.name}
                      </span>
                    </Link>}
                  <a onClick={() => {
                userState.clear();
              }}>
                    <Icon name="fa-solid-sign-out" size={14} />
                    Sign Out
                  </a>
                  {/*<a href="https://beta.marketing.cinch.io/companies">
                    <div>
                      <div>UI 2.0 is live! </div>
                      <div> Click here to try it out!</div>
                    </div>
                   </a>*/}
                </div>
                <div className="divider" />
                <div className="block">
                  <div className="category">Admin</div>
                  <NavLink to="/companies" activeClassName="active">
                    <Icon name="fa-solid-toolbox" size={14} />
                    Companies
                  </NavLink>
                  {userState.hasPermission('authentication.view_user') && <NavLink to="/users" activeClassName="active">
                      <Icon name="fa-solid-user-friends" size={14} />
                      Users
                    </NavLink>}
                  {userState.hasPermission('auth.view_group') && <NavLink to="/roles" activeClassName="active">
                      <Icon name="fa-solid-lock" size={14} />
                      Roles
                    </NavLink>}
                </div>
                <div className="divider" />
                {userState.hasPermission('journey.view_journey', 'email.view_broadcast', 'communication.view_smsbroadcast', 'communication.view_smslog') && <>
                    <div className="block">
                      <div className="category">Marketing</div>
                      {userState.hasPermission('journey.view_journey') && <NavLink to="/customer-journeys" activeClassName="active">
                          <Icon name="fa-solid-code-branch" size={14} />
                          Customer Journeys
                        </NavLink>}
                      {userState.hasPermission('email.view_broadcast') && <NavLink to="/email-broadcasts" activeClassName="active">
                          <Icon name="fa-solid-bullhorn" size={14} />
                          Email Broadcasts
                        </NavLink>}
                      {userState.hasPermission('communication.view_smsbroadcast') && <NavLink to="/sms-broadcasts" activeClassName="active">
                          <Icon name="fa-solid-broadcast-tower" size={14} />
                          Sms Broadcasts
                        </NavLink>}
                      {userState.hasPermission('communication.view_smslog') && <NavLink to="/messaging" activeClassName="active">
                          <Icon name="fa-solid-comments-alt" size={14} />
                          Two-Way Messaging
                        </NavLink>}
                      {showReviews && <NavLink to="/reviews" activeClassName="active">
                          <Icon name="fa-solid-thumbs-up" size={14} />
                          Reviews
                        </NavLink>}
                    </div>
                    <div className="divider" />
                  </>}
                {userState.hasPermission('customer.view_segment', 'template.view_template', 'organization.view_location', 'organization.view_company', 'forms.view_form', 'communication.view_phonenumber', 'files.view_files', 'surveys.view_survey') && <>
                    <div className="block">
                      <div className="category">Assets</div>
                      {showCoupons && <NavLink to="/coupons" activeClassName="active">
                          <Icon name="fa-solid-tag" size={14} />
                          Coupons
                        </NavLink>}
                      {userState.hasPermission('customer.view_segment') && <NavLink to="/segments" activeClassName="active">
                          <Icon name="fa-solid-map-marker-alt" size={14} />
                          Segments
                        </NavLink>}
                      {userState.hasPermission('template.view_template') && <NavLink to="/templates" activeClassName="active">
                          <Icon name="fa-solid-mail-bulk" size={14} />
                          Templates
                        </NavLink>}
                      {userState.hasPermission('organization.view_location') && <NavLink to="/locations" activeClassName="active">
                          <Icon name="fa-solid-globe" size={14} />
                          Locations
                        </NavLink>}
                      {userState.hasPermission('organization.view_company') && <NavLink to="/data-fields" activeClassName="active">
                          <Icon name="fa-solid-table" size={14} />
                          Data Fields
                        </NavLink>}
                      {userState.hasPermission('forms.view_form') && <NavLink to="/forms" activeClassName="active">
                          <Icon name="custom-web-form" size={14} />
                          Web Forms
                        </NavLink>}
                      {userState.hasPermission('communication.view_phonenumber') && <NavLink to="/phone-numbers" activeClassName="active">
                          <Icon name="fa-solid-phone" size={14} />
                          Phone Numbers
                        </NavLink>}
                      {userState.hasPermission('files.view_files') && <NavLink to="/files" activeClassName="active">
                          <Icon name="fa-solid-photo-video" size={14} />
                          Files
                        </NavLink>}
                      {showSurveys && userState.hasPermission('surveys.view_survey') && <NavLink to="/surveys" activeClassName="active">
                          <Icon name="fa-solid-poll" size={14} />
                          Surveys
                        </NavLink>}
                    </div>
                    <div className="divider" />
                  </>}
                {userState.hasPermission('analytics.view_view', 'customer.view_customer', 'customer.edit_customer') && <>
                    <div className="block">
                      <div className="category">Data</div>
                      {userState.hasPermission('analytics.view_view') && <NavLink to="/analytics" activeClassName="active">
                          <Icon name="fa-solid-analytics" size={14} />
                          Analytics
                        </NavLink>}
                      {userState.hasPermission('customer.view_customer') && <NavLink to="/customers" activeClassName="active">
                          <Icon name="fa-solid-address-card" size={14} />
                          Customers
                        </NavLink>}
                      {userState.hasPermission('customer.view_customerrefgroup') && showCustomerGroups && <NavLink to="/customer-groups" activeClassName="active">
                          <Icon name="fa-solid-users" size={14} />
                          Customer Groups
                        </NavLink>}
                      {userState.hasPermission('customer.view_customer') && <NavLink to="/transactions" activeClassName="active">
                          <Icon name="fa-solid-receipt" size={14} />
                          Transactions
                        </NavLink>}
                      {userState.hasPermission('csvimport.change_import') && <NavLink to="/imports" activeClassName="active">
                          <Icon name="fa-solid-upload" size={14} />
                          Imports
                        </NavLink>}
                    </div>
                    <div className="divider" />
                  </>}
                {userState.hasPermission('email.view_domain', 'integration.view_credential') && <>
                    <div className="block">
                      <div className="category">Settings</div>
                      {/*
                       <NavLink to="/billing" activeClassName="active">
                       <Icon name="fa-solid-credit-card" size={14} />
                       Billing {!asCompany.is_billed_externally && (!asCompany.subscription_status || ['paused', 'cancelled', 'future'].indexOf(asCompany.subscription_status) > -1) && <Icon
                       className="billing-issue ml-2"
                       name="fa-solid-exclamation-triangle"
                       size={12}
                       />}
                       </NavLink>
                       */}
                      {userState.hasPermission('integration.view_credential') && <NavLink to="/integrations" activeClassName="active">
                          <Icon name="fa-solid-plug" size={14} />
                          Integrations
                        </NavLink>}
                      {userState.hasPermission('email.view_domain') && <NavLink to="/domains" activeClassName="active">
                          <Icon name="fa-solid-globe" size={14} />
                          Domains &amp; Addresses
                        </NavLink>}
                    </div>
                  </>}
              </div>
              <div className="block-support">
                <a href={user.support_url}>
                  <Icon name="fa-solid-question-circle" size={14} />
                  Support
                </a>
              </div>
            </>}
          {this.state.mode === MODES.EDIT_PARAM && <div className="sidebar__overlay" />}
        </div>
      </Scoped>;
  }
}