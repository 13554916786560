import React, { Component } from 'react';
import { Scoped } from 'kremling';
import styles from './integrations.styles.scss';
import { userState } from '../../shared/user-state';
import { Button } from '../../components/button/button.component';
import { addOilTypeRule as addOilTypeRuleAPI, updateOilTypeRule as updateOilTypeRuleAPI, deleteOilTypeRule as deleteOilTypeRuleAPI, getOilTypeRules as getOilTypeRulesAPI, runCalculator } from './integrations.resource';
import { ModalDialog } from '../../components/modal/modal-dialog.component';
import { Loader } from '../../components/loader/loader.component';
import { SortHeader } from "components/table/sort-header.component";
import { Pagination } from "components/pagination/pagination.component";
export class IntegrationOilChangeCalculator extends Component {
  static propTypes = {};
  state = {
    deleteOilTypeRule: {},
    selectedOilTypeRule: {
      drain_days_max: null,
      drain_miles: null,
      drain_days_min: null,
      oil_type: null,
      item_code: null,
      item_descr: null,
      item_type: null
    },
    isOilChangeModalOpen: false,
    runCalculatorModalOpen: false,
    oilTypeRules: [],
    isLoadingOilTypeRules: false,
    currentPage: '',
    paginationParams: {
      limit: 20,
      offset: 0
    },
    ordering: '-oil_type'
  };
  getListOilTypeRules = () => {
    const {
      paginationParams,
      ordering
    } = this.state;
    getOilTypeRulesAPI({
      company: userState.getAsCompanyId(),
      ordering,
      ...paginationParams
    }).then(({
      data
    }) => {
      this.setState({
        oilTypeRules: data.results,
        isLoadingOilTypeRules: false,
        selectedOilTypeRule: {},
        paginationData: data
      });
    });
  };
  componentDidMount() {
    this.setState({
      isLoadingOilTypeRules: true
    });
    this.getListOilTypeRules();
  }
  addOilTypeRule = oilTypeRule => {
    addOilTypeRuleAPI({
      company: userState.getAsCompanyId(),
      ...oilTypeRule
    }).then(() => this.getListOilTypeRules());
  };
  updateOilTypeRule = oilTypeRule => {
    updateOilTypeRuleAPI(oilTypeRule).then(() => this.getListOilTypeRules());
  };
  deleteOilTypeRule = oilTypeRuleId => {
    deleteOilTypeRuleAPI(oilTypeRuleId).then(() => this.getListOilTypeRules());
  };
  updateState = (name, value) => {
    this.setState({
      selectedOilTypeRule: {
        ...this.state.selectedOilTypeRule,
        [name]: value
      }
    });
  };
  updateOrdering = ordering => {
    this.setState({
      ordering,
      currentPage: 1
    }, () => this.getListOilTypeRules());
  };
  onPageChange = paginationParams => {
    this.setState({
      isLoading: true,
      paginationParams
    }, () => {
      this.getListOilTypeRules();
    });
  };
  render() {
    const {
      deleteOilTypeRule,
      selectedOilTypeRule,
      oilTypeRules,
      isLoadingOilTypeRules,
      paginationData,
      isOilChangeModalOpen,
      runCalculatorModalOpen,
      ordering,
      isEditModal
    } = this.state;
    return <Scoped css={styles}>
        <div className="integration">
          <div className="integration__header">
            <div>Oil Change Calculator</div>
            {userState.hasPermission('integration.add_oil_type_rule') && <>
                  <Button actionType="flat" actionType="primary" style={{
              marginLeft: "auto",
              marginRight: "8px"
            }} onClick={() => this.setState({
              runCalculatorModalOpen: true
            })}>Run</Button>
                  <Button className="btn" actionType="primary" tag="a" onClick={() => this.setState({
              isOilChangeModalOpen: true
            })}>
                    Add Oil Type Rule
                  </Button></>}
          </div>
          <div className="oilchange-list">
            {isLoadingOilTypeRules && <Loader style={{
            position: "unset"
          }} overlay />}
            <table className="table-list">
              {!isLoadingOilTypeRules && !!oilTypeRules.length && <thead>
                  <tr>
                     <SortHeader name="oil_type" ordering={ordering} update={ordering => this.updateOrdering(ordering)}>
                      Oil Type Name
                    </SortHeader>
                    <th name="phone" ordering={ordering} update={ordering => this.updateOrdering(ordering)}>
                      Oil Drain Miles
                     </th>
                     <th name="locations" ordering={ordering} update={ordering => this.updateOrdering(ordering)}>
                      Minimum Days
                     </th>
                     <th name="locations" ordering={ordering} update={ordering => this.updateOrdering(ordering)}>
                      Maximium Days
                     </th>
                     <th name="is_active" ordering={ordering} update={ordering => this.updateOrdering(ordering)}>
                      Item Type contains
                     </th>
                     <th name="is_active" ordering={ordering} update={ordering => this.updateOrdering(ordering)}>
                      Item Code contains
                     </th>
                     <th name="is_active" ordering={ordering} update={ordering => this.updateOrdering(ordering)}>
                      Item Desc contains
                     </th>
                  </tr>
                  </thead>}
              <tbody>
              {oilTypeRules.length && !isLoadingOilTypeRules && oilTypeRules.map(oilTypeRule => <tr key={oilTypeRule.id}>
                        <td>
                          {oilTypeRule.oil_type}
                        </td>
                        <td>
                          {oilTypeRule.drain_miles}
                        </td>
                        <td>{oilTypeRule.drain_days_min}</td>
                        <td>{oilTypeRule.drain_days_max}</td>
                        <td>{oilTypeRule.item_type}</td>
                        <td>{oilTypeRule.item_code}</td>
                        <td>{oilTypeRule.item_descr}</td>
                        <td>

                          {userState.hasPermission('oilchange.change_oil_type_rule') && <Button actionType="flat" icon="fa-regular-edit" onClick={() => {
                    this.setState({
                      isOilChangeModalOpen: true,
                      selectedOilTypeRule: oilTypeRule,
                      isEditModal: true
                    });
                  }} />}
                          {userState.hasPermission('oilchange.delete_oil_type_rule') && <Button actionType="flat" icon="fa-regular-trash" onClick={e => {
                    this.setState({
                      deleteOilTypeRule: oilTypeRule
                    });
                    e.stopPropagation();
                  }} />}
                        </td>
                      </tr>) || <tr>
                        {!isLoadingOilTypeRules && <td colSpan={6}>
                              <strong>No Results</strong>
                            </td>}
                      </tr>}
              </tbody>
            </table>
            <Pagination data={paginationData} onChange={this.onPageChange} />
          </div>
          <ModalDialog open={runCalculatorModalOpen} title="Oil Change Calculator" submitText="Confirm" onSubmit={() => runCalculator(userState.getAsCompanyId())} onClose={() => this.setState({
          runCalculatorModalOpen: false
        })} allowBackdropClick>
            <p>Are you sure you want to run the oil change calculator?</p>
          </ModalDialog>
          <ModalDialog open={!!deleteOilTypeRule.id} title="Remove Oil Type Rule" submitText="Remove" onSubmit={() => this.deleteOilTypeRule(deleteOilTypeRule.id)} onClose={() => this.setState({
          deleteOilTypeRule: {}
        })} allowBackdropClick>
            <p>
              <strong>{deleteOilTypeRule.ac_id}</strong>
            </p>
            <p>Are you sure you want to remove this Oil Type Rule?</p>
          </ModalDialog>
          <ModalDialog open={isOilChangeModalOpen} title="Update Oil Type Rule" submitText={isEditModal ? "Update" : "Create"} onClose={() => this.setState({
          isOilChangeModalOpen: false,
          isEditModal: false,
          selectedOilTypeRule: {}
        })} allowBackdropClick onSubmit={() => {
          isEditModal ? this.updateOilTypeRule(selectedOilTypeRule) : this.addOilTypeRule(selectedOilTypeRule);
        }}>
            <p>
              <label>Oil Type Name</label>
              <input required className="form-control" name="oil_type" value={selectedOilTypeRule.oil_type || ""} onChange={e => this.updateState(e.target.name, e.target.value)} />
            </p>
            <p>
              <label>Oil Drain Miles</label>
              <input required className="form-control" name="drain_miles" type="number" value={selectedOilTypeRule.drain_miles || ""} onChange={e => this.updateState(e.target.name, e.target.value)} />
            </p>
            <p>
              <label>Minimum Days</label>
              <input required className="form-control" name="drain_days_min" type="number" value={selectedOilTypeRule.drain_days_min || ""} onChange={e => this.updateState(e.target.name, e.target.value)} />
            </p>
            <p>
              <label>Maximium Days</label>
              <input required className="form-control" name="drain_days_max" type="number" value={selectedOilTypeRule.drain_days_max || ""} onChange={e => this.updateState(e.target.name, e.target.value)} />
            </p>
            <p>
              <label>Item Type Contains</label>
              <input required className="form-control" name="item_type" type="text" value={selectedOilTypeRule.item_type || ""} onChange={e => this.updateState(e.target.name, e.target.value)} />
            </p>
            <p>
              <label>Item Code Contains</label>
              <input required className="form-control" name="item_code" type="text" value={selectedOilTypeRule.item_code || ""} onChange={e => this.updateState(e.target.name, e.target.value)} />
            </p>
            <p>
              <label>Item Desc Contains</label>
              <input required className="form-control" name="item_descr" type="text" value={selectedOilTypeRule.item_descr || ""} onChange={e => this.updateState(e.target.name, e.target.value)} />
            </p>
          </ModalDialog>
        </div>
      </Scoped>;
  }
}