import React, { Component } from 'react';
import { func, object, shape, string } from 'prop-types';
import { getCoupons, getCoupon } from 'shared/common.api';
import { userState } from 'shared/user-state';
import { DynamicTypeAhead } from '../../../../components/dynamic-type-ahead/dynamic-type-ahead.component';
export class ActionGiveOffer extends Component {
  static propTypes = {
    update: func,
    node: object,
    data: shape({
      coupon_id: string,
      coupon_name: string
    })
  };
  state = {
    couponList: []
  };
  componentDidMount() {
    const {
      coupon_id
    } = this.props.data;
    if (coupon_id) {
      getCoupon(coupon_id).then(({
        data
      }) => {
        this.setState({
          selectedCoupon: data
        });
      });
    }
  }
  toggleCoupon = coupon => {
    this.setState({
      selectedCoupon: coupon
    });
    this.props.update({
      coupon_id: coupon ? coupon.id : null,
      coupon_name: coupon ? coupon.name : null
    });
  };
  render() {
    const {
      selectedCoupon
    } = this.state;
    return <div>
        <div className="form-group">
          <label>Coupon</label>
          <DynamicTypeAhead getItems={getCoupons} getItemsFilters={{
          company: userState.getAsCompanyId(),
          entity_source: 'loyalty'
        }} placeholder={selectedCoupon ? `${selectedCoupon.name}` : 'Choose Coupon'} displayProperty="name" keyProperty="id" value={selectedCoupon ? selectedCoupon.id : null} onChange={coupon => this.toggleCoupon(coupon)} />
        </div>
      </div>;
  }
}