import React, { Component } from 'react';
import * as Sentry from '@sentry/react';
import { userState } from '../../shared/user-state';
import { ErrorFOB } from './error-fob.component';
export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      eventId: false,
      timeout: false
    };
    window.CIErrorBoundary = this;
  }
  beforeSend({
    event_id
  }) {
    clearTimeout(this.state.timeout);
    this.setState({
      eventId: event_id,
      timeout: setTimeout(() => this.setState({
        eventId: false
      }), 5000)
    });
  }
  componentWillUnmount() {
    clearTimeout(this.state.timeout);
  }
  openReportDialog(eventId) {
    const user = userState.state.user;
    Sentry.showReportDialog({
      eventId,
      user: {
        email: user.email,
        name: `${user.first_name} ${user.last_name}`.trim()
      }
    });
    this.setState({
      eventId: false
    });
  }

  /* componentDidCatch(error, info) {
      // I wish this would work... but it doesn't...
      console.warn(error, info);
  }*/

  render() {
    const {
      eventId
    } = this.state;
    const {
      children
    } = this.props;
    return <>
                {children}

                <ErrorFOB open={!!eventId} onClick={() => this.openReportDialog(eventId)} />
            </>;
  }
}