import { Container, Graphics, Sprite } from 'pixi.js';
import { journeyState } from '../journey-state';
import { MODES, buildSquare } from './node-utils';
export class NodeConnect {
  constructor(parentNode) {
    this.mode = journeyState.state.mode;
    this.parentNode = parentNode;
    this.container = this.build();
    this.container.interactive = true;
    this.container.alpha = 0;
    this.container.on('pointerdown', this._onDragStart).on('pointerup', this._onDragEnd).on('pointerupoutside', this._onDragEnd).on('pointermove', this._onDragMove);
    this.nodeToObserver = journeyState.subscribe(({
      nodeTo
    }) => {
      this.nodeTo = nodeTo;
    }, 'nodeTo');
    this.modeObserver = journeyState.subscribe(({
      mode
    }) => {
      this.mode = mode;
    }, 'mode');
  }
  build = () => {
    const container = new Container();
    const size = 40;
    const radius = size / 2;
    this.connect = new Graphics();
    this.connect.lineStyle(2, 0xffffff);
    this.connect.beginFill(0xc3c3c3);
    this.connect.drawCircle(radius, radius, radius);
    this.connect.endFill();
    const shape = new Graphics();
    shape.beginFill(0xffffff);
    shape.drawPolygon([0, 18, 16, 18, 16, 14, 30, 20, 16, 26, 16, 22, 0, 22]);
    shape.endFill();
    container.addChild(this.connect);
    container.addChild(shape);
    return container;
  };
  willDestroy = () => {
    this.modeObserver.unsubscribe();
    this.nodeToObserver.unsubscribe();
    this.container.destroy();
  };
  _onDragStart = event => {
    if (this.mode === MODES.CANVAS) {
      event.stopped = true;
      this.data = event.data;
      this.container.dragging = true;
      const parentPosition = this.container.parent.position;
      journeyState.startPointLink(this.parentNode, parentPosition._x + 62, parentPosition._y + 62);
    }
  };
  _onDragEnd = event => {
    if (this.container.dragging) {
      this.container.dragging = false;
      this.data = null;
      journeyState.endPointLink();
    }
  };
  _onDragMove = () => {
    if (this.container.dragging) {
      journeyState.updatePointLink(this.data);
    }
  };
}