import React, { Component } from 'react';
import { func, number, string } from 'prop-types';
import { throttle } from 'lodash';
import { getCompanyFields } from 'shared/common.api';
import { v4 as uuid } from 'uuid';
import { userState } from 'shared/user-state';
const scriptId = 'CcIframeApiScript';
const mergeTags = [];
export class CustomerCanvas extends Component {
  constructor(props) {
    super(props);
    this.iframe = React.createRef();
    this.editor = React.createRef();
  }
  static propTypes = {
    onLoad: func,
    width: number,
    length: number,
    canvasId: string
  };
  start = () => {
    const {
      width,
      length,
      canvasId
    } = this.props;
    const dpi = 72;
    let widthDpi = width * dpi;
    let lengthDpi = length * dpi;
    if (width === 6 && length === 4) {
      widthDpi += 0.25 * dpi;
      lengthDpi += 0.25 * dpi;
    }
    const surface = {
      width: widthDpi,
      height: lengthDpi,
      bounds: {
        x: 0,
        y: 0,
        width: widthDpi,
        height: lengthDpi,
        name: uuid()
      }
    };
    let addressSurface;
    if (width === 6 && length === 4) {
      addressSurface = '4x6_address_block_new';
    } else if (width === 11 && length === 6) {
      addressSurface = '6x11_address_block';
    } else {
      addressSurface = surface;
    }
    const productDefinition = canvasId || {
      defaultSafetyLines: [{
        margin: 9,
        color: 'rgba(0,255,0,255)',
        altColor: 'rgba(255,255,255,0)',
        stepPx: 5,
        widthPx: 1
      }],
      surfaces: [surface, addressSurface]
    };
    CustomersCanvas.IframeApi.loadEditor(this.iframe.current, productDefinition, {
      canvas: {
        containerColor: '#f9f9f9',
        color: '#ffffff'
      },
      initialMode: 'Advanced',
      //theme: IS_PROD ? 'MdLight' : 'Md',
      theme: 'MdLight',
      customStyle: 'user',
      widgets: {
        Toolbox: {
          buttons: ['Text', 'RichText', 'Image', 'Background'].concat(mergeTags)
        }
      },
      userId: userState.getAsCompanyId()
    }).then(editor => {
      this.editor.current = editor;
      let promise = Promise.resolve();
      if (!canvasId) {
        // If this is a new mail template then we need to revert it
        promise.then(() => editor.revertProduct(false));
      }
      promise.then(() => {
        this.props.onLoad(editor);
      });
    });
  };
  init() {
    if (!document.head.querySelector(`#${scriptId}`)) {
      const script = document.createElement('script');
      script.id = scriptId;
      script.src = CC_JS;
      script.onload = this.start.bind(this);
      document.head.appendChild(script);
    } else {
      this.start();
    }
    window.addEventListener('resize', this.resize);
    this.resize();
  }
  componentDidMount() {
    // get company data fields
    getCompanyFields(userState.getAsCompanyId()).then(({
      data
    }) => {
      const buttons = [];
      mergeTags.splice(0, mergeTags.length);
      data.forEach(category => {
        if (category.fields.length) {
          buttons.push({
            iconClass: `cinch-icon-${category.id}`,
            translationKey: category.name,
            translationKeyTitle: category.name,
            action: 'header',
            enabled: false,
            itemConfig: {
              name: category.name
            }
          });
          category.fields.forEach(field => {
            buttons.push({
              translationKey: field.name,
              action: 'BoundedText',
              itemConfig: {
                name: field.name,
                text: `{{[[${category.id}.${field.id.replaceAll('__', '.')}]]}}`,
                height: 24,
                width: 300,
                alignment: 'center',
                verticalAlignment: 'top',
                overflowStrategy: 'fitToWidth',
                textShrinkMode: 'size',
                font: {
                  size: 20
                },
                location: {
                  x: '50%',
                  y: '50%',
                  originX: 'center',
                  originY: 'center'
                }
              }
            });
          });
        }
      });
      mergeTags.push({
        translationKey: 'Customer Data',
        translationKeyTitle: 'Customer Data',
        iconClass: 'cinch-icon-customer',
        buttons
      });
      const extras = [{
        name: 'Expires in 15 Days',
        tag: 'expires 15'
      }, {
        name: 'Expires in 30 Days',
        tag: 'expires 30'
      }, {
        name: 'Expires in 45 Days',
        tag: 'expires 45'
      }, {
        name: 'Expires in 60 Days',
        tag: 'expires 60'
      }];
      mergeTags.push({
        translationKey: 'Extra',
        translationKeyTitle: 'Extra',
        iconClass: 'cc-icon-add-text',
        buttons: extras.map(extra => ({
          translationKey: extra.name,
          action: 'BoundedText',
          itemConfig: {
            name: extra.name,
            text: `{{[[${extra.tag}]]}}`,
            height: 24,
            width: 300,
            alignment: 'center',
            verticalAlignment: 'top',
            overflowStrategy: 'fitToWidth',
            textShrinkMode: 'size',
            font: {
              size: 20
            },
            location: {
              x: '50%',
              y: '50%',
              originX: 'center',
              originY: 'center'
            }
          }
        }))
      });
      this.init();
    });
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
    if (this.editor.current) {
      this.editor.current.dispose();
    }
  }
  resize = throttle(() => {
    this.iframe.current.height = `${window.innerHeight - 50}px`;
  }, 1000);
  render() {
    return <iframe id="editorFrame" width="100%" height="800px" frameBorder="0" ref={this.iframe} />;
  }
}