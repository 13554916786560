import React, { Component } from 'react';
import { Scoped } from 'kremling';
import styles from './integrations.styles.scss';
import { userState } from '../../shared/user-state';
import { Button } from '../../components/button/button.component';
import { getJiffyLubeFranchises, addJiffyLubeFranchise, deleteJiffyLubeFranchise, updateJiffyLubeFranchise } from './integrations.resource';
import { toasterService } from '../../components/toaster/toaster-service';
import { ModalDialog } from '../../components/modal/modal-dialog.component';
import { Loader } from '../../components/loader/loader.component';
import { Icon } from '../../components/icon/icon.component';
export class IntegrationJiffyLube extends Component {
  static propTypes = {};
  state = {
    franchises: [],
    deleteJiffyLubeFranchise: {},
    updateJiffyLubeFranchise: {},
    isFranchiseModalOpen: false,
    franchiseRef: null,
    username: null,
    password: null
  };
  componentDidMount() {
    this.getJiffyLubeData().finally(() => this.props.onLoad());
  }
  getJiffyLubeData = () => {
    return getJiffyLubeFranchises({
      company: userState.getAsCompanyId()
    }).then(({
      results
    }) => {
      this.setState({
        franchises: results
      });
    });
  };
  addJiffyLubeFranchise = (franchiseRef, username, password) => {
    return addJiffyLubeFranchise({
      franchise_ref: franchiseRef,
      username,
      password,
      company: userState.getAsCompanyId()
    }).then(() => toasterService.success('Successfully added franchise.')).then(() => this.getJiffyLubeData()).catch(err => {
      if (err.response.data.franchise_ref) {
        toasterService.error('Invalid Franchise ID');
      } else if (err.response.data.non_field_errors) {
        toasterService.error(err.response.data.non_field_errors[0]);
      } else {
        toasterService.error('Unknown error trying to add franchise. Please try again.');
      }
    });
  };
  updateJiffyLubeFranchise = (id, username, password) => {
    return updateJiffyLubeFranchise(id, username, password).then(() => toasterService.success('Successfully updated franchise.')).then(() => this.getJiffyLubeData()).catch(err => {
      if (err.response.data.site_id) {
        toasterService.error(err.response.data.site_id[0]);
      } else {
        toasterService.error('Unknown error trying to update franchise. Please try again.');
      }
    });
  };
  removeJiffyLubeFranchise = franchiseRef => {
    return deleteJiffyLubeFranchise(franchiseRef).then(() => toasterService.success('Successfully deleted franchise')).then(() => this.getJiffyLubeData()).catch(() => toasterService.error('Unknown error to trying delete franchise. Please try again.'));
  };
  render() {
    const {
      franchises,
      isFranchiseModalOpen,
      franchiseRef,
      username,
      password,
      deleteJiffyLubeFranchise,
      updateJiffyLubeFranchise
    } = this.state;
    return <Scoped css={styles}>
        <div className="integration mb-5">
          <div className="integration__header">
            <div>Jiffy Lube</div>
            {userState.hasPermission('jiffylube.add_franchise') && <Button className="btn" actionType="primary" tag="a" onClick={() => this.setState({
            isFranchiseModalOpen: true
          })}>
                Add Franchise
              </Button>}
          </div>
          <div className="integration__body">
            {franchises.map(franchise => <div className="integration-item" key={franchise.franchiseRef}>
                <div className="integration-item__inner">
                  <div className="integration-title">{franchise.name}</div>
                  <div className="integration-actions">
                    {userState.hasPermission('jiffylube.change_franchise') && <Button actionType="flat" icon="fa-regular-edit" onClick={e => {
                  e.stopPropagation();
                  this.setState({
                    updateJiffyLubeFranchise: franchise
                  });
                }} />}
                    {userState.hasPermission('jiffylube.delete_franchise') && <Button actionType="flat" icon="fa-regular-trash" onClick={e => {
                  e.stopPropagation();
                  this.setState({
                    deleteJiffyLubeFranchise: franchise
                  });
                }} />}
                  </div>
                </div>
              </div>)}
          </div>

          <ModalDialog open={!!deleteJiffyLubeFranchise.id} title="Remove franchise" submitText="Remove" onSubmit={() => this.removeJiffyLubeFranchise(deleteJiffyLubeFranchise.id)} onClose={() => this.setState({
          deleteJiffyLubeFranchise: {}
        })} allowBackdropClick>
            <p>
              <strong>{deleteJiffyLubeFranchise.location_name}</strong>
            </p>
            <p>Are you sure you want to remove this franchise?</p>
          </ModalDialog>

          <ModalDialog open={isFranchiseModalOpen} title="Add Franchise" onSubmit={() => this.addJiffyLubeFranchise(franchiseRef, username, password)} onClose={() => this.setState({
          isFranchiseModalOpen: false
        })} allowBackdropClick>
            <p>
              Please enter your Jiffy Lube information below to link to your
              account.
            </p>
            <p>
              <label>Franchise Ref</label>
              <input required className="form-control" name="franchise_ref" onChange={e => this.setState({
              franchiseRef: e.target.value
            })} />
            </p>
            <p>
              <label>Username</label>
              <input required className="form-control" name="username" onChange={e => this.setState({
              username: e.target.value
            })} />
            </p>
            <p>
              <label>Password</label>
              <input required className="form-control" name="password" type="password" onChange={e => this.setState({
              password: e.target.value
            })} />
            </p>
          </ModalDialog>

          <ModalDialog open={!!updateJiffyLubeFranchise.id} title="Update franchise" submitText="Update" onSubmit={() => this.updateJiffyLubeFranchise(updateJiffyLubeFranchise.id, username, password)} onClose={() => this.setState({
          updateJiffyLubeFranchise: {}
        })} allowBackdropClick>
            <p>Please update your Jiffy Lube information below.</p>
            <p>
              <label>Username</label>
              <input required className="form-control" name="username" defaultValue={updateJiffyLubeFranchise.username || ''} onChange={e => this.setState({
              username: e.target.value
            })} />
            </p>
            <p>
              <label>Password</label>
              <input required className="form-control" name="password" type="password" onChange={e => this.setState({
              password: e.target.value
            })} />
            </p>
          </ModalDialog>
        </div>
      </Scoped>;
  }
}