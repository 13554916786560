import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
const StyledDialog = styled(Dialog)(({
  theme,
  props
}) => ({
  maxWidth
}) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    maxWidth
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));
const CustomDialogTitle = ({
  children,
  onClose,
  ...props
}) => {
  return <DialogTitle sx={{
    m: 0,
    p: '10px'
  }} {...props}>
      {children}
      {onClose ? <IconButton aria-label="close" onClick={onClose} sx={{
      position: 'absolute',
      right: 8,
      top: 5,
      color: theme => theme.palette.grey[500]
    }}>
          <CloseIcon />
        </IconButton> : null}
    </DialogTitle>;
};
export default function ({
  title,
  content,
  actions,
  open,
  onClose,
  maxWidth,
  height
}) {
  return <StyledDialog maxWidth={maxWidth} open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <CustomDialogTitle onClose={onClose}>{title}</CustomDialogTitle>
      <Divider />
      <DialogContent>{content}</DialogContent>
      <DialogActions>{actions}</DialogActions>
    </StyledDialog>;
}