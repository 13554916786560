import React, { Component } from 'react';
import { func, object, shape, string } from 'prop-types';
import { getSegments } from 'shared/common.api';
import { userState } from 'shared/user-state';
import { Dropdown } from 'components/dropdown/dropdown.component';
import { Button } from 'components/button/button.component';
import { Icon } from 'components/icon/icon.component';
import { DynamicTypeAhead } from '../../../../components/dynamic-type-ahead/dynamic-type-ahead.component';
export class TriggerSegment extends Component {
  static propTypes = {
    update: func,
    node: object,
    data: shape({
      segment_id: string,
      audience: string
    })
  };
  state = {
    segmentList: []
  };
  componentDidMount() {
    getSegments({
      company: userState.getAsCompanyId(),
      time_dynamic_segment: false,
      limit: 200
    }).then(({
      data
    }) => {
      this.setState({
        segmentList: data.results
      });
    });
  }
  toggleSegment = id => {
    const {
      segment_id
    } = this.props.data;
    this.props.update({
      segment_id: !segment_id ? id : segment_id.id !== id ? id : null
    });
  };
  onAudienceChange = e => {
    this.props.update({
      audience: e.target.value
    });
  };
  render() {
    const {
      segmentList
    } = this.state;
    const selectedSegment = segmentList.find(segment => segment.id === this.props.data.segment_id);
    const {
      audience
    } = this.props.data;
    return <div>
        <div className="form-group">
          <label>Static Segment</label>
          <DynamicTypeAhead getItems={getSegments} getItemsFilters={{
          time_dynamic_segment: false,
          company: userState.getAsCompanyId()
        }} placeholder={selectedSegment ? `${selectedSegment.name}` : "Choose Static Segment"} displayProperty="name" keyProperty="id" value={selectedSegment ? selectedSegment.id : null} onChange={segment => {
          this.toggleSegment(segment ? segment.id : null);
        }} />         
        </div>
        <div className="form-group form-group__radio">
          <label>
            <input type="radio" value="now" name="option" onChange={this.onAudienceChange} checked={audience === 'now'} />
            <span>Add all from this segment now</span>
          </label>
          <label>
            <input type="radio" value="all" name="option" onChange={this.onAudienceChange} checked={audience === 'all'} />
            <span>
              Add all contacts on this segment now and that are added in the
              future
            </span>
          </label>
          <label>
            <input type="radio" value="future" name="option" onChange={this.onAudienceChange} checked={audience === 'future'} />
            <span>Add only contacts that enter this segment in the future</span>
          </label>
        </div>
      </div>;
  }
}