import React, { Component } from 'react';
import { Scoped } from 'kremling';
import { isEqual } from 'lodash';
import moment from 'moment-timezone';
import { api } from '../../shared/api';
import { getCompanies, getLocations } from '../../shared/common.api';
import { PageHeader } from '../../components/page-header/page-header';
import styles from '../customers/customers.styles.scss';
import { Pagination } from '../../components/pagination/pagination.component';
import { Loader } from '../../components/loader/loader.component';
import { userState } from '../../shared/user-state';
import { SortHeader } from '../../components/table/sort-header.component';
import { CustomerDrawerContext } from 'context/customer-drawer-context';
export class Transactions extends Component {
  static contextType = CustomerDrawerContext;
  state = {
    loading: true,
    companies: [],
    locations: [],
    transactions: [],
    transaction: null,
    customer: null,
    transactionPagination: {},
    paginationParams: {
      limit: 30,
      offset: 0
    },
    query: '',
    ordering: null
  };
  componentDidMount() {
    Promise.all([getCompanies({
      limit: 9999,
      company: userState.getAsCompanyId()
    }), getLocations({
      limit: 9999,
      company: userState.getAsCompanyId()
    }), api.get(`/transactions`, {
      params: {
        limit: 30,
        transaction_when: true,
        ordering: '-transaction_when',
        company: userState.getAsCompanyId()
      }
    })]).then(([companies, locations, transactions]) => {
      this.setState({
        loading: false,
        transactionPagination: transactions.data,
        locations: locations.data.results,
        companies: companies.data.results,
        transactions: transactions.data.results
      });
    });
  }
  getTransactions = () => {
    this.setState({
      loading: true
    });
    return api.get(`/transactions`, {
      params: {
        ...this.state.paginationParams,
        limit: 30,
        transaction_when: true,
        search: this.state.query,
        company: userState.getAsCompanyId(),
        ordering: this.state.ordering || '-transaction_when'
      }
    }).then(({
      data
    }) => {
      this.setState({
        loading: false,
        transactionPagination: data,
        transactions: data.results || []
      });
    });
  };
  onSearch = query => {
    this.setState({
      query
    });
    this.getTransactions();
  };
  getCompanyName = id => {
    return this.state.companies.find(company => company.id === id).name;
  };
  pageOnChange = params => {
    if (!isEqual(params, this.state.paginationParams)) {
      this.setState({
        paginationParams: params
      }, () => {
        this.getTransactions();
      });
    }
  };
  commas = x => {
    let parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  };
  updateOrdering = ordering => {
    this.setState({
      ordering,
      currentPage: 1
    }, () => this.getTransactions());
  };
  render() {
    const {
      transactions,
      transactionPagination,
      loading,
      ordering,
      customer,
      transaction
    } = this.state;
    return <Scoped css={styles}>
        <div className="wrapper">
          {loading && <Loader overlay background="rgba(255,255,255,.5)" />}
          <PageHeader name={`Transactions ${transactionPagination.count ? `(${this.commas(transactionPagination.count)})` : ''}`} onSearch={this.onSearch} />
          <div className="wrapper-scroll p-md">
            <div className="table-container">
              <table className="table-list">
                <thead>
                  <tr>
                    <th>Customer</th>
                    <SortHeader name="subtotal" ordering={ordering} update={ordering => this.updateOrdering(ordering)}>
                      Subtotal
                    </SortHeader>
                    <SortHeader name="tax1" ordering={ordering} update={ordering => this.updateOrdering(ordering)}>
                      Tax 1
                    </SortHeader>
                    <SortHeader name="tax2" ordering={ordering} update={ordering => this.updateOrdering(ordering)}>
                      Tax 2
                    </SortHeader>
                    <SortHeader name="total" ordering={ordering} update={ordering => this.updateOrdering(ordering)}>
                      Total
                    </SortHeader>
                    <SortHeader name="transaction_when" ordering={ordering} update={ordering => this.updateOrdering(ordering)}>
                      Date
                    </SortHeader>
                  </tr>
                </thead>
                <tbody>
                  {transactions.map(t => <tr key={t.id} onClick={() => this.context.openCustomerDrawer(t.customer_id, "transactions", t.id)}>
                      <td>
                        {t.customer_first_name} {t.customer_last_name}
                      </td>
                      <td>
                        {t.subtotal ? `$${Number(t.subtotal).toFixed(2)}` : ''}
                      </td>
                      <td>{t.tax1 ? `$${Number(t.tax1).toFixed(2)}` : ''}</td>
                      <td>{t.tax2 ? `$${Number(t.tax2).toFixed(2)}` : ''}</td>
                      <td>{t.total ? `$${Number(t.total).toFixed(2)}` : ''}</td>
                      <td>
                        {t.transaction_when && moment(t.transaction_when).local().format('MM-DD-YYYY h:mm A')}
                      </td>
                    </tr>)}
                </tbody>
              </table>
            </div>
            <div className="pb-md">
              <Pagination data={transactionPagination} onChange={this.pageOnChange} limit={30} />
            </div>
          </div>
        </div>
      </Scoped>;
  }
}