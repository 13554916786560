import React, { useState, useEffect, useCallback, useContext } from 'react';
import { WorkingAsContext } from 'context/working-as-context';
import { Button, Loader, toasterService, Banner } from 'components';
import { getPossibleUseCases, createMessageCampaign } from 'shared/common.api';
export const CampaignInformationStep = props => {
  const {
    profile,
    updateProfile
  } = props;
  const [useCases, setUseCases] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [campaignVerification, setCampaignVerification] = useState({});
  const {
    asCompany
  } = useContext(WorkingAsContext);
  useEffect(() => {
    if (asCompany) {
      getPossibleUseCases(asCompany.id).then(({
        data
      }) => {
        setUseCases(data);
      });
    }
  }, [asCompany]);
  const submit = () => {
    setIsSaving(true);
    const errors = {};

    // Validate information
    if (!profile.campaign_information?.description || profile.campaign_information?.description === '') {
      errors.description = 'Description is required.';
    }
    if (!profile.campaign_information?.message_flow || profile.campaign_information?.message_flow === '') {
      errors.message_flow = 'Message flow is required.';
    } else if (profile.campaign_information?.message_flow.length < 40) {
      errors.message_flow = 'Message flow needs to be at least 40 characters long.';
    } else if (profile.campaign_information?.message_flow.length > 4096) {
      errors.message_flow = 'Message flow needs to be less than 4096 characters long.';
    }
    [1, 2, 3].forEach(i => {
      if (!profile.campaign_information?.[`message_samples_${i}`] || profile.campaign_information?.[`message_samples_${i}`] === '') {
        errors[`message_samples_${i}`] = 'Message sample is required.';
      } else if (profile.campaign_information?.[`message_samples_${i}`].length < 20) {
        errors[`message_samples_${i}`] = 'Message sample needs to be at least 20 characters long.';
      } else if (profile.campaign_information?.[`message_samples_${i}`].length > 1024) {
        errors[`message_samples_${i}`] = 'Message sample needs to be less than 1024 characters long.';
      }
    });
    if (!profile.campaign_information?.us_app_to_person_usecase || profile.campaign_information?.us_app_to_person_usecase === '') {
      errors.us_app_to_person_usecase = 'Use case is required.';
    }
    if (!profile.campaign_information?.has_embedded_links || profile.campaign_information?.has_embedded_links === '') {
      errors.has_embedded_links = 'Has embedded links is required.';
    }
    if (!profile.campaign_information?.has_embedded_phone || profile.campaign_information?.has_embedded_phone === '') {
      errors.has_embedded_phone = 'Has embedded phone is required.';
    }
    setCampaignVerification(errors);
    let promise = Promise.resolve();
    if (Object.keys(errors).length === 0) {
      // API CALL
      promise = createMessageCampaign({
        ...profile.campaign_information,
        has_embedded_links: profile.campaign_information.has_embedded_links === 'yes',
        has_embedded_phone: profile.campaign_information.has_embedded_phone === 'yes',
        company: asCompany.id
      }).catch(() => {
        toasterService.error('There was an unknown error trying to save your campaign information. Check your inputs and try again');
      });
    }
    promise.then(() => setIsSaving(false));
  };
  if (profile.campaign_status === 'IN_PROGRESS') {
    return <Banner type="info" className="my-3">
        Your application is currently "In Progress". Campaign Registration can take up to 24 hours in some cases. If you see your
        application stuck in the "In Progress" state for more than 24 hours, please contact support.
      </Banner>;
  }
  if (profile.campaign_status === 'FAILED') {
    return <Banner type="danger" className="my-3">
        Your application failed. Please contact support for more assistance.
      </Banner>;
  }
  if (profile.campaign_status === 'VERIFIED') {
    return <Banner type="success" className="my-3">
        All of your application information has been approved. You can now purchase phone numbers.
      </Banner>;
  }
  return <>
      <div className="form-group">
        <label>Description</label>
        <input className="form-control" name="description" onChange={e => updateProfile('campaign_information', 'description', e.target.value)} value={profile.campaign_information?.description || ''} autoFocus />
        <small className="mt-1 block">A short description of how you plan to use SMS. 40 character minimum. 4096 character maximum.</small>
        {campaignVerification?.description && <small className="mt-1 block text-danger">{campaignVerification?.description}</small>}
      </div>

      <div className="form-group">
        <label>Message Flow</label>
        <input className="form-control" name="message_flow" onChange={e => updateProfile('campaign_information', 'message_flow', e.target.value)} value={profile.campaign_information?.message_flow || ''} />
        <small className="mt-1 block">
          Details around how a consumer opts-in to their campaign, therefore giving consent to receive their messages. If multiple opt-in
          methods can be used for the same campaign, they must all be listed. 40 character minimum. 2048 character maximum.
        </small>
        {campaignVerification?.message_flow && <small className="mt-1 block text-danger">{campaignVerification?.message_flow}</small>}
      </div>

      <div className="form-group">
        <label>Message Samples</label>
        <input className="form-control" name="message_samples_1" onChange={e => updateProfile('campaign_information', 'message_samples_1', e.target.value)} value={profile.campaign_information?.message_samples_1 || ''} />
        {campaignVerification?.message_samples_1 && <small className="mt-1 block text-danger">{campaignVerification?.message_samples_1}</small>}
        <input className="form-control mt-2" name="message_samples_2" onChange={e => updateProfile('campaign_information', 'message_samples_2', e.target.value)} value={profile.campaign_information?.message_samples_2 || ''} />
        {campaignVerification?.message_samples_2 && <small className="mt-1 block text-danger">{campaignVerification?.message_samples_2}</small>}
        <input className="form-control mt-2" name="message_samples_3" onChange={e => updateProfile('campaign_information', 'message_samples_3', e.target.value)} value={profile.campaign_information?.message_samples_3 || ''} />
        {campaignVerification?.message_samples_3 && <small className="mt-1 block text-danger">{campaignVerification?.message_samples_3}</small>}
        <small className="mt-1 block">
          Three sample messages showing what type of messages you plan on sending. 20 character minimum. 1024 character maximum each.
        </small>
      </div>

      <div className="form-group">
        <label>Use Case</label>
        <select className="form-control" name="us_app_to_person_usecase" onChange={e => updateProfile('campaign_information', 'us_app_to_person_usecase', e.target.value)} value={profile.campaign_information?.us_app_to_person_usecase || ''}>
          <option value=""></option>
          {useCases.map(useCase => <option key={useCase.code} value={useCase.code} title={useCase.description}>
              {useCase.name}
            </option>)}
        </select>
        {campaignVerification?.us_app_to_person_usecase && <small className="mt-1 block text-danger">{campaignVerification?.us_app_to_person_usecase}</small>}
        <small className="mt-1 block">Hover over the options to view descriptions. "Marketing" is the most likely answer.</small>
      </div>

      <div className="form-group">
        <label>Has Embedded Links?</label>
        <select className="form-control" name="has_embedded_links" onChange={e => updateProfile('campaign_information', 'has_embedded_links', e.target.value)} value={profile.campaign_information?.has_embedded_links || ''}>
          <option value=""></option>
          <option value="true">Yes</option>
          <option value="false">No</option>
        </select>
        <small className="mt-1 block">Set this to "yes" if you plan on including links in your SMS messages.</small>
        {campaignVerification?.has_embedded_links && <small className="mt-1 block text-danger">{campaignVerification?.has_embedded_links}</small>}
      </div>
      <div className="form-group">
        <label>Has Embedded Phone Numbers?</label>
        <select className="form-control" name="has_embedded_phone" onChange={e => updateProfile('campaign_information', 'has_embedded_phone', e.target.value)} value={profile.campaign_information?.has_embedded_phone || ''}>
          <option value=""></option>
          <option value="true">Yes</option>
          <option value="false">No</option>
        </select>
        <small className="mt-1 block">Set this to "yes" if you plan on including phone numbers in your SMS messages.</small>
        {campaignVerification?.has_embedded_phone && <small className="mt-1 block text-danger">{campaignVerification?.has_embedded_phone}</small>}
      </div>

      <div style={{
      display: 'flex',
      gap: '10px',
      justifyContent: 'end'
    }}>
        <Button actionType="primary" disabled={isSaving} onClick={submit}>
          Submit {isSaving && <Loader className="ml-3" size="sm" />}
        </Button>
      </div>
    </>;
};