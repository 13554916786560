import React, { Component } from 'react';
import { Scoped, a } from 'kremling';
import { string, func, object } from 'prop-types';
import { debounce } from 'lodash';
import { Icon } from '../icon/icon.component';
import Button from '@mui/material/Button';
import styles from './page-header-mui.scss';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import CloseIcon from '@mui/icons-material/Close';
import { withStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { snackbarService } from 'components/mui/snackbar/snackbar-service';
const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'white',
      borderRadius: `14px`,
      '& fieldset': {
        border: 'none'
      }
    }
  }
})(TextField);
const RoundedTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderRadius: `14px`
    }
  }
})(TextField);
export class PageHeaderMui extends Component {
  static propTypes = {
    onSearch: func,
    type: string,
    searchVal: string,
    icon: object,
    name: string,
    updateName: func,
    subName: object
  };
  static defaultProps = {
    type: '',
    name: ''
  };
  state = {
    searchVal: '',
    showSearchBarIcon: false,
    showSearchPopDown: false,
    success: true,
    editVal: '',
    editName: false,
    oldName: null
  };
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions();
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }
  updateDimensions = () => {
    window.innerWidth > 1455 ? this.setState({
      showSearchBarIcon: false
    }) : this.setState({
      showSearchBarIcon: true
    });
  };
  onInputChange = searchVal => {
    this.setState({
      searchVal: searchVal || ''
    });
    this.debounceOnChange(searchVal);
  };
  debounceOnChange = debounce(searchVal => {
    this.props.onSearch(searchVal);
  }, 1000);
  reset = () => {
    this.setState({
      searchVal: ''
    });
    this.props.onSearch('');
  };
  saveEdit = () => {
    this.props.updateName(this.state.editVal);
    this.setState({
      editName: false
    });
  };
  editName = () => {
    this.setState({
      editName: true,
      editVal: this.props.name
    });
  };
  handleSnackbarUndo = () => {
    const undoName = this.state.editVal;
    const undoOld = this.state.oldName;
    this.setState({
      editVal: undoOld,
      oldName: undoName
    }, () => {
      this.saveEdit();
      snackbarService.close();
      snackbarService.popup({
        message: '"' + undoName + '" changed back to "' + undoOld + '"'
      });
    });
  };
  handleSnackbar = () => {
    snackbarService.popup({
      message: '"' + this.state.oldName + '" changed to "' + this.state.editVal + '"',
      action: <React.Fragment>
          <Button size="small" onClick={this.handleSnackbarUndo}>
            <Typography sx={{
            textTransform: 'none',
            color: '#53A6D6'
          }}>
            Undo
            </Typography>
          </Button>
          <IconButton size="small" color="white" onClick={snackbarService.close()}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
    });
  };
  render() {
    const {
      searchVal,
      showSearchBarIcon,
      editVal,
      editName,
      oldName
    } = this.state;
    const {
      type,
      icon,
      onSearch,
      name,
      subName
    } = this.props;
    document.title = this.props.type ? this.props.type + ' - Cinch' : 'Cinch';
    return <Scoped css={styles}>
        <div className="page-header" style={{
        px: '20px'
      }}>
          <Box>
            <Stack sx={{
            pl: '16px'
          }} direction="row">
              <Box sx={{
              py: 1,
              color: 'black',
              fontSize: 40
            }}>
                 {icon}
                 </Box>
              <Typography sx={{
              fontFamily: 'Nexa',
              fontSize: 24,
              color: '#1D252D',
              textAlign: 'center',
              margin: 'auto',
              pl: 1,
              opacity: this.props.name ? '0.6' : '1'
            }}>
                {' '}
                {type}{' '}
              </Typography>
              {this.props.name && <Stack direction='row'>
                  <Typography sx={{
                fontFamily: 'Nexa',
                fontSize: 24,
                color: '#1D252D',
                textAlign: 'center',
                margin: 'auto',
                pl: 1,
                opacity: '0.6'
              }}>
                    {' '}
                    &gt; {' '}
                  </Typography>
                  <Typography sx={{
                fontFamily: 'Nexa',
                fontSize: 24,
                color: '#1D252D',
                textAlign: 'center',
                margin: 'auto',
                pl: 1,
                cursor: 'pointer'
              }} onClick={this.editName}>
                    {' '}
                    {name}{' '}
                  </Typography>
                  <Box onClick={this.editName} sx={{
                cursor: 'pointer',
                pl: 1,
                mt: '12px'
              }}><Icon name="custom-pencil" size={24} /></Box>
                </Stack>}
            </Stack>
          </Box>

          <div className="page-header__actions">
            {onSearch || !showSearchBarIcon && onSearch ? <Box>
                <CustomTextField id="outlined-basic" placeholder={`Search ${name}`} sx={{
              width: '260px',
              height: '56px',
              pr: '20px'
            }} onChange={e => this.onInputChange(e.target.value)} value={searchVal} InputProps={{
              startAdornment: <InputAdornment position="start">
                        <SearchIcon sx={{
                  transform: 'scale(1.5)'
                }} />
                      </InputAdornment>,
              endAdornment: !!searchVal && <InputAdornment onClick={this.reset} position="start" sx={{
                cursor: 'pointer'
              }}>
                        <CloseIcon />
                      </InputAdornment>
            }} />
              </Box> : null}

            {subName ? <Box sx={{
            pr: '10px'
          }}>
                  {subName}
                </Box> : null}
          </div>
        </div>
        <Dialog PaperProps={{
        style: {
          borderRadius: '28px',
          width: '300px'
        }
      }} open={editName} onClose={() => {
        this.setState({
          editName: false
        });
      }}>
          <DialogTitle sx={{
          m: 0,
          p: 2,
          fontSize: 'medium'
        }}>
          <Stack alignItems="center" component="span">
          <Typography variant="h4" component="span"> Edit Name </Typography>
          </Stack>
          </DialogTitle>
          <DialogContent dividers>
            <DialogContentText>
              <Box component="span">
              <RoundedTextField label="Name" focused autoFocus onFocus={event => {
                event.target.select();
              }} value={editVal} onChange={e => this.setState({
                editVal: e.target.value
              })} onKeyPress={e => {
                if (e.key === 'Enter' && name != editVal && editVal != '') {
                  this.setState({
                    oldName: name
                  }, () => {
                    this.handleSnackbar();
                    this.saveEdit();
                  });
                }
              }} />
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{
          py: 1
        }}>
            <Button onClick={() => {
            this.setState({
              editName: false
            });
          }} variant="text" sx={{
            borderRadius: '20px',
            color: 'rgba(29, 37, 45, 0.7)',
            width: '75px'
          }}>
              Cancel
            </Button>
            <Button disabled={name == editVal || editVal == ''} onClick={() => {
            this.setState({
              oldName: name
            }, () => {
              this.handleSnackbar();
              this.saveEdit();
            });
          }} variant="text" sx={{
            mr: 1,
            borderRadius: '20px',
            width: '75px',
            color: '#53A6D6'
          }}>
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </Scoped>;
  }
}