import React, { useState, useContext } from 'react';
import { Button, Loader, toasterService, Banner } from 'components';
import { WorkingAsContext } from 'context/working-as-context';
import { submitA2P, verifyA2P } from 'shared/common.api';
export const SubmitA2PStep = props => {
  const {
    setActiveStep,
    a2pVerification,
    setA2PVerification,
    profile
  } = props;
  const {
    asCompany
  } = useContext(WorkingAsContext);
  const [isSaving, setIsSaving] = useState(false);
  const submit = () => {
    // Submit this to the Cinch API which will submit to the Twilio API...
    // This will not be stored in Cinch's database but will be retrieved directly from Twilio
    setIsSaving(true);
    verifyA2P(asCompany.id).then(({
      data
    }) => {
      setA2PVerification({
        ...data,
        steps: data.results.reduce((res, row) => {
          res[row.requirement_name] = {
            error: row.fields.reduce((res, field) => {
              if (field.passed === false) {
                res[field.object_field] = field.failure_reason;
              }
              return res;
            }, {}),
            passed: row.passed
          };
          return res;
        }, {})
      });
      if (data.status === 'compliant') {
        return submitA2P(asCompany.id);
      }
    }).then(() => setActiveStep(6)).catch(e => {
      toasterService.error('There was an unknown error trying to save your profile. Check your inputs and try again');
    }).then(() => {
      setIsSaving(false);
    });
  };
  if (profile.trust_bundle_status === 'in-review') {
    return <>
        {profile.trust_bundle_status === 'in-review' && <Banner type="info" className="my-3">
            Your application is currently in review.
          </Banner>}

        <div style={{
        display: 'flex',
        gap: '10px',
        justifyContent: 'end'
      }}>
          <Button actionType="flat" onClick={() => setActiveStep(3)}>
            Back
          </Button>
          <Button actionType="primary" disabled={isSaving} onClick={() => setActiveStep(6)}>
            Next
          </Button>
        </div>
      </>;
  }
  return <>
      {a2pVerification?.status === 'noncompliant' && <Banner type="warning" className="mb-3">
          Some of the information submitted is not valid. Please fix the errors and try again.
        </Banner>}
      <div style={{
      display: 'flex',
      gap: '10px',
      justifyContent: 'end'
    }}>
        <Button actionType="flat" onClick={() => setActiveStep(4)}>
          Back
        </Button>
        <Button actionType="primary" disabled={isSaving} onClick={submit}>
          Submit {isSaving && <Loader className="ml-3" size="sm" />}
        </Button>
      </div>
    </>;
};