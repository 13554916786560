import React, { Component } from 'react';
import { Scoped, k } from 'kremling';
import { NodeSelection } from './node-selection.component';
import { triggerNodes, actionNodes, conditionNodes } from '../node/node-list';
import { userState } from '../../../shared/user-state';
import { Icon } from '../../../components/icon/icon.component';
import { MODES } from '../node/node-utils';
import { journeyState } from '../journey-state';
import { getCompanyFields } from 'shared/common.api';
import { getInstalls } from '../../integrations/integrations.resource';
export class Toolbar extends Component {
  state = {
    mode: '',
    search: '',
    companyFields: [],
    installedIntegrations: [],
    triggerNodesFilter: [],
    actionNodesFilter: []
  };
  componentDidMount() {
    this.modeObserver = journeyState.subscribe(({
      mode
    }) => {
      this.setState({
        mode
      });
    }, 'mode');
    Promise.all([getCompanyFields(userState.getAsCompanyId()), getInstalls({
      company: userState.getAsCompanyId(),
      limit: 100
    })]).then(([companyFields, installedIntegrations]) => {
      this.setState({
        mode: journeyState.state.mode,
        companyFields: companyFields.data,
        installedIntegrations: installedIntegrations.results
      }, () => {
        this.handleFilterNodes();
      });
    });
  }
  componentWillUnmount() {
    this.modeObserver.unsubscribe();
  }
  handleFilterNodes = () => {
    const {
      installedIntegrations,
      companyFields
    } = this.state;
    const isField = field => companyFields.some(f => f.id === field);
    const isIntegration = integration => installedIntegrations.some(i => i.integration === integration);
    const triggerNodesFilter = triggerNodes.filter(node => {
      if (node.subType === 'trigger_time_schedule' && !isField('schedule') || node.subType === 'trigger_schedule' && !isField('schedule')) {
        return false;
      } else if (node.subType === 'trigger_abandoned_cart' && !isField('cart')) {
        return false;
      } else if (node.subType === 'trigger_shopify_fulfillment' && !isIntegration('shopify')) {
        return false;
      } else if (node.subType === 'trigger_zapier' && !isIntegration('zapier')) {
        return false;
      } else if (node.subType === 'trigger_review' && !isIntegration('reviews')) {
        return false;
      } else if (node.subType === 'trigger_loyalty_transaction' && !isIntegration('loyalty') || node.subType === 'trigger_received_coupon' && !isIntegration('loyalty') || node.subType === 'trigger_joined_loyalty' && !isIntegration('loyalty') || node.subType === 'trigger_redeemed_coupon' && !isIntegration('loyalty')) {
        return false;
      } else if (node.subType === 'action_send_survey_sms' && !isIntegration('surveys') || node.subType === 'action_send_survey_email' && !isIntegration('surveys')) {
        return false;
      } else {
        return true;
      }
    });
    const actionNodesFilter = actionNodes.filter(node => {
      if (node.subType === 'action_send_to_aircall' && !isIntegration('aircall')) {
        return false;
      } else if (node.subType === 'action_send_to_calltools' && !isIntegration('calltools')) {
        return false;
      } else if (node.subType === 'action_zapier' && !isIntegration('zapier')) {
        return false;
      } else if (node.subType === 'action_add_audiohook' && !isIntegration('audiohook') || node.subType === 'action_remove_audiohook' && !isIntegration('audiohook')) {
        return false;
      } else if ((node.subType === 'action_send_survey_sms' || node.subType === 'action_send_survey_email') && !isIntegration('surveys')) {
        return false;
      } else if (node.subType === 'action_give_offer' && !isIntegration('loyalty')) {
        return false;
        //} else if (node.subType === 'action_add_dripdrop' && !isIntegration('dripdrop')) {
        //  return false;
      } else if (node.subType === 'action_swell_review' && !isIntegration('swell')) {
        return false;
      } else if (node.subType === 'action_reply_review' && !isIntegration('reviews')) {
        return false;
      } else {
        return true;
      }
    });
    this.setState({
      triggerNodesFilter,
      actionNodesFilter
    });
  };
  render() {
    const {
      search,
      triggerNodesFilter,
      actionNodesFilter
    } = this.state;
    const triggers = triggerNodesFilter.filter(n => search === '' || n.name.search(new RegExp(search, 'i')) !== -1);
    const actions = actionNodesFilter.filter(n => search === '' || n.name.search(new RegExp(search, 'i')) !== -1);
    const conditions = conditionNodes.filter(n => search === '' || n.name?.search(new RegExp(search, 'i')) !== -1).filter(node => !node.visible || node.visible(userState));
    return <Scoped css={css}>
        <div className="toolbar">
          <div className="toolbar__search relative px-3 mb-5">
            <Icon name="fa-regular-search" className="search-icon" size={14} />
            <input className="form-control" placeholder="Search..." value={search} onChange={e => this.setState({
            search: e.target.value
          })} />
          </div>
          {triggers.length > 0 && <>
              <div className="toolbar__title">Triggers</div>
              <div className="toolbar-section">
                {triggers.map(node => <NodeSelection key={node.subType} node={node} draggable={this.state.mode === MODES.CANVAS} />)}
              </div>
            </>}
          {actions.length > 0 && <>
              <div className="toolbar__title">Actions</div>
              <div className="toolbar-section">
                {actions.map(node => <NodeSelection key={node.subType} node={node} draggable={this.state.mode === MODES.CANVAS} />)}
              </div>
            </>}
          {conditions.length > 0 && <>
              <div className="toolbar__title">Conditions</div>
              <div className="toolbar-section">
                {conditions.map(node => <NodeSelection key={node.subType} node={node} draggable={this.state.mode === MODES.CANVAS} />)}
              </div>
            </>}
          {this.state.mode === MODES.EDIT_PARAM && <div className="toolbar__overlay" />}
        </div>
      </Scoped>;
  }
}
const css = {
  styles: `[kremling="i24"] body,body[kremling="i24"] {
  --color-primary: #487aae;
  --color-highlight: #e7efff;
  --color-accent: #354052;
  --color-success: #73b56e;
  --color-warning: #f4b707;
  --color-danger: #df5651;
  --color-grey-10: #f9f9f9;
  --color-grey-25: #f3f3f3;
  --color-grey-50: #e9e9e9;
  --color-grey-75: #e3e3e3;
  --color-grey-100: #d3d3d3;
  --color-grey-200: #c3c3c3;
  --color-grey-300: #b3b3b3;
  --color-grey-400: #a3a3a3;
  --color-grey-500: #808080;
  --color-grey-600: #707070;
  --color-grey-700: #606060;
  --color-grey-800: #505050;
  --color-grey-900: #404040;
  --base-font-family: Roboto, Helvetica, Arial, sans-serif;
  --base-font-size: 1.4rem;
  --base-font-color: #404040;
  --base-font-weight: 400;
  --base-line-height: 1.4;
  --box-shadow-1: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.12);
  --box-shadow-2: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-3: 0 10px 20px rgba(0, 0, 0, 0.17), 0 6px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-4: 0 14px 28px rgba(0, 0, 0, 0.13), 0 10px 10px rgba(0, 0, 0, 0.1);
  --box-shadow-5: 0 19px 38px rgba(0, 0, 0, 0.18), 0 15px 12px rgba(0, 0, 0, 0.1);
  --base-border-radius: 0.4rem;
}

[kremling="i24"] .search-icon,[kremling="i24"].search-icon {
  position: absolute;
  top: 8px;
  left: 15px;
  color: #354052;
}

[kremling="i24"] .toolbar .form-control,[kremling="i24"].toolbar .form-control {
  padding-left: 2.5rem;
}

[kremling="i24"] .toolbar,[kremling="i24"].toolbar {
  background-color: #354052;
  width: 309px;
  padding: 1.6rem 0.8rem;
  flex-grow: 0;
  flex-shrink: 0;
  user-select: none;
  position: relative;
  overflow: auto;
}

[kremling="i24"] .toolbar__title,[kremling="i24"].toolbar__title {
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 16px;
  padding: 0 0.8rem;
}

[kremling="i24"] .toolbar-section,[kremling="i24"].toolbar-section {
  display: flex;
  flex-wrap: wrap;
}

[kremling="i24"] .toolbar__overlay,[kremling="i24"].toolbar__overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 30.9rem;
  background-color: rgba(0, 0, 0, 0.5);
}`,
  id: 'i24',
  namespace: 'kremling'
};