import { Container, Graphics, Rectangle } from 'pixi.js';
import { journeyState } from './journey-state';
import { updateNode, createNode } from 'shared/common.api';
import { NodeDelete } from './node/node-delete';
import { NodeDuplicate } from './node/node-duplicate';
import { cloneDeep } from 'lodash';
import { allNodes } from './node/node-list';
import { Node } from './node/node';
let sx, sy;
function boxesIntersect(a, b) {
  var ab = a.getBounds();
  var bb = b.getBounds();
  return ab.x + ab.width > bb.x && ab.x < bb.x + bb.width && ab.y + ab.height > bb.y && ab.y < bb.y + bb.height;
}
export class MultiSelect {
  box = null;
  boxDuplicate = null;
  // boundingRect
  container = null;
  eventData = null;
  sizing = false;
  active = false;
  dragging = false;
  textIntersections = [];
  nodeIntersections = [];
  x = 0;
  y = 0;
  width = 0;
  height = 0;
  constructor() {
    this.container = new Container();
    this.container.interactive = true;
    this.container.on('pointerdown', this.onDragStart).on('pointerup', this.onDragEnd).on('pointerupoutside', this.onDragEnd).on('pointermove', this.onDragMove);
  }
  onDragStart = event => {
    this.dragging = true;
    this.eventData = event.data;
    const position = this.eventData.getLocalPosition(this.container);
    sx = position.x - this.x;
    sy = position.y - this.y;
  };
  onDragEnd = event => {
    if (this.dragging) {
      this.dragging = false;
      if (this.textIntersections.length) {
        journeyState.saveText();
      }
      this.nodeIntersections.forEach(i => {
        if (journeyState.state.snapToGrid) {
          const containerInX = Math.round(i.container.x / 25) * 25;
          const containerInY = Math.round(i.container.y / 25) * 25;
          i.container.x = containerInX;
          i.container.y = containerInY;
          this.resizeToIntersections(true);
          this.delete.container.x = this.box.hitArea.x - 24;
          this.delete.container.y = this.box.hitArea.y - 24;
          this.duplicate.container.x = this.box.hitArea.x - 24;
          this.duplicate.container.y = this.box.hitArea.y + this.height;
        }
        updateNode(i.id, {
          x: i.container.x,
          y: i.container.y
        });
      });
    }
    if (this.sizing) {
      // We moused up from the initial drag
      this.checkIntersect();
      if (this.textIntersections.length + this.nodeIntersections.length <= 1) {
        // We didn't find 2 or more intersections so nothing to do
        this.stop();
      } else {
        // We dragged so we're done sizing and we can now start moving.
        this.sizing = false;
        this.resizeToIntersections(false);
      }
    }
  };
  onDragMove = () => {
    if (this.dragging) {
      const newPosition = this.eventData.getLocalPosition(this.container);
      let newX = newPosition.x - sx <= 0 ? 0 : newPosition.x - sx;
      let newY = newPosition.y - sy <= 0 ? 0 : newPosition.y - sy;
      let diffX = this.x - newX;
      let diffY = this.y - newY;
      if (newX === 0 && newPosition.x > 0) {
        sx = newPosition.x;
      }
      if (newY === 0 && newPosition.y > 0) {
        sy = newPosition.y;
      }
      this.x = newX;
      this.y = newY;
      const update = i => {
        i.container.x -= diffX;
        i.container.y -= diffY;
        journeyState.updateNodeLinks(i);
      };
      this.textIntersections.forEach(update);
      this.nodeIntersections.forEach(update);
      this.delete.container.x = this.box.hitArea.x - 24;
      this.delete.container.y = this.box.hitArea.y - 24;
      this.duplicate.container.x = this.box.hitArea.x - 24;
      this.duplicate.container.y = this.box.hitArea.y + this.height;
      this.draw();
    }
  };
  start = (x, y) => {
    this.active = true;
    this.sizing = true;
    this.x = x;
    this.y = y;
  };
  setSize = (width, height) => {
    this.width = width;
    this.height = height;
    this.draw();
  };
  stop = () => {
    this.active = false;
    this.sizing = false;
    this.dragging = false;
    this.textIntersections = [];
    this.nodeIntersections = [];
    this.x = 0;
    this.y = 0;
    this.width = 0;
    this.height = 0;
    this.clear();
  };
  clear = () => {
    if (this.box) {
      this.boxDuplicate = this.box;
      this.container.removeChild(this.box);
      this.box.destroy();
      this.box = null;
    }
    if (this.delete && !this.active && this.delete.container.children[1].alpha !== 1 && this.duplicate && !this.active && this.duplicate.container.children[1].alpha !== 1) {
      this.container.removeChild(this.delete.container);
      this.container.removeChild(this.duplicate.container);
    }
  };
  onDelete = nodes => {
    nodes.forEach(node => journeyState.removeNode(node));
    this.container.removeChild(this.delete.container);
    this.container.removeChild(this.duplicate.container);
  };
  onDuplicate = async nodes => {
    this.container.removeChild(this.delete.container);
    this.container.removeChild(this.duplicate.container);
    const {
      x,
      y,
      width,
      type,
      height
    } = this.boxDuplicate.hitArea;
    let newNodes = await Promise.all(nodes.map(async n => {
      const position = {
        x: x + width + n.container.x - x,
        y: n.container.y
      };
      const item = cloneDeep(allNodes.find(node => node.subType === n.subType));
      item.defaultParameters = cloneDeep(item.parameters);
      item.parameters = n.parameters || (item.initParams ? item.initParams() : item.parameters);
      const node = new Node(item, position);
      journeyState.state.nodesContainer.addChild(node.container);
      let newNode = await createNode(node.type, node.subType, node.container.x, node.container.y, journeyState.state.revisionId, node.parameters);
      node.id = newNode.data.id;
      node.duplicateFrom = n.id;
      journeyState.set({
        nodes: [...journeyState.state.nodes, node]
      });
      return node;
    }));
    journeyState.state.links.forEach(link => {
      let fromNode;
      let toNode;
      let initNode;
      newNodes.forEach(n => {
        initNode = n;
        if (n.duplicateFrom === link.nodeFrom.id) {
          fromNode = n;
        }
        if (n.duplicateFrom === link.nodeTo.id) {
          toNode = n;
        }
      });
      if (fromNode && toNode) {
        journeyState.addLink(fromNode, toNode, null, link.onEvent, initNode.onEventList, initNode.getEvent, initNode.getEventList);
        fromNode = null;
        toNode = null;
      }
    });
    this.x = x + width;
    this.y = y;
    this.width = width;
    this.height = height;
    this.active = true;
    this.sizing = false;
    this.dragging = false;
    newNodes.forEach(n => {
      this.nodeIntersections.push(n);
    });
    this.draw(newNodes, true);
  };
  draw = (nodes, isResizeToIntersections) => {
    this.clear();
    this.box = new Graphics();
    this.box.lineStyle(1, 0x333333);
    this.box.drawRect(this.x, this.y, this.width, this.height);
    this.box.hitArea = new Rectangle(this.x, this.y, this.width, this.height);
    if (isResizeToIntersections === true) {
      this.delete = new NodeDelete(() => this.onDelete(nodes));
      this.delete.container.x = this.box.hitArea.x - 24;
      this.delete.container.y = this.box.hitArea.y - 24;
      this.delete.container.alpha = 1;
      this.container.addChild(this.delete.container);
      this.container.addChild(this.box);
      this.duplicate = new NodeDuplicate(() => this.onDuplicate(nodes));
      this.duplicate.container.x = this.box.hitArea.x - 24;
      this.duplicate.container.y = this.box.hitArea.y + this.height;
      this.duplicate.container.alpha = 1;
      this.container.addChild(this.duplicate.container);
      this.container.addChild(this.box);
    } else {
      this.container.addChild(this.box);
    }
  };
  checkIntersect = () => {
    this.textIntersections = [];
    this.nodeIntersections = [];
    if (this.width === 0 || this.height === 0) {
      return;
    }
    journeyState.state.nodes.forEach(node => {
      if (boxesIntersect(node.container, this.box)) {
        this.nodeIntersections.push(node);
      }
    });
    journeyState.state.texts.forEach(text => {
      if (boxesIntersect(text.container, this.box)) {
        this.textIntersections.push(text);
      }
    });
  };
  resizeToIntersections = isDragEnd => {
    const buffer = 10;
    let x1, y1, x2, y2;
    const resize = intersection => {
      let bounds = intersection.container.getBounds();
      let offset = intersection.container.parent.parent.getBounds();
      offset.x /= intersection.container.parent.parent.scale.x;
      offset.y /= intersection.container.parent.parent.scale.y;
      bounds.x /= intersection.container.parent.parent.scale.x;
      bounds.y /= intersection.container.parent.parent.scale.y;
      bounds.width /= intersection.container.parent.parent.scale.x;
      bounds.height /= intersection.container.parent.parent.scale.y;
      let ix1 = bounds.x - offset.x - buffer;
      let iy1 = bounds.y - offset.y - buffer;
      let ix2 = bounds.x - offset.x + bounds.width + buffer;
      let iy2 = bounds.y - offset.y + bounds.height + buffer;
      if (x1 === undefined || ix1 < x1) x1 = ix1;
      if (y1 === undefined || iy1 < y1) y1 = iy1;
      if (x2 === undefined || ix2 > x2) x2 = ix2;
      if (y2 === undefined || iy2 > y2) y2 = iy2;
    };
    this.textIntersections.forEach(resize);
    this.nodeIntersections.forEach(resize);
    this.x = x1;
    this.y = y1;
    this.width = x2 - x1;
    this.height = y2 - y1;
    if (journeyState.state.snapToGrid && isDragEnd) {
      this.draw(this.nodeIntersections, false);
    } else {
      this.draw(this.nodeIntersections, true);
    }
  };
}