import qs from 'qs';
let editor = null;
const constants = {
  USER_TOKEN: 'userToken',
  USER_INFO: 'userInfo'
};
const utils = {
  // setEditor: e => {
  //   editor = e;
  // },
  // getEditor: () => {
  //   return editor;
  // },
  setUserToken: (rememberMe, token) => {
    if (rememberMe) {
      localStorage.setItem(constants.USER_TOKEN, token);
      utils.setUserInfo(rememberMe);
    } else {
      sessionStorage.setItem(constants.USER_TOKEN, token);
      utils.setUserInfo(rememberMe);
    }
  },
  getUserInfo: () => {
    if (localStorage.getItem(constants.USER_INFO)) {
      return JSON.parse(localStorage.getItem(constants.USER_INFO));
    } else if (sessionStorage.getItem(constants.USER_INFO)) {
      return JSON.parse(sessionStorage.getItem(constants.USER_INFO));
    } else {
      return [];
    }
  },
  setCompany: company => {
    localStorage.setItem(constants.COMPANY_SELECTED, JSON.stringify(company));
  },
  getCompany: () => {
    return localStorage.getItem(constants.COMPANY_SELECTED) ? JSON.parse(localStorage.getItem(constants.COMPANY_SELECTED)) : null;
  },
  setUserInfo: (rememberMe = false) => {
    fetch('https://engine-dev.cinch.io/users/me', {
      method: 'get',
      headers: new Headers({
        Authorization: 'Token ' + utils.getUserToken()
      })
    }).then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Something went wrong ...');
      }
    }).then(data => {
      data = JSON.stringify(data);
      if (rememberMe) {
        localStorage.setItem(constans.USER_INFO, data);
      } else {
        sessionStorage.setItem(constants.USER_INFO, data);
      }
    });
  },
  getUserToken: () => {
    if (localStorage.getItem(constants.USER_TOKEN)) {
      return localStorage.getItem(constants.USER_TOKEN);
    }
    return sessionStorage.getItem(constants.USER_TOKEN);
  },
  handleUserInput: (e, context) => {
    const name = e.target.name;
    const value = e.target.value;
    context.setState({
      [name]: value
    }); // Set input value
  },
  handleUserInputCheckbox(e, context) {
    const name = e.target.name;
    const value = e.target.checked;
    context.setState({
      [name]: value
    }); // Set input value
  },
  setRedirect: function (pathname, state) {
    this.setState({
      redirect: {
        active: true,
        to: {
          pathname,
          state
        }
      }
    });
  },
  downloadBlob: (blob, filename) => {
    // TODO @Tony is this the best way to download a file?
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
  },
  convertStringToFilename: (string, extension) => {
    return string.replace(/[^a-z0-9]/gi, '_').toLowerCase() + '.' + extension;
  },
  commaize: x => {
    let parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  },
  updateHistory(state, filters) {
    let params = {};
    if (state.paginationParams.offset) {
      params.offset = state.paginationParams.offset;
    }
    filters.forEach(key => {
      if (state[key] && state[key] !== '') {
        params[key] = state[key];
      }
    });
    if (Object.keys(params).length) {
      let newurl = window.location.protocol + '//' + window.location.host + window.location.pathname + '?' + qs.stringify(params);
      window.history.pushState({
        path: newurl
      }, '', newurl);
    }
  },
  loadStateFromParams(thisState, filters, showFunction) {
    // Load state from query parameters
    const params = qs.parse(this.props.location.search.slice(1));
    const state = {};
    filters.forEach(key => {
      if (params[key]) {
        state[key] = params[key];
      }
    });
    if (params.offset) {
      thisState.paginationParams = {
        ...thisState.paginationParams,
        offset: params.offset
      };
    }
    if (Object.keys(state).length) {
      this.setState(state, () => {
        showFunction();
      });
    } else {
      showFunction();
    }
  },
  formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? '+1 ' : '';
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return phoneNumberString;
  },
  formatIntlPhone(phoneNumberString) {
    const numbersOnly = (phoneNumberString || '').replace(/\D/g, '');
    if (numbersOnly.length === 10) {
      return `+1${numbersOnly}`;
    }
    return `+${numbersOnly}`;
  }
};
export default utils;
export function buildFormData(body) {
  const formData = new FormData();
  Object.keys(body).map(key => {
    formData.append(key, body[key]);
  });
  return formData;
}