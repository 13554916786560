import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import moment from 'moment';
import { Scoped, k } from 'kremling';
import copy from 'copy-to-clipboard';
import { PageHeader } from '../../components/page-header/page-header';
import { Button } from '../../components/button/button.component';
import { Dropdown } from '../../components/dropdown/dropdown.component';
import { Loader } from '../../components/loader/loader.component';
import { Pagination } from '../../components/pagination/pagination.component';
import { getCoupons } from '../../shared/common.api';
import { userState } from '../../shared/user-state';
import { SortHeader } from '../../components/table/sort-header.component';
import { CouponModal } from './coupon-modal.component';
import { CouponCreateBatchModal } from './coupon-create-batch-modal.component';
import { CouponDownloadBatchModal } from './coupon-download-batch-modal.component';
import { toasterService } from 'components';
export const CouponsList = () => {
  const [coupons, setCoupons] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [paginationParams, setPaginationParams] = useState({
    currentPage: 1
  });
  const [ordering, setOrdering] = useState(null);
  const [paginationData, setPaginationData] = useState();
  const [showModal, setShowModal] = useState(false);
  const [createBatchCoupon, setCreateBatchCoupon] = useState();
  const [downloadBatchCoupon, setDownloadBatchCoupon] = useState();
  const copyId = id => {
    if (copy(id)) {
      toasterService.success(`Coupon ID ${id} copied to clipboard`);
    }
  };
  const showCoupons = () => {
    setIsLoading(true);
    getCoupons({
      ...paginationParams,
      company: userState.getAsCompanyId(),
      ordering
    }).then(({
      data
    }) => {
      if (data) {
        setCoupons(data.results);
        setIsLoading(false);
        setPaginationData(data);
      }
    });
  };
  useEffect(() => {
    showCoupons();
  }, [search, paginationParams, ordering]);

  /*
  goToCreateCoupon() {
    this.props.history.push({
      pathname: '/create-coupon',
      state: { copy: false, edit: false },
    });
  }
   isCouponSelected(id) {
    this.setState({ isSelected: id, openModal: true });
  }
   isCouponEdited(id) {
    this.setState({ isSelected: id });
    this.props.history.push({
      pathname: `/coupons/${id}/edit`,
      state: { edit: true },
    });
  }
   isCouponCopied(id) {
    this.setState({ isSelected: id });
    this.props.history.push({
      pathname: `/coupons/${id}/copy`,
      state: { copy: true },
    });
  }
   deleteCoupon() {
    deleteCoupon(this.state.isSelected).then(({ data }) => {
      this.setState({ openModal: false });
      window.location.reload();
    });
  }
   goToCoupon = (id) => {
    this.props.history.push(`/coupons/${id}`);
  };
   updateOrdering = (ordering) => {
    this.setState({ ordering, currentPage: 1 }, () => this.showCoupons());
  };
  */

  return <Scoped css={css}>
      <div className="wrapper">
        <PageHeader name="Coupons" onSearch={setSearch} actions={<div>
              {userState.hasPermission('coupon.add_coupon') && <Button actionType="primary" icon="fa-regular-plus" onClick={() => setShowModal(true)} />}
            </div>} />
        <div className="wrapper-scroll coupons-list">
          {isLoading && <Loader overlay />}

          <table className="table-list">
            {!isLoading && !!coupons.length && <thead>
                <tr>
                  <SortHeader name="id" ordering={ordering} update={setOrdering}>
                    ID
                  </SortHeader>
                  <SortHeader name="name" ordering={ordering} update={setOrdering}>
                    Name
                  </SortHeader>
                  <SortHeader name="created_when" ordering={ordering} update={setOrdering}>
                    Created
                  </SortHeader>
                  <SortHeader name="discount_type" ordering={ordering} update={setOrdering}>
                    Discount Type
                  </SortHeader>
                  <SortHeader name="discount_amount" ordering={ordering} update={setOrdering}>
                    Discount Amount
                  </SortHeader>
                  <SortHeader name="promo_code" ordering={ordering} update={setOrdering}>
                    Promo Code
                  </SortHeader>
                  <th>Redeemed / Assigned / Unassigned</th>
                  <th style={{
                width: 40
              }} />
                </tr>
              </thead>}
            <tbody>
              {coupons.length && !isLoading && coupons.map(coupon => <tr key={coupon.id} onClick={() => copyId(coupon.id)}>
                    <td>{coupon.id}</td>
                    <td>{coupon.name}</td>
                    <td>{moment(coupon.created_when).format('MMM D, YYYY')}</td>
                    <td>
                      {coupon.discount_type === 'amount_off' && '$ OFF' || coupon.discount_type === 'percent_off' && '% OFF' || coupon.discount_type === 'sale_price' && 'Sale Price'}
                    </td>
                    <td>{coupon.discount_amount}</td>
                    <td>{coupon.promo_code}</td>
                    <td>
                      {coupon.redeemed} / {coupon.assigned} /{' '}
                      {coupon.unassigned}
                    </td>
                    <td>
                      {userState.hasPermission('coupon.add_coupon') && <Dropdown horizontal="west" trigger={() => <Button actionType="flat" icon="fa-regular-ellipsis-h" />} content={({
                  close
                }) => <ul className="select-list">
                              <li onClick={() => {
                    setCreateBatchCoupon(coupon);
                    close();
                  }}>
                                <a>Create Batch</a>
                              </li>
                              <li onClick={() => {
                    setDownloadBatchCoupon(coupon);
                    close();
                  }}>
                                <a>Download Batch</a>
                              </li>
                            </ul>} />}
                    </td>
                  </tr>) || <tr>
                  {!isLoading && <td colSpan={6}>
                      <strong>No Results</strong>
                    </td>}
                </tr>}
            </tbody>
          </table>
          <Pagination data={paginationData} onChange={setPaginationParams} />
        </div>
      </div>

      <CouponModal onClose={() => setShowModal(false)} onSubmit={() => showCoupons()} open={showModal} />
      <CouponCreateBatchModal onClose={() => setCreateBatchCoupon()} onSubmit={() => {
      setDownloadBatchCoupon(createBatchCoupon);
      showCoupons();
    }} coupon={createBatchCoupon} open={!!createBatchCoupon} />
      <CouponDownloadBatchModal open={!!downloadBatchCoupon} coupon={downloadBatchCoupon} onClose={() => setDownloadBatchCoupon()} />
    </Scoped>;
};
const css = {
  styles: `[kremling="i15"] body,body[kremling="i15"] {
  --color-primary: #487aae;
  --color-highlight: #e7efff;
  --color-accent: #354052;
  --color-success: #73b56e;
  --color-warning: #f4b707;
  --color-danger: #df5651;
  --color-grey-10: #f9f9f9;
  --color-grey-25: #f3f3f3;
  --color-grey-50: #e9e9e9;
  --color-grey-75: #e3e3e3;
  --color-grey-100: #d3d3d3;
  --color-grey-200: #c3c3c3;
  --color-grey-300: #b3b3b3;
  --color-grey-400: #a3a3a3;
  --color-grey-500: #808080;
  --color-grey-600: #707070;
  --color-grey-700: #606060;
  --color-grey-800: #505050;
  --color-grey-900: #404040;
  --base-font-family: Roboto, Helvetica, Arial, sans-serif;
  --base-font-size: 1.4rem;
  --base-font-color: #404040;
  --base-font-weight: 400;
  --base-line-height: 1.4;
  --box-shadow-1: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.12);
  --box-shadow-2: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-3: 0 10px 20px rgba(0, 0, 0, 0.17), 0 6px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-4: 0 14px 28px rgba(0, 0, 0, 0.13), 0 10px 10px rgba(0, 0, 0, 0.1);
  --box-shadow-5: 0 19px 38px rgba(0, 0, 0, 0.18), 0 15px 12px rgba(0, 0, 0, 0.1);
  --base-border-radius: 0.4rem;
}

[kremling="i15"] .coupons-list,[kremling="i15"].coupons-list {
  padding: 2rem;
}

[kremling="i15"] .coupons-list table button,[kremling="i15"].coupons-list table button {
  opacity: 0;
}
[kremling="i15"] .coupons-list table tr:hover button,[kremling="i15"].coupons-list table tr:hover button {
  opacity: 1;
}

[kremling="i15"] .coupon-dropdown,[kremling="i15"].coupon-dropdown {
  width: 100px !important;
}

[kremling="i15"] .invalid-input,[kremling="i15"].invalid-input {
  margin-bottom: 0px;
  color: red;
}`,
  id: 'i15',
  namespace: 'kremling'
};