const CONSTANTS = {
  URL: {
    ADD_TEMPLATE_MAIL: '/templates/print/add',
    LIST_TEMPLATE: '/templates'
  },
  MODES: {
    TEMPLATE: {
      CREATE: 'CREATE',
      PREVIEW: 'PREVIEW',
      PUBLISHV: 'PUBLISH'
    }
  },
  READ_ONLY_CUSTOMER_FIELDS: ['id', 'company_id', 'created_when', 'updated_when', 'deleted_when', 'entity_ref', 'entity_source', 'entity_number', 'index_rank', 'customer_value', 'customer_value_segment', 'visit_trend_days', 'predicted_return_date', 'predicted_visit_next_12_months', 'predicted_spend_next_12_months', 'product_preferences', 'recommended_products', 'pricing_sensitivity', 'last_transaction_when', 'first_transaction_when', 'appended_data__', 'stat_last_location']
};
export default CONSTANTS;