import React, { Component } from 'react';
import { bool, func } from 'prop-types';
import { Modal } from '../../components/modal/modal.component';
import { Button } from '../../components/button/button.component';
import { Loader } from '../../components/loader/loader.component';
export class RepublishModal extends Component {
  static propTypes = {
    isOpen: bool,
    publishJourney: func,
    onClose: func,
    endRevision: func
  };
  state = {
    republishOption: null,
    isLoading: false
  };
  submitRepublish = () => {
    this.setState({
      isLoading: true
    });
    this.props.endRevision(this.state.republishOption).then(this.props.publishJourney).then(this.closeRepublishModal);
  };
  closeRepublishModal = () => {
    this.setState({
      republishOption: null,
      isLoading: false
    });
    this.props.onClose();
  };
  render() {
    const {
      republishOption,
      isLoading
    } = this.state;
    const {
      isOpen
    } = this.props;
    return <Modal open={isOpen} onClose={this.closeRepublishModal} allowBackdropClick>
        <div className="modal__title">Publish Journey Draft</div>
        <div className="modal__body">
          <div className="mb-md">
            What would you like to do with your previously published version
            of this journey?
          </div>
          <div className="form-group form-group__radio">
            <label>
              <input type="radio" name="republish" checked={republishOption === 'close'} onChange={() => this.setState({
              republishOption: 'close'
            })} />
              <div>
                <strong>Close it</strong> - no new customers will enter the
                version of this journey being replaced, customers currently
                in the journey will continue.
              </div>
            </label>
            <label>
              <input type="radio" name="republish" onChange={() => this.setState({
              republishOption: 'stop'
            })} />
              <div>
                <strong>Stop it</strong> - all customers in the journey
                version being replaced will stop immediately and no new
                customers will enter it.
              </div>
            </label>
          </div>
        </div>
        <div className="modal__actions">
          <Button actionType="flat" onClick={this.closeRepublishModal}>
            Cancel
          </Button>
          <Button actionType="primary" className="float-right" onClick={this.submitRepublish} disabled={!republishOption}>
            {isLoading ? <Loader size="sm" /> : 'Submit'}
          </Button>
        </div>
      </Modal>;
  }
}