import React, { useState, useContext } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import { validatePhone, validateCode } from 'shared/auth.api';
import { snackbarService } from 'components/mui/snackbar/snackbar-service';
import { updateUser } from 'shared/common.api';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { userState } from '../shared/user-state';
import { WorkingAsContext } from './working-as-context';
export default function NeedsPhoneModal(props) {
  const {
    open,
    me,
    setUser,
    logOut,
    optionalOptIn,
    onClose
  } = props;
  const [phone, setPhone] = useState('');
  const [code, setCode] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [smsConsent, setSmsConsent] = useState(false);
  const {
    triggerNeedsPhoneModal
  } = useContext(WorkingAsContext);
  const submitPhone = () => {
    setIsLoading(true);
    setCode(undefined);
    validatePhone(phone).then(() => {
      setCode('');
    }).catch(({
      response
    }) => {
      if (response?.data?.non_field_errors?.[0]) {
        snackbarService.popup({
          type: 'error',
          message: response?.data?.non_field_errors?.[0]
        });
      } else {
        snackbarService.popup({
          type: 'error',
          message: 'Unknown error trying to send multi-factor authentication code.'
        });
      }
    }).then(() => {
      setIsLoading(false);
    });
  };
  const changePhone = () => {
    setCode(undefined);
  };
  const submitCode = () => {
    setIsLoading(true);
    validateCode(phone, code).then(() => updateUser(me.id, {
      phone: phone,
      require_mfa: true
    })).then(() => {
      setUser({
        ...me,
        phone,
        requires_phone: false,
        require_mfa: true
      });
      triggerNeedsPhoneModal(false);
      onClose(phone);
    }).catch(({
      response
    }) => {
      if (response?.data?.non_field_errors?.[0]) {
        snackbarService.popup({
          type: 'error',
          message: response?.data?.non_field_errors?.[0]
        });
      } else {
        snackbarService.popup({
          type: 'error',
          message: 'Unknown error trying to validate phone number.'
        });
      }
    }).then(() => {
      setIsLoading(false);
    });
  };
  return <Dialog BackdropProps={{
    style: {
      backdropFilter: 'blur(3px)'
    }
  }} PaperProps={{
    style: {
      borderRadius: '28px',
      minWidth: '700px'
    }
  }} open={open} onClose={() => {}}>
      <DialogTitle sx={{
      m: 0,
      p: 2,
      fontSize: 'medium'
    }}>
        <Stack alignItems="center" component="span">
          <ErrorOutlineIcon sx={{
          fontSize: 40,
          color: '#EF3C34'
        }} />
          <Typography variant="h4" component="span">
            {optionalOptIn ? 'Enroll in MFA' : 'Phone Number Needed'}
          </Typography>
        </Stack>
      </DialogTitle>
      <DialogContent dividers>
        <Box component="p">
          {code === undefined ? <>
              {optionalOptIn ? <Typography>
                  By entering or changing your phone number, you will be enrolled in multi-factor authentication with text messaging. Your
                  ownership of that phone number will also be validated now. If you don't wish to do this, you may cancel
                </Typography> : <Typography>
                  A company you have access to requires multi-factor authentication and your account doesn't have a phone number. Please add
                  a phone number now.
                </Typography>}
            </> : <>A code has been sent to your phone. Please enter it below.</>}
        </Box>
        {!optionalOptIn && <Box component="p">
            If you would like to log in as a different account you'll need to{' '}
            <Button component="a" onClick={logOut} variant="text">
              log out
            </Button>
          </Box>}
        <InputLabel disabled={code !== undefined} sx={{
        marginTop: '15px'
      }}>
          Phone Number
        </InputLabel>
        <Input disabled={code !== undefined} fullWidth={true} required={true} value={phone} onChange={e => setPhone(e.target.value)} placeholder={userState.user?.phone ? 'Enter your new phone number' : 'Enter your phone number'} />

        {code !== undefined && <>
            <InputLabel sx={{
          marginTop: '15px'
        }}>Verification Code</InputLabel>
            <Input fullWidth={true} required={true} value={code} onChange={e => setCode(e.target.value)} placeholder="Enter the verification code sent to your phone" />
          </>}

        <FormGroup sx={{
        pt: '20px'
      }}>
          <FormControlLabel control={<Checkbox checked={smsConsent} onClick={e => {
          setSmsConsent(!smsConsent);
        }} sx={{
          '& .MuiSvgIcon-root': {
            fontSize: 24
          }
        }} />} label="By checking this box you consent and agree to receive automated SMS messages related to login authentication" />
        </FormGroup>
      </DialogContent>
      <DialogActions sx={{
      py: 1
    }}>
        {optionalOptIn && <Button onClick={e => {
        triggerNeedsPhoneModal(false);
      }} variant="text">
            Cancel
          </Button>}

        {code != undefined && !isLoading && <Button disabled={code === undefined || isLoading} onClick={changePhone} variant="text">
            Modify Incorrect Phone Number
          </Button>}
        {code != undefined && !isLoading && <Button onClick={submitPhone} disabled={code === undefined || isLoading} variant="text">
            Resend Code
          </Button>}
        {code === undefined ? <Button onClick={submitPhone} disabled={phone === '' || isLoading || !smsConsent} variant="text">
            Proceed
          </Button> : <Button onClick={submitCode} disabled={code === '' || isLoading || !smsConsent} variant="text">
            Proceed
          </Button>}
      </DialogActions>
    </Dialog>;
}