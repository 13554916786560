import * as React from 'react';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Unstable_Grid2';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Box from '@mui/material/Box';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import { AntSwitch } from '../switch/ant-switch.component';
import { styled } from '@mui/system';
const CustomCheckbox = withStyles({
  root: {
    "&$checked": {
      color: '#53A6D6'
    }
  },
  checked: {}
})(props => <Checkbox color="default" {...props} />);
const SearchBox = styled(TextField)(() => ({
  '& fieldset': {
    borderRadius: '14px'
  }
}));
export const MultiSelectFilter = props => {
  const {
    showChip,
    filterKey,
    filters,
    disabled,
    chipStyle,
    label,
    handleFilterChange,
    options
  } = props;
  if (showChip) {
    if (!filters[filterKey]) {
      return null;
    }
    const option = options.find(option => option.value === filters[filterKey][0]);
    if (!option) {
      return null;
    }
    const extra = filters[filterKey].length > 1 ? ` +${filters[filterKey].length - 1}` : '';
    return <Chip label=<span>
          {label}: {option.chipLabel || option.label}
          {extra}
        </span> onDelete={() => {
      const {
        [filterKey]: _,
        ...newFilters
      } = filters || {};
      handleFilterChange(newFilters);
    }} deleteIcon={<CloseOutlinedIcon sx={{
      transform: 'scale(0.75)'
    }} />} className={chipStyle} />;
  }
  const [selectAll, setSelectAll] = React.useState(filters[filterKey] && filters[filterKey].length > 0 ? false : true);
  const [itemSearch, setItemSearch] = React.useState();
  const [showMoreValue, setShowMoreValue] = React.useState(10);
  return <FormGroup className="pt-1">
      <FormLabel><Typography variant='tableHeader'>{label}</Typography></FormLabel>
      <Grid container spacing={2}>
        <Grid xs={8}>
          <Typography> Select all {label.toLowerCase()}s </Typography>
        </Grid>
        <Grid xs={4}>
          <AntSwitch sx={{
          ml: '40px'
        }} disabled={disabled} checked={selectAll} onChange={() => {
          let {
            [filterKey]: _,
            ...newFilters
          } = filters || {};
          handleFilterChange(newFilters);
          setSelectAll(!selectAll);
        }} inputProps={{
          'aria-label': 'ant design'
        }} />
        </Grid>
      </Grid>
      {options.length >= 10 && <Box sx={{
      py: 1
    }}>
          <SearchBox fullWidth disabled={disabled} onChange={(e, v) => {
        setItemSearch(e.target.value);
      }} id="outlined-basic" label="Search Locations" variant="outlined" />
        </Box>}
      {options.filter(option => !itemSearch || (option.label || '').toLowerCase().includes(itemSearch.toLowerCase())).slice(0, itemSearch ? options.length : showMoreValue).map(option => <FormControlLabel sx={{
      ml: '-4px'
    }} key={option.value} control={<CustomCheckbox disabled={disabled} checked={selectAll || (filters[filterKey] || []).indexOf(option.value) > -1} onClick={e => {
      if (!e.target.checked) {
        // Item turned off
        if (selectAll) {
          // Set all to off
          setSelectAll(false);
          // Set the search options to everything except the one item.
          handleFilterChange({
            ...(filters || {}),
            [filterKey]: options.filter(o => o.value !== option.value).map(o => o.value)
          });
        } else {
          // Remove the one item from current items
          handleFilterChange({
            ...(filters || {}),
            [filterKey]: (filters[filterKey] || []).filter(o => o !== option.value)
          });
        }
      } else {
        // Item turned on
        if ((filters[filterKey] || []).length + 1 === options.length) {
          // All are selected
          let {
            [filterKey]: _,
            ...newFilters
          } = filters || {};
          handleFilterChange(newFilters);
          setSelectAll(true);
        } else {
          handleFilterChange({
            ...(filters || {}),
            [filterKey]: [...(filters[filterKey] || []), option.value]
          });
        }
      }
    }} />} label={option.label} />)}

        {options.length > showMoreValue && !itemSearch && <Button onClick={() => {
      setShowMoreValue(showMoreValue + 5);
    }}>
            <Typography sx={{
        textTransform: 'none',
        color: '#53A6D6'
      }}>Show more </Typography>
          </Button>}
    </FormGroup>;
};