import SimpleState from '@geoctrl/simple-state';
import * as Sentry from '@sentry/react';
import { toasterService } from 'components/toaster/toaster-service';
import { history } from '../root.component';
import { logout } from 'shared/auth.api';
import Cookies from 'js-cookie';
const TOKEN = 'cinch.userToken';
const TOKEN_EXPIRES = 'cinch.userTokenExpires';
const AS_COMPANY = 'cinch.asCompany';
const LOCAL = 'localhost';
const DEV = 'marketing-dev.cinch.io';
const PROD = 'marketing.cinch.io';

/**
 * User State
 * ---------------
 * The token is the only thing that should be saved locally.
 * On first load, the app should get fresh user data, checking
 * if the token is still valid.
 * All other data (eg: user save) should be held in memory.
 */
class UserState extends SimpleState {
  constructor(defaultState) {
    super(defaultState);
  }
  setToken = (token, tokenExpires) => {
    if (window.location.host.substring(0, 9) === LOCAL) {
      Cookies.set(TOKEN, token, {
        expires: 365,
        sameSite: 'strict',
        secure: true
      });
      Cookies.set(TOKEN_EXPIRES, tokenExpires, {
        expires: 365,
        sameSite: 'strict',
        secure: true
      });
    } else if (window.location.host === DEV) {
      Cookies.set(TOKEN, token, {
        expires: 365,
        domain: '.' + DEV,
        sameSite: 'strict',
        secure: true
      });
      Cookies.set(TOKEN_EXPIRES, tokenExpires, {
        domain: '.' + DEV,
        expires: 365,
        sameSite: 'strict',
        secure: true
      });
    } else {
      Cookies.set(TOKEN, token, {
        expires: 365,
        domain: '.' + PROD,
        sameSite: 'strict',
        secure: true
      });
      Cookies.set(TOKEN_EXPIRES, tokenExpires, {
        domain: '.' + PROD,
        expires: 365,
        sameSite: 'strict',
        secure: true
      });
    }
  };
  getToken = () => {
    return {
      token: Cookies.get(TOKEN),
      expires: Cookies.get(TOKEN_EXPIRES)
    };
  };
  setAsCompany = asCompany => {
    Sentry.setContext('working_as', {
      id: asCompany.id,
      name: asCompany.name
    });
    if (window.location.host.substring(0, 9) === LOCAL) {
      Cookies.set(AS_COMPANY, asCompany.id, {
        expires: 365,
        sameSite: 'strict',
        secure: true
      });
    } else if (window.location.host === DEV) {
      Cookies.set(AS_COMPANY, asCompany.id, {
        expires: 365,
        domain: '.' + DEV,
        sameSite: 'strict',
        secure: true
      });
    } else {
      Cookies.set(AS_COMPANY, asCompany.id, {
        expires: 365,
        domain: '.' + PROD,
        sameSite: 'strict',
        secure: true
      });
    }
    this.set({
      asCompany: asCompany
    });
  };
  getAsCompanyId = () => {
    return Cookies.get(AS_COMPANY);
  };
  hasPermission = (...permissions) => {
    return hasPermission(this.state.user, this.state.asCompany?.id, ...permissions);
  };
  hasMultiAccess = () => {
    return this.state.user.companies.length > 1;
  };
  authRedirect = () => {
    toasterService.warning('Session timed out - please sign in.');
    this.clear();
  };
  clear = () => {
    logout().catch().then(() => {
      localStorage.removeItem(TOKEN);
      localStorage.removeItem(TOKEN_EXPIRES);
      if (window.location.host.substring(0, 9) === LOCAL) {
        Cookies.remove(TOKEN);
        Cookies.remove(TOKEN_EXPIRES);
      } else if (window.location.host === DEV) {
        Cookies.remove(TOKEN, {
          domain: '.' + DEV
        });
        Cookies.remove(TOKEN_EXPIRES, {
          domain: '.' + DEV
        });
      } else {
        Cookies.remove(TOKEN, {
          domain: '.' + PROD
        });
        Cookies.remove(TOKEN_EXPIRES, {
          domain: '.' + PROD
        });
      }
      localStorage.removeItem(AS_COMPANY);
      if (window.location.host.substring(0, 9) === LOCAL) {
        Cookies.remove(AS_COMPANY);
      } else if (window.location.host === DEV) {
        Cookies.remove(AS_COMPANY, {
          domain: '.' + DEV
        });
      } else {
        Cookies.remove(AS_COMPANY, {
          domain: '.' + PROD
        });
      }
      this.set({
        user: null,
        asCompany: null,
        validToken: false
      });
      history.push('/sign-in');
    });
  };
}
export const userState = new UserState({
  signedIn: false,
  validToken: false,
  user: null,
  asCompany: null,
  refresh: false,
  // This basically forces the sidebar to reload... TODO figure out a better way to handle this.
  redirect: '/companies' //Default redirect location
});
export const hasPermission = (user, companyId, ...permissions) => {
  if (!user || !user.permissions || !companyId) {
    return false;
  }
  return permissions.some(permission => {
    return !!user.permissions['*'] || user.permissions[permission] && user.permissions[permission].indexOf(companyId) > -1;
  });
};