import React, { Component } from 'react';
import { Scoped } from 'kremling';
import moment from 'moment';
import { Pagination } from '../../components/pagination/pagination.component';
import { PageHeader } from '../../components/page-header/page-header';
import { Button } from '../../components/button/button.component';
import styles from './segments.styles.scss';
import { getSegmentCustomers, getSegment, getSegmentExport } from '../../shared/common.api';
import { Loader } from '../../components/loader/loader.component';
import { DrawerCustomer } from '../../components/drawer/customer';
import utils from '../../shared/utils';
import { userState } from '../../shared/user-state';
import { Modal } from '../../components/modal/modal.component';
import { CustomerDrawerContext } from 'context/customer-drawer-context';
export class Segment extends Component {
  static contextType = CustomerDrawerContext;
  static propTypes = {};
  state = {
    segment: [],
    customers: [],
    is_loading: false,
    error: null,
    count: '',
    search: '',
    currentPage: '',
    paginationParams: {},
    customer: null,
    data: {},
    fields: [{
      id: "first_name",
      name: "First Name",
      type: "text"
    }, {
      id: "last_name",
      name: "Last Name",
      type: "text"
    }, {
      id: "email",
      name: "Email",
      type: "text"
    }, {
      id: "phone",
      name: "Phone",
      type: "text"
    }, {
      id: "first_transaction_when",
      name: "First Visit",
      type: "date"
    }, {
      id: "last_transaction_when",
      name: "Last Visit",
      type: "date"
    }],
    tempFields: null,
    allFields: [{
      id: "id",
      name: "Cinch UUID",
      type: "text"
    }, {
      id: "first_name",
      name: "First Name",
      type: "text"
    }, {
      id: "last_name",
      name: "Last Name",
      type: "text"
    }, {
      id: "phone",
      name: "Phone",
      type: "text"
    }, {
      id: "email",
      name: "Email",
      type: "text"
    }, {
      id: "address1",
      name: "Address",
      type: "text"
    }, {
      id: "address2",
      name: "Address line 2",
      type: "text"
    }, {
      id: "city",
      name: "City",
      type: "text"
    }, {
      id: "state",
      name: "State",
      type: "text"
    }, {
      id: "zip_code",
      name: "Zip",
      type: "text"
    }, {
      id: "job_title",
      name: "Job Title",
      type: "text"
    }, {
      id: "organization",
      name: "Organization",
      type: "text"
    }, {
      id: "created_when",
      name: "Created Date",
      type: "date"
    }, {
      id: "updated_when",
      name: "Updated Date",
      type: "date"
    }, {
      id: "first_transaction_when",
      name: "First Visit",
      type: "date"
    }, {
      id: "last_transaction_when",
      name: "Last Visit",
      type: "date"
    }, {
      id: "stat_last_location__name",
      name: "Last Location Name",
      type: "text"
    }, {
      id: "stat_last_location__entity_ref",
      name: "Last Location Entity Ref",
      type: "text"
    }],
    toAdd: null,
    toRemove: null
  };
  commas = x => {
    let parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  };
  componentDidMount() {
    const segmentFieldsStr = localStorage.getItem('segmentFields');
    if (segmentFieldsStr) {
      this.setState({
        fields: JSON.parse(segmentFieldsStr)
      });
    }
    this.showCustomers();
    this.setState({
      currentPage: 1
    });
  }

  // searchSegment = search => {
  //   this.setState({ isLoading: true });
  //   search === ''
  //     ? this.setState({ showPagination: true })
  //     : this.setState({ showPagination: false });
  //   getSegments({ search }).then(({ data }) => {
  //     if (data)
  //       this.setState({
  //         customers: data.results,
  //         isLoading: false,
  //       });
  //   });
  // };

  searchCustomers = search => {
    this.setState({
      search
    });
    this.showCustomers({});
  };
  showCustomers = () => {
    const {
      search,
      paginationParams
    } = this.state;
    this.setState({
      isLoading: true
    });
    const params = {
      ...paginationParams,
      customer_search: search
    };
    const promises = [getSegmentCustomers(this.props.match.params.id, params), getSegment(this.props.match.params.id)];
    Promise.all(promises).then(([customersData, segmentData]) => {
      if (segmentData.data) {
        this.context.checkCompany(segmentData.data.company).then(() => {
          if (customersData.data) {
            this.setState({
              customers: customersData.data.results,
              isLoading: false,
              data: customersData.data
            });
          }
          this.setState({
            segment: segmentData.data
          });
        }).catch(() => {
          // User chose not to switch companies.
        });
      }
      ;
    });
  };
  onPageChange = paginationParams => {
    this.setState({
      isLoading: true,
      paginationParams
    }, () => {
      this.showCustomers();
    });
  };
  goToCreateSegment() {
    this.props.history.push({
      pathname: '/create-segment'
    });
  }
  isSegmentSelected(id) {
    this.setState({
      isSelected: id,
      openModal: true
    });
  }
  goToEditSegment(id) {
    this.setState({
      isSelected: id
    });
    this.props.history.push({
      pathname: `/segments/${id}/edit`
    });
  }
  goToCopySegment(id) {
    this.setState({
      isSelected: id
    });
    this.props.history.push({
      pathname: `/segments/${id}/copy`
    });
  }
  exportSegment = (id, name) => {
    getSegmentExport(id).then(response => {
      utils.downloadBlob(response.data, utils.convertStringToFilename(name, 'csv'));
    });
  };
  editFields = () => {
    this.setState({
      tempFields: JSON.parse(JSON.stringify(this.state.fields)),
      toAdd: null,
      toRemove: null
    });
  };
  addTemp = () => {
    const {
      toAdd,
      tempFields,
      allFields
    } = this.state;
    if (toAdd && tempFields.length < 6 && !tempFields.find(i => i.id === toAdd)) {
      tempFields.push(allFields.find(i => i.id === toAdd));
      this.setState({
        tempFields
      });
    }
  };
  removeTemp = () => {
    const {
      toRemove,
      tempFields
    } = this.state;
    if (toRemove) {
      const index = tempFields.findIndex(i => i.id === toRemove);
      if (index > -1) {
        tempFields.splice(index, 1);
        this.setState({
          tempFields
        });
      }
    }
  };
  saveFields = () => {
    const {
      tempFields
    } = this.state;
    localStorage.setItem('segmentFields', JSON.stringify(tempFields));
    this.setState({
      fields: tempFields,
      tempFields: null
    });
  };
  render() {
    const {
      segment,
      customers,
      error,
      isLoading,
      data,
      fields,
      tempFields,
      allFields,
      customer
    } = this.state;
    return <Scoped css={styles}>
        <div className="wrapper">
          <PageHeader onSearch={this.searchCustomers} name={segment.name + (data.count ? ` (${this.commas(data.count)})` : '')} actions={<>
                {userState.hasPermission('customer.view_customer') && <Button icon="fa-regular-download" onClick={() => this.exportSegment(segment.id, segment.name)} title="Export Customers" />}
                {userState.hasPermission('customer.change_segment') && <Button icon="fa-regular-edit" onClick={() => this.goToEditSegment(segment.id)} title="Edit Segment" />}
                {userState.hasPermission('customer.add_segment') && <Button icon="fa-regular-clone" onClick={() => this.goToCopySegment(segment.id)} title="Copy Segment" />}
              </>} preactions={<Button onClick={() => this.editFields()}>
                Fields
              </Button>} />
          <div className="segments-list">
            {isLoading && <Loader overlay />}
            {error && <p>{error.message}</p>}

            <table className="table-list">
              {!!customers && <thead>
                  <tr>
                    {fields.map(field => <th key={`header${field.id}`} name={field.id}>{field.name}</th>)}
                  </tr>
                </thead>}
              <tbody>
                {!!customers.length && !isLoading && (customers.map(customer => <tr key={customer.id} onClick={() => this.context.openCustomerDrawer(customer.id)}>
                    {fields.map(field => {
                  return <td key={`field${field.id}`}>{field.type === "date" || field.type === "datetime" ? !!customer[field.id] && moment(customer[field.id]).isValid() ? moment(customer[field.id]).format('YYYY-MM-DD') : `No ${field.name.toLowerCase()}` : field.id === "phone" ? utils.formatPhoneNumber(customer[field.id]) : customer[field.id]}</td>;
                })}
                  </tr>) || <tr>
                    {!isLoading && <td colSpan={8}>
                        <strong>No customers in segment</strong>
                      </td>}
                  </tr>)}
              </tbody>
            </table>
          </div>
          <Pagination data={data} onChange={this.onPageChange} />
        </div>

        <Modal open={!!tempFields} onClose={() => this.setState({
        tempFields: null
      })} allowBackdropClick size="lg">
            <div className="modal__title">Select Field</div>
            <div className="modal__body">
              <p>Choose up to 6 fields to view.</p>

              <div className="d-flex" style={{
            alignItems: 'center'
          }}>
                <div style={{
              flex: '1 1 50%'
            }}>
                  <select style={{
                height: '250px'
              }} size="10" className="form-control" onChange={event => this.setState({
                toAdd: event.target.value
              })}>
                      {allFields.filter(i => tempFields && !tempFields.find(j => j.id === i.id)).map(field => <option key={`option${field.id}`} value={field.id}>{field.name}</option>)}
                  </select>
                </div>
                <div style={{
              flex: '0 0 30px'
            }}>
                  <Button actionType="flat" icon="fa-solid-caret-left" onClick={() => this.removeTemp()} />
                  <Button actionType="flat" icon="fa-solid-caret-right" onClick={() => this.addTemp()} />
                </div>
                <div style={{
              flex: '1 1 50%'
            }}>
                  <select style={{
                height: '250px'
              }} size="10" className="form-control" onChange={event => this.setState({
                toRemove: event.target.value
              })}>
                      {tempFields && tempFields.map(field => <option key={`option${field.id}`} value={field.id}>{field.name}</option>)}
                  </select>
                </div>
              </div>
            </div>
            <div className="modal__actions">
              <Button onClick={() => this.setState({
            tempFields: null
          })}>
                Cancel
              </Button>
              <Button onClick={() => {
            this.saveFields();
          }} actionType="primary">
                Save
              </Button>
            </div>
          </Modal>

        <DrawerCustomer isOpen={!!customer} customer={customer} onClose={() => this.setState({
        customer: null
      })} />
      </Scoped>;
  }
}