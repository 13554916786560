import { Observable, throwError } from 'rxjs';
import axios from 'axios';
import moment from 'moment-timezone';
import { userState } from './user-state';
export const api = axios.create({
  baseURL: API_URL,
  headers: {
    'content-type': 'application/json'
  },
  paramsSerializer: params => {
    const searchParams = new URLSearchParams();
    for (const key of Object.keys(params)) {
      let param = params[key];
      if (Array.isArray(param)) {
        param = param.join(',');
      }
      if (param instanceof Date || param instanceof moment) {
        param = param.toISOString();
      }
      if (param instanceof moment) {
        param = param.toISOString();
      }
      if (param !== undefined && param !== null && param !== '') {
        searchParams.append(key, param);
      }
    }
    return searchParams.toString();
  }
});
export const unwrap = ({
  data
}) => data;
export function buildQueryParams(params) {
  return params ? `?${Object.keys(params).map(key => `${key}=${params[key]}`).join('&')}` : '';
}

/**
 * Fetch, but with observables
 * @param input
 * @param init
 * @returns {Observable}
 */
export function fetcher(input, init = {}) {
  const {
    params = {},
    body = {},
    ...config
  } = init;
  const paramsString = buildParams(params);
  const configBody = buildBody(body);
  return new Observable(observer => {
    const {
      token
    } = userState.getToken();
    fetch(`${API_URL}${input}${paramsString}`, {
      headers: {
        'content-type': 'application/json',
        Authorization: `Token ${token}`
      },
      ...configBody,
      ...config
    }).then(res => res.json(), err => throwError(err)).then(res => observer.next(res)).catch(err => throwError(err)).finally(() => observer.complete());
  });
}
function buildParams(params) {
  const obj = new URLSearchParams();
  Object.keys(params).forEach(key => {
    obj.append(key, params[key]);
  });
  const str = obj.toString();
  return str ? `?${str}` : '';
}
function buildBody(body) {
  return {
    body: JSON.stringify(body)
  };
}