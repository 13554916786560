import React, { Component } from 'react';
import { Application, Container, Graphics, Texture, TilingSprite } from 'pixi.js';
import { Scoped, k, a, m } from 'kremling';
import { debounce } from 'lodash';
import { DateTime } from 'luxon';
import moment from 'moment-timezone';
import { journeyState } from './journey-state';
import { Toolbar } from './toolbar/toolbar.component';
import { NodeModal, DynamicModalDraft, DynamicModalPublished } from './node/node-modal';
import { getJourneyRevisions, patchJourney, getJourneyNodes, patchRevision, getRevisionAnalytics, deleteRevision, duplicateJourney, revertJourney } from '../../shared/common.api';
import { Button } from '../../components/button/button.component';
import { Loader } from '../../components/loader/loader.component';
import { Icon } from '../../components/icon/icon.component';
import { Dropdown } from '../../components/dropdown/dropdown.component';
import { MODES, PROCESS } from './node/node-utils';
import { pixiUtils } from './node/pixi.utils';
import { RepublishModal } from './republish-modal';
import { ModalDialog } from '../../components/modal/modal-dialog.component';
import { toasterService } from '../../components/toaster/toaster-service';
import { Status } from '../../components/status/status.component';
import { Calendar } from '../../components/calendar/calendar.component';
import { MultiSelect } from './multi-select';
import { userState, hasPermission } from '../../shared/user-state';
import Tooltip from 'rc-tooltip';
import { CustomersListModal } from './customers-list-modal.component';
import { WorkingAsContext } from '../../context/working-as-context';
import { allNodes } from './node/node-list';
const Mousetrap = require('mousetrap');
let sx, sy;
const STATUS = {
  PAN: 'PAN',
  POINTER: 'POINTER',
  TEXT: 'TEXT'
};
const insertText = `Insert Text : d`;
const moveCanvas = `Move Canvas : s`;
const selectNodes = `Select/Move Nodes : a`;
const style = {
  modalBackdrop: {
    backgroundColor: 'rgba(53, 64, 82,0.5)',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 899
  }
};
export class CustomerJourneys extends Component {
  static contextType = WorkingAsContext;
  constructor(props) {
    super(props);
    this.state = {
      nodeEdit: null,
      linkEdit: null,
      journeyName: '',
      mode: null,
      status: STATUS.POINTER,
      zoom: 1,
      ready: false,
      invalidNodes: [],
      nodes: [],
      texts: [],
      draftId: null,
      revisionId: '',
      republishModal: false,
      deleteModal: false,
      pauseModal: false,
      duplicateModal: false,
      revertModal: false,
      isLatest: false,
      publishedRevisions: [],
      publishedRevisionId: null,
      cursorClass: 'cursor-default',
      filterType: '30days',
      startDate: null,
      endDate: null,
      calendarModal: null,
      showGrid: false,
      snapToGrid: false,
      isLoading: false,
      triggerInPastModal: null,
      timeTriggerInPast: [],
      customersListNodeId: null,
      duplicateNodeWarningModal: null
    };
    this.ratio = window.devicePixelRatio;
    this.canvasWrapper = React.createRef();
    this.statsIsFetching = React.createRef();
    this.application = new Application({
      antialias: true,
      resolution: this.ratio || 1,
      backgroundColor: 0xffffff,
      forceCanvas: true
    });
    this.movableContainer = new Container();
    const whiteBackground = new Graphics();
    whiteBackground.beginFill(0xffffff);
    const {
      width,
      height
    } = journeyState.state.canvasSize;
    whiteBackground.drawRect(0, 0, width * 2, height * 2);
    this.movableContainer.addChild(whiteBackground);
    this.linksContainer = new Container();
    this.nodesContainer = new Container();
    this.textsContainer = new Container();
    this.movableContainer.addChild(this.linksContainer);
    this.movableContainer.addChild(this.nodesContainer);
    this.movableContainer.addChild(this.textsContainer);
    this.movableContainer.interactive = true;
    this.movableContainer.on('pointerdown', this.onDragStart).on('pointerup', this.onDragEnd).on('pointerupoutside', this.onDragEnd).on('pointermove', this.onDragMove);
    this.multiSelect = new MultiSelect();
    this.movableContainer.addChild(this.multiSelect.container);
    this.application.stage.addChild(this.movableContainer);
    journeyState.set({
      application: this.application,
      textsContainer: this.textsContainer,
      nodesContainer: this.nodesContainer,
      linksContainer: this.linksContainer,
      history: this.props.history
    });
    this.application.ticker.add(function (delta) {
      pixiUtils.update();
    });
    this.modeObserver = journeyState.subscribe(({
      mode,
      process
    }) => {
      let status = STATUS.POINTER;
      let cursorClass = 'cursor-default';
      switch (mode) {
        case MODES.PAN:
          status = STATUS.PAN;
          cursorClass = 'cursor-all-scroll';
          break;
        case MODES.TEXT:
          status = STATUS.TEXT;
          cursorClass = 'cursor-text';
          break;
      }
      this.setState({
        cursorClass,
        status,
        process,
        mode
      });
    }, 'mode', 'process');
    this.customersListObserver = journeyState.subscribe(({
      customersListNodeId
    }) => {
      this.setState({
        customersListNodeId
      });
    });
    this.nodeEditObserver = journeyState.subscribe(({
      nodeEdit,
      linkEdit
    }) => {
      this.setState({
        nodeEdit,
        linkEdit
      });
    }, 'nodeEdit');
    this.validationObserver = journeyState.subscribe(({
      nodes
    }) => {
      this.setState({
        invalidNodes: journeyState.invalidNodes(),
        nodes
      });
    }, 'nodes', 'onParamEdit');
    this.duplicateNodeObserver = journeyState.subscribe(({
      duplicateNodeWarningModal
    }) => {
      this.setState({
        duplicateNodeWarningModal
      });
    });
  }
  componentDidMount() {
    this.build();
    const toDraft = this.props.location.state && this.props.location.state.mode === 'DRAFT';
    this.initJourney(toDraft);
    if (this.props.location.state) {
      journeyState.set({
        process: this.props.location.state.mode
      });
    }
    if (!!this.props.history.location.state) {
      journeyState.set({
        process: 'DRAFT'
      });
    }
    const showGridStr = localStorage.getItem('journeyShowGrid');
    const snapToGridStr = localStorage.getItem('journeySnapToGrid');
    this.setState({
      showGrid: showGridStr ? JSON.parse(showGridStr) : true,
      snapToGrid: snapToGridStr ? JSON.parse(snapToGridStr) : false
    }, () => {
      if (this.state.showGrid) {
        this.handleGrid(this.state.showGrid);
      }
    });
    journeyState.set({
      snapToGrid: snapToGridStr ? JSON.parse(snapToGridStr) : false
    });
    Mousetrap.bind('a', () => !this.state.nodeEdit && journeyState.state.process != PROCESS.PUBLISHED && journeyState.set({
      mode: MODES.CANVAS
    }));
    Mousetrap.bind('s', () => !this.state.nodeEdit && journeyState.set({
      mode: MODES.PAN
    }));
    Mousetrap.bind('d', () => !this.state.nodeEdit && journeyState.state.process != PROCESS.PUBLISHED && journeyState.set({
      mode: MODES.TEXT
    }));
  }
  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.reset();
    }
  }
  componentWillUnmount() {
    clearInterval(this.heartBeat);
    window.removeEventListener('resize', this.resize);
    this.canvasWrapper.current.removeEventListener('mousewheel', this.scroll, false);
    this.nodeEditObserver.unsubscribe();
    this.modeObserver.unsubscribe();
    this.validationObserver.unsubscribe();
    this.customersListObserver.unsubscribe();
    this.duplicateNodeObserver.unsubscribe();
    journeyState.destroy();
    this.application.destroy(false, {
      children: true
    });
    Mousetrap.unbind('a');
    Mousetrap.unbind('s');
    Mousetrap.unbind('d');
  }
  restartAnalyticsHeartBeat = (revisionId, filterType, startDate, endDate) => {
    clearInterval(this.heartBeat);
    this.beat(revisionId, filterType, startDate, endDate);
    this.heartBeat = setInterval(() => this.beat(revisionId, filterType, startDate, endDate), 30000);
  };
  beat = (revisionId, filterType, startDate, endDate) => {
    // Skip because we're still waiting for the last one.
    if (this.statsIsFetching.current) return;
    const params = {};
    if (filterType === '24hours') {
      params['created_after'] = moment().subtract(1, 'days').format('YYYY-MM-DD');
    } else if (filterType === '7days') {
      params['created_after'] = moment().subtract(7, 'days').format('YYYY-MM-DD');
    } else if (filterType === '30days') {
      params['created_after'] = moment().subtract(30, 'days').format('YYYY-MM-DD');
    } else if (filterType === 'custom') {
      if (startDate) {
        params['created_after'] = startDate.format('YYYY-MM-DD');
      }
      if (endDate) {
        params['created_before'] = moment(endDate).add(1, 'days').format('YYYY-MM-DD');
      }
    }
    this.statsIsFetching.current = true;
    return getRevisionAnalytics(revisionId, params).then(({
      data
    }) => {
      journeyState.setAnalytics(data);
    }).catch(() => {
      // Ignore timeout error
    }).then(() => {
      // All done fetching so release lock.
      this.statsIsFetching.current = false;
    });
  };
  initJourney = toDraft => {
    return getJourneyRevisions(this.props.match.params.id, {
      ordering: '-published_when',
      deleted: 'all',
      limit: 100
    }).then(({
      data
    }) => {
      if (data.results && data.results.length) {
        // check for different company link
        return this.context.checkCompany(data.results[0].company).then(() => data);
      }
      return data;
    }).then(data => {
      if (data.results.length > 1) {
        journeyState.set({
          process: toDraft ? PROCESS.DRAFT : journeyState.state.process ? journeyState.state.process : PROCESS.PUBLISHED,
          mode: toDraft && userState.hasPermission('journey.change_journey') ? MODES.CANVAS : MODES.PAN
        });
        this.setState({
          draftId: data.results[0].id,
          paused: !!data.results[1].paused_when,
          publishedRevisions: data.results.slice(1),
          publishedRevisionId: data.results[1].id,
          isLatest: true
        });
        return getJourneyNodes(data.results[toDraft ? 0 : 1].id);
      }
      journeyState.set({
        mode: MODES.CANVAS,
        process: PROCESS.DRAFT
      });
      return getJourneyNodes(data.results[0].id);
    }).then(this.getNodesResults);
  };
  reset = toDraft => {
    clearInterval(this.heartBeat);
    this.setState({
      draftId: null
    });
    this.revision = null;
    journeyState.reset();
    this.initJourney(toDraft);
  };
  selectRevision = revisionId => {
    clearInterval(this.heartBeat);
    this.setState({
      ready: false,
      isLatest: this.state.publishedRevisions.findIndex(revision => revision.id === revisionId) === 0
    });
    journeyState.reset();
    journeyState.set({
      mode: MODES.PAN,
      process: PROCESS.PUBLISHED
    });
    getJourneyNodes(revisionId).then(this.getNodesResults);
  };
  getNodesResults = ({
    data
  }) => {
    if (data.journey) {
      // make sure all nodes on this revision are available. If they're not this is a UI2.0 journey only.
      const hasMissingNode = data.nodes.some(n => !allNodes.find(n2 => n2.subType === n.name));
      if (hasMissingNode) {
        toasterService.error('This journey is incompatible with this old UI.  You will be redirected to the beta site now.');
        setTimeout(() => {
          window.location.href = `https://beta.${window.location.host}/customer-journeys/${this.props.match.params.id}`;
        }, 3000);
      } else {
        this.revision = data;
        this.setState({
          journeyName: data.journey_name,
          journeyStatus: data.journey_status,
          revisionId: data.id,
          ready: true
        });
        journeyState.set({
          revisionId: data.id
        });
        if (this.revision.meta && this.revision.meta.texts) {
          journeyState.populateTexts(this.revision.meta.texts);
        }
        journeyState.populate(data.nodes);
        if (journeyState.state.process === PROCESS.PUBLISHED) {
          this.restartAnalyticsHeartBeat(journeyState.state.revisionId, this.state.filterType, this.state.startDate, this.state.endDate);
        }
      }
    }
  };
  editDraft = () => {
    clearInterval(this.heartBeat);
    this.setState({
      ready: false
    });
    journeyState.reset();
    journeyState.set({
      mode: userState.hasPermission('journey.change_journey') ? MODES.CANVAS : MODES.PAN,
      process: PROCESS.DRAFT
    });
    return getJourneyNodes(this.state.draftId).then(this.getNodesResults);
  };
  build = () => {
    this.canvas = this.canvasWrapper.current.appendChild(this.application.view);
    this.canvas.style.transformOrigin = '0 0';
    this.canvas.style.transform = `scale(${1 / this.ratio})`;
    this.canvas.ondragover = e => e.preventDefault();
    this.canvas.ondrop = this.handleDrop;
    this.canvas.oncontextmenu = function (e) {
      e.preventDefault();
    };
    window.addEventListener('resize', this.resize);
    this.canvasWrapper.current.addEventListener('mousewheel', this.scroll, false);
    this.resize();
  };
  onDragStart = event => {
    if (journeyState.state.mode === MODES.PAN) {
      this.movableContainer.dragging = true;
      this.data = event.data;
      sx = this.data.getLocalPosition(this.movableContainer).x * this.movableContainer.scale.x;
      sy = this.data.getLocalPosition(this.movableContainer).y * this.movableContainer.scale.y;
    }
    if (!userState.hasPermission('journey.change_journey')) {
      return;
    }
    if (journeyState.state.mode === MODES.CANVAS && this.multiSelect.active && event.target !== this.multiSelect.container) {
      this.multiSelect.stop();
    }
    if (journeyState.state.mode === MODES.CANVAS && event.target === this.movableContainer) {
      this.data = event.data;
      sx = this.data.getLocalPosition(this.movableContainer).x;
      sy = this.data.getLocalPosition(this.movableContainer).y;
      this.multiSelect.start(sx, sy);
    }
  };
  onDragMove = () => {
    if (this.movableContainer.dragging) {
      this.movableContainer.hasDragged = true;
      const newPosition = this.data.getLocalPosition(this.movableContainer.parent);
      const x = newPosition.x - sx;
      const y = newPosition.y - sy;
      this.movableContainer.x = x < 0 ? Math.round(x) : 0;
      this.movableContainer.y = y < 0 ? Math.round(y) : 0;
    }
    if (this.multiSelect.sizing) {
      const newPosition = this.data.getLocalPosition(this.movableContainer);
      this.multiSelect.setSize(newPosition.x - sx, newPosition.y - sy);
    }
  };
  onDragEnd = event => {
    if (this.movableContainer.dragging) {
      this.movableContainer.dragging = false;
    }
    if (this.multiSelect.active) {
      this.multiSelect.onDragEnd(event);
    }
    if (this.state.mode === MODES.TEXT && event.target === this.movableContainer) {
      journeyState.addText('', event.data.getLocalPosition(this.movableContainer), true);
    }
  };
  resize = () => {
    this.application.renderer.resize(this.canvasWrapper.current.clientWidth, this.canvasWrapper.current.clientHeight);
    this.canvas.style.width = `${this.canvasWrapper.current.clientWidth * this.ratio}px`;
    this.canvas.style.height = `${this.canvasWrapper.current.clientHeight * this.ratio}px`;
  };
  volume = scale => {
    switch (scale) {
      case 1:
        return 1;
      case 0.9:
        return 1.111;
      case 0.8:
        return 1.25;
      case 0.7:
        return 1.428;
      case 0.6:
        return 1.665;
      case 0.5:
        return 2;
    }
  };
  handleDrop = e => {
    if (e.preventDefault) {
      e.preventDefault();
    }
    if (e.stopPropagation) {
      e.stopPropagation();
    }
    if (journeyState.state.mode === MODES.CANVAS) {
      const sidebar = document.querySelector('.root > .sidebar').getBoundingClientRect();
      if (sidebar) {
        const widthOffset = 62;
        const volumeX = this.volume(this.movableContainer.scale.x);
        const volumeY = this.volume(this.movableContainer.scale.y);
        const position = {
          x: (e.x - this.movableContainer.x - sidebar.width) * volumeX - widthOffset,
          y: (e.y - this.movableContainer.y) * volumeY - widthOffset
        };
        if (this.state.snapToGrid) {
          position.x = Math.round(position.x / 25) * 25;
          position.y = Math.round(position.y / 25) * 25;
        }
        journeyState.addNode(e.dataTransfer.getData('text/plain'), position);
      }
    }
  };
  scroll = e => {
    if (this.state.nodeEdit) return;
    const {
      zoom
    } = this.state;
    const {
      width,
      height
    } = journeyState.state.canvasSize;
    const x = Math.round(this.movableContainer.x - e.deltaX / 3);
    const y = Math.round(this.movableContainer.y - e.deltaY / 3);
    const scaledWidth = -(width * zoom);
    const scaledHeight = -(height * zoom);
    if (x > 0) {
      this.movableContainer.x = 0;
    } else if (x < scaledWidth) {
      this.movableContainer.x = scaledWidth;
    } else {
      this.movableContainer.x = x;
    }
    if (y > 0) {
      this.movableContainer.y = 0;
    } else if (y < scaledHeight) {
      this.movableContainer.y = scaledHeight;
    } else {
      this.movableContainer.y = y;
    }
  };
  scrollToNode = container => {
    const canvasWidth = this.canvasWrapper.current.clientWidth;
    const canvasHeight = this.canvasWrapper.current.clientHeight;
    if (this.movableContainer.position.x + container.position.x - 60 < 0) {
      const changeTo = -container.position.x + 60;
      this.movableContainer.x = changeTo > 0 ? 0 : changeTo;
    } else if (this.movableContainer.position.x + container.position.x > canvasWidth - 124 - 60) {
      this.movableContainer.x = -(container.position.x - canvasWidth + 124 + 60);
    }
    if (this.movableContainer.position.y + container.position.y - 100 < 0) {
      const changeTo = -container.position.y + 100;
      this.movableContainer.y = changeTo > 0 ? 0 : changeTo;
    } else if (this.movableContainer.position.y + container.position.y > canvasHeight - 124 - 60) {
      this.movableContainer.y = -(container.position.y - canvasHeight + 124 + 60);
    }
    pixiUtils.shake(container, 5);
  };
  updateName = e => {
    this.setState({
      journeyName: e.target.value === '' ? 'Untitled' : e.target.value
    });
    this.updateNameDebounced();
  };
  patchJourney = () => {
    patchJourney(this.revision.journey, {
      name: this.state.journeyName
    });
  };
  updateNameDebounced = debounce(this.patchJourney.bind(this), 700);
  handlePublishJourney = () => {
    const timeTriggerInPast = this.state.nodes.filter(node => {
      if (node.name === 'Time Trigger') {
        const {
          datetime
        } = node.parameters;
        if (DateTime.fromISO(datetime).toUTC().toISO() < DateTime.local().toUTC().toISO()) {
          return node;
        }
      }
    });
    if (timeTriggerInPast.length === 0) {
      this.publishJourney();
    } else {
      this.setState({
        triggerInPastModal: true,
        timeTriggerInPast
      });
    }
  };
  publishJourney = () => {
    /* if (!userState.state.asCompany.is_billed_externally && (!userState.state.asCompany.subscription_status || ['paused', 'cancelled', 'future'].indexOf(userState.state.asCompany.subscription_status) > -1)) {
      toasterService.error('Billing must be set up before you can publish a journey');
      return;
    } */

    this.setState({
      isLoading: true
    });
    return patchRevision(this.revision.id, {
      published_when: DateTime.local().toUTC().toISO()
    }).then(() => {
      toasterService.success('Draft successfully published');
      this.setState({
        isLoading: false
      });
      this.goToPublishedVersion();
    }).catch(err => {
      toasterService.error(err.response.data.non_field_errors[0]);
    });
  };
  delete = () => {
    deleteRevision(this.state.revisionId).then(() => {
      const revision = this.state.publishedRevisions.find(revision => revision.id === this.state.revisionId);
      revision.deleted_when = DateTime.local().toUTC().toISO();
      this.setState({
        publishedRevisions: this.state.publishedRevisions
      });
    });
  };
  endRevision = option => {
    if (this.state.publishedRevisionId) {
      this.setState({
        publishLoading: true
      });
      if (option === 'close') {
        return patchRevision(this.state.publishedRevisionId, {
          closed_when: DateTime.local().toUTC().toISO()
        });
      } else if (option === 'stop') {
        return deleteRevision(this.state.publishedRevisionId);
      }
    }
    return Promise.resolve();
  };
  togglePauseModal = pauseModal => {
    this.setState({
      pauseModal
    });
  };
  togglePauseRevision = paused => {
    /* if (!paused && !userState.state.asCompany.is_billed_externally && (!userState.state.asCompany.subscription_status || ['paused', 'cancelled', 'future'].indexOf(userState.state.asCompany.subscription_status) > -1)) {
      toasterService.error('Billing must be set up before you can unpause a journey');
      return;
    } */

    const paused_when = paused ? DateTime.local().toUTC().toISO() : null;
    patchRevision(this.state.revisionId, {
      paused_when
    }).then(() => {
      const revision = this.state.publishedRevisions.find(revision => revision.id === this.state.revisionId);
      revision.paused_when = paused_when;
      toasterService.success(paused ? 'Revision successfully paused' : 'Revision successfully unpaused');
      this.setState({
        paused,
        pauseModal: false
      });
    }).catch(err => {
      this.toggleRepublishModal();
      toasterService.error(err.response.data.non_field_errors[0]);
    });
  };
  goToPublishedVersion = () => {
    journeyState.reset();
    this.initJourney();
  };
  changeStatus = (mode, event) => {
    journeyState.set({
      mode
    });
  };
  zoomOut = () => {
    const {
      x
    } = this.movableContainer.scale;
    let zoom = Math.round((x - 0.1) * 10) / 10;
    zoom = zoom < 0.5 ? 0.5 : zoom;
    this.movableContainer.scale = {
      x: zoom,
      y: zoom
    };
    this.setState({
      zoom
    });
  };
  zoomIn = () => {
    const {
      x
    } = this.movableContainer.scale;
    let zoom = Math.round((x + 0.1) * 10) / 10;
    zoom = zoom > 1 ? 1 : zoom;
    this.movableContainer.scale = {
      x: zoom,
      y: zoom
    };
    this.setState({
      zoom
    });
  };
  toggleRepublishModal = republishModal => {
    /* if (!userState.state.asCompany.is_billed_externally && (!userState.state.asCompany.subscription_status || ['paused', 'cancelled', 'future'].indexOf(userState.state.asCompany.subscription_status) > -1)) {
      toasterService.error('Billing must be set up before you can publish a journey');
      return;
    } */

    this.setState({
      republishModal
    });
  };
  toggleDeleteModal = deleteModal => {
    this.setState({
      deleteModal
    });
  };
  toggleDuplicateModal = duplicateModal => {
    this.setState({
      duplicateModal
    });
  };
  duplicateJourney = () => {
    duplicateJourney(this.revision.journey).then(res => {
      if (res.data.id && res.data.id) {
        this.props.history.push(`/customer-journeys/${res.data.id}`);
      }
    });
    this.toggleDuplicateModal(false);
  };
  toggleRevertModal = revertModal => {
    this.setState({
      revertModal
    });
  };
  revertRevision = () => {
    revertJourney(this.revision.journey).then(() => {
      this.toggleRevertModal(false);
      this.reset(true);
    });
  };
  getStatus = (revisionId, publishedRevisions) => {
    const revision = publishedRevisions.find(revision => revision.id === revisionId);
    if (!revision) {
      return 'draft';
    }
    if (revision.deleted_when) {
      return 'stopped';
    } else if (revision.closed_when) {
      return 'closed';
    } else if (revision.paused_when) {
      return 'paused';
    } else if (revision.published_when) {
      return 'published';
    }
    return 'draft';
  };
  changeFilter(filterType) {
    this.setState({
      filterType
    }, () => {
      this.restartAnalyticsHeartBeat(journeyState.state.revisionId, this.state.filterType, this.state.startDate, this.state.endDate);
    });
  }
  changeDate(date) {
    this.setState({
      [this.state.calendarModal]: moment(date),
      calendarModal: null
    }, () => {
      this.restartAnalyticsHeartBeat(journeyState.state.revisionId, this.state.filterType, this.state.startDate, this.state.endDate);
    });
  }
  handleGrid(showGrid) {
    if (showGrid) {
      const canvas = document.createElement('canvas');
      const {
        width,
        height
      } = journeyState.state.canvasSize;
      canvas.width = 25;
      canvas.height = 25;
      const context = canvas.getContext('2d');
      context.beginPath();
      context.moveTo(25, 0);
      context.lineTo(0, 0);
      context.lineTo(0, 25);
      context.lineWidth = 1;
      context.strokeStyle = '#d3d3d3';
      context.stroke();
      const tileTexture = Texture.from(canvas);
      const background = new TilingSprite(tileTexture, width * 4, height * 4);
      this.application.stage.children[0].children[0].addChild(background);
    }
    if (!showGrid && this.application.stage.children[0].children[0].children[0]) {
      this.application.stage.children[0].children[0].removeChildren();
    }
  }
  render() {
    const {
      journeyName,
      journeyStatus,
      status,
      mode,
      zoom,
      ready,
      paused,
      process,
      invalidNodes,
      nodes,
      draftId,
      republishModal,
      deleteModal,
      pauseModal,
      duplicateModal,
      revertModal,
      publishedRevisions,
      revisionId,
      isLatest,
      cursorClass,
      filterType,
      startDate,
      endDate,
      calendarModal,
      snapToGrid,
      showGrid,
      isLoading,
      triggerInPastModal,
      timeTriggerInPast,
      customersListNodeId
    } = this.state;
    return <Scoped css={css}>
        <div className="wrapper-contain">
          {isLoading && <div style={style.modalBackdrop} />}
          <div className={cursorClass + ' customer-journeys'}>
            <div className="canvas-wrapper" ref={this.canvasWrapper}>
              {mode !== MODES.EDIT_PARAM && <>
                  <div className="journey-top-left">
                    {ready && process === PROCESS.DRAFT && userState.hasPermission('journey.change_journey') ? <input value={journeyName === 'Untitled' ? '' : journeyName} onChange={this.updateName} type="text" className="form-control" placeholder="Untitled" autoFocus={journeyName === 'Untitled'} /> : <div className="journey_overlay__name">{journeyName}</div>}
                    {ready && <div className="toggler">
                        <Button onClick={() => this.props.history.push(`/customer-journeys/details/${this.props.match.params.id}`)}>
                          Detail View
                        </Button>
                        <Button onClick={this.editDraft} className={a('success').m('active', process === PROCESS.DRAFT)}>
                          Draft View
                        </Button>
                        {draftId && <>
                            <Button onClick={this.goToPublishedVersion} className={m('active', process === PROCESS.PUBLISHED)}>
                              Published View
                            </Button>
                            {process === PROCESS.PUBLISHED && <select className="form-control inline ml-3" value={revisionId} onChange={e => this.selectRevision(e.target.value)}>
                                {publishedRevisions.map((revision, index, original) => <option value={revision.id} key={revision.id}>
                                      Revision #{original.length - index}{' '}
                                      Published:{' '}
                                      {moment(revision.published_when).format('MMM D, YYYY')}
                                    </option>)}
                              </select>}
                          </>}
                      </div>}
                  </div>
                  <div className="journey-top-right">
                    {journeyStatus ? <Status status={journeyStatus} /> : <>
                        {process === PROCESS.PUBLISHED && <Status status={this.getStatus(revisionId, publishedRevisions)} />}
                        {!!invalidNodes.length && <Dropdown horizontal="west" size="md" cover trigger={() => <Button small circle square actionType="warning">
                                {invalidNodes.length}
                              </Button>} content={() => <div className="p-md">
                                <h4>Invalid Nodes</h4>
                                <div className="validate-node">
                                  {this.state.invalidNodes.map(node => <div key={node.id} className="validate-node__item" onClick={() => this.scrollToNode(node.container)}>
                                      {node.name}
                                      <Icon size={14} name="fa-regular-arrow-circle-right" />
                                    </div>)}
                                </div>
                              </div>} />}
                        {process === PROCESS.DRAFT && !!nodes.length && userState.hasPermission('journey.change_journey') && <>
                              {draftId && <Button icon="fa-regular-undo" onClick={() => this.toggleRevertModal(true)} />}
                              <Button actionType="primary" onClick={draftId ? () => this.toggleRepublishModal(true) : this.handlePublishJourney} disabled={!!invalidNodes.length}>
                                {draftId ? 'Publish Journey Draft' : isLoading ? <Loader size="sm" /> : 'Publish Journey'}
                              </Button>
                            </>}
                        {process === PROCESS.PUBLISHED && <>
                            <Dropdown allowContentClicks={true} horizontal="west" trigger={() => <Button icon="fa-regular-filter" />} content={({
                      close
                    }) => <div className="p-2">
                                  <label>Filter Statistics</label>
                                  <select className="form-control" value={filterType} onChange={e => {
                        this.changeFilter(e.target.value);
                        if (e.target.value !== 'custom') {
                          close();
                        }
                      }}>
                                    <option value="all">All Time</option>
                                    <option value="24hours">
                                      Last 24 Hours
                                    </option>
                                    <option value="7days">Last 7 Days</option>
                                    <option value="30days">Last 30 Days</option>
                                    <option value="custom">Custom Range</option>
                                  </select>

                                  {filterType === 'custom' && <>
                                      <hr />
                                      <label>Start Date</label>
                                      <Button block onClick={() => this.setState({
                          calendarModal: 'startDate'
                        })}>
                                        {startDate ? startDate.format('LL') : 'Select Date'}
                                      </Button>
                                      <label>End Date</label>
                                      <Button block onClick={() => this.setState({
                          calendarModal: 'endDate'
                        })}>
                                        {endDate ? endDate.format('LL') : 'Select Date'}
                                      </Button>
                                    </>}
                                </div>} />
                          </>}
                        {process === PROCESS.PUBLISHED && isLatest && userState.hasPermission('journey.add_journey', 'journey.change_journey') && <>
                              <Dropdown horizontal="west" trigger={() => <Button icon="fa-regular-cog" />} content={() => <ul className="select-list">
                                    {userState.hasPermission('journey.add_journey') && <li>
                                          <a onClick={() => this.toggleDuplicateModal(true)}>
                                            Duplicate Journey
                                          </a>
                                        </li>}
                                    {userState.hasPermission('journey.change_journey') && <>
                                          <li>
                                            <a onClick={() => {
                            paused ? this.togglePauseRevision(false) : this.togglePauseModal(true);
                          }}>
                                              {paused ? 'Unpause' : 'Pause'}{' '}
                                              Journey
                                            </a>
                                          </li>
                                          <li className="divider" />
                                          <li>
                                            <a onClick={() => this.toggleDeleteModal(true)}>
                                              End Journey
                                            </a>
                                          </li>
                                        </>}
                                  </ul>} />
                            </>}
                        {process === PROCESS.PUBLISHED && !isLatest && this.getStatus(revisionId, publishedRevisions) !== 'stopped' && <>
                              <Dropdown horizontal="west" trigger={() => <Button icon="fa-regular-cog" />} content={() => <ul className="select-list">
                                    <li>
                                      <a onClick={() => this.toggleDeleteModal(true)}>
                                        End Journey
                                      </a>
                                    </li>
                                  </ul>} />
                            </>}
                      </>}
                  </div>
                  <div className="journey-bottom-left">
                    {ready && process === PROCESS.DRAFT && userState.hasPermission('journey.change_journey') && <Tooltip placement="top" overlay={selectNodes}>
                          <Button icon="fa-solid-mouse-pointer" actionType={status === STATUS.POINTER ? 'primary' : null} onClick={event => this.changeStatus(MODES.CANVAS, event)} />
                        </Tooltip>}
                    <Tooltip placement="top" overlay={moveCanvas}>
                      <Button icon="fa-regular-arrows" actionType={status === STATUS.PAN ? 'primary' : null} onClick={event => this.changeStatus(MODES.PAN, event)} />
                    </Tooltip>
                    {ready && process === PROCESS.DRAFT && userState.hasPermission('journey.change_journey') && <Tooltip placement="top" overlay={insertText}>
                          <Button icon="fa-regular-text" actionType={status === STATUS.TEXT ? 'primary' : null} onClick={event => this.changeStatus(MODES.TEXT, event)} />
                        </Tooltip>}
                  </div>
                  <div className="journey-bottom-right">
                    <div className="d-flex mr-4 mt-2">
                      <div className="checkbox-label mb-0 ">
                        <label className="form-check-label" style={{
                      backgroundColor: '#fff',
                      border: '1px #d3d3d3 solid',
                      borderRadius: '4px',
                      padding: '4px 4px 2px 6px'
                    }}>
                          <input className="form-check-input" style={{
                        position: 'relative',
                        top: '1px',
                        transform: 'scale(1.25)'
                      }} name="showGrid" type="checkbox" checked={showGrid} value={showGrid} onChange={() => {
                        this.handleGrid(!showGrid);
                        localStorage.setItem('journeyShowGrid', !showGrid);
                        this.setState({
                          showGrid: !showGrid
                        });
                      }} />
                          <span style={{
                        fontWeight: '600',
                        fontSize: '15px'
                      }} className="m-2">
                            Show Grid
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className="d-flex mr-4 mt-2">
                      <div className="checkbox-label mb-0 ">
                        <label className="form-check-label" style={{
                      backgroundColor: '#fff',
                      border: '1px #d3d3d3 solid',
                      borderRadius: '4px',
                      padding: '4px 4px 2px 6px'
                    }}>
                          <input className="form-check-input mr-1" style={{
                        position: 'relative',
                        top: '1px',
                        transform: 'scale(1.25)'
                      }} name="snapToGrid" type="checkbox" checked={snapToGrid} value={snapToGrid} onChange={() => {
                        localStorage.setItem('journeySnapToGrid', !journeyState.state.snapToGrid);
                        journeyState.set({
                          snapToGrid: !journeyState.state.snapToGrid
                        });
                        this.setState({
                          snapToGrid: !snapToGrid
                        });
                      }} />
                          <span style={{
                        fontWeight: '600',
                        fontSize: '15px'
                      }} className="m-2">
                            Snap to Grid
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className="control">
                      <Button onClick={this.zoomOut} icon="fa-regular-minus" small disabled={zoom === 0.5} />
                    </div>
                    <div className="count">{zoom * 100}%</div>
                    <div className="control">
                      <Button onClick={this.zoomIn} icon="fa-regular-plus" small disabled={zoom === 1} />
                    </div>
                  </div>
                </>}
            </div>
            {ready && process === PROCESS.DRAFT && userState.hasPermission('journey.change_journey') && <Toolbar />}
            {!!this.state.nodeEdit && (!this.state.nodeEdit.componentParams || !!this.state.linkEdit) && (
          /**
           * Old Node Modal
           * This is used for nodes that are not using the new `componentParams` and are still using the old `component`.
           * Also this is used for editing node links... Node links should probably be moved to its own component
           */
          <NodeModal readOnly={process === PROCESS.PUBLISHED} node={this.state.nodeEdit} linkEdit={this.state.linkEdit} stageX={this.movableContainer.x} stageY={this.movableContainer.y} scale={zoom} />)}
            {!!this.state.nodeEdit && !!this.state.nodeEdit.componentParams && !this.state.linkEdit && process === PROCESS.PUBLISHED && (
          /**
           * New Dynamic Node Modal
           * This modal automatically generates the modal based on the configuration.
           * This is the published version
           */
          <DynamicModalPublished node={this.state.nodeEdit} stageX={this.movableContainer.x} stageY={this.movableContainer.y} scale={zoom} />)}
            {!!this.state.nodeEdit && !!this.state.nodeEdit.componentParams && !this.state.linkEdit && process !== PROCESS.PUBLISHED && (
          /**
           * New Dynamic Node Modal
           * This modal automatically generates the modal based on the configuration.
           * This is the draft version
           */
          <DynamicModalDraft node={this.state.nodeEdit} stageX={this.movableContainer.x} stageY={this.movableContainer.y} scale={zoom} />)}
          </div>
          <RepublishModal isOpen={republishModal} onClose={() => this.toggleRepublishModal(false)} endRevision={this.endRevision} publishJourney={this.handlePublishJourney} />
          <ModalDialog open={deleteModal} onClose={() => this.toggleDeleteModal(false)} allowBackdropClick onSubmit={this.delete} title="End Journey" submitText="End Journey">
            <p>
              Are you sure you want to end this journey? All customers in it
              will stop immediately and no new customers will enter it.
            </p>
            <strong>This cannot be undone!</strong>
          </ModalDialog>

          <ModalDialog open={pauseModal} onClose={() => this.togglePauseModal(false)} allowBackdropClick onSubmit={() => this.togglePauseRevision(true)} title="Pause Journey" submitText="Pause">
            Are you sure you want to pause the journey?
          </ModalDialog>

          <ModalDialog open={duplicateModal} onClose={() => this.toggleDuplicateModal(false)} allowBackdropClick onSubmit={this.duplicateJourney} title="Duplicate Journey" submitText="Duplicate">
            Are you sure you want to duplicate this journey?
          </ModalDialog>

          <ModalDialog open={revertModal} onClose={() => this.toggleRevertModal(false)} allowBackdropClick onSubmit={this.revertRevision} title="Revert Draft Changes" submitText="Revert">
            This will revert your draft changes back to the published version.
            <br />
            <br />
            Are you sure you want to revert?
          </ModalDialog>

          <ModalDialog open={!!calendarModal} onClose={() => this.setState({
          calendarModal: null
        })} allowBackdropClick size="sm" title="Select Date">
            <Calendar minDate={null} maxDate={new Date()} value={this.state[calendarModal] ? this.state[calendarModal].toDate() : null} onChange={date => this.changeDate(date)} />
          </ModalDialog>

          <ModalDialog open={!!triggerInPastModal} onClose={() => this.setState({
          triggerInPastModal: null
        })} allowBackdropClick onSubmit={this.publishJourney} title="Publish Journey" submitText="Publish">
            WARNING: You have a Time Trigger that is scheduled for a time that
            has already passed. The time trigger is scheduled for:
            <br />
            <br />
            {timeTriggerInPast.map((node, index) => <p key={index} style={{
            marginBottom: '0px',
            marginLeft: '8px'
          }}>
                <b>{moment(node.parameters.datetime).format('MMM D, YYYY h:mm A')}</b>
              </p>)}
            <br />
            Are you sure you would like to publish this journey?`
            <br />
          </ModalDialog>

          <ModalDialog open={!!this.state.duplicateNodeWarningModal} onClose={() => {
          this.setState({
            duplicateNodeWarningModal: null
          });
          journeyState.state.duplicateNodeWarningModal = null;
        }} allowBackdropClick onSubmit={() => {
          this.setState({
            duplicateNodeWarningModal: null
          });
          journeyState.state.duplicateNodeWarningModal = null;
        }} cancelBtn={false} title="Duplicate Link Warning" submitText="Acknowledge">
            You have used the same path twice out of this node. If this was intentional, you can ignore this warning.
          </ModalDialog>

          <CustomersListModal nodeId={customersListNodeId} />
        </div>
      </Scoped>;
  }
}
const css = {
  styles: `[kremling="i14"] body,body[kremling="i14"] {
  --color-primary: #487aae;
  --color-highlight: #e7efff;
  --color-accent: #354052;
  --color-success: #73b56e;
  --color-warning: #f4b707;
  --color-danger: #df5651;
  --color-grey-10: #f9f9f9;
  --color-grey-25: #f3f3f3;
  --color-grey-50: #e9e9e9;
  --color-grey-75: #e3e3e3;
  --color-grey-100: #d3d3d3;
  --color-grey-200: #c3c3c3;
  --color-grey-300: #b3b3b3;
  --color-grey-400: #a3a3a3;
  --color-grey-500: #808080;
  --color-grey-600: #707070;
  --color-grey-700: #606060;
  --color-grey-800: #505050;
  --color-grey-900: #404040;
  --base-font-family: Roboto, Helvetica, Arial, sans-serif;
  --base-font-size: 1.4rem;
  --base-font-color: #404040;
  --base-font-weight: 400;
  --base-line-height: 1.4;
  --box-shadow-1: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.12);
  --box-shadow-2: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-3: 0 10px 20px rgba(0, 0, 0, 0.17), 0 6px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-4: 0 14px 28px rgba(0, 0, 0, 0.13), 0 10px 10px rgba(0, 0, 0, 0.1);
  --box-shadow-5: 0 19px 38px rgba(0, 0, 0, 0.18), 0 15px 12px rgba(0, 0, 0, 0.1);
  --base-border-radius: 0.4rem;
}

[kremling="i14"] .customer-journeys,[kremling="i14"].customer-journeys {
  display: flex;
  height: 100%;
}

[kremling="i14"] .cursor-default,[kremling="i14"].cursor-default {
  cursor: default;
}

[kremling="i14"] .cursor-all-scroll,[kremling="i14"].cursor-all-scroll {
  cursor: all-scroll;
}

[kremling="i14"] .cursor-text,[kremling="i14"].cursor-text {
  cursor: text;
}

[kremling="i14"] .canvas-wrapper,[kremling="i14"].canvas-wrapper {
  position: relative;
  overflow: hidden;
  flex-grow: 1;
}

[kremling="i14"] .journey-top-left,[kremling="i14"].journey-top-left {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  padding: 1rem 0 0 1rem;
  display: flex;
  align-items: center;
}
[kremling="i14"] .journey-top-left input,[kremling="i14"].journey-top-left input {
  width: 240px;
  margin-right: 1rem;
}

[kremling="i14"] .journey-overlay__right button,[kremling="i14"].journey-overlay__right button {
  margin-left: 1rem;
}

[kremling="i14"] .journey_overlay__name,[kremling="i14"].journey_overlay__name {
  background-color: #fff;
  border-radius: 0.4rem;
  padding: 0 0.8rem;
  height: 3rem;
  line-height: 3rem;
  font-weight: 500;
  font-size: 1.6rem;
  margin-right: 1rem;
}

[kremling="i14"] .journey-bottom-left,[kremling="i14"].journey-bottom-left {
  position: absolute;
  padding: 0 1rem 1rem 1rem;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  user-select: none;
}
[kremling="i14"] .journey-bottom-left>button,[kremling="i14"].journey-bottom-left>button {
  margin-right: 1rem;
}

[kremling="i14"] .journey-top-right,[kremling="i14"].journey-top-right {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1rem;
  z-index: 1;
  display: flex;
  align-items: center;
}
[kremling="i14"] .journey-top-right>button,[kremling="i14"] .journey-top-right .dropdown,[kremling="i14"].journey-top-right>button,[kremling="i14"].journey-top-right .dropdown {
  margin-left: 1rem;
}
[kremling="i14"] .journey-top-right .validate-node,[kremling="i14"].journey-top-right .validate-node {
  position: relative;
}
[kremling="i14"] .journey-top-right .validate-node .validate-node__item,[kremling="i14"].journey-top-right .validate-node .validate-node__item {
  border: solid 1px #e9e9e9;
  padding: 0.4rem 0.8rem;
  margin-top: -0.1rem;
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  transition: background-color 200ms ease;
}
[kremling="i14"] .journey-top-right .validate-node .validate-node__item .icon,[kremling="i14"].journey-top-right .validate-node .validate-node__item .icon {
  fill: #808080;
  opacity: 0;
  transition: opacity 200ms ease;
}
[kremling="i14"] .journey-top-right .validate-node .validate-node__item:hover,[kremling="i14"].journey-top-right .validate-node .validate-node__item:hover {
  border-color: #d3d3d3;
  background-color: #f3f3f3;
  z-index: 1;
}
[kremling="i14"] .journey-top-right .validate-node .validate-node__item:hover .icon,[kremling="i14"].journey-top-right .validate-node .validate-node__item:hover .icon {
  opacity: 1;
}
[kremling="i14"] .journey-top-right .validate-node>.validate-node__item:first-child,[kremling="i14"].journey-top-right .validate-node>.validate-node__item:first-child {
  border-top-left-radius: 0.4rem;
  border-top-right-radius: 0.4rem;
}
[kremling="i14"] .journey-top-right .validate-node>.validate-node__item:last-child,[kremling="i14"].journey-top-right .validate-node>.validate-node__item:last-child {
  border-bottom-left-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
}

[kremling="i14"] .journey-top-right__toggle,[kremling="i14"].journey-top-right__toggle {
  margin-right: 1rem;
  position: relative;
}
[kremling="i14"] .journey-top-right__toggle>button,[kremling="i14"].journey-top-right__toggle>button {
  margin-right: -0.1rem;
  border-radius: 0;
  z-index: 0;
}
[kremling="i14"] .journey-top-right__toggle>button:first-child,[kremling="i14"].journey-top-right__toggle>button:first-child {
  border-top-left-radius: 0.4rem;
  border-bottom-left-radius: 0.4rem;
}
[kremling="i14"] .journey-top-right__toggle>button:last-child,[kremling="i14"].journey-top-right__toggle>button:last-child {
  border-top-right-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
}

[kremling="i14"] .journey-bottom-right,[kremling="i14"].journey-bottom-right {
  display: flex;
  align-items: center;
  padding: 0 1rem 1rem 0;
  z-index: 1;
  position: absolute;
  bottom: 0;
  right: 0;
}
[kremling="i14"] .journey-bottom-right div.control,[kremling="i14"].journey-bottom-right div.control {
  width: 2.2rem;
  height: 2.2rem;
}
[kremling="i14"] .journey-bottom-right div.count,[kremling="i14"].journey-bottom-right div.count {
  font-size: 1.2rem;
  color: #606060;
  font-weight: 700;
  height: 2.2rem;
  line-height: 2.2rem;
  padding: 0 0.5rem;
  margin: 0 0.5rem;
  width: 4rem;
  background-color: #fff;
  border-radius: 0.4rem;
  text-align: center;
}`,
  id: 'i14',
  namespace: 'kremling'
};