import React, { useState, createRef, useRef } from 'react';
import classnames from 'classnames';
import { Button } from 'components';
import { Loader } from '../loader/loader.component';
import Form from 'react-jsonschema-form';
import { Modal } from '../modal/modal.component';
const FieldTemplate = props => {
  const {
    id,
    classNames,
    label,
    help,
    required,
    rawDescription,
    children,
    rawErrors,
    schema
  } = props;
  const description = id !== 'root' && !rawErrors && !!rawDescription ? <p>
        <small>{rawDescription}</small>
      </p> : null;
  const errors = !!rawErrors ? rawErrors.map((error, index) => <p key={index}>
          <small>{error}</small>
        </p>) : null;
  return <div className={classNames}>
      {!!label && id !== 'root' && <label htmlFor={id}>
          {label}
          {required ? ' *' : null}
        </label>}

      {schema.type == 'array' && <>
          {description}
          {errors}
          {children}
        </>}
      {schema.type != 'array' && <>
          {children}
          {description}
          {errors}
        </>}

      {help}
    </div>;
};
function ArrayFieldTemplate(props) {
  return <>
      {props.items.map((element, i) => {
      element.hasRemove = true;
      return <React.Fragment key={element.key}>
            <hr />
            <Button className="float-right" type="button" small actionType="flat" icon="fa-regular-times" onClick={element.onDropIndexClick(i)} />
            {element.children}
          </React.Fragment>;
    })}
      {props.canAdd && <Button type="button" onClick={props.onAddClick}>
          Add
        </Button>}
    </>;
}
function transformErrors(errors) {
  return errors.map(error => {
    if (error.name === 'required') {
      error.message = 'Field is required';
    }
    return error;
  });
}
const MODAL_PROPS = ['open', 'title', 'onClose', 'allowBackdropClick'];
export const ModalSchemaForm = props => {
  const {
    onClose,
    onSubmit,
    cancelText,
    submitText,
    actionType
  } = props;
  const formSubmit = response => {
    // TODO is it possible to prevent the form from clearing on submit?
    setSaving(true);
    onSubmit(response).then(() => onClose()).catch(() => {}).then(() => setSaving(false));
  };
  const modalProps = {};
  MODAL_PROPS.forEach(prop => {
    if (props[prop] !== undefined) {
      let val;
      ({
        [prop]: val,
        ...props
      } = props);
      modalProps[prop] = val;
    }
  });
  props.schema = JSON.parse(JSON.stringify(props.schema));
  if (props.schema.title) {
    // Set Schema title as the Modal's title
    modalProps.title = props.schema.title;
    delete props.schema.title;
  }
  const [saving, setSaving] = useState(false);
  const formData = useRef(props.formData || {});
  return <div>
      <Modal {...modalProps}>
        <div className="modal__body">
          <Form id="schema-form" ArrayFieldTemplate={ArrayFieldTemplate} FieldTemplate={FieldTemplate} transformErrors={transformErrors} children={true} showErrorList={false} {...props} formData={formData.current} onChange={event => formData.current = event.formData} onSubmit={formSubmit} />
        </div>
        <div className="modal__actions">
          <Button type="button" onClick={onClose}>
            {cancelText || 'Cancel'}
          </Button>
          {onSubmit && <Button actionType={actionType || 'primary'} type="submit" form="schema-form" disabled={saving}>
              <div className={classnames('d-inline-block', {
            'mr-3': saving
          })}>
                {submitText || 'Submit'}
              </div>
              {saving && <Loader size="sm" />}
            </Button>}
        </div>
      </Modal>
    </div>;
};