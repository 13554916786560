import React, { Component } from 'react';
import { Scoped } from 'kremling';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch } from 'react-router-dom';
import { DateTime } from 'luxon';
import * as Sentry from '@sentry/react';
import { createPortal } from 'react-dom';
import styles from './root.styles.scss';
import { SignIn } from './pages/auth/sign-in.component';
import { SignInSSO } from './pages/auth/sign-in-sso.component';
import { Sidebar } from './components/sidebar/sidebar.component';
import { AuthCheck } from './components/auth-check.component';
import { Ifa2019 } from './web-forms/ifa2019.component';
import { FbTest } from './web-forms/fb-test.component';
import { LoyaltyForm } from './web-forms/loyalty.component';
import { Maintenance } from './pages/maintenance/maintenance.component';
import Page404 from './pages/404';
import { Toaster } from './components/toaster/toaster.component';
import { SnackbarToaster } from './components/mui/snackbar/snackbar.component';
import { userState } from './shared/user-state';
import { AppRoutes } from './app-routes';
import { NoCompany } from './pages/no-company.component';
import { Unsubscribe } from './pages/unsubscribe.component';
import { ErrorBoundary } from './components/error-boundary/error-boundary.component';
import { getStatus } from './shared/common.api';
import { VerifyAddress } from './pages/domains/verify-address.component';
import { RejectAddress } from './pages/domains/reject-address.component';
import { Support } from './pages/auth/support.component';
import { ThemeProvider } from '@mui/material/styles';
import theme from './shared/theme';
import { api } from './shared/api';
import { ContextProviders } from './context/context-providers';
import { MFA } from './pages/auth/mfa.component';
const MAX_RETRIES = 3;
export const history = createBrowserHistory();
let maintenanceInterval;
class Root extends Component {
  constructor(props) {
    super(props);
    const {
      expires
    } = userState.getToken();
    const tokenExpires = DateTime.fromISO(expires).toMillis();
    const now = DateTime.local().toUTC().toMillis();
    const validToken = tokenExpires - now > 0;
    userState.set({
      validToken
    });
    this.state = {
      loadSidebar: true,
      inMaintenance: false,
      inHeavyUsage: false
    };
  }
  componentDidMount() {
    api.interceptors.response.use(res => {
      return res;
    }, err => {
      if (err.code === 'ECONNABORTED') {
        // Ignore cancelled API calls;
        return Promise.reject(err);
      } else if (err.response?.status === 504) {
        // We had a timeout due to API server load.
        if (err.config.retry === undefined) {
          err.config.retry = 1;
        } else {
          err.config.retry += 1;
        }

        // Only retry MAX_RETRIES (3) number of times)
        if (err.config.retry >= MAX_RETRIES) {
          this.setState({
            inMaintenance: true,
            inHeavyUsage: true
          });
          return Promise.resolve(err);
        }

        // Wait 1 second and retry endpoint.
        return new Promise(resolve => setTimeout(() => resolve(), 1000)).then(() => api(err.config));
      } else if (err.message === 'Network Error') {
        // TURN ON MAINTENANCE MODE
        this.setState({
          inMaintenance: true,
          inHeavyUsage: false
        });
        return Promise.resolve(err);
      }
      return Promise.reject(err);
    });

    // Ping the status endpoint once to check if we're online
    getStatus();

    // sidebar query param to hide the side bar
    let sidebarParam = new URLSearchParams(window.location.search).get('sidebar');
    if (sidebarParam == 'false') {
      this.setState({
        loadSidebar: false
      });
    }

    // company query param to automatically "work as" a company
    let companyParam = new URLSearchParams(window.location.search).get('company');
    if (companyParam) {
      userState.setAsCompany({
        id: companyParam,
        name: 'temp'
      });
    }

    // authentication query param to automatically log in
    let authenticationParam = new URLSearchParams(window.location.search).get('authentication');
    if (authenticationParam) {
      const redirect = (window.location.pathname || '/company') + '?' + new URLSearchParams(window.location.search);
      userState.setToken(authenticationParam, null);
      userState.set({
        redirect: redirect,
        validToken: true
      });
    }
  }
  componentDidUpdate(_, {
    inMaintenance
  }) {
    // Clear interval
    clearInterval(maintenanceInterval);
    if (inMaintenance) {
      // Create interval for checking if status is fixed, once a minute
      maintenanceInterval = setInterval(() => {
        getStatus().then(response => {
          if (!response.message || response.message !== 'Network Error') {
            this.setState({
              inMaintenance: false,
              inHeavyUsage: false
            });
          }
        });
      }, 10000);
    }
  }
  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }
  render() {
    const {
      loadSidebar,
      inMaintenance,
      inHeavyUsage
    } = this.state;
    return <>
        <ThemeProvider theme={theme}>
          <ContextProviders>
            <Scoped css={styles}>
              <div>
                <ErrorBoundary>
                  {inMaintenance ? <Maintenance inHeavyUsage={inHeavyUsage} /> : <Router history={history}>
                      <div className="root">
                        <Switch>
                          {/*Web Forms*/}
                          <Route exact path="/web-forms/ifa2019" component={Ifa2019} />
                          <Route exact path="/web-forms/fb-test" component={FbTest} />
                          <Route exact path="/web-forms/wallabys" render={props => <LoyaltyForm {...props} locationAPIKey="sczYOdTUXTP5cUnt6IfQDQ" title="Wallaby's Smokehouse" />} />
                          <Route exact path="/web-forms/staging" render={props => <LoyaltyForm {...props} locationAPIKey="7M-ojI8CNxHSQWrf1UTYog" title="Justin Rae" />} />
                          <Route exact path="/web-forms/renegade" render={props => <LoyaltyForm {...props} locationAPIKey="QWh1aLdmKKDujOGMif1d4w" title="Renegade Burrito" />} />
                          <Route exact path="/web-forms/cravings-bistro" render={props => <LoyaltyForm {...props} locationAPIKey="P8XvW5IpwrMaHTNk0kAlGQ" title="Cravings Bistro" />} />
                          <Route exact path="/web-forms/rock-creek" render={props => <LoyaltyForm {...props} locationAPIKey="Xl9PLmaZbRZqCelQwy5bhA" title="Rock Creek Pizza" />} />
                          <Route exact path="/web-forms/tushar" render={props => <LoyaltyForm {...props} locationAPIKey="XFks1GQfQTnzvpyePM2RbQ" title="Tushar Brazilian Express" />} />
                          <Route exact path="/web-forms/gandolfos" render={props => <LoyaltyForm {...props} locationAPIKey="Xe547XFyAzQj9T_bdBVAMA" title="Gandolfo's" />} />
                          <Route exact path="/web-forms/everest" render={props => <LoyaltyForm {...props} locationAPIKey="OXVRdAqFAdKWOPZBroj5CQ" title="Everest Curry Kitchen" />} />
                          <Route exact path="/web-forms/cravings-cupcakes" render={props => <LoyaltyForm {...props} locationAPIKey="DaxCLEUhKDAv1aX_vTR4nQ" title="Cravings Alisha's Cupcakes" />} />
                          <Route exact path="/web-forms/oilchangers" render={props => <LoyaltyForm {...props} locationAPIKey="4pR1e9YsjH9STA6vCMh-0g" title="Oil Changers" />} />
                          <Route path="/web-forms" component={Page404} />

                          {/* auth pages */}
                          <Route path="/sign-in" exact component={SignIn} />
                          <Route path="/mfa" exact component={MFA} />
                          <Route path="/auth/sso/:provider" component={SignInSSO} />
                          <Route path="/auth/reset-password/:token" component={SignIn} />
                          <Route path="/support" exact component={Support} />

                          {/*error pages*/}
                          <Route path="/no-company" exact component={NoCompany} />

                          {/*unsubscribe page*/}
                          <Route path="/unsubscribe/:id" exact component={Unsubscribe} />

                          {/*verify email page*/}
                          <Route path="/address/verify/:id/:hash" exact component={VerifyAddress} />

                          {/*reject email page*/}
                          <Route path="/address/reject/:id" exact component={RejectAddress} />

                          <Route path="/" render={props => <AuthCheck {...props}>
                                {loadSidebar && <Sidebar {...props} />}
                                <AppRoutes {...props} />
                              </AuthCheck>} />
                        </Switch>
                      </div>
                    </Router>}
                </ErrorBoundary>
              </div>
              <Toaster />
              <SnackbarToaster />
            </Scoped>
          </ContextProviders>
        </ThemeProvider>
      </>;
  }
}
export default Sentry.withProfiler(Root);