import React, { Component } from 'react';
import { Scoped, k } from 'kremling';
import logo from '../assets/img/cinch-logo.svg';
import { Button } from '../components/button/button.component';
import { api } from '../shared/api';
import { Loader } from '../components/loader/loader.component';
export class Unsubscribe extends Component {
  constructor(props) {
    super(props);
    const done = props.match.params.id === 'done';
    this.state = {
      done,
      loading: !done,
      companyName: ''
    };
  }
  componentDidMount() {
    if (!this.state.done) {
      const {
        match
      } = this.props;
      api.get(`/sendgrid/unsubscribe/${match.params.id}`).then(({
        data
      }) => {
        let state = {
          loading: false
        };
        if (data.company && data.company.name) {
          state.companyName = data.company.name;
        }
        this.setState(state);
      });
    }
  }
  unsubscribe = () => {
    const {
      match
    } = this.props;
    this.setState({
      loading: true
    });
    api.post(`/sendgrid/unsubscribe/${match.params.id}`).then(() => {
      this.setState({
        loading: false,
        done: true
      }, () => {
        this.props.history.push('/unsubscribe/done');
      });
    });
  };
  render() {
    const {
      done,
      loading,
      companyName
    } = this.state;
    return <Scoped css={css}>
        <div className="unsubscribe">
          {loading ? <Loader overlay /> : <div className="unsubscribe__child">
              {!done ? <>
                  <div>Unsubscribe from</div>
                  <h2 className="company-name">{companyName}</h2>
                  <Button actionType="primary" className="mb-md" large onClick={this.unsubscribe}>
                    Unsubscribe
                  </Button>
                </> : <h2>
                  Unsubscribe Successful
                </h2>}
              <div className="powered-by">
                <div>Powered by</div> <span className="logo" dangerouslySetInnerHTML={{
              __html: logo
            }} />
              </div>
            </div>}
        </div>
      </Scoped>;
  }
}
const css = {
  styles: `[kremling="i1"] body,body[kremling="i1"] {
  --color-primary: #487aae;
  --color-highlight: #e7efff;
  --color-accent: #354052;
  --color-success: #73b56e;
  --color-warning: #f4b707;
  --color-danger: #df5651;
  --color-grey-10: #f9f9f9;
  --color-grey-25: #f3f3f3;
  --color-grey-50: #e9e9e9;
  --color-grey-75: #e3e3e3;
  --color-grey-100: #d3d3d3;
  --color-grey-200: #c3c3c3;
  --color-grey-300: #b3b3b3;
  --color-grey-400: #a3a3a3;
  --color-grey-500: #808080;
  --color-grey-600: #707070;
  --color-grey-700: #606060;
  --color-grey-800: #505050;
  --color-grey-900: #404040;
  --base-font-family: Roboto, Helvetica, Arial, sans-serif;
  --base-font-size: 1.4rem;
  --base-font-color: #404040;
  --base-font-weight: 400;
  --base-line-height: 1.4;
  --box-shadow-1: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.12);
  --box-shadow-2: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-3: 0 10px 20px rgba(0, 0, 0, 0.17), 0 6px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-4: 0 14px 28px rgba(0, 0, 0, 0.13), 0 10px 10px rgba(0, 0, 0, 0.1);
  --box-shadow-5: 0 19px 38px rgba(0, 0, 0, 0.18), 0 15px 12px rgba(0, 0, 0, 0.1);
  --base-border-radius: 0.4rem;
}

[kremling="i1"] .unsubscribe,[kremling="i1"].unsubscribe {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
}

[kremling="i1"] .powered-by,[kremling="i1"].powered-by {
  display: flex;
  align-items: center;
  color: #a3a3a3;
  font-size: 1.2rem;
}

[kremling="i1"] .are-you-sure,[kremling="i1"].are-you-sure {
  margin-bottom: 1.6rem;
  font-size: 2rem;
}

[kremling="i1"] .logo,[kremling="i1"].logo {
  width: 5rem;
  margin-bottom: 0.7rem;
  margin-left: 0.6rem;
  fill: #a3a3a3;
}

[kremling="i1"] .unsubscribe__child,[kremling="i1"].unsubscribe__child {
  width: 36rem;
  height: 20rem;
}`,
  id: 'i1',
  namespace: 'kremling'
};