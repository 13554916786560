import React, { useState, useEffect, useContext } from 'react';
import { Button, Loader, toasterService } from 'components';
import { updateAuthorizedRepresentative } from 'shared/common.api';
import { WorkingAsContext } from 'context/working-as-context';
const REQUIRED_FIELDS = ['job_position', 'last_name', 'phone_number', 'first_name', 'email', 'business_title'];
export const AuthorizedRepresentativeStep = props => {
  const {
    updateProfile,
    profile,
    setActiveStep,
    verification
  } = props;
  const {
    asCompany
  } = useContext(WorkingAsContext);
  const [disabled, setDisabled] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const submit = () => {
    // Submit this to the Cinch API which will submit to the Twilio API...
    // This will not be stored in Cinch's database but will be retrieved directly from Twilio
    setIsSaving(true);
    updateAuthorizedRepresentative({
      company: asCompany.id,
      ...profile.authorized_representative
    }).then(() => {
      setActiveStep(2);
    }).catch(() => {
      toasterService.error('There was an unknown error trying to save your profile. Check your inputs and try again');
    }).then(() => {
      setIsSaving(false);
    });
  };
  const formatPhone = () => {
    const numbersOnly = profile.authorized_representative.phone_number.replace(/\D/g, '');
    if (numbersOnly.length === 10) {
      updateProfile('authorized_representative', 'phone_number', `+1${numbersOnly}`);
    } else if (numbersOnly.length === 11) {
      updateProfile('authorized_representative', 'phone_number', `+${numbersOnly}`);
    }
  };
  useEffect(() => {
    setDisabled(REQUIRED_FIELDS.some(field => !profile?.authorized_representative?.[field] || profile?.authorized_representative?.[field] === ''));
  }, [profile]);
  return <>
      <div className="form-group">
        <label>First Name</label>
        <input className="form-control" name="first_name" onChange={e => updateProfile('authorized_representative', 'first_name', e.target.value)} value={profile.authorized_representative?.first_name || ''} autoFocus />
        {verification?.error?.first_name && <small className="mt-1 block text-danger">{verification?.error?.first_name}</small>}
      </div>
      <div className="form-group">
        <label>Last Name</label>
        <input className="form-control" name="last_name" onChange={e => updateProfile('authorized_representative', 'last_name', e.target.value)} value={profile.authorized_representative?.last_name || ''} />
        {verification?.error?.last_name && <small className="mt-1 block text-danger">{verification?.error?.last_name}</small>}
      </div>
      <div className="form-group">
        <label>Job Title</label>
        <input className="form-control" name="business_title" onChange={e => updateProfile('authorized_representative', 'business_title', e.target.value)} value={profile.authorized_representative?.business_title || ''} />
        {verification?.error?.business_title && <small className="mt-1 block text-danger">{verification?.error?.business_title}</small>}
      </div>
      <div className="form-group">
        <label>Job Position</label>
        <select className="form-control" name="job_position" onChange={e => updateProfile('authorized_representative', 'job_position', e.target.value)} value={profile.authorized_representative?.job_position || ''}>
          <option value=""></option>
          <option value="Director">Director</option>
          <option value="GM">GM</option>
          <option value="VP">VP</option>
          <option value="CEO">CEO</option>
          <option value="CFO">CFO</option>
          <option value="General_Counsel">General Counsel</option>
          <option value="Other">Other</option>
        </select>
        {verification?.error?.job_position && <small className="mt-1 block text-danger">{verification?.error?.job_position}</small>}
      </div>
      <div className="form-group">
        <label>Email</label>
        <input className="form-control" name="email" onChange={e => updateProfile('authorized_representative', 'email', e.target.value)} value={profile.authorized_representative?.email || ''} />
        {verification?.error?.email && <small className="mt-1 block text-danger">{verification?.error?.email}</small>}
      </div>
      <div className="form-group">
        <label>Phone Number</label>
        <input className="form-control" name="phone_number" onChange={e => updateProfile('authorized_representative', 'phone_number', e.target.value)} onBlur={formatPhone} value={profile.authorized_representative?.phone_number || ''} />
        <small className="mt-1 block">Use the international format: +18885551234</small>
        {verification?.error?.phone_number && <small className="mt-1 block text-danger">{verification?.error?.phone_number}</small>}
      </div>
      <div style={{
      display: 'flex',
      gap: '10px',
      justifyContent: 'end'
    }}>
        <Button actionType="flat" onClick={() => setActiveStep(0)}>
          Back
        </Button>
        <Button actionType="primary" disabled={disabled || isSaving} onClick={submit}>
          Save {isSaving && <Loader className="ml-3" size="sm" />}
        </Button>
      </div>
    </>;
};