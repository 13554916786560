import React, { useState, useEffect, useCallback } from 'react';
import { Scoped } from 'kremling';
import classnames from 'classnames';
import styles from './domains.styles.scss';
import { PageHeader } from '../../components/page-header/page-header';
import { Loader } from '../../components/loader/loader.component';
import { Button } from '../../components/button/button.component';
import { Icon } from '../../components/icon/icon.component';
import { SortHeader } from '../../components/table/sort-header.component';
import { Dropdown } from '../../components/dropdown/dropdown.component';
import { Pagination } from '../../components/pagination/pagination.component';
import { AsyncModalDialog } from '../../components/modal/async-modal-dialog.component';
import { toasterService } from '../../components/toaster/toaster-service';
import { getDomains, createDomains, verifyDomain, deleteDomain, getAddresses, createAddress, resendVerificationEmail, deleteAddress } from '../../shared/common.api';
import { userState } from '../../shared/user-state';
import utils from '../../shared/utils';
export function Domains() {
  const [loading, setLoading] = useState(true);

  // Domain State
  const [ordering, setOrdering] = useState('domain');
  const [domainParams, setDomainParams] = useState({});
  const [domainPagination, setDomainPagination] = useState({});
  const [createModal, setCreateModal] = useState(false);
  const [createModalDomain, setCreateModalDomain] = useState('');
  const [createModalErrors, setCreateModalErrors] = useState({});
  const [verifyModal, setVerifyModal] = useState(false);
  const [verifyModalDomain, setVerifyModalDomain] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalDomain, setDeleteModalDomain] = useState();

  // Address State
  const [addressOrdering, setAddressOrdering] = useState('address');
  const [addressParams, setAddressParams] = useState({});
  const [addressPagination, setAddressPagination] = useState({});
  const [createAddressModal, setCreateAddressModal] = useState(false);
  const [createAddressModalAddress, setCreateAddressModalAddress] = useState('');
  const [createAddressModalErrors, setCreateAddressModalErrors] = useState({});
  const [deleteAddressModal, setDeleteAddressModal] = useState(false);
  const [deleteAddressModalAddress, setDeleteAddressModalAddress] = useState();
  const loadDomainData = useCallback(() => {
    return getDomains({
      company: userState.getAsCompanyId(),
      ordering,
      ...domainParams
    }).then(({
      data
    }) => {
      setDomainPagination(data);
    });
  }, [setDomainPagination, ordering, domainParams]);
  const loadAddressData = useCallback(() => {
    return getAddresses({
      company: userState.getAsCompanyId(),
      ordering: addressOrdering,
      ...addressParams
    }).then(({
      data
    }) => {
      setAddressPagination(data);
    });
  }, [setAddressPagination, addressOrdering, addressParams]);
  useEffect(() => {
    Promise.all([loadDomainData(), loadAddressData()]).then(() => {
      setLoading(false);
    });
  }, [loadDomainData, loadAddressData]);
  const openCreate = () => {
    setCreateModal(true);
    setCreateModalDomain('');
    setCreateModalErrors({});
  };
  const openAddressCreate = () => {
    setCreateAddressModal(true);
    setCreateAddressModalAddress('');
    setCreateAddressModalErrors({});
  };
  const createDomain = domain => {
    return createDomains({
      company: userState.getAsCompanyId(),
      domain
    }).then(({
      data
    }) => {
      loadDomainData();
      setCreateModal(false);
      openVerify(data);
    }).catch(e => {
      setCreateModalErrors(e.response.data);
      throw e;
    });
  };
  const preCreateAddress = address => {
    return createAddress({
      company: userState.getAsCompanyId(),
      address
    }).then(({
      data
    }) => {
      loadAddressData();
      setCreateAddressModal(false);
    }).catch(e => {
      setCreateAddressModalErrors(e.response.data);
      throw e;
    });
  };
  const openVerify = domain => {
    setVerifyModal(true);
    setVerifyModalDomain(domain);
  };
  const checkDomain = domain => {
    return verifyDomain(domain.id).then(({
      data
    }) => {
      if (data.status === 'pending') {
        setVerifyModalDomain({
          ...data
        });
        toasterService.error('Unable to verify DNS settings. Wait a few minutes and try again.');
        throw false;
      } else {
        loadDomainData();
      }
    });
  };
  const preResendVerificationEmail = address => {
    resendVerificationEmail(address.id).then(() => {
      toasterService.success('Verification email resent.');
    }).catch(() => {
      toasterService.error('Unable to resend verification email. Please try again.');
    });
  };
  const openAddressDelete = address => {
    setDeleteAddressModal(true);
    setDeleteAddressModalAddress(address);
  };
  const openDelete = domain => {
    setDeleteModal(true);
    setDeleteModalDomain(domain);
  };
  const removeDomain = domain => {
    return deleteDomain(domain.id).then(() => {
      loadDomainData();
      setDeleteModal(false);
    }).catch(e => {
      toasterService.error('Unable to remove domain. Please try again.');
      throw e;
    });
  };
  const preDeleteAddress = address => {
    return deleteAddress(address.id).then(() => {
      loadAddressData();
      setDeleteAddressModal(false);
    }).catch(e => {
      toasterService.error('Unable to remove address. Please try again.');
      throw e;
    });
  };
  const copyToClipboard = text => {
    navigator.clipboard.writeText(text);
    toasterService.success(`${text} copied to clipboard!`);
  };
  if (loading) return <Loader overlay />;
  return <Scoped css={styles}>
      <div className="wrapper">
        <PageHeader name={`Domains ${domainPagination.count ? `(${utils.commaize(domainPagination.count)})` : ''}`} actions={userState.hasPermission('email.add_domain') && <Button actionType="primary" icon="fa-regular-plus" onClick={openCreate} />} />
        <div className="wrapper-scroll broadcast-list" style={{
        flexGrow: 0,
        marginBottom: '50px'
      }}>
          <table className="table-list" style={{
          marginBottom: '24px'
        }}>
            <thead>
              <tr>
                <SortHeader name="domain" ordering={ordering} update={ordering => setOrdering(ordering)}>
                  Domain
                </SortHeader>
                <SortHeader name="status" ordering={ordering} update={ordering => setOrdering(ordering)}>
                  Status
                </SortHeader>
                <th style={{
                width: 40
              }} />
              </tr>
            </thead>
            <tbody>
              {domainPagination.results.length && domainPagination.results.map(domain => <tr key={domain.id}>
                    <td>{domain.domain}</td>
                    <td>
                      {domain.status === 'pending' && <>
                          <Icon name="fa-regular-ellipsis-h" className="mr-2" size={14} />{' '}
                          Pending
                        </>}
                      {domain.status === 'verified' && <>
                          <Icon name="fa-solid-check-circle" className="mr-2 text-success" size={14} />{' '}
                          Verified
                        </>}
                    </td>
                    <td>
                      <Dropdown horizontal="west" trigger={() => <Button actionType="flat" icon="fa-regular-ellipsis-h" />} content={() => <ul className="select-list">
                            <li>
                              {userState.hasPermission('email.delete_domain') && <a onClick={() => openDelete(domain)}>
                                  Remove Domain
                                </a>}

                              {!domain.valid && userState.hasPermission('email.change_domain') && <a onClick={() => openVerify(domain)}>
                                    Verify Domain
                                  </a>}
                            </li>
                          </ul>} />
                    </td>
                  </tr>) || <tr>
                  <td colSpan={3}>
                    <strong>No Results</strong>
                  </td>
                </tr>}
            </tbody>
          </table>
          <Pagination data={domainPagination} onChange={setDomainParams} />
        </div>

        <PageHeader name={`Addresses ${addressPagination.count ? `(${utils.commaize(addressPagination.count)})` : ''}`} actions={userState.hasPermission('email.add_address') && <Button actionType="primary" icon="fa-regular-plus" onClick={openAddressCreate} />} />
        <div className="wrapper-scroll broadcast-list">
        <table className="table-list" style={{
          marginBottom: '24px'
        }}>
            <thead>
              <tr>
                <SortHeader name="address" ordering={addressOrdering} update={ordering => setAddressOrdering(ordering)}>
                  Address
                </SortHeader>
                <SortHeader name="status" ordering={addressOrdering} update={ordering => setAddressOrdering(ordering)}>
                  Status
                </SortHeader>
                <th style={{
                width: 40
              }} />
              </tr>
            </thead>
            <tbody>
              {addressPagination.results.length && addressPagination.results.map(address => <tr key={address.id}>
                    <td>{address.address}</td>
                    <td>
                      {address.status === 'pending' && <>
                          <Icon name="fa-regular-ellipsis-h" className="mr-2" size={14} />{' '}
                          Pending
                        </>}
                      {address.status === 'verified' && <>
                          <Icon name="fa-solid-check-circle" className="mr-2 text-success" size={14} />{' '}
                          Verified
                        </>}
                      {address.status === 'rejected' && <>
                          <Icon name="fa-solid-exclamation-circle" className="mr-2 text-danger" size={14} />{' '}
                          Rejected
                        </>}
                    </td>
                    <td>
                      <Dropdown horizontal="west" trigger={() => <Button actionType="flat" icon="fa-regular-ellipsis-h" />} content={() => <ul className="select-list">
                            <li>
                              {userState.hasPermission('email.change_address') && <a onClick={() => preResendVerificationEmail(address)}>
                                  Resend Verification Email
                                </a>}
                              {userState.hasPermission('email.delete_address') && <a onClick={() => openAddressDelete(address)}>
                                  Remove Address
                                </a>}
                            </li>
                          </ul>} />
                    </td>
                  </tr>) || <tr>
                  <td colSpan={3}>
                    <strong>No Results</strong>
                  </td>
                </tr>}
            </tbody>
          </table>
          <Pagination data={addressPagination} onChange={setAddressParams} />
        </div>

        <AsyncModalDialog open={!!createModal} title="Add New Domain" submitText="Add" onSubmit={() => createDomain(createModalDomain)} onClose={() => setCreateModal(false)} allowBackdropClick>
          <p>Enter the domain you would like to verify.</p>
          <div className={classnames('form-group', {
          'is-invalid': createModalErrors.domain
        })}>
            <label>Domain</label>
            <input className="form-control" name="create-modal-domain" onChange={e => setCreateModalDomain(e.target.value)} value={createModalDomain} autoFocus />
            {createModalErrors.domain && createModalErrors.domain.map((e, i) => <div className="invalid-feedback text-danger" key={i}>
                  {e}
                </div>)}
          </div>
        </AsyncModalDialog>

        <AsyncModalDialog open={!!createAddressModal} title="Add New Address" submitText="Add" onSubmit={() => preCreateAddress(createAddressModalAddress)} onClose={() => setCreateAddressModal(false)} allowBackdropClick>
          <p>Enter the email address you would like to verify.</p>
          <div className={classnames('form-group', {
          'is-invalid': createAddressModalErrors.address
        })}>
            <label>Email Address</label>
            <input className="form-control" name="create-modal-domain" onChange={e => setCreateAddressModalAddress(e.target.value)} value={createAddressModalAddress} autoFocus />
            {createModalErrors.domain && createModalErrors.domain.map((e, i) => <div className="invalid-feedback text-danger" key={i}>
                  {e}
                </div>)}
          </div>
        </AsyncModalDialog>

        <AsyncModalDialog open={!!deleteModal} title="Remove Domain" submitText="Remove" actionType="danger" onSubmit={() => removeDomain(deleteModalDomain)} onClose={() => setDeleteModal(false)} allowBackdropClick>
          <p>Are you sure you wish to remove this domain?</p>
        </AsyncModalDialog>

        <AsyncModalDialog open={!!deleteAddressModal} title="Remove Address" submitText="Remove" actionType="danger" onSubmit={() => preDeleteAddress(deleteAddressModalAddress)} onClose={() => setDeleteAddressModal(false)} allowBackdropClick>
          <p>Are you sure you wish to remove this address?</p>
        </AsyncModalDialog>

        <AsyncModalDialog size="xl" open={!!verifyModal} title="Verify Domain" submitText="Verify" onSubmit={() => checkDomain(verifyModalDomain)} onClose={() => setVerifyModal(false)} allowBackdropClick>
          <p>Add these records to your domain provider's DNS settings</p>

          <table className="table-list mb-2">
            <thead>
              <tr>
                <th>Type</th>
                <th>Host</th>
                <th>Data</th>
                <th style={{
                width: '70px'
              }}>Verified</th>
              </tr>
            </thead>
            <tbody>
              {verifyModalDomain && Object.values(verifyModalDomain.dns).map(dns => <tr key={dns.host}>
                    <td className="text-uppercase">{dns.type}</td>
                    <td onClick={() => copyToClipboard(dns.host)}>
                      {dns.host} <Icon size={13} name="fa-regular-clipboard" />
                    </td>
                    <td onClick={() => copyToClipboard(dns.data)}>
                      {dns.data} <Icon size={13} name="fa-regular-clipboard" />
                    </td>
                    <td className="text-center">
                      {!dns.valid && <Icon className="text-danger" name="fa-solid-exclamation-circle" />}
                      {dns.valid && <Icon className="text-success" name="fa-solid-check-circle" />}
                    </td>
                  </tr>)}
            </tbody>
          </table>

          <p className="text-center">
            Having trouble?{' '}
            <a href="https://sendgrid.com/docs/ui/account-and-settings/troubleshooting-sender-authentication/" target="_blank">
              View our vendor's documentation
            </a>
          </p>
        </AsyncModalDialog>
      </div>
    </Scoped>;
}