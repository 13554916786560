import React, { Component } from 'react';
import { Scoped } from 'kremling';
import { isEqual } from 'lodash';
import moment from 'moment';
import { api } from '../../shared/api';
import { PageHeader } from '../../components/page-header/page-header';
import styles from './customers.styles.scss';
import { Pagination } from '../../components/pagination/pagination.component';
import { Loader } from '../../components/loader/loader.component';
import { userState } from '../../shared/user-state';
import { SortHeader } from '../../components/table/sort-header.component';
import { Button } from '../../components/button/button.component';
import { Modal } from '../../components/modal/modal.component';
import { getModels } from '../../components/rules/rules.resource';
import utils from '../../shared/utils';
import { CustomerDrawerContext } from 'context/customer-drawer-context';
export class Customers extends Component {
  static contextType = CustomerDrawerContext;
  state = {
    loading: true,
    customers: [],
    customerPagination: {},
    paginationParams: {
      limit: 30,
      offset: 0
    },
    query: '',
    ordering: null,
    fields: [{
      id: "first_name",
      name: "First Name",
      type: "text"
    }, {
      id: "last_name",
      name: "Last Name",
      type: "text"
    }, {
      id: "email",
      name: "Email",
      type: "text"
    }, {
      id: "phone",
      name: "Phone",
      type: "text"
    }],
    tempFields: null,
    allFields: [],
    toAdd: null,
    toRemove: null
  };
  customerFields = [{
    id: "first_name",
    name: "First Name",
    type: "text"
  }, {
    id: "last_name",
    name: "Last Name",
    type: "text"
  }, {
    id: "email",
    name: "Email",
    type: "text"
  }, {
    id: "phone",
    name: "Phone",
    type: "text"
  }];
  componentDidMount() {
    const customerFieldsStr = localStorage.getItem('customerFields');
    if (customerFieldsStr) {
      this.setState({
        fields: JSON.parse(customerFieldsStr)
      });
    }
    Promise.all([getModels(), api.get(`/customers`, {
      params: {
        limit: 30,
        company: userState.getAsCompanyId()
      }
    })]).then(([companyFields, customers]) => {
      this.setState({
        loading: false,
        customerPagination: customers.data,
        customers: customers.data.results,
        allFields: companyFields.find(i => i.id === 'customer').fields
      });
    });
  }
  getCustomers = () => {
    this.setState({
      loading: true
    });
    return api.get(`/customers`, {
      params: {
        ...this.state.paginationParams,
        limit: 30,
        search: this.state.query,
        company: userState.getAsCompanyId(),
        ordering: this.state.ordering
      }
    }).then(({
      data
    }) => {
      this.setState({
        loading: false,
        customerPagination: data,
        customers: data.results || []
      });
    });
  };
  onSearch = query => {
    this.setState({
      query
    });
    this.getCustomers();
  };
  pageOnChange = params => {
    if (!isEqual(params, this.state.paginationParams)) {
      this.setState({
        paginationParams: params
      }, () => {
        this.getCustomers();
      });
    }
  };
  commas = x => {
    let parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  };
  updateOrdering = ordering => {
    this.setState({
      ordering,
      currentPage: 1
    }, () => this.getCustomers());
  };
  editFields = () => {
    this.setState({
      tempFields: JSON.parse(JSON.stringify(this.state.fields)),
      toAdd: null,
      toRemove: null
    });
  };
  addTemp = () => {
    const {
      toAdd,
      tempFields,
      allFields
    } = this.state;
    if (toAdd && tempFields.length < 6 && !tempFields.find(i => i.id === toAdd)) {
      tempFields.push(allFields.find(i => i.id === toAdd));
      this.setState({
        tempFields
      });
    }
  };
  removeTemp = () => {
    const {
      toRemove,
      tempFields
    } = this.state;
    if (toRemove) {
      const index = tempFields.findIndex(i => i.id === toRemove);
      if (index > -1) {
        tempFields.splice(index, 1);
        this.setState({
          tempFields
        });
      }
    }
  };
  saveFields = () => {
    const {
      tempFields
    } = this.state;
    localStorage.setItem('customerFields', JSON.stringify(tempFields));
    this.setState({
      fields: tempFields,
      tempFields: null
    });
  };
  render() {
    const {
      customers,
      customerPagination,
      loading,
      ordering,
      fields,
      tempFields,
      allFields
    } = this.state;
    return <Scoped css={styles}>
        <div className="wrapper">
          {loading && <Loader overlay background="rgba(255,255,255,.5)" />}
          <PageHeader name={`Customers ${customerPagination.count ? `(${this.commas(customerPagination.count)})` : ''}`} onSearch={this.onSearch} preactions={<Button onClick={() => this.editFields()}>
                Fields
              </Button>} />
          <div className="wrapper-scroll p-md">
            <div className="table-container">
              <table className="table-list">
                <thead>
                  <tr>
                    {fields.map(field => <SortHeader key={`header${field.id}`} name={field.id} ordering={ordering} update={ordering => this.updateOrdering(ordering)}>{field.name}</SortHeader>)}
                  </tr>
                </thead>
                <tbody>
                {customers.map(customer => {
                  return <tr key={customer.id} onClick={() => this.context.openCustomerDrawer(customer.id)}>
                    {fields.map(field => {
                      return <td key={`field${field.id}`}>{field.type === "date" || field.type === "datetime" ? !!customer[field.id] && moment(customer[field.id]).isValid() ? moment(customer[field.id]).format('YYYY-MM-DD') : `No ${field.name.toLowerCase()}` : field.id === "phone" ? utils.formatPhoneNumber(customer[field.id]) : customer[field.id]}</td>;
                    })}
                  </tr>;
                })}
                </tbody>
              </table>
            </div>
            <div className="pt-md pb-md">
              <Pagination data={customerPagination} onChange={this.pageOnChange} limit={30} />
            </div>
          </div>
        </div>

        <Modal open={!!tempFields} onClose={() => this.setState({
        tempFields: null
      })} allowBackdropClick size="lg">
            <div className="modal__title">Select Field</div>
            <div className="modal__body">
              <p>Choose up to 6 fields to view.</p>

              <div className="d-flex" style={{
            alignItems: 'center'
          }}>
                <div style={{
              flex: '1 1 50%'
            }}>
                  <select style={{
                height: '250px'
              }} size="10" className="form-control" onChange={event => this.setState({
                toAdd: event.target.value
              })}>
                      {allFields.filter(i => tempFields && !tempFields.find(j => j.id === i.id)).map(field => <option key={`option${field.id}`} value={field.id}>{field.name}</option>)}
                  </select>
                </div>
                <div style={{
              flex: '0 0 30px'
            }}>
                  <Button actionType="flat" icon="fa-solid-caret-left" onClick={() => this.removeTemp()} />
                  <Button actionType="flat" icon="fa-solid-caret-right" onClick={() => this.addTemp()} />
                </div>
                <div style={{
              flex: '1 1 50%'
            }}>
                  <select style={{
                height: '250px'
              }} size="10" className="form-control" onChange={event => this.setState({
                toRemove: event.target.value
              })}>
                      {tempFields && tempFields.map(field => <option key={`option${field.id}`} value={field.id}>{field.name}</option>)}
                  </select>
                </div>
              </div>
            </div>
            <div className="modal__actions">
              <Button onClick={() => this.setState({
            tempFields: null
          })}>
                Cancel
              </Button>
              <Button onClick={() => {
            this.saveFields();
          }} actionType="primary">
                Save
              </Button>
            </div>
          </Modal>
      </Scoped>;
  }
}