import React, { Component } from 'react';
import { Scoped } from 'kremling';
import styles from './integrations.styles.scss';
import { userState } from '../../shared/user-state';
import { Button } from '../../components/button/button.component';
import { getMBSites, addMBSite, deleteMBSite, verifyMBSite, updateMBSite } from './integrations.resource';
import { toasterService } from '../../components/toaster/toaster-service';
import { ModalDialog } from '../../components/modal/modal-dialog.component';
import { Loader } from '../../components/loader/loader.component';
import { Icon } from '../../components/icon/icon.component';
export class IntegrationMindBody extends Component {
  static propTypes = {};
  state = {
    sites: [],
    deleteMindBodySite: {},
    verifyMindBodySite: {},
    updateMindBodySite: {},
    verifyId: null,
    isSiteModalOpen: false,
    siteId: null,
    username: null,
    password: null
  };
  componentDidMount() {
    this.getMindBodyData().finally(() => this.props.onLoad());
  }
  getMindBodyData = () => {
    return getMBSites({
      company: userState.getAsCompanyId()
    }).then(({
      results
    }) => {
      this.setState({
        sites: results
      });
    });
  };
  addMindBodySite = (siteId, username, password) => {
    return addMBSite(siteId, username, password, userState.getAsCompanyId()).then(verifyMindBodySite => this.setState({
      verifyMindBodySite
    })).then(() => toasterService.success('Successfully added site.')).then(() => this.getMindBodyData()).catch(err => {
      if (err.response.data.site_id) {
        toasterService.error(err.response.data.site_id[0]);
      } else if (err.response.data.company) {
        toasterService.error(err.response.data.company[0]);
      } else {
        toasterService.error('Unknown error trying to add site. Please try again.');
      }
    });
  };
  updateMindBodySite = (id, username, password) => {
    return updateMBSite(id, username, password).then(() => toasterService.success('Successfully updated site.')).then(() => this.getMindBodyData()).catch(err => {
      if (err.response.data.site_id) {
        toasterService.error(err.response.data.site_id[0]);
      } else {
        toasterService.error('Unknown error trying to update site. Please try again.');
      }
    });
  };
  verifyMindBodySiteId = verifyId => {
    this.setState({
      verifyId
    });
    return verifyMBSite(verifyId).then(() => toasterService.success('Successfully verified site.')).then(() => this.getMindBodyData()).catch(err => {
      if (err.response.data.site_id) {
        toasterService.error(err.response.data.site_id[0]);
      } else {
        toasterService.error('Unknown error trying to verify site. Please try again.');
      }
    }).then(() => this.setState({
      verifyId: null
    }));
  };
  removeMindBodySite = siteId => {
    return deleteMBSite(siteId).then(() => toasterService.success('Successfully deleted site')).then(() => this.getMindBodyData()).catch(() => toasterService.error('Unknown error to trying delete site. Please try again.'));
  };
  render() {
    const {
      sites,
      isSiteModalOpen,
      siteId,
      verifyId,
      username,
      password,
      deleteMindBodySite,
      updateMindBodySite,
      verifyMindBodySite
    } = this.state;
    return <Scoped css={styles}>
        <div className="integration mb-5">
          <div className="integration__header">
            <div>MindBody</div>
            {userState.hasPermission('mindbody.add_site') && <Button className="btn" actionType="primary" tag="a" onClick={() => this.setState({
            isSiteModalOpen: true
          })}>
                Add Site
              </Button>}
          </div>
          <div className="integration__body">
            {sites.map(site => <div className="integration-item" key={site.site_id}>
                <div className="integration-item__inner">
                  <div className="integration-title">
                    {site.name ? <strong className="pr-2">{site.name}</strong> : <span>
                        Site ID{' '}
                        <strong className="pl-2 pr-2">{site.site_id}</strong>
                      </span>}
                    {site.status !== 'Verified' ? <span>
                        <Icon className="wrong-source pr-2" name="fa-solid-exclamation-triangle" size={16} />
                        <em>{site.status}</em>
                      </span> : ''}
                  </div>
                  <div className="integration-actions">
                    {site.status === 'Unverified' && <span>
                        {userState.hasPermission('mindbody.add_site') && <Button onClick={e => {
                    e.stopPropagation();
                    this.setState({
                      verifyMindBodySite: site
                    });
                  }} className="mr-3" actionType="grey">
                            Verify Integration
                          </Button>}
                        {verifyId === site.id ? <Loader size="sm" /> : <Button onClick={e => {
                    e.stopPropagation();
                    this.verifyMindBodySiteId(site.id);
                  }} className="mr-3" actionType="grey">
                            Check Verification
                          </Button>}
                      </span>}
                    {userState.hasPermission('mindbody.add_site') && <Button actionType="flat" icon="fa-regular-edit" onClick={e => {
                  e.stopPropagation();
                  this.setState({
                    updateMindBodySite: site
                  });
                }} />}
                    {userState.hasPermission('mindbody.delete_site') && <Button actionType="flat" icon="fa-regular-trash" onClick={e => {
                  e.stopPropagation();
                  this.setState({
                    deleteMindBodySite: site
                  });
                }} />}
                  </div>
                </div>
              </div>)}
          </div>

          <ModalDialog open={!!deleteMindBodySite.id} title="Remove site" submitText="Remove" onSubmit={() => this.removeMindBodySite(deleteMindBodySite.id)} onClose={() => this.setState({
          deleteMindBodySite: {}
        })} allowBackdropClick>
            <p>
              <strong>{deleteMindBodySite.location_name}</strong>
            </p>
            <p>Are you sure you want to remove this site?</p>
          </ModalDialog>

          <ModalDialog open={isSiteModalOpen} title="Add Site" onSubmit={() => this.addMindBodySite(siteId, username, password)} onClose={() => this.setState({
          isSiteModalOpen: false
        })} allowBackdropClick>
            <p>
              Please enter your MindBody information below to link to your
              account.
            </p>
            <p>
              <label>Site ID</label>
              <input required className="form-control" name="site_id" onChange={e => this.setState({
              siteId: e.target.value
            })} />
            </p>
            <p>
              <label>Username</label>
              <input required className="form-control" name="username" onChange={e => this.setState({
              username: e.target.value
            })} />
            </p>
            <p>
              <label>Password</label>
              <input required className="form-control" name="password" type="password" onChange={e => this.setState({
              password: e.target.value
            })} />
            </p>
          </ModalDialog>

          <ModalDialog open={!!updateMindBodySite.id} title="Update site" submitText="Update" onSubmit={() => this.updateMindBodySite(updateMindBodySite.id, username, password)} onClose={() => this.setState({
          updateMindBodySite: {}
        })} allowBackdropClick>
            <p>Please update your MindBody information below.</p>
            <p>
              <label>Username</label>
              <input required className="form-control" name="username" defaultValue={updateMindBodySite.username || ''} onChange={e => this.setState({
              username: e.target.value
            })} />
            </p>
            <p>
              <label>Password</label>
              <input required className="form-control" name="password" type="password" onChange={e => this.setState({
              password: e.target.value
            })} />
            </p>
          </ModalDialog>

          <ModalDialog open={!!verifyMindBodySite.id} title="Verify Integration" submitText="Go to MINDBODY" onSubmit={() => {
          window.open(verifyMindBodySite.activation_link);
          this.setState({
            verifyMindBodySite: {}
          });
        }} onClose={() => this.setState({
          verifyMindBodySite: {}
        })} allowBackdropClick>
            <p>
              Site ID
              <strong className="pl-2">{verifyMindBodySite.site_id}</strong>
            </p>
            <p>
              To grant Cinch permission to access your MindBody account you must
              first verify the integration. To do this{' '}
              <a href={verifyMindBodySite.activation_link} target="_blank">
                Verify Integration
              </a>
            </p>
            <p>
              After verifying the integration press the "Check Verification"
              button
            </p>
            <p>
              For more information see this{' '}
              <a href="https://support.mindbodyonline.com/s/article/Setting-up-an-API-integration?language=en_US" target="_blank">
                MINDBODY support article
              </a>
            </p>
          </ModalDialog>
        </div>
      </Scoped>;
  }
}