import React, { Component } from 'react';
import { Scoped, k } from 'kremling';
import { PageHeader } from '../../components/page-header/page-header';
import { userState } from '../../shared/user-state';
import { Loader } from '../../components/loader/loader.component';
import { getAnalytics } from '../../shared/common.api';
import { DynamicTypeAhead } from '../../components/dynamic-type-ahead/dynamic-type-ahead.component';
export class Analytics extends Component {
  state = {
    isLoading: false,
    selectedIndex: 0,
    views: []
  };
  componentDidMount() {
    this.loadAnalytics();
  }
  loadAnalytics() {
    this.setState({
      isLoading: true
    });
    getAnalytics({
      company: userState.getAsCompanyId()
    }).then(({
      data
    }) => {
      let newState = {
        views: data.results,
        isLoading: false
      };
      if (data.results.length > 0) {
        newState.selectedIndex = data.results[0].id;
      }
      this.setState(newState);
    });
  }
  render() {
    const {
      isLoading,
      selectedIndex,
      views
    } = this.state;
    const selectedView = views.find(a => a.id === selectedIndex);
    return <Scoped css={css}>
        <div className="wrapper">
          <PageHeader name="Analytics" actions={<DynamicTypeAhead getItems={getAnalytics} getItemsFilters={{
          company: userState.getAsCompanyId()
        }} placeholder={selectedView ? `${selectedView.display_name}` : "Choose a View"} displayProperty="display_name" keyProperty="id" value={selectedView && selectedView.id} onChange={selectedIndex => selectedIndex ? this.setState({
          selectedIndex: selectedIndex.id
        }) : null} />} />
          <div className="wrapper-scroll analytics">
            {isLoading && <Loader overlay />}

            {!isLoading && views.length > 0 && <iframe src={selectedView.embed_url}></iframe>}
            {!isLoading && views.length === 0 && <p>Unable to find any views. Please try again later.</p>}
          </div>
        </div>
      </Scoped>;
  }
}
const css = {
  styles: `[kremling="i12"] body,body[kremling="i12"] {
  --color-primary: #487aae;
  --color-highlight: #e7efff;
  --color-accent: #354052;
  --color-success: #73b56e;
  --color-warning: #f4b707;
  --color-danger: #df5651;
  --color-grey-10: #f9f9f9;
  --color-grey-25: #f3f3f3;
  --color-grey-50: #e9e9e9;
  --color-grey-75: #e3e3e3;
  --color-grey-100: #d3d3d3;
  --color-grey-200: #c3c3c3;
  --color-grey-300: #b3b3b3;
  --color-grey-400: #a3a3a3;
  --color-grey-500: #808080;
  --color-grey-600: #707070;
  --color-grey-700: #606060;
  --color-grey-800: #505050;
  --color-grey-900: #404040;
  --base-font-family: Roboto, Helvetica, Arial, sans-serif;
  --base-font-size: 1.4rem;
  --base-font-color: #404040;
  --base-font-weight: 400;
  --base-line-height: 1.4;
  --box-shadow-1: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.12);
  --box-shadow-2: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-3: 0 10px 20px rgba(0, 0, 0, 0.17), 0 6px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-4: 0 14px 28px rgba(0, 0, 0, 0.13), 0 10px 10px rgba(0, 0, 0, 0.1);
  --box-shadow-5: 0 19px 38px rgba(0, 0, 0, 0.18), 0 15px 12px rgba(0, 0, 0, 0.1);
  --base-border-radius: 0.4rem;
}

[kremling="i12"] .analytics,[kremling="i12"].analytics {
  overflow: hidden;
}

[kremling="i12"] .analytics iframe,[kremling="i12"].analytics iframe {
  width: 100%;
  height: 100%;
  border: 0;
  position: absolute;
}

[kremling="i12"] .analytics p,[kremling="i12"].analytics p {
  width: 100%;
  margin-top: 20px;
  text-align: center;
}`,
  id: 'i12',
  namespace: 'kremling'
};