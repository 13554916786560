import React, { Component } from 'react';
import { func, object, shape, string } from 'prop-types';
import { getSegments, newSegment } from 'shared/common.api';
import { userState } from 'shared/user-state';
import { Button } from 'components/button/button.component';
import { DynamicTypeAhead } from '../../../../components/dynamic-type-ahead/dynamic-type-ahead.component';
import { Loader } from 'components/loader/loader.component';
const Styles = {
  textHelper: {
    textTransform: "none",
    padding: "8px",
    fontWeight: "400"
  },
  createButton: {
    display: "block",
    float: "right",
    paddingTop: "4px"
  }
};
export class ActionAddToSegment extends Component {
  static propTypes = {
    update: func,
    node: object,
    data: shape({
      segment_id: string
    })
  };
  state = {
    segmentList: [],
    isCreateNewSegment: false,
    loadingCreateSegment: false,
    newSegment: {
      name: "",
      description: ""
    }
  };
  componentDidMount() {
    getSegments({
      company: userState.getAsCompanyId(),
      time_dynamic_segment: false,
      limit: 200
    }).then(({
      data
    }) => {
      this.setState({
        segmentList: data.results
      });
    });
  }
  toggleSegment = id => {
    const {
      segment_id
    } = this.props.data;
    this.props.update({
      segment_id: !segment_id ? id : segment_id.id !== id ? id : null
    });
  };
  onChangeNewSegment = e => {
    this.setState({
      newSegment: {
        ...this.state.newSegment,
        [e.target.name]: e.target.value
      }
    });
  };
  createNewSegment = () => {
    this.setState({
      loadingCreateSegment: true
    });
    newSegment({
      ...this.state.newSegment,
      company: userState.getAsCompanyId()
    }).then(({
      data
    }) => {
      this.toggleSegment(data.id);
      getSegments({
        company: userState.getAsCompanyId(),
        time_dynamic_segment: false,
        limit: 200
      }).then(({
        data
      }) => {
        this.setState({
          segmentList: data.results,
          isCreateNewSegment: false,
          loadingCreateSegment: false,
          newSegment: {
            name: "",
            description: ""
          }
        });
      });
    }).catch(error => {
      this.setState({
        loadingCreateSegment: false
      });
    });
  };
  isNewSegmentValid = () => {
    const {
      newSegment
    } = this.state;
    if (newSegment.name.length === 0) {
      return true;
    }
    if (newSegment.description.length === 0) {
      return true;
    }
    return false;
  };
  render() {
    const {
      segmentList,
      isCreateNewSegment,
      newSegment,
      loadingCreateSegment
    } = this.state;
    const selectedSegment = segmentList.find(segment => segment.id === this.props.data.segment_id);
    return <div>
          <div className="form-group" style={{
        marginBottom: isCreateNewSegment ? "48px" : "8px"
      }}>
            <label>Static Segment</label>
            <DynamicTypeAhead getItems={getSegments} getItemsFilters={{
          company: userState.getAsCompanyId(),
          time_dynamic_segment: false
        }} placeholder={selectedSegment ? `${selectedSegment.name}` : "Choose Static Segment"} displayProperty="name" keyProperty="id" value={selectedSegment ? selectedSegment.id : null} onChange={segment => {
          this.toggleSegment(segment ? segment.id : null);
        }} />
            {isCreateNewSegment ? <div className="form-group mt-4 mb-2">
                      <label>New Static Segment</label>
                      <input type="text" className="form-control mb-2" name="name" value={newSegment.name || ""} placeholder="Segment Name" onChange={e => this.onChangeNewSegment(e)} />
                      <div className="form-control" style={{
            height: "auto",
            padding: "0px",
            border: "none"
          }}>
                          <textarea cols="3" name="description" className="form-control " placeholder="Input Segment Description" onChange={e => this.onChangeNewSegment(e)} value={newSegment.description || ""} />
                        <div style={Styles.createButton}>
                          <Button actionType="primary" className="mt-2" onClick={this.createNewSegment} disabled={loadingCreateSegment || this.isNewSegmentValid()}>
                            {loadingCreateSegment ? <Loader style={{
                  float: "right"
                }} size="sm" /> : "Create New Segment"}
                          </Button>
                        </div>

                      </div>

                    </div> : <label style={Styles.textHelper}>Or Create a <a onClick={() => this.setState({
            isCreateNewSegment: true
          })}>New Static Segment</a></label>}
          </div>
        </div>;
  }
}