import React, { useState, useContext } from 'react';
import moment from 'moment-timezone';
import { Button } from '../../button/button.component';
import { Scoped } from 'kremling';
import styles from './index.styles.scss';
import { WorkingAsContext } from 'context/working-as-context';
export function ActionComponent(props) {
  const {
    customerActions,
    goToTransaction
  } = props;
  const [expandedItems, setExpandedItems] = useState([]);
  const {
    hasIntegration
  } = useContext(WorkingAsContext);
  const toggleExpand = id => {
    const index = expandedItems.indexOf(id);
    if (index > -1) {
      expandedItems.splice(index, 1);
    } else {
      expandedItems.push(id);
    }
    setExpandedItems([...expandedItems]);
  };
  return <Scoped css={styles}>
      <div className="drawer__body" style={{
      height: 'calc(100vh - 9rem)',
      overflowY: 'auto'
    }}>
        <div className="d-flex justify-content-between align-items-center w-100">
          <div className="table-header pb-2">Schedules ({customerActions.schedules.length})</div>
          <Button icon={expandedItems.indexOf('schedules') > -1 ? 'fa-regular-minus' : 'fa-regular-plus'} small actionType="flat" onClick={() => toggleExpand('schedules')} />
        </div>

        {expandedItems.indexOf('schedules') > -1 && customerActions.schedules.map(ref => <div key={ref.id}>
              <div className="d-flex">
                <div className="truncate flex-grow">Location: {ref.location_name || <em>Unknown Location</em>}</div>
                <div className="text-center px-2" style={{
            flex: '0 0 150px'
          }}>
                  {moment(ref.appointment || ref.created_when).local().format('MMM D, YYYY h:mm A')}
                </div>
              </div>
              <hr />
            </div>)}

        {expandedItems.indexOf('schedules') > -1 && !customerActions.schedules.length && <i>Customer has not had any schedules.</i>}

        <div className="d-flex justify-content-between align-items-center w-100 mt-5">
          <div className="table-header pb-2">Transactions ({customerActions.transactions.length})</div>
          <Button icon={expandedItems.indexOf('transactions') > -1 ? 'fa-regular-minus' : 'fa-regular-plus'} small actionType="flat" onClick={() => toggleExpand('transactions')} />
        </div>

        {expandedItems.indexOf('transactions') > -1 && customerActions.transactions.map(ref => <div key={ref.id} className="hover" onClick={() => goToTransaction(ref.id)}>
              <div className="d-flex">
                <div className="truncate flex-grow">Invoice #{ref.entity_number || ''}</div>
                <div className="truncate flex-grow">${ref.total}</div>
                <div className="text-center px-2" style={{
            flex: '0 0 150px'
          }}>
                  {moment(ref.transaction_when || ref.created_when).local().format('MMM D, YYYY h:mm A')}
                </div>
              </div>
              <hr />
            </div>)}

        {expandedItems.indexOf('transactions') > -1 && !customerActions.transactions.length && <i>Customer has not had any transactions.</i>}

        <div className="d-flex justify-content-between align-items-center w-100 mt-5">
          <div className="table-header pb-2">Text Messages ({customerActions.sms.length})</div>
          <Button icon={expandedItems.indexOf('sms') > -1 ? 'fa-regular-minus' : 'fa-regular-plus'} small actionType="flat" onClick={() => toggleExpand('sms')} />
        </div>

        {expandedItems.indexOf('sms') > -1 && customerActions.sms.map(ref => <div key={ref.id}>
              <div className="d-flex">
                <div className="truncate flex-grow">{ref.message}</div>
                <div className="text-center px-2" style={{
            flex: '0 0 150px'
          }}>
                  {moment(ref.created_when).local().format('MMM D, YYYY h:mm A')}
                </div>
                <Button style={{
            flex: '0 0 22px'
          }} className="px-2" icon={expandedItems.indexOf(ref.id) > -1 ? 'fa-regular-minus' : 'fa-regular-plus'} small actionType="flat" onClick={() => toggleExpand(ref.id)} />
              </div>
              {expandedItems.indexOf(ref.id) > -1 && <div className="px-5 py-2 small">
                  {ref.message}
                  <br />

                  {ref.events.map(e => <div key={e.id} className="d-flex justify-content-between mt-2">
                      <div>{e.event.toUpperCase()}</div>
                      <div>{moment(e.created_when).local().format('MMM D, YYYY h:mm A')}</div>
                    </div>)}
                </div>}
              <hr />
            </div>)}

        {expandedItems.indexOf('sms') > -1 && !customerActions.sms.length && <i>Customer has not received any text messages.</i>}

        <div className="d-flex justify-content-between align-items-center w-100 mt-5">
          <div className="table-header pb-2">Email Messages ({customerActions.emails.length})</div>
          <Button icon={expandedItems.indexOf('emails') > -1 ? 'fa-regular-minus' : 'fa-regular-plus'} small actionType="flat" onClick={() => toggleExpand('emails')} />
        </div>

        {expandedItems.indexOf('emails') > -1 && customerActions.emails.map(ref => <div key={ref.id}>
              <div className="d-flex">
                <div className="truncate flex-grow">{ref.template_name}</div>
                <div className="text-center px-2" style={{
            flex: '0 0 150px'
          }}>
                  {moment(ref.created_when).local().format('MMM D, YYYY h:mm A')}
                </div>
                <Button style={{
            flex: '0 0 22px'
          }} className="px-2" icon={expandedItems.indexOf(ref.id) > -1 ? 'fa-regular-minus' : 'fa-regular-plus'} small actionType="flat" onClick={() => toggleExpand(ref.id)} />
              </div>
              {expandedItems.indexOf(ref.id) > -1 && <div className="px-5 py-2 small">
                  {ref.events.map(e => <div key={e.id} className="d-flex justify-content-between mt-2">
                      <div>{e.event.toUpperCase()}</div>
                      <div>{moment(e.created_when).local().format('MMM D, YYYY h:mm A')}</div>
                    </div>)}
                </div>}
              <hr />
            </div>)}

        {expandedItems.indexOf('emails') > -1 && !customerActions.emails.length && <i>Customer has not received any email messages.</i>}

        <div className="d-flex justify-content-between align-items-center w-100 mt-5">
          <div className="table-header pb-2">Mailers Sent ({customerActions.mailers.length})</div>
          <Button icon={expandedItems.indexOf('mailers') > -1 ? 'fa-regular-minus' : 'fa-regular-plus'} small actionType="flat" onClick={() => toggleExpand('mailers')} />
        </div>

        {expandedItems.indexOf('mailers') > -1 && customerActions.mailers.map(ref => <div key={ref.id}>
              <div className="d-flex">
                <div className="truncate flex-grow">{ref.template_name}</div>
                <div className="text-center px-2" style={{
            flex: '0 0 150px'
          }}>
                  {moment(ref.created_when).local().format('MMM D, YYYY h:mm A')}
                </div>
              </div>
              <hr />
            </div>)}

        {expandedItems.indexOf('mailers') > -1 && !customerActions.mailers.length && <i>Customer has not received any mailers.</i>}

        {hasIntegration('reviews') && <>
            <div className="d-flex justify-content-between align-items-center w-100 mt-5">
              <div className="table-header pb-2">Reviews ({customerActions.reviews.length})</div>
              <Button icon={expandedItems.indexOf('reviews') > -1 ? 'fa-regular-minus' : 'fa-regular-plus'} small actionType="flat" onClick={() => toggleExpand('reviews')} />
            </div>

            {expandedItems.indexOf('reviews') > -1 && customerActions.reviews.map(ref => <div key={ref.id}>
                  <div className="d-flex">
                    <div className="truncate flex-grow">
                      {ref.score} - {ref.comment || <i>No comment.</i>}
                    </div>
                    <div className="text-center px-2" style={{
              flex: '0 0 150px'
            }}>
                      {moment(ref.review_when).local().format('MMM D, YYYY h:mm A')}
                    </div>
                  </div>
                  <hr />
                </div>)}

            {expandedItems.indexOf('reviews') > -1 && !customerActions.reviews.length && <i>Customer has not left any reviews.</i>}
          </>}
      </div>
    </Scoped>;
}