import React, { Component } from 'react';
import { Scoped, k } from 'kremling';
import { ModalDialog } from '../../components/modal/modal-dialog.component';
import { Toggle } from '../../components/toggle/toggle.component';
import { Button } from '../../components/button/button.component';
import { PageHeader } from '../../components/page-header/page-header';
import { Loader } from '../../components/loader/loader.component';
import { IntegrationAdwords } from './integration-adwords.component';
import { IntegrationAgemni } from './integration-agemni.component';
import { IntegrationAircall } from './integration-aircall.component';
import { IntegrationFacebook } from './integration-facebook.component';
import { IntegrationForeUP } from './integration-foreup.component';
import { IntegrationNewStore } from './integration-newstore.component';
import { IntegrationLinkPOS } from './integration-linkpos.component';
import { IntegrationLoyalty } from './integration-loyalty.component';
import { IntegrationMindBody } from './integration-mindbody.component';
import { IntegrationOxiFresh } from './integration-oxifresh.component';
import { IntegrationShopify } from './integration-shopify.component';
import { IntegrationGoogle } from './integration-google.component';
import { IntegrationWoocommerce } from './integration-woocommerce.component';
import { IntegrationCallTools } from './integration-calltools.component';
import { IntegrationPestRoutes } from './integration-pestroutes.component';
import { IntegrationJiffyLube } from './integration-jiffylube.component';
import { IntegrationZapier } from './integration-zapier.component';
import { IntegrationAutoData } from './integration-auto-data.component';
import { IntegrationDynamic } from './integration-dynamic.component';
import { IntegrationGoogleBusiness } from './integration-google-business.component';
import { IntegrationOilChangeCalculator } from './integration-oilchange-calculator.component';
import { userState } from 'shared/user-state';
import { getCredentials, getInstalls, getIntegrations, deleteInstalls, createInstall } from './integrations.resource';
import { getPhoneNumbers } from 'shared/common.api';
import { sortBy } from 'lodash';
import { StaticTypeAhead } from 'components/static-type-ahead/static-type-ahead.componet';
import { snackbarService } from 'components/mui/snackbar/snackbar-service';
import validator from 'validator';
const tools = [{
  key: 'redirect_builder',
  name: 'Redirect Builder'
}];
export class IntegrationsList extends Component {
  static propTypes = {};
  state = {
    loading: true,
    loadingKeys: {
      adwords: true,
      facebook: true,
      foreup: true,
      newstore: true,
      linkpos: true,
      mindbody: true,
      oxifresh: !!userState.state.user.is_superuser,
      shopify: true,
      google: true,
      woocommerce: true,
      calltools: true,
      pestroutes: true,
      jiffylube: true,
      credentials: true
    },
    credentials: [],
    isSuperUser: userState.state.user.is_superuser,
    installedIntegrations: false,
    integrations: [],
    appManager: false,
    toolsManager: false,
    toolModal: '',
    numbers: [],
    selectedFrom: {},
    message: '',
    url: 'https://',
    validURL: '',
    encodedURL: 'Enter all fields first'
  };
  componentDidMount() {
    this.reloadCredentials();
    getIntegrations({
      limit: 1000
    }).then(({
      results
    }) => {
      this.setState({
        integrations: results.filter(integration => integration.status !== 'dev' && (userState.state.user.is_superuser || integration.status !== 'private'))
      });
    });
    getPhoneNumbers({
      company: userState.getAsCompanyId(),
      limit: 1000
    }).then(results => {
      this.setState({
        numbers: results.data.results
      });
    });
  }
  checkLoad = () => {
    if (this.state.installedIntegrations !== false && !Object.keys(this.state.installedIntegrations).some(key => this.state.loadingKeys[key])) {
      this.setState({
        loading: false
      });
    }
  };
  onLoad = key => () => {
    this.setState({
      loadingKeys: {
        ...this.state.loadingKeys,
        [key]: false
      }
    });
    this.checkLoad();
  };
  reloadCredentials = () => {
    getCredentials({
      company: userState.getAsCompanyId(),
      limit: 1000
    }).then(({
      results
    }) => {
      this.setState({
        credentials: results
      });
      this.onLoad('credentials')();
    });
    getInstalls({
      company: userState.getAsCompanyId(),
      limit: 1000
    }).then(({
      results
    }) => {
      const installedIntegrations = results.reduce((result, integration) => {
        result[integration.integration] = integration.id;
        return result;
      }, {});
      this.setState({
        installedIntegrations
      });
    });
  };
  openManageModal = () => {
    this.setState({
      appManager: {
        ...this.state.installedIntegrations
      }
    });
  };
  closeManageModal = () => {
    this.setState({
      appManager: false
    });
  };
  saveManageModal = () => {
    const keysToAdd = [];
    const idsToRemove = [];
    Object.keys(this.state.appManager).forEach(key => {
      if (!!this.state.installedIntegrations[key] && !this.state.appManager[key]) {
        idsToRemove.push(this.state.installedIntegrations[key]);
      } else if (!this.state.installedIntegrations[key] && !!this.state.appManager[key]) {
        keysToAdd.push(key);
      }
    });
    const promises = [];
    idsToRemove.forEach(id => {
      promises.push(deleteInstalls(id));
    });
    keysToAdd.forEach(integration => {
      promises.push(createInstall({
        integration,
        company: userState.getAsCompanyId()
      }));
    });
    Promise.all(promises).then(() => {
      this.reloadCredentials();

      // This is a non-descript way to tell the sidebar to recheck the installed integrations.
      // TODO make this better...
      userState.set({
        refresh: !userState.refresh
      });
    });
  };
  updateEncodedURL = () => {
    if (this.state.message && this.state.url && this.state.selectedFrom?.phone && validator.isURL(this.state.url)) {
      const link1 = 'sms:' + this.state.selectedFrom.phone + '%26body=' + encodeURIComponent(this.state.message);
      const link2 = 'sms:' + this.state.selectedFrom.phone + '?body=' + encodeURIComponent(this.state.message);
      const link3 = this.state.url;
      this.setState({
        encodedURL: 'https://engine.cinch.io/redirect?ios=' + link1 + '&android=' + link2 + '&web=' + link3
      });
    }
  };
  openToolsModal = () => {
    this.setState({
      toolsManager: true
    });
  };
  closeToolsModal = () => {
    this.setState({
      toolsManager: false
    });
  };
  closeToolModal = () => {
    this.setState({
      toolModal: false
    });
  };
  onSelectedFromChange = from => {
    this.setState({
      selectedFrom: from
    }, () => {
      this.updateEncodedURL();
    });
  };
  onMessageChange = e => {
    this.setState({
      message: e.target.value
    }, () => {
      this.updateEncodedURL();
    });
  };
  onUrlChange = e => {
    if (validator.isURL(this.state.url)) {
      this.setState({
        validURL: 'This is a valid URL',
        url: e.target.value
      }, () => {
        this.updateEncodedURL();
      });
    } else {
      this.setState({
        validURL: 'This is Not a valid URL',
        url: e.target.value
      }, () => {
        this.updateEncodedURL();
      });
    }
  };
  handleEncodeClick = () => {
    navigator.clipboard.writeText(this.state.encodedURL);
    snackbarService.popup({
      message: 'Encoded Url copied to clipboard',
      horizontal: 'center'
    });
  };
  render() {
    const {
      loading,
      isSuperUser,
      credentials,
      installedIntegrations,
      appManager,
      integrations,
      toolsManager,
      toolModal,
      numbers,
      selectedFrom,
      message,
      url,
      encodedURL,
      validURL
    } = this.state;
    const {
      match,
      location,
      history
    } = this.props;
    return <Scoped css={css}>
        {loading && <Loader overlay />}
        <div className="wrapper" style={{
        height: loading ? 0 : 'auto'
      }}>
          <PageHeader actions={<>
                <Button actionType="primary" onClick={this.openToolsModal}>
                  Tools
                </Button>
              {userState.hasPermission('integration.add_credential') && <Button actionType="primary" onClick={this.openManageModal}>
                  Manage Apps
                </Button>}
              </>} name="Integrations" />
          <div className="wrapper-scroll p-md">
            <IntegrationFacebook match={match} location={location} history={history} onLoad={this.onLoad('facebook')} />
            {installedIntegrations.foreup && <IntegrationForeUP onLoad={this.onLoad('foreup')} />}
            {installedIntegrations.newstore && <IntegrationNewStore onLoad={this.onLoad('newstore')} />}
            {installedIntegrations.linkpos && <IntegrationLinkPOS onLoad={this.onLoad('linkpos')} />}
            {installedIntegrations.mindbody && <IntegrationMindBody onLoad={this.onLoad('mindbody')} />}
            {isSuperUser && installedIntegrations.oxifresh && <IntegrationOxiFresh onLoad={this.onLoad('oxifresh')} />}
            {installedIntegrations.shopify && <IntegrationShopify match={match} location={location} history={history} onLoad={this.onLoad('shopify')} />}
            {installedIntegrations.google && <IntegrationGoogle match={match} location={location} history={history} onLoad={this.onLoad('google')} />}
            {installedIntegrations.woocommerce && <IntegrationWoocommerce match={match} location={location} history={history} onLoad={this.onLoad('woocommerce')} />}
            {installedIntegrations.calltools && <IntegrationCallTools onLoad={this.onLoad('calltools')} />}
            {installedIntegrations.pestroutes && <IntegrationPestRoutes onLoad={this.onLoad('pestroutes')} />}
            {installedIntegrations.jiffylube && <IntegrationJiffyLube onLoad={this.onLoad('jiffylube')} />}
            {installedIntegrations.zapier && <IntegrationZapier />}
            {installedIntegrations.adwords && <IntegrationAdwords match={match} location={location} history={history} onLoad={this.onLoad('adwords')} />}
            {installedIntegrations.agemni && <IntegrationAgemni reload={this.reloadCredentials} credentials={credentials.filter(c => c.integration === 'agemni')} />}
            {installedIntegrations.aircall && <IntegrationAircall reload={this.reloadCredentials} credentials={credentials.filter(c => c.integration === 'aircall')} />}
            {isSuperUser && installedIntegrations.autodata && <IntegrationAutoData reload={this.reloadCredentials} credentials={credentials.filter(c => c.integration === 'autodata')} />}
            {installedIntegrations.loyalty && <IntegrationLoyalty reload={this.reloadCredentials} credentials={credentials.filter(c => c.integration === 'loyalty')} />}
            {integrations.map(integration => installedIntegrations[integration.key] && integration.credential_schema && <IntegrationDynamic integration={integration} key={integration.key} reload={this.reloadCredentials} credentials={credentials.filter(c => c.integration === integration.key)} />)}
            {isSuperUser && installedIntegrations.oilchange && <IntegrationOilChangeCalculator />}
            {installedIntegrations.google_business && <IntegrationGoogleBusiness match={match} location={location} reload={this.reloadCredentials} credentials={credentials.filter(c => c.integration === 'google_business')} />}
          </div>

          <ModalDialog open={!!appManager} title="Manage Installed Apps" submitText="Done" onSubmit={this.saveManageModal} onClose={this.closeManageModal} allowBackdropClick type="scroll">
            <p>
              Toggle which apps you want displayed for your company on the
              integrations page. Hiding integrations disable the integration.
            </p>

            <table className="w-100">
              <tbody>
                {sortBy(integrations, 'name', 'desc').map(integration => <tr key={integration.key}>
                    <td>{integration.name}</td>
                    <td className="text-right">
                      <Toggle className="my-2" checked={!!appManager[integration.key]} onToggle={checked => this.setState({
                    appManager: {
                      ...appManager,
                      [integration.key]: checked
                    }
                  })} />
                    </td>
                  </tr>)}
              </tbody>
            </table>
          </ModalDialog>
          <ModalDialog open={!!toolsManager} title="Tools" cancelText="Close" onClose={this.closeToolsModal} allowBackdropClick type="scroll">
            <div className="w-100">
                {tools.map(tool => <div className="py-2" key={tool.key}>
                  <Button actionType="primary" onClick={e => {
                this.setState({
                  toolsManager: false,
                  toolModal: tool.key
                });
              }}>
                    {tool.name}
                  </Button>
                  </div>)}
            </div>
          </ModalDialog>
          <ModalDialog open={toolModal === 'redirect_builder'} title="Redirect Builder" cancelText="Close" onClose={this.closeToolModal} allowBackdropClick type="scroll">
            <div className="w-100">
              <p> This tool provides an easy way to build a redirect URL,
                  which will redirect a customer based
                  on the device (ios, android, web) they are using
              </p>
              <div className="modal__body">
                <div className="form-group">
                  <label>To Phone Number</label>
                  <StaticTypeAhead items={numbers} placeholder={selectedFrom ? selectedFrom.phone : 'Select a phone number'} displayProperty="phone" keyProperty="id" value={selectedFrom ? selectedFrom.id : ''} onChange={from => {
                  this.onSelectedFromChange(from);
                }} />
                </div>
                <div className="form-group">
                   <label>Text message body</label>
                  <textarea style={{
                  height: '6em'
                }} className="form-control" placeholder="Enter Message" onChange={this.onMessageChange} value={message || ''} />
                </div>
                <div className="form-group">
                   <label>Webpage URL (ex: https://www.example.com)</label>
                  <textarea className="form-control" style={{
                  height: '2.5em'
                }} placeholder="Enter URL" onChange={this.onUrlChange} value={url || ''} />
                   <label>{validURL}</label>
                </div>
                <div>
                <label>Your URL: (click to copy to clipboard)</label>

                <Button style={{
                  width: '75%'
                }} onClick={this.handleEncodeClick}>
                    <div style={{
                    whiteSpace: 'wrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    width: '225px'
                  }}>
                      {encodedURL}
                    </div>
                </Button>
                </div>
              </div>
            </div>
          </ModalDialog>

        </div>
      </Scoped>;
  }
}
const css = {
  styles: `[kremling="i9"] body,body[kremling="i9"] {
  --color-primary: #487aae;
  --color-highlight: #e7efff;
  --color-accent: #354052;
  --color-success: #73b56e;
  --color-warning: #f4b707;
  --color-danger: #df5651;
  --color-grey-10: #f9f9f9;
  --color-grey-25: #f3f3f3;
  --color-grey-50: #e9e9e9;
  --color-grey-75: #e3e3e3;
  --color-grey-100: #d3d3d3;
  --color-grey-200: #c3c3c3;
  --color-grey-300: #b3b3b3;
  --color-grey-400: #a3a3a3;
  --color-grey-500: #808080;
  --color-grey-600: #707070;
  --color-grey-700: #606060;
  --color-grey-800: #505050;
  --color-grey-900: #404040;
  --base-font-family: Roboto, Helvetica, Arial, sans-serif;
  --base-font-size: 1.4rem;
  --base-font-color: #404040;
  --base-font-weight: 400;
  --base-line-height: 1.4;
  --box-shadow-1: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.12);
  --box-shadow-2: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-3: 0 10px 20px rgba(0, 0, 0, 0.17), 0 6px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-4: 0 14px 28px rgba(0, 0, 0, 0.13), 0 10px 10px rgba(0, 0, 0, 0.1);
  --box-shadow-5: 0 19px 38px rgba(0, 0, 0, 0.18), 0 15px 12px rgba(0, 0, 0, 0.1);
  --base-border-radius: 0.4rem;
}

[kremling="i9"] .integration,[kremling="i9"].integration {
  border: solid 0.1rem var(--color-grey-100);
  border-radius: var(--base-border-radius);
  background-color: #fff;
  user-select: none;
}

[kremling="i9"] .integration__header,[kremling="i9"].integration__header {
  display: flex;
  min-width: 0;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 1.2rem;
  font-size: 1.6rem;
  font-weight: 600;
}

[kremling="i9"] .integration__body,[kremling="i9"].integration__body {
  background-color: var(--color-grey-10);
  border-bottom-left-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
}

[kremling="i9"] .integration-item,[kremling="i9"].integration-item {
  border-top: solid 0.1rem var(--color-grey-100);
}

[kremling="i9"] .integration-title,[kremling="i9"].integration-title {
  display: flex;
  align-items: center;
}
[kremling="i9"] .integration-title .caret,[kremling="i9"].integration-title .caret {
  margin-right: 0.8rem;
  transform: rotate(180deg);
  transition: transform 200ms ease;
}
[kremling="i9"] .integration-title .caret.caret--active,[kremling="i9"].integration-title .caret.caret--active {
  transform: rotate(0);
}

[kremling="i9"] .integration-item__inner,[kremling="i9"].integration-item__inner {
  padding: 0.8rem 1.2rem;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
[kremling="i9"] .integration-item__inner:hover,[kremling="i9"].integration-item__inner:hover {
  background-color: var(--color-grey-50);
  cursor: pointer;
}
[kremling="i9"] .integration-item__inner.active,[kremling="i9"].integration-item__inner.active {
  background-color: var(--color-highlight);
}

[kremling="i9"] .integration-accounts,[kremling="i9"].integration-accounts {
  padding: 1.6rem 2.4rem 2.4rem 2.4rem;
}

[kremling="i9"] .accounts,[kremling="i9"].accounts {
  table-layout: auto;
  width: 100%;
}
[kremling="i9"] .accounts>tbody>tr,[kremling="i9"].accounts>tbody>tr {
  border-top: solid 0.1rem var(--color-grey-100);
}
[kremling="i9"] .accounts td,[kremling="i9"] .accounts th,[kremling="i9"].accounts td,[kremling="i9"].accounts th {
  padding: 0.4rem 0 0.4rem 0.4rem;
}

[kremling="i9"] .accounts__connect,[kremling="i9"].accounts__connect {
  width: 28rem;
}
[kremling="i9"] .accounts__connect>*,[kremling="i9"].accounts__connect>* {
  margin-left: -1rem;
}

[kremling="i9"] .integration-header,[kremling="i9"].integration-header {
  border-top: solid 0.1rem var(--color-grey-100);
  background-color: #fff;
  padding: 0.4rem 1rem;
  font-size: 1.2rem;
  text-transform: uppercase;
  font-weight: 600;
  color: var(--color-grey-500);
}

[kremling="i9"] .integration-status,[kremling="i9"].integration-status {
  width: 1rem;
  height: 1rem;
  border: solid 0.1rem var(--color-grey-200);
  margin-right: 1.6rem;
  margin-left: 0.4rem;
  border-radius: 1rem;
  flex-grow: 0;
  flex-shrink: 0;
}

[kremling="i9"] .integration-status--active,[kremling="i9"].integration-status--active {
  border: none;
  background-color: var(--color-success);
}

[kremling="i9"] .i-subtitle,[kremling="i9"].i-subtitle {
  font-size: 1.3rem;
  color: var(--color-grey-500);
}

[kremling="i9"] .integration-actions,[kremling="i9"].integration-actions {
  display: flex;
  align-items: center;
}

[kremling="i9"] .integration__sync,[kremling="i9"].integration__sync {
  margin: 0 0.8rem 0 1.4rem;
  width: 3rem;
}

[kremling="i9"] .i-connected,[kremling="i9"].i-connected {
  font-size: 1.2rem;
  text-transform: uppercase;
  color: var(--color-grey-400);
}

[kremling="i9"] .wrong-source,[kremling="i9"].wrong-source {
  margin-bottom: 0.3rem;
  fill: #8a6d3b;
}`,
  id: 'i9',
  namespace: 'kremling'
};