import React, { useState, useEffect } from 'react';
import { Dropdown } from 'components/dropdown/dropdown.component';
import { Button } from 'components/button/button.component';
import { Icon } from 'components/icon/icon.component';
import { userState } from 'shared/user-state';
export const DynamicMultiTypeAhead = props => {
  const {
    param,
    update,
    data
  } = props;
  const {
    key,
    unitName,
    displayProperty,
    getItems,
    getItemExtraFilters
  } = param;
  const ids = data[key] || [];
  const [items, setItems] = useState([]);
  useEffect(() => {
    getItems({
      company: userState.getAsCompanyId(),
      limit: 200,
      ...(getItemExtraFilters || {})
    }).then(({
      data
    }) => {
      setItems(data.results);
    });
  }, []);
  const toggleItem = id => {
    let newIDs;
    if (ids.some(i => i === id)) {
      newIDs = ids.filter(i => i === id);
    } else {
      newIDs = [...ids, id];
    }
    update({
      [key]: newIDs
    });
  };

  // TODO Convert this to actually be dynamic type ahead instead of loading 200 records.

  if (items.length) {
    return <div className="form-group">
        <label>{param.label}</label>
        <Dropdown allowContentClicks size="block" contentHeight={250} trigger={() => <Button block dropdown>
              {!!ids.length ? <>
                  {ids.length} {unitName}
                  {ids.length === 0 || ids.length > 1 ? 's ' : ' '}
                  Selected
                </> : `All ${unitName}s`}
            </Button>} content={() => <ul className="select-list">
              <li>
                <a onClick={() => update({
            [key]: []
          })}>
                  <div>All {unitName}s</div>
                  {!ids.length && <Icon className="select-list__selected" size={14} name="fa-regular-check" />}
                </a>
              </li>
              {items.map(item => <li key={item.id}>
                  <a onClick={() => toggleItem(item.id)}>
                    <div>{item[displayProperty]}</div>
                    {ids.some(id => id === item.id) && <Icon className="select-list__selected" size={14} name="fa-regular-check" />}
                  </a>
                </li>)}
            </ul>} />
      </div>;
  }
  return <div className="form-group">
      <label>{param.label}</label>
      <Button disabled block>
        No {unitName}s Available
      </Button>
    </div>;
};