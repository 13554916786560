import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CssBaseline from '@mui/material/CssBaseline';
export default function ChangedWorkingAsModal(props) {
  const {
    newCompany,
    oldCompany,
    onDone,
    onAccept,
    onBack
  } = props;
  return <>
      {!!newCompany && <CssBaseline />}
      <Dialog BackdropProps={{
      style: {
        backdropFilter: 'blur(3px)'
      }
    }} PaperProps={{
      style: {
        borderRadius: '28px',
        minWidth: '400px'
      }
    }} open={!!newCompany} onClose={() => {
      onAccept();
    }}>
        <DialogTitle sx={{
        m: 0,
        p: 2,
        fontSize: 'medium'
      }}>
          <Stack alignItems="center" component="span">
            <ErrorOutlineIcon sx={{
            fontSize: 40,
            color: '#EF3C34'
          }} />
            <Typography variant="h4" component="span">
              {' '}
              Company Mismatch{' '}
            </Typography>
          </Stack>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            <Box component="span">
              <Stack>
                <Stack sx={{
                py: 1
              }} direction="row" spacing={1}>
                  <Typography component="span" sx={{
                  color: '#1D252D'
                }}>
                    You are currently working as:{' '}
                  </Typography>
                  <Typography variant="tableHeader">
                    {' '}
                    {oldCompany?.name}{' '}
                  </Typography>
                </Stack>
                <Stack sx={{
                py: 1
              }} direction="row" spacing={1}>
                  <Typography component="span" sx={{
                  color: '#1D252D'
                }}>
                    You have tried to use a page that belongs to:{' '}
                  </Typography>
                  <Typography variant="tableHeader">
                    {' '}
                    {newCompany?.name}{' '}
                  </Typography>
                </Stack>
                <Stack sx={{
                py: 1
              }} direction="row">
                  <Typography component="span" sx={{
                  color: '#1D252D'
                }}>
                    If you want to switch to{' '}
                  </Typography>
                  <Typography sx={{
                  px: '3px'
                }} variant="tableHeader">
                    {' '}
                    {newCompany?.name},{' '}
                  </Typography>
                  <Typography component="span" sx={{
                  color: '#1D252D'
                }}>
                    {' '}
                    select Proceed{' '}
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{
        py: 1
      }}>
          <Button onClick={() => {
          onBack();
          onDone();
        }} variant="text" sx={{
          borderRadius: '20px',
          color: 'rgba(29, 37, 45, 0.7)'
        }}>
            Go Back
          </Button>
          <Button onClick={() => {
          onAccept(newCompany);
          onDone();
        }} variant="text" sx={{
          mr: 1,
          borderRadius: '20px',
          width: '75px',
          color: '#53A6D6'
        }}>
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
    </>;
}