import React, { useState, useEffect, useCallback } from 'react';
import { Scoped } from 'kremling';
import classnames from 'classnames';
import { debounce } from 'lodash';
import styles from './phone-numbers.styles.scss';
import { PageHeader } from '../../components/page-header/page-header';
import { Loader } from '../../components/loader/loader.component';
import { Button } from '../../components/button/button.component';
import { ButtonGroup } from '../../components/button/button-group.component';
import { SortHeader } from '../../components/table/sort-header.component';
import { Dropdown } from '../../components/dropdown/dropdown.component';
import { Modal } from '../../components/modal/modal.component';
import { ModalDialog } from '../../components/modal/modal-dialog.component';
import { Pagination } from '../../components/pagination/pagination.component';
import { Banner } from '../../components/banner/banner.component';
import { toasterService } from '../../components/toaster/toaster-service';
import { getPhoneNumbers, deletePhoneNumber, updatePhoneNumber, getCompanyPhoneCount } from '../../shared/common.api';
import { userState } from '../../shared/user-state';
import utils from '../../shared/utils';
import { PhoneNumberModal } from './phone-number-modal.component';
export function PhoneNumbers() {
  const [deletePhoneModal, setDeletePhoneModal] = useState();
  const [renamePhoneModal, setRenamePhoneModal] = useState();
  const [loading, setLoading] = useState(true);
  const [ordering, setOrdering] = useState('name');
  const [paginationParams, setPaginationParams] = useState({});
  const [phoneNumberPagination, setPhoneNumberPagination] = useState({});
  const [customerCount, setCustomerCount] = useState(0);
  const [timeToSend, setTimeToSend] = useState(60);
  const [SMSMPM, setSMSMPM] = useState(60);
  const [MMSMPM, setMMSMPM] = useState(30);
  const [sendType, setSendType] = useState('sms');
  const [phoneNumbersNeeded, setPhoneNumbersNeeded] = useState(0);
  const [showCalcTool, setShowCalcTool] = useState(false);
  const [phoneNumberModal, setPhoneNumberModal] = useState(false);
  useEffect(() => {
    setPhoneNumbersNeeded(Math.ceil(customerCount / (timeToSend * (sendType === 'sms' ? SMSMPM : MMSMPM))));
  }, [customerCount, timeToSend, SMSMPM, MMSMPM, sendType]);
  const loadData = useCallback(() => {
    return getPhoneNumbers({
      company: userState.getAsCompanyId(),
      ordering,
      ...paginationParams
    }).then(({
      data
    }) => {
      setPhoneNumberPagination(data);
      setLoading(false);
    });
  }, [setLoading, setPhoneNumberPagination, ordering, paginationParams]);
  useEffect(() => {
    loadData();
    getCompanyPhoneCount(userState.getAsCompanyId()).then(({
      data
    }) => {
      setCustomerCount(data);
    });
  }, [loadData]);
  const onCreate = () => {
    setPhoneNumberModal(true);
  };
  const renameNumber = number => {
    updatePhoneNumber(number.id, {
      name: number.name
    }).then(() => loadData()).then(() => toasterService.success('Phone number successfully updated')).catch(() => {
      toasterService.error('Unable to update phone number. Please try again.');
    });
  };
  const releaseNumber = number => {
    deletePhoneNumber(number.id).then(() => loadData()).then(() => toasterService.success('Phone number successfully released')).catch(() => {
      toasterService.error('Unable to release phone number. Please try again.');
    });
  };
  if (loading) return <Loader overlay />;
  return <Scoped css={styles}>
      <div className="wrapper">
        <PageHeader name={`Phone Numbers ${phoneNumberPagination.count ? `(${utils.commaize(phoneNumberPagination.count)})` : ''}`} actions={userState.hasPermission('communication.add_phonenumber') && <Button actionType="primary" icon="fa-regular-plus" onClick={onCreate} />} />
        <div className="wrapper-scroll p-md">
          <Banner type={phoneNumbersNeeded <= phoneNumberPagination.count ? 'success' : 'warning'} onClick={() => setShowCalcTool(true)}>
            {phoneNumbersNeeded <= phoneNumberPagination.count && <>Based on your number of customers, you have at least the number of phone numbers we recommend.</>}
            {phoneNumbersNeeded > phoneNumberPagination.count && <>We recommend you have {phoneNumbersNeeded} phone numbers.</>}
          </Banner>
          <table className="table-list">
            <thead>
              <tr>
                <SortHeader name="name" ordering={ordering} update={ordering => setOrdering(ordering)}>
                  Name
                </SortHeader>
                <SortHeader name="phone" ordering={ordering} update={ordering => setOrdering(ordering)}>
                  Number
                </SortHeader>
                <SortHeader name="locality" ordering={ordering} update={ordering => setOrdering(ordering)}>
                  Location
                </SortHeader>
                <th style={{
                width: 40
              }} />
              </tr>
            </thead>
            <tbody>
              {phoneNumberPagination.results.length && phoneNumberPagination.results.map(phoneNumber => <tr key={phoneNumber.id}>
                    <td>{utils.formatPhoneNumber(phoneNumber.name)}</td>
                    <td>{utils.formatPhoneNumber(phoneNumber.phone)}</td>
                    <td>
                      {phoneNumber.locality} {phoneNumber.region} {phoneNumber.postal_code}
                    </td>
                    <td>
                      {userState.hasPermission('communication.delete_phonenumber') && <Dropdown horizontal="west" trigger={() => <Button actionType="flat" icon="fa-regular-ellipsis-h" />} content={() => <ul className="select-list">
                              <li>
                                <a onClick={() => setRenamePhoneModal(phoneNumber)}>Rename Phone Number</a>
                                <a onClick={() => setDeletePhoneModal(phoneNumber)}>Release Phone Number</a>
                              </li>
                            </ul>} />}
                    </td>
                  </tr>) || <tr>
                  <td colSpan={3}>
                    <strong>No Results</strong>
                  </td>
                </tr>}
            </tbody>
          </table>
          <Pagination data={phoneNumberPagination} onChange={setPaginationParams} />
        </div>

        <Modal open={showCalcTool} onClose={() => setShowCalcTool(false)} allowBackdropClick title="Phone Number Calculation Tool">
          <div className="modal__body">
            <div className="form-group">
              <label>Message Type</label>
              <ButtonGroup>
                <Button className={classnames({
                active: sendType === 'sms'
              })} actionType="white" onClick={() => setSendType('sms')}>
                  SMS
                </Button>
                <Button className={classnames({
                active: sendType === 'mms'
              })} actionType="white" onClick={() => setSendType('mms')}>
                  MMS
                </Button>
              </ButtonGroup>
            </div>
            <div className="form-group">
              <label>Number of Unique Phone Numbers</label>
              <input className="form-control" name="name" type="number" onChange={e => setCustomerCount(parseFloat(e.target.value))} value={customerCount} />
            </div>
            <div className="form-group">
              <label>Time to Send</label>
              <input className="form-control" name="name" type="number" onChange={e => setTimeToSend(parseFloat(e.target.value))} value={timeToSend} />
              <div className="text-sm mt-sm">Number of minutes to send a message to all of your customers</div>
            </div>
            <div className="form-group">
              <label>Max Message Per Minute SMS</label>
              <input className="form-control" name="name" type="number" onChange={e => setSMSMPM(parseFloat(e.target.value))} value={SMSMPM} />
              <div className="text-sm mt-sm">Number of messages each phone number can send per minute</div>
            </div>
            <div className="form-group">
              <label>Max Message Per Minute MMS</label>
              <input className="form-control" name="name" type="number" onChange={e => setMMSMPM(parseFloat(e.target.value))} value={MMSMPM} />
              <div className="text-sm mt-sm">Number of messages each phone number can send per minute</div>
            </div>
            <hr />
            <div className="form-group">
              <label>Number of Phone Numbers Needed</label>
              <input className="form-control" name="name" type="number" disabled={true} value={phoneNumbersNeeded} />
            </div>
          </div>
          <div className="modal__actions">
            <Button onClick={() => setShowCalcTool(false)}>Done</Button>
          </div>
        </Modal>
        <ModalDialog open={!!deletePhoneModal} title="Release Phone Number" submitText="Release" onSubmit={() => releaseNumber(deletePhoneModal)} onClose={() => setDeletePhoneModal(null)} actionType="warning" allowBackdropClick>
          <p>
            <strong>{!!deletePhoneModal && deletePhoneModal.phone}</strong>
          </p>
          <p>Are you sure you want to release this phone number? This action cannot be undone.</p>
        </ModalDialog>
        <ModalDialog open={!!renamePhoneModal} title="Rename Phone Number" onSubmit={() => renameNumber(renamePhoneModal)} onClose={() => setRenamePhoneModal(null)} allowBackdropClick>
          <p>This is a friendly name for the phone number and only impacts how the number is displayed in Cinch.</p>
          <div className="form-group">
            <label>New name for phone number</label>
            <input className="form-control" name="name" value={renamePhoneModal && renamePhoneModal.name || ''} onChange={e => setRenamePhoneModal({
            ...renamePhoneModal,
            name: e.target.value
          })} />
          </div>
        </ModalDialog>
        <PhoneNumberModal open={phoneNumberModal} loadData={loadData} onClose={() => setPhoneNumberModal(false)} />
      </div>
    </Scoped>;
}