import React from 'react';
import { Scoped } from 'kremling';
import styles from './files.styles.scss';
import { PageHeader } from '../../components/page-header/page-header';
import { FileViewer } from '../../components/file-viewer/file-viewer.component';
export function Files() {
  return <Scoped css={styles}>
      <div className="wrapper">
        <PageHeader name="Files" />
        <div className="wrapper-scroll files-list px-5">
          <p className="notice notice-warning my-4">Warning: All files uploaded here are publically available.  If they weren't they wouldn't appear in customers's emails.</p>

          <FileViewer />
        </div>
      </div>
    </Scoped>;
}
;