import React, { useState, useEffect, useContext } from 'react';
import { Scoped, m } from 'kremling';
import { patchSms, getSms, createSmsRevision, getSmsRevision, updateSmsRevision, getSegments, getCompanyFields, getPhoneNumbers, duplicateSms, deleteSms, getSmsBroadcasts, getJourneys, sendTestSmsMessage, generatePreview, getSegmentCustomers } from '../../shared/common.api';
import { userState } from '../../shared/user-state';
import { WorkingAsContext } from '../../context/working-as-context';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import { PageHeaderMui } from 'components';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { Input, TextField, Typography } from '@mui/material';
import { Icon } from '../../components/icon/icon.component';
import { withStyles } from '@mui/styles';
import { AntSwitch } from '../../components/mui/switch/ant-switch.component';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grid from '@mui/material/Unstable_Grid2';
import InputAdornment from '@mui/material/InputAdornment';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import Chip from '@mui/material/Chip';
import EmojiPicker from 'emoji-picker-react';
import { ModalFileViewer } from '../../components/file-viewer/modal-file-viewer.component';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import utils from '../../shared/utils';
import Tooltip from '@mui/material/Tooltip';
import InputPhone from 'react-phone-number-input/input';
import { isValidPhoneNumber, isPossiblePhoneNumber } from 'react-phone-number-input';
import styles from './edit.sms.styles.scss';
import { MuiLoader } from '../../components/loader/mui-Loader.component';
import moment from 'moment-timezone';
import { SegmentedMessage, GraphemeSplitter } from 'shared/segment-calculator';
import { Prompt } from 'react-router-dom';
import { snackbarService } from 'components/mui/snackbar/snackbar-service';
import { CustomerDrawerContext } from '../../context/customer-drawer-context';
const isEmail = email => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};
const StyledButton = withStyles({
  root: {
    backgroundColor: '#fff',
    color: '#53A6D6',
    borderRadius: '27px',
    textTransform: 'none',
    border: '1px solid #53A6D6',
    height: '32px',
    '&:hover': {
      backgroundColor: '#53A6D6',
      color: '#fff'
    }
  }
})(Button);
const RoundedTextField = withStyles({
  root: {
    backgroundColor: '#F1F3F4',
    height: '40px',
    borderRadius: `8px`,
    borderColor: '#F1F3F4',
    '& .MuiOutlinedInput-root': {
      height: '40px'
    }
  }
})(TextField);
const optionsMenu = [{
  name: 'Duplicate',
  icon: 'custom-file_copy'
}, {
  name: 'Archive',
  icon: 'custom-archive'
}, {
  name: 'Delete',
  icon: 'custom-delete'
}];
const SPLITTER = new GraphemeSplitter();
export const EditSmsTemplate = props => {
  const [sms, setSms] = useState({});
  const [revision, setRevision] = useState({});
  const [revisionID, setRevisionID] = useState();
  const {
    checkCompany
  } = useContext(WorkingAsContext);
  const [message, setMessage] = useState('');
  const [previewMessage, setPreviewMessage] = useState('');
  const [segmentsAnchorEl, setSegmentsAnchorEl] = React.useState(null);
  const segmentsOpen = Boolean(segmentsAnchorEl);
  const [segments, setSegments] = useState([]);
  const [segmentSearch, setSegmentSearch] = useState('');
  const [selectedSegment, setSelectedSegment] = useState(null);
  const [selectedSegmentCustomerId, setSelectedSegmentCustomerId] = useState(null);
  const [segmentSearchLoading, setSegmentSearchLoading] = useState(false);
  const [tagsAnchorEl, setTagsAnchorEl] = React.useState(null);
  const tagsOpen = Boolean(tagsAnchorEl);
  const [tagSubGroup, setTagSubGroup] = React.useState('');
  const [tagsSearch, setTagsSearch] = React.useState('');
  const [openAllTags, setOpenAllTags] = React.useState(false);
  const [emojiModal, setEmojiModal] = React.useState(false);
  const [addFileOpen, setAddFileOpen] = useState(false);
  const [media, setMedia] = React.useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [numbers, setNumbers] = useState([]);
  const [fields, setFields] = useState([]);
  const [phoneNumbersAnchorEl, setPhoneNumbersAnchorEl] = React.useState(null);
  const phoneNumbersOpen = Boolean(phoneNumbersAnchorEl);
  const [selectedSendFromPhoneNumber, setSelectedSendFromPhoneNumber] = React.useState(null);
  const [sendToPhoneNumber, setSendToPhoneNumber] = React.useState('');
  const [populatePreview, setPopulatePreview] = React.useState(false);
  const [optionsMenuAnchorEl, setOptionsMenuAnchorEl] = React.useState(null);
  const optionsMenuOpen = Boolean(optionsMenuAnchorEl);
  const [previewLoading, setPreviewLoading] = React.useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = React.useState(false);
  const [windowHeight, setWindowHeight] = React.useState(window.innerHeight);
  const messageInputRef = React.useRef();
  const [cursorLocation, setCursorLocation] = React.useState(0);
  const {
    openCustomerDrawer
  } = useContext(CustomerDrawerContext);
  const updateName = name => {
    patchSms(sms.id, {
      name
    }).then(data => {
      setSms(data.data);
    });
  };
  useEffect(() => {
    if (!revisionID) return;
    // LOAD REVISION DETAILS NOW!
    setIsLoading(true);
    getSmsRevision(revisionID).then(({
      data
    }) => {
      setRevision(data);
      setMessage(data.message || '');
      setMedia(data.media || []);
    });
  }, [revisionID]);
  const loadSms = id => {
    return getSms(id).then(({
      data
    }) => {
      // check for different company link
      return checkCompany(data.company).then(() => data);
    }).then(data => {
      const smsData = data;
      const isReadOnly = smsData.tags && smsData.tags.includes('READ-ONLY');
      const draft = (smsData.revisions || []).find(revision => !revision.published_when);
      const hasBeenPublished = !!(smsData.revisions || []).find(revision => !!revision.published_when);
      if (!hasBeenPublished && !isReadOnly && !draft) {
        // If we don't have a draft for this email (and this isn't a read only template) then create one.
        let promise = Promise.resolve();
        if (smsData.revisions && smsData.revisions.length > 0) {
          // If we have a previous revision set up the draft based off of that revision
          promise = promise.then(() => getSmsRevision(smsData.revisions[0].id)).then(({
            data
          }) => {
            delete data.id;
            delete data.published_when;
            return data;
          });
        } else {
          // if we don't have a previous revision then make an set up an empty one.
          promise = promise.then(() => ({
            sms: smsData.id
          }));
        }
        return promise.then(revision => createSmsRevision(revision)).then(({
          data
        }) => {
          if (!smsData.revisions) {
            smsData.revisions = [];
          }
          // Add new draft to top of revisions list.
          smsData.revisions.unshift(data);
          setSms(smsData);
          return {
            smsData,
            draft: data
          };
        });
      }
      setSms(smsData);
      if (isReadOnly || hasBeenPublished) {
        const revisionPublished = smsData.revisions.find(r => !!r.published_when);
        if (revisionPublished) {
          setRevisionID(revisionPublished.id);
        }
      }
      return {
        smsData,
        draft,
        isReadOnly
      };
    });
  };
  const handleSegmentClick = event => {
    setSegmentsAnchorEl(segmentsOpen ? null : event.currentTarget);
  };
  const handleSegmentClose = () => {
    setSegmentsAnchorEl(null);
  };
  useEffect(() => {
    setSegmentSearchLoading(true);
    getSegments({
      company: userState.getAsCompanyId(),
      limit: 100,
      search: segmentSearch
    }).then(({
      data
    }) => {
      setSegments(data.results);
      setSegmentSearchLoading(false);
    });
  }, [segmentSearch]);
  useEffect(() => {
    if (segments.length > 0 && revision && revision.preview_segment) {
      getSegmentCustomers(revision.preview_segment).then(data => {
        if (data.data.results.length > 0) {
          setSelectedSegmentCustomerId(data.data.results[0].id);
          setSelectedSegment(segments.find(seg => seg.id == revision.preview_segment));
          setPopulatePreview(true);
        } else {
          snackbarService.popup({
            message: revision.preview_segment.name + ' cannot be used because it is an empty dynamic segment. Please select a different one'
          });
        }
      });
    }
  }, [segments, revision]);
  useEffect(() => {
    const resizeListener = () => {
      setWindowHeight(window.innerHeight);
    };
    window.addEventListener('resize', resizeListener);
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);
  useEffect(() => {
    if (populatePreview && selectedSegmentCustomerId && message != '') {
      setPreviewLoading(true);
      const delayDebounceFn = setTimeout(() => {
        generatePreview({
          company: userState.getAsCompanyId(),
          customer: selectedSegmentCustomerId,
          message: message
        }).then(data => {
          setPreviewMessage(data.data);
          setPreviewLoading(false);
        });
      }, 2000);
      return () => clearTimeout(delayDebounceFn);
    } else {
      setPreviewMessage(message);
    }
  }, [message, populatePreview, selectedSegmentCustomerId]);
  useEffect(() => {
    const interval = setInterval(() => {
      if (hasUnsavedChanges) {
        handleSaveClick(true);
      }
    }, 60000);
    return () => clearInterval(interval);
  }, [hasUnsavedChanges]);
  const reset = () => {
    setSegmentSearch('');
  };
  const handleTagsClick = event => {
    setTagSubGroup(false);
    setTagsAnchorEl(tagsOpen ? null : event.currentTarget);
  };
  const handleTagsClose = () => {
    setTagsAnchorEl(null);
  };
  const handleEmojiClick = () => {
    setEmojiModal(!emojiModal);
  };
  const addFile = file => {
    if (media.find(m => m.Key === file.Key)) {
      snackbarService.popup({
        type: 'error',
        message: file.name + ' has already been attached'
      });
      return;
    }
    media.push(file);
  };
  useEffect(() => {
    setIsLoading(true);
    const {
      idEmailTemplate
    } = props.match.params;
    Promise.all([loadSms(idEmailTemplate), getCompanyFields(userState.getAsCompanyId()), getPhoneNumbers({
      company: userState.getAsCompanyId(),
      limit: 100
    })]).then(results => {
      const [{
        draft,
        isReadOnly
      }, fields, numbers] = results;
      if (isReadOnly) return;
      const numbersWithFormat = numbers.data.results.map(number => {
        let numberFormat = {
          ...number
        };
        numberFormat.phone = utils.formatPhoneNumber(numberFormat.phone);
        return numberFormat;
      });
      setFields(fields.data);
      setNumbers(numbersWithFormat);
      if (!revisionID) {
        setRevisionID(draft?.id);
      }
      setIsLoading(false);
    });
  }, []);
  const handlePhoneNumbersClose = () => {
    setPhoneNumbersAnchorEl(null);
  };
  const handlePhoneNumbersClick = event => {
    setPhoneNumbersAnchorEl(phoneNumbersOpen ? null : event.currentTarget);
  };
  const handleOptionsMenuClose = () => {
    setOptionsMenuAnchorEl(null);
  };
  const handleOptionsMenuClick = event => {
    setOptionsMenuAnchorEl(optionsMenuOpen ? null : event.currentTarget);
  };
  const getFieldIcon = field => {
    switch (field) {
      case 'customer':
        return 'custom-person';
      case 'email_engagement':
        return 'custom-mark_email_read';
      case 'sms_optstatus':
        return 'custom-sms';
      case 'transaction':
        return 'custom-receipt';
      case 'transaction_detail':
        return 'custom-receipt_long';
      case 'coupon':
        return 'custom-local_atm';
      case 'cart':
        return 'custom-shopping_cart';
      case 'cart_detail':
        return 'custom-shopping_cart_checkout';
      case 'cart_coupon':
        return 'custom-shopping_cart_checkout_coupon';
      case 'vehicle':
        return 'custom-directions_car';
      case 'subscription':
        return 'custom-subscriptions';
      case 'animal':
        return 'custom-pets';
      case 'real_estate':
        return 'custom-real_estate_agent';
      case 'coupon_possession':
        return 'custom-sell';
      case 'schedule':
        return 'custom-schedule';
      case 'review':
        return 'custom-reviews';
      default:
        return 'custom-person';
    }
  };
  const handleSaveClick = autoSave => {
    setIsLoading(true);
    updateSmsRevision(revisionID, {
      message: message,
      media: media,
      preview_segment: selectedSegment?.id
    }).then(() => {
      setHasUnsavedChanges(false);
      snackbarService.popup({
        message: autoSave ? sms.name + ' has been autosaved' : sms.name + ' has been saved'
      });
    });
  };
  const handleSaveAndCloseClick = () => {
    updateSmsRevision(revisionID, {
      message: message,
      media: media,
      preview_segment: selectedSegment?.id
    }).then(() => {
      setHasUnsavedChanges(false);
      snackbarService.popup({
        type: 'success',
        message: sms.name + ' has been saved'
      });
      props.history.push({
        pathname: '/templates'
      });
    });
  };
  const handlePublishClick = () => {
    updateSmsRevision(revisionID, {
      message: message,
      media: media,
      preview_segment: selectedSegment?.id,
      published_when: new Date()
    }).then(revi => {
      let tempRev = revi.data;
      delete tempRev.id;
      delete tempRev.published_when;
      createSmsRevision(tempRev).then(({
        data
      }) => {
        setHasUnsavedChanges(false);
        loadSms(sms.id).then(s => {
          setSms(s.smsData);
          setRevision(s.smsData.revisions[1]);
        });
        snackbarService.popup({
          type: 'success',
          message: sms.name + ' has been published'
        });
      });
    });
  };
  const handleRevisionSelectChange = e => {
    setRevisionID(e.target.value);
  };
  const handleCloseClick = () => {
    if (props?.location?.return) {
      if (props.location.returnParams) {
        props.history.push({
          pathname: props?.location?.return,
          search: props?.location?.returnParams
        });
        const queryParams = new URLSearchParams(props?.location?.returnParams);
        openCustomerDrawer(queryParams.get('customer_drawer_id'));
      } else {
        props.history.push({
          pathname: props?.location?.return
        });
      }
    } else {
      props.history.push({
        pathname: '/templates'
      });
    }
  };
  const handleEditClick = () => {
    setRevisionID(sms.revisions[0].id);
  };
  const handleDuplicateClick = () => {
    updateSmsRevision(revisionID, {
      message: message,
      media: media,
      preview_segment: selectedSegment?.id
    }).then(() => {
      setHasUnsavedChanges(false);
      duplicateSms(sms.id).then(({
        data
      }) => {
        setSms(data);
        props.history.push(`/templates/edit/sms/${data.id}`);
      });
    });
  };
  const handleArchiveClick = () => {
    //TODO: check archive conditions first, and add a second snackbar toast callback to list page
    const promises = [getSmsBroadcasts({
      company: userState.getAsCompanyId(),
      sms_template: sms.id,
      broadcast_when: false
    }), getJourneys({
      company: userState.getAsCompanyId(),
      node_template: sms.id,
      archived: false
    })];
    Promise.all(promises).then(([data, companies]) => {
      if (!data.data.results.length || !companies.data.results.length) {
        patchSms(sms.id, {
          archived_when: new Date()
        }).then(() => {
          snackbarService.popup({
            type: 'success',
            message: sms.name + ' has been archived'
          });
          props.history.push({
            pathname: '/templates'
          });
        });
      } else {
        snackbarService.popup({
          type: 'error',
          message: 'This template cant be archived because it is in use'
        });
      }
    });
  };
  const handleDeleteClick = () => {
    //TODO: check delete conditions first
    const promises = [getSmsBroadcasts({
      company: userState.getAsCompanyId(),
      sms_template: sms.id,
      broadcast_when: false
    }), getJourneys({
      company: userState.getAsCompanyId(),
      node_template: sms.id,
      archived: false
    })];
    Promise.all(promises).then(([data, companies]) => {
      if (!data.data.results.length || !companies.data.results.length) {
        deleteSms(sms.id).then(() => {
          snackbarService.popup({
            type: 'success',
            message: sms.name + ' has been deleted'
          });
          props.history.push({
            pathname: '/templates'
          });
        });
      } else {
        snackbarService.popup({
          type: 'error',
          message: 'This template cant be deleted because it is in use'
        });
      }
    });
  };
  const sentTestMessage = () => {
    sendTestSmsMessage({
      company: userState.getAsCompanyId(),
      customer: populatePreview ? selectedSegmentCustomerId : null,
      sendToPhoneNumber: sendToPhoneNumber,
      message: message + '\n\nReply STOP to cancel',
      sendfromPhoneNumber: selectedSendFromPhoneNumber.id,
      attachments: media.map(a => a.url)
    }).then(({
      data
    }) => {
      snackbarService.popup({
        type: 'success',
        message: 'Test Message successfuly sent to ' + sendToPhoneNumber
      });
    }).catch(() => {
      snackbarService.popup({
        type: 'error',
        message: 'Test Message Failed to send'
      });
    });
  };
  const handleSendTestClick = () => {
    setIsLoading(true);
    if (!revision.published_when) {
      updateSmsRevision(revisionID, {
        message: message,
        media: media
      }).then(() => {
        sentTestMessage();
      });
    } else {
      sentTestMessage();
    }
  };
  const getMessageCount = () => {
    const fullMessage = message + '\nReply STOP to cancel';
    const segmentedMessage = new SegmentedMessage(fullMessage, SPLITTER);
    const charactersValue = `${SPLITTER.countGraphemes(fullMessage)}`;
    let segmentsValues = `${segmentedMessage.segments.length}`;
    segmentsValues += ' segment';
    if (segmentedMessage.segments.length != 1) {
      segmentsValues += 's';
    }
    return <Typography sx={{
      marginTop: '4px',
      pr: 1,
      color: '#1D252D80',
      fontSize: 10
    }}>
        {charactersValue} characters = {segmentsValues}
      </Typography>;
  };
  return <Scoped css={styles}>
      <CssBaseline />
      {isLoading && <MuiLoader />}
      <div className="wrapper" style={{
      backgroundColor: '#DAEFFF'
    }}>
        <PageHeaderMui type="Templates" name={sms.name} icon={<Icon name="custom-sms-template" size={34} />} updateName={updateName} subName={<Stack direction="row">
              <Icon name="custom-chat-bubble" size={24} />
              <Typography sx={{
          fontSize: 24,
          pl: '24px',
          mt: '3px'
        }}> Text Message</Typography>
            </Stack>} />
        <div className="mui-wrapper">
          <Box sx={{
          m: '20px'
        }}>
            <Paper sx={{
            width: '100%',
            borderRadius: '14px'
          }}>
              <Toolbar variant="dense" sx={{
              minHeight: '56px',
              pl: {
                sm: 2
              },
              pr: {
                xs: 1,
                sm: 1
              },
              borderBottom: '1px solid #E2E2E2'
            }}>
                <FormControl sx={{
                m: 1,
                width: 290,
                height: '32px'
              }}>
                  <Select sx={{
                  borderRadius: '8px',
                  height: '32px'
                }} labelId="demo-simple-select-label" value={revision.id || ''} onChange={handleRevisionSelectChange} IconComponent={props => <span style={{
                  position: 'relative',
                  bottom: '2px'
                }}>
                        <Icon name="custom-keyboard-arrow-down" {...props} />
                      </span>}>
                    {sms?.revisions?.map(rev => <MenuItem key={rev.id} value={rev.id || ''}>
                        <Stack direction="row" spacing={1}>
                          <Icon name={rev.published_when ? 'custom-published' : 'custom-draft'} size={20} />
                          <span>
                            {rev.published_when ? `Published ${moment(rev.published_when).format('MMM D, YYYY h:mm A')}` : 'Draft'}
                          </span>
                        </Stack>
                      </MenuItem>)}
                  </Select>
                </FormControl>

                <Box sx={{
                flexGrow: 1
              }} />
                {revision.published_when ? <>
                    <Box sx={{
                  px: '16px'
                }}>
                      <StyledButton onClick={handleCloseClick} variant="outlined">
                        <Icon name="custom-cancel" size={14} text="Close" />
                      </StyledButton>
                    </Box>
                    <Box sx={{
                  px: '16px'
                }}>
                      <StyledButton onClick={handleEditClick} variant="outlined">
                        <Icon name="custom-edit_square" size={14} text="Edit" />
                      </StyledButton>
                    </Box>
                  </> : <>
                    <Box sx={{
                  px: '16px'
                }}>
                      <StyledButton onClick={handleSaveAndCloseClick} variant="outlined">
                        Save & close
                      </StyledButton>
                    </Box>
                    <Box sx={{
                  px: '16px'
                }}>
                      <StyledButton onClick={e => {
                    handleSaveClick(false);
                  }} variant="outlined">
                        <Icon name="custom-save" size={14} text="Save" />
                      </StyledButton>
                    </Box>
                    <Box sx={{
                  px: '16px'
                }}>
                      <StyledButton onClick={handlePublishClick} variant="outlined">
                        <Icon name="custom-publish" size={14} text="Publish" />
                      </StyledButton>
                    </Box>
                  </>}
                <Box sx={{
                pr: '16px'
              }}>
                  <IconButton sx={{
                  width: '24px',
                  height: '24px',
                  textAlign: 'center',
                  padding: 0,
                  backgroundColor: optionsMenuOpen ? '#DAEFFF' : '#fff',
                  color: optionsMenuOpen ? '#53A6D6' : '#1D252D',
                  ':hover': {
                    backgroundColor: '#DAEFFF',
                    color: '#53A6D6'
                  }
                }} onClick={handleOptionsMenuClick}>
                    <Icon name="custom-more_vert" />
                  </IconButton>
                </Box>
              </Toolbar>
              <Divider />
              <Box>
                <Stack sx={{
                display: 'flex',
                px: 2,
                pt: 2
              }}>
                  <Box sx={{
                  display: 'flex'
                }} className="d-flex w-100 justify-content-between">
                    <Typography variant="h5" sx={{
                    pl: 3,
                    pb: 2,
                    flexGrow: 1
                  }}>
                      Message Details
                    </Typography>
                    <Typography variant="h5" sx={{
                    pl: 3,
                    pb: 2,
                    flexGrow: 1
                  }}>
                      Message preview
                    </Typography>
                  </Box>
                  <Box sx={{
                  flexGrow: 1,
                  display: 'flex',
                  height: '65vh',
                  px: '20px'
                }} className="d-flex justify-content-between">
                    <Box sx={{
                    p: 2,
                    border: '1px solid #BEBEBE',
                    width: '100%',
                    paddingLeft: '20px',
                    borderRadius: '14px'
                  }}>
                      <Box sx={{
                      height: '90%',
                      maxHeight: '90%'
                    }}>
                        <Input multiline maxRows={(windowHeight - 200) / 30} minRows={3} fullWidth autoFocus disableUnderline placeholder="Message text" sx={{
                        caretColor: '#53A6D6'
                      }} value={message} disabled={!!revision.published_when} onChange={e => {
                        setHasUnsavedChanges(true);
                        setMessage(e.target.value);
                      }} ref={messageInputRef} onBlur={e => {
                        setCursorLocation(e?.target?.selectionStart);
                      }}></Input>
                        <Stack direction="row" sx={{
                        border: '1px dashed #BCBCBC'
                      }}>
                          <Typography sx={{
                          color: '#E48D4B',
                          pl: 1,
                          pr: 2
                        }}>
                            Reply STOP to cancel
                          </Typography>
                          <Typography sx={{
                          color: 'rgba(29, 37, 45, 0.5)'
                        }}>
                            &#40; Automatically populates two lines below message text when sent &#41;
                          </Typography>
                        </Stack>
                      </Box>
                      <Divider flexItem />
                      <Box sx={{
                      height: '10%',
                      alignItems: 'center',
                      display: 'flex'
                    }}>
                        <Stack direction="row" sx={{}} spacing={2}>
                          {media.map(file => <Chip key={file.key} label=<span>
                                {file.name} &#40;{file.formatedSize}&#41;
                              </span> onDelete={() => {
                          setMedia(cs => cs.filter(c => c.Key !== file.Key));
                        }} deleteIcon={<CloseOutlinedIcon sx={{
                          transform: 'scale(0.75)'
                        }} />} sx={{
                          backgroundColor: '#DAEFFF',
                          color: '#53A6D6',
                          borderRadius: '8px',
                          textTransform: 'none'
                        }} />)}
                        </Stack>
                      </Box>
                    </Box>
                    <Divider sx={{
                    px: '10px'
                  }} orientation="vertical" />
                    <Box sx={{
                    p: 2,
                    border: '1px solid #BEBEBE',
                    width: '100%',
                    ml: '20px',
                    borderRadius: '14px'
                  }}>
                      <Box sx={{
                      display: 'flex',
                      overflowY: 'auto',
                      flexDirection: 'column-reverse',
                      height: '90%',
                      maxHeight: '90%'
                    }}>
                        <Box sx={{
                        mb: '30px',
                        width: '55%',
                        background: '#53A6D6',
                        color: '#fff',
                        padding: '10px 20px',
                        borderRadius: '8px',
                        whiteSpace: 'pre-wrap',
                        position: 'relative',
                        '&::after': {
                          backgroundColor: '#53A6D6',
                          content: '""',
                          display: 'block',
                          position: 'absolute',
                          width: 16,
                          height: 16,
                          bottom: -6,
                          transform: 'rotate(45deg)',
                          left: '7px'
                        }
                      }}>
                          {previewLoading ? <Box sx={{
                          display: 'flex',
                          alignContent: 'center',
                          margin: 'auto',
                          alignItems: 'center',
                          pl: '130px'
                        }}>
                              <CircularProgress color="white" />
                            </Box> : <Box>
                              {previewMessage ? previewMessage + '\n\nReply STOP to cancel' : 'Enter message text to the left to display preview...'}
                            </Box>}
                        </Box>
                        <Box>
                          {media.map(chip => <Box sx={{
                          py: 2
                        }}>
                              <Box key={chip.key} sx={{
                            flexGrow: 1,
                            width: '55%',
                            background: '#D9D9D9',
                            color: '#1D252D',
                            padding: '10px 20px',
                            borderRadius: '8px',
                            whiteSpace: 'pre-wrap',
                            position: 'relative'
                          }}>
                                {chip.name} &#40;{chip.formatedSize}&#41;
                              </Box>
                            </Box>)}
                        </Box>
                      </Box>
                      <Divider flexItem />
                      <Box sx={{
                      height: '10%',
                      maxHeight: '10%',
                      alignItems: 'center',
                      display: 'flex',
                      margin: 'auto'
                    }}>
                        <Stack direction="row" sx={{}} spacing={2}>
                          <Box>
                            <Box sx={{
                            height: '32px',
                            borderRadius: '8px',
                            border: '1px solid #BEBEBE',
                            display: 'flex',
                            margin: 'auto',
                            alignItems: 'center',
                            cursor: 'pointer'
                          }} onClick={handlePhoneNumbersClick}>
                              <Box display="flex" flexDirection="row" justifyContent="space-around" sx={{
                              width: '100%',
                              color: phoneNumbersOpen ? '#53A6D6' : '#1D252D'
                            }}>
                                <Box sx={{
                                flexShrink: 1,
                                pl: 1
                              }}>
                                  <Icon name="custom-send_to_mobile" size={24} />
                                </Box>
                                <Box sx={{
                                flexGrow: 5,
                                width: '100%',
                                alignContent: 'center',
                                margin: 'auto',
                                alignItems: 'center'
                              }}>
                                  <Box sx={{
                                  display: 'flex',
                                  alignContent: 'center',
                                  margin: 'auto',
                                  alignItems: 'center'
                                }}>
                                    <Typography sx={{
                                    color: phoneNumbersOpen ? '#53A6D6' : '#1D252D',
                                    alignContent: 'center',
                                    margin: 'auto',
                                    alignItems: 'center'
                                  }}>
                                      {selectedSendFromPhoneNumber ? selectedSendFromPhoneNumber.phone : 'Send from'}
                                    </Typography>
                                  </Box>
                                </Box>
                                {phoneNumbersOpen ? <Box sx={{
                                pl: 1,
                                flexShrink: 1,
                                transform: 'rotate(180deg)',
                                alignContent: 'center',
                                margin: 'auto',
                                alignItems: 'center'
                              }}>
                                    <Icon name="custom-keyboard-arrow-down" size={20} />
                                  </Box> : <Box sx={{
                                flexShrink: 1,
                                alignContent: 'center',
                                margin: 'auto',
                                alignItems: 'center',
                                pl: 1
                              }}>
                                    <Icon name="custom-keyboard-arrow-down" size={20} />
                                  </Box>}
                              </Box>
                            </Box>
                          </Box>
                          <Box sx={{
                          transform: 'rotate(90deg)'
                        }}>
                            <Icon name="custom-more_vert"></Icon>
                          </Box>
                          <Tooltip title={<div style={{
                          whiteSpace: 'pre-line'
                        }}>
                                <div> Only enter 10 or 11 digits </div>
                                <div>
                                  {' '}
                                  {sendToPhoneNumber ? isPossiblePhoneNumber(sendToPhoneNumber) ? 'This is a valid phone number' : 'This is not a valid phone number' : ''}
                                </div>
                              </div>}>
                            <InputPhone placeholder="Send to..." className={'react-tel-input'} value={sendToPhoneNumber} country="US" error={sendToPhoneNumber ? isValidPhoneNumber(sendToPhoneNumber) ? undefined : 'Invalid phone number' : 'Phone number required'} onChange={e => {
                            setSendToPhoneNumber(e);
                          }} />
                          </Tooltip>
                          <Tooltip disableHoverListener={selectedSendFromPhoneNumber && sendToPhoneNumber && isPossiblePhoneNumber(sendToPhoneNumber) && message} title={<div style={{
                          whiteSpace: 'pre-line'
                        }}>
                                {!selectedSendFromPhoneNumber && <div> Select a Send From number </div>}
                                {!sendToPhoneNumber && <div> Enter a Send To number </div>}
                                {sendToPhoneNumber && !isPossiblePhoneNumber(sendToPhoneNumber) && <div> Enter a valid Send To number </div>}
                                {!message && <div> Enter a message </div>}
                              </div>}>
                            <span>
                              <StyledButton disabled={!selectedSendFromPhoneNumber || !sendToPhoneNumber || !isPossiblePhoneNumber(sendToPhoneNumber) || !message} onClick={handleSendTestClick}>
                                Send Test
                              </StyledButton>
                            </span>
                          </Tooltip>
                        </Stack>
                      </Box>
                    </Box>
                  </Box>
                  <Box sx={{
                  display: 'flex',
                  width: '100%'
                }} className="d-flex w-100 justify-content-between">
                    <Box sx={{
                    px: 3,
                    pb: 2,
                    width: '50%'
                  }}>
                      <Stack>
                        <Stack direction="row">
                          <Box sx={{
                          flexGrow: 1
                        }} />
                          <Stack direction="row">
                            {media?.length == 0 ? getMessageCount() : <Typography sx={{
                            marginTop: '4px',
                            pr: 1,
                            color: '#1D252D80',
                            fontSize: 10
                          }}>
                                {(message || '').length + 21}* out of 1,600 characters
                              </Typography>}
                            <Tooltip title="SMS Segments
                                  SMS (aka text) messages are billed per segment.
                                  A single segment is typically 160 characters, but if you're using special characters,
                                  like emoji it may be less. You can send multiple segments (really as many as you like)
                                  but for billing purposes, each segment counts as a message sent.">
                              <Box sx={{
                              color: '#53A6D6'
                            }}>
                                <Icon name="custom-help" />
                              </Box>
                            </Tooltip>
                          </Stack>
                        </Stack>
                        <Stack direction="row">
                          <Stack sx={{
                          position: 'relative',
                          bottom: '5px'
                        }} direction="row" spacing={1}>
                            <IconButton sx={{
                            backgroundColor: tagsOpen ? '#DAEFFF' : '#fff',
                            color: tagsOpen ? '#53A6D6' : '#1D252D',
                            ':hover': {
                              backgroundColor: '#DAEFFF',
                              color: '#53A6D6'
                            }
                          }} onClick={handleTagsClick} disabled={!!revision.published_when}>
                              <Icon name="custom-tags" />
                            </IconButton>
                            <IconButton sx={{
                            backgroundColor: emojiModal ? '#DAEFFF' : '#fff',
                            color: emojiModal ? '#53A6D6' : '#1D252D',
                            ':hover': {
                              backgroundColor: '#DAEFFF',
                              color: '#53A6D6'
                            }
                          }} onClick={handleEmojiClick} disabled={!!revision.published_when}>
                              <Icon name="custom-mood" />{' '}
                            </IconButton>
                            <IconButton sx={{
                            backgroundColor: addFileOpen ? '#DAEFFF' : '#fff',
                            color: addFileOpen ? '#53A6D6' : '#1D252D',
                            ':hover': {
                              backgroundColor: '#DAEFFF',
                              color: '#53A6D6'
                            }
                          }} onClick={() => setAddFileOpen(true)} disabled={!!revision.published_when}>
                              <Icon name="custom-attachment" />
                            </IconButton>
                          </Stack>
                          <Box sx={{
                          flexGrow: 1
                        }} />
                          {message.includes('{{{') && <Box>
                              <Stack direction="row">
                                <Box sx={{
                              color: '#FCCD6F'
                            }}>
                                  {' '}
                                  <Icon name="custom-warning"></Icon>{' '}
                                </Box>
                                <Typography sx={{
                              marginTop: '4px',
                              pl: 1,
                              color: '#1D252D80',
                              fontSize: 10
                            }}>
                                  Message contains variable data which may increase the size of the message
                                </Typography>
                              </Stack>
                            </Box>}
                        </Stack>
                      </Stack>
                    </Box>
                    <Box sx={{
                    display: 'flex',
                    px: 3,
                    pb: 2,
                    flexGrow: 1
                  }}>
                      <Stack direction="row" sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexGrow: 1
                    }}>
                        <Stack direction="row">
                          <FormControl>
                            <AntSwitch sx={{
                            ml: '8px',
                            colorPrimary: '#53A6D6'
                          }} checked={populatePreview} onChange={() => {
                            setPopulatePreview(!populatePreview);
                          }} inputProps={{
                            'aria-label': 'ant design'
                          }} />
                          </FormControl>
                          <Typography sx={{
                          pl: '10px'
                        }}> Populate preview with customer data</Typography>
                        </Stack>
                        <Box sx={{
                        flexGrow: 1
                      }} />
                        <Box sx={{
                        height: '32px',
                        minWidth: '210px',
                        borderRadius: '8px',
                        border: '1px solid #BEBEBE',
                        display: 'flex',
                        margin: 'auto',
                        alignItems: 'center'
                      }} onClick={handleSegmentClick}>
                          <Box display="flex" flexDirection="row" justifyContent="space-around" sx={{
                          width: '100%',
                          color: segmentsOpen ? '#53A6D6' : !populatePreview ? '#BEBEBE' : selectedSegment || populatePreview ? '#1D252D' : '#BEBEBE'
                        }}>
                            <Box sx={{
                            flexShrink: 1,
                            pl: 1
                          }}>
                              <Icon name="custom-segment" size={24} />
                            </Box>
                            <Box sx={{
                            flexGrow: 5,
                            width: '100%',
                            alignContent: 'center',
                            margin: 'auto',
                            alignItems: 'center'
                          }}>
                              <Box sx={{
                              display: 'flex',
                              alignContent: 'center',
                              margin: 'auto',
                              alignItems: 'center'
                            }}>
                                <Typography sx={{
                                color: segmentsOpen ? '#53A6D6' : !populatePreview ? '#BEBEBE' : selectedSegment || populatePreview ? '#1D252D' : '#BEBEBE',
                                alignContent: 'center',
                                margin: 'auto',
                                alignItems: 'center'
                              }}>
                                  {selectedSegment ? selectedSegment.name : 'Customer segment'}
                                </Typography>
                              </Box>
                            </Box>
                            {segmentsOpen ? <Box sx={{
                            pl: 1,
                            flexShrink: 1,
                            transform: 'rotate(180deg)',
                            alignContent: 'center',
                            margin: 'auto',
                            alignItems: 'center'
                          }}>
                                <Icon name="custom-keyboard-arrow-down" size={20} />
                              </Box> : <Box sx={{
                            flexShrink: 1,
                            alignContent: 'center',
                            margin: 'auto',
                            alignItems: 'center',
                            pl: 1
                          }}>
                                <Icon name="custom-keyboard-arrow-down" size={20} />
                              </Box>}
                          </Box>
                        </Box>
                      </Stack>
                    </Box>
                  </Box>
                </Stack>
              </Box>
            </Paper>
          </Box>
          <Popper anchorEl={segmentsAnchorEl} sx={{
          zIndex: 200
        }} open={segmentsOpen} onClose={handleSegmentClose} title="Segments" placement="top-end">
            <ClickAwayListener onClickAway={handleSegmentClose}>
              <Paper elevation={0} sx={{
              p: 1,
              borderRadius: '16px',
              overflow: 'visible',
              width: '383px',
              overflowY: 'scroll',
              height: '85vh',
              scrollbarWidth: 'thin',
              '&::-webkit-scrollbar': {
                width: '0.4em'
              },
              '&::-webkit-scrollbar-track': {
                background: '#f1f1f1',
                marginTop: '20px',
                marginBottom: '20px'
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#888'
              },
              '&::-webkit-scrollbar-thumb:hover': {
                background: '#555'
              },
              filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 200
              }
            }}>
                <Box sx={{
                p: 1
              }}>
                  <Grid container justifyContent="center">
                    <RoundedTextField placeholder="Search customer segments" fullWidth sx={{
                    height: '40px'
                  }} value={segmentSearch} onChange={e => {
                    setSegmentSearch(e.target.value);
                  }} InputProps={{
                    startAdornment: <InputAdornment position="start">
                            <Icon name="custom-search" />
                          </InputAdornment>,
                    endAdornment: !!segmentSearch && <InputAdornment onClick={reset} position="start" sx={{
                      cursor: 'pointer'
                    }}>
                            <CloseIcon />
                          </InputAdornment>
                  }} />
                  </Grid>
                  <Divider flexItem sx={{
                  pt: 2
                }} />
                  <Box display="flex" flexDirection="column">
                    {!segmentSearchLoading ? segments.filter(opt => !segmentSearch || opt.name.toLowerCase().includes(segmentSearch.toLowerCase())).map(segment => <Button disabled={segment.customers == 0 && segment.time_dynamic_segment == false} variant="text" fullWidth key={segment.id} sx={{
                    cursor: 'pointer',
                    height: '48px',
                    color: '#1D252D',
                    textTransform: 'none',
                    justifyContent: 'flex-start'
                  }} onClick={() => {
                    setSegmentSearchLoading(true);
                    getSegmentCustomers(segment.id).then(data => {
                      if (data.data.results.length > 0) {
                        setSelectedSegment(segment);
                        setPopulatePreview(true);
                        setSegmentSearchLoading(false);
                        setSelectedSegmentCustomerId(data.data.results[0].id);
                        handleSegmentClose();
                      } else {
                        setSegmentSearchLoading(false);
                        snackbarService.popup({
                          message: segment.name + ' cannot be used because there are no customers in that dynamic segment. Please select a different one'
                        });
                      }
                    });
                  }}>
                            <span style={{
                      textAlign: 'left'
                    }}>
                              {segment.customers == 0 && segment.time_dynamic_segment == false ? segment.name + ' (This segment is empty)' : segment.name}
                            </span>
                          </Button>) : <Box sx={{
                    display: 'flex',
                    alignContent: 'center',
                    margin: 'auto',
                    alignItems: 'center',
                    pt: '100px'
                  }}>
                        <CircularProgress />
                      </Box>}
                  </Box>
                  <Grid container justifyContent="flex-end">
                    <Button variant="text" onClick={() => {
                    props.handleFilterChange({});
                    handleClose();
                  }}></Button>
                  </Grid>
                </Box>
              </Paper>
            </ClickAwayListener>
          </Popper>
          <Popper anchorEl={tagsAnchorEl} sx={{
          zIndex: 200
        }} open={tagsOpen} onClose={handleTagsClose} placement="top-start">
            <ClickAwayListener onClickAway={handleTagsClose}>
              <Paper elevation={0} sx={{
              py: 1,
              borderRadius: '16px',
              overflow: 'visible',
              width: '242px',
              overflowY: 'scroll',
              height: '85vh',
              scrollbarWidth: 'thin',
              '&::-webkit-scrollbar': {
                width: '0.4em'
              },
              '&::-webkit-scrollbar-track': {
                background: '#f1f1f1',
                marginTop: '20px',
                marginBottom: '20px'
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#888'
              },
              '&::-webkit-scrollbar-thumb:hover': {
                background: '#555'
              },
              filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 200
              }
            }}>
                <Box sx={{
                py: 1
              }}>
                  <Grid container justifyContent="center" sx={{
                  px: 2
                }}>
                    <RoundedTextField placeholder="Search tags" fullWidth sx={{
                    height: '40px'
                  }} value={tagsSearch} onChange={e => {
                    setTagsSearch(e.target.value);
                    if (e.target.value == '') {
                      setOpenAllTags(false);
                    } else {
                      setOpenAllTags(true);
                    }
                  }} InputProps={{
                    startAdornment: <InputAdornment position="start">
                            <Icon name="custom-search" />
                          </InputAdornment>,
                    endAdornment: !!segmentSearch && <InputAdornment onClick={reset} position="start" sx={{
                      cursor: 'pointer'
                    }}>
                            <CloseIcon />
                          </InputAdornment>
                  }} />
                  </Grid>
                  <Divider flexItem sx={{
                  pt: 2
                }} />
                  <Box display="flex" flexDirection="column">
                    {fields.filter(option => !tagsSearch || option.fields.some(opt => opt.name.toLowerCase().includes(tagsSearch.toLowerCase()))).length != 0 ? fields.filter(option => !tagsSearch || option.fields.some(opt => opt.name.toLowerCase().includes(tagsSearch.toLowerCase()))).map(tag => <>
                            <Button variant="text" fullWidth key={tag.id} sx={{
                      cursor: 'pointer',
                      height: '48px',
                      color: '#1D252D',
                      textTransform: 'none',
                      justifyContent: 'flex-start',
                      px: 2
                    }} onClick={() => {
                      if (tagSubGroup == tag.name) {
                        setTagSubGroup('');
                      } else {
                        setTagSubGroup(tag.name);
                      }
                    }}>
                              <Box display="flex" flexDirection="row" justifyContent="space-around" sx={{
                        width: '100%'
                      }}>
                                <Box sx={{
                          flexShrink: 1,
                          pr: 1
                        }}>
                                  {' '}
                                  <Icon name={getFieldIcon(tag.id)} size={24} />
                                </Box>
                                <Box sx={{
                          flexGrow: 5,
                          width: '100%',
                          alignContent: 'left',
                          alignItems: 'left'
                        }}>
                                  <Box sx={{
                            display: 'flex',
                            alignContent: 'left',
                            alignItems: 'left'
                          }}>
                                    <Typography variant="tableHeader" sx={{
                              alignContent: 'left',
                              alignItems: 'left'
                            }}>
                                      {tag.name}
                                    </Typography>
                                  </Box>
                                </Box>
                                {tagSubGroup != tag.name ? <Icon name="custom-keyboard-arrow-down" size={24} /> : <Box sx={{
                          transform: 'rotate(180deg)'
                        }}>
                                    <Icon name="custom-keyboard-arrow-down" size={24} />
                                  </Box>}
                              </Box>
                            </Button>
                            <Divider sx={{
                      width: '242px',
                      marginLeft: '-16px'
                    }} />
                            {(openAllTags || tagSubGroup == tag.name) && tag.fields.filter(opt => opt.name.toLowerCase().includes(tagsSearch.toLowerCase())).map(t => <Button variant="text" fullWidth key={t.id} sx={{
                      cursor: 'pointer',
                      height: '48px',
                      color: '#1D252D',
                      textTransform: 'none',
                      justifyContent: 'flex-start'
                    }} onClick={() => {
                      setHasUnsavedChanges(true);
                      const addition = ' {{{' + `${tag.id}` + '.' + `${t.id}` + '}}} ';
                      const output = [message.slice(0, cursorLocation), addition, message.slice(cursorLocation)].join('');
                      setMessage(output);
                      handleTagsClick();
                    }}>
                                    <Typography sx={{
                        textAlign: 'left'
                      }}>{t.name}</Typography>
                                  </Button>)}
                          </>) : <Box sx={{
                    p: 2
                  }}>No Results</Box>}
                  </Box>
                  <Grid container justifyContent="flex-end">
                    <Button variant="text" onClick={() => {
                    props.handleFilterChange({});
                    handleClose();
                  }}></Button>
                  </Grid>
                </Box>
              </Paper>
            </ClickAwayListener>
          </Popper>

          {emojiModal && <div style={{
          position: 'absolute',
          bottom: '110px',
          left: '110px',
          boxShadow: 'none'
        }}>
              <EmojiPicker emojiStyle="google" previewConfig={{
            showPreview: false
          }} onEmojiClick={e => {
            setHasUnsavedChanges(true);
            const output = [message.slice(0, cursorLocation), e.emoji, message.slice(cursorLocation)].join('');
            setMessage(output);
            setEmojiModal(false);
          }} />
            </div>}

          <ModalFileViewer open={addFileOpen} onSubmit={addFile} onClose={() => setAddFileOpen(false)} />

          <Popper anchorEl={phoneNumbersAnchorEl} sx={{
          zIndex: 200
        }} open={phoneNumbersOpen} onClose={handlePhoneNumbersClose} placement="top">
            <ClickAwayListener onClickAway={handlePhoneNumbersClose}>
              <Paper elevation={0} sx={{
              py: 1,
              borderRadius: '16px',
              overflow: 'visible',
              width: '168px',
              height: Math.max(168, 24 + numbers.length * 48) + 'px',
              filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))',
              mt: 1.5
            }}>
                <Box sx={{
                py: 1
              }}>
                  <Grid container justifyContent="center" sx={{
                  px: 2
                }}></Grid>
                  <Box display="flex" flexDirection="column">
                    {numbers.length > 0 ? numbers.map(number => <>
                          <Button variant="text" fullWidth key={number.id} sx={{
                      cursor: 'pointer',
                      height: '48px',
                      color: '#1D252D',
                      textTransform: 'none',
                      justifyContent: 'flex-start',
                      px: 2
                    }} onClick={() => {
                      setSelectedSendFromPhoneNumber(number);
                      handlePhoneNumbersClose();
                    }}>
                            <Typography> {number.phone}</Typography>
                          </Button>
                        </>) : <Box sx={{
                    p: 2
                  }}>
                        <Typography sx={{
                      pb: 2
                    }}>No Phone Numbers </Typography>
                        <a onClick={() => props.history.push({
                      pathname: `/phone-numbers`
                    })}>Click here to buy one</a>
                      </Box>}
                  </Box>
                  <Grid container justifyContent="flex-end">
                    <Button variant="text" onClick={() => {
                    props.handleFilterChange({});
                    handleClose();
                  }}></Button>
                  </Grid>
                </Box>
              </Paper>
            </ClickAwayListener>
          </Popper>
          <Popper anchorEl={optionsMenuAnchorEl} sx={{
          zIndex: 200
        }} open={optionsMenuOpen} onClose={handleOptionsMenuClose} placement="bottom-end">
            <ClickAwayListener onClickAway={handleOptionsMenuClose}>
              <Paper elevation={0} sx={{
              py: 1,
              borderRadius: '28px',
              overflow: 'visible',
              width: '146px',
              height: '168px',
              filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))',
              mt: 1.5
            }}>
                <Box sx={{
                py: 1
              }}>
                  <Grid container justifyContent="center" sx={{
                  px: 2
                }}></Grid>
                  <Box display="flex" flexDirection="column">
                    {optionsMenu.map(opt => <>
                        <Button variant="text" fullWidth key={opt.id} sx={{
                      cursor: 'pointer',
                      height: '48px',
                      color: '#1D252D',
                      textTransform: 'none',
                      justifyContent: 'flex-start',
                      px: 2
                    }} onClick={() => {
                      if (opt.name == 'Duplicate') {
                        handleDuplicateClick();
                      } else if (opt.name == 'Archive') {
                        handleArchiveClick();
                      } else if (opt.name == 'Delete') {
                        handleDeleteClick();
                      }
                    }}>
                          <Icon name={opt.icon} />
                          <Typography sx={{
                        pl: 1
                      }}> {opt.name}</Typography>
                        </Button>
                      </>)}
                  </Box>
                  <Grid container justifyContent="flex-end">
                    <Button variant="text" onClick={() => {}}></Button>
                  </Grid>
                </Box>
              </Paper>
            </ClickAwayListener>
          </Popper>
          <Prompt when={hasUnsavedChanges} message="There are unsaved changes, do you wish to discard them?" />
        </div>
      </div>
    </Scoped>;
};