import React, { Component } from 'react';
import { bool, func, oneOf, string, node, oneOfType } from 'prop-types';
import { Modal } from './modal.component';
import { Button } from '../button/button.component';
export class ModalDialog extends Component {
  static propTypes = {
    open: bool,
    onClose: func,
    onCancel: func,
    allowBackdropClick: bool,
    title: string,
    size: oneOf(['sm', 'md', 'lg', 'xl']),
    scroll: bool,
    onSubmit: func,
    submitText: oneOfType([string, node]),
    cancelText: oneOfType([string, node]),
    children: node,
    actionType: oneOf(['primary', 'flat', 'grey', 'warning', 'flat-light', 'white', 'danger']),
    cancelBtn: bool,
    submitDisabled: bool
  };
  static defaultProps = {
    actionType: 'primary',
    submitDisabled: false,
    cancelBtn: true
  };
  render() {
    const {
      open,
      onClose = () => {},
      onCancel = () => {},
      onSubmit,
      allowBackdropClick,
      title,
      size,
      type,
      submitText = 'Submit',
      cancelText = 'Cancel',
      children,
      actionType,
      submitDisabled,
      cancelBtn
    } = this.props;
    return <div>
        <Modal open={open} onClose={() => {
        onCancel();
        onClose();
      }} allowBackdropClick={allowBackdropClick} title={title} size={size} type={type}>
          <div className="modal__body">{children}</div>
          <div className="modal__actions">
            {cancelBtn && <Button onClick={() => {
            onCancel();
            onClose();
          }}>
              {cancelText}
            </Button>}
            {onSubmit && <Button disabled={submitDisabled} actionType={actionType} onClick={() => {
            onSubmit();
            onClose();
          }}>
                {submitText}
              </Button>}
          </div>
        </Modal>
      </div>;
  }
}