import { createTheme } from '@mui/material/styles';
const primary = '#487AAD';
const secondary = '#848484';
const success = '#48AD48';
const warning = '#374051';
const draft = '';
const theme = createTheme({
  palette: {
    primary: {
      main: primary
    },
    secondary: {
      main: secondary
    },
    white: {
      main: '#fff',
      contrastText: '#000'
    },
    type: 'light' // Switching the dark mode on is a single property value change.
  },
  typography: {
    fontFamily: 'Nexa',
    color: '#1D252D',
    fontWeight: '400',
    fontSize: '14px',
    textTransform: 'none',
    subtitle1: {
      fontWeight: 'medium',
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.01px',
      color: '#374051'
    },
    h4: {
      fontWeight: 'normal',
      fontSize: '20px',
      lineHeight: '32px',
      letterSpacing: '0px',
      color: '#374051'
    },
    h5: {
      fontWeight: 'normal',
      fontSize: '18px',
      lineHeight: '32px',
      letterSpacing: '0px',
      color: '#374051'
    },
    body1: {
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '16px',
      letterSpacing: '0.14px',
      color: "#1D252D"
    },
    tableHeader: {
      fontFamily: 'Nexa-Heavy',
      color: "#1D252D"
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Nexa';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: url('/assets/fonts/nexa/Nexa-Text-Regular.woff2') format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        },
        @font-face {
          font-family: 'Nexa-Black';
          font-style: normal;
          font-display: swap;
          font-weight: 700;
          src: url('/assets/fonts/nexa/Nexa-Text-Black.woff2') format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        },
        @font-face {
          font-family: 'Nexa-Heavy';
          font-style: normal;
          font-display: swap;
          font-weight: 700;
          src: url('/assets/fonts/nexa/Nexa-Text-Heavy.woff2') format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `
    },
    MuiIconButton: {
      variants: [{
        props: {
          variant: 'button'
        },
        style: {
          '&:hover': {
            color: primary
          },
          color: secondary
        }
      }]
    },
    MuiButton: {
      variants: [{
        props: {
          variant: 'text'
        },
        style: {
          textAlign: 'center',
          fontWeight: 'normal',
          fontSize: '14px',
          lineHeight: '19px',
          letterSpacing: '0.14px',
          color: primary
        }
      }]
    },
    MuiLink: {
      variants: [{
        props: {
          variant: 'primary'
        },
        style: {
          font: 'normal normal normal 14px/19px Roboto',
          letterSpacing: '0.14px',
          color: primary,
          textTransform: 'uppercase'
        }
      }, {
        props: {
          variant: 'secondary'
        },
        style: {
          font: 'normal normal normal 12px/16px Roboto',
          letterSpacing: '0.12px',
          color: primary
        }
      }]
    },
    MuiChip: {
      variants: [{
        props: {
          variant: 'draft'
        },
        style: {
          color: primary,
          border: '1px solid #487AAD',
          backgroundColor: 'transparent',
          fontSize: '12px',
          textTransform: 'capitalize'
        }
      }, {
        props: {
          variant: 'archived'
        },
        style: {
          color: warning,
          backgroundColor: 'transparent',
          border: '1px solid #AD486B',
          fontSize: '12px',
          textTransform: 'capitalize'
        }
      },
      // {
      //   props: {variant: 'deleted'},
      // },
      // {
      //   props: {variant: 'paused'},
      // },
      {
        props: {
          variant: 'published'
        },
        style: {
          color: success,
          backgroundColor: 'transparent',
          border: '1px solid #48AD48',
          fontSize: '12px',
          textTransform: 'capitalize'
        }
      }, {
        props: {
          variant: 'tag'
        },
        style: {
          color: secondary,
          fontFamily: 'Roboto',
          background: '#F3F3F3',
          fontSize: '12px',
          lineHeight: '16px',
          letterSpacing: '0px',
          borderRadius: '13px',
          '&:hover': {
            background: '#6F809E40'
          },
          '&:focus': {
            background: '#6F809E40'
          },
          '& span': {
            padding: '8px 8px'
          }
        }
      }

      // {
      //   props: {variant: 'stopped'},
      // },
      ]
    }
  },
  overrides: {
    MuiTableCell: {},
    MuiAppBar: {},
    MuiPaper: {},
    MuiFormLabel: {},
    MuiDataGrid: {
      root: {}
    },
    MuiTabs: {
      root: {}
    },
    MuiListItem: {
      root: {}
    },
    MuiFormControl: {
      MuiDrawer: {
        root: {}
      }
    },
    MuiButtonBase: {}
  },
  props: {
    MuiButtonBase: {}
  }
});
export default theme;