import React, { Component } from 'react';
import { userState } from '../../shared/user-state';
import { Button } from '../../components/button/button.component';
import { addPestRoutesCredentials, updatePestRoutesCredentials, getPestRoutesCredentials, deletePestRoutesCredentials } from './integrations.resource';
import { toasterService } from '../../components/toaster/toaster-service';
import { ModalDialog } from '../../components/modal/modal-dialog.component';
import moment from 'moment-timezone';
import { TypeAhead } from 'components/type-ahead/type-ahead.component';
import { StaticTypeAhead } from '../../components/static-type-ahead/static-type-ahead.componet';
export class IntegrationPestRoutes extends Component {
  static propTypes = {};
  state = {
    credentials: [],
    deleteCredentials: {},
    isAddModalOpen: false,
    credential: {}
  };
  componentDidMount() {
    this.getPestRoutesData().finally(() => this.props.onLoad());
  }
  getPestRoutesData = () => {
    return getPestRoutesCredentials({
      company: userState.getAsCompanyId()
    }).then(({
      results
    }) => {
      this.setState({
        credentials: results
      });
    });
  };
  submitCredentials = credential => {
    if (credential.id) {
      this.updateCredentials(credential.id, credential.domain, credential.key, credential.token, credential.timezone);
    } else {
      this.addCredentials(credential.domain, credential.key, credential.token, credential.timezone);
    }
  };
  addCredentials = (domain, key, token, timezone) => {
    return addPestRoutesCredentials(domain, key, token, userState.getAsCompanyId(), timezone).then(() => toasterService.success('Successfully added credentials')).then(() => this.getPestRoutesData()).catch(e => {
      if (e.response && e.response.data) {
        toasterService.error(e.response.data[0]);
      } else {
        toasterService.error('Unknown error trying add credentials. Please try again.');
      }
    });
  };
  updateCredentials = (id, domain, key, token, timezone) => {
    return updatePestRoutesCredentials(id, domain, key, token, timezone).then(() => toasterService.success('Successfully updated credentials')).then(() => this.getPestRoutesData()).catch(e => {
      if (e.response && e.response.data) {
        toasterService.error(e.response.data[0]);
      } else {
        toasterService.error('Unknown error trying add credentials. Please try again.');
      }
    });
  };
  removeCredentials = credentialsId => {
    return deletePestRoutesCredentials(credentialsId).then(() => toasterService.success('Successfully deleted credentials')).then(() => this.getPestRoutesData()).catch(() => toasterService.error('Unknown error trying delete credentials. Please try again.'));
  };
  render() {
    const {
      credentials,
      deleteCredentials,
      isAddModalOpen,
      credential
    } = this.state;
    const timezone = moment.tz.names().map(t => {
      return {
        id: t,
        value: t
      };
    });
    return <div className="integration mb-5">
        <div className="integration__header">
          <div>PestRoutes</div>
          {userState.hasPermission('pestroutes.add_credential') && <Button className="btn" actionType="primary" tag="a" onClick={() => this.setState({
          isAddModalOpen: true,
          credential: {}
        })}>
              Add Credentials
            </Button>}
        </div>
        <div className="integration__body">
          {credentials.map(credential => <div className="integration-item" key={credential.id}>
              <div className="integration-item__inner">
                <div className="integration-title">
                  <strong>{credential.domain}</strong>
                </div>
                <div className="integration-actions">
                  {userState.hasPermission('pestroutes.change_credential') && <Button actionType="flat" icon="fa-regular-edit" onClick={e => {
                e.stopPropagation();
                this.setState({
                  isAddModalOpen: true,
                  credential
                });
              }} />}
                  {userState.hasPermission('pestroutes.delete_credential') && <Button actionType="flat" icon="fa-regular-trash" onClick={e => {
                e.stopPropagation();
                this.setState({
                  deleteCredentials: credential
                });
              }} />}
                </div>
              </div>
            </div>)}
        </div>

        <ModalDialog open={isAddModalOpen} title={`${credential.id ? 'Update' : 'Add'} API Credentials`} onSubmit={() => this.submitCredentials(credential)} onClose={() => this.setState({
        isAddModalOpen: false
      })} allowBackdropClick>
          <p>
            Please enter the API authentication key and token for your
            PestRoutes account.
          </p>
          <p>
            <label>Domain</label>
            <input required className="form-control" value={credential.domain || ''} onChange={e => this.setState({
            credential: {
              ...credential,
              domain: e.target.value
            }
          })} />
            <small>
              Only include the first part of the domain. For example, if your
              company is https://acme.pestroutes.com only enter "acme" (without
              quotation marks)
            </small>
          </p>
          <p>
            <label>Frequency</label>
            <select required className="form-control" value={credential.frequency || 'daily'} onChange={e => this.setState({
            credential: {
              ...credential,
              frequency: e.target.value
            }
          })}>
              <option value="daily">Daily</option>
              <option value="hourly">Hourly</option>
            </select>
            <small>Use daily if you're on the PestRoutes free API</small>
          </p>
          <p>
            <label>Authentication Key</label>
            <input required className="form-control" value={credential.key || ''} onChange={e => this.setState({
            credential: {
              ...credential,
              key: e.target.value
            }
          })} />
          </p>
          <p>
            <label>Authentication Token</label>
            <input required className="form-control" value={credential.token || ''} onChange={e => this.setState({
            credential: {
              ...credential,
              token: e.target.value
            }
          })} />
          </p>
          <div className="form-group">
            <label>Timezone</label>
            <StaticTypeAhead items={timezone} placeholder={credential ? credential.timezone : 'Choose Timezone'} displayProperty="value" keyProperty="id" value={credential.timezone || ''} onChange={tz => tz && this.setState({
            credential: {
              ...credential,
              timezone: tz.value
            }
          })} />
          </div>
        </ModalDialog>

        <ModalDialog open={!!deleteCredentials.id} title="Remove Credentials" submitText="Remove" onSubmit={() => this.removeCredentials(deleteCredentials.id)} onClose={() => this.setState({
        deleteCredentials: {}
      })} allowBackdropClick>
          <p>
            <strong>{deleteCredentials.domain}</strong>
          </p>
          <p>Are you sure you want to delete these credentials?</p>
        </ModalDialog>
      </div>;
  }
}