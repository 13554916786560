import * as React from 'react';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '../pagination/pagination.component';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import { visuallyHidden } from '@mui/utils';
import { ChipsArray } from '../chip/chip.component';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import PresetsCalendar from '../calendar/presets-calendar.component';
import { FilterMenu } from '../filter/filter.component';
import { userState } from 'shared/user-state';
import qs from 'qs';
import { Loader } from '../../loader/loader.component';
import Typography from '@mui/material/Typography';
import { AntSwitch } from '../switch/ant-switch.component';
export default function EnhancedTable(props) {
  const {
    filters,
    setFilters,
    filterOptions
  } = props;
  const [order, setOrder] = React.useState(props.defaultSort && props.defaultSort.charAt(0) == '-' ? 'desc' : 'asc');
  const [orderBy, setOrderBy] = React.useState(props.defaultSort ? props.defaultSort.charAt(0) == '-' ? props.defaultSort.substring(1) : props.defaultSort : null);
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [rowHover, setRowHover] = React.useState('');
  const [columnHover, setColumnHover] = React.useState('');
  const [paginationParams, setPaginationParams] = React.useState({
    limit: 20,
    offset: 0
  });
  const [isLoading, setIsLoading] = React.useState(false);
  const [ordering, setOrdering] = React.useState(props.defaultSort || null);
  const [data, setData] = React.useState([]);
  const [paginationData, setPaginationData] = React.useState({});
  const [paramsLength, setParamsLength] = React.useState();
  const [search, setSearch] = React.useState('');
  const [autoRefresh, setAutoRefresh] = React.useState(props.enableAutoRefresh);
  const [isRefreshing, setIsRefreshing] = React.useState(false);
  React.useEffect(() => {
    //this adds url params to active filters
    let params = {};
    if (paginationParams.offset) {
      params.offset = paginationParams.offset;
    }
    for (const property in filters) {
      if (filters[property] !== '') {
        params[property] = filters[property];
      }
    }
    const pageFilters = ['date_after', 'date_before', 'search', ...filterOptions.map(o => o.key)];
    const queryParams = new URLSearchParams(window.location.search);
    for (let key of queryParams.keys()) {
      const inFilters = pageFilters.indexOf(key) > -1;
      if (inFilters) {
        params[key] = queryParams.get(key);
      } else {
        //check if key is a multi-select filter
        const subKey = key.substring(0, key.length - 3);
        const elementFilter = pageFilters.indexOf(subKey) > -1;
        if (elementFilter) {
          if (!params[subKey]) {
            params[subKey] = [];
          }
          params[subKey].push(queryParams.get(key));
        }
      }
    }
    setFilters(params);
  }, []);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setOrdering((isAsc ? '-' : '') + property);
    getData();
  };
  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelected = data.map(n => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };
  const handleClickbox = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };
  const handleChangePage = newPage => {
    setPage(newPage);
    if (newPage != 0) setAutoRefresh(false);
    setPaginationParams({
      limit: 20,
      offset: 20 * newPage
    });
  };
  const isSelected = name => selected.indexOf(name) !== -1;
  function handleFilterChange(newFilters) {
    handleChangePage(0);
    setFilters(newFilters);
  }
  function handleDateChange(after, before) {
    const {
      date_after: _,
      date_before: __,
      ...newFilters
    } = filters;
    handleChangePage(0);
    setFilters({
      ...newFilters,
      date_after: after,
      ...(!!before && {
        date_before: before
      })
    });
  }
  const getData = autoRefreshLoading => {
    if (!autoRefreshLoading) {
      setIsLoading(true);
    } else {
      setIsRefreshing(true);
    }
    if (props.api) {
      props.api({
        ...paginationParams,
        ...filters,
        search,
        company: userState.getAsCompanyId(),
        ordering
      }).then(({
        data
      }) => {
        if (data) {
          setData(data.results);
          setIsLoading(false);
          setIsRefreshing(false);
          setPaginationData(data);
        }
      });
    }
  };
  React.useEffect(() => {
    setSearch(props.search);
  }, [props.search]);
  React.useEffect(() => {
    getData();
  }, [paginationParams, search, props.onParentChange]);
  React.useEffect(() => {
    let params = {};
    if (paginationParams.offset) {
      params.offset = paginationParams.offset;
    }
    for (const property in filters) {
      if (filters[property] !== '') {
        params[property] = filters[property];
      }
    }
    const pageFilters = ['date_after', 'date_before', 'search', ...filterOptions.map(o => o.key)];

    // Add in other query params that are not filters
    const queryParams = new URLSearchParams(window.location.search);
    for (let key of queryParams.keys()) {
      const subKey = key.substring(0, key.length - 3);
      const inFilters = pageFilters.indexOf(key) > -1 || pageFilters.indexOf(subKey) > -1;
      if (!inFilters) {
        // If we have a query param that's not a filter then add it to the URL
        params[key] = queryParams.get(key);
      }
    }
    if (Object.keys(params).length) {
      let newurl = window.location.protocol + '//' + window.location.host + window.location.pathname + '?' + qs.stringify(params);
      window.history.pushState({
        path: newurl
      }, '', newurl);
    } else {
      let newurl = window.location.protocol + '//' + window.location.host + window.location.pathname;
      window.history.pushState({
        path: newurl
      }, '', newurl);
    }
    setParamsLength(Object.keys(params).length);
    getData();
  }, [filters]);
  React.useEffect(() => {
    if (autoRefresh && page == 0) {
      const interval = setInterval(() => getData(true), 60000);
      return () => clearInterval(interval);
    }
  }, [data, autoRefresh]);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;
  return <Box sx={{
    m: '20px'
  }}>
      <Paper sx={{
      width: '100%',
      borderRadius: '14px'
    }}>
        <Toolbar variant="dense" sx={{
        pl: {
          sm: 2
        },
        pr: {
          xs: 1,
          sm: 1
        },
        borderBottom: '1px solid #E2E2E2',
        ...(false && selected.length > 0 && {
          bgcolor: theme => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity)
        })
      }}>
          <Stack direction="row" spacing={2} sx={{
          width: '100%',
          alignItems: 'center'
        }}>
            <Tooltip title="Filter list">
              <>
              <FilterMenu filters={filters} handleFilterChange={handleFilterChange} filterOptions={filterOptions} />
              </>
            </Tooltip>

            <ChipsArray filters={filters} handleFilterChange={handleFilterChange} filterOptions={filterOptions} sx={{
            flexGrow: 1
          }} />
            <Divider orientation="vertical" sx={{
            height: '32px'
          }} />
            {props.enableAutoRefresh && <>
                <AntSwitch sx={{
              ml: '8px',
              colorPrimary: '#53A6D6'
            }} disabled={page != 0} checked={autoRefresh} onChange={() => {
              setAutoRefresh(!autoRefresh);
            }} inputProps={{
              'aria-label': 'ant design'
            }} />
                <Typography> {isRefreshing ? 'Refreshing...' : 'Auto refresh'} </Typography>
              </>}
            {props.enableAutoRefresh && <Divider orientation="vertical" sx={{
            height: '32px'
          }} />}
            <PresetsCalendar after={filters.date_after} before={filters.date_before} handleDateChange={handleDateChange} bottom="10px" />

            <Divider orientation="vertical" sx={{
            height: '32px'
          }} />
            <TablePagination sx={{
            height: '100%',
            overflow: 'hidden'
          }} count={paginationData.count} hasNext={!!paginationData.next} hasPrevious={!!paginationData.previous} rowsPerPage={rowsPerPage} rowCount={data.length} page={page} handleChangePage={handleChangePage} />
          </Stack>
        </Toolbar>

        <TableContainer>
          <Table sx={{
          minWidth: 750
        }} aria-labelledby="tableTitle" size={'medium'}>
            <TableHead sx={{
            height: '60px'
          }}>
              <TableRow>
                {props.enableCheckboxes && <TableCell padding="checkbox">
                    <Checkbox color="primary" indeterminate={selected.length > 0 && selected.length < data.length} checked={data.length > 0 && selected.length === data.length} onChange={handleSelectAllClick} inputProps={{}} />
                  </TableCell>}

                {props.headCells.map(headCell => <TableCell key={headCell.key} align={headCell.align} sortDirection={orderBy === headCell.key ? order : false} sx={{
                py: 1,
                pr: 1
              }}>
                    {headCell.noSort ? <Typography variant='tableHeader'>{headCell.label}</Typography> : <TableSortLabel active={orderBy === headCell.key} direction={orderBy === headCell.key ? order : 'asc'} onClick={e => handleRequestSort(e, headCell.key)}>
                        <Typography variant='tableHeader'>{headCell.label}</Typography>
                        {orderBy === headCell.key ? <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box> : null}
                      </TableSortLabel>}
                  </TableCell>)}
              </TableRow>
            </TableHead>
            <TableBody>
              {!isLoading && data.length > 0 ? data.map((row, index) => {
              const isItemSelected = isSelected(row.id);
              return <TableRow hover sx={{
                cursor: 'pointer'
              }} role="checkbox" aria-checked={isItemSelected} tabIndex={-1} key={index} selected={isItemSelected} onMouseEnter={() => setRowHover(row.id)} onMouseLeave={() => setRowHover(null)}>
                        {props.enableCheckboxes && <TableCell padding="checkbox">
                            <Checkbox onClick={event => handleClickbox(event, row.id)} color="primary" checked={isItemSelected} />
                          </TableCell>}

                        {props.headCells.map(headCell => <TableCell sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  maxWidth: '300px'
                }} onClick={() => props.handleRowClick && props.handleRowClick(row)} key={headCell.key} {...headCell.props} onMouseEnter={() => setColumnHover(headCell.key)} onMouseLeave={() => setColumnHover(null)}>
                            {React.createElement(headCell.displayComponent, {
                    data: row,
                    index: index,
                    onHover: {
                      row: rowHover,
                      column: columnHover
                    }
                  })}
                          </TableCell>)}
                      </TableRow>;
            }) : !isLoading && data.length == 0 && paramsLength > 0 ? <TableRow>
                  <TableCell />
                  <TableCell>No results. Try adjusting filters</TableCell>
                </TableRow> : !isLoading && data.length == 0 ? <TableRow>
                  <TableCell />
                  <TableCell>No results</TableCell>
                </TableRow> : <TableRow>
                  <TableCell>
                    <Loader overlay />
                  </TableCell>
                </TableRow>}

              {emptyRows > 0 && <TableRow style={{
              height: 53 * emptyRows
            }}>
                  <TableCell colSpan={6} />
                </TableRow>}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>;
}