import React, { Component } from 'react';
import { func, object, shape, string } from 'prop-types';
export class ActionWebhook extends Component {
  static propTypes = {
    update: func,
    node: object,
    data: shape({
      uri: string,
      state: string
    })
  };
  onStateChange = e => {
    this.props.update({
      state: e.target.value
    });
  };
  onUriChange = e => {
    this.props.update({
      uri: e.target.value
    });
  };
  render() {
    const {
      state,
      uri
    } = this.props.data;
    return <div>
        <div className="form-group">
          <label>URI</label>
          <input className="form-control" onChange={this.onUriChange} value={uri || ''} />
          <p className="mt-3">
            When a customer enters this node a <code>POST</code> HTTP request will be made to the specified URI.
          </p>
        </div>
        <div className="form-group">
          <label>State</label>
          <textarea cols="3" className="form-control" style={{
          height: '100%'
        }} onChange={this.onStateChange} value={state || ''}></textarea>
          <p className="mt-3">
            The <code>POST</code> payload will be an array of objects that entered the node. Multiple customers may enter a node at the same time:
          </p>
          <pre>
            [{'{'}<br />
            &nbsp;&nbsp;&nbsp;"customer_id": "12345678-1234-5678-1234-567812345678",<br />
            &nbsp;&nbsp;&nbsp;"customer_node_id": "12345678-1234-5678-1234-567812345678",<br />
            &nbsp;&nbsp;&nbsp;"node_id": "12345678-1234-5678-1234-567812345678",<br />
            &nbsp;&nbsp;&nbsp;"state": "(This will be populated from the state field above)"<br />
            }]
          </pre>
        </div>
      </div>;
  }
}