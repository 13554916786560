import React, { useState, useEffect, useRef } from 'react';
import styles from './roles-list.styles.scss';
import { debounce } from 'lodash';
import { Scoped } from 'kremling';
import { PageHeader } from '../../components/page-header/page-header';
import { Modal } from '../../components/modal/modal.component';
import { Button } from '../../components/button/button.component';
import { Loader } from '../../components/loader/loader.component';
import { toasterService } from '../../components/toaster/toaster-service';
import { getRole, updateRole, createRole, deleteRole } from '../../shared/common.api';
import { userState } from '../../shared/user-state';
import { Save } from '../../components/save/save.component';
import { getInstalls } from '../../pages/integrations/integrations.resource.js';
const getClassNames = (permission, level) => {
  let className = 'dot';
  if (level === 'none' || permission && (level === 'view' && permission !== 'none' || permission === 'manage')) {
    className += ' active';
  }
  return className;
};
const getWidth = permission => {
  if (permission === 'manage') {
    return 'calc(100% - 20px)';
  } else if (permission === 'view') {
    return 'calc(50% - 15px)';
  }
  return '0%';
};
const ProgressRow = props => {
  return <div className="row permission-row mb-3">
      <div className="col-3 progress-label">
        <div>{props.label}</div>
      </div>
      <div className="col-9 progress-bar">
        <div className="bar" style={{
        width: props.levels.length === 3 ? 'calc(100% - 20px)' : 'calc(50% - 15px)',
        backgroundColor: '#dfdfdf',
        zIndex: 1
      }}></div>
        <div className="bar" style={{
        width: getWidth(props.permission)
      }}></div>
        {props.levels.map(level => <div key={`${props.ky}-${level}`} onClick={() => props.updatePermission(props.ky, level)} className={getClassNames(props.permission, level)}></div>)}
        {ALL.filter(t => props.levels.indexOf(t) === -1).map(level => <div key={`${props.ky}-${level}`} className="blank-dot">
            &nbsp;
          </div>)}
      </div>
    </div>;
};
const VIEW_ONLY = ['none', 'view'];
const ALL = ['none', 'view', 'manage'];
const Role = props => {
  const [role, setRole] = useState();
  const [saving, setSaving] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [confirmModal, setConfirmModal] = useState(false);
  const [showCoupons, setShowCoupons] = useState(false);
  const [showSurveys, setShowSurveys] = useState(false);
  const [showReviews, setShowReviews] = useState(false);
  const confirmDeleteRole = () => {
    if (`${role.id}` === 'undefined') {
      toasterService.success('Role successfully deleted.');
      props.history.push('/roles');
      return;
    }
    deleteRole(role.id).then(() => {
      toasterService.success('Role successfully deleted.');
      props.history.push('/roles');
    }).catch(e => {
      toasterService.error('Unable to delete role.  Make sure there are no users still using this role');
    });
    setConfirmModal(false);
  };
  const save = updatedRole => {
    const promise = updatedRole.id ? updateRole(updatedRole.id, updatedRole) : createRole(updatedRole).then(({
      data
    }) => {
      setRole(role => ({
        ...role,
        id: data.id
      }));
      props.history.replace(`/roles/${data.id}`);
    });
    promise.then(() => {
      setSaving(false);
    });
  };
  const isInvalidPermissionChange = (permission, level) => {
    if (permission == 'roles' && (role.permissions.users == 'view' || role.permissions.users == 'manage') && level == 'none') {
      toasterService.success('The Permissions role cannot be None while the Users role is active');
      return true;
    }
    if (permission == 'domains' && (role.permissions.templates == 'view' || role.permissions.templates == 'manage') && level == 'none') {
      toasterService.success('The Domains role cannot be None while the Templates role is active');
      return true;
    }
    if (permission == 'segments' && (role.permissions.customers == 'view' || role.permissions.customers == 'manage') && level == 'none') {
      toasterService.success('The Segments role cannot be None while the Customers / Transactions  role is active');
      return true;
    }
    if (permission == 'company' && (role.permissions.customers == 'view' || role.permissions.customers == 'manage') && level == 'none') {
      toasterService.success('The Company / Data Fields role cannot be None while the Customers / Transactions  role is active');
      return true;
    }
    if ((permission == 'company' || permission == 'segments' || permission == 'templates' || permission == 'locations' || permission == 'forms' || permission == 'numbers' || permission == 'domains') && (role.permissions.journeys == 'view' || role.permissions.journeys == 'manage') && level == 'none') {
      toasterService.success('The ' + permission + ' role cannot be None while the Customer Journeys role is active');
      return true;
    }
    if ((permission == 'segments' || permission == 'numbers' || permission == 'customers') && (role.permissions.messaging == 'view' || role.permissions.messaging == 'manage') && level == 'none') {
      toasterService.success('The ' + permission + ' role cannot be None while the Two-way Messaging role is active');
      return true;
    }
    return false;
  };
  const checkAutomaticPermissions = (permission, level, permissions) => {
    //Users permission requires at least role perrmision level to be at least set to view
    if (role.permissions.roles == 'none' && permission == 'users' && (level == 'view' || level == 'manage')) {
      permissions.roles = 'view';
      toasterService.success('The Users role requires the Permissions role to be set to View or Manage');
    }

    //template permission
    if (role.permissions.domains == 'none' && permission == 'templates' && (level == 'view' || level == 'manage')) {
      permissions.domains = 'view';
      toasterService.success('The Templates role requires the Domains role to be set to View or Manage ');
    }

    //Customers / Transactions
    let customerRoleToggled = false;
    if (role.permissions.segments == 'none' && permission == 'customers' && (level == 'view' || level == 'manage')) {
      permissions.segments = 'view';
      customerRoleToggled = true;
    }
    if (role.permissions.company == 'none' && permission == 'customers' && (level == 'view' || level == 'manage')) {
      permissions.company = 'view';
      customerRoleToggled = true;
    }
    if (customerRoleToggled) toasterService.success('The Customers / Transactions role requires requires multiple roles to be set to View or Manage ');

    //Customer journey permission:
    if (permission == 'journeys' && (level == 'view' || level == 'manage')) {
      let somethingChanged = false;
      if (permissions.segments == 'none') {
        permissions.segments = 'view';
        somethingChanged = true;
      }
      ;
      if (permissions.templates == 'none') {
        permissions.templates = 'view';
        somethingChanged = true;
      }
      ;
      if (permissions.locations == 'none') {
        permissions.locations = 'view';
        somethingChanged = true;
      }
      ;
      if (permissions.forms == 'none') {
        permissions.forms = 'view';
        somethingChanged = true;
      }
      ;
      if (permissions.numbers == 'none') {
        permissions.numbers = 'view';
        somethingChanged = true;
      }
      ;
      if (permissions.company == 'none') {
        permissions.company = 'view';
        somethingChanged = true;
      }
      ;
      if (permissions.domains == 'none') {
        permissions.domains = 'view';
        somethingChanged = true;
      }
      ;
      if (somethingChanged) toasterService.success('The Customer Journeys role requires multiple roles to be set to View or Manage');
    }

    //Twoway messaging:
    if (permission == 'messaging' && (level == 'view' || level == 'manage')) {
      let somethingChanged = false;
      if (permissions.numbers == 'none') {
        permissions.numbers = 'view';
        somethingChanged = true;
      }
      if (permissions.segments == 'none') {
        permissions.segments = 'view';
        somethingChanged = true;
      }
      if (permissions.customers == 'none') {
        permissions.customers = 'view';
        somethingChanged = true;
      }
      if (somethingChanged) toasterService.success('The Two-way Messaging role requires multiple roles to be set to View or Manage');
    }
  };
  const updatePermission = (permission, level) => {
    if (!canEdit || isInvalidPermissionChange(permission, level)) return;
    const permissions = {
      ...role.permissions,
      [permission]: level
    };
    checkAutomaticPermissions(permission, level, permissions);
    update('permissions', permissions);
  };
  const update = (key, val) => {
    setRole(role => {
      const updatedRole = {
        ...role,
        [key]: val
      };
      setSaving(true);
      debouncedSave(updatedRole);
      return updatedRole;
    });
  };
  const debouncedSave = useRef(debounce(save, 500)).current;
  useEffect(() => {
    const {
      user,
      asCompany
    } = userState.state;
    if (userState.state.asCompany) {
      getInstalls({
        company: userState.state.asCompany.id,
        limit: 100
      }).then(({
        results: installs
      }) => {
        setShowCoupons(installs.some(i => i.integration === 'loyalty' || i.integration === 'isi'));
        setShowSurveys(installs.some(i => i.integration === 'surveys'));
        setShowReviews(installs.some(i => i.integration === 'reviews'));
        if (props.match.params.id === 'new') {
          setIsLoading(false);
          setRole({
            display_name: 'Untitled',
            company: asCompany.id,
            permissions: {}
          });
          setCanEdit(userState.hasPermission('authentication.add_role'));
        } else {
          setIsLoading(true);
          getRole(props.match.params.id).then(({
            data
          }) => {
            if (data) {
              setRole(data);
              setIsLoading(false);
              setCanEdit(data.company && userState.hasPermission('authentication.change_role'));
            }
          });
        }
      });
    }
  }, []);
  if (!role) {
    return <Loader overlay background="#fff" />;
  }
  return <Scoped css={styles}>
      <div className="wrapper">
        <PageHeader name={role.display_name || 'Role'} updateName={canEdit ? name => update('display_name', name) : null} actions={canEdit && <>
                <Save saving={saving} />
                <Button actionType="danger" onClick={() => setConfirmModal(true)}>
                  Delete
                </Button>
              </>} />
        <div className="wrapper-scroll roles-list">
          {isLoading && <Loader overlay />}

          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label>Description</label>
                <input onChange={e => update('description', e.target.value)} className="form-control" type="text" disabled={!canEdit} value={role.description || ''} />
              </div>
            </div>
          </div>

          <div className="row permission-row mb-3">
            <div className="col-3">
              <strong>Permissions</strong>
            </div>
            <div className="col-9 progress-header">
              <div>None</div>
              <div>View</div>
              <div>Manage</div>
            </div>
          </div>
          <ProgressRow label="Company / Data Fields" ky="company" updatePermission={updatePermission} permission={role.permissions.company} levels={ALL} />
          <ProgressRow label="Users *" ky="users" updatePermission={updatePermission} permission={role.permissions.users} levels={ALL} />
          <ProgressRow label="Permissions" ky="roles" updatePermission={updatePermission} permission={role.permissions.roles} levels={ALL} />
          <ProgressRow label="Customer Journeys *" ky="journeys" updatePermission={updatePermission} permission={role.permissions.journeys} levels={ALL} />
          <ProgressRow label="Broadcasts" ky="broadcasts" updatePermission={updatePermission} permission={role.permissions.broadcasts} levels={ALL} />
          <ProgressRow label="Two-way Messaging *" ky="messaging" updatePermission={updatePermission} permission={role.permissions.messaging} levels={ALL} />
          {showReviews && <ProgressRow label="Reviews" ky="reviews" updatePermission={updatePermission} permission={role.permissions.reviews} levels={ALL} />}
          {showCoupons && <ProgressRow label="Coupons" ky="coupons" updatePermission={updatePermission} permission={role.permissions.coupons} levels={ALL} />}
          <ProgressRow label="Segments" ky="segments" updatePermission={updatePermission} permission={role.permissions.segments} levels={ALL} />
          <ProgressRow label="Templates *" ky="templates" updatePermission={updatePermission} permission={role.permissions.templates} levels={ALL} />
          <ProgressRow label="Locations" ky="locations" updatePermission={updatePermission} permission={role.permissions.locations} levels={ALL} />
          <ProgressRow label="Web Forms" ky="forms" updatePermission={updatePermission} permission={role.permissions.forms} levels={ALL} />
          <ProgressRow label="Phone Numbers" ky="numbers" updatePermission={updatePermission} permission={role.permissions.numbers} levels={ALL} />
          <ProgressRow label="Files" ky="files" updatePermission={updatePermission} permission={role.permissions.files} levels={ALL} />
          {showSurveys && <ProgressRow label="Surveys" ky="surveys" updatePermission={updatePermission} permission={role.permissions.surveys} levels={ALL} />}
          <ProgressRow label="Analytics" ky="analytics" updatePermission={updatePermission} permission={role.permissions.analytics} levels={VIEW_ONLY} />
          <ProgressRow label="Customers / Transactions *" ky="customers" updatePermission={updatePermission} permission={role.permissions.customers} levels={ALL} />
          <ProgressRow label="Integrations" ky="integrations" updatePermission={updatePermission} permission={role.permissions.integrations} levels={ALL} />
          <ProgressRow label="Domains" ky="domains" updatePermission={updatePermission} permission={role.permissions.domains} levels={ALL} />
          <p className="mt-5">
            * Setting this permission will automatically set other necessary
            permissions
          </p>
        </div>
      </div>

      <Modal open={!!confirmModal} onClose={() => setConfirmModal(false)} allowBackdropClick title="Delete Role">
        <div className="modal__body">
          <p>
            Are you sure you want to delete this role? This action cannot be
            undone.
          </p>
        </div>
        <div className="modal__actions">
          <Button actionType="flat" onClick={() => setConfirmModal(false)}>
            Cancel
          </Button>
          <Button actionType="danger" onClick={() => confirmDeleteRole()}>
            Confirm
          </Button>
        </div>
      </Modal>
    </Scoped>;
};
export default Role;