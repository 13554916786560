import React, { useState, useEffect, useContext } from 'react';
import { Banner, Button, Loader, toasterService } from 'components';
import { updateBusinessInformation } from 'shared/common.api';
import { WorkingAsContext } from 'context/working-as-context';
const REQUIRED_FIELDS = ['business_industry', 'business_name', 'business_regions_of_operation', 'business_registration_identifier', 'business_registration_number', 'business_type', 'website_url'];
export const BusinessInformationStep = props => {
  const {
    updateProfile,
    profile,
    setActiveStep,
    onClose,
    verification
  } = props;
  const {
    asCompany
  } = useContext(WorkingAsContext);
  const [disabled, setDisabled] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const submit = () => {
    // Submit this to the Cinch API which will submit to the Twilio API...
    // This will not be stored in Cinch's database but will be retrieved directly from Twilio
    setIsSaving(true);
    updateBusinessInformation({
      company: asCompany.id,
      ...profile.business_information
    }).then(() => {
      setActiveStep(1);
    }).catch(() => {
      toasterService.error('There was an unknown error trying to save your profile. Check your inputs and try again');
    }).then(() => {
      setIsSaving(false);
    });
  };
  useEffect(() => {
    setDisabled(REQUIRED_FIELDS.some(field => !profile?.business_information?.[field] || profile?.business_information?.[field] === ''));
  }, [profile]);
  return <>
      <div className="form-group">
        <label>Business Name</label>
        <input className="form-control" name="business_name" onChange={e => updateProfile('business_information', 'business_name', e.target.value)} value={profile.business_information?.business_name || ''} autoFocus />
        {verification?.error?.business_name && <small className="mt-1 block text-danger">{verification?.error?.business_name}</small>}
      </div>

      <div className="form-group">
        <label>Website URL</label>
        <input className="form-control" name="website_url" onChange={e => updateProfile('business_information', 'website_url', e.target.value)} value={profile.business_information?.website_url || ''} />
        {verification?.error?.website_url && <small className="mt-1 block text-danger">{verification?.error?.website_url}</small>}
      </div>

      <div className="form-group">
        <label>Business Regions of Operation</label>
        <select className="form-control" name="business_regions_of_operation" onChange={e => updateProfile('business_information', 'business_regions_of_operation', e.target.value)} value={profile.business_information?.business_regions_of_operation || ''}>
          <option value=""></option>
          <option value="AFRICA">Africa</option>
          <option value="ASIA">Asia</option>
          <option value="EUROPE">Europe</option>
          <option value="LATIN_AMERICA">Latin America</option>
          <option value="USA_AND_CANADA">USA and Canada</option>
        </select>
        {verification?.error?.business_regions_of_operation && <small className="mt-1 block text-danger">{verification?.error?.business_regions_of_operation}</small>}
      </div>

      <div className="form-group">
        <label>Business Type</label>
        <select className="form-control" name="business_type" onChange={e => updateProfile('business_information', 'business_type', e.target.value)} value={profile.business_information?.business_type || ''}>
          <option value=""></option>
          <option value="Sole Proprietorship">Sole Proprietorship</option>
          <option value="Partnership">Partnership</option>
          <option value="Limited Liability Corporation">Limited Liability Corporation</option>
          <option value="Co-operative">Co-operative</option>
          <option value="Non-profit Corporation">Non-profit Corporation</option>
          <option value="Corporation">Corporation</option>
        </select>
        {verification?.error?.business_type && <small className="mt-1 block text-danger">{verification?.error?.business_type}</small>}
      </div>

      <div className="form-group">
        <label>Business Industry</label>
        <select className="form-control" name="business_industry" onChange={e => updateProfile('business_information', 'business_industry', e.target.value)} value={profile.business_information?.business_industry || ''}>
          <option value=""></option>
          <option value="AUTOMOTIVE">Automotive</option>
          <option value="AGRICULTURE">Agriculture</option>
          <option value="BANKING">Banking</option>
          <option value="CONSUMER">Consumer</option>
          <option value="EDUCATION">Education</option>
          <option value="ENGINEERING">Engineering</option>
          <option value="ENERGY">Energy</option>
          <option value="OIL_AND_GAS">Oil and Gas</option>
          <option value="FAST_MOVING_CONSUMER_GOODS">Fast Moving Consumer Goods</option>
          <option value="FINANCIAL">Financial</option>
          <option value="FINTECH">Fintech</option>
          <option value="FOOD_AND_BEVERAGE">Food and Beverage</option>
          <option value="GOVERNMENT">Government</option>
          <option value="HEALTHCARE">Healthcare</option>
          <option value="HOSPITALITY">Hospitality</option>
          <option value="INSURANCE">Insurance</option>
          <option value="LEGAL">Legal</option>
          <option value="MANUFACTURING">Manufacturing</option>
          <option value="MEDIA">Media</option>
          <option value="ONLINE">Online</option>
          <option value="RAW_MATERIALS">Raw Materials</option>
          <option value="REAL_ESTATE">Real Estate</option>
          <option value="RELIGION">Religion</option>
          <option value="RETAIL">Retail</option>
          <option value="JEWELRY">Jewelry</option>
          <option value="TECHNOLOGY">Technology</option>
          <option value="TELECOMMUNICATIONS">Telecommunications</option>
          <option value="TRANSPORTATION">Transportation</option>
          <option value="TRAVEL">Travel</option>
          <option value="ELECTRONICS">Electronics</option>
          <option value="NOT_FOR_PROFIT">Not For Profit</option>
        </select>
        {verification?.error?.business_industry && <small className="mt-1 block text-danger">{verification?.error?.business_industry}</small>}
      </div>

      <div className="form-group">
        <label>Business Registration Identifier</label>
        <select className="form-control" name="business_registration_identifier" onChange={e => updateProfile('business_information', 'business_registration_identifier', e.target.value)} value={profile.business_information?.business_registration_identifier || ''}>
          <option value=""></option>
          <option value="EIN">USA: Employer Identification Number (EIN)</option>
          <option value="DUNS">USA: DUNS Number (Dun & Bradstreet)</option>
          <option value="CCN">Canada: Canadian Corporation Number (CCN)</option>
          <option value="CN">Great Britain: Company Number</option>
          <option value="ACN">Australia: Company Number from ASIC (ACN)</option>
          <option value="CIN">India: Corporate Identity Number</option>
          <option value="VAT">Estonia: VAT Number</option>
          <option value="VATRN">Romania: VAT Registration Number</option>
          <option value="RN">Israel: Registration Number</option>
          <option value="Other">Other</option>
        </select>
        {verification?.error?.business_registration_identifier && <small className="mt-1 block text-danger">{verification?.error?.business_registration_identifier}</small>}
        {profile.business_information?.business_registration_identifier === 'DUNS' && <div className="mt-2" style={{
        fontSize: 12
      }}>
            <Banner type="warning">
              If you have a US entity or an International Tax ID, use EIN to register their customer profile to avoid brand registration
              failures. Do not use a DUNS number.{' '}
            </Banner>
          </div>}
      </div>

      <div className="form-group">
        <label>Business Registration Number</label>
        <input className="form-control" name="business_registration_number" onChange={e => updateProfile('business_information', 'business_registration_number', e.target.value)} value={profile.business_information?.business_registration_number || ''} />
        {verification?.error?.business_registration_number && <small className="mt-1 block text-danger">{verification?.error?.business_registration_number}</small>}
      </div>

      <div style={{
      display: 'flex',
      gap: '10px',
      justifyContent: 'end'
    }}>
        <Button actionType="flat" onClick={onClose}>
          Cancel
        </Button>
        <Button actionType="primary" disabled={disabled || isSaving} onClick={submit}>
          Save {isSaving && <Loader className="ml-3" size="sm" />}
        </Button>
      </div>
    </>;
};