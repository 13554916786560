import React, { useState, useEffect, useCallback, useContext } from 'react';
import { debounce } from 'lodash';
import classnames from 'classnames';
import { getAvailablePhoneNumbers, purchasePhoneNumber } from '../../shared/common.api';
import { WorkingAsContext } from 'context/working-as-context';
import { Modal, Loader, Button, ButtonGroup, toasterService } from 'components';
import { userState } from 'shared/user-state';
export const BuyPhoneNumberModal = props => {
  const [findNumberTab, setFindNumberTab] = useState('local');
  const [findNumberRegion, setFindNumberRegion] = useState('');
  const [findNumberAreaCode, setFindNumberAreaCode] = useState('');
  const [findNumberContains, setFindNumberContains] = useState('');
  const [findNumberAvailables, setFindNumberAvailables] = useState([]);
  const [findNumberLoading, setFindNumberLoading] = useState(false);
  const [buyNumberLoading, setBuyNumberLoading] = useState(false);
  const [buyNumberModal, setBuyNumberModal] = useState();
  const [buyNumberAccept, setBuyNumberAccept] = useState(false);
  const {
    asCompany
  } = useContext(WorkingAsContext);
  const fetchAvailable = () => {
    setFindNumberLoading(true);
    getAvailablePhoneNumbers({
      in_region: findNumberRegion,
      area_code: `${findNumberAreaCode}`.length === 3 ? findNumberAreaCode : null,
      contains: `${findNumberContains}`.length > 1 ? findNumberContains : null
    }).then(({
      data
    }) => {
      setFindNumberAvailables(data);
      setFindNumberLoading(false);
    });
  };
  const openBuyNumberModal = number => {
    setBuyNumberAccept(false);
    props.onClose();
    setBuyNumberModal(number);
  };
  const closeBuyNumberModal = () => {
    setBuyNumberModal(null);
  };
  const buyNumber = () => {
    setBuyNumberLoading(true);
    purchasePhoneNumber({
      company: asCompany.id,
      phone: buyNumberModal.phone_number,
      ...buyNumberModal
    }).then(() => props.loadData()).then(() => {
      toasterService.success('Phone number successfully purchased');
      setBuyNumberModal(null);
      setBuyNumberLoading(false);
    }).catch(() => {
      toasterService.error('Unable to purchase phone number. Try again or try a different phone number.');
    });
  };
  const debouncedFetchAvailable = useCallback(debounce(fetchAvailable, 500), [fetchAvailable]);
  useEffect(() => {
    debouncedFetchAvailable();
    return debouncedFetchAvailable.cancel;
  }, [findNumberRegion, findNumberAreaCode, findNumberContains]);
  return <>
      <Modal open={props.open} onClose={props.onClose} allowBackdropClick title="Search for New Phone Number" size="lg">
        <div className="modal__body">

        {userState.state.user.is_superuser && props.profile?.campaign_status !== 'VERIFIED' && <p>
              This company doesn't have an approved SMS profile. <Button onClick={() => props.setShowBuy(false)}>Set up profile</Button>
            </p>}

          <div className="text-center mb-4">
            <ButtonGroup>
              <Button className={classnames({
              active: findNumberTab === 'local'
            })} actionType="white" onClick={() => setFindNumberTab('local')}>
                Local
              </Button>
              <Button className={classnames({
              active: findNumberTab === 'shortcode'
            })} actionType="white" onClick={() => setFindNumberTab('shortcode')}>
                Short Code
              </Button>
              <Button className={classnames({
              active: findNumberTab === 'tollfree'
            })} actionType="white" onClick={() => setFindNumberTab('tollfree')}>
                Toll Free
              </Button>
            </ButtonGroup>
          </div>

          {findNumberTab === 'local' && <>
              <div className="row">
                <div className="col-4 form-group">
                  <select className={classnames('form-control', {
                active: findNumberRegion !== ''
              })} required value={findNumberRegion} onChange={event => setFindNumberRegion(event.target.value)}>
                    <option value="" disabled={true}>
                      Choose a State
                    </option>
                    <option value=""></option>
                    <option value="AL">Alabama</option>
                    <option value="AK">Alaska</option>
                    <option value="AZ">Arizona</option>
                    <option value="AR">Arkansas</option>
                    <option value="CA">California</option>
                    <option value="CO">Colorado</option>
                    <option value="CT">Connecticut</option>
                    <option value="DE">Delaware</option>
                    <option value="DC">District Of Columbia</option>
                    <option value="FL">Florida</option>
                    <option value="GA">Georgia</option>
                    <option value="HI">Hawaii</option>
                    <option value="ID">Idaho</option>
                    <option value="IL">Illinois</option>
                    <option value="IN">Indiana</option>
                    <option value="IA">Iowa</option>
                    <option value="KS">Kansas</option>
                    <option value="KY">Kentucky</option>
                    <option value="LA">Louisiana</option>
                    <option value="ME">Maine</option>
                    <option value="MD">Maryland</option>
                    <option value="MA">Massachusetts</option>
                    <option value="MI">Michigan</option>
                    <option value="MN">Minnesota</option>
                    <option value="MS">Mississippi</option>
                    <option value="MO">Missouri</option>
                    <option value="MT">Montana</option>
                    <option value="NE">Nebraska</option>
                    <option value="NV">Nevada</option>
                    <option value="NH">New Hampshire</option>
                    <option value="NJ">New Jersey</option>
                    <option value="NM">New Mexico</option>
                    <option value="NY">New York</option>
                    <option value="NC">North Carolina</option>
                    <option value="ND">North Dakota</option>
                    <option value="OH">Ohio</option>
                    <option value="OK">Oklahoma</option>
                    <option value="OR">Oregon</option>
                    <option value="PA">Pennsylvania</option>
                    <option value="RI">Rhode Island</option>
                    <option value="SC">South Carolina</option>
                    <option value="SD">South Dakota</option>
                    <option value="TN">Tennessee</option>
                    <option value="TX">Texas</option>
                    <option value="UT">Utah</option>
                    <option value="VT">Vermont</option>
                    <option value="VA">Virginia</option>
                    <option value="WA">Washington</option>
                    <option value="WV">West Virginia</option>
                    <option value="WI">Wisconsin</option>
                    <option value="WY">Wyoming</option>
                  </select>
                </div>
                <div className="col-4 form-group">
                  <input className="form-control" placeholder="Search Area Code" onChange={event => setFindNumberAreaCode(event.target.value)} maxLength={3} value={findNumberAreaCode} />
                </div>
                <div className="col-4 form-group">
                  <input className="form-control" placeholder="Search by Numbers" onChange={event => setFindNumberContains(event.target.value)} value={findNumberContains} />
                </div>
              </div>

              <p className="text-center">You will be charged $2.00 per month for local numbers.</p>

              {!!findNumberLoading && <div className="text-center w-100" style={{
            height: '283px',
            paddingTop: '100px'
          }}>
                  <Loader />
                </div>}

              {!findNumberLoading && <table className="table-list">
                  <thead>
                    <tr>
                      <th>Number</th>
                      <th>Location</th>
                      <th style={{
                  width: 40
                }} />
                    </tr>
                  </thead>
                  <tbody>
                    {!!findNumberAvailables.length && findNumberAvailables.map(phoneNumber => <tr key={phoneNumber.phone_number} onClick={() => openBuyNumberModal(phoneNumber)}>
                          <td>{phoneNumber.friendly_name}</td>
                          <td>
                            {phoneNumber.locality} {phoneNumber.region} {phoneNumber.postal_code}
                          </td>
                          <td>
                            <Button actionType="primary">Buy</Button>
                          </td>
                        </tr>)}

                    {!findNumberAvailables.length && <tr>
                        <td colSpan={3}>
                          <strong>No Results</strong>
                        </td>
                      </tr>}
                  </tbody>
                </table>}
            </>}
          {findNumberTab === 'shortcode' && <>
              <p>
                If you'd like to purchase a short code please reach out to us at <a href="mailto:support@cinch.io">support@cinch.io</a>
              </p>
              <p>Short codes typically cost around $1,000/month.</p>
            </>}
          {findNumberTab === 'tollfree' && <p>
              If you'd like to purchase a toll free number please reach out to us at <a href="mailto:support@cinch.io">support@cinch.io</a>
            </p>}
        </div>
      </Modal>
      <Modal open={!!buyNumberModal} onClose={() => closeBuyNumberModal()} allowBackdropClick title="Buy New Phone Number" size="md">
        <div className="modal__body">
          {!!buyNumberModal && <div className="d-flex justify-content-between mx-5">
              <h3>{buyNumberModal.friendly_name}</h3>
              <h3>
                {buyNumberModal.locality} {buyNumberModal.region} {buyNumberModal.postal_code}
              </h3>
            </div>}
          {!!buyNumberLoading && <div className="text-center w-100" style={{
          height: '98px',
          paddingTop: '15px'
        }}>
              <Loader />
            </div>}
          {!buyNumberLoading && <div>
              <p className="text-center">You will be charged $2.00 per month for this number.</p>

              <div className="d-flex mx-5">
                <input className="form-check-input mt-2" name="acceptTerms" type="checkbox" value={buyNumberAccept} htmlFor="rememberMe" onChange={e => setBuyNumberAccept(e.target.checked)} id="acceptTerms" />
                <p>
                  By purchasing this number I agree to Cinch's Terms of Use regarding text messaging found here:{' '}
                  <a href="https://cinch.io/terms/" target="_blank">
                    See Terms of Use
                  </a>
                </p>
              </div>
            </div>}
        </div>
        <div className="modal__actions">
          <Button onClick={() => closeBuyNumberModal()}>Cancel</Button>
          <Button actionType="primary" disabled={!buyNumberAccept || buyNumberLoading} onClick={() => buyNumber()}>
            Buy
          </Button>
        </div>
      </Modal>
    </>;
};