import { Container, TextStyle, Text as PIText, Texture, Sprite } from 'pixi.js';
import { MODES } from '../node/node-utils';
import { NodeDelete } from '../node/node-delete';
import { journeyState } from '../journey-state';
import { TextParam } from './text-param.component';
import * as htmlToImage from 'html-to-image';
const convertHtmlToImg = async textDom => {
  return htmlToImage.toPng(textDom, {
    pixelRatio: 1
  }).then(function (dataUrl) {
    return Texture.from(dataUrl);
  }).catch(function (error) {
    console.error('oops, something went wrong!', error);
  });
};
const createTextDom = () => {
  let textDom = document.createElement('div');
  textDom.id = 'text-dom';
  textDom.style.display = 'inline-block';
  textDom.style.width = 'fit-content';
  textDom.style.whiteSpace = 'pre';
  textDom.style.height = 'auto';
  textDom.style.maxWidth = 'fit-content';
  textDom.style.lineHeight = 1.0;
  textDom.style.fontSize = '15px';
  document.body.appendChild(textDom);
  return textDom;
};
let sx, sy;
export class Text {
  constructor(text, initialPosition = {
    x: 0,
    y: 0
  }) {
    this.initialPosition = initialPosition;
    this.mode = journeyState.state.mode;
    this.textDom = createTextDom();
    this.component = TextParam;
    this.parameters = {
      text
    };
    this.listen();
    this.build();
  }
  listen = () => {
    this.modeObserver = journeyState.subscribe(({
      mode
    }) => {
      this.mode = mode;
      this.onMouseOut();
    }, 'mode');
  };
  build = () => {
    this.buildContainer();
    this.buildShape();
    this.buildDelete();
  };
  buildShape = async () => {
    if (this.parameters.text !== '') {
      if (/<\/?[a-z][\s\S]*>/i.test(this.parameters.text)) {
        this.textDom.innerHTML = this.parameters.text;
      } else {
        this.textDom.innerHTML = this.parameters.text.replaceAll(/\n/g, '</br>').replaceAll(' ', '&nbsp;');
      }
    } else {
      this.textDom.innerHTML = this.parameters.text;
    }
    const texture = await convertHtmlToImg(this.textDom);
    this.text = new Sprite(texture);
    this.container.addChild(this.text);
  };
  buildContainer = () => {
    this.container = new Container();
    this.container.movement = null;
    this.container.x = this.initialPosition.x;
    this.container.y = this.initialPosition.y;
    this.container.interactive = true;
    this.container.on('mouseover', this.onMouseOver).on('mouseout', this.onMouseOut).on('pointerdown', this.onDragStart).on('pointerup', this.onDragEnd).on('pointerupoutside', this.onDragEnd).on('pointermove', this.onDragMove);
  };
  buildDelete = () => {
    this.nodeDelete = new NodeDelete(this.onDelete);
    this.nodeDelete.container.x = -26;
    this.nodeDelete.container.y = -5;
    this.container.addChild(this.nodeDelete.container);
  };
  willDestroy = () => {
    this.modeObserver.unsubscribe();
    this.container.destroy();
    document.body.removeChild(this.textDom);
  };
  updateParameters = async parameters => {
    if (parameters.text !== '') {
      if (/<\/?[a-z][\s\S]*>/i.test(parameters.text)) {
        this.textDom.innerHTML = parameters.text;
      } else {
        this.textDom.innerHTML = parameters.text.replaceAll(/\n/g, '</br>').replaceAll(' ', '&nbsp;');
      }
    } else {
      this.textDom.innerHTML = parameters.text;
    }
    this.parameters = parameters;
    const texture = await convertHtmlToImg(this.textDom);
    this.text.texture = texture;
    journeyState.saveText();
  };
  onDelete = event => {
    if (!this.container.hasDragged && this.mode === MODES.CANVAS) {
      event.stopped = true;
      journeyState.removeText(this);
    }
  };
  onMouseOver = () => {
    if (this.mode === MODES.CANVAS) {
      this.nodeDelete.container.alpha = 1;
    }
  };
  onMouseOut = () => {
    if (this.mode === MODES.CANVAS) {
      this.nodeDelete.container.alpha = 0;
    }
  };
  onDragStart = event => {
    if (this.mode === MODES.CANVAS) {
      this.container.dragging = true;
      this.container.movement = 0;
      this.data = event.data;
      sx = this.data.getLocalPosition(this.container).x * this.container.scale.x;
      sy = this.data.getLocalPosition(this.container).y * this.container.scale.y;
    }
  };
  onDragEnd = event => {
    if (this.container.dragging) {
      this.container.dragging = false;
      this.container.hasDragged = false;
      this.data = null;
      journeyState.saveText();
    }
    if (this.container.movement === 0 && this.mode === MODES.CANVAS || this.mode === MODES.TEXT) {
      journeyState.editTextParam(this);
    }
    this.container.movement = null;
  };
  onDragMove = event => {
    // Chris -- I added a check to make sure there is movement in at least one direction before we mark the "hasDragged".
    // Otherwise I was having problems where I couldn't delete a node. Instead it it was opening the paramters.
    // It might be a Windows thing.
    if (this.container.dragging && (event.data.originalEvent.movementX !== 0 || event.data.originalEvent.movementY !== 0)) {
      this.container.hasDragged = true;
      const newPosition = this.data.getLocalPosition(this.container.parent);
      const x = newPosition.x - sx;
      const y = newPosition.y - sy;
      this.container.movement = (Math.abs(this.container.x - x) + Math.abs(this.container.y - y)) * 100;
      this.container.x = x <= 0 ? 0 : Math.round(x);
      this.container.y = y <= 0 ? 0 : Math.round(y);
      journeyState.updateNodeLinks(this);
    }
  };
}