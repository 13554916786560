import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { debounce } from 'lodash';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import DoneIcon from '@mui/icons-material/Done';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import { Input, Link, Box } from '@mui/material';
import { toasterService } from '../../toaster/toaster-service';
const styles = {
  icon: {
    width: '14px',
    height: '14px'
  }
};
const TextExpand = ({
  textContent,
  onClick
}) => <Box display="flex" flexDirection="row-reverse">
    <Link sx={{
    mt: '8px'
  }} underline="none" onClick={onClick}>
      {textContent}
    </Link>
  </Box>;
const Tags = ({
  editTagMode,
  objectTarget,
  viewName,
  getViewTags,
  patchView,
  onSuccess,
  onClick
}) => {
  const [tagValue, updateTagValue] = useState('');
  const [companyId, updateCompanyId] = useState();
  const [companyTags, updateCompanyTags] = useState([]);
  const [tags, updateTags] = useState([]);
  const [showInput, setShowInput] = useState(false);
  const stackTagsRef = useRef();
  const [showMore, setShowMore] = useState(false);
  const [hasShowMore, setHasShowMore] = useState(false);
  const [maxTags, setMaxTags] = useState(100);
  useEffect(() => {
    if (objectTarget) {
      updateTags([...(objectTarget.tags || [])].map(t => t.toUpperCase()));
      if (companyId !== objectTarget.company) {
        updateCompanyId(objectTarget.company);
      }
    }
  }, [objectTarget]);
  useEffect(() => {
    if (companyId) {
      getViewTags(companyId).then(({
        data
      }) => updateCompanyTags(data.results.map(t => t.toUpperCase())));
    }
  }, [companyId]);

  // Calculate tag widths
  const handleResize = () => {
    if (!stackTagsRef.current.children.length) {
      // Tags aren't loaded yet so skip for now.
      return;
    }
    const stackWidth = stackTagsRef.current.offsetWidth;
    let newMaxTags = 0;
    let currentRow = 0;
    let widthLeft = stackWidth;
    for (let i = 0; i < stackTagsRef.current.children.length; i++) {
      const tag = stackTagsRef.current.children[i];
      console.log(tag.offsetWidth);
      if (tag.offsetWidth > widthLeft) {
        if (currentRow === 0) {
          currentRow = 1;
          widthLeft = stackWidth - 41; // 41px is the hardcoded width of the "show more" button.
          continue;
        }
        setHasShowMore(true);
        break;
      } else {
        widthLeft -= tag.offsetWidth + 8; // 8px is the right margin that for some reason isn't included in offsetWidth
        newMaxTags++;
      }
    }
    setMaxTags(newMaxTags);
  };

  // Handle show more tag calculation on start;
  useLayoutEffect(() => handleResize(), [tags]);

  // On window resize, recalculate tags
  useEffect(() => {
    const debouncedHandleResize = debounce(() => handleResize(), 500);
    window.addEventListener('resize', debouncedHandleResize);
    return () => window.removeEventListener('resize', debouncedHandleResize);
  });
  const onSubmit = newTags => {
    return patchView(objectTarget.id, {
      tags: newTags
    }).then(({
      data
    }) => {
      toasterService.success(`Successfully updated ${viewName} tags.`);
      if (onSuccess) {
        onSuccess(data);
      }
    }).catch(e => {
      toasterService.error('An unknown error occured trying to save tags. Please try again');
      return Promise.reject();
    });
  };
  const addNewTag = newTag => {
    console.log(newTag);
    newTag = newTag.toUpperCase();
    updateTagValue('');
    if (!companyTags.includes(newTag)) {
      updateCompanyTags([...companyTags, newTag]);
    }
    if (!tags.includes(newTag)) {
      updateTags([...tags, newTag]);
    }
    setShowInput(false);
  };
  const deleteTag = tag => {
    const index = tags.indexOf(tag);
    if (index > -1) {
      updateTags([...tags.slice(0, index), ...tags.slice(index + 1)]);
      onSubmit([...tags.slice(0, index), ...tags.slice(index + 1)]);
    } else {
      updateTags([...tags, tag]);
      onSubmit([...tags, tag]);
    }
  };
  console.log(maxTags);
  return <Box width="100%">
      <Stack id="chip-stack" ref={stackTagsRef} direction="row" display="flex" flexWrap="wrap" overflow="hidden">
        {tags !== null && tags.map((tag, index) => {
        return (showMore || index < maxTags) && <Chip label={tag} key={index} variant="tag" sx={{
          mb: 1,
          mr: 1
        }} onClick={onClick} onDelete={editTagMode ? () => deleteTag(tag) : null} deleteIcon={<CancelIcon sx={styles.icon} />} />;
      })}
        {editTagMode && <Chip variant="tag" label={showInput ? <Input disableUnderline id="standard-basic" sx={{
        width: 36
      }} inputProps={{
        style: {
          padding: 0
        }
      }} value={tagValue} onChange={e => updateTagValue(e.target.value)} size="small" variant="standard" /> : <AddIcon sx={styles.icon} />} onClick={() => setShowInput(true)} onDelete={showInput ? () => addNewTag(tagValue) : null} deleteIcon={<DoneIcon sx={styles.icon} />} />}
        {hasShowMore && !editTagMode && <TextExpand onClick={() => setShowMore(!showMore)} textContent={!showMore ? 'Show More' : 'Show Less'} />}
      </Stack>
    </Box>;
};
export default Tags;