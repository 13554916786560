import React, { Component } from 'react';
import { userState } from '../../shared/user-state';
import { Button } from '../../components/button/button.component';
import { getShopifyShops, addShopifyShop, deleteShopifyShop, authorizeShopifyShop } from './integrations.resource';
import { toasterService } from '../../components/toaster/toaster-service';
import { Icon } from '../../components/icon/icon.component';
import { ModalDialog } from '../../components/modal/modal-dialog.component';
import { ModalCompanySelect } from '../../components/modal/modal-company-select.component';
export class IntegrationShopify extends Component {
  static propTypes = {};
  state = {
    shops: [],
    deleteShop: {},
    isModalOpen: false,
    isGettingStarted: false,
    companySelectArgs: false,
    shopName: null
  };
  componentDidMount() {
    this.shopifyRedirect().then(hasRedirect => hasRedirect && this.props.history.push('/integrations')).then(() => this.getShopifyData()).finally(() => this.props.onLoad());
  }
  shopifyRedirect = () => {
    return new Promise(resolve => {
      const {
        match,
        location
      } = this.props;
      if (match.params.redirect === 'shopify-redirect') {
        const shopName = new URLSearchParams(location.search).get('shop').replace('.myshopify.com', '');
        this.authorizeShop(shopName).then(() => resolve(false));
      } else if (match.params.redirect === 'shopify-authorize') {
        const params = new URLSearchParams(location.search);
        this.addShop({
          ...Object.fromEntries(params),
          name: params.get('shop').replace('.myshopify.com', '')
        }).then(() => resolve(true));
      } else {
        resolve(false);
      }
    });
  };
  getShopifyData = () => {
    return getShopifyShops({
      company: userState.getAsCompanyId()
    }).then(({
      results
    }) => this.setState({
      shops: results
    }));
  };
  authorizeShop = shopName => {
    return authorizeShopifyShop(shopName).then(({
      url
    }) => window.location.href = url);
  };
  addShop = companySelectArgs => {
    if (userState.hasMultiAccess()) {
      this.setState({
        companySelectArgs
      });
      return Promise.resolve(false);
    } else {
      return this.finallyAddShop({
        ...companySelectArgs,
        companyId: userState.getAsCompanyId()
      });
    }
  };
  finallyAddShop = ({
    name,
    code,
    hmac,
    timestamp,
    companyId,
    token
  }) => {
    return addShopifyShop(name, code, hmac, timestamp, companyId, token).then(() => toasterService.success('Successfully added shop.  Data import will begin shortly and can take several minutes.')).then(() => this.setState({
      isGettingStarted: true
    })).then(() => this.getShopifyData()).catch(err => {
      const key = Object.keys(err.response.data)[0];
      toasterService.error(err.response.data[key][0]);
    });
  };
  removeShop = shopId => {
    return deleteShopifyShop(shopId).then(() => toasterService.success('Successfully deleted shop')).then(() => this.getShopifyData()).catch(() => toasterService.error('Unknown error trying delete shop. Please try again.'));
  };
  render() {
    const {
      shops,
      isModalOpen,
      isGettingStarted,
      deleteShop,
      companySelectArgs
    } = this.state;
    return <div className="integration mb-5">
        <div className="integration__header">
          <div>Shopify</div>
          {userState.hasPermission('shopify.add_shop') && <Button className="btn" actionType="primary" tag="a" onClick={() => this.setState({
          isModalOpen: true
        })}>
              Add Shop
            </Button>}
        </div>
        <div className="integration__body">
          {shops.map(shop => <div className="integration-item" key={shop.id}>
              <div className="integration-item__inner">
                <div className="integration-title">
                  <strong>{shop.name}.myshopify.com</strong>
                </div>
                <div className="integration-actions">
                  {userState.hasPermission('shopify.delete_shop') && <Button actionType="flat" icon="fa-regular-trash" onClick={e => {
                e.stopPropagation();
                this.setState({
                  deleteShop: shop
                });
              }} />}
                  {userState.hasPermission('shopify.change_shop') && shop.version !== '2020-04' && <Button actionType="flat" icon="fa-regular-sync-alt" onClick={e => {
                e.stopPropagation();
                window.open(`${API_URL}/shopify/shops/request?shop=${shop.domain}.myshopify.com`, '_blank');
              }} />}
                </div>
              </div>
            </div>)}
        </div>

        <ModalCompanySelect open={!!companySelectArgs} onClose={() => this.setState({
        companySelectArgs: false
      })} onSubmit={companyId => this.finallyAddShop({
        ...companySelectArgs,
        companyId
      })} onCancel={() => toasterService.error('You must select a company to link your account')}>
          <p>Select which company to link this Shopify account.</p>
        </ModalCompanySelect>

        <ModalDialog open={!!deleteShop.id} title="Remove Shop" submitText="Remove" onSubmit={() => this.removeShop(deleteShop.id)} onClose={() => this.setState({
        deleteShop: {}
      })} allowBackdropClick>
          <p>
            <strong>{deleteShop.name}</strong>
          </p>
          <p>Are you sure you want to remove this shop?</p>
        </ModalDialog>

        <ModalDialog open={isModalOpen} title="Add Shop" onClose={() => this.setState({
        isModalOpen: false
      })} allowBackdropClick>
          <p>
            To install the Cinch App for your Shopify Store, please do so from
            the{' '}
            <a href="https://apps.shopify.com/cinch-marketing" target="_blank">
              Shopify App Store
            </a>
            .
          </p>
        </ModalDialog>

        <ModalDialog open={isGettingStarted} title="Getting Started" onClose={() => this.setState({
        isGettingStarted: false
      })} allowBackdropClick onSubmit={() => {}} submitText="Done" size="lg">
          <p>
            Thank you for installing the Cinch App. Your data is currently
            syncing. While you wait for your data to finish syncing explore your
            marketing possibilities by:
          </p>

          <div className="d-flex">
            <div className="pr-5 pt-2">
              <Icon name="fa-solid-map-marker-alt" size={26} />
            </div>
            <div>
              <h3 className="text-uppercase">Creating Customer Segments</h3>
              <p>
                <a href="/segments">Customer Segments</a> allow you to target
                specific groups of customers. By targeting segments of customers
                you can effectively allocate marketing resources and maximize
                cross- and up-selling opportunities.
              </p>

              <p className="pb-3">
                Cinch allows you to create segments of customers based on
                various types of information you've collected from the
                customers. Segments can also be based on transaction history.
              </p>
            </div>
          </div>

          <div className="d-flex">
            <div className="pr-5 pt-2">
              <Icon name="fa-solid-code-branch" size={26} />
            </div>
            <div>
              <h3 className="text-uppercase">Creating Customer Journeys</h3>
              <p>
                <a href="/customer-journeys">Customer Journeys</a> allow you to
                automate your marketing efforts. Create drip campaigns that send
                emails after purchases. Add customers to Facebook Ad Audiences.
              </p>

              <p className="pb-3">
                Cinch allows you to create campaigns from simple to complex. The
                possibilities are endless.
              </p>
            </div>
          </div>

          <div className="d-flex">
            <div className="pr-5 pt-2">
              <Icon name="fa-solid-bullhorn" size={26} />
            </div>
            <div>
              <h3 className="text-uppercase">
                Creating Print And Email Templates
              </h3>
              <p>
                <a href="/templates">Print and Email Templates</a> allow you to
                not only communicate with your customers digitally but also by
                mail.
              </p>

              <p className="pb-3">
                Cinch allows you to create these templates right in your
                browser. Using advanced online editors you can create templates
                with dynamic data allowing you to personalize them as they are
                sent out.
              </p>
            </div>
          </div>
        </ModalDialog>
      </div>;
  }
}