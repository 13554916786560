import React, { useEffect, useState } from 'react';
import { getter } from './utils';
import { Scoped } from 'kremling';
import styles from './index.styles.scss';
import { Icon } from '../../icon/icon.component';
import { Button } from '../../button/button.component';
import moment from 'moment-timezone';
import numeral from 'numeral';
const fieldsToExclude = ['location_name', 'total', 'transaction_when', 'vehicle'];
const fieldsDetailsToExclude = ['item', 'item_descr', 'item_type', 'price_total', 'quantity'];
export function TransactionsComponent(props) {
  // These props are a lazy way to pass data around. We need to optimize this later.
  const {
    transactions,
    selectedTransaction,
    customerDetails,
    availableModelsFields,
    availableModels
  } = props;
  const [i, setTransactionIndex] = useState(0);
  const [vehicle, setVehicle] = useState({});
  const [locationName, setLocationName] = useState('');
  const [showMoreFields, setShowMoreFields] = useState(false);
  const [showMoreDetails, setShowMoreDetails] = useState(false);
  const [detailSelected, setDetailSelected] = useState(null);
  const setLocationAndVehicle = i => {
    if (transactions[i] && transactions[i].location_name) {
      setLocationName(transactions[i].location_name);
    }
    if (transactions[i] && transactions[i].possession) {
      const getVehicle = customerDetails.vehicles.filter(v => v.possession === transactions[i].possession);
      setVehicle(getVehicle[0]);
    }
  };
  useEffect(() => {
    let toSelect = 0;
    if (selectedTransaction) {
      toSelect = transactions.findIndex(t => t.id === selectedTransaction);
      if (toSelect === -1) {
        toSelect = 0;
      }
    }
    setLocationAndVehicle(toSelect);
    setTransactionIndex(toSelect);
  }, []);
  const handleTransactionIndex = i => {
    setLocationAndVehicle(i);
    setTransactionIndex(i);
  };
  const transactionFields = availableModelsFields.find(model => model.id === 'transaction').fields.filter(f => !fieldsToExclude.includes(f.id));
  const transactionDetailsFields = availableModelsFields.find(model => model.id === 'transaction_detail').fields.filter(f => !fieldsDetailsToExclude.includes(f.id));
  return <Scoped css={styles}>
      <div className="drawer__body" style={{
      height: 'calc(100vh - 9rem)',
      overflowY: 'auto'
    }}>
        <div className="d-flex justify-content-between">
          <Button icon="fa-solid-caret-left" disabled={transactions.length === 0 || i === transactions.length - 1} onClick={() => handleTransactionIndex(i + 1)} />
          {transactions && transactions[i] && <span style={{
          marginTop: '8px'
        }}>
              {!!locationName && locationName}{' '}
              {moment(transactions[i].transaction_when).local().format('MMM D, YYYY h:mm A')}
            </span>}
          <Button icon="fa-solid-caret-right" disabled={i === 0} onClick={() => handleTransactionIndex(i - 1)} />
        </div>
        {(!transactions || !transactions[i]) && <div className="my-5">
            <em>Customer has no transactions</em>
          </div>}
        {transactions && transactions[i] && <>
            <table className="my-5 data-table">
              <tbody>
                {!!locationName && <tr>
                    <th>LOCATION</th>
                    <td>{locationName}</td>
                  </tr>}
                {!!vehicle && Object.keys(vehicle).length !== 0 && <tr>
                    <th>VEHICLE</th>
                    <td>
                      {vehicle.year} {vehicle.make} {vehicle.model}
                    </td>
                  </tr>}
                {!!vehicle && Object.keys(vehicle).length !== 0 && vehicle.tag_state && vehicle.tag && <tr>
                    <th>VEHICLE TAGS</th>
                    <td>
                      {vehicle.tag_state}-{vehicle.tag}
                    </td>
                  </tr>}
                {!!vehicle && Object.keys(vehicle).length !== 0 && vehicle.vin && <tr>
                    <th>VEHICLE VIN</th>
                    <td>
                        {vehicle.vin}
                    </td>
                </tr>}
                {!!vehicle && Object.keys(vehicle).length !== 0 && transactions[i].txn_odometer && <tr>
                    <th>VEHICLE ODOMETER</th>
                    <td>
                        {transactions[i].txn_odometer}
                    </td>
                </tr>}
                <tr>
                  <th>TRANSACTION DATE</th>
                  <td>
                    {moment(transactions[i].transaction_when).local().format('MMM D, YYYY h:mm A')}
                  </td>
                </tr>
                <tr>
                  <th>TRANSACTION AMOUNT</th>
                  <td>{numeral(transactions[i].total).format('$0,0.00')}</td>
                </tr>
              </tbody>
            </table>

            <div className="div-table">
              {showMoreFields && availableModels.indexOf('transaction') > -1 && transactionFields.map((field, index) => {
            const fieldValue = transactions[i][field.id];
            if (!showMoreFields && index > 7) {
              return;
            }
            return fieldValue !== undefined && fieldValue !== null && fieldValue !== '' ? <div key={field.id} className={'mt-2 column'}>
                      <h4>{field.name}</h4>
                      <p>
                        <i>
                          {field.type === 'datetime' ? moment(fieldValue).local().format('MMM D, YYYY h:mm A') : field.type === 'date' ? moment(fieldValue).format('MMM D, YYYY') : fieldValue}
                        </i>
                      </p>
                    </div> : field.id.substring(0, 13) === 'entity_data__' ? <div key={field.id} className={'mt-2 column'}>
                      <h4>{field.name}</h4>
                      <p>
                        {getter(transactions[i], field.id.split('__')) || <i>No Data Available</i>}
                      </p>
                    </div> : field.id.substring(0, 15) === 'appended_data__' ? <div key={field.id} className={'mt-2 column'}>
                      <h4>{field.name}</h4>
                      <p>
                        {getter(transactions[i], field.id.split('__')) || <i>No Data Available</i>}
                      </p>
                    </div> : <React.Fragment key={field.id} />;
          })}
            </div>
            {transactionFields.length > 4 && <div className="buttonCenter mt-2">
                <Button tag="a" onClick={() => setShowMoreFields(!showMoreFields)}>
                  {showMoreFields ? 'Show Less' : 'Show More'}
                </Button>
              </div>}
            <div className="table-header mt-5">Transaction Details</div>
            <table className=" table-list w-100 no-hover">
              <thead>
                <tr>
                  <th>
                    ITEM TYPE
                    <p style={{
                  marginLeft: '8px',
                  marginBottom: 0
                }}>
                      ITEM DESCR
                    </p>
                  </th>
                  <th>ITEM</th>
                  <th style={{
                width: '60px'
              }}>QTY</th>
                  <th style={{
                width: '60px'
              }}>PRICE</th>
                  <th style={{
                width: '24px'
              }}></th>
                </tr>
              </thead>
              <tbody>
                {transactions[i].details.map((detail, index) => {
              if (!showMoreDetails && index > 4) {
                return;
              }
              return <React.Fragment key={detail.id}>
                      <tr>
                        <th className="pb-0">{detail.item_type}</th>
                        <th className="pb-0">{detail.item}</th>
                        <td className="pb-0">{detail.quantity}</td>
                        <td className="pb-0">
                          {numeral(detail.price_total).format('$0,0.00')}
                        </td>
                        <td className="pb-0" onClick={() => setDetailSelected(detail.id === detailSelected ? null : detail.id)}>
                          {' '}
                          <Icon className="ml-2" name={detail.id === detailSelected ? 'fa-solid-sort-up' : 'fa-solid-sort-down'} size={14} />
                        </td>
                      </tr>
                      <tr>
                        <td colspan="5" className="pt-0">
                          {detail.item_descr}
                          <div className="div-table" style={{
                      flexWrap: 'unset'
                    }}>
                            {detail.id === detailSelected && availableModels.indexOf('transaction_detail') > -1 && transactionDetailsFields.map((field, index) => {
                        const fieldValue = detail[field.id];
                        return fieldValue !== undefined && fieldValue !== null && fieldValue !== '' ? <div key={index} className={'mt-2 column'}>
                                    <h4>{field.name}</h4>
                                    <p>
                                      <i>
                                        {field.type === 'datetime' ? moment(fieldValue).local().format('MMM D, YYYY h:mm A') : field.type === 'date' ? moment(fieldValue).format('MMM D, YYYY') : fieldValue}
                                      </i>
                                    </p>
                                  </div> : field.id.substring(0, 13) === 'entity_data__' ? <div key={index} className={'mt-2 column'}>
                                    <h4>{field.name}</h4>
                                    <p>
                                      {getter(detail[index], field.id.split('__')) || <i>No Data Available</i>}
                                    </p>
                                  </div> : field.id.substring(0, 15) === 'appended_data__' ? <div key={index} className={'mt-2 column'}>
                                    <h4>{field.name}</h4>
                                    <p>
                                      {getter(detail[index], field.id.split('__')) || <i>No Data Available</i>}
                                    </p>
                                  </div> : <></>;
                      })}
                          </div>
                        </td>
                      </tr>
                    </React.Fragment>;
            })}
              </tbody>
            </table>
            {transactions[i].details.length > 5 && <div className="buttonCenter mt-2">
                <Button tag="a" onClick={() => setShowMoreDetails(!showMoreDetails)}>
                  {showMoreDetails ? 'Show Less' : 'Show More'}
                </Button>
              </div>}
            {transactions[i].coupons.length > 0 && <table className="mt-5 table-list w-100 no-hover">
                <thead>
                  <tr>
                    <th>COUPON CODE</th>
                    <th style={{
                width: '30%'
              }}>DISCOUNT TYPE</th>
                    <th style={{
                width: '30%'
              }}>DISCOUNT AMOUNT</th>
                  </tr>
                </thead>
                <tbody>
                  {transactions[i].coupons.map(coupon => <tr key={coupon.id}>
                      <th className="pb-0">{coupon.code}</th>
                      <td className="pb-0">{coupon.discount_type}</td>
                      <td className="pb-0">{coupon.discount_amount}</td>
                    </tr>)}
                </tbody>
              </table>}

            <div className="d-flex justify-content-flex-end w-100">
              <table className="mt-5 data-table">
                <tbody>
                  <tr>
                    <th className="pb-5">SUBTOTAL</th>
                    <td style={{
                  width: '60px'
                }}>
                      {numeral(transactions[i].subtotal).format('$0,0.00')}
                    </td>
                  </tr>
                  <tr>
                    <th>TAX 1</th>
                    <td>{numeral(transactions[i].tax1).format('$0,0.00')}</td>
                  </tr>
                  <tr>
                    <th className="pb-5">TAX 2</th>
                    <td>{numeral(transactions[i].tax2).format('$0,0.00')}</td>
                  </tr>
                  <tr>
                    <th>TRANSACTION AMOUNT</th>
                    <td>{numeral(transactions[i].total).format('$0,0.00')}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>}
      </div>
    </Scoped>;
}