import * as React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import { LicenseInfo } from '@mui/x-license-pro';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Typography from '@mui/material/Typography';
import { Icon } from '../../icon/icon.component';
export const FilterMenu = props => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [popperHeight, setPopperHeight] = React.useState(window.innerHeight - 180);
  LicenseInfo.setLicenseKey('ab02b215e727d277d4348b479f14c9bfTz01NDQ5OCxFPTE3MDAyMzk2NzQ0NzgsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');
  const handleClick = event => {
    setAnchorEl(open ? null : event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  React.useEffect(() => {
    setPopperHeight(window.innerHeight - 180);
  }, [window.innerHeight]);
  return <React.Fragment>
      <Box sx={{
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      fontFamily: 'Nexa-Regular',
      fontWeight: 400,
      lineHeight: '16.8px'
    }}>
        <Tooltip title="Filter">
          <IconButton onClick={handleClick} sx={{
          width: '24px',
          height: '24px',
          textAlign: 'center',
          padding: 0,
          color: '#1D252D'
        }}>
            <Icon name="custom-tune" />
          </IconButton>
        </Tooltip>
      </Box>
      <Popper anchorEl={anchorEl} id="account-menu" sx={{
      zIndex: 200
    }} open={open} onClose={handleClose} title="Filters" transformorigin={{
      horizontal: 'right',
      vertical: 'top'
    }} anchororigin={{
      horizontal: 'right',
      vertical: 'bottom'
    }}>
        <ClickAwayListener onClickAway={handleClose}>
          <Paper elevation={0} sx={{
          p: 1,
          borderRadius: '16px',
          overflow: 'visible',
          width: '290px',
          overflowY: 'scroll',
          height: `${popperHeight}px`,
          scrollbarWidth: 'thin',
          '&::-webkit-scrollbar': {
            width: '0.4em'
          },
          '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
            marginTop: '20px',
            marginBottom: '20px'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#888'
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#555'
          },
          filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))',
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 200
          }
        }}>
            <Box sx={{
            p: 1
          }}>
              <Grid container justifyContent="center">
                <Typography variant='tableHeader'> Filters</Typography>
              </Grid>

              {props.filterOptions.map(filter => {
              const {
                key: k,
                ...subProps
              } = filter;
              return <div key={k}>
                    {React.createElement(filter.component, {
                  ...subProps,
                  filterKey: k,
                  filters: props.filters,
                  handleFilterChange: props.handleFilterChange
                })}
                    <Divider sx={{
                  my: 1
                }} />
                  </div>;
            })}

              <Grid container justifyContent="flex-end">
                <Button variant="text" onClick={() => {
                props.handleFilterChange({});
                handleClose();
              }}>
                  <Typography sx={{
                  textTransform: 'none',
                  color: '#53A6D6'
                }}>Clear all </Typography>
                </Button>
              </Grid>
            </Box>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </React.Fragment>;
};