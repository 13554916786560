import React, { useState, useEffect, useContext } from 'react';
import { Scoped } from 'kremling';
import { Modal } from '../../components/modal/modal.component';
import { toasterService } from '../../components/toaster/toaster-service';
import { Button } from '../../components/button/button.component';
import { Loader } from '../../components/loader/loader.component';
import { PageHeader } from '../../components/page-header/page-header';
import { getEmail, patchEmail, getCompanyFields, getDomains, createEmailRevision, getEmailRevision, updateEmailRevision, getSegments, getPremadeBlocksTemplate, createPremadeBlocksTemplate, updatePremadeBlocksTemplate, deletePremadeBlocksTemplate } from '../../shared/common.api';
import { userState, hasPermission } from '../../shared/user-state';
import { topolJson, topolPremadeBlocks } from './email-template.utils';
import styles from './template-list.styles.scss';
import { Status } from '../../components/status/status.component';
import { Icon } from '../../components/icon/icon.component';
import { isEmpty } from 'lodash';
import moment from 'moment-timezone';
import { IFrame, DynamicTypeAhead, Dropdown } from 'components';
import { api } from '../../shared/api';
import { WorkingAsContext } from '../../context/working-as-context';
const isEmail = email => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};
const blockInitialValue = {
  name: null,
  company: userState.getAsCompanyId(),
  definition: null,
  block_type: null,
  premade_category: null,
  img: null,
  template: null
};
export const EditEmailTemplate = props => {
  const [email, setEmail] = useState({});
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [revision, setRevision] = useState({});
  const [revisionID, setRevisionID] = useState();
  const [forceReload, setForceReload] = useState(false);
  const [mergeTags, setMergeTags] = useState();
  const [showEditDetails, setShowEditDetails] = useState(false);
  const [showSendTest, setShowSendTest] = useState(false);
  const [testData, setTestData] = useState({});
  const [loading, setLoading] = useState(true);
  const [formErrors, setFormErrors] = useState({});
  const [emailDetailsPublish, setEmailDetailsPublish] = useState(false);
  const [savedBlocks, setSavedBlocks] = useState([]);
  const [savedBlock, setSavedBlock] = useState(blockInitialValue);
  const [premadeBlocks, setPremadeBlocks] = useState([]);
  const [savedBlockModal, setSavedBlockModal] = useState(false);
  const [saveAndCloseModal, setSaveAndCloseModal] = useState(false);
  const [publishReminder, setPublishReminder] = useState(false);
  const {
    checkCompany
  } = useContext(WorkingAsContext);
  const updateName = name => {
    setEmail({
      ...email,
      name
    });
    patchEmail(email.id, {
      name
    });
  };
  const getErrorMessage = () => {
    const keys = Object.keys(formErrors).filter(key => formErrors[key]).map(key => {
      if (key === 'subject') return 'Email Subject';
      if (key === 'from_address') return 'Valid Email From Address';
      if (key === 'from_name') return 'From Name';
    });
    if (keys.length > 0) {
      return `Please provide a ${keys.join(', ')}`;
    }
  };
  const handlePublish = () => {
    if (!email.name) {
      toasterService.error('Template Name is required!');
    } else {
      setLoading(true);
      setShowEditDetails(false);
      setEmailDetailsPublish(false);
      setRevision({
        ...revision,
        published_when: new Date()
      });
      TopolPlugin.save();
    }
  };
  const handleCloseClick = () => {
    if (!!props.history.location.state) {
      props.history.push({
        pathname: props.history.location.state,
        state: 'draft'
      });
    } else {
      props.history.push('/templates');
    }
  };
  const checkFormErrors = () => {
    return !!formErrors.from_name || !!formErrors.subject || !!formErrors.from_address;
  };
  const validateEmailDetails = () => {
    return !checkFormErrors() && !!revision.subject && !!revision.from_address && !!revision.from_name;
  };
  const openSendTestModal = () => {
    TopolPlugin.save();
    setShowSendTest(true);
  };
  const updateRevision = e => {
    const name = e.target.name;
    const value = e.target.value;
    setRevision({
      ...revision,
      [name]: value
    });
  };
  const onBlur = (key, val) => {
    let isInvalid = key === 'from_address' && !isEmail(val) || isEmpty(val);
    setFormErrors({
      ...formErrors,
      [key]: isInvalid
    });
  };
  const savePlugin = () => {
    setShowEditDetails(false);
    TopolPlugin.save();
  };
  const updateDraft = (json, html, callback) => {
    setRevision(revision => {
      const newRevision = {
        ...revision,
        template: json,
        body_html: html
      };
      updateEmailRevision(newRevision.id, newRevision).then(() => {
        if (callback) {
          callback(revision);
        }
      });
      return newRevision;
    });
  };
  const checkPublished = revision => {
    if (revision.published_when) {
      // We just published a new revision
      loadEmail(email.id).then(() => {
        setForceReload(val => !val);
      });
    }
  };
  const goBack = () => {
    const {
      idEmailTemplate
    } = props.match.params;
    getEmail(idEmailTemplate).then(({
      data
    }) => {
      const revision_has_changes = data.revisions.find(rev => rev.id == revisionID).has_unpublished_changes;
      const reminder = localStorage.getItem('templatePublishChangesReminder');
      if (revision_has_changes && reminder != 'true') {
        setSaveAndCloseModal(true);
      } else {
        handleCloseClick();
      }
    });
  };
  const sendTest = () => {
    let message = `${revision.body_html}`;
    if (testData.message) {
      message = `<div><p>${testData.message.replace(/(?:\r\n|\r|\n)/g, '<br>')}</p></div>${message}`;
    }
    message += "<div><p style='font-weight:800;color:red;text-align:center;margin:20px 0 10px 0'>This email was sent for TESTING purposes only.</p></div>";
    api.post(`/sendgrid/test`, {
      to_address: testData.to,
      from_name: revision.from_name,
      preview_text: revision.preview_text || '',
      subject: revision.subject,
      from_address: revision.from_address,
      body_plain: revision.body_plain || ' ',
      body_html: message,
      segment_id: testData.segment_id,
      company_id: userState.getAsCompanyId()
    }).then(() => {
      toasterService.success('Successfully sent test email');
    });
    setShowSendTest(false);
  };
  const fetchPremadeBlocks = () => {
    getPremadeBlocksTemplate({
      company: userState.getAsCompanyId()
    }).then(({
      data
    }) => {
      if (data) {
        const premadeBlocks = data.email_premade;
        const savedBlocks = data.email_saved.map((block, index) => ({
          ...block,
          uid: block.id,
          id: index
        }));
        setPremadeBlocks(premadeBlocks);
        setSavedBlocks(savedBlocks);
        if (TopolPlugin) {
          // There's no function to update the premade blocks.
          TopolPlugin.setSavedBlocks(savedBlocks);
        }
      }
    });
  };
  useEffect(() => {
    fetchPremadeBlocks();
    const interval = setInterval(() => TopolPlugin.save(), 60000);
    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    if (!revisionID) return;
    // LOAD REVISION DETAILS NOW!
    setLoading(true);
    TopolPlugin.destroy();
    getEmailRevision(revisionID).then(({
      data
    }) => {
      setRevision(data);
      if (!data.published_when) {
        // Only load TOPOL if the revision is not published

        const TOPOL_OPTIONS = {
          id: '#widget',
          light: true,
          authorize: {
            apiKey: TOPOL_TOKEN,
            userId: userState.getAsCompanyId()
          },
          mergeTags: mergeTags,
          //removeTopBar: !!data.published_when,
          topBarOptions: ['undoRedo', 'changePreview', 'previewSize', 'saveAndClose', 'save'],
          savedBlocks,
          premadeBlocks,
          contentBlocks: {
            product: {
              hidden: true
            }
          },
          callbacks: {
            onInit: () => {
              if (!!data.published_when) {
                TopolPlugin.togglePreview();
              }
              setLoading(false);
            },
            onSave: (json, html) => updateDraft(json, html, checkPublished),
            onSaveAndClose: (json, html) => updateDraft(json, html, goBack),
            onBlockSave: (json, html) => {
              setSavedBlockModal({
                isOpen: true,
                type: 'create'
              });
              setSavedBlock({
                company: userState.state.asCompany.id,
                block_type: 'saved',
                definition: json.definition
              });
            },
            onBlockRemove(id) {
              setSavedBlock(savedBlocks.find(block => block.id === id));
              setSavedBlockModal({
                isOpen: true,
                type: 'delete'
              });
            },
            onBlockEdit(id) {
              setSavedBlock(savedBlocks.find(block => block.id === id));
              setSavedBlockModal({
                isOpen: true,
                type: 'edit'
              });
            }
          }
        };
        TopolPlugin.init(TOPOL_OPTIONS);
        TopolPlugin.load(JSON.stringify(data.template || topolJson));
      } else {
        setLoading(false);
      }
    });
  }, [revisionID, forceReload]);
  const handleSaveBlock = () => {
    let promise = Promise.resolve();
    if (savedBlockModal.type === 'create') {
      if (savedBlock.block_type === 'premade') {
        savedBlock.definition = [savedBlock.definition];
      }
      promise = promise.then(() => createPremadeBlocksTemplate(savedBlock));
    } else if (savedBlockModal.type === 'edit') {
      promise = promise.then(() => updatePremadeBlocksTemplate(savedBlock.uid, savedBlock));
    } else if (savedBlockModal.type === 'delete') {
      promise = promise.then(() => deletePremadeBlocksTemplate(savedBlock.uid, savedBlock));
    }
    setSavedBlockModal({
      isOpen: false,
      type: null
    });
    promise.then(() => fetchPremadeBlocks());
  };
  const loadEmail = id => {
    /*
     * Load the email from the API if the email is missing a draft revision create it.
     *
     * This function is called on page load and after a new revision is published.
     */
    return getEmail(id).then(({
      data
    }) => {
      // check for different company link
      return checkCompany(data.company).then(() => data);
    }).then(data => {
      const email = data;
      const isReadOnly = email.tags && email.tags.includes('READ-ONLY');
      const draft = (email.revisions || []).find(revision => !revision.published_when);
      if (!isReadOnly && !draft) {
        // If we don't have a draft for this email (and this isn't a read only template) then create one.
        let promise = Promise.resolve();
        if (email.revisions && email.revisions.length > 0) {
          // If we have a previous revision set up the draft based off of that revision
          promise = promise.then(() => getEmailRevision(email.revisions[0].id)).then(({
            data
          }) => {
            delete data.id;
            delete data.published_when;
            return data;
          });
        } else {
          // if we don't have a previous revision then make an set up an empty one.
          promise = promise.then(() => ({
            email: email.id,
            from_address: userState.state.asCompany.default_email,
            from_name: userState.state.asCompany.default_from_name
          }));
        }
        return promise.then(revision => createEmailRevision(revision)).then(({
          data
        }) => {
          if (!email.revisions) {
            email.revisions = [];
          }
          // Add new draft to top of revisions list.
          email.revisions.unshift(data);
          setEmail(email);
          if (email.revisions.length === 1) {
            setShowEditDetails(true);
          }
          return {
            email,
            draft: data
          };
        });
      }
      setEmail(email);
      setIsReadOnly(isReadOnly);
      if (isReadOnly) {
        const revisionPublished = email.revisions.find(r => !!r.published_when);
        if (revisionPublished) setRevisionID(revisionPublished.id);
      }
      return {
        email,
        draft,
        isReadOnly
      };
    });
  };
  useEffect(() => {
    /**
     * Init
     *
     * Load company fields, email details, and email domains.
     * Set up merge tags for TOPOL
     */
    const {
      idEmailTemplate
    } = props.match.params;
    Promise.all([getCompanyFields(userState.state.asCompany.id).then(({
      data
    }) => data), loadEmail(idEmailTemplate), getDomains({
      status: 'verified',
      //IS NOT FILTERING BY STATUS
      company: userState.getAsCompanyId(),
      ordering: '-updated_when',
      limit: 1
    }).then(({
      data
    }) => data.results)]).then(([fields, {
      draft,
      isReadOnly
    }, domains]) => {
      if (isReadOnly) return;
      const from_address_domain = domains && domains.length && `@${domains[0].domain}`;
      const topolMergeTags = fields.filter(field => ['cart_coupon', 'cart_detail', 'transaction_detail', 'coupon'].indexOf(field.id) === -1).map(field => ({
        name: field.name,
        items: field.fields.map(item => ({
          value: `{{ ${field.id}.${item.id.replace(/__/g, '.')} }}`,
          text: item.name
        }))
      }));
      topolMergeTags.push({
        name: 'Other',
        items: [{
          value: '{{ expires 15 }}',
          text: 'Expires in 15 Days'
        }, {
          value: '{{ expires 30 }}',
          text: 'Expires in 30 Days'
        }, {
          value: '{{ expires 45 }}',
          text: 'Expires in 45 Days'
        }, {
          value: '{{ expires 60 }}',
          text: 'Expires in 60 Days'
        }, {
          value: '{{ surveyLink }}',
          text: 'Survey Link'
        }, {
          value: '{{ swellReviewLink }}',
          text: 'Swell Review Link'
        }, {
          value: "{{ singleUseCouponCode 'coupon id here' }}",
          text: 'Single Use Coupon Code'
        }, {
          value: '<a href="{{ unsubscribe_url }}">Unsubscribe</a>',
          text: 'Unsubscribe Link'
        }]
      });
      setMergeTags(topolMergeTags);
      if (props.match.params.isFromCreate) {
        // If this is a newly created email template then open up the edit modal.
        setShowEditDetails(true);
      }
      setRevisionID(draft.id);
    }).catch(error => {
      toasterService.error('Something went wrong getting the email template ...');
    });
  }, [props]);
  const isSaveDisabled = () => {
    if (!savedBlock.name) {
      return true;
    } else if (!savedBlock.block_type) {
      return true;
    } else if (savedBlock.block_type === 'premade' && !savedBlock.premade_category) {
      return true;
    } else {
      return false;
    }
  };
  return <Scoped css={styles}>
      <div className="wrapper-contain email-container">
        <PageHeader actions={<>
              {loading && <Loader />}
              {!loading && <>
                  <div>
                    <select className="form-control" value={revision.id} onChange={e => setRevisionID(e.target.value)}>
                      {email.revisions.filter(r => !!r.published_when || !isReadOnly).map(revision => {
                return <option key={revision.id} value={revision.id}>
                              {!revision.published_when ? 'Draft' : `Published ${moment(revision.published_when).format('MMM D, YYYY h:mm A')}`}
                            </option>;
              })}
                    </select>
                  </div>
                  <Button onClick={openSendTestModal}>Send Test Email</Button>
                  {!revision.published_when && <Button onClick={() => setShowEditDetails(true)} title={!validateEmailDetails() ? 'Missing required fields' : undefined}>
                      Edit Email Details
                      {!validateEmailDetails() && <Icon className="missing-data ml-2" name="fa-solid-exclamation-triangle" size={12} />}
                    </Button>}
                </>}
              {!revision.published_when && !loading && <Button actionType="primary" onClick={() => {
          setShowEditDetails(true);
          setEmailDetailsPublish(true);
        }} disabled={!validateEmailDetails()} title={!validateEmailDetails() ? 'Missing required fields' : undefined}>
                   {!!email.revisions.filter(r => !!r.published_when).length ? 'Publish Changes' : 'Publish Template'}
                </Button>}
              {!!revision.published_when && !loading && <div>
                   <Status>Published</Status>
                  <Button className='ml-3' onClick={() => {
            handleCloseClick();
          }}>
                   {!!props.history.location.state ? 'Return to Journey' : 'Close'}
                  </Button>
                </div>}
            </>} name={email.name} updateName={updateName} />

        <div id="widget" style={{
        display: !revision.published_when ? 'block' : 'none'
      }} />

        {!!revision.published_when && <IFrame content={revision.body_html} style={{
        height: '100%',
        border: 0
      }} />}

        <Modal open={showEditDetails} onClose={() => setShowEditDetails(false)} allowBackdropClick title={showEditDetails && emailDetailsPublish ? 'Publish Email' : 'Edit Email Details'} size="xl" type="scroll">
          <div className="modal__body mb-4">
            <div className="row">
              <div className="col-8">
                <div className="row">
                  <div className="col-12">
                    <div className={!formErrors.subject ? 'form-group' : 'form-group is-invalid'}>
                      <label>
                        Email Subject <span className="form-error ">*</span>
                      </label>
                      <input className="form-control" type="text" name="subject" onBlur={e => onBlur(e.target.name, revision.subject)} value={revision.subject || ''} onChange={e => updateRevision(e)} />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className={!formErrors.from_name ? 'form-group' : 'form-group is-invalid'}>
                      <label>
                        From name <span className="form-error">*</span>
                      </label>
                      <input className="form-control" type="text" name="from_name" value={revision.from_name || ''} onBlur={e => onBlur(e.target.name, revision.from_name)} onChange={e => updateRevision(e)} />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className={!formErrors.from_address ? 'form-group' : 'form-group is-invalid'}>
                      <label>
                        Email From Address <span className="form-error">*</span>
                      </label>
                      <input className="form-control" type="email" name="from_address" value={revision.from_address || ''} onBlur={e => onBlur(e.target.name, revision.from_address)} onChange={e => updateRevision(e)} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <label>Plain message for older email clients</label>
                  <textarea className="form-control" style={{
                  minHeight: '10.2rem'
                }} name="body_plain" value={revision.body_plain || ''} onChange={e => updateRevision(e)} />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <label>Preview text for email template</label>
                  {!!revision.preview_text && <input className="form-control" type="text" name="preview_text" value={revision.preview_text || ''} onChange={e => updateRevision(e)} />}
                  {!revision.preview_text && <input className="form-control" type="text" name="preview_text" disabled={true} value="Preview text now found in Settings tab" />}
                </div>
              </div>
            </div>
            <div className="form-error float-left">* Required Fields</div>
            {checkFormErrors() ? <div className="form-error float-right">
                <Icon name="fa-solid-exclamation-triangle " className="mb-2 mr-2" size={14} />
                <b>{getErrorMessage()}</b>
              </div> : null}
          </div>
          <div className="modal__actions">
            <Button onClick={() => setShowEditDetails(false)}>Cancel</Button>
            <Button actionType="primary" disabled={!validateEmailDetails()} onClick={e => showEditDetails && emailDetailsPublish ? handlePublish() : savePlugin()}>
              {showEditDetails && emailDetailsPublish ? 'Publish' : 'Save'}
            </Button>
          </div>
        </Modal>
        <Modal open={showSendTest} onClose={() => setShowSendTest(false)} allowBackdropClick title="Send Test Email" type="scroll">
          <div className="modal__body">
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <label>Send Email To</label>
                  <input placeholder="example@cinch.io" className="form-control" type="text" name="testTo" value={testData.to || ''} onChange={e => setTestData({
                  ...testData,
                  to: e.target.value
                })} />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <label>Optionally populate with customer data:</label>
                  <DynamicTypeAhead getItems={getSegments} getItemsFilters={{
                  company: userState.getAsCompanyId()
                }} placeholder="Choose Customer Segment" displayProperty="name" keyProperty="id" value={testData.segment_id || ''} onChange={segment => setTestData({
                  ...testData,
                  segment_id: segment.id
                })} />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <label>
                    Message to include at the top of the test email.
                  </label>
                  <textarea name="testMessage" className="form-control" style={{
                  height: '75px'
                }} onChange={e => setTestData({
                  ...testData,
                  message: e.target.value
                })} value={testData.message || ''} />
                </div>
              </div>
            </div>
          </div>
          <div className="modal__actions">
            <Button onClick={() => setShowSendTest(false)}>Cancel</Button>
            <Button actionType="primary" disabled={!testData.to} onClick={sendTest}>
              Send
            </Button>
          </div>
        </Modal>
        <Modal open={savedBlockModal.isOpen} onClose={() => setSavedBlockModal({
        isOpen: false
      })} allowBackdropClick title={savedBlockModal.type === 'create' ? 'Create Block' : savedBlockModal.type === 'edit' ? 'Edit Block' : `Delete Block ${savedBlock.name}`} type="scroll">
          <div className="modal__body">
            <div className="row">
              <div className="col-12">
                {savedBlockModal.type === 'edit' || savedBlockModal.type === 'create' ? <>
                    <div className="form-group mt-2 mb-4">
                      <label>Block Name</label>
                      <input placeholder="Block Name" className="form-control" type="text" name="savedBlock" label={'Block Name'} value={savedBlock.name || ''} onChange={e => setSavedBlock({
                    ...savedBlock,
                    name: e.target.value
                  })} />
                    </div>
                    {userState.state.user.is_superuser && <>
                        <div className="form-group">
                          <label>Block Type</label>
                          <Dropdown disabled={savedBlockModal.type === 'edit' ? true : false} className="w-100" size="block" trigger={() => <Button disabled={savedBlockModal.type === 'edit' ? true : false} style={{
                      textTransform: 'capitalize'
                    }} block dropdown>
                                {savedBlock ? savedBlock.block_type : 'Choose a Block Type'}
                              </Button>} content={({
                      close
                    }) => <ul className="select-list">
                                <li key="premade">
                                  <a onClick={() => {
                          setSavedBlock({
                            ...savedBlock,
                            premade_category: null,
                            block_type: 'premade'
                          });
                          close();
                        }}>
                                    Premade
                                  </a>
                                </li>
                                <li key="saved">
                                  <a onClick={() => {
                          setSavedBlock({
                            ...savedBlock,
                            premade_category: null,
                            block_type: 'saved'
                          });
                          close();
                        }}>
                                    Saved
                                  </a>
                                </li>
                              </ul>} />
                        </div>
                        {savedBlock.block_type === 'premade' && <div className="form-group">
                            <label>Premade Category</label>
                            <Dropdown className="w-100" size="block" trigger={() => <Button style={{
                      textTransform: 'capitalize'
                    }} block dropdown>
                                  {savedBlock ? savedBlock.premade_category : 'Choose Premade Category'}
                                </Button>} content={({
                      close
                    }) => <ul className="select-list">
                                  {['headers', 'content', 'ecomm', 'footers'].map(category => <li key={category}>
                                      <a style={{
                          textTransform: category === 'ecomm' ? 'none' : 'capitalize'
                        }} onClick={() => {
                          setSavedBlock({
                            ...savedBlock,
                            premade_category: category
                          });
                          close();
                        }}>
                                        {category === 'ecomm' ? 'eCommerce' : category}
                                      </a>
                                    </li>)}
                                </ul>} />
                          </div>}
                      </>}
                  </> : <span>Are you sure you want to delete this block?...</span>}
              </div>
            </div>
          </div>
          <div className="modal__actions">
            <Button onClick={() => {
            setSavedBlock(blockInitialValue);
            setSavedBlockModal({
              isOpen: false
            });
          }}>
              Cancel
            </Button>
            <Button actionType="primary" disabled={savedBlockModal.isOpen && isSaveDisabled()} onClick={() => handleSaveBlock()}>
              Save
            </Button>
          </div>
        </Modal>
        <Modal open={saveAndCloseModal} onClose={() => setSaveAndCloseModal(false)} allowBackdropClick title={'Save & Close'} type="scroll">
          <div className="modal__body">
            <div className="row">

          <label> You have made changes to this template that are unpublished. Would you like to publish those changes now?</label>
            </div>
            <div className="row pt-3">
            <input type="checkbox" value={publishReminder} checked={publishReminder} onChange={e => {
              localStorage.setItem('templatePublishChangesReminder', !publishReminder);
              setPublishReminder(!publishReminder);
            }} />
               Do not show this message again this session
          </div>
          </div>
          <div className="modal__actions">
            <Button onClick={() => {
            setSaveAndCloseModal(false);
          }}>
              Cancel
            </Button>
            <Button actionType="primary" onClick={() => {
            setSaveAndCloseModal(false);
            toasterService.success('Successfully saved template');
            handleCloseClick();
          }}>
              No, Just Close
            </Button>

            <Button actionType="primary" onClick={() => {
            setSaveAndCloseModal(false);
            setShowEditDetails(true);
            setEmailDetailsPublish(true);
          }}>
              Yes, Publish
            </Button>
          </div>
        </Modal>
      </div>
    </Scoped>;
};