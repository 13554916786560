import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import EventIcon from '@mui/icons-material/Event';
import Avatar from '@mui/material/Avatar';
import Rating from '@mui/material/Rating';
import GoogleIcon from '@mui/icons-material/Google';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import OutlinedInput from '@mui/material/OutlinedInput';
import moment from 'moment';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { userState, hasPermission } from 'shared/user-state';
import { SmsTemplatesMenu } from 'components/templates/sms-templates.component';
import { Emoji } from 'components/emoji/emoji.component';
export default function ReplyModal(props) {
  const [hasBeenEdited, setHasBeenEdited] = React.useState(false);
  const [message, setMessage] = React.useState(props.data.reply);
  const [discardChangesDialog, setDiscardChangesDialog] = React.useState(false);
  const [discardChangesDialogCustomerDrawer, setDiscardChangesDialogCustomerDrawer] = React.useState(false);
  const convertNormalizedScore = normalizedScore => {
    if (normalizedScore == 0) return 1;
    if (normalizedScore > 0 && normalizedScore <= 0.25) return 2;
    if (normalizedScore > 0.25 && normalizedScore <= 0.5) return 3;
    if (normalizedScore > 0.5 && normalizedScore <= 0.75) return 4;
    if (normalizedScore > 0.75) return 5;
  };
  const insertEmoji = e => {
    setHasBeenEdited(true);
    setMessage(message ? message + e : e);
  };
  const handleTemplateChoice = (template, revision) => {
    setHasBeenEdited(true);
    setMessage(message ? message + revision.message : revision.message);
  };
  return <>
    <Dialog PaperProps={{
      style: {
        borderRadius: '28px',
        minWidth: '325px'
      }
    }} open={!!props.data} maxWidth="md" onClose={(event, reason) => {
      if (reason == 'backdropClick' && hasBeenEdited) {
        setDiscardChangesDialog(true);
      } else {
        props.onDataChange(null);
      }
    }}>
      <DialogTitle sx={{
        m: 0,
        p: 2,
        fontSize: 'medium'
      }}>
        <IconButton aria-label="close" onClick={e => {
          if (hasBeenEdited) {
            setDiscardChangesDialog(true);
          } else {
            props.onDataChange(null);
          }
        }} sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme => theme.palette.grey[500]
        }}>
          <CloseIcon />
        </IconButton>

        <Stack direction="row" spacing={2} onClick={e => {
          if (hasBeenEdited) {
            setDiscardChangesDialogCustomerDrawer(true);
            setDiscardChangesDialog(true);
          } else {
            props.onCustomerDrawer({
              id: props.data.customer_id
            });
          }
        }} sx={{
          cursor: 'pointer'
        }}>
          <Box>
            <Avatar />
          </Box>
          <Stack>
            <> {props.data.customer_first_name}  {props.data.customer_last_name}</>
            <Box sx={{
              display: 'flex'
            }}>
              <GoogleIcon />
              <Rating name="read-only" value={convertNormalizedScore(props.data.normalized_score)} readOnly />
            </Box>
          </Stack>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <DialogContentText>
          <Stack component="span">
          <Box component="span" sx={{
              color: '#1D252D'
            }}>
            <Stack direction="row" spacing={2} component="span">
              <Typography align="center" component={'span'} sx={{
                  color: '#1D252D'
                }}>
                <EventIcon />{' '}
                {moment(props.data.review_when).format('MMM D, YYYY')}
              </Typography>
              <Typography align="center" component={'span'} sx={{
                  color: '#1D252D'
                }}>
                <LocationOnIcon />
                {props.data.location.name} - {props.data.location.address1} {props.data.location.address2}, {props.data.location.city}, {props.data.location.state} {props.data.location.zip_code}{' '}
              </Typography>
            </Stack>
          </Box>
          <Box component="span" sx={{
              py: 2,
              color: '#1D252D'
            }}>
            {props.data.comment}
          </Box>
          </Stack>
        </DialogContentText>

        <Divider sx={{
          px: 2
        }} />

        <Box sx={{
          pt: 2
        }}>
          {props.data.reply_when && <Typography sx={{
            position: 'relative',
            top: '18px',
            left: '15px',
            color: 'rgba(29, 37, 45, 0.5)',
            fontSize: 'small'
          }}>
              {props.data.reply_when && moment(props.data.reply_when).format('dddd, MMMM D, YYYY | h:mm A')}
            </Typography>}
          <OutlinedInput sx={{
            p: 2,
            borderRadius: '16px',
            alignItems: !!props.data.reply_when ? '' : 'start'
          }} rows={5} multiline id="name" placeholder="Type reply" fullWidth variant="outlined" value={message} onChange={e => {
            setHasBeenEdited(true);
            setMessage(e.target.value);
          }} />
        </Box>
        <Stack sx={{
          pt: 2
        }} direction='row' spacing={2}>
            <SmsTemplatesMenu handleTemplateChoice={handleTemplateChoice} placement='top-start' />
            <Emoji openDirection="left" popout onEmojiClick={e => insertEmoji(e)} />
        </Stack>
      </DialogContent>

      <DialogActions sx={{
        py: 3
      }}>
        <Button onClick={() => {
          if (hasBeenEdited) {
            setDiscardChangesDialog(true);
          } else {
            props.onDataChange(null);
          }
        }} sx={{
          borderRadius: '20px',
          color: '#53A6D6',
          width: '125px',
          borderStyle: 'solid',
          borderColor: '#53A6D6',
          borderWidth: '1px'
        }}>
          {userState.hasPermission('reviews.add_review') ? 'Cancel' : 'Close'}
        </Button>
        {userState.hasPermission('reviews.add_review') && <Button disabled={!hasBeenEdited || message.length == 0} onClick={() => {
          props.onDataChange(null);
          props.onSubmit({
            comment: message
          });
        }} variant="contained" sx={{
          borderRadius: '20px',
          mr: 2,
          width: '125px'
        }}>
          {!!props.data.reply_when ? 'Update' : 'Post'}
        </Button>}
      </DialogActions>
    </Dialog>
    <Dialog PaperProps={{
      style: {
        borderRadius: '28px',
        width: '300px'
      }
    }} open={!!discardChangesDialog} onClose={() => {
      setDiscardChangesDialog(false);
    }}>
      <DialogTitle sx={{
        m: 0,
        p: 2,
        fontSize: 'medium'
      }}>
      <Stack alignItems="center" component="span">
        <ErrorOutlineIcon sx={{
            fontSize: 40,
            color: '#EF3C34'
          }} />
       <Typography variant="h4" component="span"> Discard Changes? </Typography>
      </Stack>
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          <Box component="span">
       <Typography component="span" sx={{
              color: '#1D252D'
            }}>You have unsaved changes that will be lost if you close this window. </Typography>
          </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{
        py: 1
      }}>
        <Button onClick={() => {
          setDiscardChangesDialog(false);
        }} variant="text" sx={{
          borderRadius: '20px',
          color: 'rgba(29, 37, 45, 0.7)',
          width: '75px'
        }}>
          Cancel
        </Button>
        <Button onClick={() => {
          if (discardChangesDialogCustomerDrawer) {
            props.onCustomerDrawer({
              id: props.data.customer_id
            });
          } else {
            props.onDataChange(null);
          }
        }} variant="text" sx={{
          mr: 1,
          borderRadius: '20px',
          width: '75px',
          color: '#53A6D6'
        }}>
          Discard
        </Button>
      </DialogActions>
    </Dialog>
    </>;
}