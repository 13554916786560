import React, { Component, createRef } from 'react';
import { object, func, bool, string } from 'prop-types';
import classnames from 'classnames';
import { Button } from '../../button/button.component';
import { ButtonGroup } from '../../button/button-group.component';
import { Drawer } from '../drawer.component';
import { getOptStatusSMS, sendCustomerMessage, getPhoneNumbers, getCustomerDetails, getCustomerMessages, unsubscribeEmail, updateSMSStatus, getCustomerActions, getCustomer } from '../../../shared/common.api';
import { Loader } from '../../loader/loader.component';
import { Scoped } from 'kremling';
import styles from './index.styles.scss';
import { getModels } from '../../rules/rules.resource';
import { toasterService } from '../../toaster/toaster-service';
import { userState } from '../../../shared/user-state';
import { ActionComponent } from './actions.component';
import { DetailsComponent } from './details.component';
import { MessagingComponent } from './messaging.component';
import { TimelineComponent } from './timeline.component';
import { TransactionsComponent } from './transactions.component';
import { SchedulesComponent } from './schedules.component';
import moment from 'moment-timezone';
const audio = new Audio('/assets/aud/wet.mp3');
export class DrawerCustomer extends Component {
  static propTypes = {
    customerID: string,
    isOpen: bool,
    onClose: func,
    startTab: string,
    startTransaction: string
  };
  static defaultProps = {
    customerID: null,
    isOpen: false,
    startTab: 'details'
  };
  state = {
    tab: null,
    customerDetails: null,
    customerActions: null,
    customerTimeline: null,
    availableModels: [],
    expandedItems: [],
    phoneNumbers: [],
    messages: [],
    interval: null,
    lastCheck: null,
    selectedTransaction: null,
    selectedSchedule: null,
    lastCompanyID: null
  };
  componentDidMount() {
    // When asCompany changes check our model information
    this.userObserver = userState.subscribe((...args) => this.updateFromState(...args), 'asCompany', 'user');
  }
  componentWillUnmount() {
    this.userObserver.unsubscribe();
  }
  componentDidUpdate(prevProps) {
    if (!userState.state.asCompany) {
      // We're not loaded yet... no company selected
      return;
    }
    const {
      customerID
    } = this.props;
    if (userState.hasPermission('customer.view_customer')) {
      if (!customerID) {
        clearInterval(this.state.interval);
      } else if (customerID && (prevProps.customerID !== customerID || !this.state.interval)) {
        clearInterval(this.state.interval);
        this.setState({
          lastCheck: new Date(),
          interval: setTimeout(() => this.getUpdates(), 5000)
        }, () => {
          this.loadCustomerDetails();
        });
      }
    }
  }
  updateFromState() {
    if (!userState.state.asCompany || !userState.state.user?.permissions || this.state.lastCompanyID === userState.state.asCompany.id) {
      return;
    }
    if (userState.hasPermission('customer.view_customer')) {
      getModels().then(allFields => {
        this.setState({
          availableModels: allFields
        });
      });
    }
    if (userState.hasPermission('customer.view_two_way_messaging')) {
      getPhoneNumbers({
        company: userState.getAsCompanyId(),
        limit: 100
      }).then(({
        data
      }) => this.setState({
        phoneNumbers: data.results
      }));
    }
    this.setState({
      lastCompanyID: userState.getAsCompanyId()
    });
  }
  getUpdates = () => {
    const {
      customer,
      phoneNumbers,
      customerDetails,
      lastCheck,
      messages
    } = this.state;
    if (!customer || !customer.phone || !phoneNumbers.length) {
      return;
    }
    const newLastCheck = new Date();
    getOptStatusSMS({
      phone: customer.phone,
      company: userState.getAsCompanyId()
    }).then(({
      data
    }) => {
      if (!data || !data.results) {
        return;
      }
      if (data.results.length > 0 && !customerDetails.sms_optstatus) {
        this.setState({
          customerDetails: {
            ...customerDetails,
            sms_optstatus: data.results[0].status
          }
        });
      } else if (data.results.length === 0 && customerDetails.sms_optstatus) {
        this.setState({
          customerDetails: {
            ...customerDetails,
            sms_optstatus: null
          }
        });
      }
    });
    getCustomerMessages(customer.id, {
      after: moment(lastCheck).subtract(3, 'minutes').toDate()
    }).then(({
      data
    }) => {
      if (data.length) {
        let hasNewMessage = false;
        data.filter(message => message.twilio_phone != '+13852179309' && message.twilio_phone != '+18018500116').reverse().forEach(message => {
          const oldIndex = messages.findIndex(oldMessage => oldMessage.id === message.id);
          if (oldIndex > -1) {
            messages[oldIndex] = message;
          } else {
            if (message.direction === 'incoming') {
              hasNewMessage = true;
            }
            messages.push(message);
          }
        });
        if (hasNewMessage) {
          audio.play();
        }
        this.setState({
          messages
        });
      }
      this.setState({
        lastCheck: newLastCheck,
        interval: setTimeout(() => this.getUpdates(), 5000)
      });
    });
  };
  unsubscribeCustomer = emailStatus => {
    const {
      customer,
      customerDetails
    } = this.state;
    if (!customer.company || !customer.email) {
      toasterService.error('Customer is missing required information to unsubscribe');
      return;
    }
    unsubscribeEmail(customer.company, customer.email, customerDetails.unsubscribe_id, emailStatus).then(({
      data
    }) => {
      this.setState({
        customerDetails: {
          ...this.state.customerDetails,
          unsubscribe_id: data.id,
          email_optstatus: emailStatus
        }
      });
      toasterService.success('Successfully unsubscribed customer from future emails');
    }).catch(() => {
      toasterService.error('There was an unknown error trying to unsubscribe the customer');
    });
  };
  unsubscribeCustomerSMS = () => {
    const {
      customer,
      customerDetails
    } = this.state;
    if (!customer.company || !customer.phone) {
      toasterService.error('Customer is missing required information to unsubscribe');
      return;
    }
    updateSMSStatus(customer.company, customer.phone, customerDetails.sms_optstatus_id, 'opt_out').then(() => {
      this.setState({
        customerDetails: {
          ...this.state.customerDetails,
          sms_optstatus: 'opt_out'
        }
      });
      toasterService.success('Successfully unsubscribed customer from future text messages');
    }).catch(() => {
      toasterService.error('There was an unknown error trying to unsubscribe the customer');
    });
  };
  resetCustomerSMS = () => {
    const {
      customer,
      customerDetails
    } = this.state;
    if (!customer.company || !customer.phone || !customerDetails.sms_optstatus_id) {
      toasterService.error('Customer is missing required information to unsubscribe');
      return;
    }
    updateSMSStatus(customer.company, customer.phone, customerDetails.sms_optstatus_id, null).then(() => {
      this.setState({
        customerDetails: {
          ...this.state.customerDetails,
          sms_optstatus: null
        }
      });
      toasterService.success('Successfully reset customer status for future text messages');
    }).catch(() => {
      toasterService.error('There was an unknown error trying to reset status for the customer');
    });
  };
  sendMessage = (message, phoneNumber, attachments) => {
    const {
      customer,
      messages
    } = this.state;
    return sendCustomerMessage(customer.id, {
      message,
      phone: phoneNumber,
      attachments: attachments.map(a => a.url)
    }).then(({
      data
    }) => {
      audio.play();
      this.setState({
        messages: [...messages, data]
      });
    }).catch(() => {
      toasterService.error('Unable to send message, Please try again.');
    });
  };
  goToTransaction = transactionId => {
    this.setState({
      tab: 'transactions',
      selectedTransaction: transactionId
    });
  };
  goToSchedule = scheduleId => {
    this.setState({
      tab: 'schedules',
      selectedSchedule: scheduleId
    });
  };
  loadCustomerDetails = () => {
    const {
      customerID
    } = this.props;
    this.setState({
      tab: this.props.startTab,
      customerDetails: null,
      customerActions: null,
      customerTimeline: null,
      message: '',
      attachments: [],
      addFileOpen: false
    });
    const promises = [getCustomerDetails(customerID), getCustomerActions(customerID), getCustomerMessages(customerID), getCustomer(customerID)];
    Promise.all(promises).then(([details, actions, messages, customer]) => {
      let timeline = [];
      if (customer) {
        this.setState({
          customer: customer.data
        });
      }
      actions.data.sms.forEach(sms => {
        sms.events.reduce((timeline, event) => {
          timeline.push({
            id: event.id,
            created_when: event.created_when,
            type: 'SMS',
            detail1: event.event,
            detail2: sms.message
          });
          return timeline;
        }, timeline);
      });
      actions.data.transactions.reduce((timeline, transaction) => {
        timeline.push({
          id: transaction.id,
          created_when: transaction.transaction_when,
          type: 'Transaction',
          detail1: `$${transaction.total}`,
          detail2: `Invoice #${transaction.entity_number || ''}`
        });
        return timeline;
      }, timeline);
      actions.data.mailers.reduce((timeline, mailer) => {
        timeline.push({
          id: mailer.id,
          created_when: mailer.created_when,
          type: 'Mailer',
          detail1: 'Sent',
          detail2: mailer.template_name
        });
        return timeline;
      }, timeline);
      actions.data.emails.forEach(email => {
        email.events.reduce((timeline, event) => {
          timeline.push({
            id: event.id,
            created_when: event.created_when,
            type: 'Email',
            detail1: event.event,
            detail2: email.template_name
          });
          return timeline;
        }, timeline);
      });
      actions.data.schedules.reduce((timeline, schedule) => {
        timeline.push({
          id: schedule.id,
          created_when: schedule.appointment,
          type: 'Schedule',
          detail1: '',
          detail2: moment(schedule.appointment).format('MMM D, YYYY hh:mm A')
        });
        return timeline;
      }, timeline);
      actions.data.reviews.reduce((timeline, review) => {
        timeline.push({
          id: review.id,
          created_when: review.review_when,
          type: 'Review',
          detail1: review.score,
          detail2: review.comment
        });
        return timeline;
      }, timeline);
      timeline = timeline.sort((a, b) => {
        if (a.created_when === b.created_when) return 0;
        return a.created_when > b.created_when ? -1 : 1;
      });

      // TODO: Hardcoded outbound numbers. May need to fix some day.
      this.setState({
        messages: messages.data.filter(message => message.twilio_phone != '+13852179309' && message.twilio_phone != '+18018500116').reverse(),
        customerDetails: details.data,
        customerTimeline: timeline,
        customerActions: actions.data
      });
    });
  };
  changeTab = tab => {
    this.setState({
      tab: tab
    });
    this.props.onTabChange(tab);
  };
  render() {
    const {
      tab,
      customerDetails,
      customerActions,
      customerTimeline,
      availableModels,
      messages,
      phoneNumbers,
      customer,
      selectedTransaction,
      selectedSchedule
    } = this.state;
    const {
      onClose,
      isOpen,
      startTransaction
    } = this.props;
    return <Drawer open={isOpen} onClose={onClose} allowBackdropClick size="lg">
        <Scoped css={styles}>
          {!!customer && <>
              <div className="drawer__title">
                {customer.first_name} {customer.last_name}
              </div>
              <div className="nav text-center mb-3">
                <ButtonGroup>
                  {userState.hasPermission('customer.view_customer') && <>
                      <Button className={classnames({
                  active: tab === 'details'
                })} actionType="white" onClick={() => this.changeTab('details')}>
                        Details
                      </Button>
                      <Button className={classnames({
                  active: tab === 'timeline'
                })} actionType="white" onClick={() => this.changeTab('timeline')}>
                        Timeline
                      </Button>
                      <Button className={classnames({
                  active: tab === 'actions'
                })} actionType="white" onClick={() => this.changeTab('actions')}>
                        Actions
                      </Button>
                    </>}
                  {userState.hasPermission('customer.view_two_way_messaging') && <Button className={classnames({
                active: tab === 'messaging'
              })} actionType="white" onClick={() => this.changeTab('messaging')}>
                      Messaging
                    </Button>}
                  {userState.hasPermission('customer.view_schedule') && availableModels.find(m => m.id === 'schedule') && <Button className={classnames({
                active: tab === 'schedules'
              })} actionType="white" onClick={() => this.changeTab('schedules')}>
                      Schedules
                    </Button>}
                  {userState.hasPermission('transaction.view_transaction') && <Button className={classnames({
                active: tab === 'transactions'
              })} actionType="white" onClick={() => this.changeTab('transactions')}>
                      Transactions
                    </Button>}
                </ButtonGroup>
              </div>
              {(tab === 'details' && !customerDetails || tab === 'timeline' && !customerActions || tab === 'actions' && !customerActions || tab === 'messaging' && !customerActions || tab === 'schedules' && !customerActions || tab === 'transactions' && !customerActions) && <Loader />}
              {tab === 'details' && customerDetails && <DetailsComponent customer={customer} availableModels={availableModels.map(i => i.id)} availableModelsFields={availableModels} customerDetails={customerDetails} goToSchedule={this.goToSchedule} unsubscribeCustomer={this.unsubscribeCustomer} unsubscribeCustomerSMS={this.unsubscribeCustomerSMS} resetCustomerSMS={this.resetCustomerSMS} />}
              {tab === 'timeline' && customerTimeline && <TimelineComponent customerTimeline={customerTimeline} goToSchedule={this.goToSchedule} goToTransaction={this.goToTransaction} />}
              {tab === 'actions' && customerActions && <ActionComponent customerActions={customerActions} goToTransaction={this.goToTransaction} />}
              {tab === 'messaging' && customerDetails && <MessagingComponent messages={messages} customerDetails={customerDetails} customer={customer} phoneNumbers={phoneNumbers} sendMessage={this.sendMessage} />}
              {tab === 'schedules' && customerDetails && <SchedulesComponent schedules={customerActions.schedules} selectedSchedule={selectedSchedule} availableModelsFields={availableModels} availableModels={availableModels.map(i => i.id)} />}
              {tab === 'transactions' && customerDetails && <TransactionsComponent transactions={customerActions.transactions} customerDetails={customerDetails} availableModelsFields={availableModels} availableModels={availableModels.map(i => i.id)} selectedTransaction={selectedTransaction || startTransaction} />}
            </>}
        </Scoped>
      </Drawer>;
  }
}