import React, { Component } from 'react';
import { Scoped, k } from 'kremling';
import { PageHeader } from '../../components/page-header/page-header';
import { Button } from '../../components/button/button.component';
import { Dropdown } from '../../components/dropdown/dropdown.component';
import { Badge } from '../../components/badge/badge.component';
import { userState, hasPermission } from '../../shared/user-state';
import { Loader } from '../../components/loader/loader.component';
import { Pagination } from '../../components/pagination/pagination.component';
import { getCompanies, postCompany, getUsers, getCompanyStatuses } from '../../shared/common.api';
import { toasterService } from '../../components/toaster/toaster-service';
import { Modal } from '../../components/modal/modal.component';
import { SortHeader } from '../../components/table/sort-header.component';
import { Calendar } from '../../components/calendar/calendar.component';
import { DynamicTypeAhead } from '../../components/dynamic-type-ahead/dynamic-type-ahead.component';
import utils from '../../shared/utils';
import moment from 'moment-timezone';
const paramsFilters = ['search', 'ordering', 'companyFilter'];
export class CompaniesList extends Component {
  constructor(props) {
    super(props);
    this.minDate = new Date(2000, 1, 1, 0, 0, 0, 0);
    this.state = {
      companies: [],
      is_loading: false,
      error: null,
      search: '',
      paginationParams: {
        offset: 0,
        limit: 20
      },
      companyFilter: 'true',
      createModal: false,
      loadingCreateCompany: false,
      ordering: null,
      newCompanyName: '',
      newCompanyStatus: '',
      newCompanyAccountManager: null,
      newCompanyContractSignedDate: null,
      newCompanyContractSignedMRR: ''
    };
    this.updateHistory = utils.updateHistory.bind(this);
    this.loadStateFromParams = utils.loadStateFromParams.bind(this);
  }
  componentDidMount() {
    this.loadStateFromParams(this.state, paramsFilters, this.showCompanies);
  }
  searchCompanies = search => {
    this.setState({
      search
    }, () => {
      this.updateHistory(this.state, paramsFilters);
      this.showCompanies();
    });
  };
  showCompanies = () => {
    const {
      search,
      companyFilter,
      ordering
    } = this.state;
    this.setState({
      isLoading: true
    });
    getCompanies({
      ...this.state.paginationParams,
      search: search,
      is_active: companyFilter,
      ordering
    }).then(({
      data
    }) => {
      if (data) {
        this.setState({
          companies: data.results,
          isLoading: false,
          paginationData: data
        });
      }
    });
  };
  filterCompanies(filter) {
    this.setState({
      companyFilter: filter.target.value
    }, () => {
      this.updateHistory(this.state, paramsFilters);
      this.showCompanies();
    });
  }
  pageOnChange = params => {
    this.setState({
      paginationParams: params
    }, () => {
      this.updateHistory(this.state, paramsFilters);
      this.showCompanies();
    });
  };
  goToCompany = id => {
    // if I have permission to view
    if (hasPermission(userState.state.user, id, 'organization.view_company')) {
      this.props.history.push(`/companies/${id}`);
    }
  };
  createCompany = () => {
    this.setState({
      loadingCreateCompany: true
    });
    postCompany({
      name: this.state.newCompanyName,
      status: this.state.newCompanyStatus,
      account_manager: this.state.newCompanyAccountManager,
      contract_original_signed_date: this.state.newCompanyContractSignedDate ? this.state.newCompanyContractSignedDate.format('YYYY-MM-DD') : null,
      contract_signed_mrr: parseFloat(this.state.newCompanyContractSignedMRR) || null,
      use_mailhouse_return_address: true,
      created_user: userState.state.user.id // TODO move this to the backend
    }).then(({
      data
    }) => {
      this.goToCompany(data.id);
    });
  };
  workAsCompany = asCompany => {
    userState.setAsCompany(asCompany);
    toasterService.success(`Now working as "${asCompany.name}"`);
  };
  updateOrdering = ordering => {
    this.setState({
      ordering,
      paginationParams: {
        ...this.state.paginationParams,
        offset: 0
      }
    }, () => {
      this.updateHistory(this.state, paramsFilters);
      this.showCompanies();
    });
  };
  render() {
    const {
      companies,
      error,
      isLoading,
      paginationData,
      createModal,
      newCompanyName,
      newCompanyStatus,
      newCompanyAccountManager,
      newCompanyContractSignedDate,
      newCompanyContractSignedMRR,
      loadingCreateCompany,
      ordering
    } = this.state;
    return <Scoped css={css}>
        <div className="wrapper">
          <PageHeader name="Companies" onSearch={this.searchCompanies} actions={userState.hasPermission('organization.add_company') && <>
                  <select className="form-control inline" name="companyFilter" value={this.state.companyFilter} onChange={event => this.filterCompanies(event, this)}>
                    <option value="">All Companies</option>
                    <option value="true">Active</option>
                    <option value="false">Inactive</option>
                  </select>
                  <Button actionType="primary" icon="fa-regular-plus" onClick={() => this.setState({
            createModal: true
          })} />
                </>} />
          <div className="wrapper-scroll company-list">
            {isLoading && <Loader overlay />}
            {error && <p>{error.message}</p>}

            <table data-cy="company-list" className="table-list">
              {!isLoading && !!companies.length && <thead>
                  <tr>
                    <th style={{
                  width: 50
                }} />
                    <SortHeader name="name" ordering={ordering} update={ordering => this.updateOrdering(ordering)}>
                      Info
                    </SortHeader>
                    <SortHeader name="phone" ordering={ordering} update={ordering => this.updateOrdering(ordering)}>
                      Contact
                    </SortHeader>
                    <SortHeader name="locations" ordering={ordering} update={ordering => this.updateOrdering(ordering)}>
                      Locations
                    </SortHeader>
                    <SortHeader name="is_active" ordering={ordering} update={ordering => this.updateOrdering(ordering)}>
                      Status
                    </SortHeader>
                    <th style={{
                  width: 40
                }} />
                  </tr>
                </thead>}
              <tbody>
                {companies.length && !isLoading && companies.map(company => <tr data-cy="company" data-id={company.id} key={company.id} onClick={() => this.goToCompany(company.id)}>
                      <td>
                        <Badge name={company.name} />
                      </td>
                      <td>
                        <div>
                          <strong>{company.name}</strong>
                        </div>
                        <div className="company-list__address">
                          {company.address1} {company.address2} {company.city}{' '}
                          {company.country} {company.zip_code}
                        </div>
                      </td>
                      <td>{utils.formatPhoneNumber(company.phone)}</td>
                      <td>{company.locations}</td>
                      <td>{company.is_active ? 'Active' : 'Inactive'}</td>
                      <td>
                        <Dropdown horizontal="west" trigger={() => <Button actionType="flat" icon="fa-regular-ellipsis-h" />} content={props => <ul className="select-list">
                              <li>
                                <a onClick={() => {
                        this.workAsCompany(company);
                        props.close();
                      }}>
                                  Work as {company.name}
                                </a>
                              </li>
                            </ul>} />
                      </td>
                    </tr>) || <tr>
                    {!isLoading && <td colSpan={6}>
                        <strong>No Results</strong>
                      </td>}
                  </tr>}
              </tbody>
            </table>
            <Pagination data={paginationData} onChange={this.pageOnChange} />
          </div>

          <Modal open={createModal} onClose={() => this.setState({
          createModal: false
        })} allowBackdropClick title="Create Company">
            <div className="modal__body">
              <div className="form-group">
                <label>
                  Company Name <span className="form-error">*</span>
                </label>
                <input type="text" className="form-control" autoFocus value={newCompanyName} onChange={e => this.setState({
                newCompanyName: e.target.value
              })} />
              </div>
              <div className="form-group">
                <label>
                  Status <span className="form-error">*</span>
                </label>
                <DynamicTypeAhead getItems={getCompanyStatuses} placeholder='Choose Company Status' displayProperty="name" keyProperty="id" value={newCompanyStatus} onChange={manager => {
                if (manager) {
                  this.setState({
                    newCompanyStatus: manager.id
                  });
                } else {
                  this.setState({
                    newCompanyStatus: ''
                  });
                }
              }} />
              </div>
              <div className="form-group">
                <label>Account Manager</label>
                <DynamicTypeAhead getItems={getUsers} placeholder='Choose Account Manager' displayProperty="email" keyProperty="id" value={newCompanyAccountManager} onChange={manager => {
                if (manager) {
                  this.setState({
                    newCompanyAccountManager: manager.id
                  });
                } else {
                  this.setState({
                    newCompanyAccountManager: ''
                  });
                }
              }} />
              </div>
              <div className="form-group">
                <label>Contract Signed Date</label>
                <Dropdown size="md" allowContentClicks trigger={() => <Button dropdown block>
                      {newCompanyContractSignedDate ? newCompanyContractSignedDate.format('LL') : 'Select Date'}
                    </Button>} content={({
                close
              }) => <div className="p-sm">
                      <Calendar minDate={this.minDate} value={newCompanyContractSignedDate ? newCompanyContractSignedDate.toDate() : null} onChange={date => {
                  this.setState({
                    newCompanyContractSignedDate: moment(date)
                  });
                  close();
                }} />
                    </div>} />
              </div>
              <div className="form-group">
                <label>Contract Signed MRR</label>
                <input type="text" className="form-control" value={newCompanyContractSignedMRR} onChange={e => this.setState({
                newCompanyContractSignedMRR: e.target.value
              })} />
              </div>
              <div className="form-error text-sm">* Required</div>
            </div>
            <div className="modal__actions">
              <Button onClick={() => this.setState({
              createModal: false
            })}>
                Cancel
              </Button>
              <Button actionType="primary" onClick={this.createCompany} disabled={!newCompanyName}>
                Create
              </Button>
            </div>
            {loadingCreateCompany && <Loader overlay />}
          </Modal>
        </div>
      </Scoped>;
  }
}
const css = {
  styles: `[kremling="i10"] body,body[kremling="i10"] {
  --color-primary: #487aae;
  --color-highlight: #e7efff;
  --color-accent: #354052;
  --color-success: #73b56e;
  --color-warning: #f4b707;
  --color-danger: #df5651;
  --color-grey-10: #f9f9f9;
  --color-grey-25: #f3f3f3;
  --color-grey-50: #e9e9e9;
  --color-grey-75: #e3e3e3;
  --color-grey-100: #d3d3d3;
  --color-grey-200: #c3c3c3;
  --color-grey-300: #b3b3b3;
  --color-grey-400: #a3a3a3;
  --color-grey-500: #808080;
  --color-grey-600: #707070;
  --color-grey-700: #606060;
  --color-grey-800: #505050;
  --color-grey-900: #404040;
  --base-font-family: Roboto, Helvetica, Arial, sans-serif;
  --base-font-size: 1.4rem;
  --base-font-color: #404040;
  --base-font-weight: 400;
  --base-line-height: 1.4;
  --box-shadow-1: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.12);
  --box-shadow-2: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-3: 0 10px 20px rgba(0, 0, 0, 0.17), 0 6px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-4: 0 14px 28px rgba(0, 0, 0, 0.13), 0 10px 10px rgba(0, 0, 0, 0.1);
  --box-shadow-5: 0 19px 38px rgba(0, 0, 0, 0.18), 0 15px 12px rgba(0, 0, 0, 0.1);
  --base-border-radius: 0.4rem;
}

[kremling="i10"] .company-list,[kremling="i10"].company-list {
  padding: 2rem;
}

[kremling="i10"] .company-list table button,[kremling="i10"].company-list table button {
  opacity: 0;
}
[kremling="i10"] .company-list table tr:hover button,[kremling="i10"].company-list table tr:hover button {
  opacity: 1;
}

[kremling="i10"] .company-list__address,[kremling="i10"].company-list__address {
  font-size: 12px;
}

[kremling="i10"] .invalid-input,[kremling="i10"].invalid-input {
  margin-bottom: 0px;
  color: red;
}

/* The switch - the box around the slider */
[kremling="i10"] .switch,[kremling="i10"].switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
[kremling="i10"] .switch input,[kremling="i10"].switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
[kremling="i10"] .slider,[kremling="i10"].slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #df5651;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

[kremling="i10"] .slider:before,[kremling="i10"].slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

[kremling="i10"] input:checked+.slider,input[kremling="i10"]:checked+.slider {
  background-color: #73b56e;
}

[kremling="i10"] input:focus+.slider,input[kremling="i10"]:focus+.slider {
  box-shadow: 0 0 1px #73b56e;
}

[kremling="i10"] input:checked+.slider:before,input[kremling="i10"]:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
[kremling="i10"] .slider.round,[kremling="i10"].slider.round {
  border-radius: 34px;
}

[kremling="i10"] .slider.round:before,[kremling="i10"].slider.round:before {
  border-radius: 50%;
}

[kremling="i10"] .companyActive>.isActive,[kremling="i10"].companyActive>.isActive {
  fill: #73b56e;
}

[kremling="i10"] .companyActive>.isNotActive,[kremling="i10"].companyActive>.isNotActive {
  fill: #df5651 !important;
}

[kremling="i10"] .filter,[kremling="i10"].filter {
  display: inline-block !important;
  border: solid 1px #d3d3d3;
  line-height: 1;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 1.4rem;
  color: #404040;
  border-radius: 0.4rem;
  padding: 0 1rem;
  transition: border-color 200ms ease, background-color 200ms ease, box-shadow 200ms ease;
  height: 3rem;
  background-color: #fff;
}
[kremling="i10"] .filter:focus,[kremling="i10"].filter:focus {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.22rem rgba(72, 122, 174, 0.25);
  background-color: #fff;
  outline: none;
  color: #495057;
}
[kremling="i10"] .filter.inline,[kremling="i10"].filter.inline {
  width: auto;
  display: inline-block;
}
[kremling="i10"] .filter.transparent,[kremling="i10"].filter.transparent {
  border-color: transparent;
  background-color: transparent;
}

[kremling="i10"] .brand,[kremling="i10"].brand {
  position: relative;
}

[kremling="i10"] .brand-close,[kremling="i10"].brand-close {
  position: absolute;
  right: 2.8rem;
  top: 0.4rem;
}`,
  id: 'i10',
  namespace: 'kremling'
};