import React, { Component } from 'react';
import { func, object, shape, string } from 'prop-types';
import { DateTime } from 'luxon';
import { Dropdown } from 'components/dropdown/dropdown.component';
import { Button } from 'components/button/button.component';
import { Calendar } from 'components/calendar/calendar.component';
import { timeSlots, getTime } from './trigger-recurring/trigger-recurring.utils';
export class ActionContest extends Component {
  static propTypes = {
    update: func,
    node: object,
    data: shape({
      datetime: string
    })
  };
  update = dateTime => {
    this.props.update({
      datetime: dateTime.set({
        second: 0,
        millisecond: 0
      }).toUTC().toISO()
    });
  };
  updateDate = date => {
    let dateTime = DateTime.fromJSDate(date);
    if (!this.props.data.datetime) {
      dateTime = dateTime.set({
        hour: 0,
        minute: 0
      });
    } else {
      const oldDate = DateTime.fromISO(this.props.data.datetime);
      dateTime = dateTime.set({
        hour: oldDate.hour,
        minute: oldDate.minute
      });
    }
    this.update(dateTime);
  };
  updateTime = (hour, minute) => {
    const newDate = DateTime.fromISO(this.props.data.datetime).set({
      hour,
      minute
    });
    this.update(newDate);
  };
  render() {
    const {
      datetime,
      winners_1,
      winners_2,
      winners_3,
      allow_multiple
    } = this.props.data;
    const currentDate = datetime && DateTime.fromISO(datetime);
    return <div>
        <div className="form-group">
          <label>Number of Winners</label>

          <div className="row">
            <div className="col-4 form-group">
              <label>1st Place</label><br />
              <input className="form-control" type="number" min="1" onChange={e => this.props.update({
              winners_1: e.target.value
            })} value={winners_1 || 1} />
            </div>
            <div className="col-4 form-group">
              <label>2nd Place</label><br />
              <input className="form-control" type="number" min="0" onChange={e => this.props.update({
              winners_2: e.target.value
            })} value={winners_2 || 0} />
            </div>
            <div className="col-4 form-group">
              <label>3rd Place</label><br />
              <input className="form-control" type="number" min="0" onChange={e => this.props.update({
              winners_3: e.target.value
            })} value={winners_3 || 0} />
            </div>
          </div>

          <label>Allow single customer to win multiple prizes each round?</label>
          <input type="checkbox" checked={allow_multiple} onChange={() => this.props.update({
          allow_multiple: !allow_multiple
        })} />
        </div>

        <div className="row">
          <div className="col-6">
            <div className="form-group">
              <label>Select Date</label>
              <Dropdown size="md" allowContentClicks trigger={() => <Button dropdown block>
                    {currentDate ? currentDate.toFormat('LL/dd/yy') : 'Select Date'}
                  </Button>} content={({
              close
            }) => <div className="p-sm">
                    <Calendar value={currentDate ? currentDate.toJSDate() : null} onChange={date => {
                this.updateDate(date);
                close();
              }} />
                  </div>} />
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label>Select Time</label>
              <Dropdown size="block" contentHeight={280} trigger={() => <Button block dropdown disabled={!currentDate}>
                    {currentDate ? getTime(currentDate.hour, currentDate.minute) : null}
                  </Button>} content={() => <ul className="select-list">
                    {timeSlots.map(slot => <li key={slot.time}>
                        <a onClick={() => this.updateTime(slot.hour, slot.minute)}>
                          {slot.time}
                        </a>
                      </li>)}
                  </ul>} />
            </div>
          </div>
        </div>
      </div>;
  }
}