import React, { Component } from 'react';
import Modal from 'react-modal';
import moment from 'moment';
import styles from './segments.styles.scss';
import { Modal as NewModal } from '../../components/modal/modal.component';
import { Scoped } from 'kremling';
import { PageHeader } from '../../components/page-header/page-header';
import { Button } from '../../components/button/button.component';
import { Dropdown } from '../../components/dropdown/dropdown.component';
import { Loader } from '../../components/loader/loader.component';
import { Pagination } from '../../components/pagination/pagination.component';
import { TagModal } from 'components';
import { Tags } from '../../components/tags/tags.component';
import { getJourneys, getSegments, deleteSegment, editSegment, getSegmentExport, getSegmentTemplates, createSegmentFromTemplate, getSegmentsTags, getBroadcasts, createNewFolder, getCompanyFolders, updateFolder, deleteFolder } from '../../shared/common.api';
import { userState } from '../../shared/user-state';
import { SortHeader } from '../../components/table/sort-header.component';
import { Status } from '../../components/status/status.component';
import { DynamicTypeAhead } from 'components/dynamic-type-ahead/dynamic-type-ahead.component';
import utils from '../../shared/utils';
import { DateTime } from 'luxon';
import { Icon } from '../../components/icon/icon.component';
import Tooltip from 'rc-tooltip';
const ALL_FIELDS = [{
  id: 'name',
  name: 'Name',
  type: 'text',
  className: ''
}, {
  id: 'tags',
  name: 'Tags',
  type: 'tags',
  className: ''
}, {
  id: 'created_when',
  name: 'Created',
  type: 'date',
  className: ''
}, {
  id: 'created_by_user__last_name',
  name: 'Created By',
  type: 'created_by',
  className: ''
}, {
  id: 'updated_when',
  name: 'Updated',
  type: 'date',
  className: ''
}, {
  id: 'customers',
  name: 'Customer Count',
  type: 'dynamic',
  className: ''
}, {
  id: 'included',
  name: 'Included',
  type: 'text',
  className: 'capitalize'
}];
const renderIcon = item => {
  if (item == 'folder') return <Icon name="fa-regular-folder" className="mr-3" />;
  return <Icon name="fa-solid-map-marker-alt" className="mr-3" />;
};
const paramsFilters = ['filters', 'search', 'ordering', 'archiveMode'];
export class SegmentsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      segments: [],
      isLoadingSegments: true,
      error: null,
      openModal: false,
      search: '',
      paginationParams: {},
      publishedJourneys: [],
      selectedSegment: {},
      selectedTagSegment: null,
      ordering: '-updated_when',
      showCreateModal: false,
      selectedTemplate: '',
      filters: null,
      isCreatingTemplate: false,
      loadingDeleteSegment: false,
      fields: ALL_FIELDS,
      segmentsTags: [],
      archiveMode: false,
      archiveModalId: null,
      segmentInBroadcast: [],
      filterDrawerOpen: false,
      folderData: [],
      dragItem: null,
      newFolderCreate: null,
      newFolderName: "",
      currentFolderView: [{
        name: 'Home',
        id: null
      }],
      renameFolderModal: null,
      renameFolderName: '',
      deleteFolderModal: null,
      addressBarWidth: 500,
      showAddressBar: true
    };
    this.updateHistory = utils.updateHistory.bind(this);
    this.loadStateFromParams = utils.loadStateFromParams.bind(this);
  }
  getSegmentTags = () => {
    getSegmentsTags(userState.getAsCompanyId()).then(({
      data
    }) => {
      const segmentsTags = data.results.map((tag, index) => {
        return {
          tag: tag,
          id: index
        };
      });
      this.setState({
        segmentsTags
      });
    });
  };
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions();
    this.loadStateFromParams(this.state, paramsFilters, this.showSegments);
    this.getSegmentTags();
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }
  onFilter = filters => {
    this.setState({
      filters
    }, () => {
      this.updateHistory(this.state, paramsFilters);
      this.showSegments();
    });
  };
  searchSegment = search => {
    this.setState({
      search
    }, () => {
      this.updateHistory(this.state, paramsFilters);
      this.showSegments();
    });
  };
  showSegments = () => {
    const {
      search,
      ordering,
      filters,
      archiveMode
    } = this.state;
    this.setState({
      isLoadingSegments: true
    });
    const parent = this.state.currentFolderView.at(-1)?.id ? this.state.currentFolderView.at(-1)?.id : 'home';
    const data = {
      ...this.state.paginationParams,
      ...(filters || {}),
      search,
      archived: archiveMode,
      company: userState.state.asCompany.id,
      ordering,
      folders: search !== '' ? null : parent
    };
    const promises = [getCompanyFolders({
      model: 'segment',
      company: userState.getAsCompanyId(),
      parent: parent
    }), getSegments(data)];
    Promise.all(promises).then(([folderData, data]) => {
      this.setState({
        folderData: folderData.data.results,
        segments: data.data.results,
        isLoadingSegments: false,
        paginationData: data.data
      });
    });
  };
  pageOnChange = params => {
    this.setState({
      paginationParams: params
    }, () => {
      this.updateHistory(this.state, paramsFilters);
      this.showSegments();
    });
  };
  createSegment() {
    if (!this.state.selectedTemplate || this.state.selectedTemplate === '') {
      this.props.history.push({
        pathname: '/create-segment'
      });
    } else {
      this.setState({
        isCreatingTemplate: true
      });
      createSegmentFromTemplate(this.state.selectedTemplate, userState.state.asCompany.id).then(({
        data
      }) => this.isSegmentEdited(data));
    }
  }
  isSegmentSelected(segment) {
    getJourneys({
      company: userState.state.asCompany.id,
      node_segment: segment.id,
      limit: 1
    }).then(companies => {
      this.setState({
        selectedSegment: segment,
        openModal: true,
        publishedJourneys: companies.data.results
      });
    });
  }
  isSegmentEdited(segment) {
    this.setState({
      selectedSegment: segment
    });
    this.props.history.push({
      pathname: `/segments/${segment.id}/edit`
    });
  }
  isSegmentCopied(segment) {
    this.setState({
      selectedSegment: segment
    });
    this.props.history.push({
      pathname: `/segments/${segment.id}/copy`
    });
  }
  deleteSegment() {
    this.setState({
      loadingDeleteSegment: true
    });
    deleteSegment(this.state.selectedSegment.id).then(() => {
      this.setState({
        openModal: false,
        loadingDeleteSegment: true
      });
      window.location.reload();
    });
  }
  goToSegment = segmentId => {
    this.props.history.push(`/segments/${segmentId}`);
  };
  exportSegment = segment => {
    getSegmentExport(segment.id).then(response => {
      utils.downloadBlob(response.data, utils.convertStringToFilename(segment.name, 'csv'));
    });
  };
  updateOrdering = ordering => {
    this.setState({
      ordering,
      currentPage: 1
    }, () => {
      this.updateHistory(this.state, paramsFilters);
      this.showSegments();
    });
  };
  addTagFromSegmentRow = (e, tag) => {
    e.stopPropagation();
    this.setState({
      filterDrawerOpen: true
    });
    let filters = this.state.filters ? this.state.filters : {};
    if (filters && filters.tags && filters.tags.length !== 0) {
      if (!filters.tags.includes(tag)) {
        filters.tags.push(tag);
      }
    } else {
      filters.tags = [];
      filters.tags.push(tag);
    }
    this.onFilter(filters);
  };
  loadBroadcasts = id => {
    return getBroadcasts({
      company: userState.getAsCompanyId(),
      segment: id,
      broadcast_when: false
    }).then(data => {
      this.setState({
        segmentInBroadcast: data.data.results
      });
    });
  };
  loadPublishedJourneys = id => {
    return getJourneys({
      company: userState.getAsCompanyId(),
      node_segment: id,
      archived: false
    }).then(companies => {
      this.setState({
        publishedJourneys: companies.data.results
      });
    });
  };

  /**
   * ARCHIVE TEMPLATE
   */

  closeArchiveModal = () => {
    this.setState({
      archiveModalId: null
    });
  };
  openArchiveModal = segment => {
    this.setState({
      segmentInBroadcast: [],
      publishedJourneys: []
    });
    this.loadPublishedJourneys(segment.id).then(() => {
      this.loadBroadcasts(segment.id).then(() => {
        this.setState({
          archiveModalId: segment.id,
          selectedSegment: segment
        });
      });
    });
  };
  archiveSegment = () => {
    const {
      selectedSegment
    } = this.state;
    editSegment(selectedSegment.id, {
      archived_when: DateTime.local()
    }).then(() => {
      this.showSegments();
      this.closeArchiveModal();
    });
  };
  restoreSegment = segment => {
    editSegment(segment.id, {
      archived_when: null
    }).then(() => this.showSegments());
  };
  handleArchiveMode = () => {
    const {
      archiveMode
    } = this.state;
    this.setState({
      archiveMode: !archiveMode,
      search: '',
      paginationParams: {}
    }, () => {
      this.updateHistory(this.state, paramsFilters);
      this.showSegments();
    });
  };

  /**
     * FOLDERS
     */
  makeNewFolder = newFolderName => {
    this.setState({
      isLoadingSegments: true
    });
    const parent = this.state.currentFolderView.at(-1)?.id;
    createNewFolder({
      name: newFolderName,
      model: 'segment',
      parent: parent,
      company: userState.getAsCompanyId()
    }).then(() => {
      getCompanyFolders({
        model: 'segment',
        company: userState.getAsCompanyId(),
        parent: this.state.currentFolderView.at(-1)?.id ? this.state.currentFolderView.at(-1)?.id : 'home'
      }).then(results => {
        this.setState({
          folderData: results.data.results,
          isLoadingSegments: false
        });
      });
    });
  };
  renameFolder = () => {
    this.setState({
      isLoadingSegments: true
    });
    updateFolder(this.state.renameFolderModal, {
      name: this.state.renameFolderName
    }).then(() => {
      this.showSegments();
    });
  };
  deleteFolders = () => {
    this.setState({
      isLoadingSegments: true
    });
    const id = this.state.deleteFolderModal;
    this.setState({
      deleteFolderModal: null
    });
    deleteFolder(id).then(() => {
      this.showSegments();
    });
  };
  handleEnterDirectory = folder => {
    this.setState({
      isLoadingSegments: true,
      currentFolderView: [...this.state.currentFolderView, folder]
    }, () => {
      this.showSegments();
    });
  };
  handleEnterTreeDirectory = (f, index) => {
    if (f.id == this.state.currentFolderView.at(-1)?.id) {
      return;
    }
    this.setState({
      isLoadingSegments: true
    });
    const popCount = -(this.state.currentFolderView.length - index - 1);
    this.setState({
      currentFolderView: this.state.currentFolderView.slice(0, popCount)
    }, () => {
      this.showSegments();
    });
  };
  handleDragStart = id => {
    this.setState({
      dragItem: id
    });
  };
  handleDragEnter = e => {
    e.target.parentNode.style.backgroundColor = "grey";
  };
  handleDragLeave = e => {
    e.target.parentNode.style.backgroundColor = "white";
  };
  handleDrop = (e, folder) => {
    editSegment(this.state.dragItem, {
      folder: folder.id
    }).then(() => {
      this.showSegments();
    });
    e.target.parentNode.style.backgroundColor = "white";
  };
  moveUpDirectory = () => {
    this.setState({
      currentFolderView: this.state.currentFolderView.slice(0, -1)
    }, () => {
      this.showSegments();
    });
  };
  updateDimensions = () => {
    this.setState({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight
    }, () => {
      if (this.state.windowWidth > 1250) {
        this.setState({
          addressBarWidth: 500
        });
        this.setState({
          showAddressBar: true
        });
      } else if (this.state.windowWidth < 1250 && this.state.windowWidth > 860) {
        this.setState({
          addressBarWidth: this.state.windowWidth - 750
        });
        this.setState({
          showAddressBar: true
        });
      } else {
        this.setState({
          showAddressBar: false
        });
      }
    });
  };
  render() {
    const {
      isCreatingTemplate,
      segments,
      error,
      isLoadingSegments,
      paginationData,
      ordering,
      showCreateModal,
      selectedTemplate,
      fields,
      filters,
      search,
      loadingDeleteSegment,
      segmentsTags,
      selectedSegment,
      selectedTagSegment,
      filterDrawerOpen,
      archiveMode,
      publishedJourneys,
      segmentInBroadcast,
      currentFolderView,
      folderData,
      addressBarWidth,
      showAddressBar
    } = this.state;
    return <Scoped css={styles}>
        <div className="wrapper">
          <PageHeader name={archiveMode ? 'Segments - Archive' : 'Segments'} searchVal={search} onSearch={this.searchSegment} filterDrawerOpen={filterDrawerOpen} hasFolders={true} preactions={<>
                <Button onClick={() => this.handleArchiveMode()} actionType={archiveMode ? 'primary' : 'grey'}>
                  {archiveMode ? 'Exit Archive' : 'View Archive'}
                </Button>
                </>} actions={<>
                {userState.hasPermission('customer.add_segment') && <Button actionType="primary" icon="fa-regular-plus" onClick={() => this.setState({
            showCreateModal: true
          })} />}
              </>} leftactions={<>
                {showAddressBar && <div className='form-control' style={{
            width: addressBarWidth + 'px',
            overflow: 'hidden'
          }}>
                    <table>
                      <tbody style={{
                float: 'right',
                marginTop: '3px',
                cursor: 'pointer',
                whiteSpace: 'nowrap'
              }}>
                        <tr>
                          {currentFolderView.map((f, index) => <td key={f.id} onClick={() => {
                    this.handleEnterTreeDirectory(f, index);
                  }}>
                            {f.name == 'Home' ? <Icon name="fa-regular-home-alt" className="mr-3"></Icon> : <>{f.name}</>}
                                <Icon name="fa-regular-angle-right"></Icon>
                            </td>)}
                        </tr>
                      </tbody>
                    </table>
                  </div>}

                {this.state.currentFolderView.length > 1 && <Tooltip placement="bottom" align={{
            points: ['tl', 'tr'],
            offset: [0, 35],
            targetOffset: ['100%', '0%'],
            overflow: {
              adjustX: true,
              adjustY: true
            }
          }} overlay="Move up a directory">
                  <span>
                    <Button icon="fa-regular-arrow-up" actionType="grey" onClick={() => {
                this.moveUpDirectory();
              }} /></span>
                  </Tooltip>}
                {userState.hasPermission('customer.add_segment') && <Tooltip placement="bottom" align={{
            points: ['tl', 'tr'],
            offset: [0, 35],
            targetOffset: ['100%', '0%'],
            overflow: {
              adjustX: true,
              adjustY: true
            }
          }} overlay="Create new folder">
                  <Button icon="fa-regular-folder-plus" actionType="grey" onClick={() => {
              this.setState({
                newFolderCreate: true
              });
            }} />
                    </Tooltip>}
              </>} tags={segmentsTags} onFilter={filters => this.onFilter(filters)} filterVals={filters} filters={[{
          key: 'name',
          label: 'Name',
          type: 'text'
        }, {
          key: 'tags',
          label: 'Tags',
          type: 'typeahead',
          options: segmentsTags
        }, {
          key: 'created',
          label: 'Created',
          type: 'range'
        }, {
          key: 'created_by',
          label: 'Created By',
          type: 'text'
        }, {
          key: 'updated',
          label: 'Updated',
          type: 'range'
        }, {
          key: 'included',
          label: 'Included',
          type: 'options',
          options: ['nothing', 'possession', 'schedule', 'transaction', 'cart']
        }]} />
          <div className="wrapper-scroll segments-list">
            {isLoadingSegments && <Loader overlay />}
            {error && <p>{error.message}</p>}
            <table className="table-list">
              {!isLoadingSegments && !!segments.length && <thead>
                  <tr>
                    {fields.map(field => field.type === 'dynamic' ? <th key={field.id}>{field.name}</th> : <SortHeader key={field.id} name={field.id} ordering={ordering} update={ordering => this.updateOrdering(ordering)}>
                          {field.name}
                        </SortHeader>)}
                    <th style={{
                  width: 50
                }} />
                  </tr>
                </thead>}
              <tbody>
                {search === '' && folderData.map(folder => <tr key={folder.id} onClick={() => {
                this.handleEnterDirectory(folder);
              }} onDragEnter={e => this.handleDragEnter(e)} onDragLeave={e => this.handleDragLeave(e)} onDrop={e => this.handleDrop(e, folder)} onDragOver={e => e.preventDefault()}>
                    <td style={{
                  paddingLeft: '15px'
                }}>
                      {renderIcon('folder')}
                      {folder.name}
                    </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        {userState.hasPermission('customer.add_segment', 'customer.delete_segment', 'customer.view_segment', 'customer.change_segment') && <Dropdown horizontal="west" trigger={() => <Button actionType="flat" icon="fa-regular-ellipsis-h" />} content={prop => <ul className="select-list">
                                {userState.hasPermission('customer.change_segment') && <>
                                    <li>
                                      <a onClick={() => {
                          this.setState({
                            renameFolderName: folder.name,
                            renameFolderModal: folder.id
                          });
                          prop.close();
                        }}>
                                        Rename
                                      </a>
                                    </li>
                                  </>}
                                {userState.hasPermission('customer.delete_segment') && <li>
                                    <a onClick={() => {
                        this.setState({
                          deleteFolderModal: folder.id
                        });
                        prop.close();
                      }}>
                                      Delete
                                    </a>
                                  </li>}
                              </ul>} />}
                      </td>
                    </tr>)}
                {segments.length && !isLoadingSegments && segments.map(segment => <tr key={segment.id} onClick={() => this.goToSegment(segment.id)} draggable onDragStart={e => this.handleDragStart(segment.id)} onDragOver={e => e.preventDefault()}>
                      {fields.map(field => <td className={field.className} key={field.id + segment.id} style={{
                  paddingLeft: '15px'
                }}>
                          {field.type === 'tags' && <Tags tags={segment.tags} onDelete={null} onClick={(e, tag) => {
                    this.addTagFromSegmentRow(e, tag);
                  }} />}
                          {field.id === 'name' && renderIcon('segment')}
                          {search !== '' && field.id === 'name' && segment.path}
                          {field.type === 'text' && segment[field.id]}
                          {field.type === 'dynamic' && (segment.time_dynamic_segment === true ? <Status status="published">Dynamic</Status> : segment.customers)}
                          {field.type === 'date' && moment(segment[field.id]).format('MMM D, YYYY')}
                          {field.type === 'created_by' && segment.created_by_user && `${segment.created_by_last_name}, ${segment.created_by_first_name}`}
                          {field.type === 'created_by' && !segment.created_by_user && 'Unknown User'}
                        </td>)}
                      <td>
                        <Dropdown horizontal="west" trigger={() => <Button actionType="flat" icon="fa-regular-ellipsis-h" />} content={() => <ul className="select-list">
                              {userState.hasPermission('customer.change_segment') && <>
                                  <li onClick={() => this.isSegmentEdited(segment)}>
                                    <a>Edit</a>
                                  </li>
                                  <li>
                                    <a onClick={() => this.setState({
                          selectedTagSegment: segment
                        })}>
                                      Edit Tags
                                    </a>
                                  </li>
                                </>}
                              {this.state.currentFolderView.length > 1 && <li>
                                <a onClick={() => {
                        this.setState({
                          isLoadingJourneys: true
                        });
                        editSegment(segment.id, {
                          folder: null
                        }).then(() => {
                          this.showSegments();
                        });
                      }}>
                                  Move to Root Folder
                                </a>
                              </li>}
                              {!archiveMode ? <li>
                                  <a onClick={() => this.openArchiveModal(segment)}>
                                    Archive
                                  </a>
                                </li> : <li>
                                  <a onClick={() => this.restoreSegment(segment)}>
                                    Restore
                                  </a>
                                </li>}
                              {userState.hasPermission('customer.add_segment') && <li onClick={() => this.isSegmentCopied(segment)}>
                                  <a>Copy</a>
                                </li>}
                              <li onClick={() => this.exportSegment(segment)}>
                                <a>Export</a>
                              </li>
                              {userState.hasPermission('customer.delete_segment') && <>
                                  <li className="divider" />
                                  <li onClick={() => this.isSegmentSelected(segment)}>
                                    <a>Delete</a>
                                  </li>
                                </>}
                            </ul>} />
                      </td>
                    </tr>) || <tr>
                    {!isLoadingSegments && <td colSpan={9}>
                        <strong>No Results</strong>
                      </td>}
                  </tr>}
              </tbody>
            </table>
            <Pagination data={paginationData} onChange={this.pageOnChange} />
          </div>
          <Modal isOpen={this.state.openModal} className="old-modal" contentLabel="Delete Segment" style={{
          overlay: {
            zIndex: 100
          }
        }}>
            <div className="old-modal__inner">
              <div className="modal__title">
                Delete Segment
                <Button onClick={() => this.setState({
                openModal: false
              })} actionType="flat" icon="fa-regular-times" small />
              </div>
              <div className="modal__body">
                <p>
                  Are you sure you want to delete{' '}
                  <strong>{selectedSegment.name}</strong>? This cannot be
                  undone.
                </p>
                {!!this.state.publishedJourneys.length && <p>
                    This segment is part of{' '}
                    <strong>{this.state.publishedJourneys[0].name}</strong> and
                    cannot be deleted at this time.
                  </p>}
              </div>
              <div className="modal__actions">
                <Button actionType="flat" onClick={() => this.setState({
                openModal: false
              })}>
                  Cancel
                </Button>
                <Button actionType="primary" className="float-right" disabled={!!this.state.publishedJourneys.length || loadingDeleteSegment} onClick={() => this.deleteSegment()}>
                  {loadingDeleteSegment ? <Loader size="sm" /> : 'Confirm'}
                </Button>
              </div>
            </div>
          </Modal>

          <NewModal title="Create Segment" onClose={() => !isCreatingTemplate && this.setState({
          showCreateModal: false
        })} open={!!showCreateModal} allowBackdropClick>
            <div className="modal__body">
              <p>
                You can either create a blank new segment or create a segment
                from a template.
              </p>

              <DynamicTypeAhead getItems={getSegmentTemplates} getItemsFilters={{
              company: userState.state.asCompany.id,
              ordering: 'segment__name'
            }} placeholder="Blank Segment" displayProperty="segment_name" keyProperty="id" value={selectedTemplate ? selectedTemplate : null} disabled={isCreatingTemplate} onChange={val => this.setState({
              selectedTemplate: val ? val.id : null
            })} />
            </div>
            <div className="modal__actions">
              {isCreatingTemplate ? <Loader size="sm" /> : <>
                  <Button disabled={isCreatingTemplate} actionType="flat" onClick={() => this.setState({
                showCreateModal: false
              })}>
                    Cancel
                  </Button>
                  <Button disabled={isCreatingTemplate} actionType="primary" onClick={() => this.createSegment()}>
                    Confirm
                  </Button>
                </>}
            </div>
          </NewModal>
          <NewModal title={!!publishedJourneys.length || !!segmentInBroadcast.length ? 'Cannot Archive Segment' : 'Archive Segment'} onClose={this.closeArchiveModal} open={!!this.state.archiveModalId} allowBackdropClick>
            <div className="modal__body">
              {!!publishedJourneys.length || !!segmentInBroadcast.length ? <>
                  <p>
                    The segment <strong>{selectedSegment.name} </strong> cannot
                    be archived because it is used in the following places.
                  </p>
                  <h4>Journeys</h4>
                  <p>
                  {!!publishedJourneys.length ? publishedJourneys.map((j, index) => {
                  return <p key={index}>{j.name}</p>;
                }) : <p>None</p>}
                  </p>
                  {'  '}
                  <h4>Broadcasts</h4>
                  {!!segmentInBroadcast.length ? segmentInBroadcast.map((s, index) => {
                return <p key={index}>{s.email_template_name}</p>;
              }) : <p>None</p>}
                </> : <p>
                  Are you sure you would like to archive this segment? It will
                  no longer be available to send in journeys or broadcasts
                </p>}
            </div>
            <div className="modal__actions">
              {!!publishedJourneys.length || !!segmentInBroadcast.length ? <Button actionType="primary" onClick={this.closeArchiveModal}>
                  Okay
                </Button> : <>
                  <Button actionType="flat" onClick={this.closeArchiveModal}>
                    Cancel
                  </Button>
                  <Button actionType="primary" onClick={() => this.archiveSegment()}>
                    Confirm
                  </Button>
                </>}
            </div>
          </NewModal>
          <NewModal title="Rename Folder" onClose={() => this.setState({
          renameFolderModal: null
        })} open={!!this.state.renameFolderModal} allowBackdropClick>
            <div className="modal__body">
              <p>
              <input type="text" className="form-control" value={this.state.renameFolderName} onChange={e => {
                this.setState({
                  renameFolderName: e.target.value
                });
              }} />
              </p>
            </div>
            <div className="modal__actions">
              <Button actionType="flat" onClick={() => this.setState({
              renameFolderModal: null
            })}>
                Cancel
              </Button>
              <Button actionType="primary" onClick={() => {
              this.renameFolder();
              this.setState({
                renameFolderModal: null
              });
            }}>
                Rename Folder
              </Button>
            </div>
          </NewModal>
          <NewModal title="Delete Folder" onClose={() => this.setState({
          deleteFolderModal: null
        })} open={!!this.state.deleteFolderModal} allowBackdropClick>
            <div className="modal__body">
              <p>
               Are you sure you want to delete this folder?
               </p>
               <p>
               If this folder has any segments in it, they will not be deleted, but will be moved back to the home folder.
              </p>
            </div>
            <div className="modal__actions">
              <Button actionType="flat" onClick={() => this.setState({
              deleteFolderModal: null
            })}>
                Cancel
              </Button>
              <Button actionType="danger" onClick={() => this.deleteFolders()}>
                Delete
              </Button>
            </div>
          </NewModal>
          <NewModal title="Create New Folder" onClose={() => this.setState({
          newFolderCreate: null
        })} open={!!this.state.newFolderCreate} allowBackdropClick size='sm'>
            <div className="modal__body">
               <input type="text" className="form-control" value={this.state.newFolderName} placeholder="Folder Name" onChange={e => {
              this.setState({
                newFolderName: e.target.value
              });
            }} onKeyPress={e => {
              if (e.key === 'Enter') {
                this.makeNewFolder(this.state.newFolderName);
                this.setState({
                  newFolderCreate: false
                });
              }
            }} autoFocus />
            </div>
            <div className="modal__actions">
              <Button actionType="flat" onClick={() => this.setState({
              newFolderCreate: null
            })}>
                Cancel
              </Button>
              <Button actionType="primary" onClick={e => {
              this.makeNewFolder(this.state.newFolderName);
              this.setState({
                newFolderCreate: false
              });
            }}>
                Create
              </Button>
            </div>
          </NewModal>
          <TagModal onClose={() => this.setState({
          selectedTagSegment: null
        })} onSuccess={() => {
          this.getSegmentTags();
          this.showSegments();
        }} objectTarget={selectedTagSegment} getViewTags={getSegmentsTags} patchView={editSegment} viewName={'Segments'}></TagModal>
        </div>
      </Scoped>;
  }
}