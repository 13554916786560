import { Container, Graphics, Text, TextStyle } from 'pixi.js';
import { journeyState } from '../journey-state';
import { NodeDelete } from './node-delete';
import { MODES } from './node-utils';
const toRadians = function (degree) {
  return degree * (Math.PI / 180);
};
const nodeOffset = 124 / 2;
const gap = nodeOffset + 16;
export class NodeLink {
  constructor() {
    this.container = new Container();
    this.deleteCount = 0;
  }
  getPoints() {
    return this.line.graphicsData[0].shape.points;
  }
  getSlope(x1, y1, x2, y2) {
    const points = this.getPoints();
    return ((y2 || points[3]) - (y1 || points[1])) / ((x2 || points[2]) - (x1 || points[0]));
  }
  build(x1 = -999, y1 = -999, x2 = -999, y2 = -999, tempLink) {
    this.line = new Graphics();
    this.line.lineStyle(3, 0xd3d3d3);
    this.line.moveTo(x1, y2).lineTo(x1, y2);
    const style = new TextStyle({
      fontSize: 11,
      fontWeight: 700,
      fill: '#a3a3a3'
    });
    this.clickContainer = new Graphics();
    this.clickContainer.alpha = 0;
    this.clickContainer.beginFill(0xffffff);
    this.clickContainer.drawRect(-8, 0, 16, 10);
    this.clickContainer.interactive = true;
    this.clickContainer.rotation = Math.atan2(y2 - y1, x2 - x1);
    this.eventText = new Text('', style);
    this.textContainer = new Container();
    this.innerTextContainer = new Container();
    this.innerTextContainer.addChild(this.eventText);
    this.textContainer.addChild(this.innerTextContainer);
    this.innerTextContainer.y = 12;
    this.innerTextContainer.x = 14;
    this.eventText.y = -12;
    this.arrowContainer = new Container();
    this.arrowContainer.pivot.set(0, 6);
    this.arrowContainer.rotation = Math.PI * 2 * 0.5;
    this.arrow = new Graphics();
    this.arrow.y = 6;
    this.arrow.pivot.set(0, 6);
    this.arrow.beginFill(0xd3d3d3);
    this.arrow.drawPolygon([0, 4, 0, 0, 14, 6, 0, 12, 0, 8]);
    this.arrow.endFill();
    this.arrow.rotation = toRadians(45);
    this.line.addChild(this.arrowContainer);
    this.arrowContainer.addChild(this.arrow);
    this.line.addChild(this.arrowContainer);
    this.container.addChild(this.clickContainer);
    this.container.addChild(this.textContainer);
    this.container.addChild(this.line);
    if (!tempLink) {
      this.clickContainer.on('mouseover', () => this.showDelete()).on('mouseout', () => this.hideDelete());
      this.delete = new NodeDelete(this.onDelete);
      this.delete.container.x = (x1 + x2 - 26) / 2;
      this.delete.container.y = (y1 + y2 - 26) / 2;
      this.delete.addHook('mouseover', () => this.showDelete());
      this.delete.addHook('mouseout', () => this.hideDelete());
      this.container.addChild(this.delete.container);
    }
  }
  willDestroy = () => {
    this.container.destroy();
  };
  update = (x1, y1, x2, y2, pointLink) => {
    const points = this.getPoints();
    const slope = this.getSlope(x1, y1, x2, y2);
    let quad1, quad2, quad3, quad4, newX, newY;
    if (this.delete) {
      this.delete.container.x = (x1 + x2 - 26) / 2;
      this.delete.container.y = (y1 + y2 - 26) / 2;
    }
    this.clickContainer.x = x1;
    this.clickContainer.y = y1;
    this.clickContainer.rotation = Math.atan2(y2 - y1, x2 - x1) + 270 * Math.PI / 180;
    this.clickContainer.height = Math.pow(Math.pow(Math.abs(y2 - y1), 2) + Math.pow(Math.abs(x2 - x1), 2), 0.5);
    this.textContainer.x = (x1 + x2 - 26) / 2;
    this.textContainer.y = (y1 + y2 - 26) / 2;
    this.innerTextContainer.pivot = {
      x: this.eventText.width / 2,
      y: this.eventText.height / 2
    };
    const rotation = Math.atan2(y2 - y1, x2 - x1) * 90 / Math.PI;
    if (rotation > -45 && rotation < 45) {
      this.innerTextContainer.rotation = Math.atan2(y2 - y1, x2 - x1) + Math.PI / 180;
    } else {
      this.innerTextContainer.rotation = Math.atan2(y2 - y1, x2 - x1) + 180 * Math.PI / 180;
    }
    if (!pointLink) {
      quad1 = (slope >= 1 || slope <= -1) && y2 < y1;
      quad2 = slope > -1 && slope < 1 && x2 > x1;
      quad3 = (slope >= 1 || slope <= -1) && y2 > y1;
      quad4 = slope > -1 && slope < 1 && x2 < x1;
      const xDistance = x1 - x2;
      const yDistance = y1 - y2;
      const northSouth = quad1 || quad3;
      const distance = northSouth ? xDistance : yDistance;
      const diff = (northSouth ? yDistance : xDistance) / gap;
      newX = northSouth ? distance / diff : gap;
      newY = northSouth ? gap : distance / diff;
      if (quad1 || quad4) {
        points[2] = x2 + newX;
        points[3] = y2 + newY;
      } else {
        points[2] = x2 - newX;
        points[3] = y2 - newY;
      }
    } else {
      points[2] = x2;
      points[3] = y2;
    }
    points[0] = x1;
    points[1] = y1;
    this.arrow.rotation = Math.atan2(points[1] - points[3], points[0] - points[2]);
    this.arrowContainer.x = points[2];
    this.arrowContainer.y = points[3];
    this.line.dirty++;
    this.line.clearDirty++;
  };
  showDelete = () => {
    if (journeyState.state.mode === MODES.CANVAS) {
      if (this.deleteCount === 0) this.delete.container.alpha = 1;
      this.deleteCount += 1;
    }
  };
  hideDelete = () => {
    if (this.deleteCount === 1) this.delete.container.alpha = 0;
    this.deleteCount = this.deleteCount - 1 < 0 ? 0 : this.deleteCount - 1;
  };
  onDelete = () => {
    if (journeyState.state.mode === MODES.CANVAS) {
      journeyState.removeLink(this);
    }
  };
  customPath(x1, y1, x2, y2) {
    this.build(x1, y1, x2, y2, true);
  }
  updatePointLink(x, y) {
    const points = this.getPoints();
    this.update(points[0], points[1], x, y, true);
  }
  connect(nodeFrom, nodeTo, onEvent) {
    this.nodeFrom = nodeFrom;
    this.nodeTo = nodeTo;
    this.onEvent = onEvent;
    this.updateNodes();
  }
  updateNodes() {
    let centerX1 = this.nodeFrom.container.x + nodeOffset;
    let centerY1 = this.nodeFrom.container.y + nodeOffset;
    let centerX2 = this.nodeTo.container.x + nodeOffset;
    let centerY2 = this.nodeTo.container.y + nodeOffset;
    if (journeyState.state.snapToGrid === true) {
      centerX1 = Math.round(this.nodeFrom.container.x / 25) * 25 + nodeOffset;
      centerY1 = Math.round(this.nodeFrom.container.y / 25) * 25 + nodeOffset;
      centerX2 = Math.round(this.nodeTo.container.x / 25) * 25 + nodeOffset;
      centerY2 = Math.round(this.nodeTo.container.y / 25) * 25 + nodeOffset;
    }
    if (!this.clickContainer) {
      this.build(centerX1, centerY1, centerX2, centerY2);
    }
    this.update(centerX1, centerY1, centerX2, centerY2);
  }
}