import * as React from 'react';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import { postForm } from 'shared/forms.api';
import { Loader } from '../../../components/loader/loader.component';
import { userState } from 'shared/user-state';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import { snackbarService } from 'components/mui/snackbar/snackbar-service';
export default function GoogleLeads(props) {
  const [textFieldValue, setTextFieldValue] = React.useState(props.data?.name || '');
  const [isLoading, setIsLoading] = React.useState(false);
  const [newFormData, setNewFormData] = React.useState(props.data || null);
  const handleGoogleLeadsDialogSubmit = () => {
    setIsLoading(true);
    postForm({
      company: userState.getAsCompanyId(),
      name: textFieldValue,
      entity_source: 'google_leads',
      url: null,
      redirect: null,
      fields: null,
      html_index: null,
      html_tag: null
    }).then(({
      data
    }) => {
      setNewFormData(data);
      setIsLoading(false);
    });
  };
  const handleTextFieldChange = e => {
    setTextFieldValue(e.target.value);
  };
  const handleURLClick = () => {
    navigator.clipboard.writeText(API_URL + "/google_leads/");
    snackbarService.popup({
      message: 'Url copied to clipboard'
    });
  };
  const handleKeyClick = () => {
    navigator.clipboard.writeText(newFormData.id);
    snackbarService.popup({
      message: 'Key copied to clipboard'
    });
  };
  return <>
        <CssBaseline />
        <Dialog maxWidth='md' PaperProps={{
      style: {
        borderRadius: '28px',
        minWidth: '350px'
      }
    }} open={!!props.open} onClose={(e, reason) => {
      if (reason === 'backdropClick' && !!newFormData) {
        return;
      }
      props.onClose();
    }}>
            <DialogTitle sx={{
        m: 0,
        p: 2,
        fontSize: 'medium'
      }}>
            <IconButton aria-label="close" onClick={e => {
          props.onClose();
        }} sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme => theme.palette.grey[500]
        }}>
                <CloseIcon />
                </IconButton>
            <Stack alignItems="center" component="span">
            <Typography variant="h4" component="span"> Add Google Leads </Typography>
            </Stack>
            </DialogTitle>
            <DialogContent dividers>
                <DialogContentText component="span">
                    {isLoading && <Loader overlay background="rgba(255,255,255,.5)" />}
                </DialogContentText>
                <TextField disabled={!!newFormData} autoComplete='off' autoFocus margin="dense" id="name" label="Form Name" helperText="Enter a friendly name for this form in Cinch" type="name" fullWidth variant="standard" value={textFieldValue} onChange={handleTextFieldChange} />
                {newFormData && <>
                        <Divider sx={{
            py: 2
          }}> <Chip label="Enter on your Google Leads Ad:" /> </Divider>
                        <Stack>
                            <Stack direction="row" spacing={1}>
                                <Typography sx={{
                display: 'flex',
                alignItems: 'center'
              }}> Url: </Typography>
                                <Button sx={{
                textTransform: 'none'
              }} variant='text' onClick={() => {
                handleURLClick();
              }}>
                                    {API_URL + "/google_leads/"}
                                </Button>
                            </Stack>
                            <Stack direction="row" spacing={1}>
                                <Typography sx={{
                display: 'flex',
                alignItems: 'center'
              }}> Key: </Typography>
                                <Button sx={{
                textTransform: 'none'
              }} variant='text' onClick={() => {
                handleKeyClick();
              }}>
                                    {newFormData.id}
                                </Button>
                            </Stack>
                            <Typography sx={{
              pt: 1
            }}> For assistance with this process -
                                <a href="https://support.cinch.io/knowledge" target="_blank">
                                    How to setup a Google Lead form
                                </a>
                            </Typography>
                        </Stack>
                    </>}
            </DialogContent>
            <DialogActions sx={{
        py: 1
      }}>
                {!newFormData && <Button disabled={isLoading} onClick={() => {
          props.onClose();
        }} variant="text" sx={{
          borderRadius: '20px',
          color: 'rgba(29, 37, 45, 0.7)',
          width: '75px',
          textTransform: 'none'
        }}>
                Cancel
                </Button>}
                {!newFormData && <Button disabled={isLoading || !textFieldValue} onClick={() => {
          setIsLoading(true);
          handleGoogleLeadsDialogSubmit();
        }} variant="text" sx={{
          mr: 1,
          borderRadius: '20px',
          width: '75px',
          color: '#53A6D6',
          textTransform: 'none'
        }}>
                Create
                </Button>}
                {newFormData && <Button disabled={isLoading || !textFieldValue} onClick={() => {
          props.handleSubmit();
        }} variant="text" sx={{
          mr: 1,
          borderRadius: '20px',
          width: '75px',
          color: '#53A6D6',
          textTransform: 'none'
        }}>
                Close
                </Button>}
            </DialogActions>
        </Dialog>
    </>;
}