import React from 'react';
import { PageHeaderMui } from 'components';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { getMessaging, getLocations } from '../../shared/common.api';
import moment from 'moment-timezone';
import EnhancedTable from '../../components/mui/enhanced-table/enhanced-table.component';
import { Typography } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import ReplyIcon from '@mui/icons-material/Reply';
import { userState } from 'shared/user-state';
import { Icon } from 'components/icon/icon.component';
import { SingleSelectFilter } from 'components/mui/filter/single-select.component';
import { MultiSelectFilter } from 'components/mui/filter/multi-select.component';
import { ToggleFilter } from 'components/mui/filter/toggle.component';
import { LocationTooltip } from 'components/mui/tooltip/location-tooltip.component';
import { CustomWidthTooltip } from '../../components/mui/tooltip/custom-width-tooltip.component';
import utils from 'shared/utils';
import { CustomerDrawerContext } from '../../context/customer-drawer-context';
import { useContext } from 'react';
const renderReplied = (review, hover) => {
  if (!review.reply_when && review.id == hover) {
    return <div style={{
      width: '40px',
      display: 'flex'
    }}>
        <ReplyIcon sx={{
        fontSize: '24px',
        color: '#53A6D6'
      }} />
        <Typography sx={{
        pl: 1
      }} color={'#53A6D6'}>
          Reply
        </Typography>
      </div>;
  }
  if (!!review.reply_when && review.id == hover) {
    return <div style={{
      width: '40px',
      display: 'flex'
    }}>
        <EditOutlinedIcon sx={{
        fontSize: '24px',
        color: '#53A6D6'
      }} />
        <Typography sx={{
        pl: 1
      }} color={'#53A6D6'}>
          Edit Reply
        </Typography>
      </div>;
  }
  if (!review.reply_when) {
    return <CancelOutlinedIcon sx={{
      fontSize: '24px',
      color: '#FCCD6F'
    }} />;
  }
  return <CheckCircleOutlineIcon sx={{
    fontSize: '24px',
    color: '#66CC99'
  }} />;
};
export const Messaging = () => {
  const [search, setSearch] = React.useState('');
  const [locations, setLocations] = React.useState([]);
  const [filters, setFilters] = React.useState({
    date_after: moment().subtract(7, 'days').startOf('day').toISOString()
  });
  const {
    openCustomerDrawer
  } = useContext(CustomerDrawerContext);
  React.useEffect(() => {
    getLocations({
      company: userState.getAsCompanyId(),
      limit: 1000,
      ordering: 'name'
    }).then(({
      data
    }) => {
      if (data && data.results) {
        setLocations(data.results);
      }
    });
  }, []);
  React.useEffect(() => {
    if (filters.direction && filters.has_reply) {
      const {
        has_reply: _,
        ...newFilters
      } = filters;
      setFilters(newFilters);
    }
  }, [filters]);
  const searchSegment = search => {
    setSearch(search);
  };
  const filterOptions = [{
    component: SingleSelectFilter,
    label: 'Text Direction',
    key: 'direction',
    options: [{
      label: 'Incoming',
      value: 'incoming',
      isDefault: true
    }, {
      label: 'Outgoing',
      value: 'outgoing'
    }, {
      label: 'Both',
      value: 'both'
    }]
  }, {
    component: SingleSelectFilter,
    label: 'Replied',
    key: 'has_reply',
    disabled: !!filters.direction,
    options: [{
      label: 'Show all',
      value: 'all',
      isDefault: true
    }, {
      label: 'Yes',
      value: 'true'
    }, {
      label: 'No',
      value: 'false'
    }]
  }, {
    component: ToggleFilter,
    label: 'Show Start/Stop/Unsubscribe',
    key: 'show_opt',
    defaultVal: 'off'
  }, {
    component: MultiSelectFilter,
    label: 'Last Location',
    key: 'last_location_ids',
    options: locations.map(location => ({
      label: location.name,
      value: location.id
    }))
  }];
  const headCells = [{
    key: 'customer',
    align: 'left',
    disablePadding: true,
    label: 'Customer',
    component: 'th',
    scope: 'row',
    padding: 'none',
    lableId: `enhanced-table-checkbox-0`,
    noSort: true,
    displayComponent: ({
      data
    }) => !!data.first_name || !!data.last_name ? `${data.first_name || ''} ${data.last_name || ''}` : utils.formatPhoneNumber(data.customer_phone),
    props: {}
  }, {
    key: 'review_when',
    align: 'left',
    disablePadding: true,
    label: 'Date',
    noSort: true,
    displayComponent: ({
      data
    }) => <>{moment(data.last_message_when).format('MMM D, YYYY')}</>
  }, {
    key: 'last_location',
    align: 'left',
    disablePadding: true,
    label: 'Last Location',
    noSort: true,
    displayComponent: ({
      data
    }) => <LocationTooltip location={{
      address1: data.last_location_address,
      entity_number: data.entity_number,
      entity_ref: data.entity_ref,
      name: data.last_location_name,
      city: data.last_location_city,
      state: data.last_location_state,
      zip_code: data.last_location_zip_code
    }} />
  }, {
    key: 'comment',
    align: 'left',
    disablePadding: true,
    label: 'Message',
    type: 'string',
    noSort: true,
    displayComponent: ({
      data
    }) => {
      const message = `${data.last_message_direction === 'outgoing' ? 'You: ' : ''}${data.last_message}`;
      return <CustomWidthTooltip tip={message} />;
    }
  }, {
    key: 'reply_when',
    align: 'left',
    disablePadding: true,
    label: 'Replied',
    noSort: true,
    displayComponent: ({
      data,
      onHover
    }) => <>
          {data.last_message_direction === 'incoming' && renderReplied(data, onHover)}
        </>
  }];
  return <>
      <div className="wrapper" style={{
      backgroundColor: '#DAEFFF'
    }}>
        <PageHeaderMui type="Two-Way Messaging" icon={<Icon name="fa-solid-comments-alt" />} searchVal={search} onSearch={searchSegment} />
        <div className="mui-wrapper">
          <CssBaseline />
          <EnhancedTable sx={{
          fontFamily: 'Nexa'
        }} headCells={headCells} search={search} api={getMessaging} enableAutoRefresh handleRowClick={row => {
          openCustomerDrawer(row.id, "messaging");
        }} filterOptions={filterOptions} filters={filters} setFilters={setFilters} />
        </div>
      </div>
    </>;
};