import React, { Component } from 'react';
import { string } from 'prop-types';
import { Scoped } from 'kremling';
import styles from './badge.styles.scss';
export class Badge extends Component {
  static propTypes = {
    name: string
  };
  static defaultProps = {
    name: ''
  };
  render() {
    return <Scoped css={styles}>
        <span className="badge">
          {this.props.name.split(' ').filter((segment, i) => i < 2).map(segment => segment[0]).join('').toUpperCase()}
        </span>
      </Scoped>;
  }
}