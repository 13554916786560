import React, { Component, useContext } from 'react';
import { Scoped, k } from 'kremling';
import { get } from 'lodash';
import { PageHeader } from 'components/page-header/page-header';
import { Dropdown } from 'components/dropdown/dropdown.component';
import { Button } from 'components/button/button.component';
import { FormWizard } from './form-wizard/form-wizard.component';
import { userState } from 'shared/user-state';
import { ModalDialog } from 'components/modal/modal-dialog.component';
import { toasterService } from 'components/toaster/toaster-service';
import { deleteForm, getForms } from 'shared/forms.api';
import { Status } from '../../components/status/status.component';
import CssBaseline from '@mui/material/CssBaseline';
import NewFormDialog from './new-form-dialog.component';
import GoogleLeads from './forms/google-leads-dialog.component';
import { Loader } from '../../components/loader/loader.component';
import FacebookLeads from './forms/facebook-leads-dialog.component';
export class WebFormsList extends Component {
  state = {
    forms: [],
    showFormWizard: false,
    deletingForm: null,
    newForm: false,
    googleLeadsDialog: false,
    googleLeadsDialogData: null,
    facebookLeadsDialog: false,
    facebookLeadsDialogData: null,
    isLoading: false
  };
  componentDidMount() {
    this.getForms();
  }
  getForms = () => {
    this.setState({
      isLoading: true
    });
    getForms({
      company: userState.getAsCompanyId(),
      limit: 1000
    }).then(({
      data
    }) => {
      this.setState({
        isLoading: false
      });
      this.setState({
        forms: data.results
      });
    });
  };
  onFormWizardClose = cancelled => {
    this.setState({
      showFormWizard: false
    }, !cancelled ? this.getForms : null);
  };
  deleteForm = () => {
    const {
      deletingForm
    } = this.state;
    deleteForm(deletingForm.id).then(() => {
      toasterService.success(`Successfully deleted "${deletingForm.name}" form.`);
      this.setState({
        deletingForm: null
      }, this.getForms);
    });
  };
  getDropDown = form => {
    if (form.entity_source === 'google') {
      return <li>
          <a href={`https://docs.google.com/forms/d/${form.entity_ref}/edit`} target="_blank">
            Edit on Google
          </a>
        </li>;
    } else if (form.entity_source === 'google_leads') {
      return <li>
            <a onClick={() => {
          this.setState({
            googleLeadsDialog: true,
            googleLeadsDialogData: {
              id: form.id,
              name: form.name
            }
          });
        }}>
              View Connection Credentials
            </a>
          </li>;
    } else if (userState.hasPermission('forms.delete_form')) {
      return <li>
          <a onClick={() => this.setState({
          deletingForm: form
        })}>Delete</a>
        </li>;
    }
  };
  handleNewFormDialogClose = () => {
    this.setState({
      newForm: false
    });
  };
  handleNewFormDialogContinue = choice => {
    this.setState({
      newForm: false
    });
    if (choice === 'webform') {
      this.setState({
        showFormWizard: true
      });
    }
    if (choice === 'googleleads') {
      this.setState({
        googleLeadsDialog: true
      });
    }
    if (choice === 'facebookleads') {
      this.setState({
        facebookLeadsDialog: true
      });
    }
  };
  handleGoogleLeadsDialogClose = () => {
    this.setState({
      googleLeadsDialog: false
    });
  };
  handleGoogleLeadsDialogSubmit = () => {
    this.setState({
      googleLeadsDialog: false
    });
    this.getForms();
  };
  handleFacebookLeadsDialogClose = () => {
    this.setState({
      facebookLeadsDialog: false
    });
  };
  handleFacebookLeadsDialogSubmit = () => {
    this.setState({
      facebookLeadsDialog: false
    });
    this.getForms();
  };
  render() {
    const {
      history
    } = this.props;
    const {
      showFormWizard,
      forms,
      deletingForm,
      newForm,
      googleLeadsDialog,
      googleLeadsDialogData,
      facebookLeadsDialog,
      facebookLeadsDialogData,
      isLoading
    } = this.state;
    return <Scoped css={css}>
        <div className="wrapper">
        {isLoading && <Loader overlay background="rgba(255,255,255,.5)" />}
          <PageHeader name="Web Forms" actions={userState.hasPermission('forms.add_form') && <Button actionType="primary" onClick={() => this.setState({
          newForm: true
        })}>
                + Add Form
              </Button>} />
          <div className="wrapper-scroll p-md">
            <table className="table-list">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Source</th>
                  <th style={{
                  width: 20
                }} />
                </tr>
              </thead>
              <tbody>
                {forms.map(form => <tr key={form.id} onClick={() => history.push(`/forms/${form.id}`)}>
                    <td>{form.name}</td>
                    <td>
                      {form.entity_source === 'google_leads' ? <> Google Leads </> : <>{form.entity_source === 'facebook_leads' ? <>Facebook Leads</> : <>{form.entity_source === 'google' ? <>
                            Google
                            {!!form.fields || <Status status="draft" className="ml-2">
                                Unlinked
                              </Status>}
                          </> : 'Web'}</>}
                      </>}
                    </td>
                    <td>
                      <Dropdown horizontal="west" trigger={() => <Button icon="fa-regular-ellipsis-h" actionType="flat" />} content={() => <ul className="select-list">
                            {this.getDropDown(form)}
                          </ul>} />
                    </td>
                  </tr>)}
              </tbody>
            </table>
          </div>
        </div>
        <ModalDialog open={!!deletingForm} title="Delete Form" submitText="Delete" onSubmit={this.deleteForm} onClose={() => this.setState({
        deletingForm: null
      })}>
          This action will permanently delete form "{get(deletingForm, 'name')}"
        </ModalDialog>

        <FormWizard isOpen={showFormWizard} onClose={this.onFormWizardClose} />

        {googleLeadsDialog && <GoogleLeads open={googleLeadsDialog} data={googleLeadsDialogData} onClose={this.handleGoogleLeadsDialogClose} handleSubmit={this.handleGoogleLeadsDialogSubmit} />}

        {facebookLeadsDialog && <FacebookLeads open={facebookLeadsDialog} data={facebookLeadsDialogData} onClose={this.handleFacebookLeadsDialogClose} handleSubmit={this.handleFacebookLeadsDialogSubmit} />}

        {newForm && <>
          <CssBaseline />
          <NewFormDialog open={newForm} onClose={this.handleNewFormDialogClose} onContinue={this.handleNewFormDialogContinue} />
        </>}
      </Scoped>;
  }
}
const css = {
  styles: `[kremling="i18"] body,body[kremling="i18"] {
  --color-primary: #487aae;
  --color-highlight: #e7efff;
  --color-accent: #354052;
  --color-success: #73b56e;
  --color-warning: #f4b707;
  --color-danger: #df5651;
  --color-grey-10: #f9f9f9;
  --color-grey-25: #f3f3f3;
  --color-grey-50: #e9e9e9;
  --color-grey-75: #e3e3e3;
  --color-grey-100: #d3d3d3;
  --color-grey-200: #c3c3c3;
  --color-grey-300: #b3b3b3;
  --color-grey-400: #a3a3a3;
  --color-grey-500: #808080;
  --color-grey-600: #707070;
  --color-grey-700: #606060;
  --color-grey-800: #505050;
  --color-grey-900: #404040;
  --base-font-family: Roboto, Helvetica, Arial, sans-serif;
  --base-font-size: 1.4rem;
  --base-font-color: #404040;
  --base-font-weight: 400;
  --base-line-height: 1.4;
  --box-shadow-1: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.12);
  --box-shadow-2: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-3: 0 10px 20px rgba(0, 0, 0, 0.17), 0 6px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-4: 0 14px 28px rgba(0, 0, 0, 0.13), 0 10px 10px rgba(0, 0, 0, 0.1);
  --box-shadow-5: 0 19px 38px rgba(0, 0, 0, 0.18), 0 15px 12px rgba(0, 0, 0, 0.1);
  --base-border-radius: 0.4rem;
}`,
  id: 'i18',
  namespace: 'kremling'
};