import React, { useEffect, useState, createContext } from 'react';
import { WorkingAsContextProvider } from './working-as-context';
import { CustomerDrawerContextProvider } from './customer-drawer-context';
export const ContextProviders = ({
  children
}) => {
  return <WorkingAsContextProvider>
      <CustomerDrawerContextProvider>
        {children}
      </CustomerDrawerContextProvider>
    </WorkingAsContextProvider>;
};