import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';
import { getJourneys, getTemplates, getTemplatesTags, deleteEmail, patchEmail, postEmail, duplicateEmail, getEmailTemplateTemplates, createEmailFromTemplate, getBroadcasts } from '../../shared/common.api';
import { Loader, PageHeader, Pagination, Button, DynamicTypeAhead, Icon } from '../../components/index';
import { userState } from '../../shared/user-state';
import utils from '../../shared/utils';
import { DateTime } from 'luxon';

//MATERIAL UI
import CardComponent from '../../components/mui/card/Card';
import { Typography, Box, Stack, Divider, Grid, Link, IconButton, Button as MuiButton } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import DangerousIcon from '@mui/icons-material/Dangerous';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import Tags from '../../components/mui/tags/tags.component';
import ChipStatus from '../../components/mui/status/status.component';
import Modal from '../../components/mui/modal/modal.component';
import { m } from 'kremling';
const filterFields = [{
  key: 'name',
  label: 'Template Name',
  type: 'text'
}, {
  key: 'status',
  label: 'Status',
  type: 'options',
  options: ['draft', 'published']
}, {
  key: 'created',
  label: 'Created',
  type: 'range'
}, {
  key: 'type',
  label: 'Type',
  type: 'options',
  options: ['mail', 'email']
}, {
  key: 'created_by',
  label: 'Created By',
  type: 'text'
}];
const paramsFilters = ['filters', 'search', 'ordering', 'archiveMode'];
export class TemplateListGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      selectedTemplate: null,
      publishedJourneys: [],
      error: null,
      templates: [],
      redirect: {
        active: false,
        to: {
          pathname: '',
          state: {}
        }
      },
      is_loading: false,
      paginationParams: {
        limit: 20,
        offset: 0
      },
      modalType: '',
      openDeleteTemplate: false,
      filters: null,
      isAddTemplateModalOpen: false,
      templateLoading: false,
      emailTemplateId: '',
      ordering: '-updated_when',
      archiveMode: false,
      archiveModalId: null,
      templateInBroadcast: [],
      templateTags: [],
      editTagMode: false,
      filterDrawerOpen: false,
      showArchiveModal: false,
      showHelpModal: false,
      isOpenRestoredModal: false
    };
    this.updateHistory = utils.updateHistory.bind(this);
    this.loadStateFromParams = utils.loadStateFromParams.bind(this);
  }
  postEmail = () => {
    this.setState({
      templateLoading: true
    });
    if (this.state.emailTemplateId !== '') {
      createEmailFromTemplate(this.state.emailTemplateId, userState.getAsCompanyId()).then(({
        data
      }) => {
        this.setState({
          redirect: {
            active: true,
            to: {
              pathname: `/templates/edit/email/${data.id}`
            }
          }
        });
      });
    } else {
      postEmail({
        name: 'Untitled',
        company: userState.getAsCompanyId()
      }).then(({
        data
      }) => {
        this.setState({
          redirect: {
            active: true,
            to: {
              pathname: `/templates/edit/email/${data.id}`,
              isFromCreate: true
            }
          }
        });
      });
    }
  };
  createTemplateOpen = () => {
    this.setState({
      isAddTemplateModalOpen: true,
      templateLoading: false
    });
  };
  createTemplateCancel = () => {
    this.setState({
      isAddTemplateModalOpen: false
    });
  };

  /**
   * FETCH FUNCTIONS FOR LISTING TEMPLATES
   */
  componentDidMount() {
    this.loadStateFromParams(this.state, paramsFilters, this.showTemplates);
    getTemplatesTags(userState.getAsCompanyId()).then(({
      data
    }) => {
      const templatesTags = data.results.map((tag, index) => {
        return {
          tag: tag,
          id: index
        };
      });
      this.setState({
        templatesTags
      });
    });
  }
  showTemplates = () => {
    const {
      search,
      ordering,
      filters,
      archiveMode
    } = this.state;
    this.setState({
      isLoading: true
    });
    getTemplates({
      ...this.state.paginationParams,
      ...(filters || {}),
      search: search,
      archived: archiveMode,
      company: userState.getAsCompanyId(),
      type_in: 'email',
      ordering
    }).then(({
      data
    }) => {
      const state = {
        isLoading: false,
        editTagMode: false
      };
      state.templates = data.results;
      state.paginationData = data;
      this.setState(state);
    }).catch(error => this.setState({
      error,
      isLoading: false
    }));
  };
  searchTemplates = search => {
    this.setState({
      search
    }, () => {
      this.updateHistory(this.state, paramsFilters);
      this.showTemplates();
    });
  };
  onFilter = filters => {
    this.setState({
      filters
    }, () => {
      this.updateHistory(this.state, paramsFilters);
      this.showTemplates();
    });
  };
  pageOnChange = params => {
    this.setState({
      paginationParams: params
    }, () => {
      this.updateHistory(this.state, paramsFilters);
      this.showTemplates();
    });
  };
  handleDeleteEmailTemplateModal = template => {
    const {
      openDeleteTemplate
    } = this.state;
    this.setState({
      openDeleteTemplate: !openDeleteTemplate,
      selectedTemplate: template
    });
  };
  deleteEmailTemplate = template => {
    deleteEmail(template.id).then(() => {
      this.showTemplates();
      this.handleDeleteEmailTemplateModal(null);
    }).catch(error => {
      toast.error('Oops, something went wrong!');
    });
  };
  loadBroadcasts = id => {
    return getBroadcasts({
      company: userState.getAsCompanyId(),
      email_template: id,
      broadcast_when: false
    }).then(data => {
      this.setState({
        templateInBroadcast: data.data.results
      });
    });
  };
  loadPublishedJourneys = id => {
    return getJourneys({
      company: userState.getAsCompanyId(),
      node_template: id,
      archived: false
    }).then(companies => {
      this.setState({
        publishedJourneys: companies.data.results
      });
    });
  };
  toastClosed = () => {
    window.location.reload();
  };
  getLinkToTemplate = id => {
    return `/templates/edit/email/${id}`;
  };

  /**
   * ARCHIVE TEMPLATE
   */

  closeArchiveModal = () => {
    this.setState({
      openDeleteTemplate: false
    });
  };
  updateEmail = template => {
    patchEmail(template.id, {
      archived_when: DateTime.local()
    }).then(() => {
      this.showTemplates();
      this.closeArchiveModal();
    });
  };
  openArchiveModal = template => {
    const {
      publishedJourneys,
      templateInBroadcast
    } = this.state;
    if (!!publishedJourneys.length || !!templateInBroadcast.length) {
      this.setState({
        showArchiveModal: true
      });
    } else {
      this.updateEmail(template);
    }
  };
  archiveTemplate = async template => {
    this.setState({
      templateInBroadcast: [],
      publishedJourneys: [],
      selectedTemplate: template
    });
    if (template.published) {
      await this.loadPublishedJourneys(template.id);
      await this.loadBroadcasts(template.id);
      this.openArchiveModal(template);
    } else {
      this.updateEmail(template);
    }
  };
  restoreTemplate = template => {
    patchEmail(template.id, {
      archived_when: null
    }).then(() => {
      this.setState({
        isOpenRestoredModal: true
      });
      this.showTemplates();
    });
  };
  duplicateEmailTemplate = template => {
    duplicateEmail(template.id).then(({
      data
    }) => this.props.history.push(`/templates/edit/email/${data.id}`));
  };
  updateOrdering = ordering => {
    this.setState({
      ordering
    }, () => {
      this.updateHistory(this.state, paramsFilters);
      this.showTemplates();
    });
  };
  handleArchiveMode = () => {
    const {
      archiveMode
    } = this.state;
    this.setState({
      archiveMode: !archiveMode,
      search: '',
      paginationParams: {}
    }, () => {
      this.updateHistory(this.state, paramsFilters);
      this.showTemplates();
    });
  };
  addTagFromTemplateteRow = (e, tag) => {
    e.stopPropagation();
    this.setState({
      filterDrawerOpen: true
    });
    let filters = this.state.filters ? this.state.filters : {};
    if (filters && filters.tags && filters.tags.length !== 0) {
      if (!filters.tags.includes(tag)) {
        filters.tags.push(tag);
      }
    } else {
      filters.tags = [];
      filters.tags.push(tag);
    }
    this.onFilter(filters);
  };
  render() {
    const {
      templates,
      redirect,
      isLoading,
      error,
      paginationData,
      isAddTemplateModalOpen,
      templateLoading,
      publishedJourneys,
      emailTemplateId,
      selectedTemplate,
      templatesTags,
      search,
      filters,
      archiveMode,
      templateInBroadcast,
      filterDrawerOpen,
      editTagMode,
      showArchiveModal,
      showHelpModal,
      openDeleteTemplate,
      isOpenRestoredModal,
      isOpenRestoreModal
    } = this.state;
    if (redirect.active) {
      return <Redirect to={redirect.to} />;
    }
    return <div className="wrapper">
        <PageHeader name={archiveMode ? 'Templates - Archive' : 'Templates'} searchVal={search} onSearch={this.searchTemplates} filterDrawerOpen={filterDrawerOpen} actions={<>
              {userState.hasPermission('template.add_template') && <Button actionType="primary" icon="fa-regular-plus" onClick={this.createTemplateOpen} />}
              <Button onClick={() => this.handleArchiveMode()} actionType={archiveMode ? 'primary' : 'grey'} style={{
          position: 'absolute',
          right: '340px'
        }}>
                {archiveMode ? 'Exit Archive' : 'View Archive'}
              </Button>
            </>} tags={templatesTags} onFilter={filters => this.onFilter(filters)} filterVals={filters} filters={[...filterFields, {
        key: 'tags',
        label: 'Tags',
        type: 'typeahead',
        options: templatesTags
      }]} />
        <ToastContainer />
        <Box sx={{
        flexGrow: 1
      }} className={'m-5'}>
          <Grid container className="mb-4" spacing={1} direction="row" justifyContent="flex-start" alignItems="baseline">
            {isLoading && <Loader overlay />}
            {error && <p>{error.message}</p>}
            {!!templates.length && !isLoading && templates.map(template => {
            return <Grid item xs={3} className="mb-4" key={template.id}>
                    <CardComponent className="mb-2" title={<Typography variant="subtitle1">Updated</Typography>} subheader={moment(template.updated_when).format('LL')} imgUrl={template.email.thumbnail_url} actions={<div>
                          {template.archived_when ? <ChipStatus status={'archived'} /> : template.published ? <ChipStatus status="published" /> : <ChipStatus status="draft" />}
                        </div>} cardContent={<Box>
                          <Stack spacing={0.5}>
                            <Typography variant="subtitle1">Title</Typography>
                            <Typography variant="h4" sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: '2',
                    WebkitBoxOrient: 'vertical',
                    minHeight: '55px'
                  }}>
                              {template.name}
                            </Typography>
                            <Typography variant="subtitle1">
                              Subject Line
                            </Typography>
                            <Typography variant="body1" minHeight="32px">
                              {template.published && template.email.revisions[1] ? template.email.revisions[1].subject : template.email.revisions[0].subject}
                            </Typography>
                            <Divider variant="middle" />
                            <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    pl: 1,
                    pb: 1,
                    pt: 2
                  }}>
                              <Typography variant="subtitle1">Tags</Typography>
                              <Link underline="none" variant="secondary" onClick={() => this.setState({
                      selectedTemplate: template,
                      editTagMode: !editTagMode
                    })}>
                                {editTagMode && selectedTemplate.id === template.id ? 'Cancel' : 'Edit'}
                              </Link>
                            </Box>
                            <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    pl: 1,
                    pb: 1
                  }}>
                              <Tags editTagMode={editTagMode && selectedTemplate.id === template.id} onSuccess={() => this.showTemplates()} objectTarget={editTagMode && selectedTemplate.id === template.id ? selectedTemplate : template} getViewTags={getTemplatesTags} patchView={patchEmail} viewName={'Templates'} onClick={(e, tag) => {
                      this.addTagFromTemplateteRow(e, tag);
                    }} />
                            </Box>
                          </Stack>
                        </Box>} cardActions={<Box width="100%" sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-evenly',
                pl: 1,
                pb: 1
              }}>
                          <Link underline="none" variant="primary" onClick={() => this.props.history.push(this.getLinkToTemplate(template.id))}>
                            OPEN
                          </Link>
                          <Box sx={{
                  display: 'flex',
                  marginLeft: 'auto'
                }}>
                            {archiveMode ? <IconButton variant="button" onClick={() => this.setState({
                    isOpenRestoreModal: true,
                    selectedTemplate: template
                  })} aria-label="add to favorites">
                                <Icon name="fa-regular-undo" size={18} />
                              </IconButton> : <IconButton variant="button" onClick={() => this.duplicateEmailTemplate(template)} aria-label="add to favorites">
                                <Icon name="fa-regular-copy" size={18} />
                              </IconButton>}
                            <IconButton variant="button" aria-label="share" onClick={() => this.handleDeleteEmailTemplateModal(template)}>
                              <Icon name="fa-regular-trash" size={18} />
                            </IconButton>
                          </Box>
                        </Box>} key={template.id} />
                  </Grid>;
          })}
          </Grid>
          <Pagination data={paginationData} onChange={this.pageOnChange} />
          <Modal maxWidth="256px" height="203px" open={openDeleteTemplate} title={showHelpModal ? <Box sx={{
          p: 0,
          m: 0,
          display: 'flex',
          alignItems: 'center'
        }}>
                  <IconButton sx={{
            p: 0
          }} aria-label="back" onClick={() => this.setState({
            showHelpModal: false
          })}>
                    <ArrowBackIosIcon />
                  </IconButton>
                  <Typography variant="subtitle1">Help</Typography>
                </Box> : <Typography variant="subtitle1">Remove Template</Typography>} onClose={() => this.setState({
          showArchiveModal: false,
          openDeleteTemplate: false
        })} content={!showArchiveModal ? <Box>
                  <Typography variant="body1">
                    How would like to remove the template?
                  </Typography>
                  <br></br>
                  <Typography variant="body1" display="block">
                    <b style={{
              fontColor: '#2E3645'
            }}>Note</b>:The delete
                    action cannot be undone.
                  </Typography>
                </Box> : !showHelpModal && selectedTemplate ? <Box>
                  <Box sx={{
            display: 'flex'
          }}>
                    <DangerousIcon sx={{
              fill: '#E60D0D'
            }} />
                    <Typography variant="body1">
                      Template, {selectedTemplate.name} can’t be removed because
                      it is being used in the
                    </Typography>
                  </Box>
                  <br></br>
                  {!!publishedJourneys.length && <Box sx={{
            ml: 2
          }}>
                      <Typography variant="subtitle1">Journeys:</Typography>
                      {publishedJourneys.map(journey => {
              return <Typography paragraph={true} sx={{
                mb: '4px'
              }}>
                            <Link underline="none" onClick={() => this.props.history.push(`/customer-journeys/${journey.id}`)}>
                              {journey.name}
                            </Link>
                          </Typography>;
            })}
                    </Box>}
                  {!!templateInBroadcast.length && <Box sx={{
            ml: 2
          }}>
                      <Typography variant="subtitle1">Broadcasts:</Typography>
                      {templateInBroadcast.map((broadcast, index) => {
              return <p>
                            <Link underline="none" onClick={() => this.props.history.push(`/customer-journeys/${broadcast.id}`)}>
                              {broadcast.name}
                            </Link>
                          </p>;
            })}
                    </Box>}
                </Box> : <Box>
                  <Typography variant="body1" fontWeight={600}>
                    Removing a Published Template
                  </Typography>
                  <Typography variant="body1" display="block">
                    To remove a published Template turn off the Journey or
                    Broadcast it is associated with or replace it with another
                    template.{' '}
                  </Typography>
                  <br></br>
                </Box>} actions={!showArchiveModal ? <Box>
                  <MuiButton variant="text" onClick={() => this.deleteEmailTemplate(selectedTemplate)}>
                    DELETE
                  </MuiButton>
                  {!archiveMode && <MuiButton variant="text" onClick={() => this.archiveTemplate(selectedTemplate)}>
                      ARCHIVE
                    </MuiButton>}
                </Box> : !showHelpModal ? <Box>
                  <MuiButton variant="text" onClick={() => this.setState({
            showHelpModal: true
          })}>
                    HELP
                  </MuiButton>
                  <MuiButton variant="text" onClick={() => this.setState({
            openDeleteTemplate: false,
            showArchiveModal: false
          })}>
                    Cancel
                  </MuiButton>
                </Box> : <Box>
                  <MuiButton variant="text" onClick={() => this.setState({
            openDeleteTemplate: false
          })}>
                    okay
                  </MuiButton>
                </Box>} />
          <Modal maxWidth="256px" height="203px" open={isOpenRestoreModal} title={<Typography variant="subtitle">Restore Template</Typography>} onClose={!isOpenRestoredModal ? () => this.setState({
          isOpenRestoreModal: false
        }) : null} content={selectedTemplate && <Box>
                  {!isOpenRestoredModal ? <Typography variant="body1">
                      Would you like to restore template,
                      <Link underline="none" onClick={() => this.props.history.push(`/templates/edit/email/${selectedTemplate.id}`)}>
                        {' '}
                        {selectedTemplate.name}
                      </Link>{' '}
                      as a draft?
                    </Typography> : <Typography variant="body1">
                      Template,{' '}
                      <Link underline="none" onClick={() => this.props.history.push(`/templates/edit/email/${selectedTemplate.id}`)}>
                        {selectedTemplate.name}
                      </Link>{' '}
                      has been restored.
                    </Typography>}
                </Box>} actions={!isOpenRestoredModal ? <Box>
                  <MuiButton variant="text" onClick={() => this.setState({
            isOpenRestoreModal: false
          })}>
                    CANCEL
                  </MuiButton>
                  <MuiButton variant="text" onClick={() => this.restoreTemplate(selectedTemplate)}>
                    CONFIRM
                  </MuiButton>
                </Box> : <Box>
                  <MuiButton variant="text" onClick={() => {
            this.props.history.push(`/templates/edit/email/${selectedTemplate.id}`);
            this.setState({
              isOpenRestoredModal: false,
              isOpenRestoreModal: false
            });
          }}>
                    Open Draft
                  </MuiButton>
                  <MuiButton variant="text" onClick={() => this.setState({
            isOpenRestoreModal: false,
            isOpenRestoredModal: false
          })}>
                    okay
                  </MuiButton>
                </Box>} />
          <Modal open={isAddTemplateModalOpen} title="Create Email Template" onClose={this.createTemplateCancel} content={<div className="modal__body">
                <div className="form-group space-between">
                  <div className="form-group">
                    <label>
                      You can either create a blank new email or create an email
                      from a template.
                    </label>
                    <DynamicTypeAhead getItems={getEmailTemplateTemplates} getItemsFilters={{
                company: userState.state.asCompany.id,
                ordering: 'email__name'
              }} placeholder="Blank Template" displayProperty="email_name" keyProperty="id" value={emailTemplateId ? emailTemplateId : null} onChange={val => this.setState({
                emailTemplateId: val ? val.id : null
              })} />
                  </div>
                </div>
              </div>} actions={<div className="modal__actions">
                <Button actionType="flat" onClick={this.createTemplateCancel}>
                  Cancel
                </Button>
                <Button onClick={this.postEmail} actionType="primary">
                  Create Email Template
                </Button>
                {templateLoading && <Loader background={'#fff'} overlay />}
              </div>}></Modal>
        </Box>
      </div>;
  }
}