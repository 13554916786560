import React, { Component } from 'react';
import { Scoped, k } from 'kremling';
import cinchLogo from '../../assets/img/cinch-logo.svg';
import { getStatus } from '../../shared/common.api';
import signInImg from '../../assets/img/sign-in-pattern.jpg';
export class Maintenance extends Component {
  setLogo = () => ({
    __html: cinchLogo
  });
  render() {
    return <Scoped css={css}>
        <div className="wrapper-contain">
          <div className="sign-in__wrapper" style={{
          backgroundImage: `url(${signInImg})`
        }}>
            <div className="sign-in">
              <div className="text-center">
                <div className="mb-5">
                  <div className="logo" dangerouslySetInnerHTML={this.setLogo()} />
                </div>

                {this.props?.inHeavyUsage ? <div>{window.navigator.onLine ? 'Site is currently experiencing unusually high load at the moment.  We are scaling up to fix the issue right now.' : 'Your computer seems to be offline.  Check your network connection.'}</div> : <div>{window.navigator.onLine ? 'Site is currently undergoing maintenance.' : 'Your computer seems to be offline.  Check your network connection.'}</div>}

              </div>
            </div>
          </div>

        </div>
      </Scoped>;
  }
}
const css = {
  styles: `[kremling="i6"] body,body[kremling="i6"] {
  --color-primary: #487aae;
  --color-highlight: #e7efff;
  --color-accent: #354052;
  --color-success: #73b56e;
  --color-warning: #f4b707;
  --color-danger: #df5651;
  --color-grey-10: #f9f9f9;
  --color-grey-25: #f3f3f3;
  --color-grey-50: #e9e9e9;
  --color-grey-75: #e3e3e3;
  --color-grey-100: #d3d3d3;
  --color-grey-200: #c3c3c3;
  --color-grey-300: #b3b3b3;
  --color-grey-400: #a3a3a3;
  --color-grey-500: #808080;
  --color-grey-600: #707070;
  --color-grey-700: #606060;
  --color-grey-800: #505050;
  --color-grey-900: #404040;
  --base-font-family: Roboto, Helvetica, Arial, sans-serif;
  --base-font-size: 1.4rem;
  --base-font-color: #404040;
  --base-font-weight: 400;
  --base-line-height: 1.4;
  --box-shadow-1: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.12);
  --box-shadow-2: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-3: 0 10px 20px rgba(0, 0, 0, 0.17), 0 6px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-4: 0 14px 28px rgba(0, 0, 0, 0.13), 0 10px 10px rgba(0, 0, 0, 0.1);
  --box-shadow-5: 0 19px 38px rgba(0, 0, 0, 0.18), 0 15px 12px rgba(0, 0, 0, 0.1);
  --base-border-radius: 0.4rem;
}

[kremling="i6"] .sign-in__wrapper,[kremling="i6"].sign-in__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-position: center;
}

[kremling="i6"] .sign-in,[kremling="i6"].sign-in {
  background-color: #fff;
  padding: 5rem 2rem;
  border-radius: 2rem;
  box-shadow: var(--box-shadow-5);
  flex-grow: 1;
}
@media (min-width: 768px) {
  [kremling="i6"] .sign-in,[kremling="i6"].sign-in {
    border-radius: 1rem;
    padding: 5rem 3rem;
    width: 36rem;
    flex-grow: 0;
  }
}

[kremling="i6"] .logo,[kremling="i6"].logo {
  fill: var(--color-primary);
  width: 100px;
  margin: 0 auto;
}`,
  id: 'i6',
  namespace: 'kremling'
};