import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { Scoped } from 'kremling';
import { EDIT_COMPONENT_MAP } from './components';
import { Button } from 'components/button/button.component';
import { journeyState } from '../../journey-state';
import { ModalWrapper } from './modal-wrapper.component';
import css from './node-modal.styles.scss';
export const DynamicModalDraft = props => {
  const {
    node,
    stageX,
    stageY,
    scale
  } = props;
  const [data, setData] = useState(node.parameters);
  const close = () => {
    journeyState.closeParam();
  };
  const apply = () => {
    node.updateParameters(data);
    close();
  };
  const update = newData => {
    setData(prevData => ({
      ...prevData,
      ...newData
    }));
  };
  const reset = () => {
    setData(prevData => ({
      ...prevData,
      ...node.defaultParameters
    }));
  };
  if (!node) {
    // Is this necessary?
    return null;
  }
  return createPortal(<Scoped css={css}>
      <ModalWrapper node={node} stageX={stageX} stageY={stageY} scale={scale}>
        <Button className="node-modal__head-close" actionType="flat" icon="fa-regular-times" small onClick={close} />
        <div className="node-modal__inner">
          <div className="row">
            {node.componentParams.map(param => <div className={param.className || 'col-12'} key={param.key}>
                {!!EDIT_COMPONENT_MAP[param.type] && React.createElement(EDIT_COMPONENT_MAP[param.type], {
              param,
              update,
              data,
              node
            })}
              </div>)}
          </div>
        </div>
        <div className="node-modal__footer">
          <div>
            <Button actionType="flat" onClick={reset}>
              Reset
            </Button>
          </div>
          <div className="node-modal__footer-inner">
            <Button actionType="flat" onClick={close}>
              Cancel
            </Button>
            <Button actionType="primary" onClick={apply}>
              Apply
            </Button>
          </div>
        </div>
      </ModalWrapper>
    </Scoped>, document.body);
};