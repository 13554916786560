import React, { useState, useContext } from 'react';
import { Button, Loader, toasterService, Banner } from 'components';
import { WorkingAsContext } from 'context/working-as-context';
import { submitBrand } from 'shared/common.api';
export const SubmitBrandStep = props => {
  const {
    profile,
    setActiveStep,
    setProfile
  } = props;
  const {
    asCompany
  } = useContext(WorkingAsContext);
  const [isSaving, setIsSaving] = useState(false);
  const submit = () => {
    // Submit this to the Cinch API which will submit to the Twilio API...
    // This will not be stored in Cinch's database but will be retrieved directly from Twilio
    setIsSaving(true);
    submitBrand(asCompany.id).then(() => {
      setProfile({
        ...profile,
        brand_status: 'PENDING'
      });
    }).catch(() => {
      toasterService.error('There was an unknown error trying to save your profile. Check your inputs and try again');
    }).then(() => {
      setIsSaving(false);
    });
  };
  if (profile.brand_status === 'PENDING') {
    return <Banner type="info" className="my-3">
        Your application is currently "In Progress". A2P Brand Registration can take up to 12 hours in some cases. If you see your brand
        stuck in the "In Progress" state for more than 12 hours, please contact support.
      </Banner>;
  }
  return <>
      {profile.brand_status === 'FAILED' && <Banner type="danger" className="mb-3">
          Your application failed verification with the reason: {profile.brand_failure_reason}. Go back and update your information and then
          resubmit.
        </Banner>}
      {profile.brand_attempts < 3 ? <Banner type="warning" className="mb-3">
          You can only submit your brand for verification three times. Currently submitted {profile.brand_attempts} of 3 times. Make sure
          all information is correct before doing so.
        </Banner> : <Banner type="danger" className="mb-3">
          You have already submitted your brand for verification three times. You need to contact support for further help.
        </Banner>}
      <div style={{
      display: 'flex',
      gap: '10px',
      justifyContent: 'end'
    }}>
        <Button actionType="flat" onClick={() => setActiveStep(5)}>
          Back
        </Button>
        <Button actionType="primary" disabled={isSaving} onClick={submit}>
          Submit {isSaving && <Loader className="ml-3" size="sm" />}
        </Button>
      </div>
    </>;
};