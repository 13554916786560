import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import { snackbarService } from 'components/mui/snackbar/snackbar-service';
import { updatePassword } from 'shared/auth.api';
export default function NeedsPasswordResetModal(props) {
  const {
    open,
    me,
    setUser,
    logOut
  } = props;
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const submitPassword = () => {
    setIsLoading(true);
    updatePassword(currentPassword, newPassword, newPasswordConfirmation).then(() => {
      setUser({
        ...me,
        requires_password_reset: false
      });
    }).catch(({
      response
    }) => {
      if (response?.data?.non_field_errors?.[0]) {
        snackbarService.popup({
          type: 'error',
          message: response?.data?.non_field_errors?.[0]
        });
      } else {
        snackbarService.popup({
          type: 'error',
          message: "Unknown error trying to set your password."
        });
      }
    }).then(() => {
      setIsLoading(false);
    });
  };
  return <Dialog BackdropProps={{
    style: {
      backdropFilter: 'blur(3px)'
    }
  }} PaperProps={{
    style: {
      borderRadius: '28px',
      minWidth: '400px'
    }
  }} open={open} onClose={() => {}}>
      <DialogTitle sx={{
      m: 0,
      p: 2,
      fontSize: 'medium'
    }}>
        <Stack alignItems="center" component="span">
          <ErrorOutlineIcon sx={{
          fontSize: 40,
          color: '#EF3C34'
        }} />
          <Typography variant="h4" component="span">
            Password Reset Needed
          </Typography>
        </Stack>
      </DialogTitle>
      <DialogContent dividers>
        <Box component="p">
          A company you have access to requires you to reset your password.  Please do so now.
        </Box>
        <Box component="p">
          If you would like to log in as a different account you'll need to <Button component="a" onClick={logOut} variant="text">log out</Button>
        </Box>
        <InputLabel disabled={isLoading} sx={{
        marginTop: "15px"
      }}>Current password</InputLabel>
        <Input type="password" disabled={isLoading} fullWidth={true} required={true} value={currentPassword} onChange={e => setCurrentPassword(e.target.value)} placeholder="Enter your current password" />
        <InputLabel disabled={isLoading} sx={{
        marginTop: "15px"
      }}>New password</InputLabel>
        <Input type="password" disabled={isLoading} fullWidth={true} required={true} value={newPassword} onChange={e => setNewPassword(e.target.value)} placeholder="Enter your current password" />
        <InputLabel disabled={isLoading} sx={{
        marginTop: "15px"
      }}>Confirm new password</InputLabel>
        <Input type="password" disabled={isLoading} fullWidth={true} required={true} value={newPasswordConfirmation} onChange={e => setNewPasswordConfirmation(e.target.value)} placeholder="Enter your current password" />
      </DialogContent>
      <DialogActions sx={{
      py: 1
    }}>
        <Button onClick={submitPassword} disabled={isLoading} variant="text">
          Change Password
        </Button>
      </DialogActions>
    </Dialog>;
}