import React, { Component } from 'react';
import { object, func } from 'prop-types';
export class OnEvent extends Component {
  static propTypes = {
    node: object,
    update: func
  };
  constructor(props) {
    super(props);
    this.state = {
      events: []
    };
  }
  componentDidMount() {
    if (this.props.node.onEventList) {
      this.setState({
        events: this.props.node.onEventList
      });
    } else if (this.props.node.getEventList) {
      this.props.node.getEventList(this.props.node).then(events => this.setState({
        events
      }));
    }
  }
  render() {
    const {
      events
    } = this.state;
    const {
      name,
      parameters
    } = this.props.node;
    return <div onClick={e => e.stopPropagation()}>
        <h3>{name || parameters.name}</h3>
        <div className="form-group">
          {events.map(event => <label key={event.id} className="block">
              <input type="radio" name="events" value={event.id} onChange={() => this.props.update(event)} />
              {event.name}
            </label>)}
        </div>
      </div>;
  }
}