import React, { Component } from 'react';
import styles from './locations.styles.scss';
import { Scoped } from 'kremling';
import moment from 'moment';
import { PageHeader } from '../../components/page-header/page-header';
import { Icon } from '../../components/icon/icon.component';
import { Button } from '../../components/button/button.component';
import { Loader } from '../../components/loader/loader.component';
import { Pagination } from '../../components/pagination/pagination.component';
import { getLocations, editLocation } from '../../shared/common.api';
import { userState } from '../../shared/user-state';
import { SortHeader } from '../../components/table/sort-header.component';
import { Modal } from '../../components/modal/modal.component';
import { Dropdown } from 'components/dropdown/dropdown.component';
import { EditModalLocation } from './edit-modal-location.component';
const ALL_FIELDS = [{
  id: 'name',
  name: 'Name',
  type: 'text',
  size: 6
}, {
  id: 'address1',
  name: 'Address',
  type: 'text',
  size: 6
}, {
  id: 'address2',
  name: 'Address Line 2',
  type: 'text',
  size: 6
}, {
  id: 'city',
  name: 'City',
  type: 'text',
  size: 6
}, {
  id: 'state',
  name: 'State',
  type: 'text',
  size: 6
}, {
  id: 'zip_code',
  name: 'Zip Code',
  type: 'text',
  size: 6
}, {
  id: 'phone',
  name: 'Phone',
  type: 'text',
  size: 6
}, {
  id: 'country',
  name: 'Country',
  type: 'text',
  size: 6
}, {
  id: 'timezone',
  name: 'Timezone',
  type: 'text',
  size: 6
}, {
  id: 'lat',
  name: 'Latitude',
  type: 'text',
  size: 6
}, {
  id: 'lon',
  name: 'Longitude',
  type: 'text',
  size: 6
}, {
  id: 'is_active',
  name: 'Is Active?',
  type: 'boolean',
  size: 6
}, {
  id: 'store_hours',
  name: 'Store Hours',
  type: 'text',
  size: 6
}, {
  id: 'store_image',
  name: 'Store Image',
  type: 'text',
  size: 6
}, {
  id: 'store_manager',
  name: 'Store Manager',
  type: 'text',
  size: 6
}, {
  id: 'facebook_review_url',
  name: 'Facebook Review Url',
  type: 'text',
  size: 6
}, {
  id: 'google_review_url',
  name: 'Google Review Url',
  type: 'text',
  size: 6
}, {
  id: 'yelp_review_url',
  name: 'Yelp Review Url',
  type: 'text',
  size: 6
}, {
  id: 'closed_when',
  name: 'Date Closed',
  type: 'date',
  size: 6
}, {
  id: 'created_when',
  name: 'Date Created',
  type: 'date',
  size: 6
}, {
  id: 'deleted_when',
  name: 'Date Deleted',
  type: 'date',
  size: 6
}, {
  id: 'updated_when',
  name: 'Date Updated',
  type: 'date',
  size: 6
}, {
  id: 'entity_ref',
  name: 'Entity Ref',
  type: 'text',
  size: 6
}, {
  id: 'entity_source',
  name: 'Entity Source',
  type: 'text',
  size: 6
}, {
  id: 'id',
  name: 'Cinch ID',
  type: 'text',
  size: 6
}, {
  id: 'market',
  name: 'Market ID',
  type: 'text',
  size: 6
}, {
  id: 'sms_phone_number',
  name: 'SMS Phone Number ID',
  type: 'text',
  size: 6
}, {
  id: 'company',
  name: 'Company ID',
  type: 'text',
  size: 6
}];
export class LocationsList extends Component {
  state = {
    locations: [],
    is_loading: false,
    error: null,
    openModal: false,
    search: '',
    paginationParams: {},
    ordering: null,
    tempFields: null,
    fields: [{
      id: 'name',
      name: 'Name',
      type: 'text'
    }, {
      id: 'entity_ref',
      name: 'Entity Ref',
      type: 'text'
    }, {
      id: 'address1',
      name: 'Adress',
      type: 'text'
    }, {
      id: 'phone',
      name: 'Phone',
      type: 'text'
    }, {
      id: 'is_active',
      name: 'Active',
      type: 'boolean'
    }],
    showEditModal: false,
    editErrors: {},
    editData: {},
    allFields: ALL_FIELDS,
    toAdd: null,
    toRemove: null
  };
  componentDidMount() {
    const locationFieldsStr = localStorage.getItem('locationFields');
    if (locationFieldsStr) {
      this.setState({
        fields: JSON.parse(locationFieldsStr)
      });
    }
    this.setState({
      currentPage: 1
    });
    this.showLocations();
  }
  searchLocation = search => {
    this.setState({
      search
    }, () => {
      this.showLocations();
    });
  };
  showLocations = () => {
    const {
      search,
      ordering
    } = this.state;
    this.setState({
      isLoading: true
    });
    getLocations({
      ...this.state.paginationParams,
      search: search,
      company: userState.getAsCompanyId(),
      ordering
    }).then(({
      data
    }) => {
      if (data) {
        this.setState({
          locations: data.results,
          isLoading: false,
          paginationData: data
        });
      }
    });
  };
  pageOnChange = params => {
    this.setState({
      paginationParams: params
    }, () => {
      this.showLocations();
    });
  };
  updateOrdering = ordering => {
    this.setState({
      ordering,
      currentPage: 1
    }, () => this.showLocations());
  };
  editFields = () => {
    this.setState({
      tempFields: JSON.parse(JSON.stringify(this.state.fields)),
      toAdd: null,
      toRemove: null
    });
  };
  addTemp = () => {
    const {
      toAdd,
      tempFields,
      allFields
    } = this.state;
    if (toAdd && tempFields.length < 6 && !tempFields.find(i => i.id === toAdd)) {
      tempFields.push(allFields.find(i => i.id === toAdd));
      this.setState({
        tempFields
      });
    }
  };
  removeTemp = () => {
    const {
      toRemove,
      tempFields
    } = this.state;
    if (toRemove) {
      const index = tempFields.findIndex(i => i.id === toRemove);
      if (index > -1) {
        tempFields.splice(index, 1);
        this.setState({
          tempFields
        });
      }
    }
  };
  saveFields = () => {
    const {
      tempFields
    } = this.state;
    localStorage.setItem('locationFields', JSON.stringify(tempFields));
    this.setState({
      fields: tempFields,
      tempFields: null
    });
  };
  submitEditLocation = () => {
    const {
      editData,
      paginationParams
    } = this.state;
    this.setState({
      editErrors: {}
    });
    return editLocation(editData.id, {
      ...editData
    }).then(data => {
      this.setState({
        editErrors: {},
        editData: {},
        paginationParams
      });
      this.showLocations();
    }).catch(e => {
      this.state({
        editErrors: e.response.data
      });
      throw e;
    });
  };
  render() {
    const {
      locations,
      error,
      isLoading,
      paginationData,
      tempFields,
      allFields,
      fields,
      ordering,
      showEditModal,
      editErrors,
      editData
    } = this.state;
    return <Scoped css={styles}>
        <div className="wrapper">
          <PageHeader name="Locations" onSearch={this.searchLocation} preactions={<Button onClick={() => this.editFields()}>Fields</Button>} />
          <div className="wrapper-scroll locations-list">
            {isLoading && <Loader overlay />}
            {error && <p>{error.message}</p>}

            <table className="table-list">
              {!isLoading && !!locations.length && <thead>
                  <tr>
                    {fields.map(field => <SortHeader key={`header${field.id}`} name={field.id} ordering={ordering} update={ordering => this.updateOrdering(ordering)}>
                        {field.name}
                      </SortHeader>)}
                  </tr>
                </thead>}
              <tbody>
                {locations.length && !isLoading && locations.map(location => <tr key={location.id} onClick={() => this.setState({
                isOpen: true
              })}>
                      {fields.map(field => <td key={`field${field.id}`}>
                          {field.type === 'date' ? !!location[field.id] && moment(location[field.id]).isValid() && moment(location[field.id]).format('MMM D, YYYY') : field.type === 'boolean' ? location[field.id] === true && <Icon name="fa-regular-check" /> : location[field.id]}
                        </td>)}
                      <td>
                        {userState.hasPermission('customer.change_customerrefgroup') && <Dropdown horizontal="west" trigger={() => <Button actionType="flat" icon="fa-regular-ellipsis-h" />} content={({
                    close
                  }) => <ul className="select-list">
                                <li onClick={() => {
                      this.setState({
                        editData: location,
                        showEditModal: true,
                        editErrors: {}
                      });
                      close();
                    }}>
                                  <a>Edit</a>
                                </li>
                              </ul>} />}
                      </td>
                    </tr>) || <tr>
                    {!isLoading && <td colSpan={6}>
                        <strong>No Results</strong>
                      </td>}
                  </tr>}
              </tbody>
            </table>
            <Pagination data={paginationData} onChange={this.pageOnChange} />
          </div>
        </div>
        <Modal open={!!tempFields} onClose={() => this.setState({
        tempFields: null
      })} allowBackdropClick size="lg">
          <div className="modal__title">Select Field</div>
          <div className="modal__body">
            <p>Choose up to 6 fields to view.</p>

            <div className="d-flex" style={{
            alignItems: 'center'
          }}>
              <div style={{
              flex: '1 1 50%'
            }}>
                <select style={{
                height: '250px'
              }} size="10" className="form-control" onChange={event => this.setState({
                toAdd: event.target.value
              })}>
                  {allFields.filter(i => tempFields && !tempFields.find(j => j.id === i.id)).map(field => <option key={`option${field.id}`} value={field.id}>
                        {field.name}
                      </option>)}
                </select>
              </div>
              <div style={{
              flex: '0 0 30px'
            }}>
                <Button actionType="flat" icon="fa-solid-caret-left" onClick={() => this.removeTemp()} />
                <Button actionType="flat" icon="fa-solid-caret-right" onClick={() => this.addTemp()} />
              </div>
              <div style={{
              flex: '1 1 50%'
            }}>
                <select style={{
                height: '250px'
              }} size="10" className="form-control" onChange={event => this.setState({
                toRemove: event.target.value
              })}>
                  {tempFields && tempFields.map(field => <option key={`option${field.id}`} value={field.id}>
                        {field.name}
                      </option>)}
                </select>
              </div>
            </div>
          </div>
          <div className="modal__actions">
            <Button onClick={() => this.setState({
            tempFields: null
          })}>
              Cancel
            </Button>
            <Button onClick={() => {
            this.saveFields();
          }} actionType="primary">
              Save
            </Button>
          </div>
        </Modal>
        <EditModalLocation showEditModal={showEditModal} setShowEditModal={() => this.setState({
        showEditModal: !showEditModal
      })} editLocation={this.submitEditLocation} editErrors={editErrors} allFields={allFields} setEditData={editData => this.setState({
        editData: editData
      })} editData={editData} />
      </Scoped>;
  }
}