import React, { Component } from 'react';
import { Scoped, k } from 'kremling';
import moment from 'moment';
import { Modal } from '../../components/modal/modal.component';
import { PageHeader } from '../../components/page-header/page-header';
import { Button, TagModal } from 'components';
import { getJourneys, getJourneyTemplates, createJourney, deleteJourney, createJourneyFromTemplate, duplicateJourney, getJourneysTags, patchJourney, createNewFolder, getCompanyFolders, updateFolder, deleteFolder } from '../../shared/common.api';
import { Loader } from '../../components/loader/loader.component';
import { userState } from '../../shared/user-state';
import { Dropdown } from '../../components/dropdown/dropdown.component';
import { Pagination } from '../../components/pagination/pagination.component';
import { Status } from '../../components/status/status.component';
import { Tags } from '../../components/tags/tags.component';
import { SortHeader } from '../../components/table/sort-header.component';
import { DynamicTypeAhead } from 'components/dynamic-type-ahead/dynamic-type-ahead.component';
import utils from '../../shared/utils';
import { NavLink } from 'react-router-dom';
import { DateTime } from 'luxon';
import { Icon } from '../../components/icon/icon.component';
import Tooltip from 'rc-tooltip';
const renderIcon = item => {
  if (item == 'folder') return <Icon name="fa-regular-folder" className="mr-3" />;
  return <Icon name="fa-solid-code-branch" className="mr-3" />;
};
const paramsFilters = ['filters', 'search', 'ordering', 'archiveMode', 'folder'];
export class CustomerJourneysList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      folderData: {},
      deleteModalId: null,
      journeyId: null,
      showCreateModal: false,
      selectedTemplate: '',
      selectedJourney: null,
      search: '',
      filters: null,
      paginationParams: {},
      isLoadingJourneys: true,
      isCreatingJourney: false,
      ordering: '-updated_when',
      journeysTags: [],
      filterDrawerOpen: false,
      archiveMode: false,
      archiveModalId: null,
      dragItem: null,
      newFolderCreate: null,
      newFolderName: "",
      currentFolderView: [{
        name: 'Home',
        id: null
      }],
      renameFolderModal: null,
      renameFolderName: '',
      deleteFolderModal: null,
      addressBarWidth: 500,
      showAddressBar: true
    };
    this.updateHistory = utils.updateHistory.bind(this);
    this.loadStateFromParams = utils.loadStateFromParams.bind(this);
  }
  getJourneyTags = () => {
    getJourneysTags(userState.getAsCompanyId()).then(({
      data
    }) => {
      const journeysTags = data.results.map((tag, index) => {
        return {
          tag: tag,
          id: index
        };
      });
      this.setState({
        journeysTags
      });
    });
  };
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions();
    this.loadStateFromParams(this.state, paramsFilters, this.getJourneys);
    this.getJourneyTags();
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }
  onSearch = search => {
    this.setState({
      search
    }, () => {
      this.updateHistory(this.state, paramsFilters);
      this.getJourneys();
    });
  };
  onFilter = filters => {
    this.setState({
      filters
    }, () => {
      this.updateHistory(this.state, paramsFilters);
      this.getJourneys();
    });
  };
  getJourneys = () => {
    this.setState({
      isLoadingJourneys: true
    });
    const {
      search,
      ordering,
      filters,
      archiveMode
    } = this.state;
    parent = this.state.currentFolderView.at(-1)?.id ? this.state.currentFolderView.at(-1)?.id : 'home';
    const promises = [getCompanyFolders({
      model: 'journey',
      company: userState.getAsCompanyId(),
      parent: parent
    }), getJourneys({
      ...this.state.paginationParams,
      ...filters,
      archived: archiveMode,
      folders: search !== '' ? null : parent,
      search: search,
      company: userState.getAsCompanyId(),
      ordering
    })];
    Promise.all(promises).then(([folderData, data]) => {
      this.setState({
        folderData: folderData.data,
        data: data.data,
        isLoadingJourneys: false
      });
    });
  };
  onPageChange = params => {
    this.setState({
      paginationParams: params
    }, () => {
      this.updateHistory(this.state, paramsFilters);
      this.getJourneys();
    });
  };
  goToJourney = id => {
    if (userState.hasPermission('journey.view_journey')) {
      this.props.history.push(`/customer-journeys/${id}`);
    }
  };

  /**
   * DELETE JOURNEY
   */
  closeDeleteModal = () => {
    this.setState({
      deleteModalId: null
    });
  };
  openDeleteModal = id => {
    this.setState({
      deleteModalId: id
    });
  };
  deleteJourney = () => {
    const id = this.state.deleteModalId;
    this.closeDeleteModal();
    deleteJourney(id).then(() => {
      this.getJourneys();
    });
  };

  /**
   * ARCHIVE JOURNEY
   */
  closeArchiveModal = () => {
    this.setState({
      archiveModalId: null
    });
  };
  openArchiveModal = id => {
    this.setState({
      archiveModalId: id
    });
  };
  archiveJourney = () => {
    const id = this.state.archiveModalId;
    this.closeArchiveModal();
    patchJourney(id, {
      archived_when: DateTime.local()
    }).then(() => this.getJourneys());
  };
  restoreJourney = id => {
    ;
    patchJourney(id, {
      archived_when: null
    }).then(() => this.getJourneys());
  };

  /**
   * DUPLICATE JOURNEY
   */
  duplicateJourney = id => {
    duplicateJourney(id).then(({
      data
    }) => {
      this.goToJourney(data.id);
    });
  };

  /**
   * CREATE JOURNEY
   */
  openCreateModal = () => {
    this.setState({
      showCreateModal: true
    });
  };
  closeCreateModal = () => {
    if (!this.state.isCreatingJourney) {
      this.setState({
        showCreateModal: false
      });
    }
  };
  createJourney = () => {
    this.setState({
      isCreatingJourney: true
    });
    if (this.state.selectedTemplate === '') {
      createJourney('Untitled', userState.getAsCompanyId()).then(({
        data
      }) => this.goToJourney(data.id));
    } else {
      createJourneyFromTemplate(this.state.selectedTemplate, userState.getAsCompanyId()).then(({
        data
      }) => this.goToJourney(data.id));
    }
  };
  updateOrdering = ordering => {
    this.setState({
      ordering
    }, () => {
      this.updateHistory(this.state, paramsFilters);
      this.getJourneys();
    });
  };
  addTagFromJourneyRow = (e, tag) => {
    e.stopPropagation();
    this.setState({
      filterDrawerOpen: true
    });
    let filters = this.state.filters ? this.state.filters : {};
    if (filters && filters.tags && filters.tags.length !== 0) {
      if (!filters.tags.includes(tag)) {
        filters.tags.push(tag);
      }
    } else {
      filters.tags = [];
      filters.tags.push(tag);
    }
    this.onFilter(filters);
  };
  handleArchiveMode = () => {
    const {
      archiveMode
    } = this.state;
    this.setState({
      archiveMode: !archiveMode,
      search: '',
      paginationParams: {}
    }, () => {
      this.updateHistory(this.state, paramsFilters);
      this.getJourneys();
    });
  };

  /**
   * FOLDERS
   */
  makeNewFolder = newFolderName => {
    this.setState({
      isLoadingJourneys: true
    });
    const parent = this.state.currentFolderView.at(-1)?.id;
    createNewFolder({
      name: newFolderName,
      model: 'journey',
      parent: parent,
      company: userState.getAsCompanyId()
    }).then(() => {
      getCompanyFolders({
        model: 'journey',
        company: userState.getAsCompanyId(),
        parent: this.state.currentFolderView.at(-1)?.id ? this.state.currentFolderView.at(-1)?.id : 'home'
      }).then(results => {
        this.setState({
          folderData: results.data,
          isLoadingJourneys: false
        });
      });
    });
  };
  renameFolder = () => {
    this.setState({
      isLoadingJourneys: true
    });
    updateFolder(this.state.renameFolderModal, {
      name: this.state.renameFolderName
    }).then(() => {
      this.getJourneys();
    });
  };
  deleteFolders = () => {
    this.setState({
      isLoadingJourneys: true
    });
    const id = this.state.deleteFolderModal;
    this.setState({
      deleteFolderModal: null
    });
    deleteFolder(id).then(() => {
      this.getJourneys();
    });
  };
  handleEnterDirectory = folder => {
    this.setState({
      isLoadingJourneys: true,
      currentFolderView: [...this.state.currentFolderView, folder]
    }, () => {
      this.getJourneys();
    });
  };
  handleEnterTreeDirectory = (f, index) => {
    if (f.id == this.state.currentFolderView.at(-1)?.id) {
      return;
    }
    this.setState({
      isLoadingJourneys: true
    });
    const popCount = -(this.state.currentFolderView.length - index - 1);
    this.setState({
      currentFolderView: this.state.currentFolderView.slice(0, popCount)
    }, () => {
      this.getJourneys();
    });
  };
  handleDragStart = id => {
    this.setState({
      dragItem: id
    });
  };
  handleDragEnter = e => {
    e.target.parentNode.style.backgroundColor = "grey";
  };
  handleDragLeave = e => {
    e.target.parentNode.style.backgroundColor = "white";
  };
  handleDrop = (e, folder) => {
    patchJourney(this.state.dragItem, {
      folder: folder.id
    }).then(() => this.getJourneys());
    e.target.parentNode.style.backgroundColor = "white";
  };
  moveUpDirectory = () => {
    this.setState({
      currentFolderView: this.state.currentFolderView.slice(0, -1)
    }, () => {
      this.getJourneys();
    });
  };
  updateDimensions = () => {
    this.setState({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight
    }, () => {
      if (this.state.windowWidth > 1250) {
        this.setState({
          addressBarWidth: 500
        });
        this.setState({
          showAddressBar: true
        });
      } else if (this.state.windowWidth < 1250 && this.state.windowWidth > 860) {
        this.setState({
          addressBarWidth: this.state.windowWidth - 750
        });
        this.setState({
          showAddressBar: true
        });
      } else {
        this.setState({
          showAddressBar: false
        });
      }
    });
  };
  render() {
    const {
      results = []
    } = this.state.data;
    const {
      isCreatingJourney,
      isLoadingJourneys,
      selectedTemplate,
      ordering,
      search,
      filters,
      selectedJourney,
      journeysTags,
      filterDrawerOpen,
      archiveMode,
      currentFolderView,
      folderData,
      addressBarWidth,
      showAddressBar
    } = this.state;
    return <Scoped css={css}>
        <div className="wrapper">
          <PageHeader name={archiveMode ? 'Customer Journeys - Archive' : 'Customer Journeys'} searchVal={search} onSearch={this.onSearch} filterDrawerOpen={filterDrawerOpen} hasFolders={true} preactions={<>
                <Button onClick={() => this.handleArchiveMode()} actionType={archiveMode ? 'primary' : 'grey'}>
                  {archiveMode ? 'Exit Archive' : 'View Archive'}
                </Button>
              </>} actions={<>
                {userState.hasPermission('journey.add_journey') && <Button icon="fa-regular-plus" actionType="primary" onClick={this.openCreateModal} />}
              </>} leftactions={<>
                {showAddressBar && <div className='form-control' style={{
            width: addressBarWidth + 'px',
            overflow: 'hidden'
          }}>
                    <table>
                      <tbody style={{
                float: 'right',
                marginTop: '3px',
                cursor: 'pointer',
                whiteSpace: 'nowrap'
              }}>
                        <tr>
                          {currentFolderView.map((f, index) => <td key={f.id} onClick={() => {
                    this.handleEnterTreeDirectory(f, index);
                  }}>
                            {f.name == 'Home' ? <Icon name="fa-regular-home-alt" className="mr-3"></Icon> : <>{f.name}</>}
                               <Icon name="fa-regular-angle-right"></Icon>
                            </td>)}
                        </tr>
                      </tbody>
                    </table>
                  </div>}

                {this.state.currentFolderView.length > 1 && <Tooltip placement="bottom" align={{
            points: ['tl', 'tr'],
            offset: [0, 35],
            targetOffset: ['100%', '0%'],
            overflow: {
              adjustX: true,
              adjustY: true
            }
          }} overlay="Move up a directory">
                    <span>
                      <Button icon="fa-regular-arrow-up" actionType="grey" onClick={() => {
                this.moveUpDirectory();
              }} /></span>
                    </Tooltip>}

                {userState.hasPermission('journey.add_journey') && <Tooltip placement="bottom" align={{
            points: ['tl', 'tr'],
            offset: [0, 35],
            targetOffset: ['100%', '0%'],
            overflow: {
              adjustX: true,
              adjustY: true
            }
          }} overlay="Create new folder">
                    <Button icon="fa-regular-folder-plus" actionType="grey" onClick={() => {
              this.setState({
                newFolderCreate: true
              });
            }} />
                    </Tooltip>}
              </>} tags={journeysTags} onFilter={filters => this.onFilter(filters)} filterVals={filters} filters={[{
          key: 'name',
          label: 'Name',
          type: 'text'
        }, {
          key: 'tags',
          label: 'Tags',
          type: 'typeahead',
          options: journeysTags
        }, {
          key: 'status',
          label: 'Status',
          type: 'options',
          options: ['draft', 'paused', 'published', 'closed', 'stopped']
        }, {
          key: 'updated',
          label: 'Last Updated',
          type: 'range'
        }, {
          key: 'created',
          label: 'Created',
          type: 'range'
        }]} />
          <div className="wrapper-scroll journey-list">
            {isLoadingJourneys && <Loader overlay />}
            {!isLoadingJourneys && <table className="table-list">
                <thead>
                  <tr>
                    <SortHeader name="name" ordering={ordering} update={ordering => this.updateOrdering(ordering)}>
                      Name
                    </SortHeader>
                    <SortHeader name="tags" ordering={ordering} update={ordering => this.updateOrdering(ordering)}>
                      Tags
                    </SortHeader>
                    <th>Status</th>
                    <SortHeader style={{
                  width: '11rem'
                }} name="updated_when" ordering={ordering} update={ordering => this.updateOrdering(ordering)}>
                      Last Updated
                    </SortHeader>
                    <SortHeader style={{
                  width: '11rem'
                }} name="created_when" ordering={ordering} update={ordering => this.updateOrdering(ordering)}>
                      Created On
                    </SortHeader>
                    <SortHeader style={{
                  width: '11rem'
                }} name="last_triggered_when" ordering={ordering} update={ordering => this.updateOrdering(ordering)}>
                      Last Triggered On
                    </SortHeader>
                    <th style={{
                  width: '3rem'
                }} />
                  </tr>
                </thead>
                <tbody>
                  {search === '' && folderData.results.map(folder => <tr key={folder.id} onClick={() => {
                this.handleEnterDirectory(folder);
              }} onDragEnter={e => this.handleDragEnter(e)} onDragLeave={e => this.handleDragLeave(e)} onDrop={e => this.handleDrop(e, folder)} onDragOver={e => e.preventDefault()}>
                        <td style={{
                  paddingLeft: '15px'
                }}>
                          {renderIcon('folder')}
                          {folder.name}
                        </td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>
                            {userState.hasPermission('journey.add_journey', 'journey.delete_journey', 'journey.view_journey', 'journey.change_journey') && <Dropdown horizontal="west" trigger={() => <Button actionType="flat" icon="fa-regular-ellipsis-h" />} content={prop => <ul className="select-list">
                                    {userState.hasPermission('journey.change_journey') && <>
                                        <li>
                                          <a onClick={() => {
                          this.setState({
                            renameFolderName: folder.name,
                            renameFolderModal: folder.id
                          });
                          prop.close();
                        }}>
                                            Rename
                                          </a>
                                        </li>
                                      </>}
                                    {userState.hasPermission('journey.delete_journey') && <li>
                                        <a onClick={() => {
                        this.setState({
                          deleteFolderModal: folder.id
                        });
                        prop.close();
                      }}>
                                          Delete
                                        </a>
                                      </li>}
                                  </ul>} />}
                          </td>
                        </tr>)}

                  {results.map(journey => <tr key={journey.id} onClick={() => this.goToJourney(journey.id)} draggable onDragStart={e => this.handleDragStart(journey.id)} onDragOver={e => e.preventDefault()}>
                      <td style={{
                  paddingLeft: '15px'
                }}>
                        {renderIcon('journey')}
                        {search !== '' && journey.path}
                        {journey.name}
                      </td>
                      <td>
                        <Tags tags={journey.tags} onDelete={null} onClick={(e, tag) => {
                    this.addTagFromJourneyRow(e, tag);
                  }} />
                      </td>
                      <td>
                        <Status status={journey.archive_when ? 'archived' : journey.status} />
                      </td>
                      <td>
                        {moment(journey.updated_when).format('MMM D, YYYY')}
                      </td>
                      <td>
                        {moment(journey.created_when).format('MMM D, YYYY')}
                      </td>
                      <td>
                        {!!journey.last_triggered_when && moment(journey.last_triggered_when).format('MMM D, YYYY')}
                      </td>
                      <td>
                        {userState.hasPermission('journey.add_journey', 'journey.delete_journey', 'journey.view_journey', 'journey.change_journey') && <Dropdown horizontal="west" trigger={() => <Button actionType="flat" icon="fa-regular-ellipsis-h" />} content={() => <ul className="select-list">
                                {userState.hasPermission('journey.view_journey') && <li>
                                    <NavLink to={`/customer-journeys/details/${journey.id}`}>
                                      View Details
                                    </NavLink>
                                  </li>}
                                {userState.hasPermission('journey.change_journey') && <>
                                    <li>
                                      <a onClick={() => this.setState({
                          selectedJourney: journey
                        })}>
                                        Edit Tags
                                      </a>
                                    </li>

                                    {this.state.currentFolderView.length > 1 && <li>
                                      <a onClick={() => {
                          this.setState({
                            isLoadingJourneys: true
                          });
                          patchJourney(journey.id, {
                            folder: null
                          }).then(() => {
                            this.getJourneys();
                          });
                        }}>
                                        Move to Root Folder
                                      </a>
                                    </li>}

                                    {!archiveMode ? <li>
                                        <a onClick={() => this.openArchiveModal(journey.id)}>
                                          Archive
                                        </a>
                                      </li> : <li>
                                        <a onClick={() => this.restoreJourney(journey.id)}>
                                          Restore
                                        </a>
                                      </li>}
                                  </>}
                                {userState.hasPermission('journey.add_journey') && <li>
                                    <a onClick={() => this.duplicateJourney(journey.id)}>
                                      Duplicate
                                    </a>
                                  </li>}
                                {userState.hasPermission('journey.delete_journey') && <li>
                                    <a onClick={() => this.openDeleteModal(journey.id)}>
                                      Delete
                                    </a>
                                  </li>}
                              </ul>} />}
                      </td>
                    </tr>)}
                </tbody>
              </table>}
            <Pagination data={this.state.data} onChange={this.onPageChange} />
          </div>
          <Modal title="Delete Journey" onClose={this.closeDeleteModal} open={!!this.state.deleteModalId} allowBackdropClick>
            <div className="modal__body">
              <p>
                Are you sure you want to delete this journey? This cannot be
                undone.
              </p>
            </div>
            <div className="modal__actions">
              <Button actionType="flat" onClick={this.closeDeleteModal}>
                Cancel
              </Button>
              <Button actionType="primary" onClick={() => this.deleteJourney()}>
                Confirm
              </Button>
            </div>
          </Modal>

          <Modal title="Archive Journey" onClose={this.closeArchiveModal} open={!!this.state.archiveModalId} allowBackdropClick>
            <div className="modal__body">
              <p>
                Are you sure you want to archive this journey? All revisions
                will be stopped.
              </p>
            </div>
            <div className="modal__actions">
              <Button actionType="flat" onClick={this.closeArchiveModal}>
                Cancel
              </Button>
              <Button actionType="primary" onClick={() => this.archiveJourney()}>
                Confirm
              </Button>
            </div>
          </Modal>

          <Modal title="Create Journey" onClose={this.closeCreateModal} open={!!this.state.showCreateModal} allowBackdropClick>
            <div className="modal__body">
              <p>
                You can either create a blank new journey or create a journey
                from a template.
              </p>
              <DynamicTypeAhead placeholder="Blank Journey" disabled={isCreatingJourney} value={selectedTemplate} onChange={selectedTemplate => {
              if (selectedTemplate) {
                this.setState({
                  selectedTemplate: selectedTemplate.id
                });
              } else {
                this.setState({
                  selectedTemplate: ''
                });
              }
            }} getItems={getJourneyTemplates} getItemsFilters={{
              company: userState.getAsCompanyId(),
              ordering: 'revision__journey__name'
            }} displayProperty="journey_name" keyProperty="id" />
            </div>
            <div className="modal__actions">
              {isCreatingJourney ? <Loader size="sm" /> : <>
                  <Button actionType="flat" onClick={this.closeCreateModal}>
                    Cancel
                  </Button>
                  <Button actionType="primary" onClick={() => this.createJourney()}>
                    Confirm
                  </Button>
                </>}
            </div>
          </Modal>
          <Modal title="Rename Folder" onClose={() => this.setState({
          renameFolderModal: null
        })} open={!!this.state.renameFolderModal} allowBackdropClick>
            <div className="modal__body">
              <p>
              <input type="text" className="form-control" value={this.state.renameFolderName} onChange={e => {
                this.setState({
                  renameFolderName: e.target.value
                });
              }} />
              </p>
            </div>
            <div className="modal__actions">
              <Button actionType="flat" onClick={() => this.setState({
              renameFolderModal: null
            })}>
                Cancel
              </Button>
              <Button actionType="primary" onClick={() => {
              this.renameFolder();
              this.setState({
                renameFolderModal: null
              });
            }}>
                Rename Folder
              </Button>
            </div>
          </Modal>

          <Modal title="Delete Folder" onClose={() => this.setState({
          deleteFolderModal: null
        })} open={!!this.state.deleteFolderModal} allowBackdropClick>
            <div className="modal__body">
              <p>
               Are you sure you want to delete this folder?
               </p>
               <p>
               If this folder has any journeys in it, they will not be deleted, but will be moved back to the home folder.
              </p>
            </div>
            <div className="modal__actions">
              <Button actionType="flat" onClick={() => this.setState({
              deleteFolderModal: null
            })}>
                Cancel
              </Button>
              <Button actionType="danger" onClick={() => this.deleteFolders()}>
                Delete
              </Button>
            </div>
          </Modal>
          <Modal title="Create New Folder" onClose={() => this.setState({
          newFolderCreate: null
        })} open={!!this.state.newFolderCreate} allowBackdropClick size='sm'>
            <div className="modal__body">
               <input type="text" className="form-control" value={this.state.newFolderName} placeholder="Folder Name" onChange={e => {
              this.setState({
                newFolderName: e.target.value
              });
            }} onKeyPress={e => {
              if (e.key === 'Enter') {
                this.makeNewFolder(this.state.newFolderName);
                this.setState({
                  newFolderCreate: false
                });
              }
            }} autoFocus />
            </div>
            <div className="modal__actions">
              <Button actionType="flat" onClick={() => this.setState({
              newFolderCreate: null
            })}>
                Cancel
              </Button>
              <Button actionType="primary" onClick={e => {
              this.makeNewFolder(this.state.newFolderName);
              this.setState({
                newFolderCreate: false
              });
            }}>
                Create
              </Button>
            </div>
          </Modal>
          <TagModal onClose={() => this.setState({
          selectedJourney: null
        })} onSuccess={() => {
          this.getJourneyTags();
          this.getJourneys();
        }} objectTarget={selectedJourney} getViewTags={getJourneysTags} patchView={patchJourney} viewName={"Journeys"}></TagModal>
        </div>
      </Scoped>;
  }
}
const css = {
  styles: `[kremling="i13"] body,body[kremling="i13"] {
  --color-primary: #487aae;
  --color-highlight: #e7efff;
  --color-accent: #354052;
  --color-success: #73b56e;
  --color-warning: #f4b707;
  --color-danger: #df5651;
  --color-grey-10: #f9f9f9;
  --color-grey-25: #f3f3f3;
  --color-grey-50: #e9e9e9;
  --color-grey-75: #e3e3e3;
  --color-grey-100: #d3d3d3;
  --color-grey-200: #c3c3c3;
  --color-grey-300: #b3b3b3;
  --color-grey-400: #a3a3a3;
  --color-grey-500: #808080;
  --color-grey-600: #707070;
  --color-grey-700: #606060;
  --color-grey-800: #505050;
  --color-grey-900: #404040;
  --base-font-family: Roboto, Helvetica, Arial, sans-serif;
  --base-font-size: 1.4rem;
  --base-font-color: #404040;
  --base-font-weight: 400;
  --base-line-height: 1.4;
  --box-shadow-1: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.12);
  --box-shadow-2: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-3: 0 10px 20px rgba(0, 0, 0, 0.17), 0 6px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-4: 0 14px 28px rgba(0, 0, 0, 0.13), 0 10px 10px rgba(0, 0, 0, 0.1);
  --box-shadow-5: 0 19px 38px rgba(0, 0, 0, 0.18), 0 15px 12px rgba(0, 0, 0, 0.1);
  --base-border-radius: 0.4rem;
}

[kremling="i13"] .journey-list,[kremling="i13"].journey-list {
  padding: 2rem;
}

[kremling="i13"] .journey-list table button,[kremling="i13"].journey-list table button {
  opacity: 0;
}
[kremling="i13"] .journey-list table tr:hover button,[kremling="i13"].journey-list table tr:hover button {
  opacity: 1;
}`,
  id: 'i13',
  namespace: 'kremling'
};