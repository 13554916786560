import * as Sentry from '@sentry/react';
import { userState } from './user-state';
import { api } from './api';
export function signIn(body) {
  return api.post('/users/login', body);
}
export function validateMFA(mfa) {
  return api.post('/users/validate-mfa', {
    mfa
  });
}
export function validatePhone(phone) {
  return api.post('/users/validate-phone', {
    phone
  });
}
export function validateCode(phone, code) {
  return api.post('/users/validate-code', {
    phone,
    code
  });
}
export function updatePassword(currentPassword, newPassword, newPasswordConfirmation) {
  return api.post('/users/update-password', {
    current_password: currentPassword,
    new_password: newPassword,
    new_password_confirmation: newPasswordConfirmation
  });
}
export function getMe() {
  return api.get(`/users/me`).then(response => {
    Sentry.setUser(response.data);
    return response;
  });
}
export function logout() {
  return api.post(`/users/logout`);
}
export function signInSSO(body) {
  return api.post('/integration/hubspot/sign-in', body);
}
export function getHubspotToken() {
  return api.get('/integration/hubspot/token');
}
export function getSSORedirect(provider) {
  return api.get(`/sso/${provider}/auth`);
}
export function getSSOToken(provider) {
  return api.get(`/sso/${provider}/redirect${window.location.search}`);
}

// Pass tokens for all requests
api.interceptors.request.use(config => {
  if (config.url !== '/users/login') {
    const {
      token
    } = userState.getToken();
    if (token) config.headers['Authorization'] = `Token ${token}`;
  }
  return config;
});

// Check if our token expired.
api.interceptors.response.use(res => {
  return res;
}, err => {
  // If we need MFA redirect there.
  if (err?.response?.status === 401 && err?.response?.data?.detail === 'Token needs to be authenticated with MFA') {
    if (window.location.pathname !== '/mfa') {
      history.push('/mfa');
    }
    return 'Token needs to be authenticated with MFA';
  }
  if (err.response && err.response.status === 401 && err.response.config.url === '/users/logout') {
    // We're logged out...
    return;
  }
  if (err.response && err.response.status === 401 && err.response.config.url !== '/users/me' && err.response.config.url !== '/users/logout') {
    // Check me
    return getMe().then(({
      status
    }) => {
      if (status !== 200) {
        userState.authRedirect();
        return err.response;
      }

      // We had a different problem
      return Promise.reject(err.response);
    }).catch(() => {
      userState.authRedirect();
      return err.response;
    });
  }
  return Promise.reject(err);
});