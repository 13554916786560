import React, { Component } from 'react';
import { oneOf, bool } from 'prop-types';
import EmojiPicker from 'emoji-picker-react';
import { Scoped } from 'kremling';
import { Button } from '../button/button.component';
import styles from './emoji.styles.scss';
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
export class Emoji extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emojiPicker: false,
      anchorEl: null
    };
  }
  static propTypes = {
    openDirection: oneOf(['right', 'up', 'down', 'left', 'farRight']),
    popout: bool
  };
  static defaultProps = {
    openDirection: 'up',
    popout: false
  };
  handleClose = () => {
    this.setState({
      emojiPicker: false
    });
  };
  render() {
    const {
      emojiPicker
    } = this.state;
    const {
      openDirection,
      popout
    } = this.props;
    return <Scoped css={styles}>
                <Button className="ml-3" actionType="flat" icon="fa-regular-smile" onClick={() => this.setState({
        emojiPicker: !emojiPicker
      })} />
                {!popout && emojiPicker && <div className={`pop-${openDirection}`}>
                        <EmojiPicker emojiStyle='google' previewConfig={{
          showPreview: false
        }} onEmojiClick={e => {
          this.props.onEmojiClick(e.emoji);
          this.setState({
            emojiPicker: !emojiPicker
          });
        }} />
                    </div>}
                 {popout && <Popper anchorEl={this.state.anchorEl} sx={{
        zIndex: 2000
      }} open={emojiPicker} onClose={this.handleClose}>
                    <ClickAwayListener onClickAway={this.handleClose}>
                        <Paper elevation={0}>
                            <Box sx={{
              p: 1
            }}>
                            {emojiPicker && <div className={`pop-${openDirection}`}>
                                    <EmojiPicker emojiStyle='google' previewConfig={{
                  showPreview: false
                }} onEmojiClick={e => {
                  this.props.onEmojiClick(e.emoji);
                  this.setState({
                    emojiPicker: !emojiPicker
                  });
                }} />
                                </div>}
                            </Box>
                        </Paper>
                    </ClickAwayListener>
                </Popper>}
            </Scoped>;
  }
}