import React, { Component } from 'react';
import { object, func, string } from 'prop-types';
import { Scoped, m, a } from 'kremling';
import { cloneDeep, get } from 'lodash';
import { DateTime } from 'luxon';
import numeral from 'numeral';
import styles from './rules.styles.scss';
import { Dropdown } from 'components/dropdown/dropdown.component';
import { Button } from 'components/button/button.component';
import { Icon } from 'components/icon/icon.component';
import { Calendar } from 'components/calendar/calendar.component';
import { DynamicTypeAhead } from 'components/dynamic-type-ahead/dynamic-type-ahead.component';
import { operatorText, operatorDate, operatorNumber, operatorBoolean, operatorList, getFieldsFromModel, fieldTypeName, operatorUUID } from './rules.utils';
import { Loader } from 'components/loader/loader.component';
import { getTime, timeSlots } from '../../pages/customer-journeys/node/parameters/trigger-recurring/trigger-recurring.utils';
import { getModels } from 'components/rules/rules.resource';
import { getLocations, getCoupons } from 'shared/common.api';
import { userState } from 'shared/user-state';
import { getLocation } from '../../shared/common.api';
const FIELD_TEXT = 'text';
const FIELD_NUMBER = 'number';
const FIELD_BOOLEAN = 'boolean';
const FIELD_DATE = 'date';
const FIELD_DATETIME = 'datetime';
const FIELD_LIST = 'list';
const FIELD_UUID = 'uuid';
const QUERY_TYPE_VALUE = 'value';
const QUERY_TYPE_RELATED_FIELD = 'related_field';
const QUERY_TYPE_RELATIVE_TIME = 'relative_time';
export class Rules extends Component {
  static propTypes = {
    onChange: func,
    query: object
  };
  state = {
    loading: true
  };
  componentDidMount() {
    getModels().then(models => {
      this.setState({
        models,
        loading: false
      });
    }, () => {});
  }
  updateCondition = (target, value) => {
    const query = cloneDeep(this.props.query);
    let builder = query;
    target.forEach(t => builder = builder[t]);
    if (value === 'or' && builder.and) {
      builder.or = cloneDeep(builder.and);
      delete builder.and;
    } else if (value === 'and' && builder.or) {
      builder.and = cloneDeep(builder.or);
      delete builder.or;
    }
    this.props.onChange(query);
  };
  addRule = target => {
    const query = cloneDeep(this.props.query);
    let builder = query;
    target.forEach(t => {
      builder = builder[t];
    });
    builder.push({});
    this.props.onChange(query);
  };
  removeRule = (target, index) => {
    const query = cloneDeep(this.props.query);
    let builder = query;
    target.forEach(t => {
      builder = builder[t];
    });
    builder.splice(index, 1);
    this.props.onChange(query);
  };
  addGroup = target => {
    const query = cloneDeep(this.props.query);
    let builder = query;
    target.forEach(t => {
      builder = builder[t];
    });
    builder.push({
      and: []
    });
    this.props.onChange(query);
  };
  removeGroup = (target, index) => {
    const query = cloneDeep(this.props.query);
    let builder = query;
    target.forEach(t => {
      builder = builder[t];
    });
    builder.splice(index, 1);
    this.props.onChange(query);
  };
  updateRow = (target, index, key, value) => {
    const query = cloneDeep(this.props.query);
    const valueFlow = ['value'];
    const relatedFieldFlow = ['related_model', 'related_field'];
    const relativeTimeFlow = ['value', 'time_unit', 'time_type'];
    let builder = query;
    target.forEach(t => {
      builder = builder[t];
    });
    if (key === 'query_type' && value !== builder[index].query_type) {
      valueFlow.forEach(f => builder[index][f] = '');
      relatedFieldFlow.forEach(f => builder[index][f] = '');
      relativeTimeFlow.forEach(f => builder[index][f] = '');
    }
    if (key === 'value' && !builder[index].query_type) {
      builder[index].query_type = QUERY_TYPE_VALUE;
    }
    builder[index][key] = value;
    let flowEnd = [];
    switch (builder[index].query_type) {
      case QUERY_TYPE_VALUE:
        flowEnd = valueFlow;
        break;
      case QUERY_TYPE_RELATED_FIELD:
        flowEnd = relatedFieldFlow;
        break;
      case QUERY_TYPE_RELATIVE_TIME:
        flowEnd = relativeTimeFlow;
        break;
    }
    const flow = ['model', 'field', 'operator', 'query_type', ...flowEnd];
    const mapIndex = flow.findIndex(next => next === key) + 1;
    flow.slice(mapIndex).forEach(next => {
      builder[index][next] = '';
    });
    this.props.onChange(query);
  };
  buildGroup = (group, level, condition, groupIndex, oldTarget) => {
    const target = oldTarget.length ? [...oldTarget, groupIndex, condition] : [condition];
    const conditionTarget = oldTarget.length ? [...oldTarget, groupIndex] : [];
    return <div className="data-field-group">
        <div className="data-field-group__header data-field-connect top">
          <div className="toggler">
            <Button small className={m('active', condition === 'and')} onClick={() => this.updateCondition(conditionTarget, 'and')}>
              and
            </Button>
            <Button small className={m('active', condition === 'or')} onClick={() => this.updateCondition(conditionTarget, 'or')}>
              or
            </Button>
          </div>
          <div className="data-field-group__actions">
            <Button small onClick={() => this.addRule(target)}>
              <Icon size={10} name="fa-regular-plus" /> Rule
            </Button>
            <Button small onClick={() => this.addGroup(target)}>
              <Icon size={10} name="fa-regular-plus" /> Group
            </Button>
            {level !== 0 && <Button small icon="fa-regular-minus" onClick={() => this.removeGroup(oldTarget, groupIndex)} />}
          </div>
        </div>
        {this.iterator(group, level, target)}
      </div>;
  };
  iterator = (group, level, target) => {
    return group.map((item, index) => {
      return <div key={`${level}${index}`} style={{
        position: 'relative'
      }} className="data-field-connect">
          {item.and || item.or ? this.buildGroup(item.and || item.or, level + 1, item.and ? 'and' : 'or', index, target) : <div className="data-field-row">
              <div className={a('data-field-row__inputs')}>
                {this.buildModel(item, index, target)}
              </div>
              <Button className="data-field-row__remove" icon="fa-regular-minus" actionType="flat" tabIndex={-1} onClick={() => this.removeRule(target, index)} small />
            </div>}
        </div>;
    });
  };
  buildModel = (item, index, target) => {
    const {
      models
    } = this.state;
    const selectedModel = models.find(m => m.id === item.model);
    return <>
        <Dropdown contentHeight={260} trigger={() => <Button className={!selectedModel ? 'rules-btn-choose' : ''}>
              {selectedModel ? selectedModel.name : 'Choose Model'}
            </Button>} content={({
        close
      }) => <ul className="select-list select-list__small">
              {models.map(model => <li key={model.id}>
                  <a onClick={() => {
            this.updateRow(target, index, 'model', model.id);
            close();
          }}>
                    <div>{model.name}</div>
                    {selectedModel && selectedModel.id === model.id && <Icon name="fa-solid-check-circle" size={14} />}
                  </a>
                </li>)}
            </ul>} />
        {!!selectedModel && this.buildField(item, index, target)}
      </>;
  };
  buildField = (item, index, target) => {
    const {
      models
    } = this.state;
    const fields = getFieldsFromModel(item.model, models);
    const selectedField = fields.find(f => f.id === item.field);
    return <>
        <Dropdown contentHeight={260} trigger={() => <Button className={!selectedField ? 'rules-btn-choose' : ''}>
              {selectedField ? selectedField.name : 'Choose Field'}
            </Button>} content={({
        close
      }) => <ul className="select-list select-list__small">
              {fields.map(field => <li key={field.id}>
                  <a onClick={() => {
            this.updateRow(target, index, 'field', field.id);
            close();
          }}>
                    <div>{field.name}</div>
                    {selectedField && selectedField.id === field.id && <Icon name="fa-solid-check-circle" size={14} />}
                  </a>
                </li>)}
            </ul>} />
        {!!selectedField && this.buildOperator(item, index, target, selectedField)}
      </>;
  };
  buildOperator = (item, index, target, selectedField) => {
    let operators;
    if (selectedField.type === FIELD_BOOLEAN) {
      operators = operatorBoolean;
    } else if (selectedField.type === FIELD_DATE || selectedField.type === FIELD_DATETIME) {
      operators = operatorDate;
    } else if (selectedField.type === FIELD_NUMBER) {
      operators = operatorNumber;
    } else if (selectedField.type === FIELD_LIST) {
      operators = operatorList;
    } else if (selectedField.type === FIELD_UUID) {
      operators = operatorUUID;
    } else {
      operators = operatorText;
    }
    const selectedOperator = operators.find(o => o.id === item.operator);
    return <>
        <Dropdown contentHeight={260} trigger={() => <Button className={!selectedOperator ? 'rules-btn-choose' : ''}>
              {selectedOperator ? selectedOperator.name : 'Choose Operator'}
            </Button>} content={({
        close
      }) => <ul className="select-list select-list__small">
              {operators.map(operator => <li key={operator.id}>
                  <a onClick={() => {
            this.updateRow(target, index, 'operator', operator.id);
            close();
          }}>
                    <div>{operator.name}</div>
                    {selectedOperator && selectedOperator.id === operator.id && <Icon name="fa-solid-check-circle" size={14} />}
                  </a>
                </li>)}
            </ul>} />

        {!!selectedOperator && !selectedOperator.end && this.buildQueryType(item, index, target, selectedField)}
      </>;
  };
  buildQueryType = (item, index, target, selectedField) => {
    const isDateType = selectedField.type === FIELD_DATE || selectedField.type === FIELD_DATETIME;
    const queryTypes = [{
      id: 'value',
      name: fieldTypeName(selectedField.type)
    }, {
      id: 'related_field',
      name: 'Related Field'
    }];
    if (isDateType) {
      queryTypes.push({
        id: 'relative_time',
        name: 'Relative Time'
      });
    }
    const selectedQueryType = queryTypes.find(qt => qt.id === item.query_type);
    return <>
        {selectedField.type !== FIELD_UUID ? <>
            <Dropdown contentHeight={260} trigger={() => <Button className={!selectedQueryType ? 'rules-btn-choose' : ''}>
                  {selectedQueryType ? selectedQueryType.name : 'Choose Query Type'}
                </Button>} content={({
          close
        }) => <ul className="select-list select-list__small">
                  {queryTypes.map(queryType => <li key={queryType.id}>
                      <a onClick={() => {
              this.updateRow(target, index, 'query_type', queryType.id);
              close();
            }}>
                        <div>{queryType.name}</div>
                        {selectedQueryType && selectedQueryType.id === queryType.id && <Icon name="fa-solid-check-circle" size={14} />}
                      </a>
                    </li>)}
                </ul>} />
            {!!selectedQueryType && <>
                <div className="rule-context">=</div>
                {selectedQueryType.id === 'value' && selectedField.type === FIELD_DATE && this.buildValueDate(item, index, target)}
                {selectedQueryType.id === 'value' && selectedField.type === FIELD_DATETIME && this.buildValueDate(item, index, target, true)}
                {selectedQueryType.id === 'value' && (selectedField.type === FIELD_TEXT || selectedField.type === FIELD_LIST || !selectedField.type) && this.buildValueText(item, index, target)}
                {selectedQueryType.id === 'value' && selectedField.type === FIELD_NUMBER && this.buildValueNumber(item, index, target)}
                {selectedQueryType.id === 'value' && selectedField.type === FIELD_BOOLEAN && this.buildValueBoolean(item, index, target)}
                {selectedQueryType.id === 'related_field' && this.buildRelatedModel(item, index, target, selectedField)}
                {selectedQueryType.id === 'relative_time' && this.buildRelativeTime(item, index, target)}
              </>}
          </> : this.buildValueUUID(item, index, target, selectedField)}
      </>;
  };
  buildRelatedModel = (item, index, target, selectedField) => {
    const {
      models
    } = this.state;
    const selectedRelatedModel = models.find(m => m.id === item.related_model);
    return <>
        <Dropdown contentHeight={260} trigger={() => <Button className={!selectedRelatedModel ? 'rules-btn-choose' : ''}>
              {selectedRelatedModel ? selectedRelatedModel.name : 'Choose Related Model'}
            </Button>} content={({
        close
      }) => <ul className="select-list select-list__small">
              {models.map(model => <li key={model.id}>
                  <a onClick={() => {
            this.updateRow(target, index, 'related_model', model.id);
            close();
          }}>
                    <div>{model.name}</div>
                    {selectedRelatedModel && selectedRelatedModel.id === model.id && <Icon name="fa-solid-check-circle" size={14} />}
                  </a>
                </li>)}
            </ul>} />
        {!!selectedRelatedModel && this.buildRelatedField(item, index, target, selectedField)}
      </>;
  };
  buildRelatedField = (item, index, target, selectedField) => {
    const {
      models
    } = this.state;
    const fields = getFieldsFromModel(item.related_model, models).filter(f => f.type === selectedField.type);
    const selectedRelatedField = fields.find(f => f.id === item.related_field);
    return <>
        <Dropdown contentHeight={260} trigger={() => <Button className={!selectedRelatedField ? 'rules-btn-choose' : ''}>
              {selectedRelatedField ? selectedRelatedField.name : 'Choose Related Field'}
            </Button>} content={({
        close
      }) => <ul className="select-list select-list__small">
              {fields.map(field => <li key={field.id}>
                  <a onClick={() => {
            this.updateRow(target, index, 'related_field', field.id);
            close();
          }}>
                    <div>{field.name}</div>
                    {selectedRelatedField && selectedRelatedField.id === field.id && <Icon name="fa-solid-check-circle" size={14} />}
                  </a>
                </li>)}
            </ul>} />
      </>;
  };
  buildRelativeTime = (item, index, target) => {
    const units = ['years', 'months', 'weeks', 'days'];
    const types = ['ago', 'from now'];
    return <>
        <input type="number" className="form-control inline" style={{
        width: '5.5rem',
        marginRight: '.6rem'
      }} value={item.value} autoFocus onChange={e => this.updateRow(target, index, 'value', e.target.value)} />
        <Dropdown contentHeight={260} trigger={() => <Button className={!item.time_unit ? 'rules-btn-choose' : ''}>
              {item.time_unit || 'Choose Unit'}
            </Button>} content={({
        close
      }) => <ul className="select-list select-list__small">
              {units.map(unit => <li key={unit}>
                  <a onClick={() => {
            this.updateRow(target, index, 'time_unit', unit);
            close();
          }}>
                    <div>{unit}</div>
                    {unit === item.time_unit && <Icon name="fa-solid-check-circle" size={14} />}
                  </a>
                </li>)}
            </ul>} />
        <Dropdown contentHeight={260} trigger={() => <Button className={!item.time_type ? 'rules-btn-choose' : ''}>
              {item.time_type || 'Choose Type'}
            </Button>} content={({
        close
      }) => <ul className="select-list select-list__small">
              {types.map(unit => <li key={unit}>
                  <a onClick={() => {
            this.updateRow(target, index, 'time_type', unit);
            close();
          }}>
                    <div>{unit}</div>
                    {unit === item.time_type && <Icon name="fa-solid-check-circle" size={14} />}
                  </a>
                </li>)}
            </ul>} />
      </>;
  };
  buildValueText = (item, index, target) => {
    return <input type="text" className="form-control inline" value={item.value} autoFocus onChange={e => this.updateRow(target, index, 'value', e.target.value)} />;
  };
  buildValueUUID = (item, index, target, selectedField) => {
    const getter = selectedField.target === 'coupon' ? getCoupons : getLocations;
    return <DynamicTypeAhead getItems={getter} getItemsFilters={{
      company: userState.getAsCompanyId()
    }} placeholder="Choose" displayProperty="name" keyProperty="id" value={item.value} onChange={val => this.updateRow(target, index, 'value', val ? val.id : null)} />;
  };
  buildValueDate = (item, index, target, isDateTime) => {
    let dateValue;
    if (item.value) {
      dateValue = DateTime.fromISO(item.value);
    }
    return <>
        <Dropdown size={250} allowContentClicks horizontal="west" fixedContent trigger={() => <Button className={!dateValue ? 'rules-btn-choose' : ''}>
              {dateValue ? dateValue.toFormat('LL/dd/yy') : 'Choose Date'}
            </Button>} content={({
        close
      }) => <Scoped css={styles}>
              <div className="p-sm">
                <Calendar minDate={new Date('jan 1 1970')} onChange={date => {
            const newDate = DateTime.fromJSDate(date).set({
              hour: dateValue ? dateValue.hour : 12,
              minute: dateValue ? dateValue.minute : 0
            });
            this.updateRow(target, index, 'value', newDate.toUTC().toISO());
            close();
          }} value={dateValue ? dateValue.toJSDate() : null} />
              </div>
            </Scoped>} />
        {!!dateValue && isDateTime && <Dropdown size={80} horizontal="west" contentHeight={260} trigger={() => <Button>{getTime(dateValue.hour, dateValue.minute)}</Button>} content={({
        close
      }) => <ul className="select-list">
                {timeSlots.map(slot => <li key={slot.time}>
                    <a onClick={() => {
            const newDate = dateValue.set({
              hour: slot.hour,
              minute: slot.minute
            });
            this.updateRow(target, index, 'value', newDate.toUTC().toISO());
            close();
          }}>
                      {slot.time}
                    </a>
                  </li>)}
              </ul>} />}
      </>;
  };
  buildValueNumber = (item, index, target) => {
    return <input type="number" className="form-control inline" value={item.value} autoFocus onChange={e => this.updateRow(target, index, 'value', numeral(e.target.value).value())} />;
  };
  buildValueBoolean = (item, index, target) => {};
  render() {
    const {
      query
    } = this.props;
    const {
      loading
    } = this.state;
    if (!query) return null;
    if (loading) return <Scoped css={styles}>
          <div className="rules__loading">
            <Loader />
          </div>
        </Scoped>;
    return <Scoped css={styles}>
        <div className="data-field">
          {this.buildGroup(query.and || query.or, 0, query.and ? 'and' : 'or', 0, [])}
        </div>
      </Scoped>;
  }
}