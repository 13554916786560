import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { Scoped, a, k } from 'kremling';
import moment from 'moment-timezone';
import { v4 as uuid } from 'uuid';
import { PageHeader } from 'components/page-header/page-header';
import { Button } from 'components/button/button.component';
import { Modal } from 'components/modal/modal.component';
import { CustomerCanvas } from '../customer-canvas/customer-canvas.component';
import { getMail, patchPrints, getPrintRevision, createPrintRevision, createPrintRevisionFromDraft } from 'shared/common.api';
import { userState } from 'shared/user-state';
import styles from './mail-print-style.scss';
import { Loader } from 'components/loader/loader.component';
import { Icon } from 'components/icon/icon.component';
import { toasterService } from 'components/toaster/toaster-service';
import { updatePrintRevision } from '../../../shared/common.api';
import { WorkingAsContext } from '../../../context/working-as-context';
export class MailTemplate extends Component {
  static contextType = WorkingAsContext;
  state = {
    editor: null,
    idMailTemplate: '',
    data: {},
    draft: {},
    loadTemplate: true,
    previewModal: false,
    publishModal: false,
    isGeneratingProofs: false,
    approveFront: false,
    approveBack: false,
    proofs: [],
    selectedProof: 0,
    company: userState.state.asCompanyId
  };
  componentDidMount() {
    const {
      idMailTemplate
    } = this.props.match.params;
    getMail(idMailTemplate).then(({
      data
    }) => {
      // check for different company link
      this.context.checkCompany(data.company).then(() => {
        const mail = data;
        const draft = data.revisions.find(r => !r.published_when);
        const latestRev = data.revisions.find(r => !!r.published_when);
        if (!draft && latestRev) {
          createPrintRevisionFromDraft(latestRev.id).then(({
            data
          }) => {
            this.setState({
              data: mail,
              draft: data,
              loadTemplate: false
            });
          });
        } else if (!draft) {
          this.setState({
            data,
            draft: {
              canvas_id: uuid()
            },
            loadTemplate: false
          });
        } else {
          getPrintRevision(draft.id).then(({
            data
          }) => {
            this.setState({
              data: mail,
              draft: data,
              loadTemplate: false
            });
          });
        }
      });
    }).catch(error => this.setState({
      error,
      isLoading: false
    }));
  }
  updateName = name => {
    const data = {
      ...this.state.data,
      name
    };
    this.setState({
      data
    });
    patchPrints(data.id, {
      name
    });
  };
  publish = () => {
    const {
      draft
    } = this.state;
    this.saveProduct().then(updatePrintRevision(draft.id, {
      published_when: new Date()
    })).then(() => {
      toasterService.success('Mail template successfully published');
      this.props.history.push('/templates');
    });
  };
  saveProduct = () => {
    const {
      idMailTemplate
    } = this.props.match.params;
    const {
      editor,
      draft
    } = this.state;
    return editor.saveProduct(draft.canvas_id).then(res => {
      if (!draft.id) {
        return createPrintRevision({
          print: idMailTemplate,
          canvas_id: res.stateId
        }).then(({
          data
        }) => {
          const print = JSON.parse(JSON.stringify(this.state.data));
          print.revisions.unshift({
            id: data.id
          });
          this.setState({
            draft: data,
            data: print
          });
        });
      }
    });
  };
  onLoad = editor => {
    editor.subscribe();
    this.setState({
      editor
    });
    this.saveProduct();
  };
  openPreviewModal = openModal => {
    this.setState({
      [openModal]: true,
      isGeneratingProofs: true
    });
    this.state.editor.getProofImages({
      maxHeight: 968,
      maxWidth: 968
    }).then(result => {
      this.setState({
        proofs: result.proofImageUrls,
        isGeneratingProofs: false
      });
    });
  };
  closePreviewModal = () => {
    this.setState({
      previewModal: false,
      publishModal: false,
      isGeneratingProofs: false,
      proofs: [],
      selectedProof: 0,
      approveFront: false,
      approveBack: false
    });
  };
  loadRevision(revision_id) {
    const {
      id
    } = this.state.data;
    this.props.history.push(`/templates/print/${id}/${revision_id}`);
  }
  render() {
    const {
      data,
      draft,
      previewModal,
      publishModal,
      isGeneratingProofs,
      proofs,
      editor,
      selectedProof,
      approveFront,
      approveBack,
      loadTemplate
    } = this.state;
    const {
      name,
      substrate_length_inches,
      substrate_width_inches
    } = data;
    const {
      id,
      canvas_id
    } = draft;
    return <Scoped css={styles}>
        <div className="wrapper height-100" style={{
        overflow: 'hidden'
      }}>
          <PageHeader name={name} updateName={this.updateName} actions={<>
                <div>
                  <select className="form-control" value={draft.id} onChange={e => this.loadRevision(e.target.value)}>
                    {!!data && !!data.revisions && data.revisions.map(revision => <option key={revision.id} value={revision.id}>
                          {!revision.published_when ? 'Draft' : `Published ${moment(revision.published_when).format('MMM D, YYYY h:mm A')}`}
                        </option>)}
                  </select>
                </div>
                <Button disabled={!editor} onClick={() => this.saveProduct().then(() => toasterService.success('Successfully Saved template'))}>
                  Save
                </Button>
                <Button onClick={() => this.openPreviewModal('previewModal')} disabled={!editor}>
                  Preview
                </Button>
                <Button actionType="primary" onClick={() => this.openPreviewModal('publishModal')} disabled={!editor}>
                  Publish
                </Button>
              </>} />
          <div>
            <div className="customer-canvas">
              {!loadTemplate && <CustomerCanvas onLoad={this.onLoad} canvasId={id ? canvas_id : null} length={substrate_length_inches} width={substrate_width_inches} />}
            </div>
            <ToastContainer />
          </div>
          <Modal open={previewModal} onClose={this.closePreviewModal} allowBackdropClick title="Preview" size="xl" type="scroll">
            {isGeneratingProofs && <Loader overlay background={'#fff'} />}
            <Scoped css={previewCss}>
              <div className="modal__body" style={{
              minHeight: '30rem'
            }}>
                <div className="preview-proof">
                  {proofs.map((proof, index) => <div className={a('preview-proof__item').m('active', selectedProof === index)} onClick={() => this.setState({
                  selectedProof: index
                })} key={index.toString()}>
                      <div className="preview-proof__item-inner">
                        <img src={proof[0]} />
                      </div>
                      {index === 0 ? 'Front' : 'Back'}
                    </div>)}
                </div>
                {!!proofs.length && <div className="preview-current">
                    <img src={proofs[selectedProof][0]} />
                  </div>}
              </div>
              <div className="modal__actions">
                <Button onClick={this.closePreviewModal}>Close</Button>
              </div>
            </Scoped>
          </Modal>

          <Modal open={publishModal} title="Publish" type="scroll" size="xl" onClose={this.closePreviewModal} allowBackdropClick>
            {isGeneratingProofs && <Loader overlay background={'#fff'} />}
            <Scoped css={previewCss}>
              <div className="modal__body">
                <p>
                  Please check these images carefully for misprints or errors.
                  <br />
                  By proceeding you confirm that you are responsible for any
                  mistakes or problems with this design.
                </p>
                <div className="preview-proof">
                  {proofs.map((proof, index) => <div className={a('preview-proof__item').m('active', selectedProof === index)} onClick={() => this.setState({
                  selectedProof: index
                })} key={index.toString()}>
                      <div className="preview-proof__item-inner">
                        <img src={proof[0]} />
                      </div>
                      {index === 0 ? 'Front' : 'Back'}
                      {(index === 0 && approveFront || index !== 0 && approveBack) && <div className="preview-proof__check">
                          <Icon name="fa-regular-check" size={14} />
                        </div>}
                    </div>)}
                </div>
                {!!proofs.length && <div className="preview-current">
                    <img src={proofs[selectedProof][0]} />
                  </div>}
              </div>
              <div className="modal__actions">
                <Button actionType="flat" onClick={this.closePreviewModal}>
                  Cancel
                </Button>
                <Button actionType={!approveBack || !approveFront ? 'primary' : 'grey'} disabled={selectedProof === 0 ? approveFront : approveBack} onClick={() => {
                let newState = {};
                if (selectedProof === 0) {
                  newState.approveFront = true;
                  if (!approveBack) newState.selectedProof = 1;
                } else {
                  newState.approveBack = true;
                  if (!approveFront) newState.selectedProof = 0;
                }
                this.setState(newState);
              }}>
                  {selectedProof === 0 && approveFront || selectedProof !== 0 && approveBack ? 'Approved' : `Approve ${selectedProof === 0 ? 'Front' : 'Back'}`}
                </Button>
                <Button actionType={!approveBack || !approveFront ? 'grey' : 'primary'} disabled={!approveBack || !approveFront} onClick={this.publish}>
                  Publish
                </Button>
              </div>
            </Scoped>
          </Modal>
        </div>
      </Scoped>;
  }
}
const previewCss = {
  styles: `[kremling="i17"] body,body[kremling="i17"] {
  --color-primary: #487aae;
  --color-highlight: #e7efff;
  --color-accent: #354052;
  --color-success: #73b56e;
  --color-warning: #f4b707;
  --color-danger: #df5651;
  --color-grey-10: #f9f9f9;
  --color-grey-25: #f3f3f3;
  --color-grey-50: #e9e9e9;
  --color-grey-75: #e3e3e3;
  --color-grey-100: #d3d3d3;
  --color-grey-200: #c3c3c3;
  --color-grey-300: #b3b3b3;
  --color-grey-400: #a3a3a3;
  --color-grey-500: #808080;
  --color-grey-600: #707070;
  --color-grey-700: #606060;
  --color-grey-800: #505050;
  --color-grey-900: #404040;
  --base-font-family: Roboto, Helvetica, Arial, sans-serif;
  --base-font-size: 1.4rem;
  --base-font-color: #404040;
  --base-font-weight: 400;
  --base-line-height: 1.4;
  --box-shadow-1: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.12);
  --box-shadow-2: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-3: 0 10px 20px rgba(0, 0, 0, 0.17), 0 6px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-4: 0 14px 28px rgba(0, 0, 0, 0.13), 0 10px 10px rgba(0, 0, 0, 0.1);
  --box-shadow-5: 0 19px 38px rgba(0, 0, 0, 0.18), 0 15px 12px rgba(0, 0, 0, 0.1);
  --base-border-radius: 0.4rem;
}

[kremling="i17"] .preview-proof,[kremling="i17"].preview-proof {
  display: flex;
  margin-bottom: 2rem;
}

[kremling="i17"] .preview-proof__item,[kremling="i17"].preview-proof__item {
  text-align: center;
  padding: 0.6rem;
  border-radius: 0.4rem;
  cursor: pointer;
  position: relative;
}

[kremling="i17"] .preview-proof__item.active,[kremling="i17"].preview-proof__item.active {
  background-color: var(--primary-background-color);
}

[kremling="i17"] .preview-proof__item-inner,[kremling="i17"].preview-proof__item-inner {
  width: 8rem;
  position: relative;
  border: solid 0.1rem #d3d3d3;
  padding: 0.3rem;
  border-radius: 0.3rem;
  background-color: #fff;
  margin-bottom: 0.4rem;
}

[kremling="i17"] .preview-proof__item img,[kremling="i17"].preview-proof__item img {
  width: 100%;
}

[kremling="i17"] .preview-current img,[kremling="i17"].preview-current img {
  width: 100%;
}

[kremling="i17"] .preview-title,[kremling="i17"].preview-title {
  text-align: center;
  margin-bottom: 1rem;
}

[kremling="i17"] .preview-proof__check,[kremling="i17"].preview-proof__check {
  height: 2rem;
  width: 2rem;
  position: absolute;
  right: 0.3rem;
  top: 0.3rem;
  border-radius: 2rem;
  background-color: #73b56e;
}

[kremling="i17"] .preview-proof__check svg,[kremling="i17"].preview-proof__check svg {
  fill: #fff !important;
  height: 1.4rem;
  width: 1.4rem;
}`,
  id: 'i17',
  namespace: 'kremling'
};