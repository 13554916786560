import React, { Component } from 'react';
import { Scoped } from 'kremling';
import styles from './integrations.styles.scss';
import { userState } from '../../shared/user-state';
import { createCredentials as createCredentialsAPI, updateCredential as updateCredentialsAPI, deleteCredential as deleteCredentialsAPI } from './integrations.resource';
import { toasterService } from '../../components/toaster/toaster-service';
import { ModalDialog } from '../../components/modal/modal-dialog.component';
import { ModalSchemaForm, Button } from 'components';
export class IntegrationDynamic extends Component {
  static propTypes = {};
  state = {
    deleteCredential: {},
    updateCredential: {},
    isCredentialModalOpen: false,
    identifier: null
  };
  addCredential = entity_data => {
    return createCredentialsAPI({
      entity_data,
      company: userState.getAsCompanyId(),
      integration: this.props.integration.key
    }).then(() => {
      toasterService.success('Successfully added credentials. Syncing will begin soon.');
      this.setState({
        isCredentialModalOpen: false
      });
      this.props.reload();
    }).catch(err => {
      if (err.response.data.non_field_errors) {
        toasterService.error(err.response.data.non_field_errors[0]);
      } else {
        toasterService.error('Unknown error trying to add credentials. Please try again.');
      }
      throw err;
    });
  };
  updateCredential = (id, entity_data) => {
    return updateCredentialsAPI(id, {
      entity_data
    }).then(() => {
      toasterService.success('Successfully updated credentials.');
      this.setState({
        updateCredential: {}
      });
      this.props.reload();
    }).catch(err => {
      if (err.response.data.non_field_errors) {
        toasterService.error(err.response.data.non_field_errors[0]);
      } else {
        toasterService.error('Unknown error trying to update credentials. Please try again.');
      }
      throw err;
    });
  };
  removeCredentials = credentialsId => {
    return deleteCredentialsAPI(credentialsId).then(() => toasterService.success('Successfully deleted credentials')).then(() => this.props.reload()).catch(() => toasterService.error('Unknown error to trying delete credentials. Please try again.'));
  };
  render() {
    const {
      isCredentialModalOpen,
      deleteCredential,
      updateCredential
    } = this.state;
    const {
      credentials,
      integration
    } = this.props;
    return <Scoped css={styles}>
        <div className="integration mb-5">
          <div className="integration__header">
            <div>{integration.name}</div>
            {userState.hasPermission('integration.add_credential') && <Button className="btn" actionType="primary" tag="a" onClick={() => this.setState({
            isCredentialModalOpen: true
          })}>
                Add Credentials
              </Button>}
          </div>
          <div className="integration__body">
            {credentials.map(credential => <div className="integration-item" key={credential.id}>
                <div className="integration-item__inner">
                  <div className="integration-title">
                    {credential.entity_data[integration.identifier_key]}
                  </div>
                  <div className="integration-actions">
                    {userState.hasPermission('integration.change_credential') && <Button actionType="flat" icon="fa-regular-edit" onClick={e => {
                  e.stopPropagation();
                  this.setState({
                    updateCredential: credential
                  });
                }} />}

                    {userState.hasPermission('integration.delete_credential') && <Button actionType="flat" icon="fa-regular-trash" onClick={e => {
                  e.stopPropagation();
                  this.setState({
                    deleteCredential: credential
                  });
                }} />}
                  </div>
                </div>
              </div>)}
          </div>

          <ModalDialog open={!!deleteCredential.id} title="Remove Credential" submitText="Remove" onSubmit={() => this.removeCredentials(deleteCredential.id)} onClose={() => this.setState({
          deleteCredential: {}
        })} allowBackdropClick>
            <p>
              <strong>{deleteCredential.identifier}</strong>
            </p>
            <p>Are you sure you want to remove this credential?</p>
          </ModalDialog>

          <ModalSchemaForm open={isCredentialModalOpen} title="Add Credential" onSubmit={({
          formData
        }) => this.addCredential(formData)} onClose={() => this.setState({
          isCredentialModalOpen: false
        })} allowBackdropClick schema={integration.credential_schema} />

          <ModalSchemaForm open={!!updateCredential.id} title="Update Credential" onSubmit={({
          formData
        }) => this.updateCredential(updateCredential.id, formData)} onClose={() => this.setState({
          updateCredential: {}
        })} allowBackdropClick schema={integration.credential_schema} formData={updateCredential.entity_data} />
        </div>
      </Scoped>;
  }
}