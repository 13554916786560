import React, { Component } from 'react';
import { func, object, shape, string, number } from 'prop-types';
import { Scoped, k } from 'kremling';
export class TriggerTimeSchedule extends Component {
  static propTypes = {
    update: func,
    node: object,
    data: shape({
      amount: number,
      unit: string,
      direction: string
    })
  };
  onAmountChange = e => {
    this.props.update({
      amount: e.target.value
    });
  };
  onUnitChange = e => {
    this.props.update({
      unit: e.target.value
    });
  };
  onDirectionChange = e => {
    this.props.update({
      direction: e.target.value
    });
  };
  render() {
    const {
      unit,
      amount,
      direction
    } = this.props.data;
    return <Scoped css={css}>
        <div>
          <div className="form-group">
            <label>Time</label>
            <div className="row">
              <div className="col-3">
                <input type="number" className="form-control" value={amount} onChange={this.onAmountChange} />
              </div>
              <div className="col-5">
                <select className="form-control" value={unit} onChange={this.onUnitChange}>
                  <option value="minutes">Minute(s)</option>
                  <option value="hours">Hour(s)</option>
                  <option value="days">Day(s)</option>
                  <option value="weeks">Week(s)</option>
                </select>
              </div>
              <div className="col-4">
                <select className="form-control" value={direction} onChange={this.onDirectionChange}>
                  <option value="ago">Ago</option>
                  <option value="from_now">From Now</option>
                </select>
              </div>
            </div>
          </div>
          <div className="form-group">
            <p>
              Note: This node runs every 15 minutes and triggers all schedules within a 15 minute window.
            </p>
          </div>
        </div>
      </Scoped>;
  }
}
const css = {
  styles: `[kremling="i32"] body,body[kremling="i32"] {
  --color-primary: #487aae;
  --color-highlight: #e7efff;
  --color-accent: #354052;
  --color-success: #73b56e;
  --color-warning: #f4b707;
  --color-danger: #df5651;
  --color-grey-10: #f9f9f9;
  --color-grey-25: #f3f3f3;
  --color-grey-50: #e9e9e9;
  --color-grey-75: #e3e3e3;
  --color-grey-100: #d3d3d3;
  --color-grey-200: #c3c3c3;
  --color-grey-300: #b3b3b3;
  --color-grey-400: #a3a3a3;
  --color-grey-500: #808080;
  --color-grey-600: #707070;
  --color-grey-700: #606060;
  --color-grey-800: #505050;
  --color-grey-900: #404040;
  --base-font-family: Roboto, Helvetica, Arial, sans-serif;
  --base-font-size: 1.4rem;
  --base-font-color: #404040;
  --base-font-weight: 400;
  --base-line-height: 1.4;
  --box-shadow-1: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.12);
  --box-shadow-2: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-3: 0 10px 20px rgba(0, 0, 0, 0.17), 0 6px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-4: 0 14px 28px rgba(0, 0, 0, 0.13), 0 10px 10px rgba(0, 0, 0, 0.1);
  --box-shadow-5: 0 19px 38px rgba(0, 0, 0, 0.18), 0 15px 12px rgba(0, 0, 0, 0.1);
  --base-border-radius: 0.4rem;
}`,
  id: 'i32',
  namespace: 'kremling'
};