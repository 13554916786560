import React, { useState, useEffect } from 'react';
import { PageHeader, Button, Loader, Pagination, Status, SortHeader, Dropdown, Modal } from 'components';
import { getSurveys, duplicateSurvey, deleteSurvey } from 'shared/common.api';
import { userState } from 'shared/user-state';
import { history } from '../../root.component';
import utils from 'shared/utils';
import moment from 'moment-timezone';
export const SurveysList = () => {
  const [loading, setLoading] = useState(true);
  const [surveys, setSurveys] = useState([]);
  const [count, setCount] = useState();
  const [search, setSearch] = useState('');
  const [forceReload, setForceReload] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState();
  const [ordering, setOrdering] = useState('-updated_when');
  const [paginationParams, setPaginationParams] = useState({
    limit: 20,
    offset: 0
  });
  useEffect(() => {
    setLoading(true);
    getSurveys({
      ...paginationParams,
      search,
      ordering,
      company: userState.getAsCompanyId()
    }).then(({
      data
    }) => {
      if (data) {
        setSurveys(data.results);
        setCount(data.count);
        setLoading(false);
      }
    });
  }, [search, ordering, paginationParams, forceReload]);
  const duplicate = survey => {
    duplicateSurvey(survey.id).then(({
      data: survey
    }) => history.push(`/surveys/${survey.id}`));
  };
  const _delete = survey => {
    deleteSurvey(survey.id).then(() => {
      setForceReload(r => !r);
      setShowDeleteModal(null);
    });
  };
  return <>
      <div className="wrapper">
        {loading && <Loader overlay background="rgba(255,255,255,.5)" />}
        <PageHeader name={`Surveys ${count ? `(${utils.commaize(count)})` : ''}`} onSearch={setSearch} actions={userState.hasPermission('surveys.add_survey') && <Button actionType="primary" icon="fa-regular-plus" onClick={() => history.push('/surveys/create')} />} />
        <div className="wrapper-scroll surveys-list">
          <table className="table-list">
            {!loading && !!surveys.length && <thead>
                <tr>
                  <SortHeader name="title" ordering={ordering} update={setOrdering}>
                    Title
                  </SortHeader>
                  <th>Status</th>
                  <SortHeader name="created_when" ordering={ordering} update={setOrdering}>
                    Date Created
                  </SortHeader>
                  <SortHeader name="updated_when" ordering={ordering} update={setOrdering}>
                    Last Edited
                  </SortHeader>
                  <th width="78px"></th>
                </tr>
              </thead>}
            <tbody>
              {surveys.length && !loading && surveys.map(survey => <tr key={survey.id} onClick={() => history.push(`/surveys/${survey.id}`)}>
                    <td>{survey.title}</td>
                    <td>
                      <Status status={survey.status} />
                    </td>
                    <td>
                      {moment(survey.created_when).local().format('MMM D, YYYY h:mm A')}
                    </td>
                    <td>
                      {moment(survey.updated_when).local().format('MMM D, YYYY h:mm A')}
                    </td>
                    <td>
                      {userState.hasPermission('surveys.add_survey', 'surveys.delete_survey') && <Dropdown horizontal="west" trigger={() => <Button actionType="flat" icon="fa-regular-ellipsis-h" />} content={() => <ul className="select-list">
                              {userState.hasPermission('surveys.add_survey') && <li onClick={() => duplicate(survey)}>
                                  <a>Duplicate</a>
                                </li>}
                              {userState.hasPermission('surveys.delete_survey') && <li onClick={() => setShowDeleteModal(survey)}>
                                  <a>Delete</a>
                                </li>}
                            </ul>} />}
                    </td>
                  </tr>) || <tr>
                  {!loading && <td colSpan={5}>
                      <strong>No Results</strong>
                    </td>}
                </tr>}
            </tbody>
          </table>
          <div className="pt-md pb-md">
            <Pagination limit={paginationParams.limit} data={{
            count
          }} onChange={setPaginationParams} />
          </div>
        </div>

        <Modal title="Delete Survey" onClose={() => setShowDeleteModal(null)} open={!!showDeleteModal} allowBackdropClick>
          <div className="modal__body">
            <p>
              Are you sure you want to delete this survey? This cannot be
              undone. Any invite already sent will no longer work.
            </p>
          </div>
          <div className="modal__actions">
            <Button actionType="flat" onClick={() => setShowDeleteModal(null)}>
              Cancel
            </Button>
            <Button actionType="danger" onClick={() => _delete(showDeleteModal)}>
              Delete
            </Button>
          </div>
        </Modal>
      </div>
    </>;
};