import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { DateTime } from 'luxon';
import { getMe } from '../shared/auth.api';
import { getCompanies, getCompany } from '../shared/common.api';
import { userState } from '../shared/user-state';
import { Loader } from './loader/loader.component';
import { toasterService } from 'components/toaster/toaster-service';
export class AuthCheck extends Component {
  state = {
    isAuthenticated: null
  };
  componentDidMount() {
    if (!userState.state.user && userState.getToken().token) {
      let asCompany;
      Promise.all([getMe()]).then(([user]) => {
        if (user.status !== 200) {
          return this.setState({
            isAuthenticated: false
          });
        }
        const companyId = userState.getAsCompanyId();
        if (companyId) {
          asCompany = user.data.companies.find(company => company.id === companyId);
        }
        if (!asCompany && user.data.companies[0]) {
          asCompany = user.data.companies[0];
        }
        if (!asCompany) {
          return this.props.history.push('/no-company');
        }
        userState.setAsCompany(asCompany);
        userState.set({
          user: user.data,
          validToken: true
        });
        this.setState({
          isAuthenticated: true
        });
      }, error => {
        userState.authRedirect();
        this.setState({
          isAuthenticated: false
        });
      });
    } else if (userState.getAsCompanyId() && userState.getToken().token) {
      this.setState({
        isAuthenticated: true
      });
    } else {
      this.setState({
        isAuthenticated: false
      });
    }
  }
  componentDidUpdate() {
    if (this.state.isAuthenticated) {
      document.title = 'Cinch';
    }
  }
  render() {
    const {
      isAuthenticated
    } = this.state;
    if (isAuthenticated === null) return <Loader overlay />;
    if (isAuthenticated) return this.props.children;
    return <Redirect to={`/sign-in?redirect=${encodeURIComponent(this.props.location.pathname + this.props.location.search)}`} />;
  }
}