import React, { Component } from 'react';
import { bool, func, string } from 'prop-types';
import { Scoped, k } from 'kremling';
export class FormWizardDetails extends Component {
  static propTypes = {
    formRedirect: string,
    formUrl: string,
    showFormRedirect: bool,
    updateState: func,
    formName: string
  };
  updateFormUrl = formUrl => {
    this.props.updateState({
      formUrl,
      shouldSearch: true,
      forms: [],
      formFields: []
    });
  };
  updateShowFormRedirect = showFormRedirect => {
    this.props.updateState({
      showFormRedirect
    });
  };
  updateFormName = formName => {
    this.props.updateState({
      formName
    });
  };
  updateFormRedirect = formRedirect => {
    this.props.updateState({
      formRedirect
    });
  };
  render() {
    const {
      formRedirect,
      formUrl,
      formName,
      showFormRedirect
    } = this.props;
    return <Scoped css={css}>
        <div>
          <div className="form-group">
            <label>Form Name</label>
            <input value={formName} onChange={e => this.updateFormName(e.target.value)} className="form-control" type="text" />
          </div>
          <div className="form-group">
            <label>Form URL</label>
            <input value={formUrl} onChange={e => this.updateFormUrl(e.target.value)} className="form-control" type="text" placeholder="eg: https://yoursite.com/your-form" />

            <div className="mt-sm">
              Must be a valid and complete URL. E.g., <em>https://yourdomain.com</em> and not simply <em>yourdomain.com</em>
            </div>
          </div>
          <div className="form-group">
            <label>
              <input type="checkbox" checked={showFormRedirect} onChange={() => this.updateShowFormRedirect(!showFormRedirect)} />
              Force Redirect (Optional)
            </label>
            <div className="mb-sm">
              On form submission, force a redirect to a custom page
            </div>
            {showFormRedirect && <>
                <input type="text" className="form-control" placeholder="eg: https://yoursite.com/redirect-url" value={formRedirect} onChange={e => this.updateFormRedirect(e.target.value)} />

                <div className="mt-sm">
                  Must be a valid and complete URL. E.g., <em>https://yourdomain.com</em> and not simply <em>yourdomain.com</em>
                </div>
              </>}
          </div>
        </div>
      </Scoped>;
  }
}
const css = {
  styles: `[kremling="i38"] body,body[kremling="i38"] {
  --color-primary: #487aae;
  --color-highlight: #e7efff;
  --color-accent: #354052;
  --color-success: #73b56e;
  --color-warning: #f4b707;
  --color-danger: #df5651;
  --color-grey-10: #f9f9f9;
  --color-grey-25: #f3f3f3;
  --color-grey-50: #e9e9e9;
  --color-grey-75: #e3e3e3;
  --color-grey-100: #d3d3d3;
  --color-grey-200: #c3c3c3;
  --color-grey-300: #b3b3b3;
  --color-grey-400: #a3a3a3;
  --color-grey-500: #808080;
  --color-grey-600: #707070;
  --color-grey-700: #606060;
  --color-grey-800: #505050;
  --color-grey-900: #404040;
  --base-font-family: Roboto, Helvetica, Arial, sans-serif;
  --base-font-size: 1.4rem;
  --base-font-color: #404040;
  --base-font-weight: 400;
  --base-line-height: 1.4;
  --box-shadow-1: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.12);
  --box-shadow-2: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-3: 0 10px 20px rgba(0, 0, 0, 0.17), 0 6px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-4: 0 14px 28px rgba(0, 0, 0, 0.13), 0 10px 10px rgba(0, 0, 0, 0.1);
  --box-shadow-5: 0 19px 38px rgba(0, 0, 0, 0.18), 0 15px 12px rgba(0, 0, 0, 0.1);
  --base-border-radius: 0.4rem;
}`,
  id: 'i38',
  namespace: 'kremling'
};