import React, { useState, useEffect } from 'react';
import { getter } from './utils';
import { Scoped } from 'kremling';
import styles from './index.styles.scss';
import moment from 'moment-timezone';
import { getSegments, createCustomerSegment, deleteCustomerSegment } from '../../../shared/common.api';
import { Button } from 'components/button/button.component';
import { Modal } from 'components/modal/modal.component';
import { ModalDialog } from 'components/modal/modal-dialog.component';
import { DynamicTypeAhead } from 'components/dynamic-type-ahead/dynamic-type-ahead.component';
import { toasterService } from 'components/toaster/toaster-service';
import { userState } from '../../../shared/user-state';
import utils from '../../../shared/utils';
import { sortBy } from 'lodash';
const fieldsToExclude = ['id', 'company_id', 'first_name', 'last_name', 'phone', 'email', 'address1', 'address2', 'city', 'state', 'zip_code', 'country', 'entity_data'];
export function DetailsComponent(props) {
  // These props are a lazy way to pass data around.  (Mostly the unsubscribe/resubscribe stuff).  We need to optimize this later.
  const {
    customer,
    availableModels,
    availableModelsFields,
    customerDetails,
    unsubscribeCustomer,
    unsubscribeCustomerSMS,
    resetCustomerSMS,
    goToSchedule
  } = props;
  const [showMoreCustomerFields, setShowMoreCustomerFields] = useState(false);
  const [showMoreSegments, setShowMoreSegments] = useState(false);
  const [showMoreCustomerRefs, setShowMoreCustomerRefs] = useState(false);
  const [showMoreSchedules, setShowMoreSchedules] = useState(false);
  const [showMoreAnimals, setShowMoreAnimals] = useState(false);
  const [showMoreRealStates, setShowMoreRealStates] = useState(false);
  const [showMoreVehicles, setShowMoreVehicles] = useState(false);
  const [showMoreSubscriptions, setShowMoreSubscriptions] = useState(false);
  const [showEditSMSStatus, setShowEditSMSStatus] = useState(false);
  const [showEditEmailStatus, setShowEditEmailStatus] = useState(false);
  const [showOptOutSMS, setShowOptOutSMS] = useState(false);
  const [cantOptInSMS, setCantOptInSMS] = useState(false);

  // Add to segment modal
  const [showModal, setShowModal] = useState(false);
  const [selectedSegment, setSelectedSegment] = useState();
  const addCustomerToSegment = () => {
    setShowModal(false);
    createCustomerSegment({
      customer: customer.id,
      segment: selectedSegment.id
    }).then(({
      data
    }) => {
      toasterService.success('Customer successfully added to segment.');
      customerDetails.segments.push({
        id: data.id,
        segment_id: selectedSegment.id,
        segment_name: selectedSegment.name
      });
    }).catch(err => {
      if (err.response && err.response.data.non_field_errors) {
        toasterService.error(err.response.data.non_field_errors[0]);
      } else {
        toasterService.error('Unknown error trying to add customer to segment.');
      }
    }).then(() => {
      setSelectedSegment(null);
    });
  };
  const deleteSegment = id => {
    deleteCustomerSegment(id).then(() => {
      toasterService.success('Customer successfully removed from segment.');
      customerDetails.segments = customerDetails.segments.filter(s => s.id !== id);
      // Hacky way to rerender view... TODO fix this right
      setSelectedSegment(selectedSegment === null ? false : null);
    }).catch(() => {
      toasterService.error('Unknown error trying to delete customer from segment.');
    });
  };
  const formatField = (field, value) => {
    if (value === undefined || value === null || value === '') {
      return <i>No Data Available</i>;
    }
    if (field.type === 'datetime' || field.type === 'date') {
      const date = moment(value);
      if (!date.isValid()) {
        return <i>No Data Available</i>;
      }
      value = field.type === 'datetime' ? date.local().format('MMM D, YYYY h:mm A') : date.format('MMM D, YYYY');
    }
    return <i>{value}</i>;
  };
  const resubscribeSMS = () => {
    if (customerDetails.sms_optstatus === 'opt_out') {
      setCantOptInSMS(true);
    } else {
      resetCustomerSMS();
      setShowEditSMSStatus(false);
    }
  };
  const customerModel = availableModelsFields.find(model => model.id === 'customer');
  const customerFields = customerModel ? customerModel.fields.filter(f => !fieldsToExclude.includes(f.id)) : [];
  return <Scoped css={styles}>
      <div className="drawer__body" style={{
      height: 'calc(100vh - 9rem)',
      overflowY: 'auto'
    }}>
        <table className="data-table withoutMR">
          <tbody>
            {/* PHONE - SMS OPT STATUS */}
            <tr>
              <th>phone</th>
              <th style={{
              verticalAlign: 'center'
            }}>sms opt status</th>
              <th> {showEditSMSStatus && 'Edit SMS Status'}</th>
            </tr>
            <tr>
              <td>{utils.formatPhoneNumber(customer.phone) || 'No Phone Number'}</td>
              {customer.phone && <>
                  <td>{customerDetails.sms_optstatus || 'No Status'} </td>
                  <td>
                    {showEditSMSStatus ? <div style={{
                  display: 'flex'
                }}>
                        <Button className="ml-2" actionType="flat" small={true} style={{
                    fontSize: '13px'
                  }} onClick={() => setShowEditSMSStatus(false)}>
                          Cancel
                        </Button>
                        <Button className="ml-2" small={true} style={{
                    fontSize: '13px'
                  }} onClick={() => setShowOptOutSMS(true)}>
                          Opt Out
                        </Button>
                        <Button className="ml-2 buttonPrimary" actionType="primary" style={{
                    fontSize: '13px'
                  }} small={true} onClick={() => resubscribeSMS()}>
                          Reset Status
                        </Button>
                      </div> : <Button actionType="flat" style={{
                  position: 'relative',
                  top: -8
                }} icon="fa-regular-pencil-alt" onClick={e => {
                  e.stopPropagation();
                  setShowEditSMSStatus(true);
                }} />}
                  </td>
                </>}
            </tr>
            {/* EMAIL - EMAIL OPT STATUS*/}
            <tr>
              <th>email</th>
              <th>email opt status</th>
              <th> {showEditEmailStatus && 'Edit Email Status'}</th>
            </tr>
            <tr>
              <td style={{
              textTransform: customer.email && 'lowercase'
            }}>{customer.email || <i>No Email Address</i>}</td>
              {customer.email && <>
                  <td>{customerDetails.email_optstatus || 'No Status'}</td>
                  <td>
                    {showEditEmailStatus ? <div style={{
                  display: 'flex'
                }}>
                        <Button className="ml-2" actionType="flat" small={true} style={{
                    fontSize: '13px'
                  }} onClick={() => setShowEditEmailStatus(false)}>
                          Cancel
                        </Button>
                        <Button className="ml-2" small={true} style={{
                    fontSize: '13px'
                  }} onClick={() => {
                    unsubscribeCustomer('unsubscribed');
                    setShowEditEmailStatus(false);
                  }}>
                          Unsubscribe
                        </Button>
                        <Button className="ml-2 buttonPrimary" actionType="primary" style={{
                    fontSize: '13px'
                  }} small={true} onClick={() => {
                    unsubscribeCustomer('pending');
                    setShowEditEmailStatus(false);
                  }}>
                          Reset Status
                        </Button>
                      </div> : <Button actionType="flat" style={{
                  position: 'relative',
                  top: -8
                }} icon="fa-regular-pencil-alt" onClick={e => {
                  e.stopPropagation();
                  setShowEditEmailStatus(true);
                }} />}
                  </td>
                </>}
            </tr>
            {/* ADDRESS  */}
            <tr>
              <th>address</th>
            </tr>
            <tr>
              <td>
                {!customer.address1 && !customer.address2 && !customer.city && !customer.state && !customer.zip_code && <i>No Address</i>}
                {customer.address1}{' '}
                {customer.address2 && <>
                    <br />
                    {customer.address2}
                  </>}{' '}
                <br />
                {customer.city} {customer.state} {customer.zip_code}
              </td>
            </tr>
          </tbody>
        </table>

        <div className="mb-5 mt-2 div-table">
          {availableModels.indexOf('customer') > -1 && customerFields.map((field, index) => {
          const fieldValue = customer[field.id];
          if (field.id.substring(0, 15) === 'customer_refs__' || !showMoreCustomerFields && index > 5) {
            return;
          }
          return field.id.substring(0, 13) !== 'entity_data__' && field.id.substring(0, 15) !== 'appended_data__' ? <div key={index} className={'mt-2 column'}>
                  <h4>{field.name}</h4>
                  <p>{formatField(field, fieldValue)}</p>
                </div> : <div key={index} className={'mt-2 column'}>
                  <h4>{field.name}</h4>
                  <p>{getter(customer, field.id.split('__')) || <i>No Data Available</i>}</p>
                </div>;
        })}
        </div>
        {customerFields.length > 5 && <div className="buttonCenter">
            <Button tag="a" onClick={() => setShowMoreCustomerFields(!showMoreCustomerFields)}>
              {showMoreCustomerFields ? 'Show Less' : 'Show More'}
            </Button>
          </div>}

        <div className="table-header">Customer Matching</div>
        <table className="table-list mb-5">
          <thead>
            <tr>
              <th>Name</th>
              <th>Phone</th>
              <th>Email</th>
              <th>Source</th>
            </tr>
          </thead>
          <tbody>
            {customerDetails.customer_refs.map((ref, index) => {
            if (!showMoreCustomerRefs && index > 4) {
              return;
            }
            return <tr key={ref.id}>
                  <td>
                    {ref.first_name} {ref.last_name}
                  </td>
                  <td>{utils.formatPhoneNumber(ref.phone)}</td>
                  <td>{ref.email}</td>
                  <td>{ref.entity_source}</td>
                </tr>;
          })}
            {!customerDetails.customer_refs.length && <tr>
                <td colSpan="4">
                  <i>Customer has no matching data</i>
                </td>
              </tr>}
          </tbody>
        </table>
        {customerDetails.customer_refs.length > 5 && <div className="buttonCenter">
            <Button tag="a" onClick={() => setShowMoreCustomerRefs(!showMoreCustomerRefs)}>
              {showMoreCustomerRefs ? 'Show Less' : 'Show More'}
            </Button>
          </div>}

        {userState.hasPermission('customer.add_segment') && <div className="d-flex justify-content-between">
            <div className="table-header">Static Segments</div>
            {userState.hasPermission('customer.add_segment') && <Button onClick={() => setShowModal(true)}>Add to Static Segment</Button>}
          </div>}
        <table className="table-list mb-5">
          <thead>
            <tr>
              <th colSpan={2}>Name</th>
            </tr>
          </thead>
          <tbody>
            {customerDetails.segments.map((ref, index) => {
            if (!showMoreSegments && index > 4) {
              return;
            }
            return <tr key={ref.id}>
                  <td>{ref.segment_name}</td>
                  {userState.hasPermission('customer.delete_segment') && <td className="text-right">
                      <Button small={true} onClick={() => deleteSegment(ref.id)}>
                        Remove from Static Segment
                      </Button>
                    </td>}
                </tr>;
          })}
            {!customerDetails.segments.length && <tr>
                <td colSpan={2}>
                  <i>Customer not in any segments</i>
                </td>
              </tr>}
          </tbody>
        </table>
        {customerDetails.segments.length > 5 && <div className="buttonCenter">
            <Button tag="a" onClick={() => setShowMoreSegments(!showMoreSegments)}>
              {showMoreSegments ? 'Show Less' : 'Show More'}
            </Button>
          </div>}

        {availableModels.indexOf('schedule') > -1 && customerDetails.schedules.length ? <>
            <div className="table-header">Schedules</div>
            <table className="table-list mb-5">
              <thead>
                <tr>
                  <th>Appointment</th>
                </tr>
              </thead>
              <tbody>
                {sortBy(customerDetails.schedules, ref => {
              return new Date(ref.appointment);
            }).map((ref, index) => {
              if (!showMoreSchedules && index > 4) {
                return;
              }
              return <tr onClick={() => goToSchedule(ref.id)} key={ref.id}>
                      <td>{moment.utc(ref.appointment).format('MMM D, YYYY hh:mm A')}</td>
                    </tr>;
            })}
              </tbody>
            </table>
            {customerDetails.schedules.length > 5 && <div className="buttonCenter">
                <Button tag="a" onClick={() => setShowMoreSchedules(!showMoreSchedules)}>
                  {showMoreSchedules ? 'Show Less' : 'Show More'}
                </Button>
              </div>}
          </> : <></>}

        {availableModels.indexOf('animal') > -1 && customerDetails.animals.length ? <>
            <div className="table-header">Animals</div>
            <table className="table-list mb-5">
              <thead>
                <tr>
                  <th>Name</th>
                </tr>
              </thead>
              <tbody>
                {customerDetails.animals.map((ref, index) => {
              if (!showMoreAnimals && index > 4) {
                return;
              }
              return <tr key={ref.id}>
                      <td>{ref.name}</td>
                    </tr>;
            })}
              </tbody>
            </table>

            {customerDetails.animals.length > 5 && <div className="buttonCenter">
                <Button tag="a" onClick={() => setShowMoreAnimals(!showMoreAnimals)}>
                  {showMoreAnimals ? 'Show Less' : 'Show More'}
                </Button>
              </div>}
          </> : <></>}
        {availableModels.indexOf('real_estate') > -1 && customerDetails.real_estates.length ? <>
            <div className="table-header">Real Estate</div>
            <table className="table-list mb-5">
              <thead>
                <tr>
                  <th>Address</th>
                </tr>
              </thead>
              <tbody>
                {customerDetails.real_estates.map((ref, index) => {
              if (!showMoreRealStates && index > 4) {
                return;
              }
              return <tr key={ref.id}>
                      <td>
                        {ref.address1} {ref.address2} {ref.city} {ref.state} {ref.zip_code}
                      </td>
                    </tr>;
            })}
              </tbody>
            </table>
            {customerDetails.real_estates.length > 5 && <div className="buttonCenter">
                <Button tag="a" onClick={() => setShowMoreRealStates(!showMoreRealStates)}>
                  {showMoreRealStates ? 'Show Less' : 'Show More'}
                </Button>
              </div>}
          </> : <></>}
        {availableModels.indexOf('subscription') > -1 && customerDetails.subscriptions.length ? <>
            <div className="table-header">Subscriptions</div>
            <table className="table-list mb-5">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {customerDetails.subscriptions.map((ref, index) => {
              if (!showMoreSubscriptions && index > 4) {
                return;
              }
              return <tr key={ref.id}>
                      <td>{ref.name}</td>
                      <td>{ref.status}</td>
                    </tr>;
            })}
              </tbody>
            </table>
            {customerDetails.subscriptions.length > 5 && <div className="buttonCenter">
                <Button tag="a" onClick={() => setShowMoreSubscriptions(!showMoreSubscriptions)}>
                  {showMoreSubscriptions ? 'Show Less' : 'Show More'}
                </Button>
              </div>}
          </> : <></>}

        {availableModels.indexOf('vehicle') > -1 && customerDetails.vehicles.length ? <>
            <div className="table-header">Vehicles</div>
            <table className="table-list mb-5">
              <thead>
                <tr>
                  <th>Year</th>
                  <th>Make</th>
                  <th>Model</th>
                </tr>
              </thead>
              <tbody>
                {customerDetails.vehicles.map((ref, index) => {
              if (!showMoreVehicles && index > 4) {
                return;
              }
              return <tr key={ref.id}>
                      <td>{ref.year}</td>
                      <td>{ref.make}</td>
                      <td>{ref.model}</td>
                    </tr>;
            })}
              </tbody>
            </table>
            {customerDetails.vehicles.length > 5 && <div className="buttonCenter">
                <Button tag="a" onClick={() => setShowMoreVehicles(!showMoreVehicles)}>
                  {showMoreVehicles ? 'Show Less' : 'Show More'}
                </Button>
              </div>}
          </> : <></>}
      </div>
      <Modal open={showModal} onClose={() => setShowModal(false)} allowBackdropClick title="Add Customer to Static Segment">
        <div className="modal__body">
          <p>Select the static segment</p>
          <div className="form-group">
            <DynamicTypeAhead getItems={getSegments} getItemsFilters={{
            time_dynamic_segment: false,
            company: userState.getAsCompanyId(),
            id_not_in: customerDetails.segments.map(s => s.segment_id).join(',')
          }} placeholder="Choose Customer Segment" displayProperty="name" keyProperty="id" value={selectedSegment} onChange={val => setSelectedSegment(val)} />
          </div>
        </div>
        <div className="modal__actions">
          <Button actionType="flat" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button actionType="primary" onClick={() => addCustomerToSegment()} disabled={!selectedSegment}>
            Add
          </Button>
        </div>
      </Modal>

      <ModalDialog open={showOptOutSMS} onClose={() => setShowOptOutSMS(false)} allowBackdropClick title="Warning" onSubmit={() => {
      unsubscribeCustomerSMS();
      setShowEditSMSStatus(false);
    }} actionType="danger" submitText="Opt Out of SMS">
        You are about to opt this phone number out from receiving text messages. The only way to have this phone number opt-in will be to
        have them text "START" to your phone number.
      </ModalDialog>
      <ModalDialog open={cantOptInSMS} cancelText="Okay" onClose={() => setCantOptInSMS(false)} allowBackdropClick>
        <div className="pt-5">
          Because this phone number has opted out of text messages, you cannot opt them in. They can opt themselves back in to your text
          message by texting "START" to your phone number
        </div>
      </ModalDialog>
    </Scoped>;
}